import { Component,Inject, OnInit  } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-attendence-detail',
  templateUrl: './attendence-detail.component.html',
  styleUrls: ['./attendence-detail.component.scss']
})
export class AttendenceDetailComponent implements OnInit {
  
  skLoading: boolean = false;
  url: any = '';
  attendance_data: any = {};
  data_val: any = {};
  assign_login_data2: any = {};
  checkin_data: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<AttendenceDetailComponent>,public service : DatabaseService,public dialog : DialogService) {

    this.url = this.service.newUploadUrl + 'attendence/';
   }

  ngOnInit(): void {
    this.data_val = this.service.userLoginData.data
    this.assign_login_data2 = this.data_val
    this.getDetails();
  }
  close() {
    this.dialogRef.close();
  }
  
  getDetails() {
    this.skLoading = true;
    this.service.PostRequest({ 'attendance_id': this.data.attendance_id, 'user_id': this.data.user_id, 'date': this.data.date }, "Attendance/attendenceForTravelDetail")
      .subscribe((result => {
        if (result['statusCode'] == 200) {
          this.attendance_data = result['result'];
          this.checkin_data = this.attendance_data['check_in_data'];
          this.skLoading = false;
        } else {
          this.dialog.error('error',result['statusMsg'])
          this.skLoading = false;
        }
      }))
  }
}
