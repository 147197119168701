<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Pending Orders</h2>
                    <p>{{pendingOrderData.length}} Orders Are Available</p>
                </div>
                
            </ng-container>
            <ng-container >
                <div class="left-auto df afe">
                    <div>
                        <ul class="date-filter">
                            <li>
                                <div class="cs-form pb0">
                                    <p>Date From</p>
                                    <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                        <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                            placeholder="Search ..." name="date_from" [(ngModel)]="filterSearch.date_from"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </li>
                            <li>
                                <div class="cs-form pb0">
                                    <p>Date To</p>
                                    <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                        <input type="text" matInput [matDatepicker]="picker3" [max]="date" [min]="filterSearch.date_from"
                                            placeholder="Search ..." name="date_to" [(ngModel)]="filterSearch.date_to"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </li>
                            <li class="cs-btn mt15 mr10" *ngIf="this.filterSearch.date_from && this.filterSearch.date_to">
                                <a mat-button (click)="getPendingOrderList(1)">Go</a>
                            </li>
                            <li class="cs-btn mt15" *ngIf="this.filterSearch.date_from || this.filterSearch.date_to">
                                <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="!loader && lastUpdatedDate">
                        <div class="indicates">
                            <!-- Last Sync Time - {{lastUpdatedDate}} -->
                            Last Sync Time - {{lastUpdatedDate ? (lastUpdatedDate | date: 'd MMM y, hh mm a') : 'N/A'}}
                        </div>
                        <div class="cs-action">
                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                (click)="getPendingOrderList(1)"><i class="material-icons">search</i></a>
                            <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                        </div>
                    </div>

                    
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw100">So Number</div>
                            <div class="div_cell mmw100">Primary Order Id </div>
                            <div class="div_cell mmw110">SO Date</div>
                            <div class="div_cell mmw100" *ngIf="data_val?.login_type != 'distributor'">Sold To Party
                            </div>
                            <div class="div_cell mw180" *ngIf="data_val?.login_type != 'distributor'">Sold To Party Name
                            </div>
                            <div class="div_cell mmw100">Dispatch Quantity</div>
                            <div class="div_cell mmw100">Pending Quantity</div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">State</div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">District</div>
                            
                            <div class="div_cell mmw90">Amount</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Sales_Document"
                                        [(ngModel)]="filterSearch.Sales_Document"
                                        (keyup.enter)="getPendingOrderList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                               <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="order_id"
                                        [(ngModel)]="filterSearch.order_id"
                                        (keyup.enter)="getPendingOrderList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="doc_date"
                                            [(ngModel)]="filterSearch.doc_date" (dateChange)="onDate($event)"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw100" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Sold_to_party"
                                        [(ngModel)]="filterSearch.Sold_to_party"
                                        (keyup.enter)="getPendingOrderList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw180" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Sold_to_party_name"
                                        [(ngModel)]="filterSearch.Sold_to_party_name"
                                        (keyup.enter)="getPendingOrderList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                            </div>
                            <div class="div_cell mmw100">
                            </div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="state_name"
                                        [(ngModel)]="filterSearch.state_name"
                                        (keyup.enter)="getPendingOrderList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">
                               

                            </div>
                            <div class="div_cell mmw90">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="getPendingOrderList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of pendingOrderData; let i=index">
                            <div class="div_cell mmw50 ac">
                                <strong>{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw100"><a class="link-btn" mat-flat-button
                                    routerLink="../pendingOrder-Detail/{{data.Sales_Document}}">
                                    {{data.Sales_Document ? data.Sales_Document : 'N/A'}}
                                </a></div>
                            <div class="div_cell mmw100" *ngIf="data.order_id">
                                <a class="link-btn" mat-flat-button
                                    routerLink="../order-detail/{{data.order_id}}">#AFF-{{data.order_id ? data.order_id
                                    : 'N/A'}}</a>
                            </div>
                            <div class="div_cell mmw100" *ngIf="!data.order_id">
                                N/A
                            </div>
                            <div class="div_cell mmw110">{{data.Document_Date | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw100" *ngIf="data_val?.login_type != 'distributor'">
                                {{data.Sold_to_party ? data.Sold_to_party:'N/A'}}</div>
                            <div class="div_cell mw180" *ngIf="data_val?.login_type != 'distributor'">{{data.state_name
                                ? (data.Sold_to_party_name |
                                titlecase):'N/A'}}</div>
                                <div class="div_cell mmw100">{{data.Dispatch_qty ? data.Dispatch_qty : '0'}} </div>
                            <div class="div_cell mmw100">{{data.Pending_qty ? data.Pending_qty : '0' }} </div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">{{data.state_name
                                ? (data.state_name | titlecase):'N/A'}}
                            </div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">
                                {{data.district_name ? data.district_name:'N/A'}}</div>
                            <div class="div_cell mmw90  ac">₹ {{data.Net_Total_Value ? data.Net_Total_Value : '0'}}
                            </div>
                        </main>
                        <!-- Loop Data -->
                        <app-not-found *ngIf="pendingOrderData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw180" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw200" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>
                </section>
            </div>

            <!-- Pagination Start -->
            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">

                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="apiHit.userLoginData.data.moduleData[7]['add'] == 1"
                            (click)="ExcelUploadFunction(pendingOrderData)"><img src="assets/img/excel.png"> Upload
                            Excel</a>
                        <a mat-raised-button color="accent" *ngIf="pendingOrderData.length > 0"
                            (click)="ExcelDownloadFunction()" class="ml10"><img src="assets/img/excel.png"> Download
                            Excel</a>
                    </div>

                </div>
            </div>
            <!-- Pagination End -->

        </div>

    </div>
</div>