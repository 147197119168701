import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA , MatDialog, MatDialogRef, } from '@angular/material/dialog';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-checkin-detail',
  templateUrl: './checkin-detail.component.html',
  styleUrls: ['./checkin-detail.component.scss']
})
export class CheckinDetailComponent implements OnInit {
  skLoading:boolean = false;
  user_details:any ={};
  attedence_data:any ={}
  check_in_data:any =[];
  array :any =[]
  constructor(@Inject(MAT_DIALOG_DATA)public data, public dialogs: MatDialog,public service: DatabaseService,public dialogRef: MatDialogRef<CheckinDetailComponent>,public dialog : DialogService ) { }
  close(){
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.array = new Array(10);
    this.getDetails();
  }
  getDetails(){
    this.skLoading = true;
    this.service.PostRequest({'id':this.data.user_id,'date':this.data.date}, "Checkin/checkinDetail")
    .subscribe((result => {
      if(result['statusCode']==200){
      this.user_details  = result['result'];
      this.attedence_data = this.user_details.attedence_data;
      this.check_in_data = this.attedence_data.check_in_data;
      this.skLoading = false;
      }else{
        this.dialog.error('',result['statusMsg'])
      }
    }))
  }
}
