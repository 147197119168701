<div >
    <div mat-dialog-title class="model-head" >
        <h2>Assign New Retailer</h2>
        <a class="close-model" color="warn" mat-button  [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="AddRetailerForm" (ngSubmit)="updateRetailer()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Retailer Name</mat-label>
                            <mat-select  formControlName="retailer_id" 
                            id="retailer_id" required multiple>
                            <mat-option *ngFor="let row of retailerData" (click)="retailerid()"  value="{{row.id}}">{{row.dr_name | titlecase}}</mat-option>
                        </mat-select>
                        <mat-hint *ngIf="AddRetailerForm.controls.retailer_id.invalid && 
                        AddRetailerForm.controls['retailer_id']?.touched">
                        Retailer Is Required
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <div class="cs-btn">
        <button type="submit"  mat-button >Update</button>
    </div>
</mat-dialog-actions>
</form>
</div>