<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Billing List</h2>
                    <p>{{totalBills}} Invoice Are Available</p>
                </div>
            </ng-container>
            <ng-container >
                <div class="left-auto df afe">
                   
                    <div>
                        <ul class="date-filter">
                            <li>
                                <div class="cs-form pb0">
                                    <p>Date From</p>
                                    <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                        <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                            placeholder="Search ..." name="date_from" [(ngModel)]="filterSearch.date_from"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </li>
                            <li>
                                <div class="cs-form pb0">
                                    <p>Date To</p>
                                    <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                        <input type="text" matInput [matDatepicker]="picker3" [max]="date" [min]="filterSearch.date_from"
                                            placeholder="Search ..." name="date_to" [(ngModel)]="filterSearch.date_to"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </li>
                            <li class="cs-btn mt15 mr10" *ngIf="this.filterSearch.date_from && this.filterSearch.date_to">
                                <a mat-button (click)="getInvoiceList(1)">Go</a>
                            </li>
                            <li class="cs-btn mt15" *ngIf="this.filterSearch.date_from || this.filterSearch.date_to">
                                <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                            </li>
                            
                        </ul>
                    </div>
                    <div *ngIf="!loader && updatedDate">
                        <div class="indicates">
                            Last Sync Time - {{updatedDate ? (updatedDate | date: 'd MMM y, hh mm a') : 'N/A'}}
                        </div>
                        <div class="cs-action">
                            <!-- <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                (click)="getInvoiceList(1)"><i class="material-icons">search</i></a> -->
                            <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                (click)="refreshData()"><i
                                    class="material-icons">settings_backup_restore</i></a>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw180">Billing Date</div>
                            <div class="div_cell ">Billing Number</div>
                            <div class="div_cell mmw110">Total Bill Value</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor' || data_val?.user_type == 'approval_user'">Delivery Person</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'  || data_val?.user_type == 'approval_user'">Mobile No.</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor' || data_val?.user_type == 'approval_user' ">Vehicle Number </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'  || data_val?.user_type == 'approval_user'">Transportation Mode</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'  || data_val?.user_type == 'approval_user'">LR Number </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">Dr Code</div>
                            <div class="div_cell mw110" *ngIf="data_val?.login_type != 'distributor'">Dr Name</div>
                            <div class="div_cell mmw110">Box Value </div>
                            <div class="div_cell mmw90">Action</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw180">&nbsp;</div>
                            <div class="div_cell">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="ODN_Number"
                                        [(ngModel)]="filterSearch.ODN_Number" (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>

                            <div class="div_cell mmw110"></div>

                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Code"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Code"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Code"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Code"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Code"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Code"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Code"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Code"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Code"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Code"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Code"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Code"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw110" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Name"
                                        [(ngModel)]="filterSearch.Cust_Vendor_Name"
                                        (keyup.enter)="getInvoiceList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110"></div>
                            <div class="div_cell mmw90">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="getInvoiceList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of pendingOrderData; let i=index">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw180">
                                <!-- {{data.Bill_Date}} -->
                                {{data.Bill_Date ? (data.Bill_Date | date: 'd MMM y') : 'N/A'}}
                            </div>
                            <div class="div_cell"><a class="link-btn" mat-flat-button
                                    routerLink="../billing-Detail/{{data.ODN_Number}}">
                                    {{data.ODN_Number ? data.ODN_Number:'N/A'}}
                                </a>
                            </div>
                            <div class="div_cell mmw110 text-center">{{data.total_amount ? (data.total_amount | number : '1.2-2') : '0'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">{{data.dispatch_details?data.dispatch_details.delivery_boy_name:'N/A'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">{{data.dispatch_details?data.dispatch_details.mobile_number:'N/A'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">{{data.dispatch_details?data.dispatch_details.vehicle_number:'N/A'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">{{data.dispatch_details?data.dispatch_details.transportation_mode:'N/A'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">{{data.dispatch_details?data.dispatch_details.reference_number:'N/A'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">{{data.Cust_Vendor_Code ? data.Cust_Vendor_Code : 'N/A'}}</div>
                            <div class="div_cell mw110" *ngIf="data_val?.login_type != 'distributor'">{{data.Cust_Vendor_Name ? (data.Cust_Vendor_Name | titlecase)
                                :'N/A'}}</div>
                            <div class="div_cell mmw110">{{data.dispatch_details?data.dispatch_details.box_value:'N/A'}}</div>

                            <div class="div_cell mmw90">&nbsp;</div>
                        </main>
                        <!-- Loop Data -->
                        <app-not-found *ngIf="pendingOrderData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <!-- <div class="div_cell mmw110" *ngIf="data_val?.login_type == 'distributor'|| data_val?.user_type == 'approval_user'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div> -->
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw110" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>
                </section>


            </div>

            <!-- Pagination Start -->
            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination" *ngIf="total > 0" >
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
          
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" style="margin-right: 12px;" *ngIf="apiHit.userLoginData.data.moduleData[8]['add'] == 1" (click)="FileHitDownloadSection(pendingOrderData)"><img src="assets/img/excel.png">Update Billing File</a>

                        <a mat-raised-button color="accent" *ngIf="apiHit.userLoginData.data.moduleData[8]['add'] == 1" (click)="ExcelUploadFunction(pendingOrderData)"><img src="assets/img/excel.png"> Upload Excel</a>

                        <a mat-raised-button color="accent"  *ngIf="pendingOrderData.length > 0"  (click)="ExcelDownloadFunction()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                    
                </div>
            </div>
            <!-- Pagination End -->
        </div>

    </div>
</div>