import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PayNowModelComponent } from '../pay-now-model/pay-now-model.component';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { ExcelUploadModalComponent } from '../excel-upload-modal/excel-upload-modal.component';


@Component({
    selector: 'app-distributor-list',
    templateUrl: './distributor-list.component.html'
})
export class DistributorListComponent implements OnInit {

    distributorData: any = [];
    paginationData: any = {};
    loader: any;
    total: any;
    filterSearch: any = {};
    skeleton: any = [];
    date: any = [];
    excelData: any = [];
    pageNumber: any;
    totalDistributor: any;
    data_val: any = {};
    access_level:any;

    constructor(

        public dialog: MatDialog,
        private apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.skeleton = new Array(10);
        this.date = new Date();
        this.pageNumber = 1;
        // this.distributorList(1);
    }

    ngOnInit(): void {
        this.data_val = this.apiHit.userLoginData.data
        console.log(this.data_val)
        console.log(this.data_val.access_level)
        this.access_level=this.data_val.access_level

        this.paginationData = this.apiHit.getFilterData('distributor-list');
        this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        console.log('distributor list filter search ', this.paginationData);
        this.distributorList(this.paginationData?.target ? this.paginationData.target : 1);
    }


    //Get DIstributor List Through API Function Start

    distributorList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }

        this.distributorData = [];
        this.paginationData.search = this.filterSearch;
        this.paginationData.pagelimit = 20;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
        this.paginationData.target = target;
        this.paginationData.pageNumber = this.pageNumber;
        if (this.apiHit.userLoginData.data.user_type == 'approval_user') {
            this.paginationData.user_id = this.apiHit.userLoginData.data.id;
        }
        else {
            this.paginationData.user_id = ''
        }
        this.apiHit.setFilterData(this.paginationData);

        this.apiHit.PostRequest(this.paginationData, "Distributor/dealer_list").subscribe((result => {

            console.log(result);
            this.distributorData = result['dealer_list']['info'];
            this.totalDistributor = result['dealer_list']['totalDistributor'];
            this.total = result['dealer_list']['totalPage'];
            this.loader = '';

            
          for (let i = 0; i < this.distributorData.length; i++) {
            if (this.distributorData[i].distributor_status == '1') {
              this.distributorData[i].activity_status = true
            }
            else if (this.distributorData[i].distributor_status == '0') {
              this.distributorData[i].activity_status = false;

            }
        }

        }));

    }
    //Get DIstributor List Through API Function End


    public onDate(event): void {
        this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
        this.distributorList(1);
    }

    onChangePageNumber(type) {

        console.log(this.pageNumber);

        if (type == 'previous') {

            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                this.distributorList(2);

            }

        }
        else if (type == 'next') {

            if (this.total > this.pageNumber) {

                this.pageNumber += 1;
                this.distributorList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber > this.total) {

                console.log('current else');
                this.pageNumber = this.total;
                this.distributorList(2);

            }
            else if (this.pageNumber == 0) {

                this.pageNumber = 1;
                this.distributorList(2);

            }
            else if (this.pageNumber) {

                console.log('last else if');
                this.distributorList(2);

            }

        }

    }

    exportDistributorDataInExcel() {

        this.loader = 1;
        this.paginationData.pagelimit = 0;
        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_DR_DATA").subscribe((result => {

            console.log(result);
            this.distributorList(1);
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURLNew + "uploads/DR_DATA.csv");

                console.log(this.apiHit.downloadURLNew,'uploads/DR_DATA.csv' )

            }, 700);

        }));
    }

    //Delete Distributor Function Start

    deleteDistributorData(deleteId) {

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ "id": deleteId }, "Distributor/delete_dealer").subscribe((result => {

                    this.loader = 1;

                    console.log(result);

                    if (result['delete_dealer'] == 'success') {

                        this.distributorList(2);

                    }
                    else {

                        this.dialog2.error('error', result['errors']);

                    }
                    setTimeout(() => {

                        this.loader = '';

                    }, 700);

                }));

            }

        });

    }
    //Delete Distributor Function End


    //Pay Amount To Distributor(Open Dialog Box) Function Start 

    payNowModel(data): void {

        console.log(data);
        console.log(data.wallet_balance);

        const dialogRef = this.dialog.open(PayNowModelComponent, {

            width: '400px',

            disableClose: true,

            data:
            {
                data
            }

        });

        dialogRef.afterClosed().subscribe(result => {

            dialogRef.close();
            this.distributorList(2);

        });

    }
    //Pay Amount To Distributor(Open Dialog Box) Function End



    //Excel Upload Function Start 

    ExcelUploadFunction(data): void {

        console.log(data);
        console.log(data.wallet_balance);

        const dialogRef = this.dialog.open(ExcelUploadModalComponent, {

            width: '400px',

            disableClose: true,

            data:
            {
                data
            }

        });

        dialogRef.afterClosed().subscribe(result => {

            dialogRef.close();
            this.distributorList(2);

        });

    }
    //Excel Upload Function End



    //Refresh Distributor List Function Start

    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null) {
            this.pageNumber = 1;
        }
        this.distributorList(1);

    }
    //Refresh Distributor List Function End


    //Distributor Status Update start()
    updateStatus(index, id, event) {
        if (event.checked == false) {
          this.dialog2.alert("Are You Sure You Want To Change Status").then((result) => {
            if (result) {
              if (event.checked == false) {
                this.distributorData[index].status = "0";
              }
              else {
                this.distributorData[index].status = "1";
              }
              let value = this.distributorData[index].status;
              this.apiHit.PostRequest({ 'distributor_id': id, 'status': value }, "Distributor/distributorStatusChange")
                .subscribe(result => {
                  if (result['data'] == 'success') {
                  this.dialog2.success('Success', 'Distributor Status Sucessfully Updated');
                    this.distributorList(1);
                  }
                  else {
                    this.dialog2.error('error', 'Something Went Wrong');
                    this.distributorList(1);


                  }
                })
            }
          })
        }
        else if (event.checked == true) {
          this.dialog2.alert("Are You Sure You Want To Change Status").then((result) => {
            if (result) {
              if (event.checked == false) {
                this.distributorData[index].status = "0";
              }
              else {
                this.distributorData[index].status = "1";
              }
    
              let value = this.distributorData[index].status;
              this.apiHit.PostRequest({ 'distributor_id': id, 'status': value }, "Distributor/distributorStatusChange")
                .subscribe(result => {
                  if (result['data'] == 'success') {
                  this.dialog2.success('Success', 'Distributor Status Sucessfully Updated');
                    this.distributorList(1);
                  }
                  else {
                     this.dialog2.error('error', 'Something Went Wrong');
                    this.distributorList(1);


                  }
                })
            }
            else{
                this.distributorList(1);

            }
          })
        }
      }
    //Distributor Status Update End()


}