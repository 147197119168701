import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-product-edit-modal',
  templateUrl: './product-edit-modal.component.html',
})
export class ProductEditModalComponent implements OnInit {

  ProductEdit: FormGroup;
  AddProduct: FormGroup;
  dataValue: any;
  date: any;
  productDetail: any;
  stateData: any = [];
  districtData: any = [];
  loader: any;
  minDate: Date;
  maxDate: Date;
  stataData: any;
  selectedDistrict: any;
  from: any;
  id: any;
  AddCategory: FormGroup;
  UpdateCategory: FormGroup;
  category: any;

  constructor(

    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialog,
    public fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog2: DialogService,

  ) {

    console.log(data);
    // console.log(apiHit);
    this.dataValue = this.data['target'];
    this.productDetail = data['data'];
    this.from = data['from'];
    this.id = data['id'];
    this.category = data['category'];
  }

  ngOnInit(): void {


    if (this.dataValue == 2) {

      this.ProductEdit = this.fb.group({

        segment: [this.productDetail['segment'], [Validators.required]],
        category: [this.productDetail['brand'], [Validators.required]],
        material_code: [this.productDetail['material_code'], [Validators.required]],
        part_code: [this.productDetail['part_code'], [Validators.required]],
        default_stock: [this.productDetail['default_stock'], [Validators.required]],
        coupon_value: [this.productDetail['coupon_value'], [Validators.required]],
        mrp: [this.productDetail['mrp'], [Validators.required]],
        list_price: [this.productDetail['list_price'], [Validators.required]],
        set_box_price: [this.productDetail['set_box_price'], [Validators.required]],
        id: [this.productDetail['id'], [Validators.required]],
        box_size: [this.productDetail['box_size'], [Validators.required]],
        box_mrp: [this.productDetail['box_mrp'], [Validators.required]],
        master_box_pack: [this.productDetail['master_box_pack'], [Validators.required]],
        set_box_pack: [this.productDetail['set_box_pack'], [Validators.required]],
        short_part_code: [this.productDetail['short_part_code'], [Validators.required]],
        set_per_master_box: [this.productDetail['set_per_master_box'], [Validators.required]],
        with_rivet_or_pad_kit: [this.productDetail['with_rivet_or_pad_kit'], [Validators.required]],
        uid: [this.apiHit.userLoginData.data.id, [Validators.required]],

      });
    }
    else if (this.dataValue == 1) {

      this.AddProduct = this.fb.group({

        segment: ['', [Validators.required]],
        category: ['', [Validators.required]],
        material_code: ['', [Validators.required]],
        part_code: ['', [Validators.required]],
        default_stock: ['', [Validators.required]],
        coupon_value: ['', [Validators.required]],
        mrp: ['', [Validators.required]],
        list_price: ['', [Validators.required]],
        set_box_price: ['', [Validators.required]],
        box_size: ['', [Validators.required]],
        box_mrp: ['', [Validators.required]],
        master_box_pack: ['', [Validators.required]],
        set_box_pack: ['', [Validators.required]],
        short_part_code: ['', [Validators.required]],
        set_per_master_box: ['',[Validators.required]],
        with_rivet_or_pad_kit: ['',[Validators.required]],
        uid: [this.apiHit.userLoginData.data.id, [Validators.required]],

      });

    }

    else if (this.from == 'customer-category-edit'){
      this.UpdateCategory = this.fb.group({
        category: [this.category, [Validators.required]],
        id: [this.id, [Validators.required]],
      });
    }
    else if (this.from == 'customer-category-add'){
      this.AddCategory = this.fb.group({
        category: ['', [Validators.required]]
      });
    }

  }

  //Update Product Alert Through API Function Start

  updateQuantity() {

    console.log(this.ProductEdit.value);

    this.apiHit.PostRequest(this.ProductEdit.value, "Distributor/updateDefaultStockAlert").subscribe((result => {

      console.log(result);

      if (result['status'] == "Success") {

        this.dialog2.success('Success', 'Product Sucessfully Updated');

        this.dialogRef.closeAll();

      }
      else if (result['status'] == 'AlreadyExist') {

        this.dialog2.error('error', 'Product Code ALready Exist');

      }
      else {

        this.dialog2.error('error', 'Something Went Wrong');

      }

    }));

  }
  //Update Product Alert Through API Function End


  //Update Product Alert Through API Function Start

  AddProductFunction() {

    console.log(this.AddProduct.value);

    this.apiHit.PostRequest(this.AddProduct.value, "Distributor/addParts").subscribe((result => {

      console.log(result);

      if (result['status'] == "success") {

        this.dialog2.success('Success', 'Product Sucessfully Added');

        this.dialogRef.closeAll();

      }
      else if (result['status'] == 'AlreadyExist') {

        this.dialog2.error('error', 'Product Code ALready Exist');

      }
      else {

        this.dialog2.error('error', 'error');

      }

    }));

  }
  //Update Product Alert Through API Function End


  OnlyNumber(event: any) {

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {

      event.preventDefault();

    }

  }

  AddCategoryFunction(){
    this.apiHit.PostRequest({'data': this.AddCategory.value}, "Distributor/addDistributorCategory").subscribe((result => {

      console.log(result);

      if (result['distributor_category'] == 'success') {

        this.dialog2.success('Success', 'Category Successfully Added !!!');
        this.dialogRef.closeAll();

      }
      else {

        this.dialog2.error('error', result['errors']);

      }
    }));
  }

  UpdateCategoryFunction(){
    this.apiHit.PostRequest({'data': this.UpdateCategory.value}, "Distributor/updateDistributorCategory").subscribe((result => {

      console.log(result);

      if (result['distributor_category'] == 'success') {

        this.dialog2.success('Success', 'Category Successfully Updated !!!');
        this.dialogRef.closeAll();

      }
      else {

        this.dialog2.error('error', result['errors']);

      }
    }));
  }


}
