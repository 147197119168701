import { Component, OnInit ,Inject } from '@angular/core';
import { FormBuilder, FormGroup ,Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-manual-hit-file-download',
  templateUrl: './manual-hit-file-download.component.html',
  styleUrls: ['./manual-hit-file-download.component.scss']
})
export class ManualHitFileDownloadComponent implements OnInit {
  HitCron: FormGroup;
  apiURL: any;
  skLoading: boolean=false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialog,
    public fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog2: DialogService,
  ) { }

  ngOnInit() {
    // Initialize the form group
    this.HitCron = this.fb.group({
      file_name: ['', Validators.required], // Add other form controls here as needed
    });
  }
  HitFileFunction() {

    console.log(this.HitCron);

    this.dialog2.alert('Are You Sure You Want To Update  Billing File?').then((result) => {

      if (result === true) {

      this.skLoading = true;

        this.apiHit.PostRequest(this.HitCron.value, "FTP_INTEGRATION/UPLOAD_ASK_INVOICE_BILLING_MANUAL").subscribe((result => {

          console.log(result['statusCode']);

          if (result['statusCode'] == '200') {
            this.skLoading = false;
            this.dialog2.success('Success',result['statusMsg']);
            this.dialogRef.closeAll();

          }
          else {
            this.skLoading = false;
            this.dialog2.error('error',result['statusMsg']);
          }
        }));

      }

    })


  }

}
