import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA ,MatDialog} from '@angular/material/dialog';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-expense-modal',
  templateUrl: './expense-modal.component.html',
  styleUrls: ['./expense-modal.component.scss']
})
export class ExpenseModalComponent implements OnInit {
  data_val:any = {};
  userData: any;
  userId: any;
  loader:any;
  userName: any;
  assign_login_data:any={};
  logined_user_data:any={};
  constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve:DatabaseService,public dialogeRef : DialogService) { }

  ngOnInit(): void {
    this.data_val = this.serve.userLoginData.data
    console.log(this.data.type)
    console.log(this.data.totalAmt)

    
    this.userId = this.data_val.id
    this.userName = this.data_val.name
  }
  update_status(){ 
    if(this.data['hr_approved_amount'] == ''){
      
      delete this.data.hr_approved_amount;
    } 
  
    if(this.data['approved_amount'] == ''){
      
      delete this.data.approved_amount;
    } 
    else if (this.data['reason'] == ''){
      
      delete this.data.reason;
    }
    this.data.created_by_id=this.userId;
    this.data.created_by_name=this.userName;
    console.log(this.data.hr_approved_amount)
    console.log(this.data.totalAmt)

    if(this.data.type == 'hrStatus' && (Number(this.data.hr_approved_amount) > Number(this.data.totalAmt ) )){
      console.log('if')
      this.dialogeRef.error("Alert","Entered amount can't be greater than Claim Amount!");
    }
     else if(this.data.type == 'acStatus' && (Number(this.data['approved_amount']) > Number(this.data.totalAmt))){
      this.dialogeRef.error("Alert","Entered amount can't be greater than Claim Amount!");
    }
    else{
      this.serve.PostRequest(this.data,"Expense/updateStatus").subscribe((result)=>{
        
        if(result['statusCode']==200)
        {
          this.dialogeRef.success('',result['statusMsg']);
          this.dialog.closeAll();
        }else{
          this.dialogeRef.error("Error",result['statusMsg']);
        }
      })
    }
    
    
  // }else{
  //   this.toast.errorToastr("Entered amount can't be greater than Claim Amount !")
  // }
  
  }
}
