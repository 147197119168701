import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
  selector: 'app-order-edit-modal',
  templateUrl: './order-edit-modal.component.html',
})
export class OrderEditModalComponent implements OnInit {

  QuantityEdit: FormGroup;
  ChangeStatus: FormGroup;
  SapStatus: FormGroup;
  dataValue: any;
  date: any;
  orderData: any;
  stateData: any = [];
  districtData: any = [];
  partNo: any = [];
  categoryName: any = [];
  segmentdata: any = [];
  buttonLoading : any = false;
  minDate: Date;
  maxDate: Date;
  stataData: any;
  selectedDistrict: any;
  retailerStatus: boolean = false;


  constructor(

    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialog,
    public fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog2: DialogService,

  ) {
    console.log(data);
    this.dataValue = this.data['target'];
    this.orderData = data['data'];
    this.retailerStatus = false;
  }


  ngOnInit(): void {

    if (this.dataValue == 1) {
      this.ChangeStatus = this.fb.group({

        status: [this.orderData['order_status'], [Validators.required]],
        reason_reject: [this.orderData['reject_reason'], []],
        order_id: [this.orderData['order_id'], [Validators.required]],
        uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
        user_type: [1, [Validators.required]],

      });
      // this.checkOrderStatus()
    }

    else if (this.dataValue == 2) {

      this.QuantityEdit = this.fb.group({

        order_id: [this.orderData['order_id'], [Validators.required]],
        items_id: [this.orderData['item_id'], [Validators.required]],
        segment: [this.orderData['segment'], [Validators.required]],
        category: [this.orderData['category'], [Validators.required]],
        part_code: [this.orderData['part_code'], [Validators.required]],
        item_qty: [this.orderData['qty'], [Validators.required]],
        user_id: [this.apiHit.userLoginData.data.id, [Validators.required]],
        searchCtrl: [''],

      });
      if (this.apiHit.userLoginData.data.id == 1) {
        this.getSegment();
        this.getCategory(this.orderData['segment'], 1);
        this.getPartCodeNo(this.orderData['category'], 1);
      }

    }

    else if (this.dataValue == 3) {

      this.SapStatus = this.fb.group({

        order_id: [this.orderData['order_id'], [Validators.required]],
        uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
        sap_orderNo1: [this.orderData['sap_order_1'], []],
        sap_orderNo2: [this.orderData['sap_order_2'], []],
        sap_orderNo3: [this.orderData['sap_order_3'], []],
        list_discount: [this.orderData['list_discount'], []],
        invoice_discount: [this.orderData['invoice_discount'], []],
        transporter: [this.orderData['transport'], []],
        sap_status: [this.orderData['sap_status'], [Validators.required]],
        sap_status_reason: [this.orderData['sap_status_reason'], []],

      });

      // this.checkSapOrderStatus();
    }


  }

  getSegment() {

    this.apiHit.PostRequest('', "app/AppOrderController/getSegment").subscribe((result => {

      console.log(result['result']);
      if (result['result']['status'] == 'success') {
        this.segmentdata = result['result']['segmentData'];
      }

    }));
  }


  //Part Code Api Start
  getCategory(brand, type) {

    this.partNo = [];
    this.categoryName = [];
    // console.log(this.couponForm.value);
    if (type == 2) {
      this.QuantityEdit.controls.category.setValue('');
      this.QuantityEdit.controls.part_code.setValue('');
      this.QuantityEdit.controls.item_qty.setValue('');
    }

    this.apiHit.PostRequest({ 'brand': brand }, "CouponCode/partCategory").subscribe((result => {

      console.log(result);
      this.categoryName = result['partCategory'];

    }));

  }
  //Part Code Api End


  //Part Code Api Start
  getPartCodeNo(category, type) {

    this.partNo = [];
    if (type == 2) {
      this.QuantityEdit.controls.part_code.setValue('');
      this.QuantityEdit.controls.item_qty.setValue('');
    }
    console.log(this.QuantityEdit.controls.segment.value);

    this.apiHit.PostRequest({ 'category': category, segment: this.QuantityEdit.controls.segment.value }, "CouponCode/partNoList").subscribe((result => {

      console.log(result);
      this.partNo = result['part_No_List'];

    }));

  }
  //Part Code Api End



  checkOrderStatus() {

    if (this.ChangeStatus.controls.status.value == '3' || this.ChangeStatus.controls.status.value == '4') {
      this.retailerStatus = true;
      this.ChangeStatus.controls.reason_reject.setValidators([Validators.required]);
      this.ChangeStatus.controls.reason_reject.setValue('');
    }
    else {
      this.retailerStatus = false;
      this.ChangeStatus.controls.reason_reject.setValidators(null);
      this.ChangeStatus.controls.reason_reject.setValue('');
    }
    console.log(this.ChangeStatus);
  }


  checkSapOrderStatus() {

    console.log(this.SapStatus);

    if (this.SapStatus.controls.sap_status.value == 'Hold' || this.SapStatus.controls.sap_status.value == 'Reject' || this.SapStatus.controls.sap_status.value == 'Pending') {
      if (this.SapStatus.controls.sap_status.value != 'Pending') {
        this.SapStatus.controls.sap_status_reason.setValidators([Validators.required]);
      }
      this.SapStatus.controls.sap_status_reason.setValue('');
      this.SapStatus.controls.sap_orderNo1.setValidators(null);
      this.SapStatus.controls.sap_orderNo1.setValue('');
      this.SapStatus.controls.sap_orderNo2.setValue('');
      this.SapStatus.controls.sap_orderNo2.setValue('');
      this.SapStatus.controls.list_discount.setValidators(null);
      this.SapStatus.controls.list_discount.setValue('');
      this.SapStatus.controls.invoice_discount.setValue('');
      this.SapStatus.controls.transporter.setValidators(null);
      this.SapStatus.controls.transporter.setValue('');
    }
    else {
      this.SapStatus.controls.sap_status_reason.setValidators(null);
      this.SapStatus.controls.sap_status_reason.setValue('');
      this.SapStatus.controls.sap_orderNo1.setValidators([Validators.required]);
      this.SapStatus.controls.sap_orderNo1.setValue('');
      this.SapStatus.controls.sap_orderNo2.setValue('');
      this.SapStatus.controls.sap_orderNo2.setValue('');
      this.SapStatus.controls.list_discount.setValidators([Validators.required]);
      this.SapStatus.controls.list_discount.setValue('');
      this.SapStatus.controls.invoice_discount.setValue('');
      this.SapStatus.controls.transporter.setValidators([Validators.required]);
      this.SapStatus.controls.transporter.setValue('');
    }

  }

  // KeyPress Function Only For Write Number Function Start
  OnlyNumber(event: any) {

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {

      event.preventDefault();

    }

  }
  // KeyPress Function Only For Write Number Function Start

  updateQuantity() {

    this.buttonLoading == true;
    console.log(this.QuantityEdit.value);

    this.dialog2.alert('Are You Sure You Want To Update Item').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest(this.QuantityEdit.value, "app/AppOrderController/editOrderItems").subscribe((result => {

          console.log(result);

          if (result['order_info']['status'] == "Success") {

            this.dialog2.success('Success', 'Item Sucessfully Updated');

            this.dialogRef.closeAll();

          }
          else {

            this.dialog2.error('error', 'Something Went Wrong');

          }
          this.buttonLoading = false;
        }));

      }

    })

  }



  //Status Change API Function Start
  changeStatusFunction() {

    if (this.ChangeStatus.value.status != 3 && this.ChangeStatus.value.status != 4) {
      this.ChangeStatus.value.reason_reject = '';
    }
    console.log(this.ChangeStatus.value);

    this.dialog2.alert('Are You Sure You Want To Change Order Status').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest(this.ChangeStatus.value, "app/AppOrderController/changeStatus").subscribe((result => {

          console.log(result);

          if (result['Order_status_detail']['status'] == "Success") {

            this.dialog2.success('Success', 'OrderStatus Sucessfully Changed');

            this.dialogRef.closeAll();

          }
          else {

            this.dialog2.error('error', 'Something Went Wrong');

          }
        }));

      }

    })


  }
  //Status Change API Function End


  //Sap Status Change API Function Start
  changeSapStatus() {

    console.log(this.SapStatus);

    this.dialog2.alert('Are You Sure You Want To Change Order SAP Status').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest(this.SapStatus.value, "app/AppOrderController/approveSapStatus").subscribe((result => {

          console.log(result['result']);

          if (result['result']['status'] == 'Success') {

            this.dialog2.success('Success', 'SAP Order Status Sucessfully Changed');

            this.dialogRef.closeAll();

          }
          else {

            this.dialog2.error('error', 'Something Went Wrong');

          }
        }));

      }

    })


  }
  //Sap Status Change API Function End

}