import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
    selector: 'app-ask-to-distributor-list',
    templateUrl: './ask-to-distributor-list.component.html'
})
export class AskToDistributorListComponent implements OnInit {

    data: any = [];
    skeleton: any = [];
    loader: any;
    distributorTransaction: any;
    total: any;
    distributorTransactionSearch: any = {};
    date: any;
    pageNumber: any;
    paginationData: any = {};
    totalTransaction: any = {};


    constructor(

        private apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.data = new Array(2);
        this.skeleton = new Array(10);
        this.date = new Date();

    }

    ngOnInit(): void {
        this.pageNumber = 1;
        this.distributorTransactionList(1);

    }


    onChangePageNumber(type) {

        console.log(this.pageNumber);
        setTimeout(() => {

            if (type == 'previous') {

                if (this.pageNumber > 1) {


                    this.pageNumber -= 1;
                    this.distributorTransactionList(2);

                }

            }
            else if (type == 'next') {

                if (this.total > this.pageNumber) {

                    this.pageNumber += 1;
                    this.distributorTransactionList(2);

                }

            }
            else if (type = 'current') {

                if (this.pageNumber > this.total) {

                    console.log('current else');

                    this.pageNumber = this.total;
                    this.distributorTransactionList(2);

                }
                else if (this.pageNumber == 0) {

                    this.pageNumber = 1;
                    this.distributorTransactionList(2);

                }
                else if (this.pageNumber) {

                    console.log('last else');

                    this.distributorTransactionList(2);
                }

            }
        }, 100);


    }


    //Get DIstributor Transaction List Through API Function Start
    distributorTransactionList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }
        if (this.distributorTransactionSearch.date_from && this.distributorTransactionSearch.date_to) {
            this.distributorTransactionSearch.date_from = moment(this.distributorTransactionSearch.date_from).format('YYYY-MM-DD');
            this.distributorTransactionSearch.date_to = moment(this.distributorTransactionSearch.date_to).format('YYYY-MM-DD');
        }
        this.distributorTransaction = {};
        this.paginationData.searchData = this.distributorTransactionSearch;
        this.paginationData.pagelimit = 20;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;

        this.apiHit.PostRequest(this.paginationData, "Distributor/company_to_distributor_transaction_search").subscribe((result => {

            console.log(result);

            this.distributorTransaction = result['transactionData']['data'];
            this.total = result['transactionData']['data'].totalPage;
            this.totalTransaction = result['transactionData']['data'].total_rows_after_search;

            setTimeout(() => {

                this.loader = '';

            }, 300);

        }));

    }
    //Get DIstributor Transaction List Through API Function End


    //Delete Distributor Transaction Function Start

    deleteDistributorData(deleteId) {


        console.log(deleteId);

        this.dialog2.delete('').then((result) => {

            if (result === true) {
                this.loader = 1;

                this.apiHit.PostRequest({ "id": deleteId }, "Distributor/payment_delete").subscribe((result => {

                    console.log(result);

                    if (result['payment_delete'] == 'success') {

                        this.distributorTransactionList(2);

                    }
                    else {

                        this.dialog2.error('error', 'Something Went Wrong');
                        this.loader = '';

                    }

                }));

            }

        });

    }
    //Delete Distributor Transaction Function End



    public onDate(event): void {

        this.distributorTransactionSearch.dateCreated = moment(event.value).format('YYYY-MM-DD');
        this.distributorTransactionList(1);

    }


    clearDateFilter() {
        this.distributorTransactionSearch.date_from = '';
        this.distributorTransactionSearch.date_to = '';
        this.distributorTransactionList(1)
    }

    refreshData() {

        this.distributorTransactionSearch = {};
        this.distributorTransactionList(1);

    }

    exportExcel() {

        this.loader = 1;
        this.paginationData.pagelimit = 0;
        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_company_to_distributor_transaction_search").subscribe((result => {

            console.log(result);
            this.distributorTransactionList(1);
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/distributer_transaction.csv");

            }, 700);

        }));
    }


}