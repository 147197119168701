import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-warehouse-stock',
  templateUrl: './warehouse-stock.component.html',
})
export class WarehouseStockComponent implements OnInit {

  search: any = {};
  loader: any;
  data: any = [];
  paginationData: any = {};
  pageNumber: any;
  skeleton: any = [];
  wareHouseStockData: any = [];
  total: any = 0;
  totalTrans: any = 0;
  filterSearch: any = {};
  selected_warehouse: any;
  warehouseData: any;


  constructor(
    public apiHit: DatabaseService,
    public dialog: DialogService,
  ) {
    this.data = new Array(2);
    this.skeleton = new Array(10);
    this.pageNumber = 1;
    // this.wareHouseStockList(1);
    this.warehouseList();
  }

  ngOnInit(): void {
  }


  selectWarehouse(warehouse) {
    this.selected_warehouse = warehouse.id;
    this.filterSearch = {}
    this.wareHouseStockList(1);
  }

  warehouseList() {
    // console.log(this.selected_district);
    // console.log(this.AddDistributorForm);
    this.loader = 1;

    this.apiHit.PostRequest('', "CouponCode/wareHouseList").subscribe((result => {

      this.warehouseData = result['data'];
      this.selected_warehouse = this.warehouseData?.[0]?.id;
      this.wareHouseStockList(1);

    }));

  }



  wareHouseStockList(target) {

    this.loader = 1;

    if (this.search.date_from && this.search.date_to) {
      this.paginationData.date_from = moment(this.search.date_from).format('YYYY-MM-DD');
      this.paginationData.date_to = moment(this.search.date_to).format('YYYY-MM-DD');
    }

    if (target == 1) {
      this.pageNumber = 1;
    }
    this.wareHouseStockData = [];
    this.paginationData.searchData = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.box_type = 1;
    this.paginationData.warehouse_id  = this.selected_warehouse;

    this.apiHit.PostRequest(this.paginationData, "CouponCode/getMasterBoxScannedType").subscribe((response => {

      console.log(response['CouponCodes_list']);
      this.wareHouseStockData = response['CouponCodes_list']['couponAvailableData'];
      this.loader = '';

    }));
  }


  clearDateFilter() {
    this.search.date_from = '';
    this.search.date_to = '';
    this.wareHouseStockList(1);
  }


  updateRetailerScheme() {

    this.loader = 1;

    this.apiHit.PostRequest('', "SchemeController/GET_RETAILER_SCHEME_BENIFIT").subscribe((response => {

      console.log(response);
      this.wareHouseStockList(1);

    }));
  }


  onChangePageNumber(type) {

    console.log(this.pageNumber);
    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {


          this.pageNumber -= 1;
          this.wareHouseStockList(2);

        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.wareHouseStockList(2);

        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          console.log('current else');

          this.pageNumber = this.total;
          this.wareHouseStockList(2);

        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.wareHouseStockList(2);

        }
        else if (this.pageNumber) {

          console.log('last else');

          this.wareHouseStockList(2);
        }

      }
    }, 100);

  }


  //Refresh Billing Refresh List Function Start

  refreshData() {

    this.filterSearch = {};
    if (this.pageNumber == null) {
      this.pageNumber = 1;
    }
    this.wareHouseStockList(1);
  }
  //Refresh Billing Refresh List Function End


  exportExcel() {

    this.loader = 1;
    this.paginationData.pagelimit = 0;
    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/EXPORT_pending_scheme_retailers").subscribe((result => {

      console.log(result);
      if (result['msg'] == 'Success') {
        this.wareHouseStockList(1);
        setTimeout(() => {

          window.open(this.apiHit.downloadURL + "uploads/pending_scheme_retailers.csv");
          this.loader = '';

        }, 700);
      }

    }));
  }


}
