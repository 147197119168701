import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { ToastComponent } from 'src/app/toast/toast.component';
import { threadId } from 'worker_threads';

@Component({
    selector: 'app-user-add',
    templateUrl: './user-add.component.html'
})
export class UserAddComponent implements OnInit {
    
    districtValue: any=[];
    stateValue: any=[];
    datalist: any=[];
    designation_list: any=[];
    system_user_designation:any=[]
    reportingManager: any=[];
    search_btn_selected: any;
    show_modules_right: any;
    districtListData: any = []; 
    statedata: any=[];
    stateDatas: any=[];
    stataData: any;
    statelistdata: any=[];
    datas: any=[];
    state: any;
    selectedDistrict: any;
    data: any;
    AddUserForm : FormGroup;
    loader : any;
    districtData : any;
    stateData : any;
    moduleData : any;
    module_data: any;
    statelists: any=[];
    userData : any={};
    userDetailData : any;
    addUser : any;
    addUser1: any;
    skeleton:any=[];
    skeleton1: any=[];
    search_btn: any;
    selected_state: any=[];
    selected_district: any=[];
    districtsearch_btn: any;
    select_state: any=[];
    statesearch_btn: string;
    zsmData : any = {};
    
    user_type_value = [
        
        {id: 'System_user', name: "System User"},
        {id: 'approval_user', name: "Approval User"},
        {id: 'sales_user', name: "Sales User"}
        
    ];
    
    approval_user_types = [
        
        {id: 'asm', name: "ASM"},
        {id: 'zsm', name: "ZSM"}
        
    ];
    myDate = new Date();
    
    constructor(
        public toast: ToastComponent,
        private fb:FormBuilder,
        public apiHit: DatabaseService,
        public dialog : DialogService,
        public activateRoute : ActivatedRoute,
        private _location: Location,
        public router : Router,
        
        ) { 
            
            this.userDetail();
            this.getDesignations()
            this.getSystemUserDesignation();
            this.getReportingManager()
            console.log(activateRoute['params']['_value']['id']);
            console.log(this.AddUserForm);
            this.data=this.user_type_value            
            this.stateList();
            this.assign_state_list();
            this.skeleton = new Array(7);
            this.skeleton1= new Array(1); 
            
            this.AddUserForm = this.fb.group ({
                
                name                :   ['' , [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                mobile_no           :   ['' , [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                email_id            :   ['' , [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                pincode             :   ['' , [Validators.minLength(6), Validators.maxLength(6)]],
                state_name          :   ['' , ],
                district_name       :   ['' , ],
                city_name           :   ['' , [Validators.minLength(3), Validators.maxLength(30)]],
                userType            :   ['',  [Validators.required]],
                street              :   ['' , [Validators.minLength(3) ]],
                approvalType        :   ['' ,  [Validators.required]],  
                searchCtrl          :   [''   ],
                statesearch_btn     :   [''   ],
                user_designation    :   [''],
                system_user_designation : ['',],
                // employee_id         :   ['',  [Validators.required]],
                weekly_off          :   [''],
                assign_user         :   [''],
                username: [''],
                password: ['']
            });
            
            this.AddUserForm.get('userType').valueChanges.subscribe(userType => {
                const userDesignationControl = this.AddUserForm.get('user_designation');
                const usernameControl = this.AddUserForm.get('username');
                const passwordControl = this.AddUserForm.get('password');
          
                if (userType == 'sales_user') {
                  userDesignationControl.setValidators([Validators.required]);
                } else {
                  userDesignationControl.clearValidators();
                }
                userDesignationControl.updateValueAndValidity();
          
                if (this.addUser == 0 && (userType === 'System_user' || userType === 'approval_user')) {
                  usernameControl.setValidators([Validators.required]);
                  passwordControl.setValidators([Validators.required]);
                } else {
                  usernameControl.clearValidators();
                  passwordControl.clearValidators();
                }
                usernameControl.updateValueAndValidity();
                passwordControl.updateValueAndValidity();
                console.log('Updating Validators...');
                
              });
            
            

            if(activateRoute['params']['_value']['id'] == 0){
                
                this.moduleRightsList();
          
                this.addUser = 1;
                
            }else{
                
                // this.userDetail();
                this.addUser = 0;
                
            }
            
            
            if(activateRoute['params']['_value']['id'] == 0){
                
                console.log(activateRoute['params']['_value']['id']);
                this.moduleRightsLists()
                this.addUser1 = 1;
                
            } else{
                
                // this.userDetail();
                this.addUser1 = 0;
                
            }
            
            
        }
        
        ngOnInit(): void {
            
            console.log(this.apiHit.userLoginData);
            this.data= this.apiHit.userLoginData.data 
            console.log(this.data);
            
        }
        
        changeFormcontrolgroup(value){
            console.log(value);
            
            if (value== 'approval_user') {
                console.log('in if ');
                this.AddUserForm.get('user_designation').clearValidators();
                this.AddUserForm.get('user_designation').updateValueAndValidity();
                console.log(this.AddUserForm);
                
            }
            else if (value== 'sales_user'){
               
                // this.AddUserForm.get('approvalType').clearAsyncValidators();
                this.AddUserForm.get('approvalType').clearValidators();
                this.AddUserForm.get('approvalType').updateValueAndValidity();
            }
            else{
                this.AddUserForm.get('approvalType').clearValidators();
                this.AddUserForm.get('approvalType').updateValueAndValidity();

                this.AddUserForm.get('user_designation').clearValidators();
                this.AddUserForm.get('user_designation').updateValueAndValidity();

            }
        }
        district_search(value) {
            
            console.log(value);
            console.log(this.module_data);
            console.log(this.show_modules_right);
            
        }
        
        searchBtn(val) {
            
            console.log(val);
            console.log(this.AddUserForm);
            
        }
        
        
        reset_district_button() {
            
            this.districtsearch_btn='';
            
            this.search_btn =0;
        }
        
        reset_button() {
            
            this.statesearch_btn='';
            this.search_btn_selected = 0;
        }
        
        districtList1() {
            
            this.apiHit.PostRequest( {'state' : this.AddUserForm.value.state_name}, "Distributor/district_list").subscribe((result => {
                
                this.districtData = result['district_list']['district_name'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
            
        } 
        getSystemUserDesignation() {
            this.apiHit.PostRequest({}, "User/getSystemUserDesignation").subscribe((response => {
              this.system_user_designation = response['userData'];
            }));
          }
        
        getDesignations() {
            this.apiHit.PostRequest({}, "User/getDesignation ").subscribe((response => {
              this.designation_list = response['userData'];
            }));
          }
        getReportingManager() {
            this.apiHit.PostRequest({}, "User/getReportingManager").subscribe((response => {
              this.reportingManager = response['userData'];
            }));
          }
        //Get User Detail Through API Function Start
        
        userDetail() {
            
            this.loader = 1;
            this.apiHit.PostRequest( this.activateRoute['params']['_value'], "User/get_user_detail").subscribe((result => {
                
                this.userDetailData = result['userData'];
                this.moduleData     = result['userData']['moduleData'];
                this.module_data    = result['userData']['module_data'];
                
                this.statelists= result['userData']['asm_zsm_stateList'];
                console.log(this.statelists);

                for (let index = 0; index < this.statelists.length; index++) 
                {
                    this.selected_state.push( this.statelists[index].state_name)
                    this.selected_district.push(this.statelists[index].district_name)
                }
                
                console.log(this.selected_district);
                console.log(this.selected_state);
                    
                
                
                this.on_select_state('','')
                const stateIndex = this.stateDatas.findIndex(row => row.state == this.statelists.state_name);
                
                console.log(this.stateDatas);
                console.log(stateIndex);
                
                if(stateIndex == -1) {

                    console.log(stateIndex);
                    this.stateDatas.checked =true
                    console.log(this.stateDatas);
                    
                }
                
                console.log(this.statelists);
                console.log(this.userDetailData);
                
                this.setUserData();
                this.districtList(this.userDetailData['state_name'])
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Get User Detail Through API Function End


        setUserData() {
            
            console.log(this.AddUserForm);
            console.log(this.userDetailData['state_name']);
            console.log(this.userDetailData['password'],this.userDetailData['username'])
            
            this.AddUserForm.controls.name.setValue(this.userDetailData['name']);
            this.AddUserForm.controls.mobile_no.setValue(this.userDetailData['mobile_no']);
            this.AddUserForm.controls.email_id.setValue(this.userDetailData['email_id']);
            this.AddUserForm.controls.userType.setValue(this.userDetailData['user_type']);
            this.AddUserForm.controls.approvalType.setValue(this.userDetailData['approval_type']);
            this.AddUserForm.controls.pincode.setValue(this.userDetailData['pincode']);
            this.AddUserForm.controls.state_name.setValue(this.userDetailData['state_name']);
            this.AddUserForm.controls.district_name.setValue(this.userDetailData['district_name']);
            this.AddUserForm.controls.city_name.setValue(this.userDetailData['city_name']);
            this.AddUserForm.controls.street.setValue(this.userDetailData['street']);
            this.AddUserForm.controls.user_designation.setValue(this.userDetailData['user_designation'].toString());
            this.AddUserForm.controls.system_user_designation.setValue(this.userDetailData['system_user_designation'].toString());
            // this.AddUserForm.controls.employee_id.setValue(this.userDetailData['employee_id']);
            this.AddUserForm.controls.weekly_off.setValue(this.userDetailData['weekly_off']);
            this.AddUserForm.controls.assign_user.setValue(this.userDetailData['assign_user'].toString());
            this.AddUserForm.controls.username.setValue(this.userDetailData['username']);
            this.AddUserForm.controls.password.setValue(this.userDetailData['password']);
            console.log(this.AddUserForm);
            console.log(this.userDetailData['password'],this.userDetailData['username'])
            this.changeFormcontrolgroup(this.userDetailData['user_type'])
            
        }
        
        
        //Check Role Permission Assinged Function Start
        
        checkRolePermissionAssigned() {
            
            // return;
            if(this.AddUserForm.value.userType == 'System_user'){
                
                let target = 0;
                for (let i = 0; i < this.moduleData.length; i++) {
                    
                    if(this.moduleData[i]['view'] == true ||  this.moduleData[i]['add'] == true || this.moduleData[i]['edit'] == true || this.moduleData[i]['delete'] == true){
                        
                        target = 1;
                        
                        if(this.addUser > 0){
                            
                            this.submitUser();
                            break;
                        }
                        else{
                            
                            this.updateUser();
                            break;
                        }
                        
                    }
                    
                }
                
                if(target == 0){
                    console.log('not assign function');
                    this.dialog.error('Not, any permission assign','Check atleast one permission');
                    
                }
            }
            
            else{
                
                this.submitUser();
                
            }
            
            
        }
        
        checkRolePermissionAssignForApproval_user() {
            
            console.log(this.AddUserForm.value);
            console.log(this.module_data);
            console.log(this.moduleData);
            
            // return;
            if(this.AddUserForm.value.userType == 'approval_user'){
                
                let target = 0;
                console.log(this.module_data);
                console.log(this.addUser1);

                
                for (let i = 0; i < this.module_data.length; i++) {
                    
                        
                        console.log('in if 1');
                        
                        target = 1;
                        
                        if(this.addUser1 > 0 ){
                            console.log('in if 2');
                            
                            this.submitUser();
                            break;
                        }
                        else{
                            console.log('in else 2');
                            this.updateUser1();
                            break;
                        }
                        
                    
                }
                
                if(target == 0){
                    console.log('not assign function');
                    this.dialog.error('Not, any permission assign','Check atleast one permission');
                    
                }
            }
            
            // else{
            
            //     this.submitUser();
            
            // }
            
            
        }
        
        inputChecked(val){
            
            
        }
        //Check Role Permission Assinged Function END
        
        
        
        //Submit User Function Start
        
        submitUser() {
            
            if(this.AddUserForm.value.userType== 'System_user'){
                
                this.dialog.save_user('').then((result) => {
                    
                    this.userData = this.AddUserForm.value;
                    
                    this.userData.moduleData = this.moduleData;
                    
                    if(result === true) {
                        
                        this.apiHit.PostRequest( { 'userData':this.userData, 'user_id': this.data.id }, "User/add_user").subscribe((result => {
                            
                            console.log(result);

                            if(result['userData'] == 'Success'){
                                
                                this.dialog.success('Success', 'User Successfully Added');
                                this.router.navigate(['user-list']);
                                
                            }
                            else if(result['userData'] == 'EmailExists'){

                                this.dialog.error('error', 'Email Is Already Exists');
                                
                            }
                            else{
                                
                                this.dialog.error('error', 'Something Went Wrong');
                                
                            }
                            
                            setTimeout(() => {
                                
                                this.loader = '';
                                
                            }, 700);
                            
                        }));
                        
                    };
                    
                })
            }
            
            if(this.AddUserForm.value.userType== 'approval_user' && this.AddUserForm.value.approvalType=='asm'){
                
                if(this.selected_state== '' ) {
                    this.dialog.error('Select Atleast One State','Required');
                    return;
                }
                
                if( this.selected_district == '')
                {
                    this.dialog.error('Select Atleast One District','Required');
                    return;   
                    
                }
                
                this.dialog.save_user('').then((result) => {
                    
                    this.userData = this.AddUserForm.value;
                    
                    this.userData.module_data = this.module_data;
                    
                    this.userData.module_data = this.module_data;
                    
                    if(result === true) {
                        
                        this.apiHit.PostRequest( 
                            {

                                'user_id': this.data.id,
                                'userData': this.userData, 
                                'stateData': this.selected_state, 
                                'districtdata': this.selected_district
                            
                            }, "User/add_user").subscribe((result => {
                            
                            if(result['userData'] == 'Success') {
                                
                                this.dialog.success('Success', 'User Successfully Added');
                                this.router.navigate(['user-list']);
                                
                            }
                            else if(result['userData'] == 'EmailExists'){

                                this.dialog.error('error', 'Email Is Already Exists');
                                
                            }
                            else{
                                
                                this.dialog.error('error', 'Something Went Wrong');
                                
                            }
                            
                            setTimeout(() => {
                                
                                this.loader = '';
                                
                            }, 700);
                            
                        }));
                        
                    };
                    
                })
            }
            
            
            if(this.AddUserForm.value.userType== 'approval_user' && this.AddUserForm.value.approvalType=='zsm'){
                
                if(this.selected_state== '' ) {
                    this.dialog.error('Select Atleast One State','Required');
                    return;
                }
                //  if( this.selected_district == '') {
                //     this.dialog.error('Select Atleast One District','Required');
                //     return;
                // }
                
                this.dialog.save_user('').then((result) => {
                    
                    this.userData = this.AddUserForm.value;
                    
                    this.userData.module_data = this.module_data;
                    
                    // this.userData.module_data = this.module_data;
                    
                    if(result === true) {
                        
                        this.apiHit.PostRequest( 
                            {

                                'user_id'       : this.data.id,
                                'userData'      : this.userData, 
                                'stateData'     : this.selected_state, 
                                'districtdata'  : this.selected_district
                            
                            }, "User/add_user").subscribe((result => {

                            console.log(result);
                            
                            if(result['userData'] == 'Success') {
                                
                                this.dialog.success('Success', 'User Successfully Added');
                                this.router.navigate(['user-list']);
                                
                            }
                            else if(result['userData'] == 'EmailExists'){

                                this.dialog.error('error', 'Email Is Already Exists');
                                
                            }
                            else{
                                
                                this.dialog.error('error', 'Something Went Wrong');
                                
                            }
                            
                            setTimeout(() => {
                                
                                this.loader = '';
                                
                            }, 700);
                            
                        }));
                        
                    };
                    
                })
            }
            if(this.AddUserForm.value.userType== 'sales_user'){
                //  if( this.selected_district == '') {
                //     this.dialog.error('Select Atleast One District','Required');
                //     return;
                // }
                
                this.dialog.save_user('').then((result) => {
                    
                    this.userData = this.AddUserForm.value;
                    
                    
                    // this.userData.module_data = this.module_data;
                    
                    if(result === true) {
                        
                        this.apiHit.PostRequest( 
                            {

                                'user_id'       : this.data.id,
                                'userData'      : this.userData, 
                            
                            }, "User/add_user").subscribe((result => {

                            console.log(result);
                            
                            if(result['userData'] == 'Success') {
                                
                                this.dialog.success('Success', 'User Successfully Added');
                                this.router.navigate(['user-list']);
                                
                            }
                            else if(result['userData'] == 'EmailExists'){

                                this.dialog.error('error', 'Email Is Already Exists');
                                
                            }
                            else{
                                
                                this.dialog.error('error', 'Something Went Wrong');
                                
                            }
                            
                            setTimeout(() => {
                                
                                this.loader = '';
                                
                            }, 700);
                            
                        }));
                        
                    };
                    
                })
            }
            
        }
        
        //Submit User Function END
        
        updateUser() {
            
            
            this.userData = this.AddUserForm.value;
            if(this.AddUserForm.value.userType== 'System_user'){
                this.userData.moduleData = this.moduleData;
            }
            this.userData.id = this.userDetailData['id'];
            console.log(this.userData);
            
            this.dialog.save_user('').then((result) => {
                
                if(result === true) {
                    
                    this.apiHit.PostRequest( {'userData' : this.userData }, "User/update_user").subscribe((result => {
                        
                        console.log(result);
                        
                        if(result['userData'] == 'Success'){
                            
                            this.dialog.success('Success', 'User Successfully Updated');
                            this.router.navigate(['user-detail', (this.userData.id)]);
                            
                        }
                        else if(result['userData'] == 'Success'){
                            this.dialog.success('error', 'Duplicate Entry Of Mobile No And Email');
                        }
                        else{
                            
                            this.dialog.error('error', 'error');
                            
                        }
                        
                        setTimeout(() => {
                            
                            this.loader = '';
                            
                        }, 700);
                        
                    }));
                };
            })
            
        }
        
        
        
        
        updateUser1() {
            

            this.userData = this.AddUserForm.value;
            this.userData.moduleData = this.module_data;
            this.userData.id = this.userDetailData['id'];
            console.log(this.userData);
            this.zsmData.userData  = this.userData;
            this.zsmData.stateData  = this.selected_state;
            if(this.AddUserForm.controls.approvalType.value == 'asm'||this.AddUserForm.controls.approvalType.value == 'zsm'){
                this.zsmData.districtdata  = this.selected_district;
                if(this.selected_district.length==0){
                    this.toast.openError('District is Mandatory', '');
                    return;
                }
            }
            console.log(this.zsmData);
            
            this.dialog.save_user('').then((result) => {
                
                if(result === true) {
                    
                    this.apiHit.PostRequest( this.zsmData, "User/update_user").subscribe((result => {
                        
                        console.log(result);
                        
                        if(result['userData'] == 'Success'){
                            
                            this.dialog.success('Success', 'User Successfully Updated');
                            this.router.navigate(['user-detail', (this.userData.id)]);
                            
                        }
                        else{
                            
                            this.dialog.error('error', 'error');
                            
                        }
                        
                        setTimeout(() => {
                            
                            this.loader = '';
                            
                        }, 700);
                        
                    }));
                };
            })
            
        }
        
        
        enterPin(formData) {
            
            console.log(this.show_modules_right);
            
            console.log(this.AddUserForm);
            if(formData.controls.pincode.value.length==6){  
                
                this.apiHit.PostRequest1( {
                    
                    'pincode': formData.controls.pincode.value                
                }, "AppMechanicDetails/getPincodeDetails").subscribe((result => {
                    
                    
                    console.log(result);
                    
                    this.stataData = result['pincodeDetails'].state;
                    
                    
                    setTimeout(() => {
                        this.selectedDistrict= result['pincodeDetails'].district;
                        this.AddUserForm.controls.district_name.setValue(this.selectedDistrict);
                        console.log(this.AddUserForm);
                        
                        
                        
                    }, 1000);
                    
                    
                    console.log(this.selectedDistrict);
                    
                    this.AddUserForm.controls.state_name.setValue(this.stataData);
                    this.AddUserForm.controls.district_name.setValue(this.selectedDistrict);
                    
                    console.log(this.AddUserForm);
                    
                    this.districtList(this.stataData)
                }
                ));
            }
            
        }
        
        
        //Get Module List Through API Function Start
        
        moduleRightsList() {
            
            this.loader = 1;
            
            this.apiHit.PostRequest( '', "User/get_modules_list").subscribe((result => {
                
                console.log(result);
                
                this.moduleData = result['moduleList']
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Get Module List Through API Function END
        
        
        
        moduleRightsLists() {
            
            this.loader = 1;
            
            this.apiHit.PostRequest( '', "User/get_approval_modules_list").subscribe((result => {
                
                console.log(result);
                
                this.module_data = result['moduleList']
                console.log(this.module_data);
                
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        
        
        //Select All Function Start
        
        selectAll(event:any,index:any) {
            
            setTimeout(() => {
                
                console.log(this.moduleData[index].all);
                console.log(index);
                
                if(this.moduleData[index].all == true)
                {
                    this.moduleData[index]['view'] = true;
                    this.moduleData[index]['add'] = true;
                    this.moduleData[index]['edit'] = true;
                    this.moduleData[index]['delete'] = true;
                    this.moduleData[index]['all'] = true;
                }
                
                else{
                    this.moduleData[index]['view'] = false;
                    this.moduleData[index]['add'] = false;
                    this.moduleData[index]['edit'] = false;
                    this.moduleData[index]['delete'] = false;
                    this.moduleData[index]['all'] = false;
                    
                }
                
            }, 100);
            
        }
        //Select All Function End
        
        
        selectAllRetailer(event:any,index:any) {
            
            setTimeout(() => {
                
                console.log(this.module_data[index].all);
                console.log(index);
                
                if(this.module_data[index].all == true)
                {
                    this.module_data[index]['view'] = true;
                    this.module_data[index]['add'] = true;
                    this.module_data[index]['edit'] = true;
                    this.module_data[index]['delete'] = true;
                    this.module_data[index]['all'] = true;
                }
                
                else{
                    this.module_data[index]['view'] = false;
                    this.module_data[index]['add'] = false;
                    this.module_data[index]['edit'] = false;
                    this.module_data[index]['delete'] = false;
                    this.module_data[index]['all'] = false;
                    
                }
                
            }, 100);
            
        }
        
        btn_selected_btn() { 
            
            this.search_btn_selected= 1;
            
        }
        
        
        // Active View Function Start
        
        activeView(event:any,index:any) { 
            
            setTimeout(() => {
                
                console.log(event);
                console.log(index);
                this.moduleData[index]['view'] = true;
                
                if(event == 0){
                    
                    this.moduleData[index]['view'] = true;
                    if(this.moduleData[index]['view'] == true && this.moduleData[index]['add'] == true && this.moduleData[index]['edit'] == true && this.moduleData[index]['delete'] == true){
                        
                        console.log(this.moduleData[index]['all']);
                        this.moduleData[index]['all'] = true;
                        console.log(this.moduleData[index]['all']);
                        
                    }
                    
                }    
                else{
                    
                    this.moduleData[index]['all'] = false;
                    if(this.moduleData[index]['add'] == true || this.moduleData[index]['edit'] == true || this.moduleData[index]['delete'] == true){
                        
                        this.moduleData[index]['view'] = true;
                        
                    }else{
                        
                        this.moduleData[index]['view'] = false;
                        
                    }
                    
                }
                
            }, 100);
            
            
        }
        // Active View Function END
        
        activeView2(event:any,index:any) {
            
            console.log(event);
            console.log(index);
            
            setTimeout(() => {
                
                console.log(this.moduleData[index]['view']);
                
                if(this.moduleData[index]['add'] == true || this.moduleData[index]['edit'] == true || this.moduleData[index]['delete'] == true || this.moduleData[index]['all'] == true){
                    console.log('in if condition');
                    console.log(this.moduleData[index]['view']);
                    this.moduleData[index]['view'] = 1;
                    console.log(this.moduleData[index]['view']);
                    
                    
                }
                
            }, 500);
            
        }
        
        
        activeViewRetailer1(event:any,index:any) { 
            
            setTimeout(() => {
                
                console.log(event);
                console.log(index);
                this.module_data[index]['view'] = true;
                
                if(event == 0){
                    
                    this.module_data[index]['view'] = true;
                    if(this.module_data[index]['view'] == true && this.module_data[index]['add'] == true && this.module_data[index]['edit'] == true && this.module_data[index]['delete'] == true){
                        
                        console.log(this.module_data[index]['all']);
                        this.module_data[index]['all'] = true;
                        console.log(this.module_data[index]['all']);
                        
                    }
                    
                }    
                else{
                    
                    this.module_data[index]['all'] = false;
                    if(this.module_data[index]['add'] == true || this.module_data[index]['edit'] == true || this.module_data[index]['delete'] == true){
                        
                        this.module_data[index]['view'] = true;
                        
                    }else{
                        
                        this.module_data[index]['view'] = false;
                        
                    }
                    
                }
                
            }, 100);
            
            
        }
        
        activeViewRetailer(event:any,index:any) {
            
            console.log(event);
            console.log(index);
            
            setTimeout(() => {
                
                console.log(this.module_data[index]['view']);
                
                if(this.module_data[index]['add'] == true || this.module_data[index]['edit'] == true || this.module_data[index]['delete'] == true || this.module_data[index]['all'] == true){
                    console.log('in if condition');
                    console.log(this.module_data[index]['view']);
                    this.module_data[index]['view'] = 1;
                    console.log(this.module_data[index]['view']);
                    
                    
                }
                
            }, 500);
            
        }
        
        
        //State List Fetch From Database Through API  Start
        
        assign_state_list() {
            
            this.loader=1;
            
            this.apiHit.PostRequest( '', "User/stateList").subscribe((result => {
                
                console.log(result);
                
                this.stateDatas = result['List']['stateList'];
                
                for (let i = 0; i < this.stateDatas.length; i++) 
                {
                    for (let j = 0; j < this.selected_state.length; j++) 
                    {
                        if(this.stateDatas[i]['state'] == this.selected_state[j])
                        {
                            this.stateDatas[i]['checked'] = true;
                        }
                        
                    }
                }
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
        }
        
        on_select_state(event, state) {
            
            console.log(state);
            
            if(event.checked== true){
                this.selected_state.push(state)
                console.log(this.selected_state);
            }
            else{
                let isExist = this.selected_state.findIndex(row=>row== state )
                if(isExist!=-1){
                    this.selected_state.splice(isExist,1)
                }
                console.log(this.selected_state);
            }
            
            this.loader=1;

            if(this.selected_state.length > 0){

                this.apiHit.PostRequest({'stateName': this.selected_state}, "User/stateList").subscribe((result => {
                    
                    this.districtListData= result['List']['stateList']
                    
                    console.log(this.districtListData);
                    
                    for (let i = 0; i < this.districtListData.length; i++) 
                    {
                        
                        for (let j = 0; j < this.selected_district.length; j++) 
                        {
                            if(this.districtListData[i]['district'] == this.selected_district[j])
                            {
                                this.districtListData[i]['checked'] = true;
                            }
                        }
                        
                    }

                    for (let i = 0; i < this.stateDatas.length; i++) 
                    {
                        for (let j = 0; j < this.selected_state.length; j++) 
                        {
                            if(this.stateDatas[i]['state'] == this.selected_state[j])
                            {
                                this.stateDatas[i]['checked'] = true;
                            }
                            
                        }
                    }
                    
                    
                    setTimeout(() => {
                        this.loader = '';
                        
                    }, 700);
                    
                }));
            }
            else{
                this.districtListData = [];
                this.loader = '';

            }
            
        }
        

        on_select_district(district, event) {
            
            
            if(event.checked== true){
                
                this.selected_district.push( district )
                console.log(this.selected_district);
                
            }
            
            else{
                
                let isExist = this.selected_district.findIndex(row=>row== district )
                
                if(isExist!=-1){
                    
                    this.selected_district.splice(isExist,1)
                }
                console.log(this.selected_district);
                
            }
            
        }
        
        
        stateList() {
            
            console.log(this.AddUserForm);
            
            this.loader=1;
            
            this.apiHit.PostRequest( '', "Distributor/state_list").subscribe((result => {
                
                this.stateData = result['state_list']['state_name'];
                
                console.log(this.stataData);
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //State List Fetch From Database Through API  End
        
        
        selectedSalesExecutiveType(event) {
            
            console.log(event);
            if(event == 'asm' || event == 'zsm'){
                this.moduleRightsLists();
            }
            console.log(this.AddUserForm);
            
        }
        
        
        selectUserType(event) { 
            
            console.log(event);
            if(event == 'System_user'){
                this.moduleRightsList();
            }
            this.show_modules_right = event
            this.AddUserForm.controls.approvalType.setValue('');
            console.log(this.AddUserForm);
            
        }
        
        //District List Fetch From Database Through API  Start
        
        districtList(district) {
            
            console.log(this.AddUserForm);
            
            this.loader=1;
            
            this.apiHit.PostRequest( {'state' : district}, "Distributor/district_list").subscribe((result => {
                
                console.log(result);
                
                this.districtData = result['district_list']['district_name'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //District List Fetch From Database Through API  End
        
        
        districtSearch() {
            
            this.search_btn= 1;
            
        }
        
        //Check Mobile No Duplicacy Function Start
        
        checkMobileNoDuplicacy() {
            
            console.log('in mobile duplicay');
            //   return;
            
            if(this.AddUserForm.value.mobile_no.length == 10){
                console.log(this.activateRoute['params']['_value']['id']);
                
                this.apiHit.PostRequest( {
                    
                    "type"      :   "user",
                    "id"        :   this.activateRoute['params']['_value']['id'],
                    "mobileNo"  :   this.AddUserForm.value.mobile_no,
                    "user_type" : this.AddUserForm.value.userType
                    
                }, "Distributor/check_user").subscribe((result => {
                    
                    if(result['exists'] == 1){
                        console.log('in if constion mobile api');
                        this.AddUserForm.controls.mobile_no.setValue('');
                        this.dialog.error('error', 'Mobile Already Exists');
                    }
                    
                    setTimeout(() => {
                        
                        this.loader = '';
                        
                    }, 700);
                    
                }));
                
            }
            
        }
        //Check Mobile No Duplicacy Function End
        
        
        // KeyPress Function  Start
        
        OnlyNumber(event: any) {
            
            const pattern = /[0-9\'']/;
            let inputChar = String.fromCharCode(event.charCode);
            
            if (event.keyCode != 8 && !pattern.test(inputChar)) { 
                
                event.preventDefault(); 
                
            }
            
        }
        // KeyPress Function  End
        
        
        // Back Button Function  Start
        
        backClicked() {
            
            this._location.back();
            
        }
        // Back Button Function  End
        
        
        
    }