<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Distributor</h2>
                    <p>{{totalDistributor}} Distributor Available</p>
                </div>
                <div class="left-auto df" >
                    <div class="cs-btn mt0"  *ngFor=" let datas of apiHit.userLoginData.data.moduleData">
                        <a  *ngIf="datas.module_name== 'Distributor' && datas.add==1" mat-button
                            routerLink="../distributor-add">Add new</a>
                    </div>
                    <div class="cs-action" style="padding-right: 3px;">
                        <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                            (click)="distributorList(1)"><i class="material-icons">search</i></a>
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                            (click)="refreshData()"><i
                                class="material-icons">settings_backup_restore</i></a>
                    </div>
                </div>
                
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date</div>
                            <div class="div_cell mmw80">Created By</div>
                            <div class="div_cell mmw80">Code</div>
                            <div class="div_cell mw130">Company Name</div>
                            <div class="div_cell mmw180">Contact Person</div>
                            <div class="div_cell mmw100">Mobile</div>
                            <div class="div_cell mmw180">State</div>
                            <div class="div_cell mmw180">District</div>
                            <div class="div_cell mmw90 text-center">Retailer</div>
                            <div class="div_cell mmw110">MSL</div>
                            <div class="div_cell mmw90">Credit Limit</div>
                            <div class="div_cell mmw90">Outstanding</div>
                            <div class="div_cell mmw100 text-right">Wallet Balance</div>
                            <div class="div_cell mmw100 text-right">Scheme Balance</div>
                            <div class="div_cell mmw100 text-right">Customer Category</div>

                            <div class="div_cell text-center mmw160 af-bdr-right">Action</div>
                            <div class="div_cell text-center mmw120" *ngIf="access_level == '1'">Switch Status</div>


                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">

                                    <mat-form-field (click)="picker.open()">
                                        <input matInput [matDatepicker]="picker" [max]="date" placeholder="Search"
                                            [(ngModel)]="filterSearch.date" (dateChange)="onDate($event)">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>

                                    <!-- <mat-form-field (click)="picker.open()">
                                        <input  matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="date" 
                                        [(ngModel)]="filterSearch.date" (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field> -->
                                </div>
                            </div>
                            <div class="div_cell mmw80">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_name"
                                        [(ngModel)]="filterSearch.created_name" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw80">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_code"
                                        [(ngModel)]="filterSearch.dr_code" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_name"
                                        [(ngModel)]="filterSearch.dr_name" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="contact_name"
                                        [(ngModel)]="filterSearch.contact_name" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mobile_no"
                                        [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="state_name"
                                        [(ngModel)]="filterSearch.state_name" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="district_name"
                                        [(ngModel)]="filterSearch.district_name" (keyup.enter)="distributorList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90 ">
                            </div>
                            <div class="div_cell mmw110 ">
                            </div>
                            <div class="div_cell mmw90 ">
                            </div>
                            <div class="div_cell mmw90 ">
                            </div>
                            <div class="div_cell mmw100">
                            </div>
                            <div class="div_cell mmw100">
                            </div>
                            <div class="div_cell mmw100">
                            </div>
                            <div class="div_cell text-center mmw160 af-bdr-right">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="distributorList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                            <div class="div_cell mmw120" *ngIf="access_level == '1'">
                                <div class="filter-data">
                                    <mat-form-field class="cs-input select-input">
                                        <mat-select name="status" #status="ngModel" [(ngModel)]="filterSearch.status"
                                          (selectionChange)="distributorList('')">
                                          <mat-option value="">All</mat-option>
                                          <mat-option value="active">Active</mat-option>
                                          <mat-option value="deactive">Deactive </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                </div>
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <!-- Loop Data -->
                        <main *ngFor="let data of distributorData; let i=index">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">{{data.date_created | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name | titlecase)
                                :'N/A'}}</div>
                            <div class="div_cell mmw80">{{data.dr_code ? data.dr_code:'N/A'}}</div>
                            <div class="div_cell mw130"><a class="link-btn" mat-flat-button
                                    routerLink="../distributor-detail/{{data.id}}">{{data.dr_name ?
                                    (data.dr_name | titlecase) :'N/A'}}</a></div>
                            <div class="div_cell mmw180">{{data.contact_name ? (data.contact_name | titlecase ):'N/A'}}</div>
                            <div class="div_cell mmw100">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                            <div class="div_cell mmw180">{{data.state_name ? (data.state_name | titlecase):'N/A'}}</div>
                            <div class="div_cell mmw180">{{data.district_name ? (data.district_name | titlecase):'N/A'}}</div>
                            <div class="div_cell mmw90  ac">{{data.retailerCount ? data.retailerCount : '0' }}</div>
                            <div class="div_cell mmw110  ac" [ngClass]="{'red-clr':data.total_stock_alert_count >0}">
                                {{data.total_stock_alert_count ? data.total_stock_alert_count : '0'}}
                            </div>
                            <div class="div_cell mmw90  ac">₹
                                {{data.credit_limit ? data.credit_limit : '0'}}
                                <!-- {{Number(data.credit_limit).toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')} -->
                            </div>
                            <div class="div_cell mmw90  ac">₹ {{data.outstanding ? data.outstanding : '0'}}</div>
                            <div class="div_cell mmw100 afe">{{data.wallet_balance ? data.wallet_balance : '0'}}</div>
                            <div class="div_cell mmw100 afe">{{data.scheme_wallet ? data.scheme_wallet : '0'}}</div>
                            <div class="div_cell mmw100 afe">{{data.category ? data.category : 'N/A'}}</div>

                            <div class="div_cell ac mmw160">
                                <div class="cs-action">
                                    <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                        <button class="ac-green with-text" *ngIf=" datas.module_name== 'Distributor' && datas.edit==1"
                                            [disabled]="data.wallet_balance=='0' && data.scheme_wallet =='0'"
                                            (click)="payNowModel(data)" matRipple matTooltip="Pay Now"
                                            matTooltipPosition="below">Points Settlement</button>
                                        <a class="ac-red" *ngIf=" datas.module_name== 'Distributor' && datas.delete==1"
                                            (click)="deleteDistributorData(data.id)" matRipple matTooltip="Delete"
                                            matTooltipPosition="below"><i class="material-icons">delete_sweep</i></a>
                                    </ng-container>
                                </div>
                            </div>

                            <div  class="div_cell ac mmw120" *ngIf="access_level == '1'">
                                <mat-slide-toggle color="accent" [name]="'status'+i" [(ngModel)]="data.activity_status"
                                (change)="updateStatus(i,data.id,$event)">
                              </mat-slide-toggle>
                            </div>
                        </main>
                        <!-- Loop Data -->
                        <app-not-found *ngIf="distributorData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100 afe">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100 afe">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell ac mmw160">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell ac mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>
                </section>
            </div>

            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="distributorData.length > 0"
                            (click)="exportDistributorDataInExcel()" class="ml10"><img src="assets/img/excel.png">
                            Download Excel</a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>