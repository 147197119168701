import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-company-dispatch-detail',
  templateUrl: './company-dispatch-detail.component.html'
})
export class CompanyDispatchDetailComponent implements OnInit {
  
  @ViewChild('focusInput') inputEl: ElementRef;
  
  orderType:any ='order';
  id:any;
  data:any ={};
  couponNumber:any= {};
  savingFlag:boolean = false;
  userData:any;
  invoice_detail:any={}
  billing_list:any=[];
  dispatchItem:any =[];
  payment_list:any=[];
  dispatch_coupon:any=[];
  dispatch_detail:any ={};
  skLoading:boolean = false;
  
  
        
constructor(private _location: Location, public rout: Router, public route:ActivatedRoute, public alertDialog: DialogService, public service: DatabaseService,) { 
  this.userData = this.service.userLoginData.data;
  console.log(this.userData);
  console.log(this.userData.id);

  
  this.route.params.subscribe( params => {
    this.id = params.id;
  });
}
  
  ngOnInit(): void {
    this.billDatadetail()
  }
  
  ngAfterViewInit() {
    setTimeout(() => this.inputEl.nativeElement.focus());
  }
  
  
  
  // Back Button Function  Start
  backClicked() {
    
    this._location.back();
    
  }
  // Back Button Function  End
  billDatadetail()
  {
    this.skLoading = true;
    this.service.PostRequest({'bill_id': this.id},"Dispatch/tallyInvoiceCreditBillingDetail")
    .subscribe((result=>{
      if(result['statusCode']==200){
        this.invoice_detail=result['invoice_bill'];
        this.dispatch_detail=result['dispatch_data'];
        this.dispatchItem=result['invoice_bill_item'];
        // this.dispacthItemDetail();
        // for (let i = 0; i < this.billing_list.length; i++) {
        //   this.dispatchItem.push({'Item_Code':this.billing_list[i]['Item_Code'], 'Description':this.billing_list[i]['Description'], 'Qty':this.billing_list[i]['Qty'], 'dispatch_qty':0, })
        // }
        this.payment_list=result['payment_list'];
        this.dispatch_coupon=result['all_dispatch'];
        this.getdispatchDetail();
        this.skLoading = false;
      }else{
        this.skLoading = false;
        this.alertDialog.error('', result['statusMsg']);
      }
    }))
  }


  
    
    
  couponList:any =[];
  temArray:any =[];
  dispatchQTY:any = 0;
  dispatchInvoice:any = 0;
  dispatch_status:any ='Pending';
  temCoupon:any =[];
  checkCoupon(number)
  {
    if(number.length == 17){
      if(number == undefined){
        this.alertDialog.error('', "Enter coupon code number");
        return;
      }
      if(number == ''){
        this.alertDialog.error('', "Enter coupon code number");
        return;
      }
      
      if(this.temCoupon != '')
      {
        let temData = number;
        let index = this.temCoupon.findIndex(row => row.coupon_no == temData);
        if (index != -1) {
          if(this.temCoupon[index].coupon_no === temData){
            this.clearValue();
            this.alertDialog.error('', "Coupon code already exists");
            return
          }
          else{
            this.clearValue();
          }
        }
        else{
          this.clearValue();
          this.temCoupon.push({'coupon_no':number, 'status':'Pending'});
          this.dispatchItems(number);
          
        }
      }
      else{
        this.clearValue();
        this.temCoupon.push({'coupon_no':number, 'status':'Pending'});
        this.dispatchItems(number);
      }
      
    }
  }
  
  getdispatchDetail(){
    this.service.PostRequest({'created_by_name':this.userData.name, 'created_by_id':this.userData.id, 'ODN_Number':this.invoice_detail.ODN_Number},'Dispatch/checkCouponCodeCheck').subscribe((result)=>
    {
      if (result['statusCode'] == 200){
        this.dispatchQTY= result['dispatch_qty'];
        this.dispatchInvoice= result['invoice_qty'];
        
        if(this.dispatchQTY == this.dispatchInvoice){
          this.dispatch_status = 'Dispatched';
        };  
        this.dispatchItem = result['dispatch']['dispatch_item'];
        console.log(this.dispatchItem);
        
      }
      else{
        this.alertDialog.error('', result['statusMsg']);
        this.couponNumber =  {};
      }
    }); 
  }
  
  
  
  
  dispatchedCoupon:any ={};
  
  dispatchItems(number){
    this.service.PostRequest({'coupon_code':number, 'dispatch_status':this.dispatch_status, 'dr_code':this.invoice_detail.customer_code, 'created_by_name':this.userData.name, 'created_by_id':this.userData.id,  'ODN_Number':this.invoice_detail.ODN_Number},'Dispatch/checkCouponCodeCheck').subscribe((result)=>
    {
      if (result['statusCode'] == 200){
        console.log(result);
        this.dispatchedCoupon= result['coupon_code'];
        this.dispatchQTY= result['dispatch_qty'];
        this.dispatchInvoice= result['invoice_qty'];
        if(this.dispatchQTY == this.dispatchInvoice){
          this.dispatch_status = 'Dispatched';
          this.rout.navigate(['dispatch']);
        }
        
        this.dispatchItem = result['dispatch'];
        console.log(this.dispatchItem);
        
        
        if(this.dispatchedCoupon){
          for (let i = 0; i < this.temCoupon.length; i++) {
            if(this.temCoupon[i]['coupon_no'] == this.dispatchedCoupon){
              if(result['statusMsg'] != 'Success' ){
                this.alertDialog.error('', result['statusMsg']);
              }
              
              this.temCoupon[i]['status']  = result['statusMsg'];
            }
          }
        }
        
      }
      else{
        if(result['coupon_code']){
          this.dispatchedCoupon= result['coupon_code'];
          for (let i = 0; i < this.temCoupon.length; i++) {
            if(this.temCoupon[i]['coupon_no'] == this.dispatchedCoupon){
              if(result['statusMsg'] != 'Success' ){
                this.alertDialog.error('', result['statusMsg']);
              }
              this.temCoupon[i]['status']  =result['statusMsg'];
            }
          }
        }
        else{
          if(result['statusMsg'] == 'Coupon not exist.'){
            for (let i = 0; i < this.temCoupon.length; i++) {
              if(this.temCoupon[i]['coupon_no'] == number){
                this.temCoupon[i]['status'] = result['statusMsg'];
              }
            }
          }
          this.alertDialog.error('', result['statusMsg']);
        }
        this.couponNumber =  {};
      }
    }) 
  }
  
  
  clearValue(){
    this.couponNumber =  {};
  }
  
  
  
  dispacthItemDetail(){
    this.service.PostRequest({'ODN_Number':this.invoice_detail.ODN_Number},'Dispatch/dispatchedCouponList').subscribe((result)=>
    {
      if (result['statusCode'] == 200){
        this.couponList= result['result'];
        
      }
      else{
        this.alertDialog.error('', result['statusMsg']);
      }
    }) 
  }
  
}
