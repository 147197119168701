<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name">
                    <h2>Incentive Detail</h2>
                    <p>{{incentiveDetailData.retailer_name ? incentiveDetailData.retailer_name : 'N/A'}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <ng-template mat-tab-label *ngIf="loader">
                <div class="sk-loading">&nbsp;</div>
            </ng-template>
            <ng-template mat-tab-label *ngIf="!loader">
                <mat-icon class="example-tab-icon">info</mat-icon>
                Detail
            </ng-template>
            <div class="row">
                <div class="col s12 m8">
                    <div class="cs-column" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Incentive Information</h2>
                        </div>

                        <div class="cs-form ">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Txn Id</span>
                                            <p>#TXN-{{incentiveDetailData.id ? incentiveDetailData.id:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Date From</span>
                                            <p>
                                                {{incentiveDetailData.date_from ? (incentiveDetailData.date_from | date: 'd MMM y') : 'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Date To</span>
                                            <p>
                                                {{incentiveDetailData.date_to ? (incentiveDetailData.date_to | date: 'd MMM y') : 'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box" *ngIf="data_val?.login_type != 'distributor'">
                                            <span>Distributor Name</span>
                                            <p>{{incentiveDetailData.distributor_name ? incentiveDetailData.distributor_name:'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Retailer State</span>
                                            <p>{{incentiveDetailData.retailer_state ?
                                                incentiveDetailData.retailer_state:'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <div class="cs-box" *ngIf="data_val?.login_type != 'distributor'">
                                            <span>Asm</span>
                                            <p>{{incentiveDetailData.asm ?
                                                incentiveDetailData.asm:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Segment</span>
                                            <p>{{incentiveDetailData.segment ? incentiveDetailData.segment:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Total Item</span>
                                            <p>{{incentiveDetailData.order_count ? incentiveDetailData.order_count:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Total Item Qty</span>
                                            <p>{{incentiveDetailData.item_count ? incentiveDetailData.item_count:'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Total Points</span>
                                            <p>{{incentiveDetailData.earn_points ?
                                                incentiveDetailData.earn_points:'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    <!-- Skeleton Loading Data Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loading Data End -->


                    <div class="cs-column mt10" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Item Information</h2>
                        </div>

                        <div class="cs-table">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mmw90">Order No</div>
                                        <div class="div_cell mmw90">Segment</div>
                                        <div class="div_cell mmw110">Brand</div>
                                        <div class="div_cell mw140">Product Code</div>
                                        <div class="div_cell mmw90">Qty</div>
                                        <!-- <div class="div_cell mmw70">Points</div> -->
                                    </main>
                                </section>
                            </figure>
                            <!-- Table Head End -->

                            <section class="td_cell">
                                <main *ngFor="let data of incentiveDetailData.item_detail; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw90">#AFF-{{data.order_id ?
                                        data.order_id:'N/A'}}</div>
                                    <div class="div_cell mmw90">{{data.segment ? data.segment:'N/A'}}</div>
                                    <div class="div_cell mmw110">{{data.category ? data.category:'N/A'}}</div>

                                    <div class="div_cell mw140">{{data.product_code ?
                                        data.product_code:'N/A'}}</div>
                                    <div class="div_cell mmw90">{{data.qty ?
                                        data.qty:'N/A'}}</div>
                                    <!-- <div class="div_cell mmw70">{{data.category ?
                                        data.category:'N/A'}}</div> -->
                                </main>
                                <!-- Loop Data -->
                            </section>
                        </div>
                    </div>
                </div>



                <div class="col s12 m4">
                    <ng-container *ngIf="!loader">


                        <div class="cs-column mb10">
                            <div class="cs-head">
                                <h2>Incentive Status Information</h2>
                            </div>


                            <div class="cs-form ">
                                <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div class="cs-box">
                                                <span>Status</span>
                                                <p *ngIf="incentiveDetailData.status == 'Pending'" class="yellow-clr">{{incentiveDetailData.status ?
                                                    incentiveDetailData.status :'N/A'}}
                                                </p>
                                                <p *ngIf="incentiveDetailData.status != 'Pending'" class="green-clr">{{incentiveDetailData.status ?
                                                    incentiveDetailData.status :'N/A'}}
                                                </p>
                                            </div>
                                            <div class="cs-box">
                                                <span>Last Updated Date</span>
                                                <p>
                                                    {{incentiveDetailData.last_updated_Date ? (incentiveDetailData.last_updated_Date | date: 'd MMM y') : 'N/A'}}
                                                </p>
                                            </div>
                                            <div class="cs-box">
                                                <span>Last Updated By</span>
                                                <p>{{incentiveDetailData.last_updated_by ? incentiveDetailData.last_updated_by : 'N/A'}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                    </ng-container>
                    <!-- Sk Data Loading Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skDataTwo">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skDataTwo">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- Sk Data Loading End -->
                </div>
            </div>



        </div>
    </div>
</div>