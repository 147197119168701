import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-scheme-detail',
  templateUrl: './scheme-detail.component.html',
})
export class SchemeDetailComponent implements OnInit {


  schemeDetailData: any;
  loader: any;
  skeleton: any = [];
  skData: any = [];
  skDataTwo: any = [];
  date: any;
  matTabValue: any;


  constructor(

    public activateRoute: ActivatedRoute,
    private apiHit: DatabaseService,
    private _location: Location,
    public dialog: MatDialog,
    public dialog2: DialogService,

  ) {

    console.log(activateRoute['params']['_value']);
    this.skeleton = new Array(10);
    this.skData = new Array(4)
    this.getSchemeDetails();
    this.skDataTwo = new Array(2);
    this.date = new Date();

  }

  ngOnInit(): void {


  }


  // Back Button Function  Start

  backClicked() {

    this._location.back();

  }
  // Back Button Function  End




  //Get Distributor Detail Through API Function Start

  getSchemeDetails() {

    this.loader = 1;

    this.apiHit.PostRequest({ scheme_id: this.activateRoute['params']['_value']['id'] }, "SchemeController/showDetailScheme").subscribe((result => {

      console.log(result);
      if (result['SchemesList']['status'] == 'success') {

        this.schemeDetailData = result['SchemesList']['scheme_details'][0];
        this.loader = '';

      }

      else {
        this.dialog2.error('error', 'Something Went Wrong');
      }


    }));

  }
  //Get Distributor Detail Through API Function End


}