import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
  selector: 'app-partially-delivered',
  templateUrl: './partially-delivered.component.html',
})
export class PartiallyDeliveredComponent implements OnInit {

  data: any = [];
  loader: any;
  paginationData: any = {};
  pageNumber: any;
  skeleton: any = [];
  searchData: any = {};
  orderData: any = [];
  total: any = 0;
  totalTrans: any = 0;
  date: any;


  constructor(
    public apiHit: DatabaseService,
    public dialog: DialogService,

  ) {
    this.data = new Array(2);
    this.skeleton = new Array(10);
    this.date = new Date();
    this.pageNumber = 1;
    this.getPartiallyDeliveredOrder(1);

  }

  ngOnInit(): void {
  }

  getPartiallyDeliveredOrder(target) {
    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }
    this.orderData = [];
    if(this.searchData?.date_created){
      this.searchData.date_created = moment(this.searchData.date_created).format('YYYY-MM-DD');
    }
    this.paginationData.search  = this.searchData;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;

    // return;
    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/show_pending_order_invoice_details").subscribe((response => {

      console.log(response['result']);
      if(response['result']['status'] == 'success'){
        this.orderData = response['result']['pending_order_invoice_details'];
        this.total = response['result'].totalPage;
        this.totalTrans = response['result'].total_rows;
        this.loader = '';
      }
      else {
        this.dialog.error('error', 'Please Check Your Internet Connectivity');
        this.loader = '';
      }

    }));
  }


    //Refresh Company Refresh List Function Start

    refreshData() {

      this.searchData = {};
      if (this.pageNumber == null) {
        this.pageNumber = 1;
      }
      this.getPartiallyDeliveredOrder(1);
    }
    //Refresh Company Refresh List Function End
  


  onChangePageNumber(type) {

    console.log(this.pageNumber);
    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {


          this.pageNumber -= 1;
          this.getPartiallyDeliveredOrder(2);

        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.getPartiallyDeliveredOrder(2);

        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          console.log('current else');

          this.pageNumber = this.total;
          this.getPartiallyDeliveredOrder(2);

        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.getPartiallyDeliveredOrder(2);

        }
        else if (this.pageNumber) {

          console.log('last else');

          this.getPartiallyDeliveredOrder(2);
        }

      }
    }, 100);


  }

  exportExcel() {

    this.loader = 1;
    this.paginationData.pagelimit = 0;
    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/EXPORT_pending_order_invoice_details").subscribe((result => {

      console.log(result);
      if (result['msg'] == 'Success') {
        this.getPartiallyDeliveredOrder(1);
        setTimeout(() => {

          window.open(this.apiHit.downloadURL + "uploads/Pending_order_invoice_details.csv");
          this.loader = '';

        }, 700);
      }

    }));
  }


}
