<div class="container-fluid">
    <div class="container">
        <section id="head">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below" routerLink="../order-list" routerLinkActive="active">
                <mat-icon>arrow_back</mat-icon>
            </a>
            
            <div class="module-name">
                <h2>Secondary Order</h2>
                <p>Add New</p>
            </div>
            
            <div class="left-auto df afe">
                <div class="indicates">
                    Indicates required fields ( <span>*</span> ) 
                </div>
            </div>
        </section>
        
        <div class="container-pd10-5">
            
            <form #reset="ngForm" #form="ngForm" [formGroup]="orderAdd" (ngSubmit)="addToList(reset)">
                
                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>basic Information</h2>
                            </div>
                            
                            
                            <div class="cs-form pb0">
                                
                                <div class="row row-mb0">
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Select Retailer <span class="sub">*</span></mat-label>
                                            <mat-select formControlName="dr_id" name="dr_id" id="dr_id">
                                                <ngx-mat-select-search formControlName="searchCtrl"
                                                [placeholderLabel]="'Search...'"
                                                [noEntriesFoundLabel]="'Not found'" name="search">
                                            </ngx-mat-select-search>
                                            <mat-option (click)="getDistributor(data.id)"  *ngFor="let data of retailerData | stringFilterBy:orderAdd.value.searchCtrl:'dr_name'" value="{{data.id}}">
                                                {{data.dr_name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-hint class="has-error" *ngIf="orderAdd.controls.dr_id.invalid && 
                                    orderAdd.controls.dr_id.touched">
                                </mat-hint>
                            </div>
                            
                            <div class="col s12 m3" *ngIf="this.usertype != 1">
                                <mat-form-field appearance="outline" class="cs-input" >
                                    <mat-label>Select Distributor <span class="sub">*</span></mat-label>
                                    <mat-select formControlName="distributor_id" name="distributor_id" id="distributor_id">
                                        <ngx-mat-select-search formControlName="searchCtrl"
                                        [placeholderLabel]="'Search...'"
                                        [noEntriesFoundLabel]="'Not found'" name="search">
                                    </ngx-mat-select-search>
                                    <mat-option
                                    *ngFor="let data of distributorData  | stringFilterBy:orderAdd.value.searchCtrl:'dr_name'" value="{{data.id}}">
                                    {{data.dr_name}} - {{data.dr_code}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                        <mat-hint class="has-error" *ngIf="orderAdd.controls.distributor_id.invalid && 
                        orderAdd.controls.distributor_id.touched">
                    </mat-hint>
                </div>
                <div class="col s12 m3" *ngIf="this.usertype == 1">
                    <mat-form-field appearance="outline" class="cs-input"  >
                        <mat-label>Distributor</mat-label>
                        <input matInput  formControlName="distributor_id" name="distributor_id" id="distributor_id" readonly >
                    </mat-form-field>
                </div>
            </div>
            
            <div class="row">
                
                <div class="col s12 m2">
                    <mat-form-field appearance="outline" class="cs-input">
                        <mat-label>Segment <span class="sub">*</span></mat-label>
                        <mat-select formControlName="segment" name="segment" id="segment">
                            <mat-option (click)="getCategory(data.segment)"
                            *ngFor="let data of segmentdata" value="{{data.segment}}">
                            {{data.segment}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-hint class="has-error" *ngIf="orderAdd.controls.segment.invalid && 
                orderAdd.controls.segment.touched">
            </mat-hint>
        </div>
        
        <div class="col s12 m2">
            <mat-form-field appearance="outline" class="cs-input">
                <mat-label>Brand <span class="sub">*</span></mat-label>
                <mat-select formControlName="category" name="category" id="category">
                    <mat-option (click)="getPartCodeNo(data.brand)"
                    *ngFor="let data of categoryName" value="{{data.brand}}">
                    {{data.brand}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-hint class="has-error" *ngIf="orderAdd.controls.category.invalid && 
        orderAdd.controls.category.touched">
    </mat-hint>
</div>

<div class="col s12 m4">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Part No <span class="sub">*</span></mat-label>
        <mat-select formControlName="part_code" name="part_code" id="part_code">
            <ngx-mat-select-search formControlName="searchCtrl"
            [placeholderLabel]="'Search...'"
            [noEntriesFoundLabel]="'Not found'" name="search">
        </ngx-mat-select-search>
        <mat-option
        *ngFor="let data of partNo  |stringFilterBy:orderAdd.value.searchCtrl:'part_code'"
        value="{{data.part_code}}">
        {{data.part_code}}
    </mat-option>
</mat-select>

</mat-form-field>
<mat-hint class="has-error" *ngIf="orderAdd.controls.part_code.invalid && 
orderAdd.controls.part_code.touched">
</mat-hint>
</div>

<div class="col s12 m2">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Qty. <span class="sub">*</span></mat-label>
        <input matInput placeholder="Type Here ..." formControlName="qty" 
        id="qty"  (keypress)="OnlyNumber($event)">
    </mat-form-field>
    <mat-hint class="has-error"
    *ngIf="orderAdd.controls.qty.invalid && 
    orderAdd.controls['qty']?.touched && !orderAdd.controls['qty']['errors']?.minlength ">
</mat-hint>
</div>

<div class="col s12 m2">
    <div class="cs-btn mt8">
        <button type="submit" [disabled]="orderAdd.invalid"
        mat-button>Add To List</button>
    </div>
</div>

</div>



</div>
</div>
</div>
</div>

</form>


<div class="row" *ngIf="finalOrderAddArray.length > 0">
    <div class="col s12 m8">
        <div class="cs-column">
            <div class="cs-head">
                <h2>Item Information</h2>
            </div>
            
            <div class="cs-table">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw90">Segment</div>
                            <div class="div_cell mmw90">Brand</div>
                            <div class="div_cell mw150 af-bdr-right">Part Code </div>
                            <div class="div_cell ac mmw90 ">Qty</div>
                            <div class="div_cell ac mmw90 ">Action</div>
                        </main>
                    </section>
                </figure>
                
                <section class="td_cell">
                    <main *ngFor="let data of finalOrderAddArray; let i=index">
                        <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                        <div class="div_cell mmw90">{{data.segment ? data.segment : 'N/A'}}</div>
                        <div class="div_cell mmw90">{{data.category ? data.category : 'N/A'}}</div>
                        <div class="div_cell mw150 af-bdr-right">{{data.part_code ? data.part_code : 'N/A'}}</div>
                        <div class="div_cell mmw90 af-bdr-right">{{data.qty ? data.qty : '0'}}</div>
                        <div class="div_cell ac mmw90">
                            <div class="cs-action">
                                <a class="ac-red" matRipplematTooltipPosition="below" (click)="deleteOrderItem(i)"><i
                                    class="material-icons">delete_sweep</i>
                                </a>
                            </div>
                        </div>
                    </main>
                </section>
            </div>
        </div>
    </div>
    
    <form #form="ngForm" [formGroup]="placeOrder" (ngSubmit)="placeOrderFunction()">
        <div class="col s12 m4">
            <mat-form-field appearance="outline" class="cs-input wp100">
                <mat-label>Remark <span class="sub">*</span></mat-label>
                <textarea matInput placeholder="Type Here ..." class="h70" formControlName="order_remarks" 
                id="order_remarks"></textarea>
            </mat-form-field>
            <mat-hint class="has-error" *ngIf="placeOrder.controls.order_remarks.invalid && 
            placeOrder.controls.order_remarks.touched">
        </mat-hint>
    </div>
    <div class="col s12 m12">
        <div class="cs-btn">
            <button  mat-button type="submit" [disabled]="placeOrder.invalid || buttonLoading">Place Order</button>
        </div>
    </div>
</form>

</div>

</div>
</div>
</div>