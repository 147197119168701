<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <div class="module-name" *ngIf="!loader">
                <h2>Retailer Scheme</h2>
                <p>{{totalRetailerScheme ? totalRetailerScheme : '0'}} Retailer Scheme Are Available</p>
            </div>
            <div class="left-auto df afe" *ngIf="!loader">
                <div class="indicates" style="padding-bottom: 1%;">
                    <!-- Last Sync Time - {{lastUpdatedDate}} -->
                    Last Update Time - {{lastUpdatedDate ? (lastUpdatedDate | date: 'd MMM y, hh mm a') : 'N/A'}}
                </div>
                <ul class="date-filter">
                    <li>
                        <div class="cs-form pb0">
                            <p>Date From</p>
                            <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                    placeholder="Search ..." name="date_from" [(ngModel)]="search.date_from" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </li>
                    <li>
                        <div class="cs-form pb0">
                            <p>Date To</p>
                            <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                    placeholder="Search ..." name="date_to" [(ngModel)]="search.date_to" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </li>
                    <li class="cs-btn mt15 mr10" *ngIf="this.search.date_from && this.search.date_to">
                        <a mat-button (click)="retailerSchemeList(1)">Go</a>
                    </li>
                    <li class="cs-btn mt15" *ngIf="this.search.date_from || this.search.date_to">
                        <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                    </li>
                    <li class="cs-btn mt15 ml10" *ngIf="selectedRetailer.length > 0">
                        <a mat-button (click)="updateRetailerScheme()">Update Scheme</a>
                    </li>
                </ul>
            </div>
            <div class="cs-action">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="retailerSchemeList(1)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw90">Segment</div>
                            <div class="div_cell mw190">Retailer Name</div>
                            <div class="div_cell mw190">Distributor Name</div>
                            <div class="div_cell mmw160">Retailer State</div>
                            <!-- <div class="div_cell mmw100">Brand</div>
                            <div class="div_cell mmw120">Material Code</div>
                            <div class="div_cell mmw220">Part Code</div> -->
                            <div class="div_cell mmw100">APP Order Qty</div>
                            <div class="div_cell mmw120">Next Slab Target</div>
                            <div class="div_cell mmw100">Balance</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw190">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_name"
                                        [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw190">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="distributer_name"
                                        [(ngModel)]="filterSearch.distributer_name"
                                        (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw160">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="retailer_state"
                                        [(ngModel)]="filterSearch.retailer_state"
                                        (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div>
                            <!-- <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="category"
                                        [(ngModel)]="filterSearch.category" (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="material_code"
                                        [(ngModel)]="filterSearch.material_code" (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw220">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="product_code"
                                        [(ngModel)]="filterSearch.product_code" (keyup.enter)="retailerSchemeList(1);">
                                </div>
                            </div> -->
                            <div class="div_cell mmw100">&nbsp;</div>
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw100">
                               
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <!-- Loop Data -->
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of schemeData; let i =index;">
                            <div class="div_cell mmw70 ac">
                                <strong>{{i+1}}</strong>
                            </div>
                            <!-- <div class="div_cell mmw70 text-center af-bdr-left">
                                <div class="date-filter">
                                    <mat-checkbox class="mr5" (change)="onselectRetailer( $event, data, data.checked,k,1)" [(ngModel)]="data.checked"></mat-checkbox>
                                    <strong>{{i+1}}</strong>
                                </div>
                            </div> -->
                            <div class="div_cell mmw90">{{data.segment ? (data.segment | titlecase):'N/A'}} </div>
                            <div class="div_cell mw190">{{data.dr_name ? (data.dr_name | titlecase):'N/A'}} </div>
                            <div class="div_cell mw190">{{data.distributer_name ? (data.distributer_name | titlecase):'N/A'}} </div>
                            <div class="div_cell mmw160">{{data.retailer_state ? (data.retailer_state | titlecase):'N/A'}} </div>
                            <!-- <div class="div_cell mmw100">{{data.category ? data.category:'N/A'}} </div>
                            <div class="div_cell mmw120">{{data.material_code ? data.material_code:'N/A'}} </div>
                            <div class="div_cell mmw220">{{data.product_code ? data.product_code:'N/A'}} </div> -->
                            <div class="div_cell mmw100">{{data.total_order_qty ? data.total_order_qty:'0'}} </div>
                            <div class="div_cell mmw120">{{data.scheme_range ? data.scheme_range :'0'}} </div>
                            <div class="div_cell mmw100">{{data.scheme_range_balance ? data.scheme_range_balance:'0'}}</div>
                        </main>
                        <app-not-found *ngIf="schemeData.length == 0"></app-not-found>
                    </ng-container>
                    <!-- Loop Data -->


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw70 text-center af-bdr-left">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw190">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw190">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw160">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <!-- <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw220">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div> -->
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>

                </section>
            </div>

            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac">
                    <div class="pagination" *ngIf="total > 1">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                         </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="schemeData.length > 0" (click)="exportExcel()"
                            class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
    </div>
</div>