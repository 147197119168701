import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { ExpenseModalComponent } from '../expense-modal/expense-modal.component';
import { ImageModuleComponent } from '../image-module/image-module.component';
import { ActivatedRoute } from '@angular/router';
import { ExpenseEditComponent } from '../expense-edit/expense-edit.component';

@Component({
  selector: 'app-expense-detail',
  templateUrl: './expense-detail.component.html',
  styleUrls: ['./expense-detail.component.scss']
})
export class ExpenseDetailComponent implements OnInit {
  skLoading:boolean= false;
  expenseDetail:any={};
  data_val:any={};
  expenseId:any
  loader:any;
  travel:any=[];
  localConv:any=[];
  hotel:any=[];
  miscExp:any=[];
  food:any=[];
  assign_login_data: any = [];
  assign_login_data2: any = []
  constructor(public serve : DatabaseService,public dialogeRef : DialogService,public dialog: MatDialog,public route:ActivatedRoute) {
    this.data_val = this.serve.userLoginData.data
    this.assign_login_data2 = this.data_val
    console.log(this.assign_login_data2)
    this.route.params.subscribe( params => {
      console.log(params);
      this.expenseId = params.id;
    });
   }

  ngOnInit(): void {
    this.getExpenseDetail()
  }
  getExpenseDetail(){
    this.loader=1;
    this.skLoading = true;
    this.serve.PostRequest({'expenseId':this.expenseId},"Expense/expenseDetail").subscribe(result=>{
     if(result['statusCode']==200){
      this.skLoading = false;
      this.expenseDetail = result['result'];
      this.travel=result['travel'];
      this.localConv=result['localConv'];
      this.hotel=result['hotel'];
      this.miscExp=result['miscExp'];
      this.food=result['food'];
     }else{
      this.skLoading = false;
      this.dialogeRef.error('Error',result['statusMsg']);
     }
    }, err=>{
      this.skLoading = false;
      this.dialogeRef.error('Error','Something went wrong');
    })
  } 

  expModal(type,id,totalAmt){
    const dialogRef = this.dialog.open(ExpenseModalComponent,{
      width:'400px',
      data:{
        type,
        id,
        totalAmt
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.getExpenseDetail();
      
    }); 
  }
  
  edit_details(expense_type) {
    console.log(expense_type)
    const dialogRef = this.dialog.open(ExpenseEditComponent, {
      width: expense_type == 'Travelentitlement' ? '950px' : '750px',
      data: {
        'from':'expense detail page',
        'expense_type':expense_type,
        'expense_detail':expense_type == 'Travelentitlement' ? this.expenseDetail.travel: 
        expense_type == 'hotel'?this.expenseDetail.hotel:
        expense_type == 'food'?this.expenseDetail.food:
        expense_type == 'local conveyance'?this.expenseDetail.localConv:
        expense_type == 'misc expense information'?this.expenseDetail.miscExp:'no data found'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getExpenseDetail();
    });
  } 
  imageModel(image){
    const dialogRef = this.dialog.open( ImageModuleComponent, {
      panelClass:'Image-modal',
      width: '500px',
      data:{
        image,
        
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('The dialog was closed');  
    });
  }
}
