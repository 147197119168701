<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>Company Stock</h2>
                    <p>{{totalProduct}} Available</p>
                </div>
                <!-- <div class="left-auto" *ngIf="apiHit.userLoginData.data.moduleData[11]['add'] == 1">
                    <div class="cs-btn mt0">
                        <a mat-button
                            (click)="productEdit(1,'')">Add new</a>
                    </div>
                </div> -->
            </ng-container>
            <ng-container *ngIf="!loader && last_sync_date  != '0000-00-00 00:00:00' && data_val?.login_type != 'distributor'">
                <div class="left-auto df afe">
                    <div class="indicates">
                        Last Sync Time - {{last_sync_date ? (last_sync_date | date: 'd MMM y, hh mm a') : 'N/A'}}
                     
                    </div>
                </div>
            </ng-container>
            <div class="cs-action">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="stockList(1)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">

            <div class="cs-table" *ngIf="data_val?.login_type != 'distributor'">


                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw140">Material Code</div>
                            <div class="div_cell mw160">Material Description</div>
                            <div class="div_cell mmw120">Plant</div>
                            <div class="div_cell mmw140">Storage Location</div>
                            <div class="div_cell mmw160">Value Unrestricted</div>
                            <div class="div_cell mmw150">Stock</div>
                        </main>
                    </section>

                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="material_code"
                                        [(ngModel)]="filterSearch.material_code" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw160">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="material_description"
                                        [(ngModel)]="filterSearch.material_description" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="plant"
                                        [(ngModel)]="filterSearch.plant" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="storage_location"
                                        [(ngModel)]="filterSearch.storage_location" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw160">
                                Total value: {{total_value?total_value:'N/a'}}
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="value_unrestricted"
                                        [(ngModel)]="filterSearch.value_unrestricted" (keyup.enter)="stockList(1);">
                                </div>     -->
                            </div>
                            <div class="div_cell text-center mmw150 af-bdr-right">
                                Total Stock : {{total_stock?total_stock:'N/A'}}
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="stockList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of stockData; let i = index;">
                            <div class="div_cell mmw70 ac">
                                <strong>{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw140">{{data.material_code ? (data.material_code | uppercase) : 'N/A'}}</div>
                            <div class="div_cell mw160">{{data.material_description ? data.material_description : 'N/A'}}</div>
                            <div class="div_cell mmw120">{{data.plant ? data.plant : 'N/A'}}</div>
                            <div class="div_cell mmw140">{{data.storage_location ? data.storage_location : 'N/A'}}</div>
                            <div class="div_cell mmw160">{{data.value_unrestricted ? data.value_unrestricted : '0'}}</div>
                            <div class="div_cell mmw150">
                                {{data.unrestricted ? data.unrestricted : '0'}}
                            </div>
                        </main>
                        <app-not-found *ngIf="stockData.length == 0"></app-not-found>
                    </ng-container>


                    <!-- Skeleton Loading Start -->

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;">
                            <div class="div_cell mmw70">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw160">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw160">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw150">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>

                    <!-- Skeleton Loading End -->

                </section>
            </div>

            <div class="cs-table" *ngIf="data_val.login_type == 'distributor'">


                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw140">Brand</div>
                            <div class="div_cell mw160">Part Code</div>
                            <div class="div_cell mmw120">Stock Alert</div>
                            <div class="div_cell mmw140">Current Stock</div>
                            <div class="div_cell mmw160">Secondary Order</div>
                        </main>
                    </section>

                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="brand"
                                        [(ngModel)]="filterSearch.brand" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw160">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                        [(ngModel)]="filterSearch.part_code" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="default_stock"
                                        [(ngModel)]="filterSearch.default_stock" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="current_stock"
                                        [(ngModel)]="filterSearch.current_stock" (keyup.enter)="stockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw160">&nbsp;</div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of stockData; let i = index;">
                            <div class="div_cell mmw70 ac">
                                <strong>{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw140">{{data.brand ? (data.brand | uppercase) : 'N/A'}}</div>
                            <div class="div_cell mw160">{{data.part_code ? data.part_code : 'N/A'}}</div>
                            <div class="div_cell mmw120">{{data.default_stock ? data.default_stock : '0'}}</div>
                            <div class="div_cell mmw140">{{data.current_stock ? data.current_stock : 'N/A'}}</div>
                            <div class="div_cell mmw160">{{data.Secondary_order ? data.Secondary_order : '0'}}</div>
                        </main>
                        <app-not-found *ngIf="stockData.length == 0"></app-not-found>
                    </ng-container>


                    <!-- Skeleton Loading Start -->

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;">
                            <div class="div_cell mmw70">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw160">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw160">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>

                    <!-- Skeleton Loading End -->

                </section>
            </div>






            <!-- Pagination Start -->
            <!-- <div class="bottom-strip">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a>
                            </li>
                        </ul>
                    </div>

                    <div class="bottom-fix ml10">
                        <a mat-button class="pulse" (click)="stockList(3)"><img src="assets/img/excel.png"></a>
                    </div>

                </div>
            </div> -->
            <!-- Pagination End -->

        </div>

    </div>
</div>