import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/services/service';
import { MatDialog } from '@angular/material/dialog';
import { MechanicEditComponent } from '../mechanic-edit/mechanic-edit.component';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';



@Component({
  selector: 'app-mechanic-detail',
  templateUrl: './mechanic-detail.component.html'
})
export class MechanicDetailComponent implements OnInit {
  
  id: any;
  loader:any;
  skData:any= [];
  skeleton:any=[];
  date : any;
  filterSearch : any = {};
  mechanicDetails:any={};
  retailerData =[];
  mechanicTransaction : any = [];
  paginationData:any = {};
  data_val: any;
  login_data: any;


  constructor( 

    public routes : ActivatedRoute, 
    public dialog : MatDialog, 
    private _location : Location, 
    public db : DatabaseService, 
    public dialog2 : DialogService 
    
    ){

    console.log(routes['params']['_value']);
    this.id = routes['params']['_value'].id;
    this.skeleton = new Array(10);
    this.mechanicDetail();
    this.date = new Date();


  }
  
  ngOnInit(): void {

    console.log(this.db.userLoginData);
    this.data_val= this.db.userLoginData.data 

    this.skData = new Array(5);

  }

  public onDate(event): void 
  {
      this.filterSearch.dateCreated=moment(event.value).format('YYYY-MM-DD');    
      this.searchingTransaction();
  } 
  
  goBack() {

    this._location.back();

  }
  
  
  mechanicDetail(){

      this.loader = 1;
      this.db.PostRequest( {'user_Id':this.db.userLoginData.data.id, 'mechanic_Id':this.id}  ,"Mechanic/mechanic_details").subscribe((result=>{

          console.log(result);
          this.mechanicDetails      = result['mechanic_details'];
          this.retailerData         = result['mechanic_details']['retailerData'];
          this.mechanicTransaction  = result['mechanic_details']['transactionData'];
          this.login_data           = result['mechanic_details']['loggedUserDetails'][0];

          setTimeout (() => {

              this.loader='';

          }, 700);
          
      }));

  }

  searchingTransaction(){

    this.loader = 1;
    this.paginationData     = this.filterSearch;
    this.paginationData.id  = this.id;

    this.db.PostRequest(this.paginationData,"Mechanic/mechanic_transaction_search").subscribe((result=>{

        console.log(result);

        this.mechanicTransaction = result['mechanic_transaction'];

        setTimeout (() => {

            this.loader='';

        }, 700);
        
    }));

  }


  
  //Refresh Retailer List Function Start
      

  refreshData(){

    this.filterSearch = {};
    this.mechanicDetail();

  }
  //Refresh Retailer List Function End


  
  infoEdit(): void {
    
      const dialogRef = this.dialog.open(MechanicEditComponent, {
          width: '500px',
          data: {
              id : this.id,
              assignRetailerData: this.retailerData,
          }

      });
    
      dialogRef.afterClosed().subscribe(result => {

          console.log(result);
          this.mechanicDetail();

      });

  }
  

  deleteItem(id){

    this.dialog2.delete('').then((result) => {

      if(result === true) {

          this.db.PostRequest({'id':id,},"Mechanic/delete_mechanic_retailer").subscribe((result=>{

            console.log(result);

          }));
          this.mechanicDetail();
      }

    });
  
  }


}