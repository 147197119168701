<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Scheme</h2>
                    <p>{{totalDistributor}} Scheme Available</p>
                </div>
                <!-- <div class="left-auto" *ngIf="apiHit.userLoginData.data.moduleData[12]['add'] == 1">
                    <div class="cs-btn mt0">
                        <a mat-button
                            routerLink="../scheme-add/{{0}}">Add new</a>
                    </div>
                </div> -->
                <div class="left-auto">
                    <div class="cs-action">
                        <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                            (click)="schemeList(1)"><i class="material-icons">search</i></a>
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                            (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                    </div>
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw180">Date</div>
                            <div class="div_cell mmw180">Segment</div>
                            <div class="div_cell mw95">States</div>
                            <div class="div_cell mw95">Range</div>
                            <div class="div_cell mmw120">Points</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input matInput [matDatepicker]="picker" [max]="date" placeholder="Search"
                                            [(ngModel)]="filterSearch.create_date" (dateChange)="onDate($event,1)">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="schemeList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw95">
                            </div>
                            <div class="div_cell mw95">
                            </div>
                            <div class="div_cell mmw120">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="schemeList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>


                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <!-- Loop Data -->
                        <main *ngFor="let data of schemeData; let i=index">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw180">{{data.create_date | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw180">{{data.segment ? (data.segment | titlecase):'N/A'}}</div>


                            <div class="div_cell mw95">{{data.state_names ? (data.state_names | titlecase) : 'N/A'}}
                            </div>
                            <div class="div_cell mw95" *ngIf="data.type=='Between'">{{data.range_from ? data.range_from
                                : 'N/A'}} - {{data.range_to ? data.range_to : 'N/A'}}</div>
                            <div class="div_cell mw95" *ngIf="data.type=='Above'">{{data.above ? data.above : 'N/A'}}
                                Above</div>


                            <div class="div_cell mmw120  ac">{{data.scheme_point ? data.scheme_point : 'N/A'}}</div>

                        </main>
                        <!-- Loop Data -->
                        <app-not-found *ngIf="schemeData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw95">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw95">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>
                </section>
            </div>

            <!-- Pagination Start -->
            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>

                </div>
            </div>
            <!-- Pagination End -->
        </div>

    </div>
</div>