import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { BannerListComponent } from './banner/banner-list/banner-list.component';
import { BillingDetailComponent } from './Billing/billing-detail/billing-detail.component';
import { BillingListComponent } from './Billing/billing-list/billing-list.component';
import { CompanyStockListComponent } from './companyStock/company-stock-list/company-stock-list.component';
import { CouponCodeAddComponent } from './coupon/coupon-code-add/coupon-code-add.component';
import { CouponCodeListComponent } from './coupon/coupon-code-list/coupon-code-list.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { DistributorAddComponent } from './distributor/distributor-add/distributor-add.component';
import { DistributorDetailComponent } from './distributor/distributor-detail/distributor-detail.component';
import { DistributorListComponent } from './distributor/distributor-list/distributor-list.component';
import { MechanicDetailComponent } from './mechanic/mechanic-detail/mechanic-detail.component';
import { MechanicListComponent } from './mechanic/mechanic-list/mechanic-list.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { PrimaryOrderAddComponent } from './order/primary-order-add/primary-order-add.component';
import { PrimaryOrderComponent } from './order/primary-order/primary-order.component';
import { SecondaryOrderAddComponent } from './order/secondary-order-add/secondary-order-add.component';
import { SecondaryOrderComponent } from './order/secondary-order/secondary-order.component';
import { SecondaryorderDetailComponent } from './order/secondaryorder-detail/secondaryorder-detail.component';
import { PendingOrderDetailComponent } from './PendingOrder/pending-order-detail/pending-order-detail.component';
import { PendingOrdersComponent } from './PendingOrder/pending-orders/pending-orders.component';
import { PartMasterComponent } from './product/part-master/part-master.component';
import { RetailerAddComponent } from './retailer/retailer-add/retailer-add.component';
import { RetailerDetailComponent } from './retailer/retailer-detail/retailer-detail.component';
import { RetailerListComponent } from './retailer/retailer-list/retailer-list.component';
import { IncentiveDetailComponent } from './RetailerIncentive/incentive-detail/incentive-detail.component';
import { IncentiveListComponent } from './RetailerIncentive/incentive-list/incentive-list.component';
import { MasterAndPrimaryComponent } from './scanQrCode/master-and-primary/master-and-primary.component';
import { SchemeAddComponent } from './Scheme/scheme-add/scheme-add.component';
import { SchemeDetailComponent } from './Scheme/scheme-detail/scheme-detail.component';
import { SchemeListComponent } from './Scheme/scheme-list/scheme-list.component';
import { TargetListComponent } from './Target/target-list/target-list.component';
import { AskToDistributorListComponent } from './transaction/ask-to-distributor-list/ask-to-distributor-list.component';
import { CompanyStockComponent } from './transaction/company-stock/company-stock.component';
import { CouponStockUtilizeComponent } from './transaction/coupon-stock-utilize/coupon-stock-utilize.component';
import { DistributorMslComponent } from './transaction/distributor-msl/distributor-msl.component';
import { DistributorToRetailerListComponent } from './transaction/distributor-to-retailer-list/distributor-to-retailer-list.component';
import { PartiallyDeliveredComponent } from './transaction/partially-delivered/partially-delivered.component';
import { RetailerSchemeComponent } from './transaction/retailer-scheme/retailer-scheme.component';
import { RetailerToMechanicListComponent } from './transaction/retailer-to-mechanic-list/retailer-to-mechanic-list.component';
import { WarehouseStockComponent } from './transaction/warehouse-stock/warehouse-stock.component';
import { UserAddComponent } from './user/user-add/user-add.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { DamageScanCouponComponent } from './damage-scan-coupon/damage-scan-coupon.component';
import { AuthGuard } from './authGuard/auth.guard';
import { CompanyDispatchListComponent } from './company-dispatch/company-dispatch-list/company-dispatch-list.component';
import { CompanyDispatchDetailComponent } from './company-dispatch/company-dispatch-detail/company-dispatch-detail.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CheckinComponent } from './checkin/checkin.component';
import { ExpenseComponent } from './expense/expense.component';
import { ExpenseDetailComponent } from './expense-detail/expense-detail.component';
import { AllowanceMasterComponent } from './allowance-master/allowance-master.component';
import { PrintScannedListComponent } from './print-scanned-list/print-scanned-list.component';
import { CustomerCategoryComponent } from './customer-category/customer-category.component';

const routes: Routes =
  [

    { path: '', component: LoginComponent },
    // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'distributor-add', component: DistributorAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'distributor', component: DistributorListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'distributor-detail/:id', component: DistributorDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'retailer-add', component: RetailerAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'retailer', component: RetailerListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'retailer-detail/:id', component: RetailerDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'mechanic', component: MechanicListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'mechanic-detail/:id', component: MechanicDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'add-coupon', component: CouponCodeAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'coupon', component: CouponCodeListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'ask-distributor', component: AskToDistributorListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'distributor-retailer', component: DistributorToRetailerListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'retailer-mechanic', component: RetailerToMechanicListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'user-add/:id', component: UserAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'user-list', component: UserListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'user-detail/:id', component: UserDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'banner-list', component: BannerListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'order-list', component: PrimaryOrderComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'pending-orders', component: PendingOrdersComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'part-master', component: PartMasterComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'order-detail/:id', component: OrderDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'secondary-order', component: SecondaryOrderComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'secondaryorder-detail/:id', component: SecondaryorderDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'scheme-list', component: SchemeListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'scheme-add/:id', component: SchemeAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'scheme-detail/:id', component: SchemeDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'billing-list', component: BillingListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'pendingOrder-Detail/:id', component: PendingOrderDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'billing-Detail/:id', component: BillingDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'incentive-list', component: IncentiveListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'incentive-Detail/:id', component: IncentiveDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'target-list', component: TargetListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'primaryOrder-add', component: PrimaryOrderAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'secondaryOrder-add', component: SecondaryOrderAddComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'companyStock-list', component: CompanyStockListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'partially-delivered', component: PartiallyDeliveredComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'retailer-scheme', component: RetailerSchemeComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'distributor-msl', component: DistributorMslComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'companyPacked-stock', component: CompanyStockComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'warehousePacked-stock', component: WarehouseStockComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'coupon-utilize', component: CouponStockUtilizeComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'scan-qrCode', component: MasterAndPrimaryComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'damage-scan-qrCode', component: DamageScanCouponComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'dispatch', component:CompanyDispatchListComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'dispatch/:id', component:CompanyDispatchDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'expense-detail/:id', component:ExpenseDetailComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'attendance', component:AttendanceComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'checkin', component:CheckinComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'expense', component:ExpenseComponent,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'allowance', component: AllowanceMasterComponent ,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'print-scanned', component: PrintScannedListComponent ,canActivate: [AuthGuard], data: { expectedRole: ['1']} },
    { path: 'customer-category', component: CustomerCategoryComponent ,canActivate: [AuthGuard], data: { expectedRole: ['1']} },


  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
