<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>Coupon Utilize</h2>
                    <p>{{couponData.length}} Coupon Utilize Available </p>
                </div>
                <div class="left-auto">
                    <ul class="date-filter">
                        <li>
                            <div class="cs-form pb0">
                                <p>Date From</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                    <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                        placeholder="Search ..." name="date_from" [(ngModel)]="filterSearch.date_from"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li>
                            <div class="cs-form pb0">
                                <p>Date To</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                    <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                        placeholder="Search ..." name="date_to" [(ngModel)]="filterSearch.date_to"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li class="cs-btn mt15 mr10" *ngIf="this.filterSearch.date_from && this.filterSearch.date_to">
                            <a mat-button (click)="utilizeCouponList(1)">Go</a>
                        </li>
                        <li class="cs-btn mt15" *ngIf="this.filterSearch.date_from || this.filterSearch.date_to">
                            <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                        </li>
                    </ul>
                </div>
            </ng-container>
            <div class="cs-action">
                <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="utilizeCouponList(1)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        
        <div class="container-pd10-5 bottom-calc no-padding">
            
            <div class="cs-table">
                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw90 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw220">Coupon Denomination</div>
                            <div class="div_cell mmw220">Opening As On Date</div>
                            <div class="div_cell mw220">Generated During The Period</div>
                            <div class="div_cell mmw220">Scanned During The Period</div>
                            <div class="div_cell mmw220">Damage Coupons</div>

                            <!-- <div class="div_cell mmw220">Coupons Not Scanned For Packing</div> -->
                            <div class="div_cell mmw220">Coupons Balance In Market</div>

                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw90 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw220">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="coupon_code_amount" [(ngModel)]="filterSearch.coupon_code_amount"
                                    (keyup.enter)="utilizeCouponList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw220">
                                </div>
                            <div class="div_cell mmw220">
                                </div>
                            <div class="div_cell mw220"></div>
                            <div class="div_cell mmw220"></div>
                            <!-- <div class="div_cell mmw220"></div> -->
                            <div class="div_cell mmw220"></div>
                          
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->
                
                <section class="td_cell" >
                    
                    <!-- Loop Data -->
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of couponData; let i = index;">
                            <div class="div_cell mmw90 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw220">{{data.coupon_code_amount ? data.coupon_code_amount : 'N/A'}}</div>
                            <div class="div_cell mmw220">{{data.no_of_opening_coupon_in_market ? data.no_of_opening_coupon_in_market : 'N/A'}}</div>
                            <div class="div_cell mmw220">{{data.generate_during_period ? data.generate_during_period : 'N/A'}}</div>
                            <div class="div_cell mw220">{{data.scanned_during_period ? data.scanned_during_period :'N/A'}}</div>
                            <div class="div_cell mmw220">{{data.damage_coupon ? data.damage_coupon:'N/A'}}</div>
                            <div class="div_cell mmw220">{{data.remaining_coupon ? data.remaining_coupon:'N/A'}}</div>
                        </main>
                        <main *ngIf="couponData.length > 0" >
                        <div class="div_cell mmw90 ac"></div>
                        <div class="div_cell mmw220">Total</div>
                        <div class="div_cell mmw220">{{finalOpeningCoupon}}</div>
                        <div class="div_cell mmw220">{{finalGenerateDuringPeriod}}</div>
                        <div class="div_cell mmw220">{{finalScannedDuringPeriod}}</div>
                        <div class="div_cell mw220">{{finalDamageCoupon}}</div>
                        <div class="div_cell mmw220">{{finalRemainingCoupon}}</div>
                        </main>
                        <app-not-found *ngIf="couponData.length == 0"></app-not-found>
                    </ng-container>
                    <!-- Loop Data -->
                    
                    
                    <!-- Skeleton Loading Start -->
                    
                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;" >
                            <div class="div_cell mmw90 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <!-- <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div> -->
                            <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>
                    
                    <!-- Skeleton Loading End -->
                    
                </section>
            </div>
            
            <!-- Pagination Start -->
            <!-- <div class="bottom-strip">
                <div class="left-auto">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <!-- Pagination End -->
        </div>
        
    </div>
</div>