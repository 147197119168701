<div class="edit-modal">

    <div mat-dialog-content>
      <p class="heading">Change Status </p>
      <div class="cs-form">
  
        <div class="row" *ngIf="data.type == 'seniorStatus'">
          <div class="col s12">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>Senior Status</mat-label>
              <mat-select name="seniorStatus" [(ngModel)]="data.seniorStatus"
                (ngModelChange)="data.approved_amount = '';">
                <!-- <mat-select placeholder="Type Here ..."> -->
                <!-- <mat-option value="">Pending</mat-option> -->
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Reject">Reject</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row" *ngIf="data.type == 'hrStatus'">
          <div class="" [ngClass]="data.hrStatus == 'Approved' ? 'col s6':'col s12'">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>HR  Status</mat-label>
              <mat-select name="hrStatus" [(ngModel)]="data.hrStatus"
                (ngModelChange)="data.hr_approved_amount = '';data.reason = ''">
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Reject">Reject</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col s6" *ngIf="data.hrStatus == 'Approved'">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>Enter Amount</mat-label>
              <input onkeypress="return event.charCode>=48 && event.charCode<=57" matInput placeholder="Type Here .."
                name="hr_approved_amount" #hr_approved_amount="ngModel" [(ngModel)]="data.hr_approved_amount" required >
            </mat-form-field>
          </div>
        </div>

       <div class="row" *ngIf="data.type == 'vpStatus'">
          <div class="col s12">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>Vice President Status</mat-label>
              <mat-select name="vpStatus" [(ngModel)]="data.vpStatus"
                (ngModelChange)="data.reason = ''">
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Reject">Reject</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
  
  
        <div class="row" *ngIf="data.type == 'acStatus' ">
  
          <!-- "data.type == acStatus" -->
  
          <div class="" [ngClass]="data.acStatus == 'Approved' ? 'col s6':'col s12'">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>A/C Status</mat-label>
              <mat-select name="acStatus" [(ngModel)]="data.acStatus"
                (ngModelChange)="data.approved_amount = '';data.reason = '' ">
  
                <!-- <mat-select placeholder="Type Here ..."> -->
                <!-- <mat-option value="">Pending</mat-option> -->
                <mat-option value="Approved">Approved</mat-option>
                <mat-option value="Reject">Reject</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="col s6" *ngIf="data.acStatus == 'Approved'">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>Enter Amount</mat-label>
              <input onkeypress="return event.charCode>=48 && event.charCode<=57" matInput placeholder="Type Here .."
                name="approved_amount" #approved_amount="ngModel" [(ngModel)]="data.approved_amount" required >
            </mat-form-field>
          </div>
        </div>
  
  
        <div class="row">
          <div class="col s12">
            <mat-form-field class="cs-input" appearance="outline">
              <mat-label>Reason</mat-label>
              <textarea matInput placeholder="Type Here ..." class="h90" type="text" name="reason"
                [(ngModel)]="data.reason"></textarea>
              <!-- <div class="alert alert-danger" *ngIf="!street.valid && update_basic.submitted">
                Address is required....
              </div> -->
            </mat-form-field>
          </div>
        </div>
  
  
      </div>
    </div>
  
    <div mat-dialog-actions>
      <button class="mr10" mat-stroked-button color="warn" [mat-dialog-close]="false">Cancel</button>
      <button mat-raised-button color="accent"
        [disabled]="(!data.acStatus && !data.seniorStatus && !data.hrStatus && !data.vpStatus) || (data.acStatus == 'Reject' && !data.reason) || (data.seniorStatus == 'Reject' && !data.reason) || ((data.acStatus == 'Approved' && !data.approved_amount || !data.reason)) ||((data.hrStatus == 'Approved' && !data.hr_approved_amount || !data.reason)) || (data.vpStatus == 'Reject' && !data.reason)  "
        (click)="update_status()">Save</button>
        <!--  || (data.acStatus == 'Approved' && data.approved_amount < data.totalAmt) -->
    </div>
  </div>