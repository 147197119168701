<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name">
                    <h2>Billing Detail</h2>
                    <p>{{billingDetail.dr_name}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <ng-template mat-tab-label *ngIf="loader">
                <div class="sk-loading">&nbsp;</div>
            </ng-template>
            <ng-template mat-tab-label *ngIf="!loader">
                <mat-icon class="example-tab-icon">info</mat-icon>
                Detail
            </ng-template>
            <div class="row">
                <div class="col s12 m12">
                    <div class="cs-column" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Billing Information</h2>
                        </div>

                        <div class="cs-form ">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Billing Date</span>
                                            <p> {{billingDetail.Bill_Date ? (billingDetail.Bill_Date | date: 'd MMM y') : 'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Billing Number</span>
                                            <p>{{billingDetail.ODN_Number ?
                                                billingDetail.ODN_Number:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box" *ngIf="data_val?.login_type != 'distributor'">
                                            <span>Customer Name</span>
                                            <p>{{billingDetail.Cust_Vendor_Name ? billingDetail.Cust_Vendor_Name:'N/A'}} - {{billingDetail.Cust_Vendor_Code ?
                                                billingDetail.Cust_Vendor_Code:'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Total Value</span>
                                            <p>₹ {{billingDetail.total_amount ?
                                                (billingDetail.total_amount | number : '1.2-2') :'0'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>


                    <!-- Skeleton Loading Data Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loading Data End -->


                    <div class="cs-column mt10" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Item Information</h2>
                        </div>

                        <div class="cs-table">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mmw150">Order No</div>
                                        <div class="div_cell mmw150">Order ID</div>
                                        <div class="div_cell mw120">Product Name</div>
                                        <div class="div_cell mmw150">Item Code</div>
                                        <!-- <div class="div_cell mmw150">Unit Of Measurement</div> -->
                                        <div class="div_cell mmw150">Qty</div>
                                        <div class="div_cell mmw150">Rate</div>
                                        <div class="div_cell mmw150">Amount</div>
                                        <div class="div_cell mmw150">Total Amt</div>
                                        <div class="div_cell mmw150">Tax Amt</div>

                                    </main>
                                </section>
                            </figure>
                            <!-- Table Head End -->

                            <section class="td_cell">
                                <main *ngFor="let data of billingDetail.items; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw150">{{data.Order_No ?
                                        data.Order_No:'N/A'}}</div>
                                    <div class="div_cell mmw150" *ngIf="data.order_id">
                                        <a class="link-btn" mat-flat-button routerLink="/order-detail/{{data.order_id}}">#AFF-{{data.order_id}}</a>
                                    </div>
                                    <div class="div_cell mmw150" *ngIf="!data.order_id">N/A
                                    </div>
                                            
                                    <div class="div_cell mw120">{{data.Description ? data.Description:'N/A'}}</div>
                                    <div class="div_cell mmw150">{{data.Item_Code ?
                                        data.Item_Code:'N/A'}}</div> 
                                    <!-- <div class="div_cell mmw150">{{data.UOM ?
                                        data.UOM:'N/A'}}</div> -->
                                    <div class="div_cell mmw150">{{data.Qty ?
                                        data.Qty:'N/A'}} {{data.UOM ?
                                            data.UOM:'N/A'}}</div>
                                    <div class="div_cell mmw150">₹ {{data.Rate_In_INR ?
                                        data.Rate_In_INR:'0'}}</div>
                                    <div class="div_cell mmw150">₹ {{data.Amount_In_INR ?
                                        data.Amount_In_INR:'0'}}</div>
                                    <div class="div_cell mmw150">₹ {{data.Total_Amt ?
                                        data.Total_Amt:'0'}}</div>

                                     <div class="div_cell mmw150">₹ {{data.Total_Amt - data.Amount_In_INR | number : '1.2-2'}} </div>
                                </main>
                                <!-- Loop Data -->
                            </section>
                        </div>
                    </div>

                    
                </div>
            
            </div>



        </div>
    </div>
</div>