<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name">
                    <h2>Scheme Detail</h2>
                    <p>{{schemeDetailData.title ? schemeDetailData.title:'N/A'}}</p>
                </div>
                <div class="left-auto">
                    <div class="cs-btn mt0" *ngFor=" let datas of apiHit.userLoginData.data.moduleData">
                        <a *ngIf="datas.module_name== 'User' && datas.edit==1" mat-button
                            routerLink="../../scheme-add/{{schemeDetailData.id}}">Edit</a>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
                <div class="left-auto">
                    <div class="cs-btn mt0" *ngFor=" let datas of apiHit.userLoginData.data.moduleData">
                        <a *ngIf="datas.module_name== 'User' && datas.edit==1" mat-button
                            routerLink="../scheme-add/{{activateRoute.params._value.id}}">Edit</a>
                    </div>
                </div>
            </ng-container>
        </section>


        <div class="container-pd10-5">
            <div class="row">
                <div class="col s12 m12">
                    <div class="cs-column" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>basic Information</h2>
                        </div>

                        <div class="cs-form">
                            <div class="row row-mb0">
                                <div class="col s12 m12">
                                    <div class="fields" *ngIf="!loader">
                                        <div class="cs-box">
                                            <span>Date & Created By</span>
                                            <p>{{(schemeDetailData.created_date ? schemeDetailData.created_date:'N/A') |
                                                date: 'd MMM y'}} - {{schemeDetailData.created_by_name ?
                                                schemeDetailData.created_by_name:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Start Date</span>
                                            <p>{{(schemeDetailData.start_date ? schemeDetailData.start_date:'N/A') |
                                                date: 'd MMM y'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>End Date</span>
                                            <p>{{(schemeDetailData.end_date ? schemeDetailData.end_date:'N/A') | date:
                                                'd MMM y'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Title </span>
                                            <p>{{schemeDetailData.title ? schemeDetailData.title:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Segment </span>
                                            <p>{{schemeDetailData.segment ? schemeDetailData.segment:'N/A'}}</p>
                                        </div>
                                    </div>

                                    <div class="fields mt10" *ngIf="!loader">
                                        <div class="cs-box">
                                            <span>Scheme Type </span>
                                            <p>{{schemeDetailData.type ? schemeDetailData.type:'N/A'}}</p>
                                        </div>

                                        <div class="cs-box" *ngIf="schemeDetailData.type != 'Above'">
                                            <span>Range From </span>
                                            <p>{{schemeDetailData.range_from ? schemeDetailData.range_from:'N/A'}}</p>
                                        </div>

                                        <div class="cs-box" *ngIf="schemeDetailData.type != 'Above'">
                                            <span>Range To </span>
                                            <p>{{schemeDetailData.range_to ? schemeDetailData.range_to:'N/A'}}</p>
                                        </div>

                                        <div class="cs-box" *ngIf="schemeDetailData.type == 'Above'">
                                            <span>Above </span>
                                            <p>{{schemeDetailData.above ? schemeDetailData.above:'N/A'}}</p>
                                        </div>

                                        <div class="cs-box">
                                            <span>Points </span>
                                            <p> {{schemeDetailData.scheme_point ? schemeDetailData.scheme_point: 'N/A'}}
                                            </p>
                                        </div>

                                        <div class="cs-box">
                                            <span>Status </span>
                                            <p *ngIf="schemeDetailData.status == 1">Active</p>
                                            <p *ngIf="schemeDetailData.status == 2">Deactive</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <mat-divider class="mt10 mb10"></mat-divider>
                            <div class="row row-mb0">
                                <div class="col s12 m12">
                                    <div class="fields">
                                        <div class="cs-box" *ngIf="!loader">
                                            <span>Description</span>
                                            <p>{{schemeDetailData.description ? schemeDetailData.description : 'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box" *ngIf="!loader">
                                            <span>Terms And Conditions</span>
                                            <p>{{schemeDetailData.term_condition ? schemeDetailData.term_condition :
                                                'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Skeleton Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>

                        <div class="cs-form">
                            <div class="row row-mb0">
                                <div class="col s12 m12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="mt10 mb10"></mat-divider>
                            <div class="row row-mb0">
                                <div class="col s12 m12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton End -->

                </div>
            </div>


            <ng-container *ngIf="!loader">
                <div class="row">
                    <div class="col s12 m6">
                        <div class="row">
                            <div class="col s12 m6">
                                <div class="cs-column cs-column-height">
                                    <div class="cs-head">
                                        <div class="df ac jsb hp100">
                                            <h2>State</h2>
                                        </div>
                                    </div>

                                    <div class="mat-list pt0">
                                        <div class="mat-border" *ngFor="let row of schemeDetailData.states">
                                            <mat-checkbox [disabled]="!loader" [value]="schemeDetailData.states"
                                                [(ngModel)]="schemeDetailData.states">{{row}}</mat-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
</div>