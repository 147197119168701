<div class="container-fluid">
    <div class="container">
        
        <section id="head" class="ac" *ngIf="!loader">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below" (click)="backClicked()">
                <mat-icon>arrow_back</mat-icon>
            </a>
            
            <div class="module-name">
                <h2>User Detail</h2>
                <p>{{userDetailData.name ? userDetailData.name:'N/A'}}</p>
            </div>
            
            <div class="left-auto">
                <div class="cs-btn mt0" *ngFor=" let datas of apiHit.userLoginData.data.moduleData">
                    <a *ngIf="datas.module_name== 'User' && datas.edit==1" mat-button routerLink="../../user-add/{{userDetailData.id}}">Edit</a>
                </div>
            </div>
        </section>
        
        <!-- skeleton Start -->
        <section id="head" *ngIf="loader">
            <div class="module-name sk-loading" >
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        <!-- skeleton end -->
        
        <div class="container-pd10-5">
            <div class="row">
                <div class="col s12 m12">
                    <div class="cs-column"  *ngIf="!loader">
                        <div class="cs-head">
                            <h2>basic Information</h2>
                        </div>
                        
                        <div class="cs-form">
                            <div class="row row-mb0">
                                <div class="col s12 m12">
                                    <div class="fields" *ngIf="!loader">
                                        <div class="cs-box">
                                            <span>Date & Created By</span>
                                            <p>{{(userDetailData.date_created ? userDetailData.date_created:'N/A') | date: 'd MMM y'}}  -  {{userDetailData.created_by_name ? userDetailData.created_by_name:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Name</span>
                                            <p>{{userDetailData.name ? userDetailData.name:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Mobile Number</span>
                                            <p>{{userDetailData.mobile_no ? userDetailData.mobile_no:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Email Id </span>
                                            <p>{{userDetailData.email_id ? userDetailData.email_id:'N/A'}}</p>
                                        </div>
                                    </div>                                    
                                    <div class="fields mt10" *ngIf="!loader&&userDetailData.user_type == 'sales_user'">
                                        <div class="cs-box">
                                            <span>Designation</span>
                                            <p>{{userDetailData.designation_name ? userDetailData.designation_name:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Reporting Manager</span>
                                            <p>{{userDetailData.reporting_manager ? userDetailData.reporting_manager:'N/A'}}</p>
                                        </div>
                                        <!-- <div class="cs-box">
                                            <span>Weekly Off</span>
                                            <p>{{userDetailData.weekly_off ? userDetailData.weekly_off:'N/A'}}</p>
                                        </div> -->
                                    </div>

                                    <div class="fields mt10" *ngIf="!loader && userDetailData.user_type != 'sales_user'">
                                        <div class="cs-box" *ngIf="userDetailData.user_type=='System_user' && userDetailData.system_user_designation != ''">
                                            <span>Designation</span>
                                            <p>{{userDetailData.system_user_designation ? userDetailData.system_user_designation:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Username </span>
                                            <p>{{userDetailData.username ? userDetailData.username:'N/A'}}</p>
                                        </div>
                                        
                                        <div class="cs-box">
                                            <span>Password </span>
                                            <p>{{userDetailData.password ? userDetailData.password:'N/A'}}</p>
                                        </div>

                                        <div class="cs-box" *ngIf="userDetailData.user_type=='System_user'">
                                            <span>Type </span>
                                            <p>System User</p>
                                        </div>
                                                                                
                                        <div class="cs-box" *ngIf="userDetailData.user_type=='approval_user'">
                                            <span>Type </span>
                                            <p>Approval User</p>
                                        </div>
                                        
                                        <div class="cs-box"  *ngIf="userDetailData.user_type=='approval_user'">
                                            <span>Type </span>
                                            <p> {{userDetailData.approval_type ? userDetailData.approval_type: 'N/A'}}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <mat-divider class="mt10 mb10"></mat-divider>
                            <div class="row row-mb0">
                                <div class="col s12 m12">
                                    <div class="fields">
                                        <div class="cs-box" *ngIf="!loader">
                                            <span>Address</span>
                                            
                                            <p *ngIf="userDetailData.street != '' || userDetailData.city_name != '' || userDetailData.district_name != '' || userDetailData.state_name != '' || userDetailData.pincode != ''">
                                                {{userDetailData.street? (userDetailData.street + ', '):''}} {{userDetailData.city_name? (userDetailData.city_name + ', '):''}} {{userDetailData.district_name? (userDetailData.district_name + ', '):''}} 
                                                {{userDetailData.state_name? (userDetailData.state_name + ', '):''}} {{userDetailData.pincode}}
                                            </p>
                                            <p *ngIf="userDetailData.street == '' && userDetailData.city_name == '' && userDetailData.district_name == '' && userDetailData.state_name == '' && userDetailData.pincode == ''">
                                                Address Not Available
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <!-- Skeleton Start -->
                        <div class="cs-column"  *ngIf="loader">
                            <div class="cs-head sk-loading">
                                <h2>&nbsp;</h2>
                            </div>
                            
                            <div class="cs-form">
                                <div class="row row-mb0">
                                    <div class="col s12 m12">
                                        <div class="fields sk-loading">
                                            <div class="cs-box">
                                                <span>&nbsp;</span>
                                                <p>&nbsp;</p>
                                            </div>
                                            <div class="cs-box">
                                                <span>&nbsp;</span>
                                                <p>&nbsp;</p>
                                            </div>
                                            <div class="cs-box">
                                                <span>&nbsp;</span>
                                                <p>&nbsp;</p>
                                            </div>
                                            <div class="cs-box">
                                                <span>&nbsp;</span>
                                                <p>&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <mat-divider class="mt10 mb10"></mat-divider>
                                <div class="row row-mb0">
                                    <div class="col s12 m12">
                                        <div class="fields sk-loading">
                                            <div class="cs-box">
                                                <span>&nbsp;</span>
                                                <p>&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Skeleton End -->
                        
                    </div>
                </div>
                
                <ng-container *ngIf="!loader">

                    <ng-container *ngIf="userDetailData.user_type=='System_user' || userDetailData.user_type=='approval_user'">
                        <div class="row mb0">
                            <div class="col s12">
                                <h2 class="heading">Module Rights</h2>
                            </div>
                        </div>
                        
                        <div class="cs-table left-right-5">
                            <!-- Table Head Start-->
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mw150">Module Name</div>
                                        <div class="div_cell mmw140 text-center">View</div>
                                        <div class="div_cell mmw140 text-center">Add</div>
                                        <div class="div_cell mmw140 text-center">Edit</div>
                                        <div class="div_cell mmw140 text-center af-bdr-right">Delete</div>
                                        <div class="div_cell mmw140 text-center">All</div>
                                    </main>
                                </section>
                            </figure>
                            <!-- Table Head End -->
                            
                            <section class="td_cell">
                                <ng-container *ngIf="!loader && userDetailData.user_type=='System_user'">
                                    <main *ngFor="let row of moduleData; let i = index;">
                                        <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                        <div class="div_cell mw150">{{row.module_name}}</div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader" [(ngModel)]="row.view"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox [disabled]="!loader"  [(ngModel)]="row.add"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader"  [(ngModel)]="row.edit"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox [disabled]="!loader"   [(ngModel)]="row.delete"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader"  [(ngModel)]="row.all"></mat-checkbox>
                                        </div>
                                    </main>
                                </ng-container>
                                <ng-container *ngIf="!loader && userDetailData.user_type=='approval_user'">
                                    <main *ngFor="let row of module_data; let i = index;">
                                        <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                        <div class="div_cell mw150">{{row.module_name}}</div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader" [(ngModel)]="row.view"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox [disabled]="!loader"  [(ngModel)]="row.add"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader"  [(ngModel)]="row.edit"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox [disabled]="!loader"   [(ngModel)]="row.delete"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader"  [(ngModel)]="row.all"></mat-checkbox>
                                        </div>
                                    </main>
                                </ng-container>
                                
                                <!-- Skeleton Loading Start -->
                                <ng-container *ngIf="loader">
                                    <main *ngFor="let row of skeleton;" >
                                        <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mw150"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                    </main>
                                </ng-container>
                                <!-- Skeleton Loading End -->
                            </section>
                        </div>
                    </ng-container>



                    <!-- <ng-container *ngIf="userDetailData.user_type == 'approval_user'">
                        <div class="row mb0">
                            <div class="col s12">
                                <h2 class="heading">Module Rights</h2>
                            </div>
                        </div>
                        
                        <div class="cs-table left-right-5">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mw150">Module Name</div>
                                        <div class="div_cell mmw140 text-center">View</div>
                                        <div class="div_cell mmw140 text-center">Add</div>
                                        <div class="div_cell mmw140 text-center">Edit</div>
                                        <div class="div_cell mmw140 text-center af-bdr-right">Delete</div>
                                        <div class="div_cell mmw140 text-center">All</div>
                                    </main>
                                </section>
                            </figure>
                            
                            <section class="td_cell">
                                <ng-container *ngIf="!loader">
                                    <main *ngFor="let row of module_data; let i = index;">
                                        <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                        <div class="div_cell mw150">{{row.module_name}}</div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader" [(ngModel)]="row.view"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox [disabled]="!loader"  [(ngModel)]="row.add"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader"  [(ngModel)]="row.edit"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox [disabled]="!loader"   [(ngModel)]="row.delete"></mat-checkbox>
                                        </div>
                                        <div class="div_cell mmw140 ac">
                                            <mat-checkbox  [disabled]="!loader"  [(ngModel)]="row.all"></mat-checkbox>
                                        </div>
                                    </main>
                                </ng-container>
                                
                                <ng-container *ngIf="loader">
                                    <main *ngFor="let row of skeleton;" >
                                        <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mw150"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                        <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                                    </main>
                                </ng-container>
                            </section>
                        </div>
                    </ng-container> -->

                </ng-container>

                <ng-container *ngIf="!loader">
                    <div class="row" >
                        <div class="col s12 m6" >
                            <div class="row">
                                <div class="col s12 m6" *ngIf="userDetailData.approval_type== 'asm' || userDetailData.approval_type== 'zsm'">
                                    <div class="cs-column cs-column-height">
                                        <div class="cs-head" >
                                            <div class="df ac jsb hp100">
                                                <h2>State</h2>
                                            </div>
                                        </div>
                                        
                                        <div class="mat-list pt0" >
                                            <div class="mat-border" *ngFor="let row of stateData">
                                                <mat-checkbox [disabled]="!loader" [(ngModel)]="myModel">{{row}}</mat-checkbox>
                                            </div>
                                        </div>      
                                    </div>
                                </div>
                                <div class="col s12 m6" *ngIf="userDetailData.approval_type== 'asm' || userDetailData.approval_type== 'zsm'">
                                    <div class="cs-column cs-column-height">
                                        <div class="cs-head">
                                            <div class="df ac jsb hp100">
                                                <h2>District</h2>
                                                
                                                <!-- <a  (click)="districtSearch()" class="seacrh-btn" mat-button><i class="material-icons">search</i></a>
                                                <div class="filter-input "  [ngClass]="search_btn==1 ?'active': '' " >
                                                    <input type="text"  [(ngModel)]="searchText2" id="searchText2"  placeholder="Search ..." (keyup.enter)="district_search(searchText2)"   autocomplete="off">
                                                    <a class="close-btn" mat-button (click)="reset_district_button()"><i class="material-icons">close</i></a>
                                                    
                                                </div> -->
                                            </div>
                                        </div>
                                        
                                        <div class="mat-list pt0">
                                            <div class="mat-border" *ngFor="let row of stateDistrictList">
                                                <mat-checkbox color="accent"  [disabled]="!loader"  [(ngModel)]="myModel">{{row.district_name}}</mat-checkbox>
                                            </div>
                                            <!-- <app-not-found *ngIf="districtListData.length == 0"></app-not-found> -->
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>