<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Distributor Category</h2>
                    <p>{{categoryData.length ? categoryData.length : '0'}} Categories Are Available</p>
                </div>
                <div class="left-auto" *ngIf="apiHit.userLoginData.data.moduleData[25]['add'] == 1">
                    <div class="cs-btn mt0">
                        <a mat-button
                            (click)="categoryAdd()">Add New</a>
                    </div>
                   
                </div>
            </ng-container>
            <ng-container *ngIf="!loader">
                    <div class="cs-action ml10">
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                            (click)="refreshData()"><i
                                class="material-icons">settings_backup_restore</i></a>
                    </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw225 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw550">Category Name</div>
                            <div class="div_cell mmw400 text-center">Action</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw225 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw550"></div>
                            <div class="div_cell mmw400"></div>
                        </main>
                    </section>
                </figure>

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of categoryData; let i=index">
                            <div class="div_cell mmw225 ac">
                                <strong>{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw550">{{data.category ? data.category : '--'}}</div>
                            <div class="div_cell mmw400 text-center" style="display: block;">
                                <div class="cs-action">
                                    <ng-container>
                                        <a class="ac-green" matRipple matTooltip="Edit" matTooltipPosition="below" *ngIf="apiHit.userLoginData.data.moduleData[25]['edit'] == 1"><i
                                            class="material-icons" (click)="categoryEdit(data.id, data.category)">edit</i></a>
                                        <a class="ac-red" matRipple  matTooltip="Delete" matTooltipPosition="below" *ngIf="apiHit.userLoginData.data.moduleData[25]['delete'] == 1" (click)="deleteCategory(data.id)"><i class="material-icons">delete_sweep</i></a>
                                    </ng-container>
                                </div>
                            </div>
                        </main>
                        <app-not-found *ngIf="categoryData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw225 ac">
                                <p class="skeleton wp225">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw550">
                                <p class="skeleton wp550">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw400">
                                <p class="skeleton wp400">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>
                </section>
            </div>

            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="multi-btns">
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>
