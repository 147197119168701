import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';

@Component({
    selector: 'app-distributor-msl',
    templateUrl: './distributor-msl.component.html',
})
export class DistributorMslComponent implements OnInit {


    distributorStockData: any = [];
    paginationData: any = {};
    loader: any;
    total: any;
    filterSearch: any = {};
    skeleton: any = [];
    date: any = [];
    pageNumber: any;
    totalDistributorStock: any;
    stateData: any;
    selected_State : any;


    constructor(

        public dialog: MatDialog,
        private apiHit: DatabaseService,
        public dialog2: DialogService,
    ) {

        this.skeleton = new Array(10);
        this.date = new Date();
        this.pageNumber = 1;
        this.stateList();
        // this.distributorStockList(1);
    }

    ngOnInit(): void {
    }

    public onDate(event): void {
        this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
        this.distributorStockList(1);
    }


    onChangePageNumber(type) {

        console.log(this.pageNumber);

        if (type == 'previous') {

            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                this.distributorStockList(2);

            }

        }
        else if (type == 'next') {

            if (this.total > this.pageNumber) {

                this.pageNumber += 1;
                this.distributorStockList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber > this.total) {

                console.log('current else');
                this.pageNumber = this.total;
                this.distributorStockList(2);

            }
            else if (this.pageNumber == 0) {

                this.pageNumber = 1;
                this.distributorStockList(2);

            }
            else if (this.pageNumber) {

                console.log('last else if');
                this.distributorStockList(2);

            }

        }

    }


    stateList() {
        // console.log(this.selected_district);
        // console.log(this.AddDistributorForm);

        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {

            this.stateData = result['state_list']['state_name'];

            setTimeout(() => {

                this.loader = '';

            }, 100);

        }));

    }


    selectState(state){
        this.selected_State = state;
        this.filterSearch = {}
        this.distributorStockList(1);
    }


    //Get DIstributor List Through API Function Start

    distributorStockList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }

        this.distributorStockData = [];
        this.paginationData.search = this.filterSearch;
        this.paginationData.state = this.selected_State;
        this.paginationData.pagelimit = 20;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;

        this.apiHit.PostRequest(this.paginationData, "Distributor/distributerAssignParts").subscribe((result => {
            console.log(result);
            if (result['status'] == 'Success') {
                this.distributorStockData = result['info'];
                this.totalDistributorStock = result['total_row'];
                this.total = result['totalPage'];
                this.loader = '';
            }
            else {
                this.dialog2.error('error', 'Please Check Your Internet Connectivity');
                this.loader = '';
            }
        }));

    }
    //Get DIstributor List Through API Function End


    exportDistributorDataInExcel() {

        this.loader = 1;
        this.paginationData.pagelimit = 0;
        this.apiHit.PostRequest(this.paginationData, "Distributor/ExportDistributerPartsStockDetails").subscribe((result => {

            console.log(result);
            this.distributorStockList(1);
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/dr_part_stock_1.csv");

            }, 700);

        }));
    }


    //Delete Distributor Function Start

    deleteDistributorData(deleteId) {

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ "id": deleteId }, "Distributor/delete_dealer").subscribe((result => {

                    this.loader = 1;

                    console.log(result);

                    if (result['delete_dealer'] == 'success') {

                        this.distributorStockList(2);

                    }
                    else {

                        this.dialog2.error('error', result['errors']);
                        this.loader = '';

                    }
                }));

            }

        });

    }
    //Delete Distributor Function End



    //Refresh Distributor List Function Start

    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null) {
            this.pageNumber = 1;
        }
        this.distributorStockList(1);

    }
    //Refresh Distributor List Function End



}
