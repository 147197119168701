<!-- Edit Product Detail Start -->

<div *ngIf="dataValue == 2">
    <div mat-dialog-title class="model-head">
        <h2>Edit Product Detail</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="ProductEdit" (ngSubmit)="updateQuantity()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Segment<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="segment" id="segment">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.segment.invalid && 
                            ProductEdit.controls['segment']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Brand<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="category" id="category">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.category.invalid && 
                            ProductEdit.controls['category']?.touched">
                        </mat-hint>
                    </div>


                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Material Code<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="material_code" id="material_code">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.material_code.invalid && 
                            ProductEdit.controls['material_code']?.touched">
                        </mat-hint>
                    </div>


                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Part Code<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="part_code" id="part_code">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.part_code.invalid && 
                            ProductEdit.controls['part_code']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Stock Alert<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="default_stock"
                                id="default_stock" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.default_stock.invalid && 
                            ProductEdit.controls['default_stock']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Coupon Point<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="coupon_value"
                                id="coupon_value" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.coupon_value.invalid && 
                            ProductEdit.controls['coupon_value']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>MRP<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="mrp"
                                id="mrp" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.mrp.invalid && 
                            ProductEdit.controls['mrp']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>List Price<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="list_price"
                                id="list_price" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.list_price.invalid && 
                            ProductEdit.controls['list_price']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Set Box Price<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="set_box_price"
                                id="set_box_price" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.set_box_price.invalid && 
                            ProductEdit.controls['set_box_price']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Box Size<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="box_size"
                                id="box_size" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.box_size.invalid && 
                            ProductEdit.controls['box_size']?.touched">
                        </mat-hint>
                    </div>
                    
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>MRP For Master Box<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="box_mrp"
                                id="box_mrp" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.box_mrp.invalid && 
                            ProductEdit.controls['box_mrp']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Master Box Pack<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="master_box_pack"
                                id="master_box_pack">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.master_box_pack.invalid && 
                            ProductEdit.controls['master_box_pack']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Set Box Pack<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="set_box_pack"
                                id="set_box_pack">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.master_box_pack.invalid && 
                        ProductEdit.controls['set_box_pack']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Short Part Code<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="short_part_code"
                                id="short_part_code">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.master_box_pack.invalid && 
                        ProductEdit.controls['short_part_code']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Sets In Master Box<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="set_per_master_box"
                                id="set_per_master_box" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.set_per_master_box.invalid && 
                            ProductEdit.controls['set_per_master_box']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>With Rivet or Padkit<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="with_rivet_or_pad_kit"
                                id="with_rivet_or_pad_kit" >
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="ProductEdit.controls.with_rivet_or_pad_kit.invalid && 
                            ProductEdit.controls['with_rivet_or_pad_kit']?.touched">
                        </mat-hint>
                    </div>

                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="ProductEdit.invalid">Update</button>
                <!-- <button  mat-button >Save</button> -->
            </div>
        </mat-dialog-actions>
    </form>
</div>

<!-- Edit Product Detail End -->



<!-- Add Product Detail Start -->

<div *ngIf="dataValue == 1">
    <div mat-dialog-title class="model-head">
        <h2>Add Product</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="AddProduct" (ngSubmit)="AddProductFunction()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Segment<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="segment" id="segment">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.segment.invalid && 
                        AddProduct.controls['segment']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Brand<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="category" id="category">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.category.invalid && 
                        AddProduct.controls['category']?.touched">
                        </mat-hint>
                    </div>


                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Material Code<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="material_code" id="material_code">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.material_code.invalid && 
                        AddProduct.controls['material_code']?.touched">
                        </mat-hint>
                    </div>


                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Part Code<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="part_code" id="part_code">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.part_code.invalid && 
                        AddProduct.controls['part_code']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Stock Alert<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="default_stock"
                                id="default_stock" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.default_stock.invalid && 
                        AddProduct.controls['default_stock']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Coupon Point<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="coupon_value"
                                id="coupon_value" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.coupon_value.invalid && 
                        AddProduct.controls['coupon_value']?.touched">
                        </mat-hint>
                    </div>
                    
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>MRP<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="mrp"
                                id="mrp" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.mrp.invalid && 
                        AddProduct.controls['mrp']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>List Price<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="list_price"
                                id="list_price" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.list_price.invalid && 
                        AddProduct.controls['list_price']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Set Box Price<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="set_box_price"
                                id="set_box_price" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.set_box_price.invalid && 
                        AddProduct.controls['set_box_price']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Box Size<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="box_size"
                                id="box_size" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.box_size.invalid && 
                        AddProduct.controls['box_size']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>MRP For Master Box<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="box_mrp"
                                id="box_mrp" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.box_mrp.invalid && 
                        AddProduct.controls['box_mrp']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Master Box Pack<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="master_box_pack"
                                id="master_box_pack">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.master_box_pack.invalid && 
                        AddProduct.controls['master_box_pack']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Set Box Pack<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="set_box_pack"
                                id="set_box_pack">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.master_box_pack.invalid && 
                        AddProduct.controls['set_box_pack']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Short Part Code<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="short_part_code"
                                id="short_part_code">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddProduct.controls.master_box_pack.invalid && 
                        AddProduct.controls['short_part_code']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Sets In Master Box<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="set_per_master_box"
                                id="set_per_master_box" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <!-- <mat-hint class="has-error" *ngIf="ProductEdit.controls.set_per_master_box.invalid && 
                            ProductEdit.controls['set_per_master_box']?.touched">
                        </mat-hint> -->
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>With Rivet or Padkit<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="with_rivet_or_pad_kit"
                                id="with_rivet_or_pad_kit" >
                        </mat-form-field>
                        <!-- <mat-hint class="has-error" *ngIf="ProductEdit.controls.set_per_master_box.invalid && 
                            ProductEdit.controls['set_per_master_box']?.touched">
                        </mat-hint> -->
                    </div>

                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="AddProduct.invalid">Save</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>

<!-- Add Product Detail End -->


<!-- Add Distributor Category Start -->
<div *ngIf="from == 'customer-category-add'">
    <div mat-dialog-title class="model-head">
        <h2>Add Category</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>

    <form #form="ngForm" [formGroup]="AddCategory" (ngSubmit)="AddCategoryFunction()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">

                    <div class="col s12 m8">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Category Name<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="category" id="category">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AddCategory.controls.category.invalid && 
                        AddCategory.controls['category']?.touched">
                        </mat-hint>
                    </div>

                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="AddCategory.invalid">Save</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>        



<!-- Add Distributor Category End -->



<!-- Edit Distributor Category Start -->
<div *ngIf="from == 'customer-category-edit'">
    <div mat-dialog-title class="model-head">
        <h2>Update Category</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="UpdateCategory" (ngSubmit)="UpdateCategoryFunction()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">

                    <div class="col s12 m8">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Category Name<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="category" id="category">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="UpdateCategory.controls.category.invalid && 
                        UpdateCategory.controls['category']?.touched">
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>    
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="UpdateCategory.invalid">Save</button>
            </div>
        </mat-dialog-actions>   
    </form>
</div>             
<!-- Edit Distributor Category End -->