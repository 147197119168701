<mat-toolbar *ngIf="db.userLoginData && db.userLoginData.data && db.userLoginData.data.id" class="header-toolbar">
    <div class="cs-logo-outer">
        <img src="assets/img/logo.png" alt="">
      </div>

    <button  mat-icon-button class="ml5" (click)="toggleHeader()">
        <mat-icon class="menu-icon">menu</mat-icon>
    </button>
    <div class="logout-section df ac">
        <div class="name-section">
            <p>Welcome:- {{db.userLoginData.data.username}}</p>
        </div>
        <button mat-button matTooltipPosition="below" matTooltip="Logout" (click)="logout()">
            <i class="material-icons">power_settings_new</i>
            Logout
        </button>
    </div>
</mat-toolbar>
