<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac" style="height:100px;">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>Primary Orders </h2>
                    <p>{{orderListData.length}} Orders Are Available</p>


                </div>


                <!-- header table start -->
                <div class="cs-table" >
                    <!-- Table Head Start-->
                    <figure class="table-head">
                        <section class="td_cell" style="background:transparent; color:white;padding:0;">
                            <main>
                                <div class="div_cell mmw110 text-center" style="background:transparent"></div>

                                <div class="div_cell mmw110 text-center"
                                    style="background:transparent;align-items: center;">{{last_3_month_data[0].month}}
                                </div>
                                <div class="div_cell mmw110 text-center"
                                    style="background:transparent;align-items: center;">{{last_3_month_data[1].month}}
                                </div>
                                <div class="div_cell mmw110 text-center"
                                    style="background:transparent;align-items: center;">{{last_3_month_data[2].month}}
                                </div>
                            </main>
                        </section>
                    </figure>
                    <!-- table head end -->

                    <section class="td_cell" style="color:white; padding:0;">
                        <ng-container *ngIf="!loader">
                            <main>
                                <div class="div_cell mmw110" style="align-items: center;">
                                    Order Qty
                                </div>
                                <div class="div_cell mmw110 text-center af-bdr-left" style="align-items: center;">
                                    {{last_3_month_data[0].total_qty}}
                                </div>
                                <div class="div_cell mmw110 text-center af-bdr-left" style="align-items: center;">
                                    {{last_3_month_data[1].total_qty}}

                                </div>
                                <div class="div_cell mmw110 text-center af-bdr-left" style="align-items: center;">
                                    {{last_3_month_data[2].total_qty}}

                                </div>
                            </main>
                        </ng-container>
                    </section>
                    <section class="td_cell" style="color:white;padding:0;">
                        <ng-container *ngIf="!loader">
                            <main>
                                <div class="div_cell mmw110" style="align-items: center;">
                                    Order Value
                                </div>
                                <div class="div_cell mmw110" style="align-items: center;">
                                    {{last_3_month_data[0].total_amt | number:'1.2-2' }}

                                </div>
                                <div class="div_cell mmw110" style="align-items: center;">
                                    {{last_3_month_data[1].total_amt | number:'1.2-2'}}

                                </div>
                                <div class="div_cell mmw110" style="align-items: center;">
                                    {{last_3_month_data[2].total_amt | number:'1.2-2'}}

                                </div>
                            </main>
                        </ng-container>

                    </section>

                </div>

                <!-- header table end -->

                <!-- <div class="module-name ml10" *ngIf="!loader">
                    <h2>Last 3 Month Total Amount</h2>
                    <p>{{last_3_month_data.three_month_total_amt}}</p>
                </div>
                <div class="module-name ml10" *ngIf="!loader">
                    <h2>Last 3 Month Total Qty</h2>
                    <p>{{last_3_month_data.three_month_total_order_qty}}</p>
                </div> -->

                <div class="left-auto">



                    <ul class="date-filter">
                        <li>
                            <div class="cs-form pb0">
                                <p>Date From</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                    <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                        placeholder="Search ..." name="date_from" [(ngModel)]="orderSearch.date_from"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li>
                            <div class="cs-form pb0">
                                <p>Date To</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                    <input type="text" matInput [matDatepicker]="picker3" [max]="date" [min]="orderSearch.date_from"
                                        placeholder="Search ..." name="date_to" [(ngModel)]="orderSearch.date_to"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <div style="flex-direction: column;align-items: center;justify-content: center;padding-bottom: 10px;">
                        <li class="cs-btn mt15 mr10" *ngIf="this.orderSearch.date_from && this.orderSearch.date_to">
                            <a mat-button (click)="orderList(1,status)">Go</a>
                        </li>
                        <li class="cs-btn mt15" *ngIf="this.orderSearch.date_from || this.orderSearch.date_to">
                            <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                        </li>
                    </div>
                        <!-- <ng-container *ngIf="currentDay <= 25"> -->
                        <ng-container >
                            <li class="cs-btn mt15 ml10"
                                *ngIf="(apiHit.userLoginData.data.user_type=='approval_user' && apiHit.userLoginData.data.moduleData[4]['view'] == 1)">
                                <a mat-button routerLink="../primaryOrder-add">Add new</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <!-- <div class="left-auto" *ngIf="!loader">
                    <div class="date-filter">
                        <ul>
                            <li>
                                <p>From</p>
                                <mat-form-field (click)="picker2.open()">
                                    <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                        placeholder="Search ..." name="date_from"
                                        [(ngModel)]="orderSearch.date_from"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </li>
                            <li>
                                <p>To</p>
                                <mat-form-field (click)="picker3.open()">
                                    <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                        placeholder="Search ..." name="date_to"
                                        [(ngModel)]="orderSearch.date_to" (dateChange)="orderList(1,status)"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </li>
                        </ul>
                    </div>
                </div> -->

            </ng-container>
            <div class="cs-action ml10">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="orderList(1,status)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
            </div>

            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 primaryheight-calc no-padding">
            <mat-tab-group class="tab-group flat-tab-body tab-group-list" [selectedIndex]="matTabValue"
                (selectedTabChange)="tabClick($event)" mat-align-tabs="start">

                <mat-tab label="1">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">inbox</mat-icon>
                        All
                        <span class="badge">{{count.all}}</span>
                    </ng-template>
                    

                </mat-tab>

                <mat-tab label="2">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">history</mat-icon>
                        Pending
                        <span class="badge">{{count.pending}}</span>
                    </ng-template>
                </mat-tab>

                <mat-tab label="3">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">verified</mat-icon>
                        Approved
                        <span class="badge">{{count.verified}}</span>
                    </ng-template>
                </mat-tab>

                <mat-tab label="4">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">cancel_schedule_send</mat-icon>
                        Reject
                        <span class="badge">{{count.reject}}</span>
                    </ng-template>
                </mat-tab>

                <mat-tab label="5">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">pan_tool</mat-icon>
                        Hold
                        <span class="badge">{{count.hold}}</span>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>

           

            <div class="cs-table">
                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw150">Date Created</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'" >Created By</div>
                            <div class="div_cell mmw120">Order Id</div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">Distributor Name</div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">Customer  Category</div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">State</div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">District</div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">Zone</div>
                            <div class="div_cell mmw90">No Of Item</div>
                            <div class="div_cell mmw90">Total Qty</div>
                            <div class="div_cell mmw90">Dispatch Qty</div>
                            <div class="div_cell mmw90">Pending Qty</div>
                            <div class="div_cell mmw100">Order Value</div>
                            <div class="div_cell mw120">Remark</div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">Status</div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">SAP Status</div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">Reject Reason</div>
                            <div class="div_cell mmw130">Action</div>

                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw150">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="created_date"
                                            [(ngModel)]="orderSearch.created_date" (dateChange)="onDate($event)"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'"></div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="order_id"
                                        [(ngModel)]="orderSearch.order_id" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_by_name"
                                        [(ngModel)]="orderSearch.created_by_name" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="customer_category"
                                        [(ngModel)]="orderSearch.customer_category" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <select name="user_state" [(ngModel)]="orderSearch.user_state"
                                        (change)="orderList(1,status)" id="user_state">
                                        <option value="">All</option>
                                        <option *ngFor="let data of stateData; let i=index" value="{{data}}">{{data}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="district_name"
                                        [(ngModel)]="orderSearch.district_name" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <select name="zone" [(ngModel)]="orderSearch.zone" (change)="orderList(1,status)"
                                        id="zone">
                                        <option value="">All</option>
                                        <option value="North">North</option>
                                        <option value="East">East</option>
                                        <option value="West">West</option>
                                        <option value="South">South</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw90" *ngIf="loader">&nbsp;</div>
                            <div class="div_cell mmw90" *ngIf="!loader">{{count.totalOrderQty ? count.totalOrderQty : 0}}</div>
                            <div class="div_cell mw90" *ngIf="loader">&nbsp;</div>
                            <div class="div_cell mw90" *ngIf="!loader">{{count.totalDispatchQty ? count.totalDispatchQty : 0}}</div>
                            <div class="div_cell mmw90" *ngIf="loader">&nbsp;</div>
                            <div class="div_cell mmw90" *ngIf="!loader">{{count.totalPendingQty ? count.totalPendingQty : 0}}</div>
                            <div class="div_cell mmw100">{{count.totalamt ? count.totalamt : 0}}</div>
                           
                            
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <select name="order_status" [(ngModel)]="orderSearch.order_status"
                                        (change)="orderList(1,status)" id="order_status">
                                        <option value="">All</option>
                                        <option value="1">Pending </option>
                                        <option value="2">Approved</option>
                                        <option value="3">Reject</option>
                                        <option value="4">Hold </option>
                                    </select>
                                </div>
                            </div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <select name="sap_status_option" [(ngModel)]="orderSearch.sap_status_option"
                                        (change)="orderList(1,status)" id="sap_status_option">
                                        <option value="">All</option>
                                        <option value="Submit">Submit</option>
                                        <option value="Pending">Pending </option>
                                        <option value="Reject">Reject</option>
                                        <option value="Hold ">Hold </option>
                                    </select>
                                </div>
                                <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="orderList(1,status)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">

                            </div>
                            <div class="div_cell mmw130">
                                <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="orderList(1,status)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of orderListData; let i = index;">
                            <div class="div_cell mmw50 ac ">
                                <div class="dflex">
                                    <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                    <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                    <mat-checkbox name="checked" [(ngModel)]="data.checked" (change)="selected_item($event,i,data.order_id)"></mat-checkbox>
                            </div>
                            </div>
                            <div class="div_cell mmw150">{{data.order_created_date | date: 'd MMM y, h:mm a'}}</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">{{data.created_by_name ? data.created_by_name : 'Self'}}
                            </div>
                            <div class="div_cell mmw120">
                                <a class="link-btn" mat-flat-button
                                    routerLink="../order-detail/{{data.order_id}}">#AFF-{{data.order_id ? data.order_id
                                    : 'N/A'}}</a>
                            </div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">{{data.distributer_name ? (data.distributer_name | titlecase) :
                                'N/A'}}</div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">{{data.customer_category ? (data.customer_category | titlecase) : 'N/A'}}
                                </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">{{data.user_state ? (data.user_state | titlecase) : 'N/A'}}
                            </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">{{data.user_district ? (data.user_district | titlecase) : 'N/A'}}
                            </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">{{data.zone ? (data.zone | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw90">
                                {{data.item_count ? data.item_count : '0'}}
                            </div>
                            <div class="div_cell mmw90">
                                {{data.total_qty ? data.total_qty : '0'}}
                            </div>
                            <div class="div_cell mmw90">
                                {{data.Dispatch_qty ? data.Dispatch_qty : '0'}}
                            </div>
                            <div class="div_cell mmw90">
                                {{data.Pending_qty ? data.Pending_qty : '0'}}
                            </div>
                            <div class="div_cell mmw100">
                                {{data.total_amount ? (data.total_amount | number : '1.2-2') : '0'}}
                            </div>
                            <div class="div_cell mw120">
                                <span>
                                    {{data.order_remarks ? (data.order_remarks | titlecase) : 'N/A'}}
                                </span>
                            </div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <span *ngIf="data.order_status == 1" class="yellow-clr">Pending</span>
                                <span *ngIf="data.order_status == 2" class="green-clr">Approved</span>
                                <span *ngIf="data.order_status == 3" class="red-clr">Reject</span>
                                <span *ngIf="data.order_status == 4" class="yellow-clr">Hold</span>
                            </div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <span *ngIf="data.sap_status == 'Pending'" class="yellow-clr">Pending</span>
                                <span *ngIf="data.sap_status == 'Submit'" class="green-clr">Submit</span>
                                <span *ngIf="data.sap_status == 'Hold'" class="yellow-clr">Hold</span>
                                <span *ngIf="data.sap_status == 'Reject'" class="red-clr">Reject</span>
                            </div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">{{data.reason_reject ?
                                data.reason_reject : 'N/A'}}
                            </div>
                            <div class="div_cell mmw130">
                                <div class="cs-action" *ngIf="data.sap_order_1">
                                    <!-- [disabled]="data.wallet_balance=='0' && data.scheme_wallet =='0'" -->
                                    <button class="ac-green with-text" *ngIf="data.pdf_flag == '1'"
                                        (click)="generatePdf(data.order_id)" matRipple matTooltip="Export To PDF"
                                        matTooltipPosition="below">Export To PDF</button>
                                    <button class="ac-yellow with-text" *ngIf="data.pdf_flag == '0'"
                                        (click)="generatePdf(data.order_id)" matRipple matTooltip="Export To PDF"
                                        matTooltipPosition="below">Export To PDF</button>
                                </div>
                            </div>

                        </main>

                        <app-not-found *ngIf="orderListData.length == 0"></app-not-found>
                    </ng-container>



                    <!-- Skeleton Loading Start -->

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw150">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120" >
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90" >
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90" >
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>

                    <!-- Skeleton Loading End -->
                </section>
                <!-- <div class="col s12 m6" *ngIf="!loader">
                    <div class="cs-btn mt8 text-right">
                        <button type="submit" (click)="orderList(3,status)" mat-button>Export</button>
                    </div>
                </div> -->
            </div>




            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac">

                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="multi-btns" *ngIf="OrderData.length > 0 && data_val?.login_type != 'distributor'" >
                        <a mat-raised-button color="accent" *ngIf="orderListData.length > 0"
                            (click)="exportOrderExcelFunction()" class="ml10"><img src="assets/img/excel.png"> Order Place </a>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="orderListData.length > 0"
                            (click)="exportExcelFunction()" class="ml10"><img src="assets/img/excel.png"> Download
                            Excel</a>
                    </div>
                    

                </div>
            </div>
            <!-- Pagination End -->
        </div>

    </div>
</div>

<!-- <div id="content" #content> -->
<!-- <html>
    <head> -->
<table hidden="true" *ngIf="!loader" id="content" #content cellpadding="0"
    style="width:1000px; margin: 0px auto; font-size:12px; padding: 0px; background: #fff;border: 2px solid #000000;"
    cellpadding="0" cellspacing="0">
    <tr>
        <td style="padding:0px; width: 1000px;">
            <table
                style="width:1000px; font-size: 12px; border-collapse:collapse;border-bottom: 2px solid #000;background: #c5c5c5;">
                <tr>
                    <td style="width:1000px; padding: 8px; text-align: center;"><strong>ORDER DETAIL</strong></td>
                </tr>
            </table>

            <table style="width:1000px; border-collapse:collapse;">
                <tr>
                    <td
                        style="width:300px;padding: 0px;padding-left: 10px;text-align: center;border-right:1px solid #000;">
                        <img style="height: 60px;" src="../../../assets/img/logo.png">
                    </td>
                    <td style="width:700px;padding: 0px;">
                        <table style="width:700px; border-collapse:collapse;">
                            <tr>
                                <td style="padding: 5px;text-align: center;">{{distrubutorInfo.dr_name ?
                                    distrubutorInfo.dr_name : 'N/A'}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

            <table style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                <tr>
                    <td
                        style="padding: 5px;width:400px;text-align: center;font-weight: 600;border-right:1px solid #000;">
                        Distributor Information</td>
                    <td style="padding: 5px;width:600px;text-align: center;font-weight: 600;">Order Information</td>
                </tr>
            </table>

            <table style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                <tr>
                    <td style="width:400px;padding: 0px;border-right: 1px solid #000000">
                        <table style="width:400px; border-collapse:collapse;font-size: 12px;">
                            <tr>
                                <td
                                    style="padding:5px;width: 90px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">
                                    Company Name :-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                    {{distrubutorInfo.dr_name ? distrubutorInfo.dr_name : 'N/A'}}</td>
                            </tr>
                            <tr>
                                <td
                                    style="padding:5px;width: 90px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">
                                    Contact Name :-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                    {{distrubutorInfo.contact_name ? distrubutorInfo.contact_name : 'N/A'}}</td>
                            </tr>
                            <tr>
                                <td
                                    style="padding:5px;width: 90px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">
                                    DR Code :-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                    {{distrubutorInfo.dr_code ? distrubutorInfo.dr_code : 'N/A'}}</td>
                            </tr>

                            <tr>
                                <td
                                    style="padding:5px;width: 90px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                    Mobile No.:-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                    {{distrubutorInfo.mobile_no ? distrubutorInfo.mobile_no : 'N/A'}}</td>
                            </tr>

                            <tr>
                                <td
                                    style="padding:5px;width: 90px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                    Email :-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                    {{distrubutorInfo.email_id ? distrubutorInfo.email_id : 'N/A'}}</td>
                            </tr>

                            <tr>
                                <td
                                    style="padding:5px;width: 90px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                    Credit :-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">₹
                                    {{distrubutorInfo.credit_limit ? distrubutorInfo.credit_limit:'0'}}</td>
                            </tr>

                            <tr>
                                <td style="padding:5px;width: 90px; text-align: left;border-right: 1px solid #000000;">
                                    District & State:-</td>
                                <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                    {{distrubutorInfo.district_name ? distrubutorInfo.district_name : 'N/A'}} -
                                    {{distrubutorInfo.state_name ? distrubutorInfo.state_name : 'N/A'}}</td>
                            </tr>
                        </table>
                    </td>
                    <td style="width:600px;padding: 0px;">
                        <table style="width:600px; border-collapse:collapse;font-size: 12px;">
                            <tr>
                                <td style="width:300px;padding: 0px;">
                                    <table style="width:300px; border-collapse:collapse;font-size: 12px;">
                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">
                                                Date :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.created_date ?
                                                (orderDetail.created_date | date: 'd MMM y, h:mm a' ):'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">
                                                Status Updated Date :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderListPdf[0]?.last_updated ?
                                                (orderListPdf[0].last_updated | date: 'd MMM y, h:mm a' ):'N/A'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">
                                                Status :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                <strong *ngIf="orderDetail.order_status == 1">Pending</strong>
                                                <strong *ngIf="orderDetail.order_status == 2">Approved</strong>
                                                <strong *ngIf="orderDetail.order_status == 3">Reject</strong>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                SAP Status :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.sap_status ? orderDetail.sap_status : 'N/A'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                Order ID :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                #AFF-{{orderDetail.order_id ? orderDetail.order_id : 'N/A'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                No Of Items :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.total_items ? orderDetail.total_items : '0'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-right: 1px solid #000000;">
                                                Shipping Address :-</td>
                                            <td style="padding:5px;text-align: left;">{{distrubutorInfo.shipping_address
                                                ?
                                                distrubutorInfo.shipping_address:'N/A'}}</td>
                                        </tr>
                                    </table>
                                </td>

                                <td style="width:300px;padding: 0px;">
                                    <table style="width:300px; border-collapse:collapse;font-size: 12px;">
                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                Total Qty :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.total_quantity ?
                                                orderDetail.total_quantity:'0'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                List Discount :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.list_discount ? orderDetail.list_discount : '0'}}</td>
                                        </tr>

                                        <tr *ngIf="orderDetail.invoice_discount">
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                Invoice Discount :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.invoice_discount ?
                                                orderDetail.invoice_discount:'0'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                Transporter :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.transport ?
                                                orderDetail.transport:'N/A'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                SAP Order No. 1 :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.sap_order_1 ?
                                                orderDetail.sap_order_1:'N/A'}}</td>
                                        </tr>

                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">
                                                SAP Order No. 2 :-</td>
                                            <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                {{orderDetail.sap_order_2 ? orderDetail.sap_order_2 : 'N/A'}}</td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="padding:5px;width: 130px; text-align: left;border-right: 1px solid #000000;">
                                                SAP Order No. 3 :-</td>
                                            <td style="padding:5px;text-align: left;">{{orderDetail.sap_order_3 ?
                                                orderDetail.sap_order_3:'N/A'}}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

            <table *ngIf="orderListPdf.length > 0"
                style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                <tr>
                    <td style="padding: 5px;width:1000px;text-align: center;font-weight: 600;">Item Information</td>
                </tr>
            </table>
            <table *ngIf="orderListPdf.length > 0" style="width:1000px;  border-collapse:collapse; font-size: 12px;">
                <tr>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; border-left: 0px; width: 40px;">
                        S.No</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:left; padding: 4px;width: 270px;">
                        Segment</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 50px;">
                        Brand</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 40px;">
                        Material Code</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 60px;">
                        Part Code</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 60px;">
                        Qty</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:right; padding: 4px; width: 60px;">
                        Company Stock</th>
                </tr>
                <tr *ngFor="let data of orderListPdf; let i=index">
                    <td
                        style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px; border-left: 0px;">
                        {{i+1}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:left; padding: 4px;">
                        {{data.segment ?
                        data.segment:'N/A'}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">
                        {{data.category ?
                        (data.category | titlecase ):'N/A'}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">
                        {{data.material_code ?
                        (data.material_code | uppercase ):'N/A'}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">
                        {{data.product_code ? data.product_code:'N/A'}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">{{data.qty
                        ?
                        data.qty:'0'}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:right; padding: 4px;">
                        {{data.total_Company_Stock ?
                        data.total_Company_Stock:'0'}}</td>
                </tr>
            </table>

            <table *ngIf="invoiceData.length > 0"
                style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                <tr>
                    <td style="padding: 5px;width:1000px;text-align: center;font-weight: 600;">Invoice Information</td>
                </tr>
            </table>
            <table *ngIf="invoiceData.length > 0" style="width:1000px;  border-collapse:collapse; font-size: 12px;">
                <tr>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; border-left: 0px; width: 40px;">
                        S.No</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:left; padding: 4px;width: 270px;">
                        Date</th>
                    <th
                        style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 50px;">
                        Invoice Number</th>

                </tr>
                <tr *ngFor="let data of invoiceData; let i=index">
                    <td
                        style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px; border-left: 0px;">
                        {{i+1}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:left; padding: 4px;">
                        {{data.Bill_Date ? (data.Bill_Date | date: 'd MMM y') : 'N/A'}}</td>
                    <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">
                        {{data.ODN_Number ? data.ODN_Number:'N/A'}}</td>
                </tr>
            </table>
        </td>
    </tr>
</table>
<!-- </head>
</html> -->