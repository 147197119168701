import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';


@Component({
    selector: 'app-retailer-add',
    templateUrl: './retailer-add.component.html'
})
export class RetailerAddComponent implements OnInit {
    
    AddRetailerForm : FormGroup;
    date : any;
    loader : any;
    distributorData : any;
    districtData : any;
    stateData : any;
    minDate: Date;
    maxDate: Date;
    assignDistributor : any = [];
    stataData: any;
    selectedDistrict: any;
    allData: any=[];
    userId : any;
    
    constructor(
        
        private fb : FormBuilder,
        public apiHit : DatabaseService,
        public dialog : DialogService,
        public router : Router,
        
        
        ) { 
            
            this.date=new Date();
            this.distributorList();
            this.stateList();
            this.userId= this.apiHit.userLoginData.data.id;
            const currentYear = new Date().getFullYear();
            this.maxDate = new Date(currentYear - 15, 11, 31);
            
        }
        
        ngOnInit(): void {
            
            this.AddRetailerForm = this.fb.group({
                
                firmName          :   ['',[Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                firstName         :   ['',[Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                mobileNo          :   ['',[Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                email             :   ['',[Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                DOB               :   [''],
                
                anniversary       :   [''],
                gstNo             :   ['',[Validators.minLength(15), Validators.maxLength(15)]],
                
                // , Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$")
                panNo             :   ['',[Validators.minLength(10), Validators.maxLength(10)]],
                
                // , Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")
                distributor_id    :   ['',[Validators.required]],
                
                cPersonName       :   ['',[Validators.minLength(3), Validators.maxLength(30)]],
                cPersonMobileNo   :   ['',[Validators.minLength(10), Validators.maxLength(10)]],
                cPersonEmail      :   ['',[Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                
                pinNo             :   ['',[Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                state             :   ['',[Validators.required]],
                district          :   ['',[Validators.required]],
                city              :   ['',[Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                
                address           :   ['',[Validators.minLength(3)]],
                
                dr_type           :   [3 ,[Validators.required]],
                uid               :   [this.userId],
                searchCtrl: [''],

                
                
            })
            
        }
        
        
        minDateSelect(){
            
            this.minDate=this.AddRetailerForm.value.DOB;
            
        }
        
        
        
        //Check Mobile No Duplicacy On NgModel Function Start
        
        checkMobileNoDuplicacy() {
            
            if(this.AddRetailerForm.value.mobileNo.length == 10){
                
                this.apiHit.PostRequest( {
                    
                    "type"      :   'dr',
                    "mobileNo"  : this.AddRetailerForm.value.mobileNo,
                    'id'       : '' 
                    
                }, "Distributor/check_user").subscribe((result => {
                    
                    
                    if(result['exists'] == 1){
                        
                        this.AddRetailerForm.controls.mobileNo.setValue('');
                        this.dialog.error('error', 'Mobile Already Exists');
                        
                    }
                    
                    setTimeout(() => {
                        
                        this.loader = '';
                        
                    }, 700);
                    
                }));
                
            }
            
        }
        //Check Mobile No Duplicacy On NgModel Function End
        
        
        enterPin(formData) {
            
            
            if(formData.controls.pinNo.value.length==6){
                
                
                this.apiHit.PostRequest1( {
                    
                    'pincode': formData.controls.pinNo.value
                    
                }, "AppMechanicDetails/getPincodeDetails").subscribe((result => {
                    
                    
                    console.log(result);
                    
                    setTimeout(() => {
                        this.selectedDistrict= result['pincodeDetails'].district;
                        
                        this.AddRetailerForm.controls.district.setValue(this.selectedDistrict);
                        
                    }, 1000);
                    
                    this.stataData = result['pincodeDetails'].state;
                    
                    this.AddRetailerForm.controls.state.setValue(this.stataData);
                    this.AddRetailerForm.controls.district.setValue(this.selectedDistrict);
                    
                    this.districtList(this.stataData)
                }
                ));
            }
        }
        
        
        //Save Retailer Function Start
        
        saveRetailer(){
            
            this.loader =  1;
            
            if(this.AddRetailerForm.controls.DOB.value){
                
                this.AddRetailerForm.controls.DOB.setValue(moment(this.AddRetailerForm.controls.DOB.value).format('YYYY-MM-DD'));
                
            }
            
            
            if(this.AddRetailerForm.controls.anniversary.value){
                
                this.AddRetailerForm.controls.anniversary.setValue(moment(this.AddRetailerForm.controls.anniversary.value).format('YYYY-MM-DD'));
                
            }
            
            this.dialog.save_Retailer('').then((result) => {
                
                if(result === true) {
                    
                    this.apiHit.PostRequest(this.AddRetailerForm.value , "Distributor/add_dealer").subscribe((result => {
                        
                        if(result['distributors_add'] == 'success'){
                            
                            this.dialog.success('Success', ' Retailer Sucessfully Added');
                            this.router.navigate(['retailer']);
                            
                        }else{
                            
                            this.dialog.error('error', 'error');
                            
                        }
                        
                        setTimeout(() => {
                            
                            this.loader = '';
                            
                        }, 700);
                        
                    }));
                };
            })
            
            
        }
        //Save Retailer Function End
        
        
        //Distributor List Function Start
        
        distributorList(){
            console.log('show distributor');
            
            this.apiHit.PostRequest( '', "Distributor/getDistributor").subscribe((result => {
                
                this.distributorData = result['distributors'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Distributor List Function Start
        
        
        //State List Fetch From Database Through API  Start
        
        stateList() {
            
            this.apiHit.PostRequest( '', "Distributor/state_list").subscribe((result => {
                
                this.stateData = result['state_list']['state_name'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //State List Fetch From Database Through API  End
        
        
        
        districtList1(){
            
            this.apiHit.PostRequest( {'state' : this.AddRetailerForm.value.state}, "Distributor/district_list").subscribe((result => {
                
                this.districtData = result['district_list']['district_name'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        
        //District List Fetch From Database Through API  Start
        
        districtList(district){
            
            this.apiHit.PostRequest( {'state' : district}, "Distributor/district_list").subscribe((result => {
                
                this.districtData = result['district_list']['district_name'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //District List Fetch From Database Through API  End
        
        
        // KeyPress Function  Start
        
        OnlyNumber(event: any){
            
            const pattern = /[0-9\'']/;
            let inputChar = String.fromCharCode(event.charCode);
            
            if (event.keyCode != 8 && !pattern.test(inputChar)) { 
                
                event.preventDefault(); 
                
            }
        }
        // KeyPress Function  End
        
    }