<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>Products</h2>
                    <p>{{totalProduct}} Products Available</p>
                </div>
                
                <div class="left-auto" *ngIf="apiHit.userLoginData.data.moduleData[11]['add'] == 1">
                    <div class="cs-btn mt0">
                        <a mat-button
                            (click)="productEdit(1,'')">Add new</a>
                    </div>
                   
                </div>
            </ng-container>
            <div class="cs-action">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="productList(1)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">

            <div class="cs-table">


                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date Created</div>
                            <div class="div_cell mmw90">Created By</div>
                            <div class="div_cell mmw90">Segment</div>
                            <div class="div_cell mmw100">Brand</div>
                            <div class="div_cell mmw120">Material Code</div>
                            <div class="div_cell mw230">Part Code</div>
                            <div class="div_cell mmw120">Set Box Pack</div>
                            <div class="div_cell mmw120">Short Part Code</div>
                            <div class="div_cell mmw120">With Rivet or Padkit</div>
                            <div class="div_cell mmw80">Stock Alert</div>
                            <div class="div_cell mmw90">Coupon Point</div>
                            <div class="div_cell mmw80">MRP</div>
                            <div class="div_cell mmw80">List Price</div>
                            <div class="div_cell mmw80">Set Box</div>
                            <div class="div_cell mmw90">Box Size</div>
                            <div class="div_cell mmw140">MRP For Master Box</div>
                            <div class="div_cell mmw120">Master Box Pack</div>
                            <div class="div_cell mmw120">Sets In Master Box</div>
                            <div class="div_cell mmw110">Last Updated</div>
                            <div class="div_cell mmw110">Updated By</div>
                            <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                        </main>
                    </section>

                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>

                            <div class="div_cell mmw110">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="productList(1);">
                                </div>
                            </div>

                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="category"
                                        [(ngModel)]="filterSearch.category" (keyup.enter)="productList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="material_code"
                                        [(ngModel)]="filterSearch.material_code" (keyup.enter)="productList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw230">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                        [(ngModel)]="filterSearch.part_code" (keyup.enter)="productList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw120">&nbsp;</div>
                            <div class="div_cell mmw110">&nbsp;</div>
                            <div class="div_cell mmw110">&nbsp;</div>
                            <div class="div_cell text-center mmw70 af-bdr-right">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="productList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">

                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of productData; let i = index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">{{data.date_created ? (data.date_created | date: 'd MMM y') : 'N/A'}}</div>
                            <div class="div_cell mmw90">{{data.created_by_name | titlecase}}</div>
                            <div class="div_cell mmw90">{{data.segment ? (data.segment | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw100">{{data.brand ? (data.brand | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw120">{{data.material_code ? (data.material_code | uppercase) : 'N/A'}}</div>
                            <div class="div_cell mw230">{{data.part_code ? (data.part_code | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mw120">{{data.set_box_pack ? (data.set_box_pack| titlecase) : 'N/A'}}</div>
                            <div class="div_cell mw120">{{data.short_part_code ? (data.short_part_code | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mw120">{{data.with_rivet_or_pad_kit ? (data.with_rivet_or_pad_kit | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw80">{{data.default_stock ? data.default_stock : '0'}}</div>
                            <div class="div_cell mmw90">{{data.coupon_value ? data.coupon_value : '0'}}</div>
                            <div class="div_cell mmw80">₹ {{data.mrp ? data.mrp : '0'}}</div>
                            <div class="div_cell mmw80">₹ {{data.list_price ? data.list_price : '0'}}</div>
                            <div class="div_cell mmw80">₹ {{data.set_box ? data.set_box : '0'}}</div>
                            <div class="div_cell mmw90">{{data.box_size ? data.box_size : '0'}}</div>
                            <div class="div_cell mmw140">₹ {{data.box_mrp ? data.box_mrp : '0'}}</div>
                            <div class="div_cell mmw120">{{data.master_box_pack ? data.master_box_pack : 'N/A'}}</div>
                            <div class="div_cell mmw120">{{data.set_per_master_box ? data.set_per_master_box : '0'}}</div>
                            <div class="div_cell mmw110">{{data.date_updated && data.date_updated!='0000-00-00 00:00:00' ? (data.date_updated | date: 'd MMM y') : 'N/A'}}</div>
                            <div class="div_cell mmw110">{{data.updated_by_name ? (data.updated_by_name | titlecase) : 'N/A'}}</div>
                            <div class="div_cell ac mmw70">
                                <div class="cs-action">
                                    <ng-container>
                                        <a class="ac-green" matRipple matTooltip="Edit" matTooltipPosition="below" *ngIf="apiHit.userLoginData.data.moduleData[11]['edit'] == 1"><i
                                            class="material-icons" (click)="productEdit(2,data)">edit</i></a>
                                        <a class="ac-red" matRipple matTooltip="Delete" matTooltipPosition="below" *ngIf="apiHit.userLoginData.data.moduleData[11]['delete'] == 1"><i
                                                class="material-icons"
                                            (click)="deleteProduct(data)">delete_sweep</i></a>
                                    </ng-container>
                                </div>
                            </div>
                        </main>
                        <app-not-found *ngIf="productData.length == 0"></app-not-found>
                    </ng-container>


                    <!-- Skeleton Loading Start -->

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;">
                            <div class="div_cell mmw50">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw230">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw70">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>

                    <!-- Skeleton Loading End -->

                </section>
            </div>



            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                matTooltipPosition="above"><i class="material-icons">chevron_right</i></a>
                            </li>
                        </ul>
                    </div>

                    <div class="multi-btns">
                        <!-- <a mat-button class="pulse" (click)="productList(3)"><img src="assets/img/excel.png"></a> -->
                        <a mat-raised-button color="accent" *ngIf="apiHit.userLoginData.data.moduleData[11]['add'] == 1" (click)="ExcelUploadFunction()"><img src="assets/img/excel.png"> Upload Excel</a>
                        <a mat-raised-button color="accent"   (click)="productList(3)" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>

                </div>
            </div>
            <!-- Pagination End -->

        </div>

    </div>
</div>