<!-- Change Order Status Start -->

<div *ngIf="dataValue == 1">
    <div mat-dialog-title class="model-head">
        <h2>Change Order Status</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="ChangeStatus" (ngSubmit)="changeStatusFunction()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">
                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label> Status <span class="sub">*</span></mat-label>
                            <mat-select formControlName="status" id="status" (ngModelChange)="checkOrderStatus()">
                                <mat-option value="1">Pending</mat-option>
                                <mat-option value="2">Approved</mat-option>
                                <mat-option value="3">Reject</mat-option>
                                <mat-option value="4">Hold</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="ChangeStatus.controls.status.value == 3 || ChangeStatus.controls.status.value == 4" class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>{{ChangeStatus.controls.status.value == 3 ? 'Reject ' : 'Hold '}}Reason<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="reason_reject"
                                id="reason_reject">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="ChangeStatus.controls.reason_reject.invalid && 
                            ChangeStatus.controls['reason_reject']?.touched && !ChangeStatus.controls['reason_reject']['errors']?.minlength">
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="ChangeStatus.invalid">Update</button>
                <!-- <button  mat-button >Save</button> -->
            </div>
        </mat-dialog-actions>
    </form>
</div>

<!-- Change Order Status End -->



<!-- Edit Product Qty Start -->
<div *ngIf="dataValue == 2">
    <div mat-dialog-title class="model-head">
        <h2>Edit Product</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="QuantityEdit" (ngSubmit)="updateQuantity()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0" *ngIf="this.apiHit.userLoginData.data.id == 1">
                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Segment <span class="sub">*</span></mat-label>
                            <mat-select formControlName="segment" name="segment" id="segment">
                                <mat-option (click)="getCategory(data.segment,2)"
                                    *ngFor="let data of segmentdata" value="{{data.segment}}">
                                    {{data.segment}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="QuantityEdit.controls.segment.invalid && 
                        QuantityEdit.controls.segment.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Brand <span class="sub">*</span></mat-label>
                            <mat-select formControlName="category" name="category" id="category">
                                <mat-option (click)="getPartCodeNo(data.brand,2)"
                                    *ngFor="let data of categoryName" value="{{data.brand}}">
                                    {{data.brand}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-hint class="has-error" *ngIf="QuantityEdit.controls.category.invalid && 
                        QuantityEdit.controls.category.touched">
                        </mat-hint>
                    </div>
                </div>

                <div class="row row-mb0">
                    <div class="col s12 m6" *ngIf="this.apiHit.userLoginData.data.id == 1">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Part No <span class="sub">*</span></mat-label>
                            <mat-select formControlName="part_code" name="part_code" id="part_code">
                                <ngx-mat-select-search formControlName="searchCtrl"
                                    [placeholderLabel]="'Search...'"
                                    [noEntriesFoundLabel]="'Not found'" name="search">
                                </ngx-mat-select-search>
                                <mat-option *ngFor="let data of partNo  |stringFilterBy:QuantityEdit.value.searchCtrl:'part_code'"
                                    value="{{data.part_code}}">
                                    {{data.part_code}}
                                </mat-option>
                            </mat-select>

                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="QuantityEdit.controls.part_code.invalid && 
                        QuantityEdit.controls.part_code.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Qty<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="item_qty" id="item_qty"
                                (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="QuantityEdit.controls.item_qty.invalid && 
                            QuantityEdit.controls['item_qty']?.touched">
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="QuantityEdit.invalid || buttonLoading">Update</button>
                <!-- <button  mat-button >Save</button> -->
            </div>
        </mat-dialog-actions>
    </form>
</div>

<!-- Edit Product Qty End -->


<!-- Sap Status Change Start -->

<div *ngIf="dataValue == 3">
    <div mat-dialog-title class="model-head">
        <h2>Change Order SAP Status</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="SapStatus" (ngSubmit)="changeSapStatus()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">

                <div class="row row-mb0">
                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>SAP Status <span class="sub">*</span></mat-label>
                            <mat-select formControlName="sap_status" id="sap_status" (ngModelChange)="checkSapOrderStatus()">
                                <mat-option value="Pending">Pending</mat-option>
                                <mat-option value="Submit">Submit</mat-option>
                                <mat-option value="Hold">Hold</mat-option>
                                <mat-option value="Reject">Reject</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="SapStatus.controls.sap_status.value =='Hold' || SapStatus.controls.sap_status.value =='Reject'" class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>{{SapStatus.controls.sap_status.value =='Hold' ? 'Hold ' : 'Reject '}}Reason<span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="sap_status_reason"
                                id="sap_status_reason">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="SapStatus.controls.sap_status_reason.invalid && 
                            SapStatus.controls['sap_status_reason']?.touched">
                        </mat-hint>
                    </div>
                </div>

                <div class="row row-mb0" *ngIf="SapStatus.controls.sap_status.value =='Submit'">

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>SAP Order No 1 <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="sap_orderNo1"
                                id="sap_orderNo1">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="SapStatus.controls.sap_orderNo1.invalid && 
                            SapStatus.controls['sap_orderNo1']?.touched">
                        </mat-hint>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>SAP Order No 2</mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="sap_orderNo2"
                                id="sap_orderNo2">
                        </mat-form-field>
                    </div>

                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>SAP Order No 3</mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="sap_orderNo3"
                                id="sap_orderNo3">
                        </mat-form-field>
                    </div>
                </div>

                <div class="row row-mb0" *ngIf="SapStatus.controls.sap_status.value =='Submit'">
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>List Discount <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="list_discount"
                                id="list_discount" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="SapStatus.controls.list_discount.invalid && 
                            SapStatus.controls['list_discount']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Invoice Discount </mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="invoice_discount"
                                id="invoice_discount" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="SapStatus.controls.invoice_discount.invalid && 
                            SapStatus.controls['invoice_discount']?.touched">
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Transporter <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="transporter" id="transporter">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="SapStatus.controls.transporter.invalid && 
                            SapStatus.controls['transporter']?.touched">
                        </mat-hint>
                    </div>
                </div>

            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="SapStatus.invalid">Update</button>
                <!-- <button mat-button type="submit">Update</button> -->

            </div>
        </mat-dialog-actions>
    </form>
</div>

<!-- Sap Status Change End -->