import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';


@Component({
    selector: 'app-retailer-edit',
    templateUrl: './retailer-edit.component.html'
})
export class RetailerEditComponent implements OnInit {

    stataData: any;
    selectedDistrict: any;
    EditRetailer: FormGroup;
    AddContactForm: FormGroup;
    AssignedSalesUser: FormGroup;
    EditDistributor: FormGroup;
    EditContactForm: FormGroup;
    dataValue: any;
    date: any;
    loader: any;
    distributorData: any;
    districtData: any;
    stateData: any;
    updateDataDetails: any;
    retailerStatus: boolean = false;
    retailerStatusZsm: boolean = false;
    retailerStatusVerified: boolean = false;
    minDate: Date;
    maxDate: Date;
    assign: any = [];
    searchCtrl: any;
    salesUserList: any = [];


    constructor(

        @Inject(MAT_DIALOG_DATA) public data,
        private fb: FormBuilder,
        public apiHit: DatabaseService,
        public dialog2: DialogService,
        public router: Router,
        private dialogRef: MatDialog,

    ) {

        console.log(data);
        this.dataValue = data['target'];
        console.log(this.dataValue);
        this.updateDataDetails = data['data'];
        this.date = new Date();
        const currentYear = new Date().getFullYear();
        this.maxDate = new Date(currentYear - 15, 11, 31);

    }

    ngOnInit(): void {

        this.data = this.apiHit.userLoginData.data
        console.log(this.data);
        console.log(this.updateDataDetails);

        if (this.dataValue == 1) {

            this.EditRetailer = this.fb.group({

                asm_status: [this.updateDataDetails['ASM_status']],
                zsm_status: [this.updateDataDetails['ZSM_status']],
                status: [this.updateDataDetails['status']],
                asm_status_reason: [this.updateDataDetails['ASM_status_reason']],
                zsm_status_reason: [this.updateDataDetails['ZSM_status_reason']],
                asm_approved_by: [this.updateDataDetails['ASM_updated_by']],
                zsm_approved_by: [this.updateDataDetails['ZSM_updated_by']],
                scanning_status: [this.updateDataDetails['scanning_status'],[Validators.required]],
                firmName: [this.updateDataDetails['dr_name'], [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                firstName: [this.updateDataDetails['contact_name'], [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                mobileNo: [this.updateDataDetails['mobile_no'], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                email: [this.updateDataDetails['email_id'], [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                DOB: [this.updateDataDetails['date_birth'], []],
                anniversary: [this.updateDataDetails['date_anniversary'], []],
                gstNo: [this.updateDataDetails['gst_no'], [Validators.minLength(15), Validators.maxLength(15)]],
                panNo: [this.updateDataDetails['pan_no'], [Validators.minLength(10), Validators.maxLength(10)]],
                pinNo: [this.updateDataDetails['pincode'], [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                state: [this.updateDataDetails['state_name'], [Validators.required]],
                district: [this.updateDataDetails['district_name'], [Validators.required]],
                city: [this.updateDataDetails['city_name'], [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                address: [this.updateDataDetails['street'], [Validators.minLength(3)]],
                id: [this.updateDataDetails['id'], [Validators.required]],
                searchCtrl: [''],
                distributor_id: new FormControl(''),

            });

            this.stateList();
            this.districtList(this.updateDataDetails['state_name']);
            this.minDateSelect();
            // this.checkRetailerStatus(1);
            this.checkRetailerZsmStatus(1);
            this.checkRetailerAsmStatus(1);
        }


        else if (this.dataValue == 2) {

            this.EditDistributor = this.fb.group({

                distributor_id: ['', [Validators.required]],
                id: ['', [Validators.required]],
                searchCtrl: [''],


            });

            this.distributorList(1);
            this.retailerAssignId();


        }


        else if (this.dataValue == 3) {

            this.AddContactForm = this.fb.group({

                cPersonName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                cPersonMobileNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                cPersonEmail: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                id: [this.updateDataDetails['id'], [Validators.required]],

            });

        }
        else if (this.dataValue == 5) {

            this.AssignedSalesUser = this.fb.group({

                uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
                salesUser: ['', [Validators.required]],
                dr_id: [this.updateDataDetails.details[0]['id'], [Validators.required]],
              
            });
            console.log(this.AssignedSalesUser.value)
            console.log(this.updateDataDetails.details)
            this.getSalesUsers()
        }


        else {

            this.EditContactForm = this.fb.group({

                updatePersonName: [this.updateDataDetails['contact_person'], [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                updatePersonMobileNo: [this.updateDataDetails['contact_1'], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                updatePersonEmail: [this.updateDataDetails['contact_email'], [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                id: [this.updateDataDetails['id'], [Validators.required]],
                dr_id: [this.updateDataDetails['dr_id'], [Validators.required]],

            });

        }

    }

 //Distributor Api Start
 getSalesUsers() {

    // this.loader = 1;
    this.salesUserList = [];

    this.apiHit.PostRequest({}, "Distributor/salesUserList").subscribe((result => {

        console.log(result);
        this.salesUserList = result['salesUserList'];
        for (let i = 0; i < this.updateDataDetails.assign_sales_users.length; i++) {
            for (let j = 0; j < this.salesUserList.length; j++) {
                if (this.salesUserList[j]['id'] == this.updateDataDetails.assign_sales_users[i]['assigned_to']) {
                    this.salesUserList.splice(j, 1);
                }
            }
        }

        setTimeout(() => {
            this.loader = '';
        }, 1100);

    }));

}
//Distributor Api End

    districtList1() {

        console.log(this.EditRetailer.value);

        this.apiHit.PostRequest({ 'state': this.EditRetailer.value.state }, "Distributor/district_list").subscribe((result => {

            console.log(result);

            this.districtData = result['district_list']['district_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));


    }


    //Min Date Select For Anniversary Function Start
    minDateSelect() {

        this.minDate = this.EditRetailer.value.DOB;
        console.log(this.minDate);


    }
    //Min Date Select For Anniversary Function End


    //Retailer Assign Id In Distributor Edit Case Function Start
    retailerAssignId() {

        if (this.updateDataDetails.length > 0) {
            console.log(this.updateDataDetails[0]['retailer_id']);
            this.EditDistributor.controls.id.setValue(this.updateDataDetails[0]['retailer_id'])

        }
        else {

            console.log(this.updateDataDetails['id']);
            this.EditDistributor.controls.id.setValue(this.updateDataDetails['id'])

        }

    }
    //Retailer Assign Id In Distributor Edit Case Function End


    //Ng Model Function On Status Field Function Start
    checkRetailerStatus(target) {

        console.log(this.EditRetailer);
        this.retailerStatus = false;
        this.retailerStatusVerified = false;

        if (this.EditRetailer.controls.status.value == 'Verified' || this.EditRetailer.controls.status.value == 'Pending') {

            this.EditRetailer.controls.asm_status.setValue(this.EditRetailer.controls.status.value);
            this.EditRetailer.controls.zsm_status.setValue(this.EditRetailer.controls.status.value);
            this.EditRetailer.controls.asm_status_reason.setValue('');
            this.EditRetailer.controls.zsm_status_reason.setValue('');
            this.EditRetailer.controls.zsm_status_reason.setValidators(null);
            this.EditRetailer.controls.asm_status_reason.setValidators(null);
            if (this.updateDataDetails['distributorCount'] == 0 && this.EditRetailer.controls.status.value == 'Verified') {
                this.EditRetailer.controls.distributor_id.setValidators([Validators.required]);
                this.retailerStatusVerified = true;
                this.distributorList(2);
            }
            return;

        }
        if (this.EditRetailer.controls.status.value == 'Reject' || this.EditRetailer.controls.status.value == 'Suspect') {
            this.retailerStatus = true;
            this.retailerStatusVerified = true;
            this.EditRetailer.controls.zsm_status_reason.setValue('');
            this.EditRetailer.controls.asm_status_reason.setValue('');
            this.EditRetailer.controls.distributor_id.setValue('');
            this.EditRetailer.controls.asm_status.setValue(this.EditRetailer.controls.status.value);
            this.EditRetailer.controls.zsm_status.setValue(this.EditRetailer.controls.status.value);
            this.EditRetailer.controls.asm_status_reason.setValidators([Validators.required, Validators.minLength(3)]);
            this.EditRetailer.controls.zsm_status_reason.setValidators([Validators.required, Validators.minLength(3)]);
            this.EditRetailer.controls.distributor_id.setValidators(null);
            return;
        }

        // if (this.data.approval_type == 'asm' || this.data.user_type == 'System_user') {

        //     if (this.EditRetailer.controls.asm_status.value == 'Reject' || this.EditRetailer.controls.asm_status.value == 'Suspect') {

        //         if (target == 1) {

        //             this.retailerStatus = true;
        //             this.EditRetailer.controls.asm_status_reason.setValidators([Validators.required, Validators.minLength(3)]);

        //         }
        //         else {

        //             this.retailerStatus = true;
        //             this.EditRetailer.controls.asm_status_reason.setValidators([Validators.required, Validators.minLength(3)]);
        //             this.EditRetailer.controls.distributor_id.setValidators(null);
        //             this.EditRetailer.controls.distributor_id.setValue('');
        //             this.EditRetailer.controls.asm_status_reason.setValue('');

        //         }

        //     }
        //     else if (this.EditRetailer.controls.asm_status.value == 'Verified' && this.EditRetailer.controls.zsm_status.value == 'Verified' && this.updateDataDetails['distributorCount'] == 0) {

        //         this.EditRetailer.controls.distributor_id.setValidators([Validators.required]);
        //         this.EditRetailer.controls.asm_status_reason.setValidators(null);
        //         this.retailerStatusVerified = true;
        //         this.distributorList(2);

        //     }
        //     else {

        //         this.EditRetailer.controls.asm_status_reason.setValidators(null);
        //         this.EditRetailer.controls.distributor_id.setValidators(null);
        //         this.EditRetailer.controls.distributor_id.setValue('');

        //     }
        // }
        this.EditRetailer.controls.asm_status_reason.updateValueAndValidity();
        this.EditRetailer.controls.zsm_status_reason.updateValueAndValidity();
        this.EditRetailer.controls.distributor_id.updateValueAndValidity();

    }
    //Ng Model Function On Status Field Function End


    checkRetailerAsmStatus(target) {

        console.log(this.EditRetailer);
        this.retailerStatus = false;
        this.retailerStatusVerified = false;

        if (this.EditRetailer.controls.asm_status.value == 'Reject' || this.EditRetailer.controls.asm_status.value == 'Suspect') {

            console.log(1);
            if (target == 2) {
                this.EditRetailer.controls.asm_status_reason.setValue('');
            }
            this.retailerStatus = true;
            this.EditRetailer.controls.asm_status_reason.setValidators([Validators.required, Validators.minLength(3)]);
            this.EditRetailer.controls.distributor_id.setValidators(null);
            this.EditRetailer.controls.distributor_id.setValue('');


        }
        else if (this.EditRetailer.controls.zsm_status.value == 'Verified' && this.EditRetailer.controls.asm_status.value == 'Verified' && this.updateDataDetails['distributorCount'] == 0) {

            console.log(2);
            this.retailerStatusVerified = true;
            this.EditRetailer.controls.distributor_id.setValidators([Validators.required]);
            this.EditRetailer.controls.asm_status_reason.setValue('');
            this.EditRetailer.controls.asm_status_reason.setValidators(null);
            // this.distributorList(2);

        }
        else {

            console.log(3);
            this.EditRetailer.controls.asm_status_reason.setValidators(null);
            this.EditRetailer.controls.distributor_id.setValidators(null);
            this.EditRetailer.controls.distributor_id.setValue('');
            this.EditRetailer.controls.asm_status_reason.setValue('');

        }
        this.EditRetailer.controls.asm_status_reason.updateValueAndValidity();
        this.EditRetailer.controls.distributor_id.updateValueAndValidity();

    }


    checkRetailerZsmStatus(target) {

        console.log(this.EditRetailer);
        // this.EditRetailer.controls.asm_status.setValue("");
        this.retailerStatusZsm = false;
        this.retailerStatusVerified = false;

        if (this.EditRetailer.controls.zsm_status.value == 'Reject' || this.EditRetailer.controls.zsm_status.value == 'Suspect') {

            console.log(1);
            if (target == 2) {
                this.EditRetailer.controls.zsm_status_reason.setValue('');
            }
            this.retailerStatusZsm = true;
            this.EditRetailer.controls.zsm_status_reason.setValidators([Validators.required, Validators.minLength(3)]);
            this.EditRetailer.controls.distributor_id.setValidators(null);
            this.EditRetailer.controls.distributor_id.setValue('');


        }
        else if (this.EditRetailer.controls.zsm_status.value == 'Verified' && this.EditRetailer.controls.asm_status.value == 'Verified' && this.updateDataDetails['distributorCount'] == 0) {

            console.log(2);
            this.retailerStatusVerified = true;
            this.EditRetailer.controls.distributor_id.setValidators([Validators.required]);
            this.EditRetailer.controls.zsm_status_reason.setValue('');
            this.EditRetailer.controls.zsm_status_reason.setValidators(null);
            this.distributorList(2);

        }
        else {

            console.log(3);
            this.EditRetailer.controls.zsm_status_reason.setValidators(null);
            this.EditRetailer.controls.distributor_id.setValidators(null);
            this.EditRetailer.controls.distributor_id.setValue('');
            this.EditRetailer.controls.zsm_status_reason.setValue('');

        }
        this.EditRetailer.controls.zsm_status_reason.updateValueAndValidity();
        this.EditRetailer.controls.distributor_id.updateValueAndValidity();

    }


    //Assing Distributor To Retailer Through NgModel Function Start 
    assignDistributor() {

        this.assign = [];
        for (let index = 0; index < this.EditDistributor.value.distributor_id.length; index++) {

            this.assign.push({

                "id": this.EditDistributor.controls.id.value,
                "distributor_id": this.EditDistributor.value.distributor_id[index]

            });

        }
        console.log(this.assign);
    }
    //Assing Distributor To Retailer Through NgModel Function End


    enterPin(formData) {

        console.log(formData.controls.pinNo.value);
        if (formData.controls.pinNo.value.length == 6) {

            this.apiHit.PostRequest1({

                'pincode': formData.controls.pinNo.value

            }, "AppMechanicDetails/getPincodeDetails").subscribe((result => {


                console.log(result);

                setTimeout(() => {
                    this.selectedDistrict = result['pincodeDetails'].district;
                    console.log(this.selectedDistrict);

                }, 1000);

                this.stataData = result['pincodeDetails'].state;
                // this.selectedDistrict= result['pincodeDetails'][0].district_name;

                console.log(this.selectedDistrict);
                this.districtList(this.stataData)

            }
            ));
        }
    }

    //Change Distributor In Retailer Detail Through API Function Start
    changeDistributor() {

        console.log(this.EditDistributor);
        console.log(this.assign);

        // return;


        this.dialog2.save_user('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ "assign": this.assign }, "Distributor/assign_distributor").subscribe((result => {

                    console.log(result);

                    if (result['assign_distributor'] == 'success') {

                        this.dialog2.success('Success', ' Distributor Sucessfully Updated');
                        this.dialogRef.closeAll();


                    } else {

                        this.dialog2.error('error', 'error');

                    }

                }));
            };
        })

    }
    //Change Distributor In Retailer Detail Through API Function End



    //Update Retailer Detail Through API Function Start
    updateRetailer() {

        if (this.EditRetailer.controls.DOB.value) {

            this.EditRetailer.controls.DOB.setValue(moment(this.EditRetailer.controls.DOB.value).format('YYYY-MM-DD'));

        }

        // if(this.EditRetailer.controls.ASM_updated_date.value){

        //     this.EditRetailer.controls.ASM_updated_date.setValue(moment(this.EditRetailer.controls.ASM_updated_date.value).format('YYYY-MM-DD'));

        // }

        if (this.EditRetailer.controls.anniversary.value) {

            this.EditRetailer.controls.anniversary.setValue(moment(this.EditRetailer.controls.anniversary.value).format('YYYY-MM-DD'));

        }
        console.log(this.EditRetailer);
        // return;

        this.dialog2.save_Retailer('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ 'changeData': this.EditRetailer.value, 'user_id': this.data.id }, "Distributor/update_dealer").subscribe((result => {

                    console.log(result);

                    if (result['update_dealer'] == 'success') {

                        this.dialog2.success('Success', ' Retailer Sucessfully Updated');
                        this.dialogRef.closeAll();


                    } else {

                        this.dialog2.error('error', 'error');

                    }

                }));
            };
        })

    }


    //Add Contact Person Through API Function Start
    addContact() {

        this.dialog2.save_Retailer('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(this.AddContactForm.value, "Distributor/add_contact_person").subscribe((result => {

                    console.log(result);

                    if (result['add_contact_person'] == 'success') {

                        this.dialog2.success('Success', ' Retailer Contact Person Sucessfully Added');
                        this.dialogRef.closeAll();


                    } else {

                        this.dialog2.error('error', 'error');

                    }

                }));
            };
        })
    }
    //Add Contact Person Through API Function End




    //Update Contact Person Through API Function Start
    updateContact() {

        console.log(this.EditContactForm.value);
        // return;
        this.apiHit.PostRequest(this.EditContactForm.value, "Distributor/update_contact_person").subscribe((result => {

            console.log(result);

            if (result['update_contact_person'] == "success") {

                this.dialog2.success('Success', ' Contact Person Sucessfully Updated');

                this.dialogRef.closeAll();

            } else {

                this.dialog2.error('error', 'error');

            }

        }));

    }
    //Update Contact Person Through API Function End



    //Distributor List Fetch From Database Through API  Start
    distributorList(target) {

        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/dealer_list_by_name").subscribe((result => {

            console.log(result);

            this.distributorData = result['dealer_list']['info'];

            if (target = 1 && this.updateDataDetails.length > 0) {

                for (let index = this.distributorData.length; index >= 0; index--) {

                    const isIndexExist = this.updateDataDetails.findIndex(row => row.distributor_assign_id == this.distributorData[index].id);

                    if (isIndexExist !== -1) {


                        console.log(this.distributorData.splice(index, 1));
                        console.log(isIndexExist);
                        console.log(this.distributorData[index]['id']);
                        this.distributorData.splice(isIndexExist, 1);
                        // this.distributorData.splice(index, 1);
                        console.log(this.distributorData);

                    }

                }
                setTimeout(() => {
                    console.log(this.distributorData);
                }, 2000);

            }

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Distributor List Fetch From Database Through API  End



    //Check Duplicacy Of Mobile No Function(Ng Model) Start
    checkMobileNoDuplicacy() {

        if (this.EditRetailer.value.mobileNo.length == 10) {

            this.apiHit.PostRequest({

                "type": 'dr',
                "mobileNo": this.EditRetailer.value.mobileNo,
                'id': this.updateDataDetails['id']

            }, "Distributor/check_user").subscribe((result => {

                if (result['exists'] == 1) {

                    this.EditRetailer.controls.mobileNo.setValue('');
                    this.dialog2.error('error', 'Mobile Already Exists');

                }

            }));

        }

    }
    //Check Duplicacy Of Mobile No Function(Ng Model) End



    //State List Fetch From Database Through API  Start
    stateList() {

        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {

            this.stateData = result['state_list']['state_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Sales List Fetch From Database Through API  End

    addAssignedSalesUser() {

        console.log(this.AssignedSalesUser);

        this.dialog2.save_user('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(this.AssignedSalesUser.value, "Distributor/drUserAssign").subscribe((result => {

                    console.log(result);

                    if (result['msg'] == "success") {

                        this.dialog2.success('Success', 'Sales User Sucessfully Assigned');
                        this.dialogRef.closeAll();
                    }
                    else {
                        this.dialog2.error('error', 'Something Went Wrong');
                    }

                }));
            };
        })
    }

    //District List Fetch From Database Through API  Start

    districtList(district) {

        this.loader = 1;

        this.apiHit.PostRequest({ 'state': district }, "Distributor/district_list").subscribe((result => {

            this.districtData = result['district_list']['district_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //District List Fetch From Database Through API  End



    // KeyPress Function Only For Write Number Function Start

    OnlyNumber(event: any) {

        const pattern = /[0-9\'']/;
        let inputChar = String.fromCharCode(event.charCode);

        if (event.keyCode != 8 && !pattern.test(inputChar)) {

            event.preventDefault();

        }

    }
    // KeyPress Function Only For Write Number Function Start

}