import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({

    selector: 'app-distributor-add',
    templateUrl: './distributor-add.component.html'

})

export class DistributorAddComponent implements OnInit {

    AddDistributorForm: FormGroup;
    date: any;
    loader: any;
    districtData: any;
    stateData: any;
    cPersonName: any;
    stataData: any;
    selectedDistrict: any;
    selected_district: any = [];
    minDate: Date;
    maxDate: Date;
    userId: any;
    category_data: any = []
    category: any;

    constructor(

        private fb: FormBuilder,
        public apiHit: DatabaseService,
        public dialog: DialogService,
        public param: ActivatedRoute,
        public router: Router,

    ) {

        this.date = new Date();
        this.stateList();
        this.categoryList();
        const currentYear = new Date().getFullYear();
        this.maxDate = new Date(currentYear - 15, 11, 31);
        this.userId = this.apiHit.userLoginData.data.id;

    }

    ngOnInit(): void {

        this.AddDistributorForm = this.fb.group({

            firmName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
            firstName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
            mobileNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
            email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            DOB: [''],
            anniversary: [''],
            gstNo: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(15)]],
            // , Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$")

            panNo: ['', [Validators.minLength(10), Validators.maxLength(10)]],
            // , Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")

            drCode: ['', [Validators.required]],
            cPersonName: ['', [Validators.minLength(3), Validators.maxLength(30)]],
            cPersonMobileNo: ['', [Validators.minLength(10), Validators.maxLength(10)]],
            cPersonEmail: ['', [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
            category: ['',],
            pinNo: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
            state: ['', [Validators.required]],
            district: ['', [Validators.required]],
            city: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

            address: ['', [Validators.minLength(3)]],
            uid: [this.userId],
            dr_type: [1, [Validators.required]],
            credit_limit: ['',],
            outstanding: ['',],

            searchCtrl: [''],


        })
        console.log(this.AddDistributorForm.value);

    }


    // checkMobile(){


    //     if(this.AddDistributorForm.controls.cPersonName.value){

    //         this.AddDistributorForm.controls.cPersonMobileNo.setValue('required');
    //     }

    // }


    //Check Mobile No Duplicacy Function Start

    checkMobileNoDuplicacy() {

        if (this.AddDistributorForm.value.mobileNo.length == 10) {

            this.loader = 1;

            this.apiHit.PostRequest({ "type": 'dr', "mobileNo": this.AddDistributorForm.value.mobileNo }, "Distributor/check_user").subscribe((result => {

                if (result['exists'] == 1) {

                    this.AddDistributorForm.controls.mobileNo.setValue('');
                    this.dialog.error('error', 'Mobile Already Exists');

                }

                setTimeout(() => {

                    this.loader = '';

                }, 700);

            }));

        }

    }
    //Check Mobile No Duplicacy Function End



    checkDrCodeDuplicacy() {

        setTimeout(() => {

            this.apiHit.PostRequest({ "drCode": this.AddDistributorForm.value.drCode }, "Distributor/check_dr_code").subscribe((result => {

                console.log(result);
                if (result['exists'] == 1) {

                    this.dialog.error('error', 'Dr Code Already Exists');
                    this.AddDistributorForm.controls.drCode.setValue('');
                    return;

                }
                else if (result['exists'] == 0) {
                    this.saveDistributer();
                }

            }));

        }, 400);

    }



    //Save Distributor Function  Start

    saveDistributer() {

        console.log(this.AddDistributorForm.value);


        this.loader = 1;

        if (this.AddDistributorForm.controls.DOB.value) {

            this.AddDistributorForm.controls.DOB.setValue(moment(this.AddDistributorForm.controls.DOB.value).format('YYYY-MM-DD'));

        }

        if (this.AddDistributorForm.controls.anniversary.value) {

            this.AddDistributorForm.controls.anniversary.setValue(moment(this.AddDistributorForm.controls.anniversary.value).format('YYYY-MM-DD'));

        }

        console.log(this.AddDistributorForm);
        console.log(this.AddDistributorForm.value);
        // return;


        this.dialog.save_distributor('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(this.AddDistributorForm.value, "Distributor/add_dealer").subscribe((result => {

                    console.log(result);


                    if (result['distributors_add'] == 'success') {

                        this.dialog.success('Success', 'Distributor Successfully Added');
                        this.router.navigate(['distributor']);


                    }


                    else {

                        this.dialog.error('error', 'Something Went Wrong');

                    }
                    setTimeout(() => {

                        this.loader = '';

                    }, 700);

                }));
            };
        })

    }
    //Save Distributor Function  End


    enterPin(formData) {


        if (formData.controls.pinNo.value.length == 6) {

            this.apiHit.PostRequest1({

                'pincode': formData.controls.pinNo.value

            }, "AppMechanicDetails/getPincodeDetails").subscribe((result => {


                console.log(result);
                setTimeout(() => {
                    this.selectedDistrict = result['pincodeDetails'].district;

                    this.AddDistributorForm.controls.district.setValue(this.selectedDistrict);

                    console.log(this.AddDistributorForm);

                }, 1000);


                this.stataData = result['pincodeDetails'].state;

                console.log(this.selectedDistrict);

                this.AddDistributorForm.controls.state.setValue(this.stataData);
                this.AddDistributorForm.controls.district.setValue(this.selectedDistrict);
                console.log(this.AddDistributorForm);

                this.districtList(this.stataData)
            }
            ));
            setTimeout(() => {

                console.log(this.AddDistributorForm);
            }, 2000);
        }


    }
    // Category list Api Start

    categoryList() {

        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/distributer_category").subscribe((result => {

            this.category = result['distributor_category']['data'];

            console.log(this.category)
            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    // Category list Api End


    //State List Fetch From Database Through API  Start

    stateList() {
        console.log(this.selected_district);
        console.log(this.AddDistributorForm);



        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {

            this.stateData = result['state_list']['state_name'];


            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //State List Fetch From Database Through API  End


    //District List Fetch From Database Through API  Start

    districtList(district) {

        this.loader = 1;

        this.selected_district.push({ 'district': district })
        console.log(this.selected_district);


        this.apiHit.PostRequest({ 'state': district }, "Distributor/district_list").subscribe((result => {

            console.log(result);

            this.districtData = result['district_list']['district_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //District List Fetch From Database Through API  End


    districtList1() {

        console.log(this.AddDistributorForm.value);

        console.log(this.selected_district[0])

        this.apiHit.PostRequest({ 'state': this.AddDistributorForm.value.state }, "Distributor/district_list").subscribe((result => {

            console.log(result);

            this.districtData = result['district_list']['district_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));


    }

    minDateSelect() {

        this.minDate = this.AddDistributorForm.value.DOB;

    }


    // KeyPress Function  Start

    OnlyNumber(event: any) {

        const pattern = /[0-9\'']/;
        let inputChar = String.fromCharCode(event.charCode);

        if (event.keyCode != 8 && !pattern.test(inputChar)) {

            event.preventDefault();

        }
    }
    // KeyPress Function  End

}