import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/service';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ExpenseModalComponent } from '../expense-modal/expense-modal.component';

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {
  food_expense_list: any = [];
  out_expense_list: any = [];
  hotel_expense_list: any = [];
  misc_expense_list: any = []
  local_expense_list: any = []
  user_list: any = []
  rsmData: any
  rsmname: any
  rsmrole: any
  user: any = []
  name: any
  designation: any
  out: any = 0
  local: any = 0
  misc: any = 0
  food: any = 0
  hotel: any = 0
  total: any
  expense_list: any = [];
  search: any = {};
  active_tab = 'Pending';
  loader: any;
  datanotfound = false;
  skelton: any;
  searchData: any;
  search_val: any = {}
  data_val: any = {}
  backButton: boolean = false;
  list1: any = []
  assign_login_data: any = [];
  assign_login_data2: any = [];
  view_edit: boolean = true;
  view_add: boolean = true;
  view_delete: boolean = true;
  tabCount: any;
  sr_no: any = 0;
  total_page: any;
  pageCount: any;
  page_limit: any;
  pagenumber: any = 1;
  start: any = 0;
  today_date: any = new Date()
  status_count: any;
  downurl: any = '';
  downloadingLoader:boolean=false;

  constructor(public service : DatabaseService,public location: Location,public dialog: MatDialog,private apiHit : DatabaseService) { 
    console.log(service.downloadExcelURL1);
    this.downurl = service.downloadExcelURL1;
    console.log(this.downurl);
  }

  ngOnInit(): void {
    this.data_val = this.service.userLoginData.data
    console.log(this.data_val,'d')
    this.assign_login_data2 = this.data_val
    console.log( this.assign_login_data2,'data')

    console.log(this.assign_login_data2.edit_expense,'edit')
    this.expenseList();
  }
  refresh() {
    this.search = {}
    this.expenseList();
  }
  pervious() {
    this.start = this.start - this.page_limit;
    this.expenseList('');
  }

  nextPage() {

    this.start = this.start + this.page_limit;
    this.expenseList('');
  }
  back(): void {
    this.location.back()
  }
  expenseList(action: any = '') {
    if (action == "refresh") {
      this.search = {};
    }
    this.loader = true;

    if (this.search.dateCreated) {
      this.search.dateCreated = moment(this.search.dateCreated).format('YYYY-MM-DD');
    }
    if (this.search.claimDate) {
      this.search.claimDate = moment(this.search.claimDate).format('YYYY-MM-DD');
    }
    if (this.pagenumber > this.total_page) {
      this.pagenumber = this.total_page;
      this.start = this.pageCount - this.page_limit;
    }
    if (this.start < 0) {
      this.start = 0;
    }
    this.loader = true;
    this.search.status = this.active_tab
    this.service.PostRequest({ 'user_id': this.assign_login_data2.id, 'start': this.start, 'pagelimit': this.page_limit, 'search': this.search, 'expenseStatus': this.active_tab, 'user_type': this.assign_login_data2.type }, "Expense/expenseList").subscribe(result => {
      if (result['statusCode'] == 200) {
        this.expense_list = result['result'];
        this.pageCount = result['count'];
        this.tabCount = result['sub_count'];

        if (this.pagenumber > this.total_page) {
          this.pagenumber = this.total_page;
          this.start = this.pageCount - this.page_limit;
        }
        else {
          this.pagenumber = Math.ceil(this.start / this.page_limit) + 1;
        }
        this.total_page = Math.ceil(this.pageCount / this.page_limit);
        this.sr_no = this.pagenumber - 1;
        this.sr_no = this.sr_no * this.page_limit
        if (this.expense_list.length == 0) {
          this.datanotfound = true;
        }
        else {
          this.datanotfound = false;
        }

        setTimeout(() => {
          this.loader = false;

        }, 100);
      } else {
        this.loader = false;
        // this.toast.errorToastr(result['statusMsg']);
      }
    }, err => {
      this.loader = false;
      // this.toast.errorToastr('Something went wrong');
    })

    if (this.search.date_from) {
      this.search.date_from = moment(this.search.date_from).format('YYYY-MM-DD');
    }
    if (this.search.date_to) {
      this.search.date_to = moment(this.search.date_to).format('YYYY-MM-DD');
    }
  }
  sortData() {
    this.expense_list.reverse();
  }
  expModal(type, id, totalAmt) {
    const dialogRef = this.dialog.open(ExpenseModalComponent, {
      width: '500px',
      panelClass: 'cs-modal',
      data: {
        type,
        id,
        totalAmt
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.expenseList();
    });

  }

  exportRetailerIncentive (func_name) {
    console.log(func_name);
    
    this.loader = true
    this.apiHit.PostRequest({ 'user_id': this.assign_login_data2.id, 'search': this.search, 'user_type': this.assign_login_data2.type }, "Excel/" + func_name)
      .subscribe(((result: any) => {
        if (result['msg'] == true) {
          this.loader = false;
          window.open(this.downurl + result['filename'])
          this.expenseList(func_name);
        } else {
          this.loader = false;
        }
      }), err => {
        this.loader = false;
      });
  
  }
}
