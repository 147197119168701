import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-secondary-order-add',
  templateUrl: './secondary-order-add.component.html',
})
export class SecondaryOrderAddComponent implements OnInit {

  orderAdd: FormGroup;
  placeOrder: FormGroup;
  loader: any;
  segmentdata: any = [];
  distributorData: any = [];
  retailerData: any = [];
  partNo: any = [];
  categoryName: any = [];
  finalOrderAddArray: any = [];
  buttonLoading : any = false;
  usertype:any;
  data_val:any;
  login_type

  constructor(
    private fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public param: ActivatedRoute,
    public router: Router,
  ) { 
    console.log(this.apiHit.userLoginData.data,'data')
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val,'data_val')
    this.retailerList();
    this.getSegment();
   

    console.log(this.data_val.dr_name,'add')
    console.log(this.data_val.id,'add')


  }

  ngOnInit(): void {

    this.orderAdd = this.fb.group({

      dr_id: ['', [Validators.required]],
      distributor_id : [this.data_val.dr_name, [Validators.required]],
      segment: ['', [Validators.required]],
      category: ['', [Validators.required]],
      part_code: ['', [Validators.required]],
      qty: ['', [Validators.required]],
      order_status: [1, [Validators.required]],
      searchCtrl: [''],

    })

    this.placeOrder = this.fb.group({
      order_remarks: ['', [Validators.required]],
      order_status: [1],
      orderArray: [],
      total_qty: [0],
      item_count: [0],
      distributor_id: [''],
    })

  }


  placeOrderFunction() {

    this.dialog.save_distributor('').then((result) => {

      if (result === true) {

        console.log(this.placeOrder);
        this.buttonLoading = true;
        let data = this.placeOrder.value;
        if(this.data_val?.login_type == 'distributor'){
          data.dr_id = this.orderAdd.value.dr_id
          data.login_type = this.data_val.login_type
        }else{

          data.dr_id = this.orderAdd.value.dr_id;
        }
     
        // data.dr_id = this.orderAdd.value.dr_id;
        data.orderArray = this.finalOrderAddArray;
        data.item_count = this.finalOrderAddArray.length;
        if(this.usertype!=1){
        data.distributor_id = this.orderAdd.value.distributor_id;
        }else {
          data.distributor_id = this.data_val.id
        }
        data.is_admin = 1;
        data.user_id = this.apiHit.userLoginData.data.id;

        for (var i = 0; i < this.finalOrderAddArray.length; i++) {

          data.total_qty = parseInt(this.finalOrderAddArray[i]['qty']) + data.total_qty;

        }
        if(this.data_val.user_type == 'approval_user'){
          data.user_type = this.data_val.user_type;
        }
        console.log(data);

        this.apiHit.PostRequest( data, "app/AppOrderController/addOrder").subscribe((result => {

          console.log(result);
          if (result['show_Distributor_Details'].status==  'success') {

            this.dialog.success('Success', 'Order Successfully Added');
            this.router.navigate(['secondary-order']);
            this.buttonLoading = false;

          }
          else{
            this.dialog.error('error', 'Please Check Your Internet Connection');
            this.buttonLoading = false;

          }
          

        }));

      }
    });

  }


  addToList(form: NgForm) {

    console.log(this.orderAdd);
    let orderList = this.orderAdd.value
    let index = this.finalOrderAddArray.findIndex(row => row.part_code == orderList.part_code);
    console.log(form);
    if (index === -1) {
      this.finalOrderAddArray.push({ 'part_code': orderList.part_code, 'category': orderList.category, 'segment': orderList.segment, 'qty': orderList.qty })
      // form.resetForm();
      this.orderAdd.controls['part_code'].reset();
      this.orderAdd.controls['qty'].reset();
    }
    else {
      this.dialog.error('oops', 'Part Already In List');
      this.orderAdd.controls['part_code'].reset();
      this.orderAdd.controls['qty'].reset();
      // this.orderAdd.controls['qty'].reset();
    }

    // console.log(this.finalOrderAddArray);

  }



  retailerList() {

  
    this.loader = 1;

    this.retailerData = [];
    console.log(this.retailerData,'"data')
    if(this.data_val.login_type == "distributor"){
      this.usertype=1;
      this.login_type='distributor'

    }
    else {
      this.usertype=3;
    }
    console.log(this.usertype,'type')
    this.apiHit.PostRequest({ 'user_id': this.apiHit.userLoginData.data.id, "user_type": this.usertype,"login_type":this.login_type }, "User/showApprovalDistributer").subscribe((result => {

      console.log(result);

      this.retailerData = result['showApprovalDistributer'];

      setTimeout(() => {

          this.loader = '';

      }, 400);

  }));

  }


  //Get DIstributor List Through API Function Start
  getDistributor(id) {

    this.loader = 1;

    this.distributorData = [];

    this.apiHit.PostRequest({ retailer_id: id }, "app/AppDistributorDetails/getDistributorListing").subscribe((result => {

      console.log(result);
      this.distributorData = result['distributor_details'][0];
      this.loader = '';
      if(this.distributorData.length == 0){
        this.dialog.error('error', 'Not Found Any Distributor For This Retailer');
      }

    }));

  }
  //Get DIstributor List Through API Function End


  getSegment() {

    this.loader = 1;
    this.apiHit.PostRequest('', "app/AppOrderController/getSegment").subscribe((result => {

      console.log(result['result']);
      if (result['result']['status'] == 'success') {
        this.segmentdata = result['result']['segmentData'];
        this.loader = '';
      }

    }));
  }

  //Part Code Api Start
  getCategory(brand) {

    this.loader = 1;
    this.partNo = [];
    this.categoryName = [];
    // console.log(this.couponForm.value);
    this.orderAdd.controls.category.setValue('');
    this.orderAdd.controls.part_code.setValue('');
    // this.orderAdd.controls.count_amount.setValue('');

    this.apiHit.PostRequest({ 'brand': brand }, "CouponCode/partCategory").subscribe((result => {

      console.log(result);
      this.categoryName = result['partCategory'];

      setTimeout(() => {
        this.loader = '';
      }, 300);

    }));

  }
  //Part Code Api End


  //Part Code Api Start
  getPartCodeNo(category) {

    this.loader = 1;
    this.partNo = [];
    this.orderAdd.controls.part_code.setValue('');
    // this.orderAdd.controls.count_amount.setValue('');
    console.log(this.orderAdd.controls.segment.value);

    this.apiHit.PostRequest({ 'category': category, segment: this.orderAdd.controls.segment.value }, "CouponCode/partNoList").subscribe((result => {

      console.log(result);

      this.partNo = result['part_No_List'];

      setTimeout(() => {
        this.loader = '';
      }, 400);

    }));

  }
  //Part Code Api End


  deleteOrderItem(index) {

    this.dialog.delete('').then((result) => {

      if (result === true) {
        this.finalOrderAddArray.splice(index, 1)
      }
    });

  }


  // KeyPress Function  Start

  OnlyNumber(event: any) {

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {

      event.preventDefault();

    }
  }
  // KeyPress Function  End


}
