import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-coupon-status-model',
  templateUrl: './coupon-status-model.component.html',
})
export class CouponStatusModelComponent implements OnInit {


  StatusForm: FormGroup;
  statusReject: any = false;
  loader: any;
  dataValue: any;


  constructor(

    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public dialogRef: MatDialog,

  ) {

    console.log(this.data);

    if(this.data['target'] == 1){
      this.StatusForm = this.fb.group({
        coupon_status: [this.data['data']['status'], [Validators.required]],
        coupon_id: [this.data['data']['id'], [Validators.required]],
        reason: new FormControl(''),
      });
      if (this.data['data']['status'] == 'Reject') {
        this.checkStatusMode2();
      }
    }
    
  }


  ngOnInit(): void {

    // console.log(this.data);
    console.log(this.data['data']);

  }

  checkStatusMode2() {

    this.statusReject = true;
    this.StatusForm.controls.reason.setValidators([Validators.required]);
    this.StatusForm.controls.reason.setValue(this.data['data']['status_reason']);

  }


  checkStatusMode() {

    if (this.StatusForm.controls.coupon_status.value == 'Reject') {

      this.statusReject = true;

      this.StatusForm.controls.reason.setValidators([Validators.required]);
      this.StatusForm.controls.reason.setValue('');

    }
    else {

      this.statusReject = false;

      this.StatusForm.controls.reason.setValidators(null);
      this.StatusForm.controls.reason.setValue('');

    }

  }


  statusUpdate() {
    this.loader = 1;
    console.log(this.StatusForm.value);

    // return
    this.apiHit.PostRequest(this.StatusForm.value, "CouponCode/couponcodeStatus").subscribe((result => {

      console.log(result);

      if (result['coupon_Code_Status'] == 'success') {

        this.dialog.success('Success', 'Status Changed Sucessfully');
        this.dialogRef.closeAll();
        // this.router.navigate(['retailer']);

      } else {

        this.dialog.error('error', 'error');

      }

      setTimeout(() => {

        this.loader = '';

      }, 700);

    }));

  }

}