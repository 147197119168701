import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { ProductEditModalComponent } from '../product/product-edit-modal/product-edit-modal.component';

@Component({
  selector: 'app-customer-category',
  templateUrl: './customer-category.component.html',
  styleUrls: ['./customer-category.component.scss']
})
export class CustomerCategoryComponent implements OnInit {

  categoryData: any = [];
  loader: any;
  total: any;
  filterSearch: any = {};
  skeleton: any = [];
  date: any = [];
  lastUpdatedDate: any;

  constructor(

    public dialog: MatDialog,
    private apiHit: DatabaseService,
    public dialog2: DialogService,

  ) {

    this.skeleton = new Array(10);
    this.date = new Date();
    this.getCategoryList();
  }

  ngOnInit(): void {
  }

  getCategoryList() {

    this.loader = 1;
    this.apiHit.PostRequest({}, "Distributor/distributer_category").subscribe((result => {

      console.log(result);
      if (result['distributor_category']['status'] == 'success') {
        this.categoryData = result['distributor_category']['data'];
        this.loader = '';
      }
      else {
        this.loader = '';
        this.dialog2.error('error', 'Something Went Wrong');
      }

    }));

  }

  categoryEdit(id, category): void {

    const dialogRef = this.dialog.open(ProductEditModalComponent, {

      width: '',
      panelClass: "modelwidth",
      data: { 
              'id': id,
              'from': 'customer-category-edit',
              'category': category
            }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.getCategoryList();

    });

  }

  categoryAdd(): void {

    const dialogRef = this.dialog.open(ProductEditModalComponent, {

      width: '',
      panelClass: "modelwidth",
      data: {
              'from': 'customer-category-add'
            }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.getCategoryList();

    });

  }

  deleteCategory(id) {

    this.dialog2.delete('').then((result) => {

      console.log(result);

      if (result === true) {

        this.apiHit.PostRequest({'data':{"id": id}}, "Distributor/removeDistributorCategory").subscribe((result => {

          console.log(result);

          if (result['distributor_category'] == 'success') {

            this.dialog2.success('Success', 'Category Successfully Deleted !!!');
            this.getCategoryList();

          }
          else {

            this.dialog2.error('error', result['errors']);

          }
        }));

      }

    });

  }

  refreshData() {

    this.filterSearch = {};
    this.getCategoryList();

  }

}
