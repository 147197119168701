import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';


@Component({
  selector: 'app-incentive-detail',
  templateUrl: './incentive-detail.component.html',
})

export class IncentiveDetailComponent implements OnInit {

  distrubutorInfo: any;
  login_data: any;
  incentiveDetailData: any;
  reatilerInfoData: any;
  mechanicData: any;
  mechanicTransection: any = 0;
  transectionDistributor: any;
  transferDetails: any;
  assignDistributor: any;
  loader: any;
  skeleton: any = [];
  skData: any = [];
  skDataTwo: any = [];
  scanData: any = [];
  searchData: any = {};
  searchMechanic: any = {};
  searchDistributorTransaction: any = {};
  searchMechanicTransaction: any = {};
  searchTransferDetails: any = {};
  searchScanData: any = {};
  date: any;
  data_val: any;
  orderDetail: any;

  constructor(

    public dialog: MatDialog,
    public activateRoute: ActivatedRoute,
    private apiHit: DatabaseService,
    private _location: Location,
    public dialog2: DialogService,

  ) {

    console.log(this.activateRoute['params']['_value']);
    this.orderDetails();
    this.skeleton = new Array(10);
    this.skData = new Array(4)
    this.skDataTwo = new Array(2);
    this.date = new Date();


  }

  ngOnInit(): void {
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val);

  }


  // Back Button Function  Start

  backClicked() {

    this._location.back();

  }
  // Back Button Function  End




  //Reatailer Detail Function Start

  orderDetails() {

    this.loader = 1;

    this.apiHit.PostRequest({
      'txn_id': this.activateRoute['params']['_value'].id
    }, "SchemeController/GET_RETAILER_SCHEME_DETAIL"
    ).subscribe((result => {

      console.log(result);

      if (result['status'] == "Success") {
        this.incentiveDetailData = result['data'];
        this.loader = '';
      }
      else {
        this.dialog2.error('error', 'Something Went Wrong');
      }

    }));

  }
  //Reatailer Detail Function End



  //Delete Contact Function Start

  deleteOrder(orderData) {

    console.log(orderData);

    let data = { "order_id": orderData.order_id, "items_id": orderData.item_id, "user_id": this.apiHit.userLoginData.data.id }

    console.log(data);

    this.dialog2.delete('').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest(data, "app/AppOrderController/deleteOrderItems").subscribe((result => {

          console.log(result);

          if (result['order_info']['status'] == 'Success') {

            this.orderDetails();

          }

          else {

            this.dialog2.error('error', result['errors']);

          }

        }));

      }

    });

  }
  //Delete Contact Function End


  //Distributor To Retailer Date Created Search Function Start 
  public onDate(event): void {

    this.searchDistributorTransaction.dateCreated = moment(event.value).format('YYYY-MM-DD');
    this.orderDetails();

  }



  //Refresh Retailer List Function Start

  refreshData(target) {

    this.searchMechanic = {};
    this.orderDetails();


  }
  //Refresh Retailer List Function End


}