import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-scheme-list',
  templateUrl: './scheme-list.component.html',
})
export class SchemeListComponent implements OnInit {
  schemeData: any = [];
  paginationData: any = {};
  loader: any;
  total: any;
  filterSearch: any = {};
  skeleton: any = [];
  date: any = [];
  excelData: any = [];
  pageNumber: any;
  totalDistributor: any;

  constructor(

    public dialog: MatDialog,
    private apiHit: DatabaseService,
    public dialog2: DialogService,

  ) {

    this.skeleton = new Array(10);
    this.date = new Date();
    this.pageNumber = 1;
    this.schemeList(1);
  }

  ngOnInit(): void {
  }


  public onDate(event,type): void {
    if(type == 1){
      this.filterSearch.create_date = moment(event.value).format('YYYY-MM-DD');
    }
    else if(type == 2){
      this.filterSearch.start_date = moment(event.value).format('YYYY-MM-DD');
    }
    else if(type == 3){
      this.filterSearch.end_date = moment(event.value).format('YYYY-MM-DD');
    }
    this.schemeList(1);
  }


  onChangePageNumber(type) {

    console.log(this.pageNumber);

    if (type == 'previous') {

      if (this.pageNumber > 1) {

        this.pageNumber -= 1;
        this.schemeList(2);

      }

    }
    else if (type == 'next') {

      if (this.total > this.pageNumber) {

        this.pageNumber += 1;
        this.schemeList(2);

      }

    }
    else if (type = 'current') {

      if (this.pageNumber > this.total) {

        console.log('current else');
        this.pageNumber = this.total;
        this.schemeList(2);

      }
      else if (this.pageNumber == 0) {

        this.pageNumber = 1;
        this.schemeList(2);

      }
      else if (this.pageNumber) {

        console.log('last else if');
        this.schemeList(2);

      }

    }

  }


  //Get DIstributor List Through API Function Start

  schemeList(target) {

    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }

    this.schemeData = [];
    this.paginationData.search = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;

    this.apiHit.PostRequest(this.paginationData, "SchemeController/showSchemeListing").subscribe((result => {

      if(result['SchemesList']['status'] == 'success'){
        this.schemeData = result['SchemesList']['scheme_list'];
        this.totalDistributor = result['SchemesList']['total_row'];
        this.total = result['SchemesList']['totalPage'];
        this.loader = '';

      }
      else{
        this.dialog2.error('error', 'Something Went Wrong');
        this.loader = '';
      }

    }));

  }
  //Get DIstributor List Through API Function End

  changeSchemeStatus(val,SchemeId){

    this.loader = 1;

    let data = {'uid' : this.apiHit.userLoginData.data.id, 'scheme_id' : SchemeId , 'status' : val};

    console.log(data);

    this.apiHit.PostRequest(data, "SchemeController/changeStatusScheme").subscribe((result => {

      console.log(result);

      if(result['SchemesList']['status'] == 'Success'){
        this.dialog2.success('Success', 'Status Successfully Changed');
        this.loader = '';

      }
      else{
        this.dialog2.error('error', 'Something Went Wrong');
        this.loader = '';
      }

    }));
    
  }


  refreshData() {

    this.filterSearch = {};
    if (this.pageNumber == null) {
      this.pageNumber = 1;
    }
    this.schemeList(1);

  }
  //Refresh Distributor List Function End

}