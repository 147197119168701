import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {

  skeleton: any = [];
  date: any;
  loader: any;
  userData: any;
  userListSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  totalUser: any;
  matTabValue: any;
  UserType: any = 'system_user';

  constructor(

    public apiHit: DatabaseService,
    public dialog: DialogService,

  ) {

    this.skeleton = new Array(10);
    this.date = new Date();
    this.date = new Date();
    this.pageNumber = 1;
    this.total = 1;
    // this.userList(1);

  }

  ngOnInit(): void {
    this.paginationData = this.apiHit.getFilterData('user-list');
    this.userListSearch = this.paginationData?.searchData ? this.paginationData.searchData : this.userListSearch;
    this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
    console.log('user list filter search ', this.paginationData);
    this.userList(this.paginationData?.target ? this.paginationData.target : 1,'');
  }
  tabClick(tab) {
    this.matTabValue = tab['tab']['textLabel'];
    if (this.matTabValue == 1) {
        this.pageNumber = 1;
        this.userList('','system_user')
        this.UserType = 'system_user'
    }
    else {
        this.pageNumber = 1;
        this.userList('','sales_user')
        this.UserType = 'sales_user'

    }
}

  //Get User List Through API Function Start
  userList(target,userType) {

    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }
    this.userData = [];
    this.paginationData.searchData = this.userListSearch;
    this.paginationData.pagelimit=20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;
    this.paginationData.user_type = userType
    this.apiHit.setFilterData(this.paginationData);

    this.apiHit.PostRequest(this.paginationData, "User/get_user_list").subscribe((result => {

      console.log(result);
      this.userData = result['userData'];
      this.total = result['userData']['totalPage'];
      this.totalUser = result['userData']['userCount'];
      this.loader = '';

    }));

  }
  //Get User List Through API Function End


  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.userList(2,this.UserType);

        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.userList(2,this.UserType);

        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.userList(2,this.UserType);

        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.userList(2,this.UserType);

        }
        else if (this.pageNumber) {

          this.userList(2,this.UserType);

        }

      }

    },
      100);
  }

  refreshData() {

    this.userListSearch = {};
    this.userList(1,this.UserType);

  }

  public onDate(event): void {

    this.userListSearch.dateCreated = moment(event.value).format('YYYY-MM-DD');
    this.userList(1,this.UserType);

  }

  deleteUser(id) {

    this.dialog.delete('').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest({ "id": id }, "User/delete_user").subscribe((result => {

          console.log(result);

          if (result['userData'] == 'Success') {

            this.userList(2,this.UserType);

          }
          else {

            this.dialog.error('error', result['errors']);

          }
          setTimeout(() => {

            this.loader = '';

          }, 700);

        }));

      }

    });

  }
  resetDevice(index,id)
  {
    this.dialog.reset('').then((result) => {

      if (result === true) {
        this.apiHit.PostRequest({ 'id': id, 'type':'user'}, "Master/resetDeviceId")
        .subscribe(resp => {
          if(resp['statusCode'] == 200){

            this.userList(2,this.UserType);
          }
          else{
            this.dialog.error('error',resp['statusMsg']);
          }
        })
      }
    })
    
  }

}