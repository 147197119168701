<div>
    <div mat-dialog-title class="model-head">
        <h2>Pay Now</h2>
        <a class="close-model" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="AmountPayForm" (ngSubmit)="checkAmount()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">
                    <div class="col s12">
                        <mat-card style="margin-bottom:8px;">
                           <h1 style="margin-top:-10px;margin-bottom: 4px;">Wallet summary</h1>
                           <div style="display: flex;justify-content:space-between;margin-bottom: 4px;">
                               <mat-label style="font-weight: 400;">Total points - {{wallet_balances.total_point ? wallet_balances.total_point : 0}} </mat-label>
                               <!-- <mat-label style="font-weight: 400;">Scheme Balance - {{wallet_balances.scheme_wallet ? wallet_balances.scheme_wallet : 0}}</mat-label> -->
                              </div>
                           <div style="display: flex;justify-content:space-between;margin-bottom: 4px;">
                               <mat-label style="font-weight: 400;">Scanned by customer - {{wallet_balances.scanning_point ? wallet_balances.scanning_point : 0}} </mat-label>
                               <mat-label style="font-weight: 400;">Scanned by AFF - {{wallet_balances.direct_point ? wallet_balances.direct_point : 0}}</mat-label>
                           </div>
                           <!-- <div style="display: flex;justify-content:space-between;margin-bottom: 4px;">
                               <mat-label style="font-weight: 400;">Wallet Balance - {{wallet_balances.wallet_balance ? wallet_balances.wallet_balance : 0}} </mat-label>
                               <mat-label style="font-weight: 400;">Scheme Balance - {{wallet_balances.scheme_wallet ? wallet_balances.scheme_wallet : 0}}</mat-label>
                           </div> -->
                        </mat-card>

                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Payment Type <span class="sub">*</span></mat-label>
                            <mat-select formControlName="paymentType" id="paymentType">
                                <!-- <mat-option value="Wallet">Wallet Balance</mat-option> -->
                                <!-- <mat-option value="Scheme">Scheme Balance</mat-option> -->
                                <mat-option value="scanning_point">Scanned by customer</mat-option>
                                <mat-option value="direct_point">Scanned by AFF</mat-option>
                            </mat-select>
                        </mat-form-field> 
                        <!-- <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Payment Type <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="paymentType" id="paymentType"
                                readonly>
                        </mat-form-field> -->

                        <!-- <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Payment Type<span class="sub">*</span></mat-label>
                            <mat-select formControlName="paymentType" id="paymentType"
                                >
                        
                                <mat-option value="Scheme">Scheme Balance</mat-option>
                                <mat-option value="scanning_point">Scanned by customer</mat-option>
                                <mat-option value="direct_point">Scanned by AFF</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="AmountPayForm.controls.paymentType.invalid && AmountPayForm.controls['paymentType']?.touched ">

                        </mat-hint> -->
                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Mode <span class="sub">*</span></mat-label>
                            <mat-select formControlName="paymentMode" id="paymentMode"
                                (ngModelChange)="checkPaymentMode()">
                                <!-- <mat-option value="Cash">Cash</mat-option> -->
                                <mat-option value="Credit Note">Credit Note</mat-option>
                                <mat-option value="Cheque">Cheque</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="AmountPayForm.controls.paymentMode.invalid && AmountPayForm.controls['paymentMode']?.touched ">

                        </mat-hint>
                    </div>
                </div>
                <div class="row row-mb0">
                    <div class="col s12">
                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Point <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="paymentAmount"
                                id="paymentAmount" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="AmountPayForm.controls.paymentAmount.invalid && AmountPayForm.controls['paymentAmount']?.touched ">

                        </mat-hint>
                    </div>
                    <!-- <div class="row row-mb0"> -->
                        <div class="col s12">
                            <mat-form-field appearance="outline" class="cs-input mr5">
                                <mat-label>Remark <span class="sub">*</span></mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="remark"
                                    id="remark">
                            </mat-form-field>
                            <mat-hint class="has-error"
                                *ngIf="AmountPayForm.controls.remark.invalid && AmountPayForm.controls['remark']?.touched ">
    
                            </mat-hint>
                        </div>
                    <!-- </div> -->
                </div>
         


                <!-- Cheque No & Cheque Date Field Start -->

                <div class="row row-mb0" *ngIf="chequeMode">
                    <div class="col s12">
                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Cheque No <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="chequeNo" id="chequeNo">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AmountPayForm.controls.chequeNo.invalid && AmountPayForm.controls['chequeNo']?.touched &&
                            !AmountPayForm.controls['chequeNo']['errors']?.minlength">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="AmountPayForm.controls.chequeNo.invalid &&
                            AmountPayForm.controls['chequeNo']['errors']?.minlength || AmountPayForm.controls['chequeNo']['errors']?.maxlength ">
                            Cheque No Atleast Required 4 Digits
                        </mat-hint>
                    </div>
                </div>
                <div class="col s12 m3" *ngIf="chequeMode">
                    <mat-form-field appearance="outline" class="cs-input">
                        <mat-label>Date of Cheque <span class="sub">*</span></mat-label>
                        <input matInput [matDatepicker]="datecheque" formControlName="chequeDate" id="chequeDate"
                            [min]="date" readonly (click)="datecheque.open()">
                        <mat-datepicker-toggle matSuffix [for]="datecheque"></mat-datepicker-toggle>
                        <mat-datepicker #datecheque disabled="false"></mat-datepicker>
                    </mat-form-field>
                    <mat-hint class="has-error" *ngIf="AmountPayForm.controls.chequeDate.invalid && 
                        AmountPayForm.controls['chequeDate']?.touched">
                    </mat-hint>
                </div>

                <!-- Cheque No & Cheque Date End -->

                <!-- PayTm Transaction No Field Start -->


                <div class="row row-mb0" *ngIf="paytmMode">
                    <div class="col s12">
                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Credit Note Transaction No <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="transactionNo"
                                id="transactionNo">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AmountPayForm.controls.transactionNo.invalid && AmountPayForm.controls['transactionNo']?.touched &&
                            !AmountPayForm.controls['transactionNo']['errors']?.minlength">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="AmountPayForm.controls.transactionNo.invalid &&
                            AmountPayForm.controls['transactionNo']['errors']?.minlength || AmountPayForm.controls['transactionNo']['errors']?.maxlength ">
                            Transaction No Atleast Required 4 Digits
                        </mat-hint>
                    </div>
                  
                    <div class="col s12">
                        <!-- <span> -->
                        <!-- <mat-form-field appearance="outline" class="cs-input mr5">
                                <mat-icon>cloud_upload</mat-icon>
                                <mat-label>Upload File</mat-label>
                                <input type="file" accept=".pdf" style="display: none;" placeholder="Type Here ..." formControlName="pdf_file"
                                    id="pdf_file">
                            </mat-form-field> -->
                        <label class="file-input" *ngIf="!fileToUpload">
                            <mat-icon>cloud_upload</mat-icon>
                            <p>Upload File</p>
                            <!-- <input type="file"  accept=".pdf" formControlName="pdf_file"
                                id="pdf_file" style="display: none;"> -->
                            <input type="file" (change)="handleFileInput($event.target.files)" accept=".pdf"
                                style="display: none;">
                        </label>
                        <div class="cs-action mt0" *ngIf="fileToUpload">
                            <!-- <a class="ac-green" matRipple matTooltip="View"
                                    matTooltipPosition="below">
                                    <i class="material-icons">remove_red_eye</i>
                                </a> -->
                            <ng-container>
                                <a class="ac-red" (click)="removePdfFile()" matRipple matTooltip="Delete"
                                    matTooltipPosition="below"><i class="material-icons">delete_sweep</i></a>
                            </ng-container>
                        </div>
                    </div>
                    <!-- <div class="div_cell ac mmw160">
                        <div class="cs-action">
                            <button class="ac-green with-text" [disabled]="data.wallet_balance=='0' && data.scheme_wallet =='0'" 
                                (click)="payNowModel(data)" matRipple matTooltip="Pay Now"
                                matTooltipPosition="below">Points Settlement</button>
                            <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                <a class="ac-red" *ngIf=" datas.module_name== 'Distributor' && datas.delete==1"
                                    (click)="deleteDistributorData(data.id)" matRipple matTooltip="Delete"
                                    matTooltipPosition="below"><i class="material-icons">delete_sweep</i></a>
                            </ng-container>
                        </div>
                    </div> -->
                </div>

                <!-- PayTm Transaction No Field End -->

            </div>
        </div>

        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button class="save-btn" mat-button [disabled]="AmountPayForm.invalid || hideSaveButton">Save</button>
                <!-- <button class="save-btn" mat-button>Pay</button> -->
            </div>
        </mat-dialog-actions>

    </form>
</div>