import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';


@Component({
    selector: 'app-retailer-to-mechanic-list',
    templateUrl: './retailer-to-mechanic-list.component.html'
})
export class RetailerToMechanicListComponent implements OnInit {

    data: any = [];
    skeleton: any = [];
    loader: any;
    mechnaicTransaction: any;
    searchData: any;
    mechnaicTransactionSearch: any = {};
    date: any;
    pageNumber: any;
    paginationData: any = {};
    total: any;
    totalTrans: any;


    constructor(

        private apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.data = new Array(2);
        this.skeleton = new Array(10);
        this.date = new Date();
        this.pageNumber = 1;
        this.retailerTransactionList(1);

    }

    ngOnInit(): void {


    }


    retailerTransactionList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }
        this.mechnaicTransaction = [];
        this.paginationData.searchData      =   this.mechnaicTransactionSearch;
        this.paginationData.pagelimit=20;
        this.paginationData.start       =   (this.pageNumber - 1)*this.paginationData.pagelimit;

        // return;
        this.apiHit.PostRequest(this.paginationData, "Distributor/retailer_to_mechanic_transaction_search").subscribe((result => {

            console.log(result);

            this.mechnaicTransaction = result['retailer_to_mechanic_transaction_search']['data'];
            this.total = result['retailer_to_mechanic_transaction_search'].totalPage;
            this.totalTrans = result['retailer_to_mechanic_transaction_search'].total_rows;
            this.loader = '';

        }));

    }



    onChangePageNumber(type) {

        console.log(this.pageNumber);
        setTimeout(() => {

            if (type == 'previous') {

                if (this.pageNumber > 1) {


                    this.pageNumber -= 1;
                    this.retailerTransactionList(2);

                }

            }
            else if (type == 'next') {

                if (this.total > this.pageNumber) {

                    this.pageNumber += 1;
                    this.retailerTransactionList(2);

                }

            }
            else if (type = 'current') {

                if (this.pageNumber > this.total) {

                    console.log('current else');

                    this.pageNumber = this.total;
                    this.retailerTransactionList(2);

                }
                else if (this.pageNumber == 0) {

                    this.pageNumber = 1;
                    this.retailerTransactionList(2);

                }
                else if (this.pageNumber) {

                    console.log('last else');

                    this.retailerTransactionList(2);
                }

            }
        }, 100);


    }

    //Get DIstributor List Through API Function End

    public onDate(event): void {

        this.mechnaicTransactionSearch.date_created = moment(event.value).format('YYYY-MM-DD');
        this.retailerTransactionList(1);

    }

    refreshData() {

        this.mechnaicTransactionSearch = {};
        if(this.pageNumber == null){
            this.pageNumber=1;
        }
        this.retailerTransactionList(1);

    }

    exportExcel() {

        this.loader = 1;
        this.paginationData.pagelimit = 0;
        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_retailer_to_mechanic").subscribe((result => {
    
          console.log(result);
          if (result['msg'] == 'Success') {
            this.retailerTransactionList(1);
            setTimeout(() => {
    
              window.open(this.apiHit.downloadURL + "uploads/Mechanic_transaction.csv");
              this.loader = '';
    
            }, 700);
          }
    
        }));
      }

}
