import { newArray } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
    selector: 'app-mechanic-list',
    templateUrl: './mechanic-list.component.html'
})
export class MechanicListComponent implements OnInit {

    mechanicData: any = [];
    skeleton: any = [];
    loader: any;
    filterSearch: any = {};
    pageSize: any = 3;
    pageStart: any = 0;
    pageNumber: any;
    totalPage: any;
    totalMechanic = {};
    date: any;
    dataNotFound = false;
    paginationData: any = {};


    constructor(

        public dialogs: DialogService,
        public db: DatabaseService

    ) {

        this.pageNumber = 1;
        // this.mechanicList(1);
        this.skeleton = new Array(10);
        this.date = new Date();
    }

    ngOnInit(): void {
        this.paginationData = this.db.getFilterData('mechanic-list');
        this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        console.log('mechnaic list filter search ',this.paginationData);
        this.mechanicList(this.paginationData?.target ? this.paginationData.target : 1);
    }


    
    // *** MechanicList Funcation Start ***//
    mechanicList(target) {

        this.loader = 1;

        //   for (const key in this.filterSearch) {
        //       if(this.filterSearch[key]){
        //           this.pageNumber  = 1;
        //           break;
        //       }
        //   }
        if (target == 1) {
            this.pageNumber = 1;
        }

        this.mechanicData = [];
        this.paginationData.search = this.filterSearch;
        this.paginationData.pagelimit = 20;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
        this.paginationData.target = target;
        this.paginationData.pageNumber = this.pageNumber;

        this.db.setFilterData(this.paginationData);

        this.db.PostRequest(this.paginationData, "Mechanic/mechanic_list").subscribe((result => {

            console.log(result);
            this.mechanicData = result['mechanic_list']['info'];
            this.totalPage = result['mechanic_list']['totalPage'];
            this.totalMechanic = result['mechanic_list']['count'];

            this.loader = '';

            if (this.mechanicData.length == 0) {
                this.dataNotFound = true;
            }
            else {
                this.dataNotFound = false;
            }

        }));

    }
    // *** MechanicList Funcation Start ***//

    // pagination(number) {
    //     console.log(number);
    //     this.pageStart = this.pageSize + this.mechanicData.length;
    //     console.log(this.mechanicData.length);
    //     console.log(this.pageStart);

    // }

    public onDate(event): void {
        this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
        this.mechanicList(1);
    }


    onChangePageNumber(type) {
        console.log(this.pageNumber);
        setTimeout(() => {


            if (type == 'previous') {

                if (this.pageNumber > 1) {

                    this.pageNumber -= 1;
                    this.mechanicList(2);

                }

            }
            else if (type == 'next') {

                if (this.totalPage > this.pageNumber) {

                    this.pageNumber += 1;
                    this.mechanicList(2);

                }

            }
            else if (type = 'current') {

                if (this.pageNumber > this.totalPage) {

                    console.log('current else');

                    this.pageNumber = this.totalPage;
                    this.mechanicList(2);

                }
                else if (this.pageNumber == 0) {

                    this.pageNumber = 1;
                    this.mechanicList(2);

                }
                else if (this.pageNumber) {

                    console.log('last else');

                    this.mechanicList(2);
                }

            }
        }, 100);


    }

    // *** Delete Funcation Start ***//
    deleteItem(id) {

        this.dialogs.delete('').then((result) => {

            if (result === true) {

                this.db.PostRequest({ 'id': id, }, "Mechanic/delete_mechanic").subscribe((result => {

                    console.log(result);
                    console.log(id);

                }));
                this.mechanicList(2);

            }

        });

    }
    // *** Delete Funcation End ***//


    // *** Refresh Funcation start ***//
    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null) {
            this.pageNumber = 1;
        }
        this.mechanicList(1);

    }
    // *** Refresh Funcation End ***//



    exportMechanicData() {

        this.loader = 1;
        this.paginationData.pagelimit = 0;
        this.db.PostRequest(this.paginationData, "Distributor/EXPORT_MECHANIC_DATA").subscribe((result => {

            console.log(result);
            this.mechanicList(1);
            setTimeout(() => {
                this.loader = '';
                window.open(this.db.downloadURL + "uploads/MECHANIC_DATA.csv")
            }, 700);

        }));
    }

}