<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>User</h2>
                    <p>{{totalUser}} User Available </p>
                </div>
                <div class="left-auto mr10">
                    <div class="cs-btn mt0" *ngFor=" let datas of apiHit.userLoginData.data.moduleData">
                        <a *ngIf="datas.module_name== 'User' && datas.add==1" mat-button routerLink="../user-add/{{0}}">Add new</a>
                    </div>
                </div>
            </ng-container>
            <div class="cs-action">
                <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="userList(1,'')"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        <mat-tab-group class="tab-group flat-tab-body tab-group-list" [selectedIndex]="matTabValue - 1" (selectedTabChange)="tabClick($event)"  mat-align-tabs="start">
                
            <mat-tab label= "1">
                <ng-template mat-tab-label *ngIf="!loader">
                    <mat-icon class="example-tab-icon">desktop_windows</mat-icon>
                    System User
                    <!-- <span class="badge">{{pendingDispatchCount}}</span> -->
                </ng-template>
                <ng-template mat-tab-label *ngIf="loader">
                    <div class="sk-loading">&nbsp;</div>
                </ng-template>
            </mat-tab>
            
            <mat-tab label= "2">
                <ng-template mat-tab-label *ngIf="!loader">
                    <mat-icon class="example-tab-icon">person</mat-icon>
                    Sales User
                    <!-- <span class="badge">{{dispatchCount}}</span> -->
                </ng-template>
                <ng-template mat-tab-label *ngIf="loader">
                    <div class="sk-loading">&nbsp;</div>
                </ng-template> 
            </mat-tab>
            
        </mat-tab-group>
        <div class="container-pd10-5 user-calc no-padding">
        
            <div class="cs-table">
                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date Created</div>
                            <div class="div_cell mmw150">Created By</div>
                            <div class="div_cell mmw150" *ngIf="matTabValue==2">Designation</div>
                            <div class="div_cell mmw180">Name</div>
                            <div class="div_cell mmw90">Mobile No.</div>
                            <div class="div_cell mmw190" *ngIf="matTabValue!=2">Type</div>
                            <div class="div_cell mmw120" *ngIf="matTabValue!=2">Designation</div>
                            <div class="div_cell mmw120" *ngIf="matTabValue==2">Reporting Manager</div>
                            <div class="div_cell mmw150" >Email Id</div>
                            <!-- <div class="div_cell mmw90" *ngIf="matTabValue==2">Weekly Off</div> -->
                            <div class="div_cell mmw240">Address</div>
                            <div class="div_cell text-center mmw70">Action</div>
                            <div class="div_cell text-center mmw70 af-bdr-right" *ngIf="matTabValue==2">Reset Device</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="dateCreated" 
                                        [(ngModel)]="userListSearch.dateCreated" (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw150">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="createdBy" [(ngModel)]="userListSearch.createdBy"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            <div class="div_cell mmw150" *ngIf="matTabValue==2">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="designation" [(ngModel)]="userListSearch.designation"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="name" [(ngModel)]="userListSearch.name"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mobile_no" [(ngModel)]="userListSearch.mobile_no"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            
                            
                            <div class="div_cell mmw190" *ngIf="matTabValue!=2">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mobile_no" [(ngModel)]="userListSearch.mobile_no"
                                    (keyup.enter)="userList(1);">
                                </div> -->
                            </div>
                            <div class="div_cell mmw120" *ngIf="matTabValue==2">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="reporting_manager" [(ngModel)]="userListSearch.reporting_manager"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            <div class="div_cell mmw120" *ngIf="matTabValue!=2" >
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="system_user_designation" [(ngModel)]="userListSearch.system_user_designation"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            <div class="div_cell mmw150" >
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="email_id" [(ngModel)]="userListSearch.email_id"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div>
                            <!-- <div class="div_cell mmw90" *ngIf="matTabValue==2">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="weekly_off" [(ngModel)]="userListSearch.weekly_off"
                                    (keyup.enter)="userList(1,UserType);">
                                </div>
                            </div> -->
                            
                            
                            
                            <div class="div_cell mmw240">&nbsp;</div>

                            <div class="div_cell text-center mmw70">&nbsp;</div>
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="userList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            
                            <div class="div_cell text-center mmw70 af-bdr-right" *ngIf="matTabValue==2">&nbsp;</div>
                            
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->
                
                <section class="td_cell" >
                    
                    <!-- Loop Data -->
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of userData.userDetails; let i = index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw150">{{data.created_by_name ? (data.created_by_name | titlecase) :'N/A'}}</div>
                            <div class="div_cell mmw150" *ngIf="matTabValue==2">
                                {{data.designation_name ? data.designation_name:'N/A'}}
                            </div>
                            <div class="div_cell mmw180"><a class="link-btn" mat-flat-button routerLink="../user-detail/{{data.id}}">{{data.name ? (data.name | titlecase):'N/A'}}</a></div>
                            <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                            
                            
                            <div class="div_cell mmw190" *ngIf="matTabValue!=2">
                                <span *ngIf="data.user_type == 'System_user'">
                                    System User
                                </span>
                                <span *ngIf="data.user_type == 'approval_user'">
                                    Approval User - {{data.approval_type ? (data.approval_type | titlecase) : 'N/A'}}
                                </span>
                            </div>
                            <div class="div_cell mmw120" *ngIf="matTabValue!=2">
                                {{data.system_user_designation ? data.system_user_designation:''}}
                            </div>


                            <div class="div_cell mmw120" *ngIf="matTabValue==2">
                                {{data.reporting_manager ? data.reporting_manager:'N/A'}}
                            </div>
                            <div class="div_cell mmw150" style="word-break: break-word">
                                {{data.email_id ? data.email_id:'N/A'}}
                            </div>
                            <!-- <div class="div_cell mmw90" *ngIf="matTabValue==2">
                                {{data.weekly_off ? data.weekly_off:'N/A'}}
                            </div> -->
                            
                            <div class="div_cell mmw240" *ngIf="data.street || data.city_name || data.district_name || data.state_name || data.pincode">
                                {{data.street? (data.street + ','):''}} {{data.city_name? (data.city_name + ','):''}} {{data.district_name? (data.district_name + ','):''}} {{data.state_name? (data.state_name + ','):''}} {{data.pincode}}
                            </div>
                            
                            <div class="div_cell mmw240" *ngIf="data.street == '' && data.city_name == '' && data.district_name == '' && data.state_name == '' && data.pincode == ''">
                                N/A
                            </div>
                            
                            <div class="div_cell mmw70">
                                <div class="cs-action">
                                    <ng-container *ngFor=" let datas of apiHit.userLoginData.data.moduleData">
                                        <a  class="ac-green" matRipple  matTooltip="Edit" matTooltipPosition="below" *ngIf="datas.module_name == 'User' && datas.edit == 1" routerLink="../user-add/{{data.id}}"><i class="material-icons">edit</i></a>
                                        <a  class="ac-red" matRipple  matTooltip="Delete" matTooltipPosition="below" *ngIf="datas.module_name == 'User' && datas.delete == 1"><i class="material-icons" (click)="deleteUser(data.id)">delete_sweep</i></a>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="matTabValue==2" class="div_cell ac mmw70">
                                <div class="cs-btn">
                                    <button mat-ripple matTooltip="Reset Device" class="save-btn" color="accent" *ngIf="data.device_unique_id != ''"
                                    (click)="resetDevice(i,data.id)">Reset Device </button>
                                </div>
                                </div>
                        </main>
                        <app-not-found *ngIf="userData.userDetails.length == 0"></app-not-found>
                    </ng-container>
                    <!-- Loop Data -->
                    
                    
                    <!-- Skeleton Loading Start -->
                    
                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;" >
                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw150" *ngIf="matTabValue==2"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw180"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            
                            <div class="div_cell mmw190" *ngIf="matTabValue!=2"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw120" *ngIf="matTabValue==2"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90" *ngIf="matTabValue==2"><p class="skeleton wp100">&nbsp;</p></div>
                            
                            <div class="div_cell mw240"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw70"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw70"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>
                    
                    <!-- Skeleton Loading End -->
                    
                </section>
            </div>
            
            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
        
    </div>
</div>