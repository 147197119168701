import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-mechanic-edit',
  templateUrl: './mechanic-edit.component.html'
})
export class MechanicEditComponent implements OnInit {
  
  AddRetailerForm : FormGroup;
  assignRetailerData: any=[]
  retailerData:any=[];
  
  dataValue :any;
  mechanicId:any;
  assign:any=[];
  
  constructor(@Inject(MAT_DIALOG_DATA)public data, private fb:FormBuilder, public db: DatabaseService, public dialogRef: MatDialog,
  public dialog: DialogService)
  {
    this.retailerList();
    console.log(data.id);
    this.mechanicId=data.id;
    this.assignRetailerData=data.assignRetailerData;
    console.log(this.mechanicId);
    console.log(this.assignRetailerData);
    
  }
  
  ngOnInit(): void {
    
    this.dataValue = this.data['target'];
    
    this.AddRetailerForm = this.fb.group({
      retailer_id:  ['',[Validators.required]],
    })
    
  }

  showOptions(event:MatCheckboxChange): void {
    console.log(event.checked);
    console.log("check funcation");
    
}
  
  retailerList()
  {
    this.db.PostRequest('',"Mechanic/retailer_list").subscribe((result=>{
      // this.retailerData = result['retailer_list'];

      this.retailerData = result['retailer_list'];

      for (let index = this.retailerData.length - 1; index >= 0; index--) {
             const isIndexExist = this.assignRetailerData.findIndex(row => row.retailer_assign_id == this.retailerData[index].id);
             console.log(isIndexExist);
             if(isIndexExist !== -1) {
                 this.retailerData.splice(index, 1);
             }
        
      }
    }));
  }
  
  retailerid(){
    
    this.assign=[];
    for (let index = 0; index < this.AddRetailerForm.value.retailer_id.length; index++) {
      console.log(this.AddRetailerForm.value.retailer_id.length);
      this.assign.push({"mechanic_id" : this.mechanicId, "retailer_id": this.AddRetailerForm.value.retailer_id[index]});
    }
    console.log(this.assign);
  }
  
  
  updateRetailer(){


    console.log(this.assign);

    this.dialog.save_distributor('').then((result) => {
                
      if(result === true) {


    this.db.PostRequest({"assign":this.assign},"Mechanic/assign_retailer").subscribe((result=>{
      console.log(result);
      if(result['assign_retailer'] == "success" ) 
      {
        this.dialog.success('Success',result['assign_retailer']);
        this.dialogRef.closeAll();
      }
      else
      {
        this.dialog.error('error',result['assign_retailer']);
      }
    }));
 
  }


});

}
}