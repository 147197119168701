<div class="container-fluid">
    <div class="container">
        
        <section id="head" class="ac">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below" (click)="goBack()" *ngIf="!loader">
                <mat-icon>arrow_back</mat-icon>
            </a>
            
            <div class="module-name" *ngIf="!loader">
                <h2>mechanic Detail</h2>
                <p>{{mechanicDetails.contact_name}}</p>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        
        <div class="container-pd10-5 no-padding hidden">
            
            <mat-tab-group class="tab-group tab-group-detail" >
              

                <!-- Mechanic Detail Tab Start -->

                <mat-tab >
                    <ng-template mat-tab-label  *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">info</mat-icon>
                        Detail
                    </ng-template>
                    <div class="row">
                        <div class="col s12 m8">
                            <div class="cs-column" *ngIf="!loader">
                                <div class="cs-head">
                                    <h2>basic Information</h2>
                                </div>
                                
                                <div class="cs-form">
                                    <div class="row">
                                        <div class="col s12 m12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Name</span>
                                                    <p>{{mechanicDetails.contact_name}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Mobile</span>
                                                    <p>{{mechanicDetails.mobile_no}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Date & Created By</span>
                                                    <!-- - {{mechanicDetails.retailerData[0].retailer_assigned_name}} -->
                                                    <p>{{(mechanicDetails.date_created)| date:'d MMM y'}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-mb0">
                                        <div class="col s12 m12">
                                            <div class="fields fields-bg">
                                                <div class="cs-box">
                                                    <span>Total Product Scan</span>
                                                    <p>{{mechanicDetails.totalCouponScan}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Total Scan Value</span>


                                                

                                                    <p>{{mechanicDetails.totalScanValue? 
                                                        mechanicDetails.totalScanValue: '0' }}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Total Walllet Balance</span>
                                                    <p>{{mechanicDetails.wallet_balance? 
                                                        mechanicDetails.wallet_balance: '0' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider class="mt10 mb10"></mat-divider>
                                    <div class="row row-mb0">
                                        <div class="col s12 m12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Address</span>

                                                    <p *ngIf="mechanicDetails.street || mechanicDetails.district_name || mechanicDetails.state_name || mechanicDetails.pincode">
                                                        {{mechanicDetails.street? (mechanicDetails.street + ','):''}} {{mechanicDetails.district_name? (mechanicDetails.district_name + ','):''}} 
                                                        {{mechanicDetails.state_name? (mechanicDetails.state_name + ','):''}}  {{mechanicDetails.pincode}}
                                                    </p>

                                                    <p *ngIf="!mechanicDetails.street && !mechanicDetails.district_name && !mechanicDetails.state_name && !mechanicDetails.pincode">
                                                        Address Not Available
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cs-column" *ngIf="loader">
                                <div class="cs-head sk-loading">
                                    <h2>&nbsp;</h2>
                                </div>
                                
                                <div class="cs-form">
                                    <div class="row row-mb0">
                                        <div class="col s12 m7">
                                            <div class="fields sk-loading">
                                                <div class="cs-box">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col s12 m5">
                                            <div class="fields sk-loading">
                                                <div class="cs-box">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <mat-divider class="mt10 mb10"></mat-divider>
                                    <div class="row row-mb0">
                                        <div class="col s12 m12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m4">
                            <div class="cs-column" *ngIf="!loader">
                                <div class="cs-head">
                                    <h2>retailer Information</h2>

                                    <ng-container *ngIf="login_data.user_type=='System_user'">


                                        <span *ngFor="let datas of db.userLoginData.data.moduleData">

                                    <a class="edit-desc"  *ngIf=" datas.module_name== 'Mechanic' && datas.add==1" (click)="infoEdit()"  mat-raised-button><i class="material-icons">add</i></a>
                                </span>  
                                </ng-container>
                                </div>
                                <div class="cs-table">
                                    <!-- Table Head Start-->
                                    <figure class="table-head">
                                        <section class="th_cell">
                                            <main>
                                                <div class="div_cell mw100  af-bdr-left">Name</div>

                                                <div class="div_cell mmw90">Mobile No.</div>

                                                <div class="div_cell mmw110 text-right">Wallet Balance</div>
                                                
                                                <ng-container *ngIf="login_data.user_type=='System_user'">

                                                <div class="div_cell text-center mmw40 af-bdr-right"  *ngIf="retailerData.length > 1">Action</div>
                                                </ng-container>
                                            </main>
                                        </section>
                                    </figure>
                                    <!-- Table Head End -->
                                    
                                    
                                    
                                    <section class="td_cell" >
                                        <!-- Loop Data -->
                                        <main *ngFor="let row of retailerData">
                                            <div class="div_cell mw100 ">{{row.retailer_assigned_name | titlecase}}- {{row.retailer_assign_id}}</div>


                                                                                    <div class="div_cell mmw90">{{row.retailer_assigned_mobile}}</div>


                                            <div class="div_cell mmw110 afe"> {{row.mechanic_wallet ?  row.mechanic_wallet : '0'}}</div>
                                            <div class="div_cell mmw40 ac"  *ngIf="retailerData.length > 1">
                                                <div class="cs-action" *ngIf="login_data.user_type=='System_user'">
                                                    <a class="ac-red" matRipple  matTooltip="Delete" matTooltipPosition="below" (click)="deleteItem(row.id)"><i class="material-icons">delete_sweep</i></a>
                                                </div>
                                            </div>
                                        </main>
                                        <!-- Loop Data -->
                                        
                                    </section>
                                </div>
                            </div>

                            <div class="cs-column" *ngIf="loader">
                                <div class="cs-head sk-loading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <div class="cs-table">
                                    
                                    <section class="td_cell" >
                                        <!-- Loop Data -->
                                        <main *ngFor="let row of skData">
                                            <div class="div_cell mw100 "><p class="skeleton wp100">&nbsp;</p></div>
                                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                                            <div class="div_cell mmw40 ac" *ngIf="retailerData.length > 1"><p class="skeleton wp100">&nbsp;</p></div>
                                        </main>
                                        <!-- Loop Data -->
                                        
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-tab>

                <!-- Mechanic Detail Tab End -->



                <!-- Mechanic Transaction Tab Start -->

                <mat-tab>
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Transaction
                    </ng-template> 
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table cs-column">
                                <!-- Table Head Start-->
                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw120">Date</div>
                                            <div class="div_cell mw140">Retailer</div>
                                            <div class="div_cell mmw200">Payment Mode</div>
                                            <div class="div_cell mmw110 text-right af-bdr-right">Amount</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw120">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker.open()">
                                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="dateCreated" 
                                                        [(ngModel)]="filterSearch.dateCreated" (dateChange)="onDate($event)"  readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mw140">
                                                <div class="filter-data">
                                                    <i class="material-icons">search</i>
                                                    <input type="text" placeholder="Search ..." name="retailerData"
                                                    [(ngModel)]="filterSearch.retailerData" (keyup.enter)="searchingTransaction();">
                                                </div>
                                            </div>
                                            <div class="div_cell mmw200">
                                            </div>
                                            <div class="div_cell text-center mmw110 af-bdr-right">
                                                <div class="cs-action jfe">
                                                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="searchingTransaction()"><i class="material-icons">search</i></a>
                                                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                                                </div>
                                            </div>
                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->
                                
                                
                                
                                <section class="td_cell" >
                                    <!-- Loop Data -->
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let row of mechanicTransaction; let i=index;">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw120">{{row.date_created | date:'d MMM y'}}</div>
                                            <div class="div_cell mw140">{{row.retailer_name ? row.retailer_name : 'N/A'}} - {{row.retailer_mobile ? row.retailer_mobile : 'N/A'}}</div>
                                            <div class="div_cell mmw200">{{row.payment_mode ? row.payment_mode : 'N/A'}}</div>


                                           

                                            <div class="div_cell mmw110 afe"> {{row.payment_amount ? row.payment_amount : '0'}}</div>
                                        </main>
                                        <app-not-found *ngIf="mechanicTransaction.length == 0"></app-not-found>
                                    </ng-container>
                                    <!-- Loop Data -->


                                    <!-- Skeleton Loading Start -->

                                    <ng-container *ngIf="loader">
                                        <main *ngFor="let row of skeleton;" >
                                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                                            <div class="div_cell mmw120"><p class="skeleton wp100">&nbsp;</p></div>
                                            <div class="div_cell mw140"><p class="skeleton wp100">&nbsp;</p></div>
                                            <div class="div_cell mmw200"><p class="skeleton wp100">&nbsp;</p></div>
                                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                                        </main>
                                    </ng-container>

                                    <!-- Skeleton Loading End -->

                                </section>
                            </div>
                        </div>
                    </div>   
                </mat-tab>

                <!-- Mechanic Transaction Tab End -->


            </mat-tab-group>
        </div>
    </div>
</div>
