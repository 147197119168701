<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Target</h2>
                    <p>{{targetData.all ? targetData.all : '0'}} Target Are Available</p>
                </div>
            </ng-container>
            <ng-container *ngIf="!loader && targetData.last_sync_date  != '0000-00-00 00:00:00'">
                <div class="left-auto df afe">
                    <div class="indicates">
                        <!-- Last Sync Time - {{lastUpdatedDate}} -->
                        Last Sync Time - {{targetData.last_sync_date ? (targetData.last_sync_date | date: 'd MMM y, hh mm a') : 'N/A'}}
                    </div>
                    <div class="cs-action">
                        <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                            (click)="getTargetList(1)"><i class="material-icons">search</i></a>
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                            (click)="refreshData()"><i
                                class="material-icons">settings_backup_restore</i></a>
                    </div>
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw90">Month-quarter</div>
                            <div class="div_cell mmw90">Year</div>
                            <div class="div_cell mmw100">Dr Code</div>
                            <div class="div_cell mw270">Dr Name</div>
                            <!-- <div class="div_cell mmw90">Segment</div> -->
                            <div class="div_cell mmw120">Zone</div>
                            <div class="div_cell mmw120">State</div>
                            <div class="div_cell mmw120">Target</div>
                            <div class="div_cell mmw170">Achievement</div>
                            <div class="div_cell mmw140">Achievement(In %)</div>
                            <div class="div_cell mmw90">Action</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="month"
                                        [(ngModel)]="filterSearch.month" (keyup.enter)="getTargetList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="year"
                                        [(ngModel)]="filterSearch.year" (keyup.enter)="getTargetList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_code"
                                        [(ngModel)]="filterSearch.dr_code" (keyup.enter)="getTargetList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw270">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_name"
                                        [(ngModel)]="filterSearch.dr_name" (keyup.enter)="getTargetList(1);">
                                </div>
                            </div>
                            <!-- <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="getTargetList(1);">
                                </div>
                            </div> -->
                            <div class="div_cell mmw120"></div>
                            <div class="div_cell mmw120"></div>
                            <div class="div_cell mmw120"></div>
                            <div class="div_cell mmw170"></div>
                            <div class="div_cell mmw140"></div>
                            <div class="div_cell mmw90">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="getTargetList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of targetData.targetData; let i=index">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw90">{{data.month ? (data.month | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw90">{{data.year ? data.year : 'N/A'}}</div>
                            <div class="div_cell mmw100">{{data.dr_code ? data.dr_code : 'N/A'}}</div>
                            <div class="div_cell mw270">{{data.dr_name ? (data.dr_name | titlecase):'N/A'}}</div>
                            <!-- <div class="div_cell mmw90">{{data.segment ? (data.segment | titlecase):'N/A'}}</div> -->
                            <div class="div_cell mmw120">{{data.zone ? data.zone:'--'}}</div>
                            <div class="div_cell mmw120">{{data.state_name ? data.state_name:'--'}}</div>
                            <div class="div_cell mmw120">{{data.target ? data.target:'0'}}</div>
                            <div class="div_cell mmw170">{{data.achivement ? data.achivement : '0'}}</div>
                            <div class="div_cell mmw140">{{data.achivement_in_percentage ? data.achivement_in_percentage : '0'}}%</div>
                            <div class="div_cell mmw90">
                                <div class="cs-action">
                                    <ng-container *ngIf="apiHit.userLoginData.data.moduleData[9]['delete'] == 1">
                                        <a class="ac-red" matRipple  matTooltip="Delete" matTooltipPosition="below" (click)="deleteItem(data.id)"><i class="material-icons">delete_sweep</i></a>
                                    </ng-container>
                                </div>
                            </div>
                        </main>
                        <app-not-found *ngIf="targetData.targetData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw270">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <!-- <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div> -->
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw170">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>
                </section>
            </div>

            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>

                    
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="apiHit.userLoginData.data.moduleData[9]['add'] == 1" (click)="ExcelUploadFunction(targetData)"><img src="assets/img/excel.png"> Upload Excel</a>
                        <a mat-raised-button color="accent" *ngIf="targetData.targetData.length > 0"  (click)="ExcelDownloadFunction()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>