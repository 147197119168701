import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';
import { MatDialog } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-billing-detail',
  templateUrl: './billing-detail.component.html',
})
export class BillingDetailComponent implements OnInit {

  billingDetail: any;
  loader: any;
  data_val: any;
  skeleton: any = [];
  skData: any = [];
  skDataTwo: any = [];
  TaxAmount: any;
  Amtvalue:any;
  totalAmount:any;
  constructor(

    public dialog: MatDialog,
    public activateRoute: ActivatedRoute,
    private apiHit: DatabaseService,
    private _location: Location,
    public dialog2: DialogService,

  ) {

    console.log(this.activateRoute['params']['_value']);
    this.getBillingDetail();
    this.skeleton = new Array(10);
    this.skData = new Array(4)
    this.skDataTwo = new Array(2);


  }

  ngOnInit(): void {
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val);
  }


  // Back Button Function  Start

  backClicked() {

    this._location.back();

  }
  // Back Button Function  End




  //Reatailer Detail Function Start

  getBillingDetail() {

    this.loader = 1;

    this.apiHit.PostRequest({
      'ODN_Number': this.activateRoute['params']['_value'].id
    }, "app/AppDistributorDetails/GET_INVOICE_BILLING_DETAIL"
    ).subscribe((result => {

      console.log(result);

      if (result['status'] == "Success") {
        this.billingDetail = result['data'];
        this.loader = '';
        console.log(this.billingDetail.items)
      }
      else {
        this.dialog2.error('error', 'Something Went Wrong');
      }

    }));

  }
  //Reatailer Detail Function End



}