import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators, NgForm } from '@angular/forms';
import { DialogService } from '../services/dialog.service';
import { DatabaseService } from '../services/service';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    hide = true;
    logInForm: FormGroup;
    loader: any;
    userName: FormControlName
    data: any;
    login_details: any = [];
    userType: string = 'system';

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        private fb: FormBuilder,
        public apiHit: DatabaseService,
        public dialog: DialogService,
        public param: ActivatedRoute,
        public router: Router,

    ) {

    }

    ngOnInit(): void {

        this.data = this.apiHit.userLoginData.data

        this.logInForm = this.fb.group({
            userName: ['', [Validators.required]],
            password: ['', [Validators.required]]

        });

    }

    logIn(form: NgForm) {

        this.loader = 1;
        this.apiHit.PostRequest(this.logInForm.value, "Login/submitnew").subscribe((result => {

            this.login_details = result['data'];
            console.log("LoginData",this.login_details)

            if (result['message'] == 'Success') {

                if (this.login_details.user_type == 'System_user') {

                    setTimeout(() => {

                        this.apiHit.setLoginData(result);
                        this.dialog.success('Success', result['message']);
                        this.router.navigate([result['data']['navigation_url']]);
                        this.loader = '';
                        form.resetForm();


                        // if(result['data']['moduleData'][0]['view'] == 1){
                        //     console.log('in dashboard navigation');
                        //     this.router.navigate(['dashboard']);
                        //     form.resetForm();
                        //     return;
                        // }
                        // else if(result['data']['moduleData'][1]['view'] == 1){
                        //     console.log('in distributor navigation');
                        //     this.router.navigate(['distributor']);
                        //     form.resetForm();
                        //     return;
                        // }
                        // else if(result['data']['moduleData'][2]['view'] == 1){
                        //     console.log('in Retailer navigation');
                        //     this.router.navigate(['retailer']);
                        //     form.resetForm();
                        //     return;
                        // }
                        // else if(result['data']['moduleData'][3]['view'] == 1){
                        //     console.log('in Mechnaic navigation');
                        //     this.router.navigate(['mechanic']);
                        //     form.resetForm();
                        // }
                        // else if(result['data']['moduleData'][4]['view'] == 1){
                        //     console.log('in Coupon code navigation');
                        //     this.router.navigate(['coupon']);
                        //     form.resetForm();
                        //     return;
                        // }
                        // else if(result['data']['moduleData'][5]['view'] == 1){
                        //     console.log('in User navigation');
                        //     this.router.navigate(['user-list']);
                        //     form.resetForm();
                        //     return;
                        // }
                        // else if(result['data']['moduleData'][6]['view'] == 1){
                        //     console.log('in Banner navigation');
                        //     this.router.navigate(['banner-list']);
                        //     form.resetForm();
                        //     return;
                        // }
                        // else{
                        //     console.log('in Transaction Report navigation');
                        //     this.router.navigate(['ask-distributor']);
                        //     form.resetForm();
                        //     return;
                        // }

                    },
                        1000);

                }
                else if (this.login_details.user_type == 'scanning_user') {
                    setTimeout(() => {

                        this.apiHit.setLoginData(result);
                        this.router.navigate(['scan-qrCode']);
                        this.loader = '';
                        form.resetForm();
                    }, 1000);

                }
                else if (this.login_details.user_type == 'dispatch_user') {
                    setTimeout(() => {
                        this.apiHit.setLoginData(result);
                        this.router.navigate(['dispatch']);
                        this.loader = '';
                        form.resetForm();
                    }, 1000);

                }else if (this.login_details.login_type == 'distributor') {
                    setTimeout(() => {
                        this.apiHit.setLoginData(result);
                        this.router.navigate(['distributor-detail/'+this.login_details?.id]);
                        this.loader = '';
                        form.resetForm();
                    }, 1000);

                }
                else {

                    setTimeout(() => {
                        this.apiHit.setLoginData(result);
                        this.dialog.success('Success', result['message']);
                        this.router.navigate(['distributor']);
                        this.loader = '';
                        form.resetForm();

                    }, 1000);

                }

            }
            else if (result['message'] == 'Failed'){

                this.dialog.error('error', 'Incorrect User Name or Password');
                this.loader = '';

            }
            else if(result['message'] == 'Blocked'){
                this.dialog.error('Alert', 'Dear User, your account has been blocked.Please contact admin for support.');
                this.loader = '';
            }

            else {
                this.dialog.error('error', 'Something went wrong');
                this.loader = '';

            }

        }));

    }

    // KeyPress Function  Start

    NotAllowedSpace(event: any) {

        if (event.keyCode === 32) {

            event.preventDefault();

        }
    }
    // KeyPress Function  End

}