import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { CouponStatusModelComponent } from '../coupon/coupon-status-model/coupon-status-model.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-print-scanned-list',
  templateUrl: './print-scanned-list.component.html',
  styleUrls: ['./print-scanned-list.component.scss']
})
export class PrintScannedListComponent implements OnInit {
  filterSearch: any = {};
  data: any = [];
  date: any;
  skeleton: any = [];
  loader: boolean = false;
  paginationData: any = {};
  availableCouponCount: any;
  availableCouponData: any = [];
  scannedCouponCount: any;
  scannedCouponTotalAmount: any = 0;
  scannedCouponData: any = [];
  masterCouponData: any = [];
  pageNumber: any;
  total: any;
  totalCouponCount: any;
  matTabValue: any;
  totalcount: any = {};
  pagename:any;
  constructor(
    public apiHit: DatabaseService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.data = new Array(3);
        this.skeleton = new Array(10);
        this.date = new Date();
        this.matTabValue = 2;
        this.pageNumber = 1;
        
         
        this.couponCodeAvailableList(1);
    

   }

  ngOnInit(): void {
    this.paginationData = this.apiHit.getFilterData('coupon-list');
    // this.paginationData = JSON.parse(localStorage.getItem('filterData'));
    this.filterSearch = this.paginationData?.searchData ? this.paginationData.searchData : this.filterSearch;
    this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
    this.matTabValue = this.paginationData?.matTabValue ? this.paginationData?.matTabValue : this.matTabValue;

    console.log('coupon code list filter search ', this.paginationData);
    if (this.matTabValue == 1) {
        this.couponCodeAvailableList(this.paginationData?.target ? this.paginationData.target : 1);
    }
    else if (this.matTabValue == 2) {
        this.couponCodeScannedList(this.paginationData?.target ? this.paginationData.target : 1);
    }
    else {
        this.getMasterCouponList(this.paginationData?.target ? this.paginationData.target : 1);
    }
  }

  getCouponCount(){
    this.apiHit.PostRequest(this.paginationData, "Distributor/get_coupon_code_count").subscribe((result => {

        console.log(result);
        let data = result['coupon_code_count'];
        this.totalcount = {
            'primaryAvailableCount': data.couponAvailableCount,
            'primaryScannedCount': data.couponScannedCount,
            'masterAvailableCount': data.available_master,
            'masterScannedCount': data.scanned_master_coupon,
            'masterDispatchScannedCount': data.dispatched_master_coupon,
        }
        this.total = result['coupon_code_count']['totalAvailablePage'];
    }));
}


//Get Available Coupon List Through API Function Start
couponCodeAvailableList(target) {

    this.loader = true;
    this.availableCouponData = [];
    this.totalcount = {};
    if (target == 1) {
        this.pageNumber = 1;
    }
    if (this.filterSearch.date_from && this.filterSearch.date_to) {
        this.filterSearch.date_from = moment(this.filterSearch.date_from).format('YYYY-MM-DD');
        this.filterSearch.date_to = moment(this.filterSearch.date_to).format('YYYY-MM-DD');
    }

    this.paginationData.searchData = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;
    this.paginationData.matTabValue = this.matTabValue;
    console.log(this.paginationData);

    this.apiHit.setFilterData(this.paginationData);

    this.apiHit.PostRequest(this.paginationData, "Distributor/get_coupon_code").subscribe((result => {

        console.log(result);
        this.availableCouponData = result['coupon_code']['couponAvailableData'];
        // let data = result['coupon_code'];
        // this.totalcount = {
        //     'primaryAvailableCount': data.couponAvailableCount,
        //     'primaryScannedCount': data.couponScannedCount,
        //     'masterAvailableCount': data.available_master,
        //     'masterScannedCount': data.scanned_master_coupon,
        //     'masterDispatchScannedCount': data.dispatched_master_coupon,
        // }
        // this.total = result['coupon_code']['totalAvailablePage'];
        this.loader = false;

    }));

    this.getCouponCount();

}
//Get Available Coupon List Through API Function End


//Get Scanned List Through API Function Start
couponCodeScannedList(target) {

    this.loader = true;
    this.scannedCouponData = [];
    this.scannedCouponTotalAmount = 0;
    this.totalcount = {};
    if (target == 1) {
        this.pageNumber = 1;
    }
    if (this.filterSearch.date_from && this.filterSearch.date_to) {
        this.filterSearch.date_from = moment(this.filterSearch.date_from).format('YYYY-MM-DD');
        this.filterSearch.date_to = moment(this.filterSearch.date_to).format('YYYY-MM-DD');
    }
    this.paginationData.searchData = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;
    this.paginationData.matTabValue = this.matTabValue;
    console.log(this.paginationData);

    this.apiHit.setFilterData(this.paginationData);

    this.apiHit.PostRequest(this.paginationData, "Distributor/get_scanned_code").subscribe((result => {
        this.loader = false;
        console.log(result);
        this.scannedCouponData = result['coupon_code']['couponScannedData'];
        this.scannedCouponTotalAmount = result['total_coupon_value'];
        // let data = result['coupon_code'];
        // this.totalcount = {
        //     'primaryAvailableCount': data.couponAvailableCount,
        //     'primaryScannedCount': data.couponScannedCount,
        //     'masterAvailableCount': data.available_master_coupon,
        //     'masterScannedCount': data.scanned_master_coupon,
        //     'masterDispatchScannedCount': data.dispatched_master_coupon,
        // }
        // this.total = result['coupon_code']['totalScannedPage'];

    }));
    this.getCouponCount();

}
//Get Scanned List Through API Function End


//Get Master Coupon List Through API Function Start
getMasterCouponList(target) {

    this.loader = true;
    this.scannedCouponData = [];
    this.totalcount = {};
    if (target == 1) {
        this.pageNumber = 1;
    }
    if (this.filterSearch.dateCreated) {
        this.filterSearch.dateCreated = moment(this.filterSearch.dateCreated).format('YYYY-MM-DD');
    }
    if (this.filterSearch.date_of_packing) {
        this.filterSearch.date_of_packing = moment(this.filterSearch.date_of_packing).format('YYYY-MM-DD');
    }
    if (this.filterSearch.dispatch_date_time) {
        this.filterSearch.dispatch_date_time = moment(this.filterSearch.dispatch_date_time).format('YYYY-MM-DD');
    }
    if (this.filterSearch.date_from && this.filterSearch.date_to) {
        this.filterSearch.date_from = moment(this.filterSearch.date_from).format('YYYY-MM-DD');
        this.filterSearch.date_to = moment(this.filterSearch.date_to).format('YYYY-MM-DD');
    }
    this.paginationData.coupon_type = this.matTabValue;
    this.paginationData.searchData = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;
    this.paginationData.matTabValue = this.matTabValue;
    console.log(this.paginationData);

    this.apiHit.setFilterData(this.paginationData);

    this.apiHit.PostRequest(this.paginationData, "CouponCode/masterCouponCode").subscribe((result => {

        console.log(result);
        this.scannedCouponData = result['CouponCodes_list']['couponAvailableData'];
        // let data = result['CouponCodes_list'];
        // this.totalcount = {
        //     'primaryAvailableCount': data.couponAvailableCount,
        //     'primaryScannedCount': data.couponScannedCount,
        //     'masterAvailableCount': data.available_master_coupon,
        //     'masterScannedCount': data.scanned_master_coupon,
        //     'masterDispatchScannedCount': data.dispatched_master_coupon,
        // }
        // this.total = result['CouponCodes_list']['totalPage'];
        this.loader = false;

    }));
    this.getCouponCount();

}
//Get Master Coupon List Through API Function End

onChangePageNumber(type, target) {

    console.log(target);
    console.log(this.pageNumber);
    setTimeout(() => {

        if (type == 'previous') {
            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                if (target == 1) {
                    this.couponCodeAvailableList(2);
                }
                else if (target == 2) {
                    this.couponCodeScannedList(2);
                }
                else {
                    this.getMasterCouponList(2);
                }
            }
        }
        else if (type == 'next') {
            if (this.total > this.pageNumber) {

                this.pageNumber += 1;
                if (target == 1) {
                    this.couponCodeAvailableList(2);
                }
                else if (target == 2) {
                    this.couponCodeScannedList(2);
                }
                else {
                    this.getMasterCouponList(2);
                }
            }
        }
        else if (type = 'current') {
            if (this.pageNumber > this.total) {
                this.pageNumber = this.total;
                if (target == 1) {
                    this.couponCodeAvailableList(2);
                }
                else if (target == 2) {
                    this.couponCodeScannedList(2);
                }
                else {
                    this.getMasterCouponList(2);
                }
            }
            else if (this.pageNumber == 0) {
                this.pageNumber = 1;
                if (target == 1) {
                    this.couponCodeAvailableList(2);
                }
                else if (target == 2) {
                    this.couponCodeScannedList(2);
                }
                else {
                    this.getMasterCouponList(2);
                }
            }
            else if (this.pageNumber) {
                if (target == 1) {
                    this.couponCodeAvailableList(2);
                }
                else if (target == 2) {
                    this.couponCodeScannedList(2);
                }
                else {
                    this.getMasterCouponList(2);
                }
            }
        }

    }, 100);

}



public onDate(event): void {
    console.log('in available function');
    this.filterSearch.dateCreated = moment(event.value).format('YYYY-MM-DD');
    this.couponCodeAvailableList(1);

}
public onDate2(event): void {
    console.log('in scanned function');
    this.filterSearch.scanDate = moment(event.value).format('YYYY-MM-DD');
    this.couponCodeScannedList(1);

}
public onDate3(event): void {
    console.log('in scanned function');
    this.filterSearch.dateCreated = moment(event.value).format('YYYY-MM-DD');
    this.couponCodeScannedList(1);

}


ExcelDownloadFunction(type) {

  this.loader = true;
  this.paginationData.pagelimit = 0;
  if (type == 1) {
      this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_COUPON_DATA").subscribe((result => {

          console.log(result);
          this.couponCodeAvailableList(1);
          setTimeout(() => {

              this.loader = false;
              window.open(this.apiHit.downloadURL + "uploads/COUPON_DATA.csv");

          }, 200);

      }));
  }
  else if (type == 2) {

      this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_COUPON_SCAN_DATA").subscribe((result => {

          console.log(result);
          this.couponCodeAvailableList(1);
          setTimeout(() => {

              this.loader = false;
              window.open(this.apiHit.downloadURL + "uploads/COUPON_SCAN_DATA.csv");


          }, 200);

      }));

  }
  else {
      this.apiHit.PostRequest(this.paginationData, "CouponCode/export_master_coupon_available").subscribe((result => {

          console.log(result);
          this.getMasterCouponList(1);
          setTimeout(() => {

              this.loader = false;
              window.open(this.apiHit.downloadURL + "uploads/master_coupon_scan_data.csv");

          }, 200);

      }));
  }

}

// *** Refresh Funcation Available List start ***//

refreshData() {

  this.filterSearch = {};
  if (this.pageNumber == null) {

      this.pageNumber = 1;

  }
  this.couponCodeAvailableList(1);

}
// *** Refresh Funcation Available List End ***//


// *** Refresh Funcation Scanned List start ***//

refreshData2() {

  this.filterSearch = {};
  if (this.pageNumber == null) {

      this.pageNumber = 1;

  }
  this.couponCodeScannedList(1);

}
// *** Refresh Funcation Scanned List End ***//
refreshMasterCouponList() {
  this.filterSearch = {};
  if (this.pageNumber == null) {

      this.pageNumber = 1;

  }
  this.getMasterCouponList(1);

}

//Active Mat-Tab Function Start

tabClick(tab) {
  console.log(tab['tab']['textLabel']);
  this.matTabValue = tab['tab']['textLabel'];
  if (this.matTabValue == 1) {
      this.filterSearch = {};
      this.pageNumber = 1;
      this.couponCodeAvailableList(1);
  }
  else if (this.matTabValue == 2) {
      this.filterSearch = {};
      this.pageNumber = 1;
      this.couponCodeScannedList(1);
  }
  else {
      this.filterSearch = {};
      this.pageNumber = 1;
      this.getMasterCouponList(1);
  }
}
//Active Mat-Tab Function End


clearDateFilter() {
  this.filterSearch.date_from = '';
  this.filterSearch.date_to = '';
  if (this.matTabValue == 1) {
      this.couponCodeAvailableList(1)
  }
  else if (this.matTabValue == 2) {
      this.couponCodeScannedList(1);
  }
  else {
      this.getMasterCouponList(1);
  }
}


boxDetailModel(target, data): void {

  console.log('target', target);
  console.log('target', data);

  const dialogRef = this.dialog.open(CouponStatusModelComponent, {

      width: '550px',
      data:
      {
          target,
          data
      }

  });

  dialogRef.afterClosed().subscribe(result => {

      this.getMasterCouponList(1);

  });

}



}
