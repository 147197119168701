import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { TargetExcelUploadComponent } from '../target-excel-upload/target-excel-upload.component';

@Component({
  selector: 'app-target-list',
  templateUrl: './target-list.component.html',
})
export class TargetListComponent implements OnInit {
  targetData: any = [];
  paginationData: any = {};
  loader: any;
  total: any;
  filterSearch: any = {};
  skeleton: any = [];
  date: any = [];
  pageNumber: any;
  lastUpdatedDate: any;

  constructor(

    public dialog: MatDialog,
    private apiHit: DatabaseService,
    public dialog2: DialogService,

  ) {

    this.skeleton = new Array(10);
    this.date = new Date();
    this.pageNumber = 1;
    this.getTargetList(1);
  }

  ngOnInit(): void {
  }


  public onDate(event): void {
    this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
    this.getTargetList(1);
  }


  onChangePageNumber(type) {

    console.log(this.pageNumber);

    if (type == 'previous') {

      if (this.pageNumber > 1) {

        this.pageNumber -= 1;
        this.getTargetList(2);

      }

    }
    else if (type == 'next') {

      if (this.total > this.pageNumber) {

        this.pageNumber += 1;
        this.getTargetList(2);

      }

    }
    else if (type = 'current') {

      if (this.pageNumber > this.total) {

        console.log('current else');
        this.pageNumber = this.total;
        this.getTargetList(2);

      }
      else if (this.pageNumber == 0) {

        this.pageNumber = 1;
        this.getTargetList(2);

      }
      else if (this.pageNumber) {

        console.log('last else if');
        this.getTargetList(2);

      }

    }

  }


  //Get target List Through API Function Start

  getTargetList(target) {

    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }
    this.targetData            =   [];
    this.paginationData.search      =   this.filterSearch;
    this.paginationData.pagelimit=20;
    this.paginationData.start       =   (this.pageNumber - 1)*this.paginationData.pagelimit ;
    
    this.apiHit.PostRequest(this.paginationData, "SchemeController/target_list").subscribe((result => {

      console.log(result);
      if (result['targets']['status'] == 'success') {
        this.targetData = result['targets'];
        this.total = result['targets']['total_page'];
        this.loader = '';
      }
      else {
        this.loader = '';
        this.dialog2.error('error', 'Something Went Wrong');
      }

    }));

  }
  //Get target List Through API Function End



  // *** Delete Funcation Start ***//
  deleteItem(id) {

    this.dialog2.delete('').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest({ 'target_id': id, }, "SchemeController/delete_distributer_target").subscribe((result => {

          console.log(result);
          if (result['Target_delete']['status'] == 'success') {
            this.getTargetList(2);
          }
          else {
            this.dialog2.error('error', 'Something Went Wrong');
          }

        }));

      }

    });

  }
  // *** Delete Funcation End ***//




  //Excel Upload Function Start 

  ExcelUploadFunction(data): void {

    console.log(data);
    console.log(data.wallet_balance);

    const dialogRef = this.dialog.open(TargetExcelUploadComponent, {

      width: '400px',

      disableClose: true,

      data:
      {
        data
      }

    });

    dialogRef.afterClosed().subscribe(result => {

      dialogRef.close();
      this.getTargetList(2);

    });

  }
  // Excel Upload Function End


  ExcelDownloadFunction() {

    this.loader = 1;
    this.paginationData.pagelimit=0;
    this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_TARGET_DATA").subscribe((result => {

      console.log(result);
      this.getTargetList(1);
      setTimeout(() => {

        this.loader = '';
        if(this.apiHit.downloadURL == "https://devcrm.abacusdesk.com/askfrasle/"){
          window.open("https://devcrm.abacusdesk.com/uploads/TARGET_DATA.csv");
        }
        else{
          window.open(this.apiHit.downloadURL + "uploads/TARGET_DATA.csv");
        }


      }, 700);

    }));

  }


  //Refresh Target List Function Start

  refreshData() {

    this.filterSearch = {};
    if (this.pageNumber == null) {
      this.pageNumber = 1;
    }
    this.getTargetList(1);

  }
  //Refresh Target List Function End

}