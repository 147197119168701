<div>
    <div mat-dialog-title class="model-head">
        <h2>Upload Excel</h2>
        <a class="close-model" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>


    <form name="upload_excel" target="blank"
        method="post" enctype="multipart/form-data">
        <div class="csm-form tab-content ">
            <div class="inspace new-form-design" style="border: 0px;">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="input-block">
                            <label>Select File</label>
                            <input type="file" name="file" class="files" (change)="handleFileInput($event.target.files)"
                                required="true" accept=".csv">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col s12">
                        <div class="file-intro mb30">
                            <p>Supported file type :- &nbsp;&nbsp;&nbsp; .CSV (<a target="_blank"
                                    href="https://phpstack-414838-2222412.cloudwaysapps.com/askApi/SAMPLE_PENDING_ORDER.csv">Download
                                    sample file</a>)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-15 mt-15">
                <div class="col-sm-12 text-right">
                    <button class="cs-btn" color="accent" mat-raised-button type="submit" (click)="UploadExcel()"
                        class="btn btn-primary create-crud">Upload</button>
                </div>
            </div>
        </div>
    </form>


</div>