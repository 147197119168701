<div class="container-fluid">
  <div class="container">
    <section id="head" class="ac">
      <ng-container *ngIf="!loader">
        <div class="module-name" *ngIf="!loader">
          <h2>QR Code</h2>
          <!-- <p>{{orderListData.length}} Orders Are Available</p> -->
        </div>
        <div class="left-auto" *ngIf="secondary_codes.length > 0">
          <div class="cs-btn mt0">
            <a mat-button (click)="resetChild()">Reset</a>
          </div>
        </div>
      </ng-container>
      <div class="module-name sk-loading" *ngIf="loader">
        <h2>&nbsp;</h2>
        <!-- <p>&nbsp;</p> -->
      </div>
    </section>

    <div class="container-pd10-5 bottom-calc no-padding">

      <div class="main-btns" *ngIf="mapping == '' ">
        <button (click)="mapping = 1;setFocus()">Master QR Code Mapping</button>
        <button (click)="mapping = 2 ;setFocus()">Secondary QR Code Mapping</button>
      </div>

      <div *ngIf="mapping == 1 || mapping ==2">
        <div class="row">


          <div class="col s8">
            <div class="sprate-box pt0">
              <div class="cs-heading">
                <h2 *ngIf="mapping == 1 ">SECONDARY QR CODES</h2>
                <h2 *ngIf="mapping == 2 ">PRIMARY QR CODES</h2>
              </div>

              <div class="cs-table left-right-15 scroll500">
                <div class="table-head">
                  <table>
                    <tr>
                      <th class="mmw70 text-center">S.No</th>
                      <th class="mmw220 text-center">QR Code</th>
                      <th class="mw300 text-center" *ngIf="secondary_codes.length > 1">Part Code</th>
                      <th class="mmw120 text-center" *ngIf="secondary_codes.length > 1">Box Size</th>
                    </tr>
                  </table>
                </div>

                <div class="table-container">
                  <div class="table-content shadow-none">
                    <table>
                      <tr *ngFor="let code of secondary_codes ;let i = index">
                        <td class="mmw70 text-center">{{i+1}} </td>
                        <td class="mmw220 text-center">
                          <input type="text" #child_code{{i}} id="child_code{{i}}" name="child_code"
                            [(ngModel)]='code.child_code' (change)="checkValidation(code.child_code,i)"
                            [readonly]="code.readOnlyChk == true">
                        </td>
                        <td class="mw300 text-center" *ngIf="i == 0 && secondary_codes.length > 1">
                          <p>{{qrCode_details.part_code}}</p>
                        </td>
                        <td class="mmw120 text-center" *ngIf="i == 0 && secondary_codes.length > 1">
                          <p>{{qrCode_details.box_size}}</p>
                        </td>
                      </tr>
                    </table>


                  </div>
                </div>

              </div>
            </div>

          </div>


          <!-- <div class="col s7">
            <div class="sprate-box">
              <div class="nrml-detail">

                <div class="cs-table left-right-15 scroll500">
                  <div class="table-head">
                    <table>
                      <tr>
                        <th class="w70 text-center">S.No</th>
                        <th>QR Code</th>
                      </tr>
                    </table>
                  </div>

                  <table>
                    <tr *ngIf="mapping == 1 ">
                      <th>Master Qr Code Serial No.</th>
                      <td class="w130">
                        <input type="text" [readonly]="qrcode.readOnly == true" #master_qrcode name="master_qrcode"
                          [(ngModel)]='qrcode.master_qrcode' (change)="getValue()">
                      </td>
                    </tr>
                    <tr *ngIf="mapping == 2 ">
                      <th>Master Qr Code Serial No.</th>
                      <td class="w130">
                        <input type="text" [readonly]="qrcode.readOnly == true" #master_qrcode name="master_qrcode"
                          [(ngModel)]='qrcode.master_qrcode' (change)="getValue()">
                      </td>
                    </tr>
                    <tr *ngIf="qrCode_details != ''">
                      <th>Part Code</th>
                      <td class="w130">
                        <p>{{qrCode_details.part_code ? qrCode_details.part_code : 'N/A'}}</p>
                      </td>
                    </tr>
                    <tr *ngIf="qrCode_details != ''">
                      <th>Box Size</th>
                      <td class="w130">
                        <p>{{qrCode_details.box_size ? qrCode_details.box_size : '0'}}</p>
                      </td>
                    </tr>
                  </table>

                </div>

              </div>
            </div>

            <div class="col s5" *ngIf="secondary_codes.length">
              <div class="sprate-box pt0">
                <div class="cs-heading">
                  <h2 *ngIf="mapping == 1 ">SECONDARY QR CODES</h2>
                  <h2 *ngIf="mapping == 2 ">PRIMARY QR CODES</h2>
                </div>

                <div class="cs-table left-right-15 scroll500">
                  <div class="table-head">
                    <table>
                      <tr>
                        <th class="w70 text-center">S.No</th>
                        <th>QR Code</th>
                      </tr>
                    </table>
                  </div>

                  <div class="table-container">
                    <div class="table-content shadow-none">
                      <table>
                        <tr *ngFor="let code of secondary_codes ;let i = index">
                          <td class="w70 text-center">{{i+1}} </td>
                          <td>
                            <input type="text" #child_code{{i}} id="child_code{{i}}" name="child_code"
                              [(ngModel)]='code.child_code' (change)="checkValidation(code.child_code,i)"
                              [readonly]="code.readOnlyChk == true">
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div> -->

          <div class="row">
            <div class="col s12">
              <div class="cs-btn fixedd mt32 text-right">
                <div class="in-btn">
                  <!-- <button mat-raised-button *ngIf="mapping == 1 && child_QRcodes.length == child_limit && secondary_codes.length && secondary_codes[child_limit - 1].child.length && secondary_codes[child_limit - 1].child[part_data.secondaryPacking - 1] && secondary_codes[child_limit - 1].child[part_data.secondaryPacking - 1].secondary_child != ''" (click)="getMapped()" color="accent">Save</button>
                  <button mat-raised-button *ngIf="child_QRcodes.length == child_limit && child_QRcodes.length > 0 " (click)="getMapped()" color="accent">Save</button> 
                  <button mat-raised-button (click)="getMapped()" color="accent">Save</button> 
                  <button mat-raised-button color="accent" (click)="reopenMappedCodes()">Reopen</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>