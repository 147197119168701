<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name">
                    <h2>Retailer Detail</h2>
                    <p>{{retailerDetailData.dr_name ? retailerDetailData.dr_name:'N/A'}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <mat-tab-group class="tab-group flat-tab-body tab-group-list" (selectedTabChange)="tabClick($event)"
                mat-align-tabs="start"
                *ngIf="login_data.user_type=='System_user' || login_data.user_type=='approval_user'">


                <mat-tab label="1">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">info</mat-icon>
                        Detail
                    </ng-template>
                    <div class="row">
                        <div class="col s12 m8">
                            <div class="cs-column" *ngIf="!loader">
                                <div class="cs-head">
                                    <h2>basic Information</h2>
                                    <ng-container
                                        *ngIf="login_data.user_type=='System_user'|| login_data.login_type == 'distributor'">
                                        <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                            <a class="edit-desc"
                                                *ngIf=" datas.module_name== 'Retailer' && datas.edit== 1"
                                                mat-raised-button (click)="RetailerInfoEdit(1,retailerDetailData)"><i
                                                    class="material-icons">edit</i></a>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="login_data.user_type =='approval_user'">
                                        <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                            <a class="edit-desc"
                                                *ngIf=" datas.module_name== 'Retailer' && datas.edit== 1"
                                                mat-raised-button (click)="RetailerInfoEdit(1,retailerDetailData)"><i
                                                    class="material-icons">edit</i></a>
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="cs-form ">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Date & Created By</span>
                                                    <p>{{(retailerDetailData.date_created ?
                                                        retailerDetailData.date_created:'N/A') | date: 'd MMM y'}} -
                                                        {{retailerDetailData.created_by_name ?
                                                        retailerDetailData.created_by_name:'N/A'}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Firm Name</span>
                                                    <p>{{retailerDetailData.dr_name ? retailerDetailData.dr_name:'N/A'}}
                                                    </p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Name</span>
                                                    <p>{{retailerDetailData.contact_name ?
                                                        retailerDetailData.contact_name:'N/A'}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Mobile</span>
                                                    <p>{{retailerDetailData.mobile_no ?
                                                        retailerDetailData.mobile_no:'N/A'}}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>PAN Number</span>
                                                    <p>{{retailerDetailData.pan_no ? retailerDetailData.pan_no:'N/A'}}
                                                    </p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Email ID</span>
                                                    <p>{{retailerDetailData.email_id ?
                                                        retailerDetailData.email_id:'N/A'}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Date Of Birth</span>


                                                    <!-- <p>{{(distributorDetailData.date_birth ? (distributorDetailData.date_birth | date: 'd MMM y') :'N/A') }}</p> -->

                                                    <p>{{(retailerDetailData.date_birth ? (retailerDetailData.date_birth
                                                        | date: 'd MMM y') :'N/A') }}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Date Of Anniversary</span>
                                                    <p>{{(retailerDetailData.date_anniversary ?
                                                        (retailerDetailData.date_anniversary | date: 'd MMM y'):'N/A')
                                                        }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row grid-row-col equal">
                                        <div class="col s3">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>GST Number</span>
                                                    <p>{{retailerDetailData.gst_no ? retailerDetailData.gst_no:'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Status</span>
                                                    <p *ngIf="retailerDetailData.status=='Pending'" class="yellow-clr">
                                                        {{retailerDetailData.status ? retailerDetailData.status:'N/A'}}
                                                    </p>
                                                    <p *ngIf="retailerDetailData.status=='Verified'" class="green-clr">
                                                        {{retailerDetailData.status ? retailerDetailData.status:'N/A'}}
                                                    </p>
                                                    <p *ngIf="retailerDetailData.status=='Reject'" class="red-clr">
                                                        {{retailerDetailData.status ? retailerDetailData.status:'N/A'}}
                                                    </p>
                                                    <p *ngIf="retailerDetailData.status=='Suspect'" class="sky-clr">
                                                        {{retailerDetailData.status ? retailerDetailData.status:'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'">
                                            <!-- <ng-container *ngIf="retailerDetailData.ASM_updated_by"> -->
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Asm Name</span>
                                                    <p>{{retailerDetailData.asm_names ?
                                                        retailerDetailData.asm_names:'N/A'}}</p>
                                                </div>
                                            </div>
                                            <!-- </ng-container> -->
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user'|| login_data.login_type == 'distributor' ">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Zsm Name</span>
                                                    <p>{{retailerDetailData.zsm_names ?
                                                        retailerDetailData.zsm_names:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor' && retailerDetailData.ASM_updated_by">
                                            <!-- <ng-container *ngIf="retailerDetailData.ASM_updated_by"> -->
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Asm Approved By</span>
                                                    <p>{{retailerDetailData.ASM_updated_by ?
                                                        retailerDetailData.ASM_updated_by:'N/A'}}</p>
                                                </div>
                                            </div>
                                            <!-- </ng-container> -->
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor' && retailerDetailData.ZSM_updated_by">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Zsm Approved By</span>
                                                    <p>{{retailerDetailData.ZSM_updated_by ?
                                                        retailerDetailData.ZSM_updated_by:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user'|| login_data.login_type == 'distributor' && retailerDetailData.ASM_updated_date">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Asm Updated Date</span>
                                                    <p>{{retailerDetailData.ASM_updated_date ?
                                                        (retailerDetailData.ASM_updated_date | date : 'd MMM y, h:mm a')
                                                        :'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'&& retailerDetailData.ZSM_updated_date">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Zsm Updated Date</span>
                                                    <p>{{retailerDetailData.ZSM_updated_date ?
                                                        (retailerDetailData.ZSM_updated_date | date : 'd MMM y, h:mm a')
                                                        :'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user'|| login_data.login_type == 'distributor' && retailerDetailData.ASM_status && retailerDetailData.ASM_status!='Pending'">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Asm Status</span>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Pending'"
                                                        class="yellow-clr">{{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Verified'"
                                                        class="green-clr">{{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Reject'" class="red-clr">
                                                        {{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Suspect'" class="sky-clr">
                                                        {{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s3"
                                            *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor' && retailerDetailData.ZSM_status && retailerDetailData.ZSM_status!='Pending'">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Zsm Status</span>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Pending'"
                                                        class="yellow-clr">{{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Verified'"
                                                        class="green-clr">{{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Reject'" class="red-clr">
                                                        {{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Suspect'" class="sky-clr">
                                                        {{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s3"
                                            *ngIf="login_data.approval_type=='asm' && login_data.user_type=='approval_user'  && retailerDetailData.ASM_status">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Type</span>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Pending'"
                                                        class="yellow-clr">{{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Verified'"
                                                        class="green-clr">{{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Reject'" class="red-clr">
                                                        {{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ASM_status=='Suspect'" class="sky-clr">
                                                        {{retailerDetailData.ASM_status ?
                                                        retailerDetailData.ASM_status:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>



                                        <div class="col s3"
                                            *ngIf="login_data.approval_type=='asm' && login_data.user_type=='approval_user'   && retailerDetailData.ASM_updated_by">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Asm Approved By</span>
                                                    {{retailerDetailData.ASM_updated_by ?
                                                    retailerDetailData.ASM_updated_by : 'N/A'}}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s3"
                                            *ngIf="login_data.approval_type=='asm' && login_data.user_type=='approval_user'  && retailerDetailData.ZSM_updated_date">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Asm Updated Date</span>
                                                    {{retailerDetailData.ASM_updated_date ?
                                                    (retailerDetailData.ASM_updated_date | date : 'd MMM y, h:mm a') :
                                                    'N/A'}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s3"
                                            *ngIf="login_data.approval_type=='zsm' && login_data.user_type=='approval_user' && retailerDetailData.ZSM_status">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Type</span>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Pending'"
                                                        class="yellow-clr">{{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Verified'"
                                                        class="green-clr">{{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Reject'" class="red-clr">
                                                        {{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                    <p *ngIf="retailerDetailData.ZSM_status=='Suspect'" class="sky-clr">
                                                        {{retailerDetailData.ZSM_status ?
                                                        retailerDetailData.ZSM_status:'N/A'}}</p>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s3"
                                            *ngIf="login_data.approval_type=='zsm' && login_data.user_type=='approval_user' && retailerDetailData.ZSM_updated_by ">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Zsm Approved By</span>
                                                    {{retailerDetailData.ZSM_updated_by ?
                                                    retailerDetailData.ZSM_updated_by : 'N/A'}}
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col s3"
                                            *ngIf="login_data.approval_type=='zsm' && login_data.user_type=='approval_user' && retailerDetailData.ZSM_updated_date">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Zsm Updated Date</span>
                                                    {{retailerDetailData.ZSM_updated_date ?
                                                    (retailerDetailData.ZSM_updated_date | date : 'd MMM y, h:mm a') :
                                                    'N/A'}}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s3">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Sales Point</span>
                                                    {{retailerDetailData.total_sales_point ?
                                                    retailerDetailData.total_sales_point : '0'}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col s3">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Scanning Status</span>
                                                    {{retailerDetailData.scanning_status == '0' ? 'Active' : 'Not
                                                    Active'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt10">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Address</span>
                                                    <p>{{retailerDetailData.street? (retailerDetailData.street +
                                                        ','):''}} {{retailerDetailData.city_name?
                                                        (retailerDetailData.city_name + ','):''}}
                                                        ,{{retailerDetailData.district_name?
                                                        (retailerDetailData.district_name + ','):''}},
                                                        {{retailerDetailData.state_name? (retailerDetailData.state_name
                                                        + ','):''}} ,{{retailerDetailData.pincode}}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <ng-container
                                        *ngIf="login_data.approval_type=='zsm' || login_data.user_type=='System_user' || login_data.login_type == 'distributor' ">
                                        <div class="row row-mb0 mt10"
                                            *ngIf="retailerDetailData.ZSM_status=='Reject' || retailerDetailData.ZSM_status=='Suspect'">
                                            <div class="col s6">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>ZSM Reason</span>
                                                        <p>{{retailerDetailData.ZSM_status_reason ?
                                                            retailerDetailData.ZSM_status_reason:'N/A'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="login_data.approval_type=='asm' || login_data.user_type=='System_user' || login_data.login_type == 'distributor' ">
                                        <div class="row row-mb0 mt10"
                                            *ngIf="retailerDetailData.ASM_status=='Reject' || retailerDetailData.ASM_status=='Suspect'">
                                            <div class="col s6">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>ASM Reason</span>
                                                        <p>{{retailerDetailData.ASM_status_reason ?
                                                            retailerDetailData.ASM_status_reason:'N/A'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>


                            <!-- Skeleton Loading Data Start -->
                            <div class="cs-column" *ngIf="loader">
                                <div class="cs-head sk-loading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <div class="cs-form">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skData">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skData">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-mb0">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skData">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Skeleton Loading Data End -->


                            <div class="cs-column mt10"
                                *ngIf="!loader && login_data.user_type=='System_user' && login_data.user_type=='approval_user'">
                                <div class="cs-head">
                                    <h2>Contact person Information</h2>
                                    <ng-container *ngIf="login_data.user_type=='System_user'">

                                        <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">

                                            <a class="edit-desc" *ngIf="datas.module_name== 'Retailer' && datas.add==1 "
                                                (click)="RetailerInfoEdit(3,retailerDetailData)" mat-raised-button><i
                                                    class="material-icons">add</i></a>
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="cs-table">
                                    <!-- Table Head Start-->
                                    <figure class="table-head">
                                        <section class="th_cell">
                                            <main>
                                                <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                                <div class="div_cell mmw230">Name</div>
                                                <div class="div_cell mmw120">Mobile No.</div>
                                                <div class="div_cell mw150 af-bdr-right">Email Id </div>
                                            </main>
                                        </section>
                                    </figure>
                                    <!-- Table Head End -->

                                    <section class="td_cell">
                                        <div class="df fdc ac" *ngIf="personDetailData.length == 0">
                                            <img src="assets/img/no-contact.svg" alt="No Contact" class="h180">
                                            <p style="font-size: 16px; color: #65617c;" class="mt30">No Contact
                                                Available</p>
                                        </div>
                                        <!-- Loop Data -->
                                        <main *ngFor="let data of personDetailData; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw230">{{data.contact_person ?
                                                data.contact_person:'N/A'}}</div>
                                            <div class="div_cell mmw120">{{data.contact_1 ? data.contact_1:'N/A'}}</div>
                                            <div class="div_cell mw150">{{data.contact_email ?
                                                data.contact_email:'N/A'}}</div>

                                            <ng-container
                                                *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'">
                                                <div class="div_cell ac mmw70">
                                                    <div class="cs-action">

                                                        <span
                                                            *ngFor="let datas of apiHit.userLoginData.data.moduleData">

                                                            <a class="ac-green"
                                                                *ngIf="datas.module_name== 'Retailer' && datas.edit==1"><i
                                                                    class="material-icons"
                                                                    (click)="RetailerInfoEdit(4,data)">edit</i>
                                                            </a>

                                                            <a class="ac-red" matRipple
                                                                (click)="deleteContatctInfo(data.id)"
                                                                *ngIf="datas.module_name== 'Retailer' && datas.delete==1"
                                                                matTooltip="Delete" matTooltipPosition="below"><i
                                                                    class="material-icons">delete_sweep</i>


                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </main>
                                        <!-- Loop Data -->
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="col s12 m4">
                            <ng-container *ngIf="!loader">
                                <!--  *ngIf="(assignDistributor.length > 0 || retailerDetailData.status == 'Verified') && (login_data.user_type=='approval_user' || login_data.user_type=='System_user')" -->
                                <div class="cs-column mb10">
                                    <div class="cs-head">
                                        <h2>Sales User</h2>

                                        <!-- <ng-container *ngIf="login_data.user_type=='System_user' ">
                                            <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                <a class="edit-desc"
                                                    *ngIf="assignSalesUser.length > 0 && retailerDetailData.status == 'Verified' && datas.module_name== 'Retailer' && datas.edit== 1"
                                                    mat-raised-button (click)="SalesUserInfoEdit(5,retailerInfo)"><i
                                                        class="material-icons">add</i></a>
                                            </span>
                                        </ng-container> -->
                                        <!-- <ng-container *ngIf="login_data.user_type=='System_user' ">
                                            <a class="edit-desc"
                                                *ngIf="assignSalesUser.length == 0 && retailerDetailData.status == 'Verified'"
                                                mat-raised-button (click)="SalesUserInfoEdit(5,retailerInfo)"><i
                                                    class="material-icons">add</i></a>
                                        </ng-container> -->
                                        <!-- <ng-container *ngIf="login_data.user_type=='approval_user'">
                                            <a class="edit-desc"
                                                *ngIf="assignSalesUser.length > 0 && retailerDetailData.status == 'Verified' && (login_data.approval_type=='zsm' || login_data.approval_type=='asm' ) "
                                                mat-raised-button (click)="SalesUserInfoEdit(5,retailerInfo)"><i
                                                    class="material-icons">edit</i></a>
                                        </ng-container> -->

                                    </div>
                                    <div class="cs-table">
                                        <figure class="table-head">
                                            <section class="th_cell">
                                                <main>
                                                    <div class="div_cell af-bdr-left">Name</div>
                                                    <!-- <div class="div_cell text-center mmw60 af-bdr-right"
                                                        *ngIf="login_data.user_type=='System_user'">Action</div> -->
                                                </main>
                                            </section>
                                        </figure>



                                        <section class="td_cell">
                                            <!-- *ngFor="let data of assignSalesUser" -->
                                            <main *ngFor="let data of salesUser">
                                                <div class="div_cell ">{{data.name ? data.name: 'N/A' }}
                                                    ({{(data.mobile_no)}})
                                                </div>
                                                <!-- <div class="div_cell mmw60 ac"
                                                    *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'">
                                                    <div class="cs-action">

                                                        <span
                                                            *ngFor="let datas of apiHit.userLoginData.data.moduleData">

                                                            <a class="ac-red"
                                                                *ngIf="datas.module_name== 'Retailer' && datas.delete==1"
                                                                matRipple matTooltip="Delete" matTooltipPosition="below"
                                                                (click)="deleteAssignSalesUser(data.id)"><i
                                                                    class="material-icons">delete_sweep</i></a>
                                                        </span>
                                                    </div>
                                                </div> -->
                                            </main>

                                        </section>
                                    </div>
                                </div>
                                <div class="cs-column mb10"
                                    *ngIf="(assignDistributor.length > 0 || retailerDetailData.status == 'Verified') && (login_data.user_type=='approval_user' || login_data.user_type=='System_user')">
                                    <div class="cs-head">
                                        <h2>distributor Information</h2>

                                        <ng-container *ngIf="login_data.user_type=='System_user' ">
                                            <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                <a class="edit-desc"
                                                    *ngIf="assignDistributor.length > 0 && retailerDetailData.status == 'Verified' && datas.module_name== 'Retailer' && datas.edit== 1"
                                                    mat-raised-button (click)="RetailerInfoEdit(2,assignDistributor)"><i
                                                        class="material-icons">edit</i></a>
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="login_data.user_type=='System_user' ">
                                            <a class="edit-desc"
                                                *ngIf="assignDistributor.length == 0 && retailerDetailData.status == 'Verified'"
                                                mat-raised-button (click)="RetailerInfoEdit(2,retailerDetailData)"><i
                                                    class="material-icons">edit</i></a>
                                        </ng-container>
                                        <ng-container *ngIf="login_data.user_type=='approval_user'">
                                            <a class="edit-desc"
                                                *ngIf="assignDistributor.length > 0 && retailerDetailData.status == 'Verified' && (login_data.approval_type=='zsm' || login_data.approval_type=='asm' ) "
                                                mat-raised-button (click)="RetailerInfoEdit(2,retailerDetailData)"><i
                                                    class="material-icons">edit</i></a>
                                        </ng-container>
                                    </div>


                                    <div class="cs-table">
                                        <figure class="table-head">
                                            <section class="th_cell">
                                                <main>
                                                    <div class="div_cell mw100  af-bdr-left">Name</div>
                                                    <div class="div_cell mmw90">Mobile No.</div>
                                                    <div class="div_cell text-center mmw40 af-bdr-right"
                                                        *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'">
                                                        Action</div>
                                                </main>
                                            </section>
                                        </figure>



                                        <section class="td_cell">
                                            <main *ngFor="let data of assignDistributor">

                                                <!-- <div class="div_cell mmw120">{{data.contact_1 ? data.contact_1:'N/A'}}</div> -->

                                                <!-- {{mechanicDetails.street? (mechanicDetails.street + ','):''}}  -->


                                                <div class="div_cell mw100 ">{{data.distributor_assigned_name ?
                                                    (data.distributor_assigned_name +'-'): ''}}
                                                    {{data.distributor_assigned_dr_code ?
                                                    data.distributor_assigned_dr_code: 'N/A'}}</div>
                                                <div class="div_cell mmw90">{{data.distributor_assigned_mobile ?
                                                    data.distributor_assigned_mobile: 'N/A' }}</div>
                                                <div class="div_cell mmw40 ac"
                                                    *ngIf="login_data.user_type=='System_user' || login_data.login_type == 'distributor'">
                                                    <div class="cs-action">

                                                        <span
                                                            *ngFor="let datas of apiHit.userLoginData.data.moduleData">

                                                            <a class="ac-red"
                                                                *ngIf="datas.module_name== 'Retailer' && datas.delete==1"
                                                                matRipple matTooltip="Delete" matTooltipPosition="below"
                                                                (click)="deleteAssignDistributor(data.id)"><i
                                                                    class="material-icons">delete_sweep</i></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </main>

                                        </section>
                                    </div>
                                </div>

                                <div class="cs-column">
                                    <div class="cs-head">
                                        <h2>Wallet Information</h2>
                                    </div>

                                    <div class="cs-form">
                                        <div class="row">
                                            <div class="col s12 m12">
                                                <div class="fields fields-bg">
                                                    <div class="cs-box">
                                                        <span>Paid To Mechanic</span>

                                                        <p> {{retailerDetailData.paidToMechanic ?
                                                            retailerDetailData.paidToMechanic:'0'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>My Achievement</span>
                                                        <p>{{retailerDetailData.achievement ?
                                                            retailerDetailData.achievement:'0'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12 m12">
                                                <div class="fields fields-bg">
                                                    <div class="cs-box">
                                                        <span>Received Amount</span>
                                                        <p> {{retailerDetailData.receivedAmount ? +
                                                            retailerDetailData.receivedAmount:'0'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Balance Amount</span>
                                                        <p> {{retailerDetailData.balanceAmount ?
                                                            retailerDetailData.balanceAmount:'0'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- Sk Data Loading Start -->
                            <div class="cs-column" *ngIf="loader">
                                <div class="cs-head sk-loading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <div class="cs-form">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skDataTwo">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb0">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skDataTwo">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Sk Data Loading End -->
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="2">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">handyman</mat-icon>
                        Mechanic
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mw150">Name</div>
                                    <div class="div_cell mmw90">Mobile</div>
                                    <div class="div_cell mmw220">State & District</div>
                                    <!-- <div class="div_cell mmw100 text-center">Distributor</div> -->
                                    <div class="div_cell mmw100 text-right af-bdr-right">Wallet Balance</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="name"
                                                [(ngModel)]="searchMechanic.name" (keyup.enter)="searchMechanicList();">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="searchMechanic.mobile_no"
                                                (keyup.enter)="searchMechanicList();">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw220">
                                    </div>

                                    <!-- <div class="div_cell mmw100">
                                        </div> -->
                                    <div class="div_cell mmw100 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                                (click)="searchMechanicList()"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData(1)"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->



                        <section class="td_cell">
                            <!-- Loop Data -->
                            <ng-container *ngIf="!loader">
                                <main *ngFor="let data of mechanicData; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                    <div class="div_cell mw150"><a class="link-btn" mat-flat-button
                                            routerLink="../../mechanic-detail/{{data.mechanic_id}}">{{data.mechanicName
                                            ? data.mechanicName:'N/A'}}</a></div>
                                    <div class="div_cell mmw90">{{data.mechanicMobile ? data.mechanicMobile :'N/A'}}
                                    </div>

                                    <ng-container *ngIf="data.state_name != '' || data.district_name != '' ">
                                        <div class="div_cell mmw220">{{data.state_name ? data.state_name:'N/A'}} -
                                            {{data.district_name ? data.district_name:'N/A'}}</div>
                                    </ng-container>
                                    <ng-container *ngIf="data.state_name == '' && data.district_name == '' ">
                                        <div class="div_cell mmw220">N/A</div>
                                    </ng-container>

                                    <div class="div_cell mmw100 afe"> {{data.mechanic_wallet ?
                                        data.mechanic_wallet:'N/A'}}</div>
                                </main>
                                <app-not-found *ngIf="mechanicData == 0"></app-not-found>
                            </ng-container>
                            <!-- Loop Data -->


                            <ng-container *ngIf="loader">
                                <!-- Skelton Loop -->
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw220">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw100  ac"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw100  ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton Loop -->
                            </ng-container>
                        </section>
                    </div>
                </mat-tab>


                <mat-tab label="3">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading mw250">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Distributor To Retailer Transaction
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table">
                                <!-- Table Head Start-->
                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw110">Date</div>
                                            <div class="div_cell mw150">Distributor & Code</div>
                                            <div class="div_cell mmw180">Points Type</div>
                                            <div class="div_cell mmw180">Payment Mode</div>
                                            <div class="div_cell mmw110 text-right">Amount</div>
                                            <div class="div_cell mmw200 af-bdr-right">Transaction Id</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw110">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker.open()">
                                                        <input type="text" matInput [matDatepicker]="picker"
                                                            [max]="date" placeholder="Search ..." name="dateCreated"
                                                            [(ngModel)]="searchDistributorTransaction.dateCreated"
                                                            (dateChange)="onDate($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mw150">
                                                <div class="filter-data">
                                                    <i class="material-icons">search</i>
                                                    <input type="text" placeholder="Search ..." name="distributorName"
                                                        [(ngModel)]="searchDistributorTransaction.distributorName"
                                                        (keyup.enter)="distributorToRetailerTransaction();">
                                                </div>
                                            </div>
                                            <div class="div_cell mmw180"></div>
                                            <div class="div_cell mmw180"></div>
                                            <div class="div_cell mmw110"></div>
                                            <div class="div_cell mmw200 af-bdr-right">
                                                <div class="df ac">
                                                    <div class="filter-data">
                                                        <i class="material-icons">search</i>
                                                        <input type="text" placeholder="Search ..."
                                                            name="transaction_no"
                                                            [(ngModel)]="searchDistributorTransaction.transaction_no"
                                                            (keyup.enter)="distributorToRetailerTransaction();">
                                                    </div>

                                                    <div class="cs-action ml15">
                                                        <a class="ac-grey" matRipple matTooltip="Search"
                                                            matTooltipPosition="below"
                                                            (click)="distributorToRetailerTransaction()"><i
                                                                class="material-icons">search</i></a>
                                                        <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                            matTooltipPosition="below" (click)="refreshData(2)"><i
                                                                class="material-icons">settings_backup_restore</i></a>
                                                    </div>
                                                </div>
                                            </div>

                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->


                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of transectionDistributor; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                            <div class="div_cell mw150">{{data.distributor_name ?
                                                data.distributor_name:'N/A'}} - {{data.distributorCode ?
                                                data.distributorCode:'N/A'}}</div>
                                            <div class="div_cell mmw180">{{data.payment_type == 'Scheme' ? 'Scheme' :
                                                'Coupon'}} Points</div>
                                            <div class="div_cell mmw180">{{data.payment_mode ? data.payment_mode:'N/A'}}
                                            </div>
                                            <div class="div_cell mmw110 afe"> {{data.payment_amount ?
                                                data.payment_amount:'N/A'}}</div>
                                            <div class="div_cell mmw200"> {{data.transaction_no ?
                                                data.transaction_no:'N/A'}}</div>
                                        </main>
                                        <app-not-found *ngIf="transectionDistributor.length == 0"></app-not-found>
                                    </ng-container>


                                    <!-- Skelton Loop -->
                                    <ng-container *ngIf="loader">
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mw150">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw180">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw180">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw200">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                    </ng-container>
                                    <!-- Skelton Loop -->

                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="4">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading mw250">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Retailer To Mechanic Transaction
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table ">
                                <!-- Table Head Start-->
                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw110">Date</div>
                                            <div class="div_cell mw150">Mechanic</div>
                                            <div class="div_cell mmw180">Payment Mode</div>
                                            <div class="div_cell mmw110 text-right">Amount</div>
                                            <div class="div_cell mmw200 af-bdr-right">Transaction Id</div>
                                            <div class="div_cell mmw70"*ngIf="data_val.id=='1'">Action</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw110">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker4.open()">
                                                        <input type="text" matInput [matDatepicker]="picker4"
                                                            [max]="date" placeholder="Search ..." name="date_created"
                                                            [(ngModel)]="searchMechanicTransaction.date_created"
                                                            (dateChange)="onDate4($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker4">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker4></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mw150">
                                                <div class="filter-data">
                                                    <i class="material-icons">search</i>
                                                    <input type="text" placeholder="Search ..." name="mechanic_name"
                                                        [(ngModel)]="searchMechanicTransaction.mechanic_name"
                                                        (keyup.enter)="retailerToMechanicTransaction();">
                                                </div>
                                            </div>
                                            <div class="div_cell mmw180">
                                            </div>
                                            <div class="div_cell mmw110">
                                            </div>
                                            <div class="div_cell mmw200 af-bdr-right">
                                                <div class="df ac">
                                                    <div class="filter-data">
                                                        <i class="material-icons">search</i>
                                                        <input type="text" placeholder="Search ..."
                                                            name="transaction_no"
                                                            [(ngModel)]="searchMechanicTransaction.transaction_no"
                                                            (keyup.enter)="retailerToMechanicTransaction();">
                                                    </div>

                                                    <div class="cs-action ml15">
                                                        <a class="ac-grey" matRipple matTooltip="Search"
                                                            matTooltipPosition="below"
                                                            (click)="retailerToMechanicTransaction()"><i
                                                                class="material-icons">search</i></a>
                                                        <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                            matTooltipPosition="below"><i class="material-icons"
                                                                (click)="refreshData(3)">settings_backup_restore</i></a>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="div_cell mmw70" *ngIf="data_val.id=='1'">
                                            </div>
                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->

                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of mechanicTransection; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                            <div class="div_cell mw150">{{data.mechanic_name ?
                                                data.mechanic_name:'N/A'}} - {{data.mobile_no ? data.mobile_no:'N/A'}}
                                            </div>
                                            <div class="div_cell mmw180">{{data.payment_mode ? data.payment_mode:'N/A'}}
                                            </div>
                                            <div class="div_cell mmw110 afe">{{data.payment_amount ?
                                                data.payment_amount:'N/A'}}</div>
                                            <div class="div_cell mmw200"> {{data.transaction_no ?
                                                data.transaction_no:'N/A'}}</div>
                                            <div class="div_cell mmw70" *ngIf="data_val.id=='1'">
                                                <div class="cs-action">
                                                    <a class="ac-red" matRipple (click)="deleteEntry(data.id)"
                                                        matTooltip="Delete" matTooltipPosition="below">
                                                        <i class="material-icons">delete_sweep</i>
                                                    </a>
                                                </div>

                                            </div>
                                        </main>
                                        <app-not-found *ngIf="mechanicTransection.length == 0"></app-not-found>
                                    </ng-container>

                                    <ng-container *ngIf="loader">
                                        <!-- Skelton Loop -->
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mw150">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw180">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw200">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw70" >
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                        <!-- Skelton Loop -->
                                    </ng-container>
                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="5">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading mw250">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Transfer Detail
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table">

                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw110">Date</div>
                                            <div class="div_cell mw150">Distributor & Code</div>
                                            <div class="div_cell mmw110 text-right">Amount</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw110">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker5.open()">
                                                        <input type="text" matInput [matDatepicker]="picker5"
                                                            [max]="date" placeholder="Search ..." name="date_created"
                                                            [(ngModel)]="searchTransferDetails.date_created"
                                                            (dateChange)="onDate5($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker5">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker5></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mw150">
                                                <div class="filter-data">
                                                    <i class="material-icons">search</i>
                                                    <input type="text" placeholder="Search ..." name="distributorName"
                                                        [(ngModel)]="searchTransferDetails.distributorName"
                                                        (keyup.enter)="transferdetailsearch();">
                                                </div>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <div class="cs-action ml15">
                                                    <a class="ac-grey" matRipple matTooltip="Search"
                                                        matTooltipPosition="below" (click)="transferdetailsearch()"><i
                                                            class="material-icons">search</i></a>
                                                    <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                        matTooltipPosition="below"><i class="material-icons"
                                                            (click)="refreshData(4)">settings_backup_restore</i></a>
                                                </div>
                                            </div>
                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->


                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of transferDetails; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                            <div class="div_cell mw150" *ngFor="let row of data.distributorDetails">
                                                <a class="link-btn" mat-flat-button
                                                    routerLink="../../distributor-detail/{{row.id}}">{{row.dr_name ?
                                                    row.dr_name:'N/A'}} - {{row.dr_code ? row.dr_code:'N/A'}}
                                                </a>
                                            </div>
                                            <div class="div_cell mmw110 afe"> {{data.amount ? data.amount:'N/A'}}</div>
                                        </main>
                                        <app-not-found *ngIf="transferDetails.length == 0"></app-not-found>
                                    </ng-container>


                                    <!-- Skelton Loop -->
                                    <ng-container *ngIf="loader">
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mw150">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                    </ng-container>
                                    <!-- Skelton Loop -->

                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="6">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">qr_code_scanner</mat-icon>
                        Scan Detail
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table">
                                <!-- Table Head Start-->
                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw110">Date</div>
                                            <div class="div_cell mmw130">Coupon Code</div>
                                            <div class="div_cell mmw130 text-right">Coupon Value</div>
                                            <div class="div_cell mmw180">Scan Date & Time</div>
                                            <div class="div_cell mw150 af-bdr-right">Mechanic Detail</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw110">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker6.open()">
                                                        <input type="text" matInput [matDatepicker]="picker6"
                                                            [max]="date" placeholder="Search ..." name="date_created"
                                                            [(ngModel)]="searchScanData.date_created"
                                                            (dateChange)="onDate2($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker6">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker6></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mmw130">
                                                <div class="filter-data">
                                                    <i class="material-icons">search</i>
                                                    <input type="text" placeholder="Search ..." name="coupon_code"
                                                        [(ngModel)]="searchScanData.coupon_code"
                                                        (keyup.enter)="scanDataDetails();">
                                                </div>
                                            </div>
                                            <div class="div_cell mmw130">
                                            </div>
                                            <div class="div_cell mmw180">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker3.open()">
                                                        <input type="text" matInput [matDatepicker]="picker3"
                                                            [max]="date" placeholder="Search ..." name="scan_date"
                                                            [(ngModel)]="searchScanData.scan_date"
                                                            (dateChange)="onDate3($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker3">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker3></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mw150  af-bdr-right">
                                                <div class="df ac">
                                                    <div class="filter-data">
                                                        <i class="material-icons">search</i>
                                                        <input type="text" placeholder="Search ..." name="mechanic_name"
                                                            [(ngModel)]="searchScanData.mechanic_name"
                                                            (keyup.enter)="scanDataDetails();">
                                                    </div>

                                                    <div class="cs-action ml15">
                                                        <a class="ac-grey" matRipple matTooltip="Search"
                                                            matTooltipPosition="below"><i class="material-icons"
                                                                (click)="scanDataDetails()">search</i></a>
                                                        <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                            matTooltipPosition="below" (click)="refreshData(4)"><i
                                                                class="material-icons">settings_backup_restore</i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->



                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of scanData; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                            <div class="div_cell mmw130">{{data.coupon_code ? data.coupon_code:'N/A'}}
                                            </div>
                                            <div class="div_cell mmw130 afe">{{data.coupon_code_amount ?
                                                data.coupon_code_amount:'N/A'}}</div>
                                            <div class="div_cell mmw180">{{data.retailer_scan_date | date : 'd MMM y,
                                                h:mm a'}}</div>
                                            <div class="div_cell mw150">{{data.mechanic_name ?
                                                data.mechanic_name:'N/A'}} - {{data.mechanic_mobile ?
                                                data.mechanic_mobile:'N/A'}}</div>
                                        </main>
                                        <app-not-found *ngIf="scanData.length == 0"></app-not-found>
                                    </ng-container>

                                    <ng-container *ngIf="loader">
                                        <!-- Skelton Loop -->
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw130">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw130">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw180">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mw150">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                        <!-- Skelton Loop -->
                                    </ng-container>
                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>



                <!-- <mat-tab label="7">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Sales Point
                    </ng-template>
                    <div class="cs-table">
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Point Credit Date</div>
                                    <div class="div_cell mmw100">Segment</div>
                                    <div class="div_cell mmw150">Order Id</div>
                                    <div class="div_cell mw190">Part No</div>
                                    <div class="div_cell mmw220">Qty</div>
                                    <div class="div_cell mmw200">Distributor Name</div>
                                    <div class="div_cell mmw100 text-right af-bdr-right">Point</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker2.open()">
                                                <input type="text" matInput [matDatepicker]="picker2"
                                                    [max]="date" placeholder="Search ..." name="sales_point_credit_date"
                                                    [(ngModel)]="filterSearch.sales_point_credit_date"
                                                    (dateChange)="onDate6($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw100"></div>
                                    <div class="div_cell mmw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="order_id"
                                                [(ngModel)]="filterSearch.order_id" (keyup.enter)="getSalesHistory(2);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw190">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="product_code"
                                                [(ngModel)]="filterSearch.product_code"
                                                (keyup.enter)="getSalesHistory(2);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw220">
                                    </div>
                                    <div class="div_cell mmw200">
                                    </div>
                                    <div class="div_cell mmw100 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                                (click)="searchMechanicList()"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData(6)"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>



                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <main *ngFor="let data of salesPointHistory; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw110">{{(data.sales_point_credit_date) | date: 'd MMM y'}}</div>
                                    <div class="div_cell mmw100">{{data.segment
                                        ? data.segment:'N/A'}}</div>
                                    <div class="div_cell mmw150">#AFF-{{data.order_id
                                        ? data.order_id:'N/A'}}</div>
                                    <div class="div_cell mw190">{{data.product_code ? data.product_code :'N/A'}}
                                    </div>
                                    <div class="div_cell mmw220">{{data.qty ? data.qty:'N/A'}}</div>
                                    <div class="div_cell mmw200"> {{data.distributer_name ?
                                        data.distributer_name:'N/A'}}</div>
                                    <div class="div_cell mmw100 afe"> {{data.sales_point ?
                                        data.sales_point:'N/A'}}</div>
                                </main>
                                <app-not-found *ngIf="salesPointHistory == 0"></app-not-found>
                            </ng-container>


                            <ng-container *ngIf="loader">
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw190">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw220">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw200  ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100  ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                            </ng-container>
                        </section>
                    </div>
                </mat-tab> -->


            </mat-tab-group>
            <mat-tab-group class="tab-group flat-tab-body tab-group-list" (selectedTabChange)="tabClick($event)"
                mat-align-tabs="start" *ngIf="data_val.login_type == 'distributor'">


                <mat-tab label="1">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">info</mat-icon>
                        Detail
                    </ng-template>
                    <div class="row">
                        <div class="col s12 m8">
                            <div class="cs-column" *ngIf="!loader">
                                <div class="cs-head">
                                    <h2>basic Information</h2>
                                </div>

                                <div class="cs-form ">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Date & Created By</span>
                                                    <p>{{(retailerDetailData.date_created ?
                                                        retailerDetailData.date_created:'N/A') | date: 'd MMM y'}} -
                                                        {{retailerDetailData.created_by_name ?
                                                        retailerDetailData.created_by_name:'N/A'}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Firm Name</span>
                                                    <p>{{retailerDetailData.dr_name ? retailerDetailData.dr_name:'N/A'}}
                                                    </p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Name</span>
                                                    <p>{{retailerDetailData.contact_name ?
                                                        retailerDetailData.contact_name:'N/A'}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Mobile</span>
                                                    <p>{{retailerDetailData.mobile_no ?
                                                        retailerDetailData.mobile_no:'N/A'}}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Email ID</span>
                                                    <p>{{retailerDetailData.email_id ?
                                                        retailerDetailData.email_id:'N/A'}}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>


                            <!-- Skeleton Loading Data Start -->
                            <div class="cs-column" *ngIf="loader">
                                <div class="cs-head sk-loading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <div class="cs-form">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skData">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skData">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-mb0">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skData">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Skeleton Loading Data End -->



                        </div>

                        <div class="col s12 m4">
                            <ng-container *ngIf="!loader">


                                <div class="cs-column">
                                    <div class="cs-head">
                                        <h2>Wallet Information</h2>
                                    </div>

                                    <div class="cs-form">

                                        <div class="row row-mb0">
                                            <div class="col s12 m12">
                                                <div class="fields fields-bg">
                                                    <div class="cs-box">
                                                        <span>Balance Amount</span>
                                                        <p> {{retailerDetailData.balanceAmount ?
                                                            retailerDetailData.balanceAmount:'0'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- Sk Data Loading Start -->
                            <div class="cs-column" *ngIf="loader">
                                <div class="cs-head sk-loading">
                                    <h2>&nbsp;</h2>
                                </div>
                                <div class="cs-form">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skDataTwo">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb0">
                                        <div class="col s12">
                                            <div class="fields sk-loading">
                                                <div class="cs-box" *ngFor="let row of skDataTwo">
                                                    <span>&nbsp;</span>
                                                    <p>&nbsp;</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- Sk Data Loading End -->
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="2">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading mw250">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Point Settlement
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table">
                                <!-- Table Head Start-->
                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw200">Date</div>
                                            <div class="div_cell mmw300">Payment Mode</div>
                                            <div class="div_cell  text-right">Amount</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw200">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker.open()">
                                                        <input type="text" matInput [matDatepicker]="picker"
                                                            [max]="date" placeholder="Search ..." name="dateCreated"
                                                            [(ngModel)]="searchDistributorTransaction.dateCreated"
                                                            (dateChange)="onDate($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <div class="div_cell mmw300"></div>
                                            <div class="div_cell "></div>
                                            <!-- <div class="div_cell mmw200 af-bdr-right">
                                                <div class="df ac">
                                                    <div class="filter-data">
                                                        <i class="material-icons">search</i>
                                                        <input type="text" placeholder="Search ..."
                                                            name="transaction_no"
                                                            [(ngModel)]="searchDistributorTransaction.transaction_no"
                                                            (keyup.enter)="distributorToRetailerTransaction();">
                                                    </div>

                                              
                                                </div>
                                            </div> -->

                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->


                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of transectionDistributor; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw200">{{(data.date_created) | date: 'd MMM y'}}</div>

                                            <!-- <div class="div_cell mmw180">{{data.payment_type == 'Scheme' ? 'Scheme' : 'Coupon'}} Points</div> -->
                                            <div class="div_cell mmw300">{{data.payment_mode ? data.payment_mode:'N/A'}}
                                            </div>
                                            <div class="div_cell  afe"> {{data.payment_amount ?
                                                data.payment_amount:'N/A'}}</div>
                                        </main>
                                        <app-not-found *ngIf="transectionDistributor.length == 0"></app-not-found>
                                    </ng-container>


                                    <!-- Skelton Loop -->
                                    <ng-container *ngIf="loader">
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw200">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw300">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>

                                            <div class="div_cell ">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                    </ng-container>
                                    <!-- Skelton Loop -->

                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="3">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading mw250">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Transaction
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table">

                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw200">Date</div>
                                            <div class="div_cell text-center">Amount</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw200">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker5.open()">
                                                        <input type="text" matInput [matDatepicker]="picker5"
                                                            [max]="date" placeholder="Search ..." name="date_created"
                                                            [(ngModel)]="searchTransferDetails.date_created"
                                                            (dateChange)="onDate5($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker5">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker5></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell">
                                                <div class="cs-action ml15">
                                                    <a class="ac-grey" matRipple matTooltip="Search"
                                                        matTooltipPosition="below" (click)="transferdetailsearch()"><i
                                                            class="material-icons">search</i></a>
                                                    <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                        matTooltipPosition="below"><i class="material-icons"
                                                            (click)="refreshData(4)">settings_backup_restore</i></a>
                                                </div>
                                            </div>
                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->


                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of transferDetails; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw200">{{(data.date_created) | date: 'd MMM y'}}</div>

                                            <div class="div_cell  afe"> {{data.amount ? data.amount:'N/A'}}</div>
                                        </main>
                                        <app-not-found *ngIf="transferDetails.length == 0"></app-not-found>
                                    </ng-container>


                                    <!-- Skelton Loop -->
                                    <ng-container *ngIf="loader">
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw200">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                    </ng-container>
                                    <!-- Skelton Loop -->

                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>


                <mat-tab label="4">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">qr_code_scanner</mat-icon>
                        Order
                    </ng-template>
                    <div class="row">
                        <div class="col s12">
                            <div class="cs-table">
                                <!-- Table Head Start-->
                                <figure class="table-head">
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                            <div class="div_cell mmw110">Date</div>
                                            <div class="div_cell mmw130">Coupon Code</div>
                                            <div class="div_cell mmw130 text-right">Coupon Value</div>
                                            <div class="div_cell mmw180">Scan Date & Time</div>
                                            <div class="div_cell mw150 af-bdr-right">Mechanic Detail</div>
                                        </main>
                                    </section>
                                    <section class="th_cell">
                                        <main>
                                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                            <div class="div_cell mmw110">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker6.open()">
                                                        <input type="text" matInput [matDatepicker]="picker6"
                                                            [max]="date" placeholder="Search ..." name="date_created"
                                                            [(ngModel)]="searchScanData.date_created"
                                                            (dateChange)="onDate2($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker6">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker6></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mmw130">
                                                <div class="filter-data">
                                                    <i class="material-icons">search</i>
                                                    <input type="text" placeholder="Search ..." name="coupon_code"
                                                        [(ngModel)]="searchScanData.coupon_code"
                                                        (keyup.enter)="scanDataDetails();">
                                                </div>
                                            </div>
                                            <div class="div_cell mmw130">
                                            </div>
                                            <div class="div_cell mmw180">
                                                <div class="filter-data">
                                                    <mat-form-field (click)="picker3.open()">
                                                        <input type="text" matInput [matDatepicker]="picker3"
                                                            [max]="date" placeholder="Search ..." name="scan_date"
                                                            [(ngModel)]="searchScanData.scan_date"
                                                            (dateChange)="onDate3($event)" readonly>
                                                        <mat-datepicker-toggle matSuffix [for]="picker3">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #picker3></mat-datepicker>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="div_cell mw150  af-bdr-right">
                                                <div class="df ac">
                                                    <div class="filter-data">
                                                        <i class="material-icons">search</i>
                                                        <input type="text" placeholder="Search ..." name="mechanic_name"
                                                            [(ngModel)]="searchScanData.mechanic_name"
                                                            (keyup.enter)="scanDataDetails();">
                                                    </div>

                                                    <div class="cs-action ml15">
                                                        <a class="ac-grey" matRipple matTooltip="Search"
                                                            matTooltipPosition="below"><i class="material-icons"
                                                                (click)="scanDataDetails()">search</i></a>
                                                        <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                            matTooltipPosition="below" (click)="refreshData(4)"><i
                                                                class="material-icons">settings_backup_restore</i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </main>
                                    </section>
                                </figure>
                                <!-- Table Head End -->



                                <section class="td_cell">
                                    <ng-container *ngIf="!loader">
                                        <main *ngFor="let data of scanData; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                            <div class="div_cell mmw130">{{data.coupon_code ? data.coupon_code:'N/A'}}
                                            </div>
                                            <div class="div_cell mmw130 afe">{{data.coupon_code_amount ?
                                                data.coupon_code_amount:'N/A'}}</div>
                                            <div class="div_cell mmw180">{{data.retailer_scan_date | date : 'd MMM y,
                                                h:mm a'}}</div>
                                            <div class="div_cell mw150">{{data.mechanic_name ?
                                                data.mechanic_name:'N/A'}} - {{data.mechanic_mobile ?
                                                data.mechanic_mobile:'N/A'}}</div>
                                        </main>
                                        <app-not-found *ngIf="scanData.length == 0"></app-not-found>
                                    </ng-container>

                                    <ng-container *ngIf="loader">
                                        <!-- Skelton Loop -->
                                        <main *ngFor="let data of skeleton; let i=index">
                                            <div class="div_cell mmw50 ac">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw110">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw130">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw130">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mmw180">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                            <div class="div_cell mw150">
                                                <p class="skeleton wp100">&nbsp;</p>
                                            </div>
                                        </main>
                                        <!-- Skelton Loop -->
                                    </ng-container>
                                </section>
                            </div>
                        </div>
                    </div>
                </mat-tab>



                <!-- <mat-tab label="7">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Sales Point
                    </ng-template>
                    <div class="cs-table">
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Point Credit Date</div>
                                    <div class="div_cell mmw100">Segment</div>
                                    <div class="div_cell mmw150">Order Id</div>
                                    <div class="div_cell mw190">Part No</div>
                                    <div class="div_cell mmw220">Qty</div>
                                    <div class="div_cell mmw200">Distributor Name</div>
                                    <div class="div_cell mmw100 text-right af-bdr-right">Point</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker2.open()">
                                                <input type="text" matInput [matDatepicker]="picker2"
                                                    [max]="date" placeholder="Search ..." name="sales_point_credit_date"
                                                    [(ngModel)]="filterSearch.sales_point_credit_date"
                                                    (dateChange)="onDate6($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw100"></div>
                                    <div class="div_cell mmw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="order_id"
                                                [(ngModel)]="filterSearch.order_id" (keyup.enter)="getSalesHistory(2);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw190">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="product_code"
                                                [(ngModel)]="filterSearch.product_code"
                                                (keyup.enter)="getSalesHistory(2);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw220">
                                    </div>
                                    <div class="div_cell mmw200">
                                    </div>
                                    <div class="div_cell mmw100 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                                (click)="searchMechanicList()"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData(6)"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>



                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <main *ngFor="let data of salesPointHistory; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw110">{{(data.sales_point_credit_date) | date: 'd MMM y'}}</div>
                                    <div class="div_cell mmw100">{{data.segment
                                        ? data.segment:'N/A'}}</div>
                                    <div class="div_cell mmw150">#AFF-{{data.order_id
                                        ? data.order_id:'N/A'}}</div>
                                    <div class="div_cell mw190">{{data.product_code ? data.product_code :'N/A'}}
                                    </div>
                                    <div class="div_cell mmw220">{{data.qty ? data.qty:'N/A'}}</div>
                                    <div class="div_cell mmw200"> {{data.distributer_name ?
                                        data.distributer_name:'N/A'}}</div>
                                    <div class="div_cell mmw100 afe"> {{data.sales_point ?
                                        data.sales_point:'N/A'}}</div>
                                </main>
                                <app-not-found *ngIf="salesPointHistory == 0"></app-not-found>
                            </ng-container>


                            <ng-container *ngIf="loader">
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw190">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw220">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw200  ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100  ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                            </ng-container>
                        </section>
                    </div>
                </mat-tab> -->


            </mat-tab-group>

        </div>
    </div>
</div>