<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac" style="height:100px;">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>Secondary Orders</h2>
                    <p>{{orderListData.length}} Orders Are Available</p>
                </div>

               

                    <!-- header table start -->
                    <div class="cs-table" style="position: fixed; left: 27%;overflow-y: auto;height: 96px;">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="td_cell" style="background:transparent; color:white;padding:0;" >
                                <main>
                                    <div class="div_cell mmw100 text-center" style="background:transparent"></div>
    
                                    <div class="div_cell mmw100 text-center" style="background:transparent;align-items: center;">{{last_3_month_data[0].month}}</div>
                                    <div class="div_cell mmw100 text-center"  style="background:transparent;align-items: center;">{{last_3_month_data[1].month}}</div>
                                    <div class="div_cell mmw100 text-center"  style="background:transparent;align-items: center;">{{last_3_month_data[2].month}}</div>
                                </main>
                            </section>
                        </figure>
                        <!-- table head end -->
    
                        <section class="td_cell" style="color:white; padding:0;">
                            <ng-container *ngIf="!loader">
                                <main>
                                    <div class="div_cell mmw100" style="align-items: center;">
                                        Order Qty
                                    </div>
                                    <div class="div_cell mmw100 text-center af-bdr-left" style="align-items: center;">
                                        {{last_3_month_data[0].total_qty}}
                                    </div>
                                    <div class="div_cell mmw100 text-center af-bdr-left" style="align-items: center;">
                                        {{last_3_month_data[1].total_qty}}
    
                                    </div>
                                    <div class="div_cell mmw100 text-center af-bdr-left" style="align-items: center;">
                                        {{last_3_month_data[2].total_qty}}
    
                                    </div>
                                </main>
                            </ng-container>
                        </section>
                        <section class="td_cell"  style="color:white;padding:0;">
                                <ng-container *ngIf="!loader">
                                    <main>
                                        <div class="div_cell mmw100" style="align-items: center;">
                                            Order Value
                                        </div>
                                        <div class="div_cell mmw100" style="align-items: center;">
                                            {{last_3_month_data[0].total_amt | number:'1.2-2' }}
    
                                        </div>
                                        <div class="div_cell mmw100" style="align-items: center;">
                                            {{last_3_month_data[1].total_amt | number:'1.2-2'}}
    
                                        </div>
                                        <div class="div_cell mmw100" style="align-items: center;">
                                            {{last_3_month_data[2].total_amt | number:'1.2-2'}}
    
                                        </div>
                                    </main>
                                </ng-container>
                            
                        </section>
    
                    </div>
    
                    <!-- header table end -->
                <!-- <div class="left-auto" *ngIf="apiHit.userLoginData.data.moduleData[5]['view'] == 1 && apiHit.userLoginData.data.user_type=='approval_user'">
                    <div class="cs-btn mt0">
                        <a mat-button
                            routerLink="../secondaryOrder-add">Add new</a>
                    </div>
                </div> -->
                <div class="left-auto">
                    <ul class="date-filter" >
                        <li>
                            <div class="cs-form pb0">
                                <p>Date From</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                    <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                        placeholder="Search ..." name="date_from" [(ngModel)]="orderSearch.date_from"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li>
                            <div class="cs-form pb0" >
                                <p>Date To</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                    <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                        placeholder="Search ..." name="date_to" [(ngModel)]="orderSearch.date_to"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <div style="flex-direction: column;align-items: center;justify-content: center;padding-bottom: 10px;">
                        <li class="cs-btn mt15 mr10" *ngIf="this.orderSearch.date_from && this.orderSearch.date_to">
                            <a mat-button (click)="orderList(1,status)">Go</a>
                        </li>
                        <li class="cs-btn mt15" *ngIf="this.orderSearch.date_from || this.orderSearch.date_to">
                            <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                        </li>
                        <li class="cs-btn mt15 ml10"
                            *ngIf="data_val.login_type == 'distributor' || apiHit.userLoginData.data.user_type=='approval_user'  && apiHit.userLoginData.data.moduleData && apiHit.userLoginData.data.moduleData[5]['view'] == 1 ">
                            <a mat-button routerLink="../secondaryOrder-add">Add new</a>
                        </li>
                    </div>
                    </ul>
                </div>

            </ng-container>
            <div class="cs-action ml10">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="orderList(1,status)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 primaryheight-calc no-padding">


            <mat-tab-group class="tab-group flat-tab-body tab-group-list" [selectedIndex]="matTabValue" (selectedTabChange)="tabClick($event)"
                mat-align-tabs="start">

                <mat-tab label="1">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">inbox</mat-icon>
                        All
                        <span class="badge">{{count.all}}</span>
                    </ng-template>
                    
                </mat-tab>

                <mat-tab label="2">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">history</mat-icon>
                        Pending
                        <span class="badge">{{count.pending}}</span>
                    </ng-template>
                   
                </mat-tab>

                <mat-tab label="3">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">verified</mat-icon>
                        Dispatch
                        <span class="badge">{{count.verified}}</span>
                    </ng-template>
                 
                </mat-tab>

                <mat-tab label="3">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">cancel_schedule_send</mat-icon>
                        Reject
                        <span class="badge">{{count.reject}}</span>
                    </ng-template>
                  
                </mat-tab>

            </mat-tab-group>


            <div class="cs-table">
                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw150">Date Created</div>
                            <div class="div_cell mmw110">Created By</div>
                            <div class="div_cell mmw90">Order Id</div>
                            <div class="div_cell mmw180">Retailer Name</div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">Distributor Name</div>
                            <div class="div_cell mmw120">Retailer State</div>
                            <div class="div_cell mmw120">Retailer District</div>
                            <div class="div_cell mmw90">No Of Item</div>
                            <div class="div_cell mmw90">Total Qty</div>
                            <div class="div_cell mw120">Remark</div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">Status</div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">Reject Reason</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw150">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="created_date"
                                            [(ngModel)]="orderSearch.created_date" (dateChange)="onDate($event)"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw110"></div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="order_id"
                                        [(ngModel)]="orderSearch.order_id" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            <div class="div_cell mmw180">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_by_name"
                                        [(ngModel)]="orderSearch.created_by_name" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="assigned_distributor_name"
                                        [(ngModel)]="orderSearch.assigned_distributor_name" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <select name="user_state" [(ngModel)]="orderSearch.user_state" (change)="orderList(1,status)"
                                        id="user_state">
                                        <option value="">All</option>
                                        <option *ngFor="let data of stateData; let i=index"  value="{{data}}">{{data}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="user_district"
                                        [(ngModel)]="orderSearch.user_district" (keyup.enter)="orderList(1,status);">
                                </div>
                            </div>

                            <div class="div_cell mmw90"></div>
                            <div class="div_cell mmw90" *ngIf="loader">&nbsp;</div>
                            <div class="div_cell mmw90" *ngIf="!loader">{{count.totalOrderQty ? count.totalOrderQty : 0}}</div>

                            <div class="div_cell mw120">&nbsp;</div>

                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="cs-action" *ngIf="matTabValue != 3">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="orderList(1,status)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>
                        <div *ngIf="matTabValue == 3" class="div_cell mmw150">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="orderList(1,status)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>

                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of orderListData; let i = index;"
                            [ngClass]="{'alert-row':data.order_status == 1 && data.total_hours >= 48}">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>                            
                            <div class="div_cell mmw150">{{data.order_created_date | date: 'd MMM y, h:mm a'}}</div>
                            <div class="div_cell mmw110">{{data.order_created_by ? data.order_created_by : 'Self'}}</div>
                            <div class="div_cell mmw90">
                                <a class="link-btn" mat-flat-button
                                    routerLink="../secondaryorder-detail/{{data.order_id}}">#AFF-{{data.order_id}}</a>
                            </div>
                            <div class="div_cell mmw180" >{{data.created_by_name}}</div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">
                                {{data.assigned_distributor_name ? data.assigned_distributor_name : 'N/A'}}
                            </div>
                            <div class="div_cell mmw120">{{data.user_state ? data.user_state : 'N/A'}}</div>
                            <div class="div_cell mmw120">{{data.user_district ? data.user_district : 'N/A'}}</div>
                            <div class="div_cell mmw90">{{data.item_count}}</div>
                            <div class="div_cell mmw90">{{data.total_qty}}</div>
                            <div class="div_cell mw120">{{data.order_remarks}}</div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'">
                                <span *ngIf="data.order_status == 1" class="yellow-clr">Pending</span>
                                <span *ngIf="data.order_status == 2" class="green-clr">Dispatch</span>
                                <span *ngIf="data.order_status == 3" class="red-clr">Reject</span>
                            </div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">{{data.reason_reject ?
                                data.reason_reject : 'N/A'}}
                            </div>
                        </main>
                        <app-not-found *ngIf="orderListData.length == 0"></app-not-found>
                    </ng-container>



                    <!-- Skeleton Loading Start -->

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw150">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div> 
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw180" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90" *ngIf="data_val?.login_type != 'distributor'"> 
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div *ngIf="matTabValue == 3" class="div_cell mmw150">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>

                    <!-- Skeleton Loading End -->

                </section>
            </div>
          


          




            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac" *ngIf="orderListData.length > 0">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a>
                            </li>
                        </ul>
                    </div>

                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="orderListData.length > 0"
                            (click)="exportExcelFunction()" class="ml10"><img src="assets/img/excel.png"> Download
                            Excel</a>
                    </div>

                </div>
            </div>
            <!-- Pagination End -->
        </div>

    </div>
</div>