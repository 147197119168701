<div  class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name" *ngIf="distrubutorInfo.dr_name">
                    <h2>Order Detail</h2>
                    <p>{{distrubutorInfo.dr_name}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <ng-template mat-tab-label *ngIf="loader">
                <div class="sk-loading">&nbsp;</div>
            </ng-template>
            <ng-template mat-tab-label *ngIf="!loader">
                <mat-icon class="example-tab-icon">info</mat-icon>
                Detail
            </ng-template>
            <div class="row">
                <div class="col s12 m8">
                    <div class="cs-column" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Distributor Information</h2>
                        </div>

                        <div class="cs-form ">
                            <div class="row">
                                <div class="col s12" >
                                    <div class="fields">
                                        <div class="cs-box" *ngIf="distrubutorInfo.dr_name">
                                            <span>Company Name</span>
                                            <p>{{distrubutorInfo.dr_name}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>DR Code</span>
                                            <p>{{distrubutorInfo.dr_code ?
                                                distrubutorInfo.dr_code:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Contact Person Name</span>
                                            <p>{{distrubutorInfo.contact_name ? distrubutorInfo.contact_name:'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Mobile No</span>
                                            <p>{{distrubutorInfo.mobile_no ?
                                                distrubutorInfo.mobile_no:'N/A'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Email</span>
                                            <p>{{distrubutorInfo.email_id ?
                                                distrubutorInfo.email_id:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box" *ngIf="data_val?.login_type != 'distributor'">
                                            <span>Credit Limit</span>
                                            <p>₹ {{distrubutorInfo.credit_limit ?
                                                distrubutorInfo.credit_limit:'0'}}</p>
                                        </div>
                                        <div class="cs-box" *ngIf="data_val?.login_type != 'distributor'">
                                            <span>Outstanding</span>
                                            <p>₹ {{distrubutorInfo.outstanding ?
                                                distrubutorInfo.outstanding:'0'}}</p>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="row">
                                <div class="col s6">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Address</span>
                                            <p>{{distrubutorInfo.street? (distrubutorInfo.street +','):''}}
                                                {{distrubutorInfo.city_name? (distrubutorInfo.city_name +
                                                ','):''}}
                                                {{distrubutorInfo.district_name? (distrubutorInfo.district_name
                                                + ','):''}}
                                                {{distrubutorInfo.state_name? (distrubutorInfo.state_name):''}},
                                                {{distrubutorInfo.pincode}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col s6" *ngIf="orderDetail.shipping_address">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Shipping Address</span>
                                            <p>{{orderDetail.shipping_address?orderDetail.shipping_address :'N/A'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loading Data Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loading Data End -->


                    <div class="cs-column mt120"  style="width: 150%;" *ngIf="!loader"> 
                        <div class="cs-head">
                            <h2>Item Information</h2>
                        </div>

                        <div class="cs-table">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mmw70">Segment</div>
                                        <div class="div_cell mmw100">Brand</div>
                                        <div class="div_cell mmw140">Material Code</div>
                                        <div class="div_cell mw160">Part Code</div>
                                        <div class="div_cell mmw90 af-bdr-right">Qty</div>
                                        <div class="div_cell mmw90 af-bdr-right">Dispatch Qty</div>
                                        <div class="div_cell mmw90 af-bdr-right">Pending Qty</div>
                                        

                                        <div class="div_cell mmw100 " *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">List Price</div>
                                        <div class="div_cell mmw120 " *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">Discounted Amount</div>
                                        <div class="div_cell mmw100 " *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">Value</div>
                                        <div class="div_cell mmw100 af-bdr-right" *ngIf="data_val?.login_type != 'distributor'">Company Stock</div>
                                        <ng-container
                                        *ngIf="data_val?.login_type != 'distributor' && (apiHit.userLoginData.data?.moduleData[4]['delete'] == 1 || apiHit.userLoginData.data?.moduleData[4]['edit'] == 1 )">
                                        <div *ngIf="orderDetail.order_status == 1"
                                            class="div_cell ac mmw70">Action</div>
                                            </ng-container>
                                    </main>
                                </section>
                            </figure>
                            <!-- Table Head End -->

                            <section class="td_cell">
                                <main *ngFor="let data of orderList; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw70">{{data.segment ?
                                        data.segment:'N/A'}}</div>
                                    <div class="div_cell mmw100">{{data.category ?
                                        (data.category | titlecase ):'N/A'}}</div>
                                    <div class="div_cell mmw140">{{data.material_code ?
                                        (data.material_code | uppercase ):'N/A'}}</div>
                                    <div class="div_cell mw160">{{data.product_code ? data.product_code:'N/A'}}</div>
                                    <div class="div_cell mmw90">{{data.qty ?
                                        data.qty:'0'}}</div>
                                        <div class="div_cell mmw90">{{data.dispatch_qty_from_inv ?
                                            data.dispatch_qty_from_inv:'0'}}</div> 
                                             <div class="div_cell mmw90">{{data.pending_qty_from_inv ?
                                                data.pending_qty_from_inv:'0'}}</div>
                                    <div class="div_cell mmw100" *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">{{data.list_price ?
                                        data.list_price:'0'}}</div>
                                    <div class="div_cell mmw120" *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">{{data.discounted_amount ?
                                       (data.discounted_amount | number : '1.2-2'):'0'}}</div>
                                    <div class="div_cell mmw100" *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">{{data.total_amount ?
                                       ( data.total_amount | number : '1.2-2'):'0'}}</div>
                                    <div class="div_cell mmw100" *ngIf="data_val?.login_type != 'distributor'">{{data.total_Company_Stock ?
                                        data.total_Company_Stock:'0'}}</div>
                                    <ng-container
                                        *ngIf=" data_val?.login_type != 'distributor' && (apiHit.userLoginData.data?.moduleData[4]['delete'] == 1 || apiHit.userLoginData.data?.moduleData[4]['edit'] == 1 )">
                                        <div *ngIf="orderDetail.order_status == 1" class="div_cell ac mmw70">
                                            <div class="cs-action">
                                                <div
                                                    *ngIf="  orderDetail.order_status == 1 && (data_val?.login_type != 'distributor' && apiHit.userLoginData.data?.moduleData[4]['edit'] == 1 ) ">
                                                    <a class="ac-green" matRipple (click)="OrderStatusChange(2,data)"
                                                        matTooltip="Edit" matTooltipPosition="below">
                                                        <i class="material-icons">edit</i>
                                                    </a>
                                                </div>
                                                <div
                                                    *ngIf=" orderList.length > 1 && orderDetail.order_status == 1 && (data_val?.login_type != 'distributor' && apiHit.userLoginData.data?.moduleData[4]['delete'] == 1)">
                                                    <a class="ac-red" matRipple (click)="deleteOrder(data)"
                                                        matTooltip="Delete" matTooltipPosition="below">
                                                        <i class="material-icons">delete_sweep</i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </main>
                                <!-- Loop Data -->
                            </section>
                        </div>
                    </div>
                </div>



                <div class="col s12 m4">
                    <ng-container *ngIf="!loader">

                        <div class="cs-column mb10">
                            <div class="cs-head">
                                <h2>Order Information</h2>
                            </div>

                            <div class="cs-form ">
                                <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div class="cs-box">
                                                <span>Date</span>
                                                <p>{{orderDetail.created_date ?
                                                    (orderDetail.created_date | date: 'd MMM y, h:mm a' ):'N/A'}}
                                            </div>
                                            <div class="cs-box" *ngIf="orderDetail.order_status != 1">
                                                <span>Status Updated Date</span>
                                                <p>{{orderList[0].last_updated ?
                                                    (orderList[0].last_updated | date: 'd MMM y, h:mm a' ):'N/A'}}
                                            </div>
                                            <div class="cs-box">
                                                <span>Status</span>
                                                <ng-container>
                                                    <div *ngIf="orderDetail.order_status == 1 || orderDetail.order_status == 4">
                                                        <span
                                                            *ngIf="data_val?.login_type != 'distributor' && apiHit.userLoginData.data?.moduleData[4]['edit'] == 1">
                                                            <a class="edit-desc" mat-raised-button
                                                                (click)="OrderStatusChange(1,orderDetail)"><i
                                                                    class="material-icons">edit</i></a>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <strong *ngIf="orderDetail.order_status == 1"
                                                    class="yellow-clr">Pending</strong>
                                                <strong *ngIf="orderDetail.order_status == 2"
                                                    class="green-clr">Approved</strong>
                                                <strong *ngIf="orderDetail.order_status == 3"
                                                    class="red-clr">Reject</strong>
                                                <strong *ngIf="orderDetail.order_status == 4"
                                                    class="yellow-clr">Hold</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s6">
                                        <div class="fields">
                                            <div *ngIf="orderDetail.order_status == 3 || orderDetail.order_status == 4" class="cs-box">
                                                <span>{{orderDetail.order_status == 3 ? 'Reject ' : 'Hold '}} Reason</span>
                                                <p>{{orderDetail.reject_reason ? orderDetail.reject_reason : 'N/A'}}
                                                </p>
                                            </div>
                                            <div *ngIf="orderDetail.order_status != 3" class="cs-box">
                                                <span>SAP Status </span>
                                                <ng-container>
                                                    <div
                                                        *ngIf="orderDetail.order_status == 2 && (orderDetail.sap_status == 'Pending'|| orderDetail.sap_status == 'Hold')">
                                                        <span
                                                            *ngIf="data_val?.login_type != 'distributor' ||apiHit.userLoginData.data?.moduleData && apiHit.userLoginData.data?.moduleData[4]['edit'] == 1 ">
                                                            <a class="edit-desc" mat-raised-button
                                                                (click)="OrderStatusChange(3,orderDetail)"><i
                                                                    class="material-icons">edit</i></a>
                                                        </span>
                                                    </div>
                                                </ng-container>
                                                <strong *ngIf="orderDetail.sap_status == 'Pending'"
                                                    class="yellow-clr">{{orderDetail.sap_status}}</strong>
                                                <strong *ngIf="orderDetail.sap_status == 'Submit'"
                                                    class="green-clr">{{orderDetail.sap_status}}</strong>
                                                <strong *ngIf="orderDetail.sap_status == 'Hold'"
                                                    class="yellow-clr">{{orderDetail.sap_status}}</strong>
                                                <strong *ngIf="orderDetail.sap_status == 'Reject'"
                                                    class="red-clr">{{orderDetail.sap_status}}</strong>
                                            </div>
                                            <div *ngIf="(orderDetail.sap_status == 'Hold'|| orderDetail.sap_status == 'Reject') && orderDetail.order_status != 3" class="cs-box">
                                                <span>SAP {{orderDetail.sap_status == 'Hold' ? 'Hold' : 'Reject'}} Reason</span>
                                                <p>{{orderDetail.sap_status_reason ? orderDetail.sap_status_reason : 'N/A'}}
                                                </p>
                                            </div>
                                            <div class="cs-box">
                                                <span>Order Id</span>
                                                <p>#AFF-{{orderDetail.order_id ? orderDetail.order_id : 'N/A'}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col s6">
                                        <div class="fields">
                                            <div class="cs-box">
                                                   <span>Total Dispatch Qty</span>
                                                   <p>{{orderDetail.dispatchQtyFromInvoice ?
                                                       orderDetail.dispatchQtyFromInvoice:'0'}}</p>
                                               </div>
                                               <div class="cs-box">
                                                   <span>Total Pending Qty</span>
                                                   <p>{{orderDetail.pendingQtyFromInvoice ?
                                                       orderDetail.pendingQtyFromInvoice:'0'}}</p>
                                               </div>
                                           </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div class="cs-box">
                                                <span>No Of Items</span>
                                                <p>{{orderDetail.total_items ? orderDetail.total_items : '0'}}
                                                </p>
                                            </div>
                                            <div class="cs-box">
                                                <span>Total Qty</span>
                                                <p>{{orderDetail.total_quantity ?
                                                    orderDetail.total_quantity:'0'}}</p>
                                            </div>
                                          
                                            <div class="cs-box" *ngIf="data_val.user_type!='approval_user' && data_val?.login_type != 'distributor'">
                                                <span>Order Value</span>
                                                <p>{{orderDetail.total_order_amount ? orderDetail.total_order_amount : '0'}}
                                                </p>
                                            </div>
                                            <div class="cs-box" *ngIf="orderDetail.list_discount != 0">
                                                <span>List Discount</span>
                                                <p>{{orderDetail.list_discount ? orderDetail.list_discount : '0'}}
                                                </p>
                                            </div>
                                        </div>
                                       
                                    </div>
                              
                                </div>
                               
                                <!-- <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                           
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div class="cs-box" *ngIf="orderDetail.invoice_discount != 0">
                                                <span>Invoice Discount</span>
                                                <p>{{orderDetail.invoice_discount ?
                                                    orderDetail.invoice_discount:'0'}}</p>
                                            </div>
                                            <div class="cs-box" *ngIf="orderDetail.transport">
                                                <span>Transporter</span>
                                                <p>{{orderDetail.transport ?
                                                    orderDetail.transport:'N/A'}}</p>
                                            </div>
                                            <div class="cs-box" *ngIf="orderDetail.sap_order_1">
                                                <span>SAP Order No 1</span>
                                                <p>{{orderDetail.sap_order_1 ?
                                                    orderDetail.sap_order_1:'N/A'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="orderDetail.sap_order_2 || orderDetail.sap_order_3">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div class="cs-box" *ngIf="orderDetail.sap_order_2">
                                                <span>SAP Order No 2</span>
                                                <p>{{orderDetail.sap_order_2 ? orderDetail.sap_order_2 : 'N/A'}}
                                                </p>
                                            </div>
                                            <div class="cs-box" *ngIf="orderDetail.sap_order_3">
                                                <span>SAP Order No 3</span>
                                                <p>{{orderDetail.sap_order_3 ?
                                                    orderDetail.sap_order_3:'N/A'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="orderDetail.sap_order_1 && data_val?.login_type != 'distributor'">
                                    <div class="col s12 m12">
                                        <div class="cs-btn">
                                            <button  mat-button type="submit" (click)="downloadAsPDF()">Export To PDF</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="cs-column" *ngIf="invoiceData.length > 0 && !loader">

                            <div class="cs-column mb10">
                                <div class="cs-head">
                                    <h2>Invoice Information</h2>
                                </div>

                                <div class="cs-table">

                                    <figure class="table-head">
                                        <section class="th_cell">
                                            <main>
                                                <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                                <div class="div_cell mmw110">Date</div>
                                                <div class="div_cell mw170">Invoice No</div>
                                            </main>
                                        </section>
                                    </figure>
                                    <!-- Table Head End -->

                                    <section class="td_cell">
                                        <main *ngFor="let data of invoiceData; let i=index">
                                            <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                            <div class="div_cell mmw110">{{data.Bill_Date ? (data.Bill_Date | date: 'd MMM y') : 'N/A'}}</div>
                                            <div class="div_cell mw170"><a class="link-btn" mat-flat-button
                                                routerLink="../../billing-Detail/{{data.ODN_Number}}">
                                                {{data.ODN_Number ? data.ODN_Number:'N/A'}}
                                            </a>
                                            </div>
                                        </main>
                                        <!-- Loop Data -->
                                    </section>

                                </div>
                            </div>
                        </div>


                    </ng-container>
                    <!-- Sk Data Loading Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skDataTwo">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skDataTwo">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- Sk Data Loading End -->
                </div>
            </div>

        </div>
    </div>
</div>




<!-- <div id="content" #content> -->
<!-- <html>
    <head> -->
        <table hidden="true" *ngIf="!loader" id="content" #content cellpadding="0" style="width:1000px; margin: 0px auto; font-size:12px; padding: 0px; background: #fff;border: 2px solid #000000;" cellpadding="0" cellspacing="0">
            <tr>
                <td style="padding:0px; width: 1000px;">
                    <table style="width:1000px; font-size: 12px; border-collapse:collapse;border-bottom: 2px solid #000;background: #c5c5c5;">
                        <tr>
                            <td style="width:1000px; padding: 8px; text-align: center;"><strong>ORDER DETAIL</strong></td>
                        </tr>
                    </table>
                    
                    <table style="width:1000px; border-collapse:collapse;">
                        <tr>
                            <td style="width:300px;padding: 0px;padding-left: 10px;text-align: center;border-right:1px solid #000;">
                                <img style="height: 60px;" src="../../../assets/img/logo.png">
                            </td>
                            <td style="width:700px;padding: 0px;">
                                <table style="width:700px; border-collapse:collapse;">
                                    <tr>
                                        <td style="padding: 5px;text-align: center;">{{distrubutorInfo.dr_name ? distrubutorInfo.dr_name : 'N/A'}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    
                    <table style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                        <tr>
                            <td style="padding: 5px;width:400px;text-align: center;font-weight: 600;border-right:1px solid #000;">Distributor Information</td>
                            <td style="padding: 5px;width:600px;text-align: center;font-weight: 600;">Order Information</td>
                        </tr>
                    </table>
                    
                    <table style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                        <tr>
                            <td style="width:400px;padding: 0px;border-right: 1px solid #000000">
                                <table style="width:400px; border-collapse:collapse;font-size: 12px;">
                                    <tr>
                                        <td style="padding:5px;width: 90px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">Company Name :-</td>
                                        <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{distrubutorInfo.dr_name ? distrubutorInfo.dr_name : 'N/A'}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding:5px;width: 90px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">Contact Name :-</td>
                                        <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{distrubutorInfo.contact_name ? distrubutorInfo.contact_name : 'N/A'}}</td>
                                    </tr>
                                    <tr>
                                        <td style="padding:5px;width: 90px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">DR Code :-</td>
                                        <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{distrubutorInfo.dr_code ? distrubutorInfo.dr_code : 'N/A'}}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td style="padding:5px;width: 90px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">Mobile No.:-</td>
                                        <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{distrubutorInfo.mobile_no ? distrubutorInfo.mobile_no : 'N/A'}}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td style="padding:5px;width: 90px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">Email :-</td>
                                        <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{distrubutorInfo.email_id ? distrubutorInfo.email_id : 'N/A'}}</td>
                                    </tr>
                                    
                                    <tr>
                                        <td style="padding:5px;width: 90px; text-align: left;border-right: 1px solid #000000;">District & State:-</td>
                                        <td style="padding:5px;text-align: left;">{{distrubutorInfo.district_name ? distrubutorInfo.district_name : 'N/A'}} - {{distrubutorInfo.state_name ? distrubutorInfo.state_name : 'N/A'}}</td>
                                    </tr>
                                </table>
                            </td>
                            <td style="width:600px;padding: 0px;">
                                <table style="width:600px; border-collapse:collapse;font-size: 12px;">
                                    <tr>
                                        <td style="width:300px;padding: 0px;">
                                            <table style="width:300px; border-collapse:collapse;font-size: 12px;">
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">Date :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.created_date ?
                                                        (orderDetail.created_date | date: 'd MMM y, h:mm a' ):'N/A'}}</td>
                                                </tr>
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">Status Updated Date :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderList[0].last_updated ?
                                                        (orderList[0].last_updated | date: 'd MMM y, h:mm a' ):'N/A'}}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom: 1px solid #000000;border-right: 1px solid #000000;">Status :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">
                                                        <strong *ngIf="orderDetail.order_status == 1">Pending</strong>
                                                        <strong *ngIf="orderDetail.order_status == 2">Approved</strong>
                                                        <strong *ngIf="orderDetail.order_status == 3">Reject</strong>
                                                    </td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">SAP Status :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.sap_status ? orderDetail.sap_status : 'N/A'}}</td>
                                                </tr>
                    
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">Order ID :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">#AFF-{{orderDetail.order_id ? orderDetail.order_id : 'N/A'}}</td>
                                                </tr>
                    
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-right: 1px solid #000000;">No Of Items :-</td>
                                                    <td style="padding:5px;text-align: left;">{{orderDetail.total_items ? orderDetail.total_items : '0'}}</td>
                                                </tr>
                                            </table>
                                        </td>
        
                                        <td style="width:300px;padding: 0px;">
                                            <table style="width:300px; border-collapse:collapse;font-size: 12px;">
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">Total Qty :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.total_quantity ?
                                                        orderDetail.total_quantity:'0'}}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">List Discount :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.list_discount ? orderDetail.list_discount : '0'}}</td>
                                                </tr>
                                                
                                                <tr *ngIf="orderDetail.invoice_discount">
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">Invoice Discount :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.invoice_discount ?
                                                        orderDetail.invoice_discount:'0'}}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">Transporter :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.transport ?
                                                        orderDetail.transport:'N/A'}}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-bottom:1px solid #000;border-right: 1px solid #000000;">SAP Order No. 1 :-</td>
                                                    <td style="padding:5px;text-align: left;border-bottom:1px solid #000;">{{orderDetail.sap_order_1 ?
                                                        orderDetail.sap_order_1:'N/A'}}</td>
                                                </tr>
                                                
                                                <tr>
                                                    <td style="padding:5px;width: 130px; text-align: left;border-right: 1px solid #000000;">SAP Order No. 2 :-</td>
                                                    <td style="padding:5px;text-align: left;">{{orderDetail.sap_order_2 ? orderDetail.sap_order_2 : 'N/A'}}</td>
                                                </tr>
                                                <!-- <tr *ngIf="orderDetail.sap_order_3">
                                                    <td style="padding:5px;width: 130px; text-align: left;border-right: 1px solid #000000;">SAP Order No. 3 :-</td>
                                                    <td style="padding:5px;text-align: left;">{{orderDetail.sap_order_3 ?
                                                        orderDetail.sap_order_3:'N/A'}}</td>
                                                </tr> -->
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    
                    <table *ngIf="orderList.length > 0" style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                        <tr>
                            <td style="padding: 5px;width:1000px;text-align: center;font-weight: 600;">Item Information</td>
                        </tr>
                    </table>
                    <table *ngIf="orderList.length > 0" style="width:1000px;  border-collapse:collapse; font-size: 12px;">
                        <tr>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; border-left: 0px; width: 40px;">S.No</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:left; padding: 4px;width: 270px;">Segment</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 50px;">Brand</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 40px;">Material Code</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 60px;">Part Code</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 60px;">Qty</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:right; padding: 4px; width: 60px;">Company Stock</th>
                        </tr>
                        <tr *ngFor="let data of orderList; let i=index">
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px; border-left: 0px;">{{i+1}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:left; padding: 4px;">{{data.segment ?
                                data.segment:'N/A'}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">{{data.category ?
                                (data.category | titlecase ):'N/A'}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">{{data.material_code ?
                                (data.material_code | uppercase ):'N/A'}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">{{data.product_code ? data.product_code:'N/A'}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">{{data.qty ?
                                data.qty:'0'}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:right; padding: 4px;">{{data.total_Company_Stock ?
                                data.total_Company_Stock:'0'}}</td>
                        </tr>
                    </table>

                    <table *ngIf="invoiceData.length > 0" style="width:1000px; border-collapse:collapse;border-top: 2px solid #000000;">
                        <tr>
                            <td style="padding: 5px;width:1000px;text-align: center;font-weight: 600;">Invoice Information</td>
                        </tr>
                    </table>
                    <table *ngIf="invoiceData.length > 0" style="width:1000px;  border-collapse:collapse; font-size: 12px;">
                        <tr>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; border-left: 0px; width: 40px;">S.No</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:left; padding: 4px;width: 270px;">Date</th>
                            <th style="border-top: 0px; border: 1px solid #000000; background: #eaeaea; text-align:center; padding: 4px; width: 50px;">Invoice Number</th>
                            
                        </tr>
                        <tr *ngFor="let data of invoiceData; let i=index">
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px; border-left: 0px;">{{i+1}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:left; padding: 4px;">{{data.Bill_Date ? (data.Bill_Date | date: 'd MMM y') : 'N/A'}}</td>
                            <td style="border-top: 0px; border: 1px solid #000000; text-align:center; padding: 4px;">{{data.ODN_Number ? data.ODN_Number:'N/A'}}</td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
    <!-- </head>
</html> -->