<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac" style="height:100px;">
            <ng-container >
                <div class="module-name">
                    <h2>Dispatch List</h2>
                    
                </div>
                <div class="left-auto">
                    <div class="cs-btn mt0"  *ngIf="gatePassAssign.length > 0" (click)="openDialog()">
                        <a mat-button >Genrate Gate Pass</a>
                    </div>
                </div>
            </ng-container>

            <div class="left-auto">
                <ul class="date-filter">
                    <li>
                        <div class="cs-form pb0">
                            <p>Date From</p>
                            <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                    placeholder="Search ..." name="date_from" [(ngModel)]="orderSearch.date_from"
                                    readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </li>
                    <li>
                        <div class="cs-form pb0">
                            <p>Date To</p>
                            <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                    placeholder="Search ..." name="date_to" [(ngModel)]="orderSearch.date_to"
                                    readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </li>
                    <div style="flex-direction: column;align-items: center;justify-content: center;padding-bottom: 10px;">
                    <li class="cs-btn mt15 mr10" *ngIf="this.orderSearch.date_from && this.orderSearch.date_to &&
                    matTabValue !='3' ">
                        <a mat-button (click)="billData(1,status)">Go</a>
                    </li>
                    <li class="cs-btn mt15 mr10" *ngIf="this.orderSearch.date_from && this.orderSearch.date_to &&
                    matTabValue =='3'">
                        <a mat-button (click)="getGeatePass()">Go</a>
                    </li>
                    <li class="cs-btn mt15" *ngIf="this.orderSearch.date_from || this.orderSearch.date_to">
                        <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                    </li>
                </div>
                    <!-- <ng-container *ngIf="currentDay <= 25"> -->
                    <!-- <ng-container >
                        <li class="cs-btn mt15 ml10"
                            *ngIf="data_val?.login_type == 'distributor' || (apiHit.userLoginData.data.user_type=='approval_user' && apiHit.userLoginData.data.moduleData[4]['view'] == 1)">
                            <a mat-button routerLink="../primaryOrder-add">Add new</a>
                        </li>
                    </ng-container> -->
                </ul>
            </div>

            <!-- <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div> -->
        </section>
        
        <div class="container-pd10-5 bottom-calc no-padding">
            
            <mat-tab-group class="tab-group flat-tab-body tab-group-list" [selectedIndex]="matTabValue - 1" (selectedTabChange)="tabClick($event)" mat-align-tabs="start">
                
                <mat-tab label= "1" *ngIf="loginData.data.user_type != 'approval_user'">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">pending_actions</mat-icon>
                        Pending
                        <span class="badge">{{pendingDispatchCount}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                </mat-tab>
                
                <mat-tab label= "2" *ngIf="loginData.data.user_type != 'approval_user'">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">article</mat-icon>
                        Pending GatePass
                        <span class="badge">{{dispatchCount}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                </mat-tab>
                
                <mat-tab label= "3">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">local_shipping</mat-icon>
                        Dispatched
                        <span class="badge">{{count}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                </mat-tab>
            </mat-tab-group>
            
            
            <!-- Available Coupon Code Tab Start -->
            <div class="cs-table" *ngIf="matTabValue == 1 || matTabValue == 2">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw50 text-center" *ngIf="matTabValue == 2 ">&nbsp;</div>
                            <div class="div_cell mmw90">Date Created</div>
                            <div class="div_cell mmw110">Document Date</div>
                            <div class="div_cell mmw140">Invoice Number</div>
                            <div class="div_cell mw250">Customer Details</div>
                            <div class="div_cell mmw100 text-center">Total Item</div>
                            <div class="div_cell mmw100 text-center">Total QTY.</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw50 text-center" *ngIf="matTabValue == 2 ">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="date_created" 
                                        [(ngModel)]="filter.date_created" (dateChange)="onDate($event, 'date_created')" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker1.open()">
                                        <input type="text" matInput [matDatepicker]="picker1" [max]="date" placeholder="Search ..." name="Bill_Date" 
                                        [(ngModel)]="filter.Bill_Date" (dateChange)="onDate($event, 'bill_date')" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="ODN_Number" [(ngModel)]="filter.ODN_Number" (keyup.enter)="billData('')">
                                </div>
                            </div>
                            <div class="div_cell mw250">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="customer_name" [(ngModel)]="filter.customer_name" (keyup.enter)="billData('')">
                                </div>
                            </div>
                            <div class="div_cell mmw100">&nbsp;</div>
                            <div class="div_cell mmw100">&nbsp;</div>
                        </main>
                    </section>
                </figure>
                
                <section class="td_cell" >
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let row of distributor_list; let i=index;">
                            <div class="div_cell mmw50 ac">
                                <!-- <p>{{pageNumber}}</p> -->
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw50 ac " *ngIf="matTabValue == 2">
                                <ng-container  >
                                    <mat-checkbox name="checked" [(ngModel)]="row.checked" (change)="select_item($event,i)"></mat-checkbox>
                                </ng-container>
                            </div>
                            <div class="div_cell mmw90">{{row.created_date | date:'dd MMM yyyy'}}</div>
                            <div class="div_cell mmw110">{{row.Bill_Date | date:'dd MMM yyyy'}}</div>
                            <div class="div_cell mmw140">
                                <a class="link-btn" mat-flat-button  routerLink="../dispatch/{{row.id}}">
                                    {{row.ODN_Number}}
                                </a>
                            </div>
                            <div class="div_cell mw250">{{row.Cust_Vendor_Name | titlecase}} - ({{row.Cust_Vendor_Code}})</div>
                            <div class="div_cell mmw100 ac">{{row.total_billing_item}}</div>
                            <div class="div_cell mmw100 ac">{{row.total_billing_item_qty}}</div>
                        </main>
                        <app-not-found *ngIf="distributor_list.length == 0" class="fix"></app-not-found>
                    </ng-container>
                    
                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of [].constructor(10);" >
                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw50" *ngIf="matTabValue == 2"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mw250"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>
                    
                </section>
            </div>
            <!-- Available Coupon Code Tab End -->
            
            
            <!-- Scanned Coupon Code Tab Start -->
            <div class="cs-table" *ngIf="matTabValue == 3">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw140">Invoice No.</div>
                            <div class="div_cell mmw90">Invoice Date</div>
                            <div class="div_cell mmw90">Invoice Value</div>
                            <div class="div_cell mw250">Customer Name</div>
                            <div class="div_cell mmw140">Order No.</div>
                            <div class="div_cell mmw90">Order Date</div>
                            <div class="div_cell mmw140">LR No.</div>
                            <div class="div_cell mmw90">LR Date</div>
                            <div class="div_cell mmw160">Transport</div>
                            <div class="div_cell mmw120">State</div>
                            <div class="div_cell mmw120">Zone</div>
                            <div class="div_cell mmw90">Sets/Nos.</div>
                            <div class="div_cell mmw100">Boxes</div>
                            <div class="div_cell mmw140">Gatepass Number</div>
                            <div class="div_cell mw160">Delivery Person</div>
                            <div class="div_cell mmw140">Mobile No.</div>
                            <div class="div_cell mmw140">Vehicle Number</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="bill_number"
                                    [(ngModel)]="filter.bill_number"  (keyup.enter)="getGeatePass('')">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker4.open()">
                                        <input type="text" matInput [matDatepicker]="picker4" [max]="date" placeholder="Search ..." name="invoice_date 
                                        [(ngModel)]="filter.invoice_date (dateChange)="onDate($event, 'invoice_date')" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                        <mat-datepicker #picker4></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div> 
                            <div class="div_cell mmw90">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="total_amount"
                                    [(ngModel)]="filter.total_amount" (keyup.enter)="getGeatePass('')">
                                </div> -->
                            </div>
                            <div class="div_cell mw250">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Cust_Vendor_Name"
                                    [(ngModel)]="filter.Cust_Vendor_Name" (keyup.enter)="getGeatePass('')">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="order_id"
                                    [(ngModel)]="filter.order_id"  (keyup.enter)="getGeatePass('')">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker5.open()">
                                        <input type="text" matInput [matDatepicker]="picker5" [max]="date" placeholder="Search ..." name="order_date 
                                        [(ngModel)]="filter.order_date (dateChange)="onDate($event, 'order_date')" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                        <mat-datepicker #picker5></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="reference_number"
                                    [(ngModel)]="filter.reference_number"  (keyup.enter)="getGeatePass('')">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker6.open()">
                                        <input type="text" matInput [matDatepicker]="picker6" [max]="date" placeholder="Search ..." name="date_created 
                                        [(ngModel)]="filter.date_created (dateChange)="onDate($event, 'date_created')" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                        <mat-datepicker #picker6></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw160">
                                <!-- <div class="filter-data">
                                <i class="material-icons">search</i>
                                <input type="text" placeholder="Search ..." name="transportation_mode"
                                [(ngModel)]="filter.transportation_mode"  (keyup.enter)="getGeatePass('')">
                                </div> -->
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <select name="user_state" [(ngModel)]="filter.user_state"
                                        (change)="getGeatePass(1,status)" id="user_state">
                                        <option value="">All</option>
                                        <option *ngFor="let data of stateData; let i=index" value="{{data}}">{{data}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <select name="zone" [(ngModel)]="filter.zone" (change)="getGeatePass(1,status)"
                                        id="zone">
                                        <option value="">All</option>
                                        <option value="North">North</option>
                                        <option value="East">East</option>
                                        <option value="West">West</option>
                                        <option value="South">South</option>
                                    </select>
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="total_qty"
                                    [(ngModel)]="filter.total_qty" (keyup.enter)="getGeatePass('')">
                                </div> -->
                            </div>
                            <div class="div_cell mmw100">&nbsp;</div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="gate_pass_no"
                                    [(ngModel)]="filter.gate_pass_no"  (keyup.enter)="getGeatePass('')">
                                </div>
                            </div>
                            <div class="div_cell mw160">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="delivery_boy_name"
                                    [(ngModel)]="filter.delivery_boy_name" (keyup.enter)="getGeatePass('')" >
                                </div>
                            </div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                        </main>
                    </section>
                </figure>
                
                <section class="td_cell" >
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let row of gate_pass_list; let i=index;">
                            <div class="div_cell mmw50 text-center af-bdr-left">{{ i + 1 + sr_no }}</div>
                            <div class="div_cell mmw140">{{row.bill_number}}</div>
                            <div class="div_cell mmw90">{{row.invoice_date | date:'dd MMM yyyy'}}</div>
                            <div class="div_cell mmw90">{{row.total_amount ? (row.total_amount | number : '1.2-2') : '0'}}</div>
                            <div class="div_cell mw250">{{row.Cust_Vendor_Name}}</div>
                            <div class="div_cell mmw140">{{row.order_id}}</div>
                            <div class="div_cell mmw90">{{row.order_date | date:'dd MMM yyyy'}}</div>
                            <div class="div_cell mmw140">{{row.reference_number}}</div>
                            <div class="div_cell mmw90">{{row.date_created | date:'dd MMM yyyy'}}</div>
                            <div class="div_cell mmw160">{{row.transportation_mode}}</div>
                            <div class="div_cell mmw120">{{row.user_state}}</div>
                            <div class="div_cell mmw120">{{row.zone}}</div>
                            <div class="div_cell mmw90">{{row.total_qty}}</div>
                            <div class="div_cell mmw100">{{row.box_value}}</div>
                            <div class="div_cell mmw140">{{row.gate_pass_no}}</div>
                            <div class="div_cell mw160">{{row.delivery_boy_name | titlecase}}</div>
                            <div class="div_cell mmw140">{{row.mobile_number}}</div>
                            <div class="div_cell mmw140">{{row.vehicle_number}}</div>
                            <!-- <div class="div_cell mmw100">
                                <div class="cs-action">
                                    <a class="ac-green" matRipple matTooltip="Edit" matTooltipPosition="below" (click)="getDetails(row.id, 'detail')"><i class="material-icons">visibility</i></a>
                                    <a class="ac-green" matRipple matTooltip="View" matTooltipPosition="below" (click)="getDetails(row.id, 'update')"><i class="material-icons">edit</i></a>
                                </div>
                            </div> -->
                        </main>
                        <app-not-found *ngIf="gate_pass_list.length == 0" class="fix"></app-not-found>
                    </ng-container>
                    
                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of [].constructor(10);" >
                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw50" *ngIf="matTabValue == 2"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mw250"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mw160"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw120"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw120"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw160"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>
                    
                </section>
            </div>
            <!-- Scanned Coupon Code Tab Start -->
            
            
            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac" *ngIf="!loader">
                    <div class="pagination">
                        <ng-container *ngIf="matTabValue == 1">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',1)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',1)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',1)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf = "(matTabValue == 3 || matTabValue == 4 || matTabValue == 5) && total > 1">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',3)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',3)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',3)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf="matTabValue == 2">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',2)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',2)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',2)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container>
                        <!-- <ng-container *ngIf = "(matTabValue == 3 || matTabValue == 4 || matTabValue == 5) && total > 1">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',3)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',3)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',3)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container> -->
                    </div>
                    <div class="multi-btns" *ngIf="gate_pass_list.length > 0 && matTabValue == 3">
                        <a mat-raised-button color="accent" (click)="ExcelDownloadFunction()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                </div>
            </div> 
            <!-- Pagination End -->
            
        </div>
    </div>
</div>