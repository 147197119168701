<div *ngIf="dataValue == 1">
    <div mat-dialog-title class="model-head">
        <h2>Edit Basic Information</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="EditDistributorForm" (ngSubmit)="checkDrCodeDuplicacy()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">
                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Firm Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="firmName" id="firmName"
                            maxlength="40">
                        </mat-form-field>
                        <mat-hint class="has-error"
                        *ngIf="EditDistributorForm.controls.firmName.invalid && EditDistributorForm.controls['firmName']?.touched && !EditDistributorForm.controls['firmName']['errors']?.minlength ">
                        
                    </mat-hint>
                    <mat-hint class="has-error"
                    *ngIf="EditDistributorForm.controls.firmName.invalid && EditDistributorForm.controls['firmName']['errors']?.minlength || EditDistributorForm.controls['firmName']['errors']?.maxlength">
                    Required 3-40 Character
                </mat-hint>
            </div>
            <div class="col s12 m3">
                <mat-form-field appearance="outline" class="cs-input">
                    <mat-label>Name <span class="sub">*</span></mat-label>
                    <input matInput placeholder="Type Here ..." formControlName="firstName" id="firstName"
                    maxlength="40">
                </mat-form-field>
                <mat-hint class="has-error"
                *ngIf="EditDistributorForm.controls.firstName.invalid && EditDistributorForm.controls['firstName']?.touched && !EditDistributorForm.controls['firstName']['errors']?.minlength ">
                
            </mat-hint>
            <mat-hint class="has-error"
            *ngIf="EditDistributorForm.controls.firstName.invalid && EditDistributorForm.controls['firstName']['errors']?.minlength || EditDistributorForm.controls['firstName']['errors']?.maxlength">
            Required 3-40 Character
        </mat-hint>
    </div>
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Mobile No. <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="mobileNo" id="mobileNo"
            maxlength="10" (ngModelChange)="checkMobileNoDuplicacy()"
            (keypress)="OnlyNumber($event)">
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.mobileNo.invalid && EditDistributorForm.controls['mobileNo']?.touched && !EditDistributorForm.controls['mobileNo']['errors']?.minlength ">
        
    </mat-hint>
    <mat-hint class="has-error"
    *ngIf="EditDistributorForm.controls.mobileNo.invalid && EditDistributorForm.controls['mobileNo']['errors']?.minlength || EditDistributorForm.controls['mobileNo']['errors']?.maxlength">
    Required 10 Character
</mat-hint>
</div>
<div class="col s12 m3">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Email Id <span class="sub">*</span></mat-label>
        <input matInput placeholder="Type Here ..." formControlName="email" id="email">
    </mat-form-field>
    <mat-hint class="has-error"
    *ngIf="EditDistributorForm.controls.email.invalid &&  EditDistributorForm.controls['email']?.touched && !EditDistributorForm.controls['email']['errors']?.pattern">
    
</mat-hint>
<mat-hint class="has-error"
*ngIf="EditDistributorForm.controls.email?.invalid &&  EditDistributorForm.controls['email']['errors']?.pattern">
Invalid Email
</mat-hint>
</div>
</div>
<div class="row row-mb0">
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Date of Birth</mat-label>
            
            <input matInput [matDatepicker]="birth" formControlName="DOB" id="DOB" [min]="minDate"
            [max]="maxDate" (ngModelChange)="minDateSelect()" readonly (click)="birth.open()">
            
            <mat-datepicker-toggle matSuffix [for]="birth"></mat-datepicker-toggle>
            <mat-datepicker #birth disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>
    
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Date of Anniversary</mat-label>
            <input matInput [matDatepicker]="anniversary" formControlName="anniversary" id="anniversary"
            [min]="minDate" [max]="date" readonly (click)="anniversary.open()">
            <mat-datepicker-toggle matSuffix [for]="anniversary"></mat-datepicker-toggle>
            <mat-datepicker #anniversary disabled="false"></mat-datepicker>
        </mat-form-field>
    </div>
    
    
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>GST Number <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="gstNo" id="gstNo"
            maxlength="15">
        </mat-form-field>
        <mat-hint class="has-error" *ngIf="EditDistributorForm.controls.gstNo.invalid &&  EditDistributorForm.controls['gstNo']?.touched && 
        !EditDistributorForm.controls['gstNo']['errors']?.minlength  ">
        
        <!-- && !EditDistributorForm.controls['gstNo']['errors']?.pattern  -->
        
    </mat-hint>
    <!-- <mat-hint class="has-error"  
        *ngIf="EditDistributorForm.controls.gstNo?.invalid &&  EditDistributorForm.controls['gstNo']['errors']?.pattern && 
        !EditDistributorForm.controls['gstNo']['errors']?.minlength">
        Invalid GST No
    </mat-hint> -->
    <mat-hint class="has-error" *ngIf="EditDistributorForm.controls.gstNo.invalid && EditDistributorForm.controls['gstNo']['errors']?.minlength || 
    EditDistributorForm.controls['gstNo']['errors']?.maxlength ">
    
    <!-- && !EditDistributorForm.controls['gstNo']['errors']?.pattern  -->
    Required 15 Character
</mat-hint>
</div>

<div class="col s12 m3">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>PAN Number</mat-label>
        <input matInput placeholder="Type Here ..." formControlName="panNo" id="panNo"
        maxlength="10">
    </mat-form-field>
    <!-- <mat-hint class="has-error"  
        *ngIf="EditDistributorForm.controls.panNo?.invalid &&  EditDistributorForm.controls['panNo']['errors']?.pattern && 
        !EditDistributorForm.controls['panNo']['errors']?.minlength">
        Invalid PAN No
    </mat-hint> -->
    <mat-hint class="has-error" *ngIf="EditDistributorForm.controls.panNo.invalid && EditDistributorForm.controls['panNo']['errors']?.minlength || 
    EditDistributorForm.controls['panNo']['errors']?.maxlength ">
    
    <!-- && !EditDistributorForm.controls['panNo']['errors']?.pattern -->
    Required 10 Character
</mat-hint>
</div>
</div>

<div class="row row-mb0">
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Distributor Code <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="drCode" id="drCode">
        </mat-form-field>
        <mat-hint class="has-error" *ngIf="EditDistributorForm.controls.drCode.invalid && 
        EditDistributorForm.controls['drCode']?.touched">
        
    </mat-hint>
</div>
<div class="col s12 m3">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Credit Limit</mat-label>
        <input matInput placeholder="Type Here ..." formControlName="credit_limit" id="credit_limit"
        (keypress)="OnlyNumber($event)">
    </mat-form-field>
    <!-- <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.credit_limit.invalid && 
        EditDistributorForm.controls['credit_limit']?.touched">
    </mat-hint> -->
</div>
<div class="col s12 m3">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Oustanding</mat-label>
        <input matInput placeholder="Type Here ..." formControlName="outstanding" id="outstanding"
        (keypress)="OnlyNumber($event)">
    </mat-form-field>
    <!-- <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.outstanding.invalid && 
        EditDistributorForm.controls['outstanding']?.touched">
    </mat-hint> -->
</div>
<div class="col s12 m3">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Select Category </mat-label>
        <mat-select [value]="category" formControlName="category"
            name="category" id="category">
            <mat-option *ngFor="let data of category; let i=index"
                value="{{data.category}}"> {{data.category}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>

</div>


<div class="cs-head flat-heading">
    <h2>address Information</h2>
</div>

<div class="row row-mb0">
    
    <div class="col s12 m6 pading0">
        <div class="row row-mb0">
            <div class="col s12 m6">
                <mat-form-field appearance="outline" class="cs-input">
                    <mat-label>Pincode <span class="sub">*</span></mat-label>
                    <input matInput placeholder="Type Here ..." formControlName="pinNo" id="pinNo"
                    maxlength="6" (keypress)="OnlyNumber($event)"
                    (input)="enterPin(EditDistributorForm)">
                </mat-form-field>
                <mat-hint class="has-error"
                *ngIf="EditDistributorForm.controls.pinNo.invalid && EditDistributorForm.controls['pinNo']?.touched && !EditDistributorForm.controls['pinNo']['errors']?.minlength">
                
            </mat-hint>
            <mat-hint class="has-error"
            *ngIf="EditDistributorForm.controls.pinNo.invalid && EditDistributorForm.controls['pinNo']['errors']?.minlength || EditDistributorForm.controls['pinNo']['errors']?.maxlength">
            Required 6 Character
        </mat-hint>
    </div>
    <div class="col s12 m6">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>State <span class="sub">*</span></mat-label>
            <mat-select (keyup.enter)="districtList1()" [value]="stataData"
            formControlName="state" id="state">
            
            
            <ngx-mat-select-search formControlName="searchCtrl"
            [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
            name="search"></ngx-mat-select-search>
            
            
            <mat-option
            *ngFor="let data of stateData |stringFilterBy:EditDistributorForm.value.searchCtrl:'data';  let i=index"
            (click)="districtList(data)" value="{{data}}">
            {{data}}
        </mat-option>
    </mat-select>
</mat-form-field>
<mat-hint class="has-error"
*ngIf="EditDistributorForm.controls.state.invalid && EditDistributorForm.controls['state']?.touched">

</mat-hint>
</div>

</div>
<div class="row row-mb0">
    <div class="col s12 m6">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>District <span class="sub">*</span></mat-label>
            <mat-select [value]="selectedDistrict" formControlName="district" id="district">
                <mat-option *ngFor="let data of districtData; let i=index" value="{{data}}">
                    {{data}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-hint class="has-error"
            *ngIf="EditDistributorForm.controls.district.invalid && EditDistributorForm.controls['district']?.touched">
            
        </mat-hint>
    </div>
    <div class="col s12 m6">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>City <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="city" id="city"
            maxlength="30">
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.city.invalid && EditDistributorForm.controls['city']?.touched && !EditDistributorForm.controls['city']['errors']?.minlength ">
        
    </mat-hint>
    <mat-hint class="has-error"
    *ngIf="EditDistributorForm.controls.city.invalid && EditDistributorForm.controls['city']['errors']?.minlength || EditDistributorForm.controls['city']['errors']?.maxlength">
    Required 3-30 Character
</mat-hint>
</div>
</div>
</div>
<div class="row row-mb0">
    <div class="col s12 m6">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Address</mat-label>
            <textarea matInput placeholder="Type Here ..." class="h70" formControlName="address"
            id="address"></textarea>
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.address.invalid && 
        EditDistributorForm.controls['address']['errors']?.minlength || EditDistributorForm.controls['address']['errors']?.maxlength">
        Required Minimum 3 Characters
    </mat-hint>
</div>
</div>
</div>

<div class="cs-head flat-heading">
    <h2>Order status</h2>
</div>
<div class="row row-mb0">
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label> Status {{statusValue}}<span class="sub">*</span></mat-label>
            <mat-select  formControlName="order_status" id="order_status" >
                <mat-option value="Active" statusValue="Active">Active</mat-option>
                <mat-option value="Inactive" statusValue="Inactive">Inactive</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="EditDistributorForm.value['order_status']=='Inactive'" class="col s12 m6">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Remark <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="order_status_remark" id="order_status_remark"
            maxlength="30">
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.order_status_remark.invalid && EditDistributorForm.controls['order_status_remark']?.touched && !EditDistributorForm.controls['order_status_remark']['errors']?.minlength ">
        
    </mat-hint>
    <mat-hint class="has-error"
    *ngIf="EditDistributorForm.controls.order_status_remark.invalid && EditDistributorForm.controls['order_status_remark']['errors']?.minlength || EditDistributorForm.controls['order_status_remark']['errors']?.maxlength">
    Required 3-30 Character
</mat-hint>
</div>
</div>
<div class="cs-head flat-heading">
    <h2>Username / Password</h2>
</div>
<div class="row row-mb0">
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Username <span class="sub">*</span></mat-label>
            <input readonly="true" matInput placeholder="Type Here ..." formControlName="username" id="username">
        </mat-form-field>
    </div>
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Password</mat-label>
            <input matInput placeholder="Type Here ..." formControlName="password" id="password"
            (keypress)="OnlyNumber($event)">
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="EditDistributorForm.controls.password.invalid && 
        EditDistributorForm.controls['password']?.touched">
    </mat-hint>
</div>
</div>
</div>
</div>
<mat-dialog-actions align="end">
    <div class="cs-btn">
        <button mat-button type="submit" [disabled]="EditDistributorForm.invalid">Update</button>
        <!-- <button  mat-button >Update</button> -->
    </div>
</mat-dialog-actions>
</form>
</div>

<div *ngIf="dataValue == 2">
    <div mat-dialog-title class="model-head">
        <h2>Add Contact Person</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form #form="ngForm" [formGroup]="AddContactForm" (ngSubmit)="addContact()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="cPersonName" id="cPersonName"
                            maxlength="30">
                        </mat-form-field>
                        <mat-hint class="has-error"
                        *ngIf="AddContactForm.controls.cPersonName.invalid && 
                        AddContactForm.controls['cPersonName']?.touched && !AddContactForm.controls['cPersonName']['errors']?.minlength ">
                        
                    </mat-hint>
                    <mat-hint class="has-error"
                    *ngIf="AddContactForm.controls.cPersonName.invalid && 
                    AddContactForm.controls['cPersonName']['errors']?.minlength || AddContactForm.controls['cPersonName']['errors']?.maxlength">
                    Required 3-30 Character
                </mat-hint>
            </div>
            
            <div class="col s12 m4">
                <mat-form-field appearance="outline" class="cs-input">
                    <mat-label>Mobile No <span class="sub">*</span></mat-label>
                    <input matInput placeholder="Type Here ..." formControlName="cPersonMobileNo"
                    id="cPersonMobileNo" maxlength="10" (keypress)="OnlyNumber($event)">
                </mat-form-field>
                <mat-hint class="has-error"
                *ngIf="AddContactForm.controls.cPersonMobileNo.invalid && 
                AddContactForm.controls['cPersonMobileNo']?.touched && !AddContactForm.controls['cPersonMobileNo']['errors']?.minlength ">
                
            </mat-hint>
            <mat-hint class="has-error"
            *ngIf="AddContactForm.controls.cPersonMobileNo.invalid && 
            AddContactForm.controls['cPersonMobileNo']['errors']?.minlength || AddContactForm.controls['cPersonMobileNo']['errors']?.maxlength">
            Required 10 Character
        </mat-hint>
    </div>
    
    <div class="col s12 m4">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Email Id <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="cPersonEmail"
            id="cPersonEmail">
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="AddContactForm.controls.cPersonEmail.invalid && 
        AddContactForm.controls['cPersonEmail']?.touched && !AddContactForm.controls['cPersonEmail']['errors']?.pattern">
        
    </mat-hint>
    <mat-hint class="has-error" *ngIf="AddContactForm.controls.cPersonEmail?.invalid && 
    AddContactForm.controls['cPersonEmail']['errors']?.pattern">
    Invalid Email
</mat-hint>
</div>
</div>
</div>
</div>
<mat-dialog-actions align="end">
    <div class="cs-btn">
        <button mat-button type="submit" [disabled]="AddContactForm.invalid">Save</button>
        <!-- <button  mat-button >Save</button> -->
    </div>
</mat-dialog-actions>
</form>
</div>





<div *ngIf="dataValue == 3">
    <div mat-dialog-title class="model-head">
        <h2>Edit Contact Person</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="EditContactForm" (ngSubmit)="updateContact()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="updatePersonName"
                            id="updatePersonName" maxlength="30">
                        </mat-form-field>
                        <mat-hint class="has-error"
                        *ngIf="EditContactForm.controls.updatePersonName.invalid && 
                        EditContactForm.controls['updatePersonName']?.touched && !EditContactForm.controls['updatePersonName']['errors']?.minlength ">
                        Required
                    </mat-hint>
                    <mat-hint class="has-error"
                    *ngIf="EditContactForm.controls.updatePersonName.invalid && 
                    EditContactForm.controls['updatePersonName']['errors']?.minlength || EditContactForm.controls['updatePersonName']['errors']?.maxlength">
                    Required 3-30 Character
                </mat-hint>
            </div>
            <div class="col s12 m4">
                <mat-form-field appearance="outline" class="cs-input">
                    <mat-label>Mobile Number <span class="sub">*</span></mat-label>
                    <input matInput placeholder="Type Here ..." formControlName="updatePersonMobileNo"
                    id="updatePersonMobileNo" maxlength="10" (keypress)="OnlyNumber($event)">
                </mat-form-field>
                <mat-hint class="has-error"
                *ngIf="EditContactForm.controls.updatePersonMobileNo.invalid && 
                EditContactForm.controls['updatePersonMobileNo']?.touched && !EditContactForm.controls['updatePersonMobileNo']['errors']?.minlength ">
                Required
            </mat-hint>
            <mat-hint class="has-error"
            *ngIf="EditContactForm.controls.updatePersonMobileNo.invalid && 
            EditContactForm.controls['updatePersonMobileNo']['errors']?.minlength || EditContactForm.controls['updatePersonMobileNo']['errors']?.maxlength">
            Required 10 Character
        </mat-hint>
    </div>
    <div class="col s12 m4">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Email ID <span class="sub">*</span></mat-label>
            <input matInput placeholder="Type Here ..." formControlName="updatePersonEmail"
            id="updatePersonEmail">
        </mat-form-field>
        <mat-hint class="has-error"
        *ngIf="EditContactForm.controls.updatePersonEmail.invalid && 
        EditContactForm.controls['updatePersonEmail']?.touched && !EditContactForm.controls['updatePersonEmail']['errors']?.pattern">
        Required
    </mat-hint>
    <mat-hint class="has-error" *ngIf="EditContactForm.controls.updatePersonEmail?.invalid && 
    EditContactForm.controls['updatePersonEmail']['errors']?.pattern">
    Invalid Email
</mat-hint>
</div>
</div>
</div>
</div>
<mat-dialog-actions align="end">
    <div class="cs-btn">
        <button mat-button type="submit" [disabled]="EditContactForm.invalid">Update</button>
        <!-- <button  mat-button type="submit">Update</button> -->
    </div>
</mat-dialog-actions>
</form>
</div>


<div *ngIf="dataValue == 4">
    <div mat-dialog-title class="model-head">
        <h2>Edit Stoct Alert</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="EditStockAlert" (ngSubmit)="updateStockALert()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m8">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Stock Alert Qty <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="default_stock"
                            id="default_stock" maxlength="10" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="EditStockAlert.controls.default_stock.invalid && 
                        EditStockAlert.controls['default_stock']?.touched">
                        Required
                    </mat-hint>
                </div>
            </div>
        </div>
    </div>
    <mat-dialog-actions align="end">
        <div class="cs-btn">
            <button mat-button type="submit" [disabled]="EditStockAlert.invalid">Update</button>
            <!-- <button  mat-button type="submit">Update</button> -->
        </div>
    </mat-dialog-actions>
</form>
</div>


<div *ngIf="dataValue == 5">
    <div mat-dialog-title class="model-head">
    <h2>Assign Brands</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="AssignBrand" (ngSubmit)="addAssignBrand()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m12">
                        
                        
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Brand <span class="sub">*</span></mat-label>
                            <mat-select formControlName="brands" name="brands" id="brands" multiple>
                                
                                <!-- <ngx-mat-select-search formControlName="searchCtrl" [placeholderLabel]="'Search...'"
                                    [noEntriesFoundLabel]="'Not found'" name="search">
                                </ngx-mat-select-search> -->
                                
                                <mat-option *ngFor="let data of categoryName" value="{{data.brand}}">{{data.brand}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AssignBrand.controls.brands.invalid && 
                        AssignBrand.controls['brands']?.touched">
                    </mat-hint>
                    
                    
                    <!-- <mat-form-field appearance="outline" class="cs-input">
                        <mat-label>Brand <span class="sub">*</span></mat-label>
                        <mat-select formControlName="brands" name="brands" id="brands">
                            <mat-option
                            *ngFor="let data of categoryName" value="{{data.brand}}">
                            {{data.brand}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-hint class="has-error" *ngIf="AssignBrand.controls.brands.invalid && 
                AssignBrand.controls.brands.touched">
            </mat-hint> -->
            
            
            
        </div>
    </div>
</div>
</div>
<mat-dialog-actions align="end">
    <div class="cs-btn">
        <button mat-button type="submit" [disabled]="AssignBrand.invalid">Add</button>
    </div>
</mat-dialog-actions>
</form>
</div>



<div *ngIf="dataValue == 6">
    <div mat-dialog-title class="model-head">
        <h2>Upload Excel</h2>
        <a class="close-model" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    
    
    <form name="upload_excel" target="blank" method="post" enctype="multipart/form-data">
        <div class="csm-form tab-content ">
            <div class="inspace new-form-design" style="border: 0px;">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="input-block">
                            <label>Select File</label>
                            <input type="file" name="file" class="files" (change)="handleFileInput($event.target.files)"
                            required="true" accept=".csv">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <p class="file-intro mb30">Supported file type :- &nbsp;&nbsp;&nbsp; .CSV (<a target="_blank"
                            href="https://phpstack-414838-2222412.cloudwaysapps.com/askApi/min_stock.csv">Download
                            sample file</a>)</p>
                        </div>
                    </div>
                </div>
                <div class="row mb-15 mt-15">
                    <div class="col-sm-12 text-right">
                        <button class="cs-btn" color="accent" mat-raised-button type="submit" (click)="UploadExcel()"
                        class="btn btn-primary create-crud">Upload</button>
                    </div>
                </div>
            </div>
        </form>
        
    </div>

    <div *ngIf="dataValue == 7">
        <div mat-dialog-title class="model-head">
            <h2>Assign Sales User</h2>
            <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
        </div>
        <form [formGroup]="AssignedSalesUser" (ngSubmit)="addAssignedSalesUser()">
            <div mat-dialog-content>
                <div class="cs-form cs-form-model">
                    <div class="row">
                        <div class="col s12 m12">
                            
                            
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Select Sales User<span class="sub">*</span></mat-label>
                                <mat-select formControlName="salesUser" name="salesUser" id="salesUser" multiple>
                                    
                                    <!-- <ngx-mat-select-search formControlName="searchCtrl" [placeholderLabel]="'Search...'"
                                        [noEntriesFoundLabel]="'Not found'" name="search">
                                    </ngx-mat-select-search> -->
                                    
                                    <mat-option *ngFor="let data of salesUserList" value="{{data.id}}">{{data.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-hint class="has-error" *ngIf="AssignedSalesUser.controls.salesUser.invalid && 
                            AssignedSalesUser.controls['salesUser']?.touched">
                        </mat-hint>
                        
                        
                        <!-- <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Brand <span class="sub">*</span></mat-label>
                            <mat-select formControlName="brands" name="brands" id="brands">
                                <mat-option
                                *ngFor="let data of categoryName" value="{{data.brand}}">
                                {{data.brand}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-hint class="has-error" *ngIf="AssignBrand.controls.brands.invalid && 
                    AssignBrand.controls.brands.touched">
                </mat-hint> -->
                
                
                
            </div>
        </div>
    </div>
    </div>
    <mat-dialog-actions align="end">
        <div class="cs-btn">
            <button mat-button type="submit" [disabled]="AssignedSalesUser.invalid">Add</button>
        </div>
    </mat-dialog-actions>
    </form>
    </div>