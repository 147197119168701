import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { UserAddComponent } from 'src/app/user/user-add/user-add.component';


@Component({
    selector: 'app-retailer-list',
    templateUrl: './retailer-list.component.html'
})
export class RetailerListComponent implements OnInit {

    data_val: any;
    reatilerDetailData: any = [];
    reatilerDetailData1: any = [];
    paginationData: any={};
    total: any;
    loader: any;
    filterSearch: any = {};
    pendingStatusCount: any;
    verifiedStatusCount: any;
    rejectStatusCount: any;
    suspectStatusCount: any;
    skeleton: any = [];
    date: any;
    excelData: any = [];
    pendingNo: any = 0;
    pageNumber: any;
    pageNumber1: any;
    totalCount: any;
    totalPage: any;
    pendingStatusCount1: any;
    verifiedStatusCount1: any;
    rejectStatusCount1: any;
    suspectStatusCount1: any;
    totalCount1: any;
    totalPage1: any;
    matTabValue: any;

    constructor(

        public apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.skeleton = new Array(10);
        this.date = new Date();
        console.log(this.apiHit.userLoginData);
        this.data_val = this.apiHit.userLoginData.data
        console.log(this.data_val);
        this.matTabValue = '';
        this.pageNumber = 1;
        this.pageNumber1 = 1;
        // this.retailerList(1);

    }

    ngOnInit(): void {

        this.paginationData = this.apiHit.getFilterData('retailer-list');
        // this.paginationData = JSON.parse(localStorage.getItem('filterData'));
        this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        this.pageNumber1 = this.paginationData?.pageNumber1 ? this.paginationData.pageNumber1 : this.pageNumber1;
        this.matTabValue = this.paginationData?.matTabValue ? this.paginationData?.matTabValue : this.matTabValue;

        console.log('retailer list filter search ', this.paginationData);

        this.retailerList(this.paginationData?.target ? this.paginationData.target : 1);

    }


    getRetailerCount(){

        // if (this.data_val.user_type == 'approval_user') {
        //     this.paginationData.user_id = this.data_val.id;
        //     this.apiHit.PostRequest(this.paginationData, "Distributor/retailer_count").subscribe((result => {
                
        //         console.log(result);
        //         // this.reatilerDetailData1 = result['showApprovalRetailer']['info'];
        //         // this.total = result['retailer_count'];
        //         this.pendingStatusCount1 = result['retailer_count']['countData']['pendingCount'];
        //         this.verifiedStatusCount1 = result['retailer_count']['countData']['verifiedCount'];
        //         this.rejectStatusCount1 = result['retailer_count']['countData']['rejectCount'];
        //         this.suspectStatusCount1 = result['retailer_count']['countData']['suspectCount'];
        //         this.totalCount1 = result['retailer_count']['countData']['allCount'];
        //         this.totalPage1 = result['retailer_count']['totalPage'];
                
        //     }));
        // }
        // else{
            this.paginationData.user_id = '';

            this.apiHit.PostRequest(this.paginationData, "Distributor/retailer_count").subscribe((result => {

                console.log(result);

                // this.reatilerDetailData = result['retailer_list']['info'];
                // this.total = result['retailer_count'];
                this.pendingStatusCount = result['retailer_count']['countData']['pendingCount'];
                this.verifiedStatusCount = result['retailer_count']['countData']['verifiedCount'];
                this.rejectStatusCount = result['retailer_count']['countData']['rejectCount'];
                this.suspectStatusCount = result['retailer_count']['countData']['suspectCount'];
                this.totalCount = result['retailer_count']['countData']['allCount'];
                this.totalPage = result['retailer_count']['totalPage'];
                // this.loader = '';

            }));
        // }

    }
    

    //Get Retailer List Through API Function Start
    retailerList(target) {

        if (target == 1) {
            this.pageNumber = 1;
            this.pageNumber1 = 1;
        }

        if (this.data_val.user_type == 'System_user' || this.data_val.login_type == 'distributor' ) {
            this.loader = 1;
            this.reatilerDetailData = [];
            this.paginationData.search = this.filterSearch;
            this.paginationData.pagelimit = 20;
            this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
            this.paginationData.retailer_type = this.matTabValue;
            this.paginationData.target = target;
            this.paginationData.pageNumber = this.pageNumber;
            this.paginationData.matTabValue = this.matTabValue;
            if(this.data_val.login_type == 'distributor'){
            this.paginationData.login_type = this.data_val.login_type;
            this.paginationData.id = this.data_val.id;
            }

            console.log(this.paginationData);
            this.apiHit.setFilterData(this.paginationData);

            this.apiHit.PostRequest(this.paginationData, "Distributor/retailer_list").subscribe((result => {

                console.log(result);

                this.reatilerDetailData = result['retailer_list']['info'];
                // this.total = result['retailer_list'];
                // this.pendingStatusCount = result['retailer_list']['countData']['pendingCount'];
                // this.verifiedStatusCount = result['retailer_list']['countData']['verifiedCount'];
                // this.rejectStatusCount = result['retailer_list']['countData']['rejectCount'];
                // this.suspectStatusCount = result['retailer_list']['countData']['suspectCount'];
                // this.totalCount = result['retailer_list']['countData']['allCount'];
                // this.totalPage = result['retailer_list']['totalPage'];
                this.loader = '';
                this.getRetailerCount()

            }));
        }

        if (this.data_val.user_type == 'approval_user') {

            this.loader = 1;
            this.reatilerDetailData1 = [];
            this.paginationData.search = this.filterSearch;
            this.paginationData.pagelimit = 20;
            this.paginationData.start = (this.pageNumber1 - 1) * this.paginationData.pagelimit;
            this.paginationData.retailer_type = this.matTabValue;
            this.paginationData.target = target;
            this.paginationData.pageNumber1 = this.pageNumber1;
            this.paginationData.matTabValue = this.matTabValue;

            console.log(this.paginationData);
            this.apiHit.setFilterData(this.paginationData);

            this.apiHit.PostRequest({ 'allData': this.paginationData, 'user_id': this.data_val.id }, "User/showApprovalRetailer").subscribe((result => {

                console.log(result);

                this.reatilerDetailData1 = result['showApprovalRetailer']['info'];
                this.total = result['showApprovalRetailer'];
                this.pendingStatusCount1 = result['showApprovalRetailer']['countData']['pendingCount'];
                this.verifiedStatusCount1 = result['showApprovalRetailer']['countData']['verifiedCount'];
                this.rejectStatusCount1 = result['showApprovalRetailer']['countData']['rejectCount'];
                this.suspectStatusCount1 = result['showApprovalRetailer']['countData']['suspectCount'];
                this.totalCount1 = result['showApprovalRetailer']['countData']['allCount'];
                this.totalPage1 = result['showApprovalRetailer']['totalPage'];
                this.loader = '';

            }));
        }


    }
    //Get Retailer List Through API Function End



    public onDate(event): void {

        this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
        this.retailerList(1);

    }

    onChangePageNumber(type) {

        console.log(this.pageNumber);

        if (type == 'previous') {

            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                this.retailerList(2);

            }

        }
        else if (type == 'next') {

            if (this.totalPage > this.pageNumber) {

                this.pageNumber += 1;
                this.retailerList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber > this.totalPage) {

                console.log('current else');

                this.pageNumber = this.totalPage;
                this.retailerList(2);

            }
            else if (this.pageNumber == 0) {

                this.pageNumber = 1;
                this.retailerList(2);

            }
            else if (this.pageNumber) {

                console.log('last else');

                this.retailerList(2);
            }

        }

    }

    onChangePageNumber1(type) {

        console.log(this.pageNumber1);

        if (type == 'previous') {

            if (this.pageNumber1 > 1) {

                this.pageNumber1 -= 1;
                this.retailerList(2);

            }

        }
        else if (type == 'next') {

            if (this.totalPage1 > this.pageNumber1) {

                this.pageNumber1 += 1;
                this.retailerList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber1 > this.totalPage1) {

                console.log('current else');

                this.pageNumber1 = this.totalPage1;
                this.retailerList(2);

            }
            else if (this.pageNumber1 == 0) {

                this.pageNumber1 = 1;
                this.retailerList(2);

            }
            else if (this.pageNumber1) {

                console.log('last else');

                this.retailerList(2);
            }

        }

    }

    //Delete Retailer Function Start
    deleteReatilerData(deleteId) {

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ "id": deleteId }, "Distributor/delete_dealer").subscribe((result => {

                    console.log(result);

                    if (result['delete_dealer'] == 'success') {

                        this.retailerList(2);

                    } else {

                        this.dialog2.error('error', result['errors']);

                    }

                }));

            }

        });

    }
    //Delete Retailer Function End



    //Refresh Retailer List Function Start


    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null || this.pageNumber1 == null) {
            this.pageNumber = 1;
            this.pageNumber1 = 1;
        }
        this.retailerList(1);

    }
    //Refresh Retailer List Function End

    //Active Mat-Tab Function Start

    tabClick(tab) {
        console.log(tab['tab']);
        this.matTabValue = tab['tab']['textLabel'];
        this.filterSearch = {};
        this.pageNumber = 1;
        this.pageNumber1 = 1;
        this.retailerList(1);

    }
    //Active Mat-Tab Function End



    //Export Data Into Excel File Function Start
    exportRetailerDataInExcel() {

        this.loader = 1;

        this.paginationData.pagelimit = 0;

        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_RETAILER_DATA").subscribe((result => {

            console.log(result);
            this.retailerList(1)
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/DR_DATA.csv");

            }, 700);

        }));

    }
    //Export Data Into Excel File Function END


    //Export Data Into Excel File For ASM Function Start
    exportAsmRetailerDataInExcel() {

        this.loader = 1;

        this.paginationData.pagelimit = 0;

        this.apiHit.PostRequest({ 'allData': this.paginationData, 'user_id': this.data_val.id }, "Distributor/ASM_EXPORT_RETAILER_DATA").subscribe((result => {

            console.log(result);
            this.retailerList(1)
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/ASM_DR_DATA.csv");

            }, 700);

        }));

    }
    //Export Data Into Excel File For ASM Function END



    //Export Data Into Excel File For ZSM Function Start
    exportZsmRetailerDataInExcel() {

        this.loader = 1;

        this.paginationData.pagelimit = 0;

        this.apiHit.PostRequest({ 'allData': this.paginationData, 'user_id': this.data_val.id }, "Distributor/ZSM_EXPORT_RETAILER_DATA").subscribe((result => {

            console.log(result);
            this.retailerList(1)
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/ZSM_DR_DATA.csv");

            }, 700);

        }));

    }
    //Export Data Into Excel File For ZSM Function END

}