import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { ToastComponent } from 'src/app/toast/toast.component';
import { truncate } from 'fs';
// import { DialogComponent } from 'src/app/dialog';
// import { SnackBarOverview } from 'src/app/toast';


@Component({
  selector: 'app-master-and-primary',
  templateUrl: './master-and-primary.component.html',
  styleUrls: ['./master-and-primary.component.scss']
})
export class MasterAndPrimaryComponent implements OnInit {

  mapping: any = 2;
  qrcode: any = {};
  part_data: any = {};
  Vendor_info: any = {};
  secondary_codes: any = [];
  parentQRCode: any = '';
  qrCode_details: any = [];
  child_QRcodes: any = [];
  child_limit: any = '';
  userType: any;
  userdata: any = {};
  userId;
  userRole: any;
  loader: any = false;
  temp_childArray: any = [];
  skeleton: any = [];
  date: any;
  orderListData: any;
  searchData: any = {};
  orderSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  status: any;
  count: any;
  stateData: any = [];

  // for pdf 
  distrubutorInfo: any = {};
  orderDetail: any = {};
  orderListPdf: any = []
  invoiceData: any = []


  @ViewChild('master_qrcode') master_qrcodeElement: ElementRef;


  constructor(
    // public user:sessionStorage,
    public router: Router,
    public ElementRef: ElementRef,
    // public dialog:DialogComponent,
    public renderer2: Renderer2,
    public toast: ToastComponent,
    public apiHit: DatabaseService,
    public dialog: DialogService,
  ) {
    // this.userdata = this.user['user']['data'];
    // this.userType = this.userdata['userType'];
    // this.userId = this.userdata['userId'];
    // this.userRole = this.userdata['role'];
    // this.service.loader=false;
  }

  ngOnInit() {
    // this.secondary_codes.push({ 'child_code': '', 'valueAssign': '', 'readOnlyChk': false });
    this.setFocus();
    // this.primaryBoxSize()
  }

  // ******* Master and Secondary QR code Mapping Start ********//

  setFocus() {

    this.secondary_codes.push({ 'child_code': '', 'valueAssign': '', 'readOnlyChk': false });
    setTimeout(() => {
      $('#child_code' + (0)).focus();

    }, 0);

  }

  getValue() {

    this.qrcode.readOnly = false
    var value = this.qrcode.master_qrcode.split("/", 3);

    console.log('value line 123', value);
    this.parentQRCode = value[0];

    console.log(this.parentQRCode);

    this.apiHit.PostRequest({ 'master_coupon_no': value[0] }, 'CouponCode/checkMasterCoupon').subscribe((response) => {
      console.log(response);
      // this.part_data = response['data']['poItem'];
      // this.Vendor_info = response['data']['vendorInfo'];

      if (response['data'].status == "200") {

        this.qrCode_details = response['data']['coupon_information'];
        console.log(this.qrCode_details);
        if (this.qrCode_details.box_size != 0) {
          this.qrcode.readOnly = true;

          for (let i = 0; i < this.qrCode_details.box_size; i++) {

            this.secondary_codes.push({ 'child_code': '', 'valueAssign': '', 'readOnlyChk': true });

          }
          this.secondary_codes[0].readOnlyChk = false
          setTimeout(() => {
            $('#child_code' + (0)).focus();

          }, 0);
        }
        else {
          this.qrcode.master_qrcode = '';
          this.qrCode_details = []
          this.dialog.error('error', 'Master Box Size Is Zero');
        }

      }
      else if (response['data'].status == "300") {
        this.dialog.error('error', response['data'].message);
        this.qrcode.master_qrcode = '';
        setTimeout(() => {
          this.master_qrcodeElement.nativeElement.focus();
        }, 0);
      }
      else if (response['data'].status == "400") {
        this.dialog.error('error', response['data'].message);
        this.qrcode.master_qrcode = '';
        setTimeout(() => {
          this.master_qrcodeElement.nativeElement.focus();
        }, 0);
      }
      else {
        this.dialog.error('error', response['data'].message);
        this.qrcode.master_qrcode = '';
        setTimeout(() => {
          this.master_qrcodeElement.nativeElement.focus();
        }, 0);
      }
      this.child_limit = this.secondary_codes.length;

      console.log(this.secondary_codes);

    });

  }



  primaryBoxSize(data, index) {

    for (let i = 0; i < data.box_size; i++) {

      this.secondary_codes.push({ 'child_code': '', 'valueAssign': '', 'readOnlyChk': true });

    }
    this.secondary_codes[1].readOnlyChk = false
    setTimeout(() => {
      $('#child_code' + (1)).focus();
    }, 0);
  }


  checkValidation(code, index, c_index) {

    console.log('checkValidation', index);

    let str = code.split("/", 3);
    const checkvalue = this.secondary_codes.findIndex(row => row.valueAssign == str[0]);

    if (checkvalue === -1) {

      let apiData = {
        'box_part_code': this.qrCode_details ? this.qrCode_details.part_code : '',
        'primary_coupon_no': str[0],
      }
      this.apiHit.PostRequest(apiData, 'CouponCode/GET_COUPON_DETAIL').subscribe((res) => {
        console.log(res);

        if (res['data'].status == "200") {

          if(res['data'].coupon_information.box_size == 0){
            this.dialog.error('error', 'Box Size Is Zero');
            this.secondary_codes[index].child_code = '';
            return
          }

          this.secondary_codes[index].valueAssign = str[0];
          this.secondary_codes[index].readOnlyChk = true;
          this.child_limit = res['data'].coupon_information.box_size;
          this.child_QRcodes.push(str[0]);
          this.qrCode_details = res['data'].coupon_information

          if ((index + 1) < this.child_limit) {
            // this.primaryBoxSize(this.qrCode_details,index);
            console.log('if 201',this.secondary_codes);

            if (this.secondary_codes.length == 1) {
              console.log('if 202');
              
              for (let i = 0; i < this.child_limit - 1; i++) {

                this.secondary_codes.push({ 'child_code': '', 'valueAssign': '', 'readOnlyChk': true });

              }
            }
              this.secondary_codes[(index + 1)].readOnlyChk = false;
              setTimeout(() => {
                $('#child_code' + (index + 1)).focus();
              }, 0);
          }
          else {
            console.log('in else');
            this.getMapped()
          }
        }
        else if (res['data'].status == "300") {
          this.secondary_codes[index].child_code = '';
          this.dialog.error('error', res['data'].message);
        }
        else {
          this.dialog.error('error', res['data'].message);
          this.secondary_codes[index].child_code = '';
        }

      })
    }
    else {
      this.dialog.error('error', 'This QR Code is Already Scanned Above');
      this.secondary_codes[index].child_code = '';
    }

    console.log(this.secondary_codes);

  }


  getMapped() {

    console.log(this.child_QRcodes);

    let apiData = {
      'primary_coupon_list': this.child_QRcodes,
      'part_code' : this.qrCode_details.part_code,
      'price' :  (this.qrCode_details.coupon_value * this.child_QRcodes.length),
      'uid' : this.apiHit.userLoginData.data.id,
    }
    this.apiHit.PostRequest(apiData, 'CouponCode/SAVE_BOX_PACKING').subscribe((response) => {
      console.log(response);
      if (response['status'] == "success") {
        this.toast.openSucess('QR Codes Mapped Successfully', '');
        this.downloadTXTFile(response['master_coupon_code'], this.qrCode_details.part_code, this.qrCode_details.master_box_pack, this.qrCode_details.box_mrp,this.qrCode_details.with_rivet_or_pad_kit);
        this.resetChild();
      }
      else {
        this.dialog.error('error', 'QR Codes Mapping Failed!! Please Try Again ...!!');
        // this.mapping = 1
        // this.secondary_codes = [];
        // this.parentQRCode = '';
        // this.child_QRcodes = [];
        // this.qrcode.master_qrcode = '';
        // this.setFocus();
      }

    })

  }


  downloadTXTFile(masterQrCode, partCode, masterBoxSize, masterBoxMRP,with_rivet_or_pad_kit) {

    this.apiHit.exportTXTFile('@'+masterQrCode+'@'+partCode+'@'+masterBoxSize+'@'+masterBoxMRP+'@'+with_rivet_or_pad_kit+'@', 'MasterCoupon')

  }

  reopenMappedCodes() {
    this.dialog.delete('').then((confirmResult) => {

      if (confirmResult) {
        this.apiHit.GetRequest('', 'reopenqrcode/' + this.parentQRCode).subscribe((response) => {
          console.log(response);
          if (response['message'] == 'Success') {
            this.toast.openSucess('QR Codes Reopened Successfully', '');
            this.secondary_codes = [];
            this.child_QRcodes = [];
            this.qrCode_details = [];
            this.secondary_codes.push({ 'child_code': '', 'readOnlyChk': false });

            setTimeout(() => {
              $('#child_code0').focus();

            }, 0);

          }
        })
      }
    });
  }

  resetChild() {

    this.mapping = 2;
    this.part_data = {}
    this.Vendor_info = {}
    this.secondary_codes = [];
    this.parentQRCode = '';
    this.qrCode_details = [];
    this.child_QRcodes = [];
    this.child_limit = '';
    this.qrcode = {};
    this.setFocus();

    // this.dialog.delete('').then((confirmResult) => {

    //   if (confirmResult) {
    //     this.secondary_codes = [];
    //     this.child_QRcodes = [];
    //     // this.qrCode_details =[];
    //     this.secondary_codes.push({ 'child_code': '', 'readOnlyChk': false });
    //     setTimeout(() => {
    //       $('#child_code0').focus();

    //     }, 0);

    //   }
    // })

  }

  // ******* Dispatch Mapping Start ********//


  distributorList: any = [];
  summary: any = [];
  dropdownSettings: any = {};
  // invoiceData:any= {};
  invoice_masterCodes: any = []
  invoice_list: any = [];

  getInvoiceList() {
    let apiData = {
      filterBy: 1,
      currentPage: 1,
      pageSize: 10
    }
    console.log(apiData);

    this.apiHit.PostRequest(apiData, 'invoicelist').subscribe((response) => {
      console.log(response);
      this.invoice_list = response['data'];
      console.log(this.invoice_list);


    })

  }

  getAllDistributorList() {

    this.dropdownSettings = {
      singleSelection: true,
      closeDropDownOnSelection: true,
      idField: 'networkId',
      textField: 'establishment',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      maxHeight: 197
    };

    this.apiHit.PostRequest({}, 'network/list').subscribe((result) => {
      if (result['status'] == 'Success') {
        const distributorList = result['data'];
        const updatedDistributorList = [];

        for (let i = 0; i < distributorList.length; i++) {
          updatedDistributorList.push({

            networkId: distributorList[i].networkId,
            establishment: distributorList[i].establishment + '/' + distributorList[i].networkCode
          });
        }

        this.distributorList = updatedDistributorList;

        console.log(this.distributorList);
      }
    });

  }

  firstFocus() {
    this.invoiceData.master_codes = [];
    this.invoiceData.summary = [];

    this.invoiceData.master_codes.push({ 'master_code': '', 'readOnly': false, 'partNumber': '', 'modelCode': '', 'category': '', 'Qty': '' });
    setTimeout(() => {
      $('#master_code' + '0').focus();
    }, 0);
  }

  checkCodeForInvoice(value, i) {
    console.log(value, i);

    var str = value.split("/", 3)

    console.log(str);


    if (str[1] != 1) {
      this.dialog.error('error', 'Please Scan Master QR code Only');
      this.invoiceData.master_codes[i].master_code = '';

      setTimeout(() => {
        $('#master_code' + i).focus();
      }, 0);
    }
    else {
      let filter_index

      filter_index = this.invoice_masterCodes.findIndex(row => row.qrCode == str[2]);

      if (filter_index == -1) {

        this.apiHit.GetRequest('', 'validateInvoiceQrCode/' + str[2]).subscribe((result) => {
          console.log(result);
          if (result['message'] == 'Master QrCode is already Mapped with Other Invoice') {
            this.dialog.error('error', 'This Master QR Code is already Mapped with Other Invoice');
            this.invoiceData.master_codes[i].master_code = '';

            setTimeout(() => {
              $('#master_code' + (i + 1)).focus();
            }, 0);

          }
          if (result['message'] == 'Secondary QrCode not mapped') {
            this.dialog.error('error', 'QR Code Mapping Not Done...!! First Map QR Codes');
            this.invoiceData.master_codes[i].master_code = '';

            setTimeout(() => {
              $('#master_code' + (i + 1)).focus();
            }, 0);

          }
          if (result['message'] == 'Success') {
            this.apiHit.GetRequest('', 'getqrmapingdetails/' + str[2]).subscribe((response) => {
              let part_data = response['data']['poItem'];

              this.invoiceData.master_codes[i].partNumber = part_data.partNumber
              this.invoiceData.master_codes[i].modelCode = part_data.modelCode
              this.invoiceData.master_codes[i].category = part_data.category
              this.invoiceData.master_codes[i].Qty = part_data.secondaryPacking


              let index = this.summary.findIndex(row => row.partNumber == part_data.partNumber);
              if (index == -1) {
                this.summary.push({ 'partNumber': part_data.partNumber, 'Qty': part_data.secondaryPacking, 'modelCode': part_data.modelCode, 'category': part_data.category })

              }
              else {
                this.summary[index].Qty = parseInt(this.summary[index].Qty) + parseInt(part_data.secondaryPacking);

              }

              if (str[2] != '') {
                this.invoice_masterCodes.push({ 'qrCode': str[2] })

              }
              this.invoiceData.master_codes[i].readOnly = true;

              this.invoiceData.master_codes.push({ 'master_code': '', 'readOnly': false });
              setTimeout(() => {
                $('#master_code' + (i + 1)).focus();
              }, 0);

              part_data = {}

            });
          }

        })
      }
      else {
        this.dialog.error('error', 'This QR Code is Aready Scanned Above');
        this.invoiceData.master_codes[i].master_code = '';
      }

    }
  }

  invoiveMapping() {
    if (!this.invoiceData.invoice_number || this.invoiceData.invoice_number.length < 4) {
      this.dialog.error('error', 'Invoice Number Required with At Least 4 Character');
      setTimeout(() => {
        $('#invoice_number').focus();
      }, 0);

    }
    else if (!this.invoiceData.networkId) {
      this.dialog.error('error', 'Distributor Required..!! Please Select Distributor');
    }
    else {

      this.dialog.delete('').then((confirmResult) => {

        if (confirmResult) {
          let apiData = {
            invoiceCode: this.invoiceData.invoice_number,
            networkId: this.invoiceData.networkId[0].networkId,
            invoiceDetails: this.invoice_masterCodes
          }
          console.log(apiData);

          this.apiHit.PostRequest(apiData, 'mapinvoice').subscribe((response) => {
            console.log(response);
            if (response['message'] == 'Success') {
              this.toast.openSucess('Invoice Mapping Successful', '');
              this.distributorList = [];
              this.summary = [];
              this.dropdownSettings = {};
              this.invoiceData = {};
              this.invoice_masterCodes = []
              this.mapping = ''
            }
            if (response['message'] == 'Duplicate Invoice Code') {
              this.dialog.error('error', 'Invoice Number Already Mapped');
            }

          })
        }
      });
    }

  }

  resetInvoiceChild() {
    this.dialog.delete('').then((confirmResult) => {

      if (confirmResult) {

        this.invoiceData.master_codes = [];
        this.summary = [];
        this.invoice_masterCodes = []

        this.invoiceData.master_codes.push({ 'master_code': '', 'readOnly': false, 'partNumber': '', 'modelCode': '', 'category': '', 'Qty': '' });
        setTimeout(() => {
          $('#master_code' + '0').focus();
        }, 0);

      }
    })
  }

  //  **** search QR code ****////

  scanned_data: any = {}
  qr_information: any = {}
  parentInfo: any = {};
  invoce_secondary: any = [];
  invoce_primary: any = [];


  seachFocus() {
    setTimeout(() => {
      $('#qrcode').focus();
    }, 0);
  }

  getSearchValue() {
    this.invoce_secondary = [];
    this.invoce_primary = [];

    if (this.scanned_data.qrcode) {
      var str = this.scanned_data.qrcode.split("/", 3)

      console.log(str[1]);

      this.scanned_data.filterBy = 0

      if (str[1] == 1 || str[1] == 2 || str[1] == 3) {
        this.scanned_data.filterBy = 2;
      }
      else if (str[1] == 'INV') {
        this.scanned_data.filterBy = 1
      }

      console.log(this.scanned_data.filterBy);


      let apiData = {
        invoiceId: str[2],
        filterBy: this.scanned_data.filterBy,
        currentPage: 1,
        pageSize: 10
      }
      console.log(apiData);
      this.loader = true;

      this.apiHit.PostRequest(apiData, 'getmappedInvoice').subscribe((response) => {
        console.log(response);
        this.loader = false;

        this.qr_information = response['data']
        console.log(this.qr_information.parentCode);


        if ((str[1] == 2 || str[1] == 3) && this.qr_information.parentQrCode != 0) {

          this.qr_information.parentCode = this.qr_information.qrInfos[0].partNumber + '/' + (str[1] - 1) + '/' + this.qr_information.parentQrCode
        }
        if (str[1] == 1 || str[1] == 2 || str[1] == 3) {
          this.parentInfo = this.qr_information.qrInfos[0]
        }

        this.qr_information.scanned_code = this.scanned_data.qrcode;
        this.scanned_data.qrcode = '';
      })

    }


  }

  getChild(code, type) {
    this.loader = true;

    if (type == 1) {
      this.invoce_secondary = [];
      this.invoce_primary = [];
      this.apiHit.GetRequest('', 'getqrmapingdetails/' + code).subscribe((response) => {
        console.log(response);
        this.loader = false;

        this.invoce_secondary = response['data']['qrCodes'];
        console.log(this.invoce_secondary);

      })
    }
    if (type == 2) {
      this.invoce_primary = [];
      this.apiHit.GetRequest('', 'getqrmapingdetails/' + code).subscribe((response) => {
        console.log(response);
        this.loader = false;

        this.invoce_primary = response['data']['qrCodes'];
        console.log(this.invoce_primary);

      })
    }

  }

  reopenInvoiceMaster(id, invoice_code, qrcode) {
    console.log(this.qr_information);

    this.dialog.delete('').then((confirmResult) => {

      if (confirmResult) {

        this.apiHit.GetRequest('', 'reopenInvoice/' + id + '/' + qrcode).subscribe((response) => {
          console.log(response);
          if (response['message'] == 'Success') {
            this.toast.openSucess('QR Codes Reopened Successfully', '');

            this.scanned_data.qrcode = invoice_code + '/' + 'INV' + '/' + id;
            this.getSearchValue();
          }


        })
      }
    });
  }

}
