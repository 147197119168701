<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Retailer Incentive</h2>
                    <p>{{incentiveData.total_row ? incentiveData.total_row : '0'}} Incentive Are Available</p>
                </div>
                <div class="left-auto">
                    <div class="cs-action">
                        <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                            (click)="getincentiveList(1)"><i class="material-icons">search</i></a>
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                            (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                    </div>
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date From</div>
                            <div class="div_cell mmw110">Date To</div>
                            <div class="div_cell mw190">Retailer</div>
                            <div class="div_cell mmw190" *ngIf="data_val?.login_type != 'distributor'">Distributor</div>
                            <div class="div_cell mmw210">State</div>
                            <div class="div_cell mmw210">District</div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">ASM</div>
                            <div class="div_cell mmw90">Txn Id</div>
                            <div class="div_cell mmw90">Segment</div>
                            <div class="div_cell mmw70">Total Item</div>
                            <div class="div_cell mmw90">Total Item Qty</div>
                            <div class="div_cell mmw90">Points</div>
                            <div class="div_cell mmw110">Settlement Status</div>
                        </main>
                    </section>


                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="date_from"
                                            [(ngModel)]="filterSearch.date_from" (dateChange)="getincentiveList(1)"
                                            readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker2.open()">
                                        <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                            placeholder="Search ..." name="date_to" [(ngModel)]="filterSearch.date_to"
                                            (dateChange)="getincentiveList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mw190">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="retailer_name"
                                        [(ngModel)]="filterSearch.retailer_name" (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw190" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="distributor_name"
                                        [(ngModel)]="filterSearch.distributor_name"
                                        (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw210">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="retailer_state"
                                        [(ngModel)]="filterSearch.retailer_state" (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw210">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="retailer_district"
                                        [(ngModel)]="filterSearch.retailer_district"
                                        (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="asm"
                                        [(ngModel)]="filterSearch.asm" (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="id" [(ngModel)]="filterSearch.id"
                                        (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="getincentiveList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw70">
                                <p style="background-color: white;margin-left:4px">{{incentiveData.total_item_count ?
                                    incentiveData.total_item_count : 0}}</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p style="background-color: white;margin-left:4px">{{incentiveData.total_order_count ?
                                    incentiveData.total_order_count : 0}}</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p style="background-color: white;margin-left:4px">
                                    {{incentiveData.total_incentive_points ? incentiveData.total_incentive_points : 0}}
                                </p>
                            </div>
                            <div class="div_cell mmw110">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="getincentiveList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of incentiveData.data; let i=index">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">
                                {{data.date_from ? (data.date_from | date: 'd MMM y') : 'N/A'}}
                            </div>
                            <div class="div_cell mmw110">
                                {{data.date_to ? (data.date_to | date: 'd MMM y') : 'N/A'}}
                            </div>
                            <div class="div_cell mw190">{{data.retailer_name ? (data.retailer_name | titlecase) :
                                'N/A'}} </div>
                            <div class="div_cell mmw190" *ngIf="data_val?.login_type != 'distributor'">{{data.distributor_name ? (data.distributor_name |
                                titlecase):'N/A'}}</div>
                            <div class="div_cell mmw210">{{data.retailer_state ? (data.retailer_state | titlecase) :
                                'N/A'}}</div>
                            <div class="div_cell mmw210">{{data.retailer_district ? data.retailer_district : 'N/A'}}
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">{{data.asm ? (data.asm | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw90"><a class="link-btn" mat-flat-button
                                    routerLink="../incentive-Detail/{{data.txn_id}}">
                                    #TXN-{{data.id ? data.id : 'N/A'}}
                                </a></div>
                            <div class="div_cell mmw90">{{data.segment ? (data.segment | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw70">{{data.order_count ? data.order_count : '0'}}</div>
                            <div class="div_cell mmw90">{{data.item_count ? data.item_count : 'N/A'}}</div>
                            <div class="div_cell mmw90">{{data.earn_points ? data.earn_points : 'N/A'}}</div>
                            <div class="div_cell mmw110">
                                <strong *ngIf="data.status=='Pending'" class="yellow-clr">{{data.status ? (data.status |
                                    titlecase) : 'N/A' }}</strong>
                                <strong *ngIf="data.status!='Pending'" class="green-clr">{{data.status ? (data.status |
                                    titlecase): 'N/A' }}</strong>
                            </div>
                        </main>
                        <app-not-found *ngIf="incentiveData.data.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw190">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw190" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw210">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw210">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110" *ngIf="data_val?.login_type != 'distributor'">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw70">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>
                </section>
            </div>

            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>

                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="incentiveData.data.length > 0"
                            (click)="exportRetailerIncentive()" class="ml10"><img src="assets/img/excel.png"> Download
                            Excel</a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>