<div *ngIf="apiHit?.userLoginData && apiHit?.userLoginData?.data && apiHit?.userLoginData?.data?.id && apiHit?.userLoginData?.data?.user_type =='System_user'"
  class="cs-navigation">
  <ul>

    <!-- cs-badge--: class for counter -->
    <!-- <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="dashboard" routerLinkActive="active"
        *ngIf="data.module_name=='Dashboard' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>dashboard</mat-icon>
        <span>Dashboard</span>
      </a>
    </li> -->



    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="distributor" routerLinkActive="active"
        *ngIf="data.module_name=='Distributor' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>people</mat-icon>
        <span>Distributor</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_distributer ?
          (this.apiHit.countLeftNavigation?.total_distributer) : null}}
        </span>
      </a>
    </li>



    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="retailer" routerLinkActive="active" *ngIf="data.module_name=='Retailer' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>person</mat-icon>
        <span>Retailer</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_retailer ?
          (this.apiHit.countLeftNavigation?.total_retailer) : null}}
        </span>
      </a>
    </li>




    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="mechanic" routerLinkActive="active" *ngIf="data.module_name=='Mechanic' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>engineering</mat-icon>
        <span>Mechanic</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_mechanic ?
          (this.apiHit.countLeftNavigation?.total_mechanic) : null}}
        </span>
      </a>
    </li>

    <!-- <div *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <li *ngIf="data.module_name=='Primary Orders' && data.view==1 || data.module_name=='Secondary Orders' && data.view==1"  class="dropdown"
        [ngClass]="{'active' : (tabActiveType['order'] == true && tab['order'] == true ) }">
        <span>
          <a matRipple (click)="tabActive('order')">
            <mat-icon>swap_horiz</mat-icon>
            <span>Orders</span>
          </a>
          <ul>
            <li>
              <a routerLink="order-list" routerLinkActive="active" *ngIf="data.module_name=='Primary Orders' && data.view==1">
                Primary
                
                <span >
                  {{this.apiHit.countLeftNavigation?.total_primary_pendings_order ?
                  (this.apiHit.countLeftNavigation?.total_primary_pendings_order) : null}}
                </span>
              </a>
            </li>
            <li>
              <a routerLink="secondary-order" routerLinkActive="active" *ngIf="data.module_name=='Secondary Orders' && data.view==1">
                Secondary
                
                <span >
                  {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
                  (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
                </span>
              </a>
            </li>
          </ul>
        </span>
      </li>
    </div> -->

    <li  class="dropdown" [ngClass]="{'active' : (tabActiveType['order'] == true && tab['order'] == true ) }"  >
      <span>
        <a matRipple (click)="tabActive('order')" >
          <mat-icon>swap_horiz</mat-icon>
          <span>Orders</span>
        </a>
        <ul>
          <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
            <a routerLink="order-list" routerLinkActive="active" *ngIf="data.module_name=='Primary Orders' && data.view==1" (click)="leftnavigationcount()">
              Primary
              
              <span >
                {{this.apiHit.countLeftNavigation?.total_primary_pendings_order ?
                (this.apiHit.countLeftNavigation?.total_primary_pendings_order) : null}}
              </span>
            </a>
          </li>
          <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
            <a routerLink="secondary-order" routerLinkActive="active" *ngIf="data.module_name=='Secondary Orders' && data.view==1" (click)="leftnavigationcount()">
              Secondary
              
              <span >
                {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
                (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
              </span>
            </a>
          </li>
        </ul>
      </span>
    </li>

    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="incentive-list" routerLinkActive="active" *ngIf="data.module_name=='Retailer Incentive' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>person</mat-icon>
        <span>Retailer Incentive</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_retailer_incentive ?
          (this.apiHit.countLeftNavigation?.total_retailer_incentive) : null}}
        </span>
      </a>
    </li>



    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="pending-orders" routerLinkActive="active" *ngIf="data.module_name=='Pending Order' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>pending_actions</mat-icon>
        <span>Pending Order</span>
        
        <!-- <span >
          {{this.apiHit.countLeftNavigation?.total_pending_data ?
          (this.apiHit.countLeftNavigation?.total_pending_data) : 0}}
        </span> -->
      </a>
    </li>


    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="billing-list" routerLinkActive="active" *ngIf="data.module_name=='Billing Details' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>receipt</mat-icon>
        <span>Billing Detail</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_billing ?
          (this.apiHit.countLeftNavigation?.total_billing) : null}}
        </span>
      </a>
    </li>


    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="target-list" routerLinkActive="active" *ngIf="data.module_name=='Targets' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>receipt</mat-icon>
        <span>Target</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_target ?
          (this.apiHit.countLeftNavigation?.total_target) : null}}
        </span>
      </a>
    </li>



    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <!--  routerLinkActive="active" -->
      <a matRipple routerLink="coupon"
        *ngIf="data.module_name=='Coupon Code' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>code</mat-icon>
        <span>Coupon Code</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_coupon ?
          (this.apiHit.countLeftNavigation?.total_coupon) : null}}
        </span>
      </a>
    </li>
 
    
    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="/dispatch" routerLinkActive="active" *ngIf="data.module_name=='Dispatch' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>local_shipping</mat-icon>
        <span>Dispatch</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.total_coupon ?
          (this.apiHit.countLeftNavigation?.total_coupon) : null}}
        </span> -->
      </a>
    </li>

    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="damage-scan-qrCode" routerLinkActive="active"
        *ngIf="data.module_name=='Coupon Damage' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>code</mat-icon>
        <span>Damage Coupon Entry</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.total_coupon ?
          (this.apiHit.countLeftNavigation?.total_coupon) : null}}
        </span> -->
      </a>
    </li>
    
    <!-- <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="damage-scan-qrCode" routerLinkActive="active">
        <mat-icon>code</mat-icon>
        <span>Damage Coupon Entry</span>
      </a>
    </li> -->
    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <!--  matRipple routerLink="coupon" routerLinkActive="active" -->
      <a *ngIf="data.module_name=='Primary Scanned' && data.view==1"  matRipple routerLink="print-scanned" routerLinkActive="active">
        <mat-icon>code</mat-icon>
        <span>Primary Scanned</span>
        
        <!-- <span >
          {{this.apiHit.countLeftNavigation?.total_coupon ?
          (this.apiHit.countLeftNavigation?.total_coupon) : null}}
        </span> -->
      </a>
    </li>

    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="part-master" routerLinkActive="active" *ngIf="data.module_name=='Part Master' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>settings</mat-icon>
        <span>Part Master</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_parts ?
          (this.apiHit.countLeftNavigation?.total_parts) : null}}
        </span>
      </a>
    </li>
 

    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="companyStock-list" routerLinkActive="active"
        *ngIf="data.module_name=='Company Stock' && data.view==1" (click)="leftnavigationcount()"
      >
        <mat-icon>settings</mat-icon>
        <span >Company Stock</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.company_stock_count ?
          (this.apiHit.countLeftNavigation?.company_stock_count) : null}}
        </span> -->
      </a>
    </li>



    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="scheme-list" routerLinkActive="active" *ngIf="data.module_name=='Scheme' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>card_giftcard</mat-icon>
        <span>Scheme</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_scheme ?
          (this.apiHit.countLeftNavigation?.total_scheme) : null}}
        </span>
      </a>
    </li>


    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="banner-list" routerLinkActive="active"
        *ngIf="data.module_name=='Banner' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>photo_size_select_actual</mat-icon>
        <span>
          Banner
        </span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_bannerList ?
          (this.apiHit.countLeftNavigation?.total_bannerList) : null}}
        </span>
      </a>
    </li>



    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="user-list" routerLinkActive="active" *ngIf="data.module_name=='User' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>person</mat-icon>
        <span>Users</span>
        
        <span >
          {{this.apiHit.countLeftNavigation?.total_user ?
          (this.apiHit.countLeftNavigation?.total_user) : null}}
        </span>
      </a>
    </li>

    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="customer-category" routerLinkActive="active" *ngIf="data.module_name=='Customer Category' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>people</mat-icon>
        <span>Distributor Category</span>
        
        <!-- <span >
          {{this.apiHit.countLeftNavigation?.total_user ?
          (this.apiHit.countLeftNavigation?.total_user) : null}}
        </span> -->
      </a>
    </li>

    <li  class="dropdown"
    [ngClass]="{'active' : (tabActiveType['sales'] == true && tab['sales'] == true ) }" >
    <span>
      <a matRipple (click)="tabActive('sales')">
        <mat-icon>swap_horiz</mat-icon>
        <span>Sale</span>
      </a>
      <ul>
        <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
          <a routerLink="attendance" routerLinkActive="active" *ngIf="data.module_name=='Attendance' && data.view==1" (click)="leftnavigationcount()">
            Attendance List
            <!-- 
              <span >
              {{this.apiHit.countLeftNavigation?.total_primary_pendings_order ?
              (this.apiHit.countLeftNavigation?.total_primary_pendings_order) : null}}
            </span> -->
          </a>
        </li>
        <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
          <a routerLink="checkin" routerLinkActive="active"  *ngIf="data.module_name=='Checkin' && data.view==1" (click)="leftnavigationcount()">
            Checkin List
            <!-- 
              <span >
              {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
              (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
            </span> -->
          </a>
        </li>
        <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
          <a routerLink="expense" routerLinkActive="active"  *ngIf="data.module_name=='Expense' && data.view==1" (click)="leftnavigationcount()">
            Expense
            <!-- 
              <span >
              {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
              (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
            </span> -->
          </a>
        </li>
        <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
          <a routerLink="allowance" routerLinkActive="active" *ngIf="data.module_name=='Allowance' && data.view==1" (click)="leftnavigationcount()">
            Allowance Master
            <!-- 
              <span >
              {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
              (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
            </span> -->
          </a>
        </li>
      </ul>
    </span>
  </li>

    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData" class="dropdown"
      [ngClass]="{'active' : (tabActiveType['transaction'] == true && tab['transaction'] == true ) }">

      <span *ngIf="data.module_name=='Transaction Report' && data.view==1">

        <a matRipple (click)="tabActive('transaction')" (click)="leftnavigationcount()">
          <mat-icon>swap_horiz</mat-icon>
          <span>Report</span>
        </a>
        <ul>
          <li>
            <a routerLink="ask-distributor" routerLinkActive="active">
              AFF Brake To Distributor
            </a>
          </li>
          <li>
            <a routerLink="distributor-retailer" routerLinkActive="active">
              Distributor To Retailer
            </a>
          </li>
          <li>
            <a routerLink="retailer-mechanic" routerLinkActive="active">
              Retailer To Mechanic
            </a>
          </li>
          <li>
            <a routerLink="partially-delivered" routerLinkActive="active">
              Partially Delivered Orders
            </a>
          </li>
          <li>
            <a routerLink="retailer-scheme" routerLinkActive="active">
              Retailer Scheme
            </a>
          </li>
          <li>
            <a routerLink="distributor-msl" routerLinkActive="active">
              Distributor MSL
            </a>
          </li>
          <li>
            <a routerLink="coupon-utilize" routerLinkActive="active">
              Coupon Code Utilize
            </a>
          </li>
          <li>
            <a routerLink="companyPacked-stock" routerLinkActive="active">
              Company Stock
            </a>
          </li>
          <li>
            <a routerLink="warehousePacked-stock" routerLinkActive="active">
              Warehouse Stock
            </a>
          </li>
        </ul>

      </span>
    </li>


  </ul>
</div>
<div *ngIf="apiHit?.userLoginData && apiHit?.userLoginData?.data && apiHit?.userLoginData?.data?.id && apiHit?.userLoginData?.data?.user_type=='approval_user'"
  class="cs-navigation">
  <ul>
    <li>
      <a matRipple routerLink="distributor" routerLinkActive="active">
        <mat-icon>people</mat-icon>
        <span>Distributor</span>
      </a>
    </li>
    <li>
      <a matRipple routerLink="retailer" routerLinkActive="active">
        <mat-icon>person</mat-icon>
        <span>Retailer</span>
      </a>
    </li>
    <li  class="dropdown"
      [ngClass]="{'active' : (tabActiveType['order'] == true && tab['order'] == true ) }">
      <span>
        <a matRipple (click)="tabActive('order')">
          <mat-icon>swap_horiz</mat-icon>
          <span>Orders</span>
        </a>
        <ul>
          <li>
            <a routerLink="order-list" routerLinkActive="active" *ngIf="moduleData[4]['view'] == 1" (click)="leftnavigationcount()">
              <span>Primary</span>
              
              <span >
                {{this.apiHit.countLeftNavigation?.total_primary_pendings_order ?
                (this.apiHit.countLeftNavigation?.total_primary_pendings_order) : null}}
              </span>
            </a>
          </li>
          <li>
            <a routerLink="secondary-order" routerLinkActive="active" *ngIf="moduleData[5]['view'] == 1" (click)="leftnavigationcount()">
              <span>Secondary</span>
              
              <span >
                {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
                (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
              </span>
            </a>
          </li>
      
        </ul>
      </span>
    </li>
    <li *ngFor=" let data of apiHit.userLoginData.data.moduleData">
      <a matRipple routerLink="/dispatch" routerLinkActive="active" *ngIf="data.module_name=='Dispatch' && data.view==1" (click)="leftnavigationcount()">
        <mat-icon>local_shipping</mat-icon>
        <span>Dispatch</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.total_coupon ?
          (this.apiHit.countLeftNavigation?.total_coupon) : null}}
        </span> -->
      </a>
    </li>
    <li>
      <a matRipple routerLink="incentive-list" routerLinkActive="active">
        <mat-icon>person</mat-icon>
        <span>Retailer Incentive</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.total_retailer_incentive ?
          (this.apiHit.countLeftNavigation?.total_retailer_incentive) : null}}
        </span> -->
      </a>
    </li>
    <li>
      <a matRipple routerLink="pending-orders" routerLinkActive="active">
        <mat-icon>pending_actions</mat-icon>
        <span>Pending Order</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.total_pending_data ?
          (this.apiHit.countLeftNavigation?.total_pending_data) : 0}}
        </span> -->
      </a>
    </li>
    <li >
      <a matRipple routerLink="billing-list" routerLinkActive="active" >
        <mat-icon>receipt</mat-icon>
        <span>Billing Detail</span>
        <!-- 
          <span >
          {{this.apiHit.countLeftNavigation?.total_billing ?
          (this.apiHit.countLeftNavigation?.total_billing) : null}}
        </span> -->
      </a>
    </li>
    <li>
      <a  routerLink="companyStock-list" routerLinkActive="active">
        <mat-icon>settings</mat-icon>
        <span>Company Stock</span>
      </a>
    </li>
  </ul>
</div>

<div *ngIf="apiHit?.userLoginData && apiHit?.userLoginData?.data && apiHit?.userLoginData?.data?.id && apiHit?.userLoginData?.data?.user_type=='scanning_user'" class="cs-navigation">
  <ul>
    <li>
      <a matRipple routerLink="scan-qrCode" routerLinkActive="active">
        <mat-icon>code</mat-icon>
        <span>QR Code Scanning</span>
      </a>
    </li>
   
  </ul>
  
</div>
<div *ngIf="apiHit?.userLoginData && apiHit?.userLoginData?.data && apiHit?.userLoginData?.data?.id && apiHit?.userLoginData?.data?.user_type=='dispatch_user'" class="cs-navigation">
  <ul>
    <li>
      <a matRipple routerLink="/dispatch" routerLinkActive="active">
        <mat-icon>local_shipping</mat-icon>
        <span>Dispatch</span>
      </a>
    </li>
   
  </ul>
 
</div>
<div *ngIf="apiHit?.userLoginData && apiHit?.userLoginData?.data && apiHit?.userLoginData?.data?.id && apiHit?.userLoginData?.data?.user_type=='dispatch_user'" class="cs-navigation">
  <ul>
    <li>
      <a matRipple routerLink="/dispatch" routerLinkActive="active">
        <mat-icon>local_shipping</mat-icon>
        <span>Dispatch</span>
      </a>
    </li>
   
  </ul>
 
</div>
<div *ngIf="apiHit?.userLoginData && apiHit?.userLoginData?.data && apiHit?.userLoginData?.data?.id && apiHit?.userLoginData?.data?.login_type=='distributor'" class="cs-navigation">
  <ul>
    <li>
      <a matRipple routerLink="distributor-detail/{{apiHit?.userLoginData?.data?.id}}" routerLinkActive="active">
        <mat-icon>portrait</mat-icon>
        <span>Profile</span>
      </a>
    </li>
    <!-- <li>
      <a matRipple routerLink="retailer" routerLinkActive="active">
        <mat-icon>person</mat-icon>
        <span>Retailer</span>
      </a>
    </li> -->
    <li  class="dropdown"
    [ngClass]="{'active' : (tabActiveType['order'] == true && tab['order'] == true ) }">
    <span>
      <a matRipple (click)="tabActive('order')">
        <mat-icon>swap_horiz</mat-icon>
        <span>Orders</span>
      </a>
      <ul>
        <li>
          <a routerLink="order-list" routerLinkActive="active" >
            <span>Primary</span>
            <!-- 
              <span >
              {{this.apiHit.countLeftNavigation?.total_primary_pendings_order ?
              (this.apiHit.countLeftNavigation?.total_primary_pendings_order) : null}}
            </span> -->
          </a>
        </li>
        <li>
          <a routerLink="secondary-order" routerLinkActive="active" >
            <span>Secondary</span>
            <!-- 
              <span >
              {{this.apiHit.countLeftNavigation?.total_secondary_pending_order ?
              (this.apiHit.countLeftNavigation?.total_secondary_pending_order) : null}}
            </span> -->
          </a>
        </li>
    
      </ul>
    </span>
  </li>
  <li>
    <a matRipple routerLink="pending-orders" routerLinkActive="active">
      <mat-icon>pending_actions</mat-icon>
      <span>Pending Order</span>
      <!-- 
        <span >
        {{this.apiHit.countLeftNavigation?.total_pending_data ?
        (this.apiHit.countLeftNavigation?.total_pending_data) : 0}}
      </span> -->
    </a>
  </li>
  <li>
    <a matRipple routerLink="incentive-list" routerLinkActive="active">
      <mat-icon>person</mat-icon>
      <span>Retailer Incentive</span>
      <!-- 
        <span >
        {{this.apiHit.countLeftNavigation?.total_retailer_incentive ?
        (this.apiHit.countLeftNavigation?.total_retailer_incentive) : null}}
      </span> -->
    </a>
  </li>
  <li >
    <a matRipple routerLink="billing-list" routerLinkActive="active" >
      <mat-icon>receipt</mat-icon>
      <span>Billing Detail</span>
      <!-- 
        <span >
        {{this.apiHit.countLeftNavigation?.total_billing ?
        (this.apiHit.countLeftNavigation?.total_billing) : null}}
      </span> -->
    </a>
  </li>
  <!-- <li>
    <a  routerLink="companyStock-list" routerLinkActive="active">
      <mat-icon>settings</mat-icon>
      <span>Company Stock</span>
    </a>
  </li> -->
  </ul>
 
</div>


