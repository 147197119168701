<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>retailer</h2>
                    <p *ngIf="data_val.user_type == 'System_user' || data_val.login_type == 'distributor'">{{reatilerDetailData.length}} retailer Available</p>
                    <p *ngIf="data_val.user_type == 'approval_user'">{{reatilerDetailData1.length}} retailer Available
                    </p>
                </div>
                <div class="cs-table ml16 " style="position: fixed; left: 27%;overflow-y: auto;height: 60px;" *ngIf="data_val.login_type != 'distributor'">
                    <!-- Table Head Start-->
                    <figure class="table-head">
                        <section class="td_cell" style="background-color:rgb(141, 140, 139); color:white;padding:0;">
                            <main>
                                <div class="div_cell mmw110">
                                    <p class="text-center">Order < 6 Sets<p>
                                </div>
                                <div class="div_cell mmw110">
                                    <p class="text-center">Order > 10 Sets<p>
                                </div>
                                <div class="div_cell">
                                    <p class="text-center">Order between 6-10 Sets<p>
                                </div>
                            </main>
                        </section>
                    </figure>
                    <!-- table head end -->

                    <section class="td_cell" style="padding:0;">
                        <ng-container *ngIf="!loader">
                            <main>
                                <div class="div_cell mmw110 stock-alert">
                                    Red
                                </div>
                                <div class="div_cell mmw110 stock-green"  >
                                    Green
                                </div>
                                <div class="div_cell stock-pending">
                                    Yellow
                                </div>
                            </main>
                        </ng-container>
                    </section>
                </div>
                <div class="left-auto" *ngIf="data_val.user_type=='System_user' ">
                    <div class="cs-btn mt0" *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                        <a mat-button *ngIf=" datas.module_name== 'Retailer' && datas.add== 1"
                            routerLink="../retailer-add">Add new</a>
                    </div>
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">

            <mat-tab-group class="tab-group flat tab-group-list" mat-align-tabs="start"
                [selectedIndex]="matTabValue == '' ? 0 : matTabValue == 'Pending' ? 1 : matTabValue == 'Verified' ? 2 :
                matTabValue == 'Reject' ? 3 : 4"
                (selectedTabChange)="tabClick($event)" *ngIf="data_val.user_type=='System_user'">


                <mat-tab label="">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">inbox</mat-icon>
                        All
                        <span class="badge">{{totalCount ? totalCount : 0}}</span>
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mmw80">Created By</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw140">Contact Person</div>
                                    <div class="div_cell mmw90">Mobile</div>
                                    <div class="div_cell mmw170">State</div>
                                    <div class="div_cell mmw170">District</div>
                                    <div class="div_cell mmw170">Distributor & Code</div>
                                    <div class="div_cell mmw80  text-center">Mechanic</div>
                                    <!-- <div class="div_cell mmw95  text-right">Sales Point</div> -->
                                    <div class="div_cell mmw95  text-right">Wallet Balance</div>
                                    <div class="div_cell mmw180">Last Three Month Order Quantity</div>

                                    <div class="div_cell mmw80">ASM Status</div>
                                    <div class="div_cell mmw80">ZSM Status</div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker.open()">
                                                <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                                    placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                                    (dateChange)="onDate($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="created_name"
                                                [(ngModel)]="filterSearch.created_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="dr_name"
                                                [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="contact_name"
                                                [(ngModel)]="filterSearch.contact_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="state_name"
                                                [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="district_name"
                                                [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="distributor_assigned_name"
                                                [(ngModel)]="filterSearch.distributor_assigned_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80  ">
                                    </div>
                                    <!-- <div class="div_cell mmw95 ">
                                    </div> -->
                                    
                                    <div class="div_cell mmw95 ">
                                    </div>
                                    <div class="div_cell mmw180">
                                        <!-- <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="zsm_status"
                                                [(ngModel)]="filterSearch.zsm_status" (keyup.enter)="retailerList(1);">
                                        </div> -->
                                    </div>
                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="asm_status"
                                                [(ngModel)]="filterSearch.asm_status" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>

                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="zsm_status"
                                                [(ngModel)]="filterSearch.zsm_status" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                  

                                    <div class="div_cell text-center mmw70 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                                (click)="retailerList(1)"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData()"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->

                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <!-- Loop Data -->
                                <main *ngFor="let data of reatilerDetailData; let i=index" [ngClass]="{'stock-alert':data.row_color=='red', 'stock-green':data.row_color=='Green','stock-pending':data.row_color=='yellow'}">
                                    <div class="div_cell mmw50 ac" >
                                        <strong
                                            *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                        <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                    </div>
                                    <div class="div_cell mmw110" >{{(data.date_created) | date: 'd MMM y'}}</div>

                                    <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name |
                                        titlecase) :'N/A'}}</div>

                                    <!-- <div class="div_cell mmw80">{{data.createdBy_name ? data.createdBy_name:'N/A'}}</div> -->
                                    <div class="div_cell mw150" ><a class="link-btn" 
                                            routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ? (data.dr_name | titlecase):'N/A'}}</a></div>
                                    <div class="div_cell mmw140" >{{data.contact_name ? (data.contact_name | titlecase ):'N/A'}}</div>
                                    <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                                    <div class="div_cell mmw170">{{data.state_name ? (data.state_name | titlecase):'N/A'}}</div>
                                    <div class="div_cell mmw170">
                                        {{data.district_name ? (data.district_name | titlecase):'N/A'}}</div>

                                    <!-- Ng For On Distributor Array Start  -->

                                    <div class="div_cell mmw170 no-padding">
                                        <main class="mmw170 no-hv" *ngFor="let data2 of data.distributorData">
                                            <div class="div_cell mmw170">{{ data2.dr_name ? (data2.dr_name | titlecase) : 'N/A' }} -
                                                {{data2.dr_code ? data2.dr_code:'N/A'}}</div>
                                        </main>
                                        <main class="mmw170 no-hv" *ngIf="data.distributorData.length == 0">
                                            <div class="div_cell mmw170">N/A</div>
                                        </main>
                                    </div>

                                    <!-- Ng For On Distributor Array End  -->

                                    <div class="div_cell mmw80 ac">{{data.mechanic_assign.length ?
                                        data.mechanic_assign.length:'0'}}</div>
                                    <!-- <div class="div_cell mmw95  afe">{{data.total_sales_point ? data.total_sales_point:'0'}}</div> -->

                                    <div class="div_cell mmw95  afe">{{data.wallet_balance ? data.wallet_balance:'0'}}
                                    </div>
                                    <div class="div_cell mmw180" style="color:#000000;align-items: center; ">
                                        {{data.total_order_quantity ? data.total_order_quantity :'N/A'}}
                                    </div>

                                    <div class="div_cell mmw80">
                                        <strong *ngIf="data.ASM_status=='Pending'" class="yellow-clr">{{data.ASM_status
                                            ? (data.ASM_status | titlecase) : 'N/A' }}</strong>
                                        <strong *ngIf="data.ASM_status=='Verified'" class="green-clr">{{data.ASM_status
                                            ? (data.ASM_status | titlecase): 'N/A' }}</strong>
                                        <strong *ngIf="data.ASM_status=='Reject'" class="red-clr">{{data.ASM_status ?
                                            (data.ASM_status | titlecase): 'N/A' }}</strong>
                                        <strong *ngIf="data.ASM_status=='Suspect'" class="sky-clr">{{data.ASM_status ?
                                            (data.ASM_status | titlecase):'N/A'}}</strong>

                                        <span *ngIf="data.ASM_status==''">N/A</span>

                                    </div>

                                    <div class="div_cell mmw80">
                                        <strong *ngIf="data.ZSM_status=='Pending'" class="yellow-clr">{{data.ZSM_status
                                            ? (data.ZSM_status | titlecase ):'N/A'}}</strong>
                                        <strong *ngIf="data.ZSM_status=='Verified'" class="green-clr">{{data.ZSM_status
                                            ? (data.ZSM_status | titlecase ):'N/A'}}</strong>
                                        <strong *ngIf="data.ZSM_status=='Reject'" class="red-clr">{{data.ZSM_status ?
                                            (data.ZSM_status | titlecase ):'N/A'}}</strong>
                                        <strong *ngIf="data.ZSM_status=='Suspect'" class="sky-clr">{{data.ZSM_status ?
                                            (data.ZSM_status | titlecase ):'N/A'}}</strong>

                                        <span *ngIf="data.ZSM_status==''">N/A</span>

                                    </div>
                                  

                                    <div class="div_cell ac mmw70">
                                        <div class="cs-action">

                                            <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">

                                                <a class="ac-red"
                                                    *ngIf="datas.module_name== 'Retailer' && datas.delete==1" matRipple
                                                    (click)="deleteReatilerData(data.id)" matTooltip="Delete"
                                                    matTooltipPosition="below"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </span>
                                        </div>
                                    </div>

                                </main>

                                <app-not-found *ngIf="reatilerDetailData.length == 0" class="fix"></app-not-found>
                                <!-- Loop Data -->
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Start  -->
                                <main *ngFor="let data of skeleton">
                                    <div class="div_cell mmw50">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw95"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw95">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw70">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton End -->
                            </ng-container>
                        </section>

                    </div>
                </mat-tab>


                <mat-tab label="Pending">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">history</mat-icon>
                        Pending
                        <span class="badge">{{pendingStatusCount}}</span>
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mmw80">Created By</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw140">Contact Person</div>
                                    <div class="div_cell mmw90">Mobile</div>
                                    <div class="div_cell mmw170">State</div>
                                    <div class="div_cell mmw170">District</div>
                                    <div class="div_cell mmw170">Distributor & Code</div>
                                    <div class="div_cell mmw80  text-center">Mechanic</div>
                                    <!-- <div class="div_cell mmw100 text-right">Sales Point</div> -->
                                    <div class="div_cell mmw100 text-right">Wallet Balance</div>
                                    <div class="div_cell mmw80">ASM Status</div>
                                    <div class="div_cell mmw80">ZSM Status</div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker2.open()">
                                                <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                                    placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                                    (dateChange)="onDate($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="created_name"
                                                [(ngModel)]="filterSearch.created_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="dr_name"
                                                [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="contact_name"
                                                [(ngModel)]="filterSearch.contact_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="state_name"
                                                [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="district_name"
                                                [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="distributor_assigned_name"
                                                [(ngModel)]="filterSearch.distributor_assigned_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80  ">
                                    </div>
                                    <!-- <div class="div_cell mmw100">
                                    </div> -->
                                    <div class="div_cell mmw100">
                                    </div>
                                    <div class="div_cell mmw80">

                                    </div>

                                    <div class="div_cell mmw80">

                                    </div>

                                    <div class="div_cell text-center mmw70 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search"
                                                matTooltipPosition="below"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData()"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->

                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <ng-container
                                    *ngFor="let data of reatilerDetailData | myfilter:{status:'Pending',type:'status'}; let i=index">

                                    <!-- Loop Data -->
                                    <main *ngIf="data.status=='Pending'" [ngClass]="{'stock-alert':data.row_color=='red', 'stock-green':data.row_color=='Green','stock-pending':data.row_color=='yellow'}">
                                        <div class="div_cell mmw50 ac">
                                            <strong
                                                *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                            <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                        </div>
                                        <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>

                                        <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name |
                                            titlecase) :'N/A'}}</div>


                                        <div class="div_cell mw150"><a class="link-btn" mat-flat-button
                                                routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ?
                                                data.dr_name:'N/A'}}</a></div>
                                        <div class="div_cell mmw140">{{data.contact_name ? data.contact_name:'N/A'}}
                                        </div>
                                        <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                                        <div class="div_cell mmw170">{{data.state_name ? data.state_name:'N/A'}}</div>
                                        <div class="div_cell mmw170">
                                            {{data.district_name ? data.district_name:'N/A'}}</div>

                                        <!-- Ng For On Distributor Array Start  -->

                                        <div class="div_cell mmw170 no-padding">
                                            <main class="mmw170 no-hv" *ngFor="let data2 of data.distributorData">
                                                <div class="div_cell mmw170">{{ data2.dr_name ? data2.dr_name : 'N/A' }}
                                                    - {{data2.dr_code ? data2.dr_code:'N/A'}}</div>
                                            </main>
                                            <main class="mmw170 no-hv" *ngIf="data.distributorData.length == 0">
                                                <div class="div_cell mmw170">N/A </div>
                                            </main>
                                        </div>

                                        <!-- Ng For On Distributor Array End  -->

                                        <div class="div_cell mmw80 ac">{{data.mechanic_assign.length ?
                                            data.mechanic_assign.length:'0'}}</div>
                                        <!-- <div class="div_cell mmw100 afe"> {{data.total_sales_point ? data.total_sales_point:'0'}}</div> -->

                                        <div class="div_cell mmw100 afe"> {{data.wallet_balance ?
                                            data.wallet_balance:'0'}}</div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="yellow-clr" >{{data.ASM_status }}</strong> -->
                                            <strong *ngIf="data.ASM_status=='Pending'"
                                                class="yellow-clr">{{data.ASM_status ? data.ASM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ASM_status=='Verified'"
                                                class="green-clr">{{data.ASM_status ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Reject'" class="red-clr">{{data.ASM_status
                                                ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Suspect'" class="sky-clr">{{data.ASM_status
                                                ? data.ASM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ASM_status==''">N/A</span>

                                        </div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="yellow-clr">{{data.ZSM_status}}</strong> -->
                                            <strong *ngIf="data.ZSM_status=='Pending'"
                                                class="yellow-clr">{{data.ZSM_status ? data.ZSM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Verified'"
                                                class="green-clr">{{data.ZSM_status ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Reject'" class="red-clr">{{data.ZSM_status
                                                ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Suspect'" class="sky-clr">{{data.ZSM_status
                                                ? data.ZSM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ZSM_status==''">N/A</span>

                                        </div>

                                        <div class="div_cell ac mmw70">
                                            <div class="cs-action">
                                                <a class="ac-red" matRipple (click)="deleteReatilerData(data.id)"
                                                    matTooltip="Delete" matTooltipPosition="below"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </div>
                                        </div>
                                    </main>
                                    <!-- Loop Data -->
                                </ng-container>
                                <app-not-found *ngIf="pendingStatusCount==0" class="fix"></app-not-found>
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Start  -->
                                <main *ngFor="let data of skeleton">
                                    <div class="div_cell mmw50">S.No.</div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw70">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton End -->
                            </ng-container>
                        </section>
                    </div>
                </mat-tab>


                <mat-tab label="Verified">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">verified</mat-icon>
                        Verified
                        <span class="badge">{{verifiedStatusCount}}</span>
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mmw80">Created By</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw140">Contact Person</div>
                                    <div class="div_cell mmw90">Mobile</div>
                                    <div class="div_cell mmw170">State</div>
                                    <div class="div_cell mmw170">District</div>
                                    <div class="div_cell mmw170">Distributor & Code</div>
                                    <div class="div_cell mmw80  text-center">Mechanic</div>
                                    <!-- <div class="div_cell mmw100 text-right">Sales Point</div> -->
                                    <div class="div_cell mmw100 text-right">Wallet Balance</div>
                                    <div class="div_cell mmw80">ASM Status</div>
                                    <div class="div_cell mmw80">ZSM Status</div>
                                    <!-- <div class="div_cell mmw80">Status</div> -->
                                    <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker3.open()">
                                                <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                                    placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                                    (dateChange)="onDate($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker3">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker3></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="created_name"
                                                [(ngModel)]="filterSearch.created_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="dr_name"
                                                [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="contact_name"
                                                [(ngModel)]="filterSearch.contact_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="state_name"
                                                [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="district_name"
                                                [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="distributor_assigned_name"
                                                [(ngModel)]="filterSearch.distributor_assigned_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80  ">&nbsp;</div>
                                    <!-- <div class="div_cell mmw100">&nbsp;</div> -->
                                    <div class="div_cell mmw100">&nbsp;</div>

                                    <div class="div_cell mmw80">&nbsp;</div>
                                    <div class="div_cell mmw80">&nbsp;</div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search"
                                                matTooltipPosition="below"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData()"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->

                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <ng-container
                                    *ngFor="let data of reatilerDetailData | myfilter:{status:'Verified',type:'status'}; let i=index">
                                    <main *ngIf="data.status == 'Verified'" [ngClass]="{'stock-alert':data.row_color=='red', 'stock-green':data.row_color=='Green','stock-pending':data.row_color=='yellow'}">
                                        <div class="div_cell mmw50 ac">
                                            <strong
                                                *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                            <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                        </div>
                                        <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>

                                        <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name |
                                            titlecase) :'N/A'}}</div>
                                        <div class="div_cell mw150"><a class="link-btn" mat-flat-button
                                                routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ?
                                                data.dr_name:'N/A'}}</a></div>
                                        <div class="div_cell mmw140">{{data.contact_name ? data.contact_name:'N/A'}}
                                        </div>
                                        <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                                        <div class="div_cell mmw170">{{data.state_name ? data.state_name:'N/A'}}</div>
                                        <div class="div_cell mmw170">
                                            {{data.district_name ? data.district_name:'N/A'}}</div>
                                        <div class="div_cell mmw170 no-padding">
                                            <main class="mmw170 no-hv"
                                                *ngFor="let data2 of data.distributorData; let j =index;">
                                                <div class="div_cell mmw170">{{ data2.dr_name ? data2.dr_name:'N/A' }} -
                                                    {{data2.dr_code ? data2.dr_code:'N/A'}}</div>
                                            </main>
                                            <main class="mmw170 no-hv" *ngIf="data.distributorData.length == 0">
                                                <div class="div_cell mmw170">N/A </div>
                                            </main>
                                        </div>
                                        <div class="div_cell mmw80 ac">{{data.mechanic_assign.length ?
                                            data.mechanic_assign.length:'0'}}</div>
                                        <!-- <div class="div_cell mmw100 afe"> {{data.total_sales_point ? data.total_sales_point:'0'}}</div> -->

                                        <div class="div_cell mmw100 afe"> {{data.wallet_balance ?
                                            data.wallet_balance:'0'}}</div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="green-clr">{{data.ASM_status}}</strong> -->
                                            <strong *ngIf="data.ASM_status=='Pending'"
                                                class="yellow-clr">{{data.ASM_status ? data.ASM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ASM_status=='Verified'"
                                                class="green-clr">{{data.ASM_status ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Reject'" class="red-clr">{{data.ASM_status
                                                ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Suspect'" class="sky-clr">{{data.ASM_status
                                                ? data.ASM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ASM_status==''">N/A</span>
                                        </div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="green-clr">{{data.ZSM_status}}</strong> -->
                                            <strong *ngIf="data.ZSM_status=='Pending'"
                                                class="yellow-clr">{{data.ZSM_status ? data.ZSM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Verified'"
                                                class="green-clr">{{data.ZSM_status ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Reject'" class="red-clr">{{data.ZSM_status
                                                ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Suspect'" class="sky-clr">{{data.ZSM_status
                                                ? data.ZSM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ZSM_status==''">N/A</span>
                                        </div>

                                        <div class="div_cell ac mmw70">
                                            <div class="cs-action">
                                                <a class="ac-red" matRipple (click)="deleteReatilerData(data.id)"
                                                    matTooltip="Delete" matTooltipPosition="below"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </div>
                                        </div>
                                    </main>
                                </ng-container>
                                <app-not-found *ngIf="verifiedStatusCount==0" class="fix"></app-not-found>
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Start  -->
                                <main *ngFor="let data of skeleton">
                                    <div class="div_cell mmw50">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>

                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw80">Status</div> -->
                                    <div class="div_cell mmw70 ">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton End -->
                            </ng-container>

                        </section>

                    </div>
                </mat-tab>


                <mat-tab label="Reject">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">cancel_schedule_send</mat-icon>
                        Reject
                        <span class="badge">{{rejectStatusCount}}</span>
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mmw80">Created By</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw140">Contact Person</div>
                                    <div class="div_cell mmw90">Mobile</div>
                                    <div class="div_cell mmw170">State </div>
                                    <div class="div_cell mmw170">District</div>
                                    <div class="div_cell mmw170">Distributor & Code</div>
                                    <div class="div_cell mmw80  text-center">Mechanic</div>
                                    <!-- <div class="div_cell mmw100 text-right">Sales Point</div> -->
                                    <div class="div_cell mmw100 text-right">Wallet Balance</div>
                                    <div class="div_cell mmw80">ASM Status</div>
                                    <div class="div_cell mmw80">ZSM Status</div>
                                    <!-- <div class="div_cell mmw60">Status</div> -->
                                    <div class="div_cell mmw200">Reason</div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker4.open()">
                                                <input type="text" matInput [matDatepicker]="picker4" [max]="date"
                                                    placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                                    (dateChange)="onDate($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker4">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker4></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="created_name"
                                                [(ngModel)]="filterSearch.created_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="dr_name"
                                                [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="contact_name"
                                                [(ngModel)]="filterSearch.contact_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="state_name"
                                                [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="district_name"
                                                [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="distributor_assigned_name"
                                                [(ngModel)]="filterSearch.distributor_assigned_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80  ">
                                    </div>
                                    <!-- <div class="div_cell mmw100">
                                    </div> -->
                                    <div class="div_cell mmw100">
                                    </div>
                                    <!-- <div class="div_cell mmw60"></div> -->


                                    <div class="div_cell mmw80">

                                    </div>

                                    <div class="div_cell mmw80">

                                    </div>

                                    <div class="div_cell mmw200"></div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search"
                                                matTooltipPosition="below"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData()"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->



                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <ng-container
                                    *ngFor="let data of reatilerDetailData | myfilter:{status:'Reject',type:'status'}; let i=index">
                                    <!-- Loop Data -->
                                    <main *ngIf="data.status=='Reject' " [ngClass]="{'stock-alert':data.row_color=='red', 'stock-green':data.row_color=='Green','stock-pending':data.row_color=='yellow'}">
                                        <div class="div_cell mmw50 ac">
                                            <strong
                                                *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                            <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                        </div>
                                        <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>

                                        <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name |
                                            titlecase) :'N/A'}}</div>

                                        <div class="div_cell mw150"><a class="link-btn" mat-flat-button
                                                routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ?
                                                data.dr_name:'N/A'}}</a></div>
                                        <div class="div_cell mmw140">{{data.contact_name ? data.contact_name:'N/A'}}
                                        </div>
                                        <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                                        <div class="div_cell mmw170">{{data.state_name ? data.state_name:'N/A'}} -
                                            {{data.district_name ? data.district_name:'N/A'}}</div>

                                        <!-- Ng For On Distributor Array Start  -->

                                        <div class="div_cell mmw170 no-padding">
                                            <main class="mmw170 no-hv" *ngFor="let data2 of data.distributorData">
                                                <div class="div_cell mmw170">{{ data2.dr_name ? data2.dr_name : 'N/A' }}
                                                    - {{data2.dr_code ? data2.dr_code:'N/A'}}</div>
                                            </main>
                                            <main class="mmw170 no-hv" *ngIf="data.distributorData.length == 0">
                                                <div class="div_cell mmw170">N/A </div>
                                            </main>
                                        </div>

                                        <!-- Ng For On Distributor Array End  -->

                                        <div class="div_cell mmw80 ac">{{data.mechanic_assign.length ?
                                            data.mechanic_assign.length:'0'}}</div>
                                        <!-- <div class="div_cell mmw100 afe"> {{data.total_sales_point ? data.total_sales_point:'0'}}</div> -->

                                        <div class="div_cell mmw100 afe"> {{data.wallet_balance ?
                                            data.wallet_balance:'0'}}</div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="red-clr">{{data.ASM_status}}</strong> -->
                                            <strong *ngIf="data.ASM_status=='Pending'"
                                                class="yellow-clr">{{data.ASM_status ? data.ASM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ASM_status=='Verified'"
                                                class="green-clr">{{data.ASM_status ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Reject'" class="red-clr">{{data.ASM_status
                                                ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Suspect'" class="sky-clr">{{data.ASM_status
                                                ? data.ASM_status:'N/A'}}</strong>


                                            <span *ngIf="data.ASM_status==''">N/A</span>

                                        </div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="red-clr">{{data.ZSM_status}}</strong> -->
                                            <strong *ngIf="data.ZSM_status=='Pending'"
                                                class="yellow-clr">{{data.ZSM_status ? data.ZSM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Verified'"
                                                class="green-clr">{{data.ZSM_status ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Reject'" class="red-clr">{{data.ZSM_status
                                                ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Suspect'" class="sky-clr">{{data.ZSM_status
                                                ? data.ZSM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ZSM_status==''">N/A</span>

                                        </div>

                                        <div class="div_cell mmw200">{{data.status_reason ? data.status_reason:'N/A'}}
                                        </div>
                                        <div class="div_cell ac mmw70">
                                            <div class="cs-action">
                                                <a class="ac-red" matRipple (click)="deleteReatilerData(data.id)"
                                                    matTooltip="Delete" matTooltipPosition="below"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </div>
                                        </div>
                                    </main>
                                    <!-- Loop Data -->
                                </ng-container>
                                <app-not-found *ngIf="rejectStatusCount==0" class="fix"></app-not-found>
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Start  -->
                                <main *ngFor="let data of skeleton">
                                    <div class="div_cell mmw50">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>

                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw200">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw70 ">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton End -->
                            </ng-container>


                        </section>

                    </div>
                </mat-tab>


                <mat-tab label="Suspect">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">help</mat-icon>
                        Suspect
                        <span class="badge">{{suspectStatusCount}}</span>
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <ng-container>
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mmw110">Date</div>
                                        <div class="div_cell mmw80">Created By</div>
                                        <div class="div_cell mw150">Company Name</div>
                                        <div class="div_cell mmw140">Contact Person</div>
                                        <div class="div_cell mmw90">Mobile</div>
                                        <div class="div_cell mmw170">State </div>
                                        <div class="div_cell mmw170">District</div>
                                        <div class="div_cell mmw170">Distributor & Code</div>
                                        <div class="div_cell mmw80  text-center">Mechanic</div>
                                        <!-- <div class="div_cell mmw100 text-right">Sales Point</div> -->
                                        <div class="div_cell mmw100 text-right">Wallet Balance</div>
                                        <div class="div_cell mmw80">ASM Status</div>
                                        <div class="div_cell mmw80">ZSM Status</div>
                                        <div class="div_cell mmw200">Reason</div>
                                        <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                    </main>
                                </ng-container>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker5.open()">
                                                <input type="text" matInput [matDatepicker]="picker5" [max]="date"
                                                    placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                                    (dateChange)="onDate($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker5">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker5></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="created_name"
                                                [(ngModel)]="filterSearch.created_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="dr_name"
                                                [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="contact_name"
                                                [(ngModel)]="filterSearch.contact_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="state_name"
                                                [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="district_name"
                                                [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="distributor_assigned_name"
                                                [(ngModel)]="filterSearch.distributor_assigned_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw80  ">
                                    </div>
                                    <!-- <div class="div_cell mmw100">
                                    </div> -->
                                    <div class="div_cell mmw100">
                                    </div>
                                    <div class="div_cell mmw80"></div>
                                    <div class="div_cell mmw80"></div>
                                    <div class="div_cell mmw200"></div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search"
                                                matTooltipPosition="below"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData()"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->



                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <ng-container
                                    *ngFor="let data of reatilerDetailData | myfilter:{status:'Suspect',type:'status'}; let i=index" >
                                    <!-- Loop Data -->
                                    <main *ngIf="data.status=='Suspect'" [ngClass]="{'stock-alert':data.row_color=='red', 'stock-green':data.row_color=='Green','stock-pending':data.row_color=='yellow'}">
                                        <div class="div_cell mmw50 ac">
                                            <strong
                                                *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                            <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                        </div>
                                        <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                                        <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name |
                                            titlecase) :'N/A'}}</div>

                                        <div class="div_cell mw150"><a class="link-btn" mat-flat-button
                                                routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ?
                                                data.dr_name:'N/A'}}</a></div>
                                        <div class="div_cell mmw140">{{data.contact_name ? data.contact_name:'N/A'}}
                                        </div>
                                        <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                                        <div class="div_cell mmw170">{{data.state_name ? data.state_name:'N/A'}}</div>
                                        <div class="div_cell mmw170">
                                            {{data.district_name ? data.district_name:'N/A'}}</div>

                                        <!-- Ng For On Distributor Array Start  -->

                                        <div class="div_cell mmw170 no-padding">
                                            <main class="mmw170 no-hv" *ngFor="let data2 of data.distributorData">
                                                <div class="div_cell mmw150">{{ data2.dr_name ? data2.dr_name : 'N/A' }}
                                                    - {{data2.dr_code ? data2.dr_code:'N/A'}}</div>
                                            </main>
                                            <main class="mmw170 no-hv" *ngIf="data.distributorData.length == 0">
                                                <div class="div_cell mmw170">N/A </div>
                                            </main>
                                        </div>

                                        <!-- Ng For On Distributor Array End  -->


                                        <div class="div_cell mmw80 ac">{{data.mechanic_assign.length ?
                                            data.mechanic_assign.length:'0'}}</div>
                                        <!-- <div class="div_cell mmw100 afe"> {{data.total_sales_point ? data.total_sales_point:'0'}}</div> -->

                                        <div class="div_cell mmw100 afe"> {{data.wallet_balance ?
                                            data.wallet_balance:'0'}}</div>
                                        <div class="div_cell mmw80">
                                            <!-- <strong class="sky-clr">{{data.ASM_status}}</strong> -->
                                            <strong *ngIf="data.ASM_status=='Pending'"
                                                class="yellow-clr">{{data.ASM_status ? data.ASM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ASM_status=='Verified'"
                                                class="green-clr">{{data.ASM_status ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Reject'" class="red-clr">{{data.ASM_status
                                                ? data.ASM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ASM_status=='Suspect'" class="sky-clr">{{data.ASM_status
                                                ? data.ASM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ASM_status==''">N/A</span>

                                        </div>

                                        <div class="div_cell mmw80">
                                            <!-- <strong class="sky-clr">{{data.ZSM_status}}</strong> -->
                                            <strong *ngIf="data.ZSM_status=='Pending'"
                                                class="yellow-clr">{{data.ZSM_status ? data.ZSM_status : 'N/A'
                                                }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Verified'"
                                                class="green-clr">{{data.ZSM_status ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Reject'" class="red-clr">{{data.ZSM_status
                                                ? data.ZSM_status: 'N/A' }}</strong>
                                            <strong *ngIf="data.ZSM_status=='Suspect'" class="sky-clr">{{data.ZSM_status
                                                ? data.ZSM_status:'N/A'}}</strong>

                                            <span *ngIf="data.ZSM_status==''">N/A</span>

                                        </div>
                                        <div class="div_cell mmw200">{{data.status_reason ? data.status_reason:'N/A'}}
                                        </div>
                                        <div class="div_cell ac mmw70">
                                            <div class="cs-action">
                                                <a class="ac-red" matRipple (click)="deleteReatilerData(data.id)"
                                                    matTooltip="Delete" matTooltipPosition="below"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </div>
                                        </div>
                                    </main>
                                    <!-- Loop Data -->
                                </ng-container>
                                <app-not-found *ngIf="suspectStatusCount==0" class="fix"></app-not-found>
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Start  -->
                                <main *ngFor="let data of skeleton">
                                    <div class="div_cell mmw50">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <!-- <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>

                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw80">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw200">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw70">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton End -->
                            </ng-container>

                        </section>

                    </div>
                </mat-tab>

            </mat-tab-group>
          
                    <div class="cs-table" *ngIf="data_val.login_type == 'distributor'">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw140">Contact Person</div>
                                    <div class="div_cell mmw90">Mobile</div>
                                    <div class="div_cell mmw170">State</div>
                                    <div class="div_cell mmw170">District</div>
                                    <!-- <div class="div_cell mmw95  text-right">Sales Point</div> -->
                                    <div class="div_cell mmw95  text-right">Wallet Balance</div>
                                    <div class="div_cell mmw180">Last Three Month Order Quantity</div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                </main>
                            </section>
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker.open()">
                                                <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                                    placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                                    (dateChange)="onDate($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
               
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="dr_name"
                                                [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="contact_name"
                                                [(ngModel)]="filterSearch.contact_name"
                                                (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="mobile_no"
                                                [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="state_name"
                                                [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="district_name"
                                                [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                        </div>
                                    </div>
                                    <!-- <div class="div_cell mmw95 ">
                                    </div> -->
                                    
                                    <div class="div_cell mmw95 ">
                                    </div>
                                    <div class="div_cell mmw180">
                                        <!-- <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="zsm_status"
                                                [(ngModel)]="filterSearch.zsm_status" (keyup.enter)="retailerList(1);">
                                        </div> -->
                                    </div>
                                  

                                    <div class="div_cell text-center mmw70 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                                (click)="retailerList(1)"><i class="material-icons">search</i></a>
                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData()"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>
                        <!-- Table Head End -->

                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <!-- Loop Data -->
                                <main *ngFor="let data of reatilerDetailData; let i=index">
                                    <div class="div_cell mmw50 ac" >
                                        <strong
                                            *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                        <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                                    </div>
                                    <div class="div_cell mmw110" >{{(data.date_created) | date: 'd MMM y'}}</div>

                                

                                    <!-- <div class="div_cell mmw80">{{data.createdBy_name ? data.createdBy_name:'N/A'}}</div> -->
                                    <div class="div_cell mw150" ><a class="link-btn" 
                                            routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ? (data.dr_name | titlecase):'N/A'}}</a></div>
                                    <div class="div_cell mmw140" >{{data.contact_name ? (data.contact_name | titlecase ):'N/A'}}</div>
                                    <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                                    <div class="div_cell mmw170">{{data.state_name ? (data.state_name | titlecase):'N/A'}}</div>
                                    <div class="div_cell mmw170">
                                        {{data.district_name ? (data.district_name | titlecase):'N/A'}}</div>

                                    <!-- Ng For On Distributor Array Start  -->

                                  

                                    <!-- Ng For On Distributor Array End  -->
                                    <!-- <div class="div_cell mmw95  afe">{{data.total_sales_point ? data.total_sales_point:'0'}}</div> -->

                                    <div class="div_cell mmw95  afe">{{data.wallet_balance ? data.wallet_balance:'0'}}
                                    </div>
                                    <div class="div_cell mmw180" style="color:#000000;align-items: center; ">
                                        {{data.total_order_quantity ? data.total_order_quantity :'N/A'}}
                                    </div>

                                  

                                    <div class="div_cell ac mmw70">
                                        <div class="cs-action">

                                            <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">

                                                <a class="ac-red"
                                                    *ngIf="datas.module_name== 'Retailer' && datas.delete==1" matRipple
                                                    (click)="deleteReatilerData(data.id)" matTooltip="Delete"
                                                    matTooltipPosition="below"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </span>
                                        </div>
                                    </div>

                                </main>

                                <app-not-found *ngIf="reatilerDetailData.length == 0" class="fix"></app-not-found>
                                <!-- Loop Data -->
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Start  -->
                                <main *ngFor="let data of skeleton">
                                    <div class="div_cell mmw50">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                        
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw140">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw170">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>

                                    <!-- <div class="div_cell mmw95"><p class="skeleton wp100">&nbsp;</p></div> -->
                                    <div class="div_cell mmw95">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw70">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton End -->
                            </ng-container>
                        </section>

                    </div>


            <mat-tab-group class="tab-group flat tab-group-list" mat-align-tabs="start"
                (selectedTabChange)="tabClick($event)" [selectedIndex]="matTabValue == '' ? 0 : matTabValue == 'Pending' ? 1 : matTabValue == 'Verified' ? 2 :
                matTabValue == 'Reject' ? 3 : 4" *ngIf="data_val.user_type=='approval_user'">

                <!-- Retailer All List Start -->

                <mat-tab label="">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">inbox</mat-icon>
                        All
                        <span class="badge">{{totalCount1 ? totalCount1 : 0}}</span>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Pending">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">history</mat-icon>
                        Pending
                        <span class="badge">{{pendingStatusCount1}}</span>
                    </ng-template>
                </mat-tab>


                <mat-tab label="Verified">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">verified</mat-icon>
                        Verified
                        <span class="badge">{{verifiedStatusCount1}}</span>
                    </ng-template>
                </mat-tab>


                <mat-tab label="Reject">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>

                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">cancel_schedule_send</mat-icon>
                        Reject
                        <span class="badge">{{rejectStatusCount1}}</span>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Suspect">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">help</mat-icon>
                        Suspect
                        <span class="badge">{{suspectStatusCount1}}</span>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>



            <div class="cs-table" *ngIf="data_val.user_type=='approval_user'">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date</div>
                            <div class="div_cell mmw80">Created By</div>
                            <div class="div_cell mw150">Company Name</div>
                            <div class="div_cell mmw140">Contact Person</div>
                            <div class="div_cell mmw90">Mobile</div>
                            <div class="div_cell mmw170">State </div>
                            <div class="div_cell mmw170">District</div>
                            <div class="div_cell mmw170">Distributor & Code</div>
                            <div class="div_cell mmw80" *ngIf="data_val.approval_type=='asm'">ASM Status</div>
                            <div class="div_cell mmw80" *ngIf="data_val.approval_type=='zsm'">ZSM Status</div>
                            <div class="div_cell mmw200" *ngIf="matTabValue=='Reject' || matTabValue=='Suspect'">Reason
                            </div>
                            <div class="div_cell mmw80  text-center">Mechanic</div>
                            <div class="div_cell mmw95  text-right">Wallet Balance</div>
                            <div class="div_cell mmw95  text-right">Total Order Quantity</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="date" [(ngModel)]="filterSearch.date"
                                            (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw80">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_name"
                                        [(ngModel)]="filterSearch.created_name" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw150">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_name"
                                        [(ngModel)]="filterSearch.dr_name" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="contact_name"
                                        [(ngModel)]="filterSearch.contact_name" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mobile_no"
                                        [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw170">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="state_name"
                                        [(ngModel)]="filterSearch.state_name" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw170">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="district_name"
                                        [(ngModel)]="filterSearch.district_name" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw170">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="distributor_assigned_name"
                                        [(ngModel)]="filterSearch.distributor_assigned_name"
                                        (keyup.enter)="retailerList(1);">
                                </div>
                            </div>

                            <div class="div_cell mmw80" *ngIf="data_val.approval_type=='asm'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="asm_status"
                                        [(ngModel)]="filterSearch.asm_status" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>

                            <div class="div_cell mmw80" *ngIf="data_val.approval_type=='zsm'">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="zsm_status"
                                        [(ngModel)]="filterSearch.zsm_status" (keyup.enter)="retailerList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw200" *ngIf="matTabValue=='Reject' || matTabValue=='Suspect'">
                            </div>

                            <div class="div_cell mmw80">
                            </div>
                            <div class="div_cell text-center mmw95 af-bdr-right">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="retailerList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                            <div class="div_cell text-center mmw95 af-bdr-right">
                                <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="retailerList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>

                        </main>
                    </section>
                </figure>

                <section class="td_cell" >
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of reatilerDetailData1; let i=index" [ngClass]="{'stock-alert':data.row_color=='red', 'stock-green':data.row_color=='Green','stock-pending':data.row_color=='yellow'}">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber1">{{((pageNumber1-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber1 == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw80">{{data.createdBy_name ? (data.createdBy_name |
                                titlecase) :'N/A'}}</div>
                                <!--  mat-flat-button -->
                            <div class="div_cell mw150" ><a class="link-btn"
                                    routerLink="../retailer-detail/{{data.id}}">{{data.dr_name ?
                                    data.dr_name:'N/A'}}</a></div>
                            <div class="div_cell mmw140">{{data.contact_name ? data.contact_name:'N/A'}}</div>
                            <div class="div_cell mmw90">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                            <div class="div_cell mmw170">{{data.state_name ? data.state_name:'N/A'}}</div>
                            <div class="div_cell mmw170">
                                {{data.district_name ? data.district_name:'N/A'}}</div>
                            <div class="div_cell mmw170 no-padding">
                                <main class="mmw170 no-hv" *ngFor="let data2 of data.distributorData">
                                    <div class="div_cell mmw170">{{ data2.dr_name ? data2.dr_name : 'N/A' }} -
                                        {{data2.dr_code ? data2.dr_code:'N/A'}}</div>
                                </main>
                                <main class="mmw170 no-hv" *ngIf="data.distributorData.length == 0">
                                    <div class="div_cell mmw170">N/A</div>
                                </main>
                            </div>
                            <div class="div_cell mmw80" *ngIf="data_val.approval_type=='asm'">
                                <strong *ngIf="data.ASM_status=='Pending'" class="yellow-clr">{{data.ASM_status
                                    ? data.ASM_status : 'N/A' }}</strong>
                                <strong *ngIf="data.ASM_status=='Verified'" class="green-clr">{{data.ASM_status
                                    ? data.ASM_status: 'N/A' }}</strong>
                                <strong *ngIf="data.ASM_status=='Reject'" class="red-clr">{{data.ASM_status ?
                                    data.ASM_status: 'N/A' }}</strong>
                                <strong *ngIf="data.ASM_status=='Suspect'" class="sky-clr">{{data.ASM_status ?
                                    data.ASM_status:'N/A'}}</strong>
                                <span *ngIf="data.ASM_status==''">N/A</span>
                            </div>
                            <div class="div_cell mmw80" *ngIf="data_val.approval_type=='zsm'">
                                <strong *ngIf="data.ZSM_status=='Pending'" class="yellow-clr">{{data.ZSM_status
                                    ? data.ZSM_status:'N/A'}}</strong>
                                <strong *ngIf="data.ZSM_status=='Verified'" class="green-clr">{{data.ZSM_status
                                    ? data.ZSM_status:'N/A'}}</strong>
                                <strong *ngIf="data.ZSM_status=='Reject'" class="red-clr">{{data.ZSM_status ?
                                    data.ZSM_status:'N/A'}}</strong>
                                <strong *ngIf="data.ZSM_status=='Suspect'" class="sky-clr">{{data.ZSM_status ?
                                    data.ZSM_status:'N/A'}}</strong>
                                <span *ngIf="data.ZSM_status==''">N/A</span>
                            </div>
                            <ng-container *ngIf="matTabValue=='Reject' || matTabValue=='Suspect'">
                                <div class="div_cell mmw200" *ngIf="data_val.approval_type=='asm'">
                                    {{data.ASM_status_reason ? data.ASM_status_reason:'N/A'}}</div>
                                <div class="div_cell mmw200" *ngIf="data_val.approval_type=='zsm'">
                                    {{data.ZSM_status_reason ? data.ZSM_status_reason:'N/A'}}</div>
                            </ng-container>
                            <div class="div_cell mmw80 ac">{{data.mechanic_assign.length ?
                                data.mechanic_assign.length:'0'}}</div>
                            <div class="div_cell mmw95  afe"> {{data.wallet_balance ? data.wallet_balance:'0'}}
                            </div>
                            <div class="div_cell mmw95  afe"> {{data.total_order_quantity ? data.total_order_quantity:'0'}}
                            </div>
                        </main>

                        <app-not-found *ngIf="reatilerDetailData1.length == 0" class="fix"></app-not-found>
                    </ng-container>

                    <ng-container *ngIf="loader">
                        <main *ngFor="let data of skeleton">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw150">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw170">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw170">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw170">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div *ngIf="matTabValue=='Reject' || matTabValue=='Suspect'" class="div_cell mmw200">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw95 afe">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw95 afe">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>


                        </main>
                    </ng-container>
                </section>


            </div>



            <ng-container *ngIf="data_val.user_type=='System_user' || data_val.login_type == 'distributor'">
                <div class="bottom-strip" *ngIf="!loader">
                    <div class="left-auto df ac">
                        <div class="pagination">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{totalPage}}</li>
                                <li (click)="onChangePageNumber('previous')"><a matRipple class="back"
                                        matTooltip="Previous" matTooltipPosition="above"><i
                                            class="material-icons">chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                        matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                            </ul>
                        </div>
                        <div class="multi-btns">
                            <a mat-raised-button color="accent" *ngIf="reatilerDetailData.length > 0"
                                (click)="exportRetailerDataInExcel()" class="ml10"><img src="assets/img/excel.png">
                                Download Excel</a>
                        </div>
                    </div>
                </div>
            </ng-container>


            <ng-container *ngIf="data_val.user_type=='approval_user'">
                <div class="bottom-strip" *ngIf="!loader">
                    <div class="left-auto df ac">
                        <div class="pagination">
                            <ul class="df ac">
                                <li>Pages {{pageNumber1}} of {{totalPage1}}</li>
                                <li (click)="onChangePageNumber1('previous')"><a matRipple class="back"
                                        matTooltip="Previous" matTooltipPosition="above"><i
                                            class="material-icons">chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber1"
                                    (keyup.enter)="onChangePageNumber1('current')" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber1('next')"><a matRipple class="next" matTooltip="Next"
                                        matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                            </ul>
                        </div>
                        <div class="multi-btns" *ngIf="data_val.approval_type=='asm'">
                            <a mat-raised-button color="accent" *ngIf="reatilerDetailData1.length > 0"
                                (click)="exportAsmRetailerDataInExcel()" class="ml10"><img src="assets/img/excel.png">
                                Download Excel</a>
                        </div>
                        <div class="multi-btns" *ngIf="data_val.approval_type=='zsm'">
                            <a mat-raised-button color="accent" *ngIf="reatilerDetailData1.length > 0"
                                (click)="exportZsmRetailerDataInExcel()" class="ml10"><img src="assets/img/excel.png">
                                Download Excel</a>
                        </div>
                    </div>
                </div>
            </ng-container>


        </div>

    </div>
</div>