import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';


@Component({
  selector: 'app-pending-order-excel-upload-modal',
  templateUrl: './pending-order-excel-upload-modal.component.html',
})
export class PendingOrderExcelUploadModalComponent implements OnInit {

  uploadExcelFile: FormGroup;


  constructor(

    
    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public router: Router,
    private dialogRef: MatDialogRef<PendingOrderExcelUploadModalComponent>

  ) { 
    
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
  }


  UploadExcel() {
    let input = new FormData();
    input.append('file', this.fileToUpload);
    input.forEach((value, key) => {
      console.log(key + " " + value)
    });

    console.log(JSON.stringify(input));
    console.log(this.fileToUpload);

    this.apiHit.FileUpload(input, "Distributor/IMPORT_DISTRIBUTOR_PENDING_ORDER").subscribe((result => {

      console.log(result);
      if(result['status'] == 'Success'){
        this.dialog.success('Success', 'Data Sucessfully Uploaded');
        this.dialogRef.close();
      }
      else{
        this.dialog.error('Error', 'Something Went Wrong');
      }
      // return;
     

    }));

  }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(files.item(0));

    // this.uploadFile2('');
  }

}
