import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-scheme-add',
  templateUrl: './scheme-add.component.html',
})
export class SchemeAddComponent implements OnInit {

  AddSchemeForm: FormGroup;
  date: any;
  loader: any;
  stateData: any;
  // minDate: Date;
  minEndDate: Date;
  userId: any;
  segmentTypeCheck: any;
  statesearch_btn: string;
  selectedState: any = [];
  segmentdata: any = [];
  schemeDetailData: any = [];
  addScheme: any;
  hideSaveButton: boolean = false;

  constructor(

    private fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public activateRoute: ActivatedRoute,
    public router: Router,
    private _location: Location,

  ) {

    this.getSegment();
    this.date = new Date();
    // this.minDate = new Date();
    this.userId = this.apiHit.userLoginData.data.id;

  }

  ngOnInit(): void {

    this.AddSchemeForm = this.fb.group({

      title: ['', [Validators.required, Validators.minLength(3)]],
      start_date: ['', [Validators.required]],
      end_date: ['', [Validators.required]],
      description: ['', []],
      term_condition: ['', []],
      state_array: [[], []],
      scheme_point: ['', [Validators.required]],
      segment: ['', [Validators.required]],
      type: ['', [Validators.required]],
      range_from: new FormControl(''),
      range_to: new FormControl(''),
      above_range: new FormControl(''),
      u_id: [this.userId],
      scheme_id: ['', []],
      searchCtrl: [''],
      status: ['1']


    })

    if (this.activateRoute['params']['_value']['id'] != 0) {

      this.getSchemeDetails();
      this.addScheme = 1;

    }
    else {
      this.stateList();
      this.addScheme = 0;
    }

  }



  setSchemeDetailValue() {

    this.AddSchemeForm.controls.title.setValue(this.schemeDetailData['title']);
    this.AddSchemeForm.controls.start_date.setValue(moment(this.schemeDetailData['start_date']).format('YYYY-MM-DD'));
    this.AddSchemeForm.controls.end_date.setValue(moment(this.schemeDetailData['end_date']).format('YYYY-MM-DD'));
    this.AddSchemeForm.controls.description.setValue(this.schemeDetailData['description']);
    this.AddSchemeForm.controls.term_condition.setValue(this.schemeDetailData['term_condition']);
    this.AddSchemeForm.controls.state_array.setValue(this.schemeDetailData['states']);
    this.AddSchemeForm.controls.scheme_point.setValue(this.schemeDetailData['scheme_point']);
    this.AddSchemeForm.controls.segment.setValue(this.schemeDetailData['segment']);
    this.AddSchemeForm.controls.type.setValue(this.schemeDetailData['type']);
    this.AddSchemeForm.controls.range_from.setValue(this.schemeDetailData['range_from']);
    this.AddSchemeForm.controls.range_to.setValue(this.schemeDetailData['range_to']);
    this.AddSchemeForm.controls.above_range.setValue(this.schemeDetailData['above']);
    this.AddSchemeForm.controls.scheme_id.setValue(this.schemeDetailData['id']);
    this.AddSchemeForm.controls.status.setValue(this.schemeDetailData['status']);
    this.checkSchemeType();
  }



  //Get Distributor Detail Through API Function Start

  getSchemeDetails() {

    this.loader = 1;

    this.apiHit.PostRequest({ scheme_id: this.activateRoute['params']['_value']['id'] }, "SchemeController/showDetailScheme").subscribe((result => {

      // console.log(result);
      if (result['SchemesList']['status'] == 'success') {

        this.schemeDetailData = result['SchemesList']['scheme_details'][0];
        this.selectedState = result['SchemesList']['scheme_details'][0]['states'];
        this.stateList();
        this.setSchemeDetailValue();
      }

      else {
        this.dialog.error('error', 'Something Went Wrong');
      }

    }));

  }
  //Get Distributor Detail Through API Function End




  getSegment() {

    this.loader = 1;
    this.apiHit.PostRequest('', "app/AppOrderController/getSegment").subscribe((result => {

      // console.log(result['result']);
      if (result['result']['status'] == 'success') {
        this.segmentdata = result['result']['segmentData'];
      }

    }));

  }


  checkSchemeType() {

    if (this.AddSchemeForm.value.type == 'Between') {

      this.segmentTypeCheck = 1;
      this.AddSchemeForm.controls.above_range.setValidators(null);
      this.AddSchemeForm.controls.range_from.setValidators([Validators.required]);
      this.AddSchemeForm.controls.range_to.setValidators([Validators.required]);
      this.AddSchemeForm.controls.above_range.setValue('');

    }
    else if (this.AddSchemeForm.value.type == 'Above') {

      this.segmentTypeCheck = 2;
      this.AddSchemeForm.controls.above_range.setValidators([Validators.required]);
      this.AddSchemeForm.controls.range_from.setValidators(null);
      this.AddSchemeForm.controls.range_to.setValidators(null);
      this.AddSchemeForm.controls.range_from.setValue('');
      this.AddSchemeForm.controls.range_to.setValue('');

    }

  }


  // Back Button Function  Start

  backClicked() {

    this._location.back();

  }
  // Back Button Function  End



  SaveScheme() {

    // console.log(this.AddSchemeForm.value);
    this.hideSaveButton = true;
    if (this.selectedState.length == 0) {
      this.dialog.error('Warning', 'Please Select AtLeast One State');
      this.hideSaveButton = false;
      return
    }

    if (this.AddSchemeForm.value.start_date > this.AddSchemeForm.value.end_date && this.AddSchemeForm.value.type == 'Between' ) {
      this.dialog.error('Warning', 'End Date Should Be Greather Than Start Date');
      this.hideSaveButton = false;
      return
    }

    if (this.AddSchemeForm.value.type == 'Between' && Number(this.AddSchemeForm.value.range_from) > Number(this.AddSchemeForm.value.range_to)) {
      this.dialog.error('Warning', 'Range To Should Be Greather Than Range From');
      this.hideSaveButton = false;
      return
    }

    this.AddSchemeForm.value.start_date = moment(this.AddSchemeForm.value.start_date).format('YYYY-MM-DD');
    this.AddSchemeForm.value.end_date = moment(this.AddSchemeForm.value.end_date).format('YYYY-MM-DD');


    this.AddSchemeForm.value.state_array = this.selectedState;

    this.apiHit.PostRequest(this.AddSchemeForm.value, "SchemeController/addScheme").subscribe((result => {

      // console.log(result);
      if (result['Scheme']['status'] == 'success') {
        if (this.activateRoute['params']['_value']['id'] != 0) {
          this.dialog.success('Success', 'Scheme Successfully Updated');
        } else {
          this.dialog.success('Success', 'Scheme Successfully Added');
        }
        this.router.navigate(['scheme-list']);
        this.hideSaveButton = false;
      }
      else if (result['Scheme']['message'] = 'Scheme already exist') {
        this.dialog.error('Error', 'Scheme Already Exist For This States ' + result['Scheme']['states']);
        this.hideSaveButton = false;
      }
      else {
        this.dialog.error('Error', 'Something Went Wrong');
        this.hideSaveButton = false;
      }

    }));

  }



  //State List Fetch From Database Through API  Start

  stateList() {

    this.loader = 1;

    this.apiHit.PostRequest('', "User/stateList").subscribe((result => {

      this.stateData = result['List']['stateList'];

      if (this.activateRoute['params']['_value']['id'] != 0) {
        // for (let i = 0; i < this.stateData.length; i++) {

        // const stateIndex = this.selectedState.findIndex(row => row == this.stateData[i]);
        // console.log(this.selectedState);
        // if(stateIndex == -1) {
        //   this.stateData.checked =true;
        // }
        // else{
        //   this.stateData.checked =false;
        // }
        // for (let index = 0; index < this.stateData.length; index++) {
        //   this.stateData[index]['checked'] = false;
        // }

        for (let index = 0; index < this.stateData.length; index++) {
          for (let j = 0; j < this.selectedState.length; j++) {
            if (this.stateData[index]['state'] == this.selectedState[j]) {
              this.stateData[index]['checked'] = true;
            }
            // else {
            //   this.stateData[index]['checked'] = false;
            // }
          }
          // }
        }
      }
      else {
        for (let i = 0; i < this.stateData.length; i++) {
          this.stateData[i]['checked'] = false;
        }
      }

      this.loader = '';
      console.log(this.stateData);

    }));

  }
  //State List Fetch From Database Through API  End




  minDateSelect() {

    this.minEndDate = this.AddSchemeForm.value.start_date;

  }


  on_select_state(event, state) {


    if (event.checked == true) {

      this.selectedState.push(state)

    }

    else {

      let isExist = this.selectedState.findIndex(row => row == state)

      if (isExist != -1) {

        this.selectedState.splice(isExist, 1)
      }

    }


  }





  // KeyPress Function  Start

  OnlyNumber(event: any) {

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {

      event.preventDefault();

    }
  }
  // KeyPress Function  End

}