import { Component, OnInit ,Inject} from '@angular/core';
import { MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-expense-edit',
  templateUrl: './expense-edit.component.html',
  styleUrls: ['./expense-edit.component.scss']
})
export class ExpenseEditComponent implements OnInit {
  expense_detail: any = {};
  expense_type: any = 'no type';
  today_date = new Date();
  depatureTime:boolean = true;
  constructor(@Inject(MAT_DIALOG_DATA)public data,public dialog:MatDialog,public serve : DatabaseService,public dialogComponent : DialogService) { }

  ngOnInit(): void {
  }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }
  date_format(type){
    
    if(type == 'hotel checkIn Date'){
      this.expense_detail.checkInDate = moment(this.expense_detail.checkInDate).format('YYYY-MM-DD');  
    }
    
    else if(type == 'hotel checkOut Date'){
      this.expense_detail.checkOutDate = moment(this.expense_detail.checkOutDate).format('YYYY-MM-DD');  
    }
    
    else if(type == 'Travelentitlement depatureDate'){
      this.expense_detail.depatureDate = moment(this.expense_detail.depatureDate).format('YYYY-MM-DD');  
    }
    
    else if(type == 'Travelentitlement arrivalDate'){
      this.expense_detail.arrivalDate = moment(this.expense_detail.arrivalDate).format('YYYY-MM-DD');  
    }
    
    else{
      this.expense_detail.date = moment(this.expense_detail.date).format('YYYY-MM-DD');
    }
    
    
    
  }
  
  update_expense(){
    console.log("update_expense method calls ");
    console.log(this.expense_detail);
    this.serve.PostRequest({ 'expense_detail': this.expense_detail,'expense_type':this.expense_type }, "Expense/update_expense_detail").subscribe((result => {
      console.log(result);
      if (result['msg'] == 'Updated Successfully') {
        console.log('in success function');
        this.dialog.closeAll();
        this.dialogComponent.success("Expense", "Updated");
      }
      else {
        console.log('in failed function');
        this.dialogComponent.error('Error',result['msg']);
      }
    }))
    
    
  }
  
  
  convert_time(event,type){
    console.log("convert_time method calls");
    console.log(event);
    console.log(type);
    
    if(type == 'depatureTime'){
      this.expense_detail.depatureTime = moment(event).format('LT');
      console.log(this.expense_detail.depatureTime);
    }
    else if(type == 'arrivalTime'){
      this.expense_detail.arrivalTime = moment(event).format('LT');
      console.log(this.expense_detail.arrivalTime);
    }
    
    else{
      console.log(event);
      console.log(type);
      
    }
    
    
    
  }
  
  
  calc_amount(type){
    console.log("calc_amount method calls");
    console.log(type);
    
    if(type == 'local'){
      this.expense_detail.approvedAmount = parseInt(this.expense_detail.amount)  
      console.log(this.expense_detail.approvedAmount);
      
    }
    
    
    else if(type == 'food'){
      this.expense_detail.approvedAmount = parseInt(this.expense_detail.amount) 
      console.log(this.expense_detail.approvedAmount);

    }
    
    else if(type == 'hotel' || type == 'food' || type == 'local' || type == 'misc'){
      this.expense_detail.approvedAmount = parseInt(this.expense_detail.amount) 
      console.log(this.expense_detail.approvedAmount);

      
    }
    
    else if(type == 'Travelentitlement'){
      this.expense_detail.approvedAmount = parseInt(this.expense_detail.arrivalAmount) + parseInt(this.expense_detail.depatureAmount) 
      console.log(this.expense_detail.approvedAmount);

    }
    
    else if(type == 'misc'){
      this.expense_detail.approvedAmount = parseInt(this.expense_detail.amount) 
      console.log(this.expense_detail.approvedAmount);
      
    }
    else{
      console.log("in else");
      console.log(this.expense_detail);
      
    }
    console.log(this.expense_detail);
    
    
  }
}
