<div class="container-fluid">
    <div class="container">
        <section id="head">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below" routerLink="../retailer" routerLinkActive="active">
                <mat-icon>arrow_back</mat-icon>
            </a>
            
            
            <div class="module-name">
                <h2>Retailer</h2>
                <p>Add New</p>
            </div>
            
            <div class="left-auto df afe">
                <div class="indicates">
                    Indicates required fields ( <span>*</span> ) 
                </div>
            </div>
        </section>
        
        <div class="container-pd10-5">
            <form #form="ngForm" [formGroup]="AddRetailerForm" (ngSubmit)="saveRetailer()"> 
                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>basic Information</h2>
                            </div>
                            
                            <div class="cs-form pb0">
                                <div class="row row-mb0">                                    

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Firm Name <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="firmName" 
                                            id="firmName" maxlength="40">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.firmName.invalid && 
                                            AddRetailerForm.controls['firmName']?.touched && !AddRetailerForm.controls['firmName']['errors']?.minlength ">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.firmName.invalid && 
                                            AddRetailerForm.controls['firmName']['errors']?.minlength || AddRetailerForm.controls['firmName']['errors']?.maxlength">
                                            Required 3-40 Characters
                                        </mat-hint>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Name <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="firstName" 
                                            id="firstName" maxlength="40">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.firstName.invalid && 
                                            AddRetailerForm.controls['firstName']?.touched && !AddRetailerForm.controls['firstName']['errors']?.minlength ">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.firstName.invalid && 
                                            AddRetailerForm.controls['firstName']['errors']?.minlength || AddRetailerForm.controls['firstName']['errors']?.maxlength">
                                            Required 3-40 Characters
                                        </mat-hint>
                                    </div>
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Mobile No.<span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="mobileNo" 
                                            id="mobileNo" maxlength="10" (ngModelChange)="checkMobileNoDuplicacy()" (keypress)="OnlyNumber($event)">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.mobileNo.invalid && 
                                            AddRetailerForm.controls['mobileNo']?.touched && !AddRetailerForm.controls['mobileNo']['errors']?.minlength ">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.mobileNo.invalid && 
                                            AddRetailerForm.controls['mobileNo']['errors']?.minlength || AddRetailerForm.controls['mobileNo']['errors']?.maxlength">
                                            Required 10 Numbers
                                        </mat-hint>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Email Id <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="email" 
                                            id="email">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.email.invalid && 
                                            AddRetailerForm.controls['email']?.touched && !AddRetailerForm.controls['email']['errors']?.pattern">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.email?.invalid && 
                                            AddRetailerForm.controls['email']['errors']?.pattern">
                                            Invalid Email
                                        </mat-hint>
                                    </div>
                                    
                                </div>

                                <div class="row row-mb0">
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Date of Birth</mat-label>


                                    
                                            <input matInput [matDatepicker]="birth" formControlName="DOB" 
                                            id="DOB"  [min]="minDate" [max]="maxDate" (ngModelChange)="minDateSelect()" readonly (click)="birth.open()">
                

                                            <mat-datepicker-toggle matSuffix [for]="birth"></mat-datepicker-toggle>
                                            <mat-datepicker #birth disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Date of Anniversary</mat-label>
                                            <input matInput [matDatepicker]="anniversary" formControlName="anniversary" 
                                            id="anniversary" [min]="minDate" [max]="date" readonly (click)="anniversary.open()">
                                            <mat-datepicker-toggle matSuffix [for]="anniversary"></mat-datepicker-toggle>
                                            <mat-datepicker #anniversary disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>GST Number</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="gstNo" 
                                            id="gstNo" maxlength="15">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.gstNo.invalid && 
                                            AddRetailerForm.controls['gstNo']['errors']?.minlength || AddRetailerForm.controls['gstNo']['errors']?.maxlength">

                                            <!-- &&!AddRetailerForm.controls['gstNo']['errors']?.pattern -->
                                            Required 15 Characters
                                        </mat-hint>
                                        <!-- <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.gstNo?.invalid && 
                                            AddRetailerForm.controls['gstNo']['errors']?.pattern && !AddRetailerForm.controls['gstNo']['errors']?.minlength">
                                            Invalid GST No
                                        </mat-hint> -->
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>PAN Number</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="panNo" 
                                            id="panNo" maxlength="10">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.panNo.invalid && 
                                            AddRetailerForm.controls['panNo']['errors']?.minlength || AddRetailerForm.controls['panNo']['errors']?.maxlength">

                                            <!-- &&!AddRetailerForm.controls['panNo']['errors']?.pattern  -->
                                            Required 10 Characters
                                        </mat-hint>
                                        <!-- <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.panNo?.invalid && 
                                            AddRetailerForm.controls['panNo']['errors']?.pattern && !AddRetailerForm.controls['panNo']['errors']?.minlength">
                                            Invalid PAN No
                                        </mat-hint> -->
                                    </div>
                                    
                                </div>


                                <div class="row row-mb0">

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Select Distributor <span class="sub">*</span></mat-label>
                                            <mat-select formControlName="distributor_id" 
                                            id="distributor_id">
                                                <mat-option *ngFor="let data of distributorData;"  value="{{data.id}}">{{data.dr_name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.distributor_id.invalid && 
                                            AddRetailerForm.controls['distributor_id']?.touched">
                                            
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>Contact Person Information</h2>
                            </div>
                            
                            <div class="cs-form pb0">
                                <div class="row row-mb0">
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Name</mat-label>
                                            <input matInput placeholder="Type Here..." formControlName="cPersonName" 
                                            id="cPersonName" maxlength="30">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.cPersonName.invalid && 
                                            AddRetailerForm.controls['cPersonName']['errors']?.minlength || AddRetailerForm.controls['cPersonName']['errors']?.maxlength">
                                            Required 3-30 Characters
                                        </mat-hint>
                                    </div>
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Mobile No</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="cPersonMobileNo" 
                                            id="cPersonMobileNo" maxlength="10" (keypress)="OnlyNumber($event)">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.cPersonMobileNo.invalid && 
                                            AddRetailerForm.controls['cPersonMobileNo']['errors']?.minlength || AddRetailerForm.controls['cPersonMobileNo']['errors']?.maxlength">
                                            Required 10 Numbers
                                        </mat-hint>
                                    </div>
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Email Id</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="cPersonEmail" 
                                            id="cPersonEmail">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.cPersonEmail?.invalid && 
                                            AddRetailerForm.controls['cPersonEmail']['errors']?.pattern">
                                            Invalid Email
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>address Information</h2>
                            </div>
                            
                            <div class="cs-form pb0">
                                <div class="row row-mb0">
                                    
                                    <div class="col s12 m6 pading0">
                                        <div class="row row-mb0">
                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Pincode <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..." formControlName="pinNo" 
                                                    id="pinNo" maxlength="6"  (keypress)="OnlyNumber($event)" (input)="enterPin(AddRetailerForm)">
                                                </mat-form-field>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddRetailerForm.controls.pinNo.invalid && 
                                                    AddRetailerForm.controls['pinNo']?.touched && !AddRetailerForm.controls['pinNo']['errors']?.minlength ">
                                                    
                                                </mat-hint>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddRetailerForm.controls.pinNo.invalid && 
                                                    AddRetailerForm.controls['pinNo']['errors']?.minlength || AddRetailerForm.controls['pinNo']['errors']?.maxlength">
                                                    Required 6 Numbers
                                                </mat-hint>
                                            </div>
                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>State <span class="sub">*</span></mat-label>
                                                    <mat-select (keyup.enter)="districtList1()" formControlName="state" 
                                                    id="state"   [value]="stataData">

                                                    <ngx-mat-select-search formControlName="searchCtrl" [placeholderLabel]="'Search...'"

                                                    [noEntriesFoundLabel]="'Not found'" name="search"
                                                   ></ngx-mat-select-search>

                                                        <mat-option *ngFor="let data of stateData  |stringFilterBy:AddRetailerForm.value.searchCtrl:'data'" (click)="districtList(data)" 
                                                        value="{{data}}">{{data}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddRetailerForm.controls.state.invalid && 
                                                    AddRetailerForm.controls['state']?.touched">
                                                    
                                                </mat-hint>
                                            </div>
                                            
                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>District<span class="sub">*</span></mat-label>
                                                   
                                                    <mat-select [value]="selectedDistrict" formControlName="district" 
                                                    id="district" >
                                                        <mat-option *ngFor="let data of districtData; let i=index" 
                                                        value="{{data}}">{{data}}</mat-option>
                                                    </mat-select>
                                                   
                                                </mat-form-field>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddRetailerForm.controls.district.invalid && 
                                                    AddRetailerForm.controls['district']?.touched">
                                                    
                                                </mat-hint>
                                                
                                            </div>
                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>City <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..." formControlName="city" 
                                                    id="city" maxlength="30">
                                                </mat-form-field>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddRetailerForm.controls.city.invalid && 
                                                    AddRetailerForm.controls['city']?.touched && !AddRetailerForm.controls['city']['errors']?.minlength ">
                                                    
                                                </mat-hint>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddRetailerForm.controls.city.invalid && 
                                                    AddRetailerForm.controls['city']['errors']?.minlength || AddRetailerForm.controls['city']['errors']?.maxlength">
                                                    Required 3-30 Characters
                                                </mat-hint>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Address</mat-label>
                                            <textarea matInput placeholder="Type Here ..." class="h70" formControlName="address" 
                                            id="address"></textarea>
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddRetailerForm.controls.address.invalid && 
                                            AddRetailerForm.controls['address']['errors']?.minlength">
                                            Required Minimum 3 Characters
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col s12 m12">
                        <div class="cs-btn">
                            <button mat-button type="submit" [disabled]="AddRetailerForm.invalid">Save</button>
                            <!-- <button mat-button type="submit">Save</button> -->
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
