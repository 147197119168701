import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
  selector: 'app-distributor-to-retailer-list',
  templateUrl: './distributor-to-retailer-list.component.html'
})
export class DistributorToRetailerListComponent implements OnInit {

  data: any = [];
  skeleton: any = [];
  loader: any;
  pageNumber: any;
  paginationData: any = {};
  distributorToRetailers: any;
  total: any;
  distributorTransactionSearch: any = {};
  date: any = [];
  totalTransaction: any = {};

  constructor(

    private apiHit: DatabaseService,
    public dialog2: DialogService,

  ) {

    this.data = new Array(2);
    this.skeleton = new Array(10);
    this.pageNumber = 1;
    this.date = new Date();
    this.distributorToRetailer(1);

  }


  ngOnInit(): void {
  }



  onChangePageNumber(type) {

    console.log(this.pageNumber);
    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {


          this.pageNumber -= 1;
          this.distributorToRetailer(2);

        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.distributorToRetailer(2);

        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          console.log('current else');

          this.pageNumber = this.total;
          this.distributorToRetailer(2);

        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.distributorToRetailer(2);

        }
        else if (this.pageNumber) {

          console.log('last else');

          this.distributorToRetailer(2);
        }

      }
    }, 100);
  }


  public onDate(event): void {

    this.distributorTransactionSearch.date_created = moment(event.value).format('YYYY-MM-DD');
    this.distributorToRetailer(1);

  }


  distributorToRetailer(target) {

    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }
    this.distributorToRetailers = [];
    this.paginationData.searchData = this.distributorTransactionSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;

    this.apiHit.PostRequest(this.paginationData, "Distributor/retailer_to_distributor_transaction_search").subscribe((result => {

      console.log(result);

      this.distributorToRetailers = result['transactionData']['data']['data'];
      this.total = result['transactionData']['data'].totalPage;
      this.totalTransaction = result['transactionData']['data'].total_rows_after_search;
      console.log(this.distributorToRetailers);

      setTimeout(() => {

        this.loader = '';

      }, 400);

    }));

  }


  exportExcel() {

    this.loader = 1;
    this.paginationData.pagelimit = 0;
    this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_distributer_to_retailer").subscribe((result => {

      console.log(result);
      if (result['msg'] == 'Success') {
        this.distributorToRetailer(1);
        setTimeout(() => {

          window.open(this.apiHit.downloadURL + "uploads/RETAILER_transaction.csv");
          this.loader = '';

        }, 700);
      }

    }));
  }



  refreshData() {

    this.distributorTransactionSearch = {};
    this.distributorToRetailer(1);

  }

}