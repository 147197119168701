import { Component, OnInit } from '@angular/core';
import { Console } from 'console';
import { DatabaseService } from '../services/service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html'
})
export class LeftNavigationComponent implements OnInit {
 
  tabActiveType:any={};
  tab:any={};
  loginData: any = {};
  moduleData: any;

  constructor(public apiHit: DatabaseService,public router: Router, ) {

         this.tabActive('tab0');
        
   }

  ngOnInit(): void {
    console.log(this.apiHit.getLoginData());
    this.moduleData= this.apiHit.userLoginData.data.moduleData;
    console.log(this.moduleData)
    
  
  }

  tabActive(tab:any) {

    this.tabActiveType = {};
    this.tabActiveType[tab] = !this.tabActiveType[tab]; 
    this.tab[tab] = !this.tab[tab]; 
    
  }
 
  leftnavigationcount(){
    // setTimeout(() => {
    //   this.apiHit.getLeftNavigationCount();
    // }, 4000);
  }
}