
<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Banner</h2>
                    <p>{{bannerData.length}} Banner Available</p>
                </div>
                
                <div class="left-auto"  >
                    <div class="cs-btn mt0">
                        <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                            <label class="file-input" *ngIf=" datas.module_name== 'Banner' && datas.add==1">
                                <mat-icon>cloud_upload</mat-icon>
                                <p>Add banner</p>
                                <input type="file"  (change)="handleFileInput($event.target.files)" accept=".jpg,.jpeg,.png" style="display: none;">
                            </label>
                        </span>
                    </div>
                </div>
                
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        
        <div class="container-pd10-5">
            <div class="row">
                <ng-container *ngIf="!loader">
                    <div class="col s3 mb10" *ngFor="let data of bannerData; let i=index">
                        <mat-card class="cs-card">
                            <div class="cardImg">
                                <img mat-card-image src="{{apiHit.uploadURL}}uploads/banners/{{data.banner_image}}" alt="Photo of a Shiba Inu">
                            </div>
                            
                            <mat-card-actions class="df ac jsb">
                                
                                    <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                        <mat-slide-toggle  *ngIf=" datas.module_name== 'Banner' && datas.edit==1" (click)="change_active_deactive(data.id, data.isActive)" [(ngModel)]="data.isActive">{{data.status| titlecase}}
                                      
                                        </mat-slide-toggle>
                                    </ng-container>
                                    <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                        
                                        <button *ngIf=" datas.module_name== 'Banner' && datas.delete==1" mat-icon-button  matTooltip="Delete" matTooltipPosition="below" color="warn" (click)="deleteBanner(data.id)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </ng-container>
                                </mat-card-actions>
                            </mat-card>
                        </div>
                        
                        <app-not-found *ngIf="bannerData.length == 0"></app-not-found>
                        
                    </ng-container>
                    
                    <ng-container *ngIf="loader">
                        <div class="col s3 mb10" *ngFor="let row of skeleton">
                            <div class="cark-sk">
                                <div class="sk-footer"><div></div></div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    