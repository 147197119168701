<div class="container-fluid">
    <div class="container">
        <section id="head"  *ngIf="!loader">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"  (click)="backClicked()">
                <mat-icon>arrow_back</mat-icon>
            </a>
            <div class="module-name">
                <h2>User</h2>
                <p *ngIf="addUser == 1">Add New</p>
                <p *ngIf="addUser == 0">Edit User</p>
                
            </div>
            
            <div class="left-auto df afe">
                <div class="indicates">
                    Indicates required fields ( <span>*</span> ) 
                </div>
            </div>
        </section>
        
        <!-- skeleton Start -->
        <section id="head" *ngIf="loader">
            <div class="module-name sk-loading" >
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        <!-- skeleton end -->
        
        
        <div class="container-pd10-5">
            
            <div class="row">
                <div class="col s12">
                    <form [formGroup]="AddUserForm" (ngSubmit)="checkRolePermissionAssigned()">
                        <div class="cs-column" *ngIf="!loader">
                            <div class="cs-head">
                                <h2>basic Information</h2>
                            </div>
                            
                            <div class="cs-form pb0">
                                <div class="row row-mb0">
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Name <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="name"  id="name" maxlength="40">
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddUserForm.controls.name.invalid &&  AddUserForm.controls['name']?.touched && !AddUserForm.controls['name']['errors']?.minlength ">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error" *ngIf="AddUserForm.controls.name.invalid && AddUserForm.controls['name']['errors']?.minlength || AddUserForm.controls['name']['errors']?.maxlength">
                                            Required 3-40 Characters
                                        </mat-hint>
                                    </div>
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>User Type <span class="sub">*</span></mat-label>
                                            <mat-select formControlName="userType"  id= "userType" (selectionChange)="selectUserType($event.value); changeFormcontrolgroup($event.value)">
                                                <mat-option value="{{item.id}}" *ngFor="let item of user_type_value">{{item.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  

                                    <div class="col s12 m3" *ngIf="AddUserForm.value.userType">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Mobile Number <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="mobile_no"  id="mobile_no" maxlength="10" (ngModelChange)="checkMobileNoDuplicacy()" (keypress)="OnlyNumber($event)">
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddUserForm.controls.mobile_no.invalid && AddUserForm.controls['mobile_no']?.touched && !AddUserForm.controls['mobile_no']['errors']?.minlength ">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error" *ngIf="AddUserForm.controls.mobile_no.invalid && AddUserForm.controls['mobile_no']['errors']?.minlength || AddUserForm.controls['mobile_no']['errors']?.maxlength">
                                            Required 10 Numbers
                                        </mat-hint>
                                    </div>
                                    
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Email ID <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="email_id" 
                                            id="email_id">
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddUserForm.controls.email_id.invalid && AddUserForm.controls['email_id']?.touched && !AddUserForm.controls['email_id']['errors']?.pattern">
                                            
                                        </mat-hint>
                                        <mat-hint class="has-error"  *ngIf="AddUserForm.controls.email_id?.invalid && AddUserForm.controls['email_id']['errors']?.pattern">
                                            Invalid Email
                                        </mat-hint>
                                    </div>
                                    
                                    <div class="col s12 m3" *ngIf="addUser == 0 && (AddUserForm.value.userType == 'System_user' || AddUserForm.value.userType == 'approval_user')">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>User Name <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="username" 
                                            id="username">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m3" *ngIf="addUser == 0 && (AddUserForm.value.userType == 'System_user' || AddUserForm.value.userType == 'approval_user')">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Password <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="password" 
                                            id="password">
                                        </mat-form-field>
                                        
                                    </div>
                                    
                               
                                    
                                    <div class="col s12 m3" *ngIf="AddUserForm.value.userType== 'approval_user' ">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Sales Executive Type <span class="sub">*</span></mat-label>
                                            <mat-select formControlName="approvalType" id="approvalType" (selectionChange)="selectedSalesExecutiveType($event.value)">
                                                <mat-option value="{{item.id}}" *ngFor="let item of approval_user_types">{{item.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>  
                                 </div>
                                 <div class="row row-mb0">
                                    <div class="col s12 m3" *ngIf="AddUserForm.value.userType == 'System_user' ">
                                            <mat-form-field appearance="outline" class="cs-input">
                                                <mat-label>Select Designation</mat-label>
                                                <mat-select formControlName="system_user_designation" id="system_user_designation" placeholder="Role">
                                                <!-- <mat-option value="" disabled>Select Role</mat-option> -->
                                                <mat-option *ngFor="let row of system_user_designation" value="{{row.id}}">{{row.role_name}}</mat-option>
                                                </mat-select>
                                        </mat-form-field>
                                    </div>
                                 </div>
                         
                            <div class="row row-mb0">
                                <div class="col s12 m3" *ngIf="AddUserForm.value.userType == 'sales_user' ">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Select Designation <span class="sub">*</span></mat-label>
                                            <mat-select formControlName="user_designation" id="user_designation" placeholder="Role">
                                            <!-- <mat-option value="" disabled>Select Role</mat-option> -->
                                            <mat-option *ngFor="let row of designation_list" value="{{row.id}}">{{row.role_name}}</mat-option>
                                            </mat-select>
                                    </mat-form-field>
                                    <mat-hint class="has-error" *ngIf="AddUserForm.controls.user_designation.invalid &&  AddUserForm.controls['user_designation']?.touched">
                                            
                                    </mat-hint>
                                    
                                </div>
                                <!-- <div class="col s12 m3" *ngIf="AddUserForm.value.userType== 'sales_user' ">
                                    <mat-form-field appearance="outline" >
                                      <mat-label>Employee Code <span class="sub">*</span></mat-label>
                                      <input matInput placeholder="Type Here ..." formControlName="employee_id" id="employee_id" >
                                    </mat-form-field>
                                    <mat-hint class="has-error" *ngIf="AddUserForm.controls.employee_id.invalid && AddUserForm.controls['employee_id']?.touched">
                                        
                                    </mat-hint>
                                </div> -->
                                  <!-- <div class="col s12 m3" *ngIf="AddUserForm.value.userType== 'sales_user' ">
                                    <mat-form-field appearance="outline" class="cs-input">
                                      <mat-label>Weekly Off</mat-label>
                                      <mat-select formControlName="weekly_off" id="weekly_off">
                                        <mat-option disabled="">Select Week</mat-option>
                                        <mat-option value="Sunday">Sunday</mat-option>
                                        <mat-option value="Monday">Monday</mat-option>
                                        <mat-option value="Tuesday">Tuesday</mat-option>
                                        <mat-option value="Wednesday">Wednesday</mat-option>
                                        <mat-option value="Thursday">Thursday</mat-option>
                                        <mat-option value="Friday">Friday</mat-option>
                                        <mat-option value="Saturday">Saturday</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div> -->
                  
                                  <div class="col s12 m3" *ngIf="AddUserForm.value.userType== 'sales_user'">
                                    <mat-form-field appearance="outline" class="cs-input">
                                      <mat-label>Reporting Manager</mat-label>
                                      <mat-select formControlName="assign_user" id="assign_user">
                                        <mat-option>
                                          <ngx-mat-select-search formControlName="searchCtrl" [placeholderLabel]="'Search...'"   
                                          [noEntriesFoundLabel]="'Not found'" name="search" (keyup)="getReportManager($event.target.value)"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let list of reportingManager;let index=index" value="{{list.id}}">
                                          {{list.name}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                  
                            </div>
                            <div class="row mb0">
                                <div class="col s12">
                                    <h2 class="heading">Address Information</h2>
                                </div>
                            </div>
                            <div class="row row-mb0">
                                
                                <div class="col s12 m6 pading0">
                                    <div class="row row-mb0">
                                        <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="cs-input">
                                                <mat-label>Pincode</mat-label>
                                                <input matInput placeholder="Type Here ..." formControlName="pincode" 
                                                id="pincode" maxlength="6" (keypress)="OnlyNumber($event)" (input)="enterPin(AddUserForm)">
                                            </mat-form-field>
                                            <mat-hint class="has-error"  *ngIf="AddUserForm.controls.pincode.invalid && AddUserForm.controls['pincode']['errors']?.minlength || AddUserForm.controls['pincode']['errors']?.maxlength">
                                                Required 6 Numbers
                                            </mat-hint>
                                        </div>
                                        
                                        <div class="col s12 m6">
                                            <mat-form-field appearance="outline" class="cs-input">
                                                <mat-label>State</mat-label>
                                                
                                                <mat-select (keyup.enter)="districtList1()"  [value]="stataData" formControlName="state_name" name="state_name"
                                                id="state_name">
                                                <mat-option>
                                                <ngx-mat-select-search 
                                                
                                                formControlName="searchCtrl" [placeholderLabel]="'Search...'"
                                                
                                                [noEntriesFoundLabel]="'Not found'" name="search"
                                                ></ngx-mat-select-search>
                                            </mat-option>
                                                <mat-option (click)="districtList(data)" *ngFor="let data of stateData|stringFilterBy:AddUserForm.value.searchCtrl:'data'"   [value]="data">
                                                    {{data}}
                                                </mat-option>
                                                
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                
                                <div class="row row-mb0">
                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>District</mat-label>
                                            <mat-select [value]="selectedDistrict" formControlName="district_name" 
                                            id="district_name">
                                            <mat-option *ngFor="let data of districtData; let i=index" value="{{data}}"> {{data}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6">
                                    <mat-form-field appearance="outline" class="cs-input">
                                        <mat-label>City</mat-label>
                                        <input matInput placeholder="Type Here ..." formControlName="city_name" id="city_name" maxlength="30">
                                    </mat-form-field>
                                    <mat-hint class="has-error"
                                    *ngIf="AddUserForm.controls.city_name.invalid &&  AddUserForm.controls['city_name']['errors']?.minlength || AddUserForm.controls['city_name']['errors']?.maxlength">
                                    Required 3-30 Characters
                                </mat-hint>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Address</mat-label>
                            <textarea matInput placeholder="Type Here ..." class="h70" formControlName="street" 
                            id="street" maxlength="40"></textarea>
                        </mat-form-field>
                        <mat-hint class="has-error"
                        *ngIf="AddUserForm.controls.street.invalid && AddUserForm.controls['street']['errors']?.minlength">
                        Required Minimum 3 Characters
                    </mat-hint>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- Skeleton Start -->
<div class="cs-column"  *ngIf="loader">
    <div class="cs-head sk-loading">
        <h2>&nbsp;</h2>
    </div>
    
    <div class="cs-form">
        <div class="row">
            <div class="col s12 m12">
                <div class="fields sk-loading">
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row row-mb0">
            <div class="col s12 m6 pading0">
                <div class="row">
                    <div class="col s12 m12">
                        <div class="fields sk-loading">
                            <div class="cs-box">
                                <p class="h35 wp100">&nbsp;</p>
                            </div>
                            <div class="cs-box">
                                <p class="h35 wp100">&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row row-mb0">
                    <div class="col s12 m12">
                        <div class="fields sk-loading">
                            <div class="cs-box">
                                <p class="h35 wp100">&nbsp;</p>
                            </div>
                            <div class="cs-box">
                                <p class="h35 wp100">&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col s12 m6">
                <div class="col s12 m12">
                    <div class="fields sk-loading">
                        <div class="cs-box">
                            <p class="h98 wp100">&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Skeleton End -->

</div>
</div>

<ng-container *ngIf="AddUserForm.value.userType == 'System_user' || AddUserForm.value.userType == 'approval_user'">
    <div class="row mb0">
        <div class="col s12">
            <h2 class="heading">Module Rights</h2>
        </div>
    </div>
    
    <div class="cs-table left-right-5">
        <!-- Table Head Start-->
        <figure class="table-head">
            <section class="th_cell">
                <main>
                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                    <div class="div_cell mw150">Module Name</div>
                    <div class="div_cell mmw140 text-center">View</div>
                    <div class="div_cell mmw140 text-center">Add</div>
                    <div class="div_cell mmw140 text-center">Edit</div>
                    <div class="div_cell mmw140 text-center af-bdr-right">Delete</div>
                    <div class="div_cell mmw140 text-center">All</div>
                </main>
            </section>
        </figure>
        
        <section class="td_cell">
            <ng-container *ngIf="!loader && AddUserForm.value.userType == 'System_user'">
                <main *ngFor="let row of moduleData; let i = index;">
                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                    <div class="div_cell mw150">{{row.module_name}}</div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView2(row.view,i)' [disabled]="row.view_perm==1 ? true : false" name="onlyview"  [(ngModel)]="row.view"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView(row.add,i)' [disabled]="row.add_perm==1 ? true : false"   [(ngModel)]="row.add"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView(row.edit,i)' [disabled]="row.edit_perm==1 ? true : false" [(ngModel)]="row.edit"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView(row.delete,i)' [disabled]="row.delete_perm==1 ? true : false" [(ngModel)]="row.delete"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='selectAll(row.all,i)' [disabled]="row.all_perm==1 ? true : false" [(ngModel)]="row.all"></mat-checkbox>
                    </div>
                </main>
                <app-not-found *ngIf="moduleData.length == 0"></app-not-found>
                
            </ng-container>
            <ng-container *ngIf="!loader && AddUserForm.value.userType == 'approval_user'">
                <main *ngFor="let row of module_data; let i = index;">
                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                    <div class="div_cell mw150">{{row.module_name}}</div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView2(row.view,i)' [disabled]="row.view_perm==1 ? true : false" name="onlyview"  [(ngModel)]="row.view"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView(row.add,i)' [disabled]="row.add_perm==1 ? true : false"   [(ngModel)]="row.add"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView(row.edit,i)' [disabled]="row.edit_perm==1 ? true : false" [(ngModel)]="row.edit"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeView(row.delete,i)' [disabled]="row.delete_perm==1 ? true : false" [(ngModel)]="row.delete"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='selectAll(row.all,i)' [disabled]="row.all_perm==1 ? true : false" [(ngModel)]="row.all"></mat-checkbox>
                    </div>
                </main>
                <app-not-found *ngIf="module_data.length == 0"></app-not-found>
                
            </ng-container>
            
            
            <!-- Skeleton Loading Start -->
            <ng-container *ngIf="loader && AddUserForm.value.userType== 'System_user'" >
                <main *ngFor="let row of skeleton;" >
                    <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mw150"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                </main>
            </ng-container>
            <!-- Skeleton Loading End -->
        </section>
        <!-- Table Head End -->
    </div>
</ng-container>


<!-- Module Rights Of ASM ZSM List Through API Start -->

<!-- <ng-container *ngIf="AddUserForm.value.userType == 'approval_user' && AddUserForm.value.approvalType== 'asm' || AddUserForm.value.approvalType== 'zsm'">
    <div class="row mb0">
        <div class="col s12">
            <h2 class="heading">Module Rights</h2>
        </div>
    </div>
    
    <div class="cs-table left-right-5">
        <figure class="table-head">
            <section class="th_cell">
                <main >
                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                    <div class="div_cell mw150">Module Name</div>
                    <div class="div_cell mmw140 text-center">View</div>
                    <div class="div_cell mmw140 text-center">Add</div>
                    <div class="div_cell mmw140 text-center">Edit</div>
                    <div class="div_cell mmw140 text-center af-bdr-right">Delete</div>
                    <div class="div_cell mmw140 text-center">All</div>
                </main>
            </section>
        </figure>
        
        <section class="td_cell">
            <ng-container *ngIf="!loader">
                <main *ngFor="let rows of module_data; let i = index;">
                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                    <div class="div_cell mw150">{{rows.module_name}}</div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (click)='activeViewRetailer(rows.view,i)' name="onlyview"  [(ngModel)]="rows.view"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeViewRetailer1(rows.add,i)' [disabled]="rows.add_perm==1 ? true : false"   [(ngModel)]="rows.add"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeViewRetailer1(rows.edit,i)' [disabled]="rows.edit_perm==1 ? true : false" [(ngModel)]="rows.edit"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='activeViewRetailer1(rows.delete,i)' [disabled]="rows.delete_perm==1 ? true : false" [(ngModel)]="rows.delete"></mat-checkbox>
                    </div>
                    <div class="div_cell mmw140 ac">
                        <mat-checkbox (change)='selectAllRetailer(rows.all,i)' [disabled]="rows.all_perm==1 ? true : false" [(ngModel)]="rows.all"></mat-checkbox>
                    </div>
                </main>
                
            </ng-container>
            
            <ng-container *ngIf="loader" >
                <main *ngFor="let row of skeleton1;" >
                    <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mw150"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                    <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                </main>
            </ng-container>
        </section>
    </div>
</ng-container> -->

<!-- Module Rights Of ASM ZSM List Through API End -->



<div class="row" >
    <div class="col s12 m6" >
        <div class="row">
            <div class="col s12 m6" *ngIf="AddUserForm.value.approvalType== 'asm' || AddUserForm.value.approvalType== 'zsm'">
                <div class="cs-column cs-column-height">
                    <div class="cs-head" >
                        <div class="df ac jsb hp100">
                            <h2>State</h2>
                            <a (click)="btn_selected_btn()" class="seacrh-btn" mat-button><i class="material-icons" >search</i>
                            </a>
                            <div class="filter-input"  [ngClass]="search_btn_selected==1 ?'active': '' " >
                                <input type="text"  [(ngModel)]="statesearch_btn" id="statesearch_btn"  placeholder="Search ..." (keyup.enter)="searchBtn(statesearch_btn)" autocomplete="off">
                                <a class="close-btn" mat-button (click)="reset_button()"><i class="material-icons">close</i></a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mat-list pt0" >
                        <div class="mat-border" *ngFor="let states of stateDatas | filter:statesearch_btn; let k=index;">
                            <mat-checkbox  (change)="on_select_state( $event, states.state, states.checked,k,1)" [(ngModel)]="states.checked">{{states.state}}</mat-checkbox>
                        </div>
                    </div>      
                </div>
            </div>
            <div class="col s12 m6" *ngIf="(AddUserForm.value.approvalType== 'asm'||AddUserForm.value.approvalType== 'zsm') && districtListData.length > 0">
                <div class="cs-column cs-column-height">
                    <div class="cs-head">
                        <div class="df ac jsb hp100">
                            <h2>District</h2>
                            <a  (click)="districtSearch()" class="seacrh-btn" mat-button><i class="material-icons">search</i></a>
                            <div class="filter-input "  [ngClass]="search_btn==1 ?'active': '' " >
                                <input type="text"  [(ngModel)]="districtsearch_btn" id="districtsearch_btn"  placeholder="Search ..." (keyup.enter)="district_search(districtsearch_btn)"  autocomplete="off">
                                <a class="close-btn" mat-button (click)="reset_district_button()"><i class="material-icons">close</i></a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mat-list">
                        <div class="mat-border" *ngFor="let districtData of districtListData  | filter:districtsearch_btn; let k=index;">
                            <mat-checkbox color="accent"  (change)="on_select_district(districtData.district, $event)" [(ngModel)]="districtData.checked">{{districtData.district}}</mat-checkbox>
                        </div>   
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mt16">
    <div class="col s12 m12">
        <div class="cs-btn"  *ngIf="!loader">
            <!-- [disabled]="AddUserForm.invalid" -->
            <button *ngIf="addUser == 1 && AddUserForm.value.userType== 'System_user'" mat-button type="submit"  (click)="checkRolePermissionAssigned()">Save
            </button>
            
            <button [hide] *ngIf="addUser1 == 1 && AddUserForm.value.approvalType== 'asm'" mat-button type="submit" [disabled]="AddUserForm.invalid"  (click)="checkRolePermissionAssignForApproval_user()">Save
            </button>
            
            
            <button [hide] *ngIf="addUser1 == 1 && AddUserForm.value.approvalType== 'zsm'" mat-button type="submit" [disabled]="AddUserForm.invalid"  (click)="checkRolePermissionAssignForApproval_user()">Save</button>
            <button *ngIf="addUser1 == 1 &&AddUserForm.value.userType == 'sales_user'" mat-button type="submit" [disabled]="AddUserForm.invalid" (click)="submitUser()">Save</button>
            
            
            
            <button *ngIf="addUser == 0 && AddUserForm.value.userType== 'System_user'"  mat-button type="submit"  (click)="checkRolePermissionAssigned()">Update
            </button>
            <button *ngIf="addUser1 == 0 &&AddUserForm.value.userType == 'sales_user'" mat-button type="submit" [disabled]="AddUserForm.invalid" (click)="updateUser()">Update</button>
            
            
            <button  *ngIf="addUser1 == 0 && AddUserForm.value.approvalType== 'asm' " mat-button type="submit" [disabled]="AddUserForm.invalid" (click)="checkRolePermissionAssignForApproval_user()">Update
            </button>
            
            <button  *ngIf="addUser1 == 0 && AddUserForm.value.approvalType== 'zsm' " mat-button type="submit" [disabled]="AddUserForm.invalid" (click)="checkRolePermissionAssignForApproval_user()">Update
            </button>
        </div>
    </div>
</div>
</div>
</div>
</div>