import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { DamageScanUploadExcelComponent } from '../damage-scan-upload-excel/damage-scan-upload-excel.component';
// import { TargetExcelUploadComponent } from '../target-excel-upload/target-excel-upload.component';


@Component({
  selector: 'app-damage-scan-coupon',
  templateUrl: './damage-scan-coupon.component.html',
  styleUrls: ['./damage-scan-coupon.component.scss']
})
export class DamageScanCouponComponent implements OnInit {

    targetData: any = [];
    paginationData: any = {};
    loader: any;
    total: any;
    filterSearch: any = {};
    skeleton: any = [];
    date: any = [];
    pageNumber: any;
    lastUpdatedDate: any;
  
    constructor(
  
      public dialog: MatDialog,
      private apiHit: DatabaseService,
      public dialog2: DialogService,
  
    ) {
  
      this.skeleton = new Array(10);
      this.date = new Date();
      this.pageNumber = 1;
      this.getTargetList(1);
    }
  
    ngOnInit(): void {
    }
  
  
    public onDate(event): void {
      this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
      this.getTargetList(1);
    }
  
  
    onChangePageNumber(type) {
  
      console.log(this.pageNumber);
  
      if (type == 'previous') {
  
        if (this.pageNumber > 1) {
  
          this.pageNumber -= 1;
          this.getTargetList(2);
  
        }
  
      }
      else if (type == 'next') {
  
        if (this.total > this.pageNumber) {
  
          this.pageNumber += 1;
          this.getTargetList(2);
  
        }
  
      }
      else if (type = 'current') {
  
        if (this.pageNumber > this.total) {
  
          console.log('current else');
          this.pageNumber = this.total;
          this.getTargetList(2);
  
        }
        else if (this.pageNumber == 0) {
  
          this.pageNumber = 1;
          this.getTargetList(2);
  
        }
        else if (this.pageNumber) {
  
          console.log('last else if');
          this.getTargetList(2);
  
        }
  
      }
  
    }
  
  
    //Get target List Through API Function Start
  
    getTargetList(target) {
  
      this.loader = 1;
      if (target == 1) {
        this.pageNumber = 1;
      }
      this.targetData=[];
      this.paginationData.search=this.filterSearch;
      this.paginationData.pagelimit=20;
      this.paginationData.start=(this.pageNumber - 1)*this.paginationData.pagelimit ;
      
      this.apiHit.PostRequest(this.paginationData, "CouponCode/damage_coupons_list").subscribe((result => {
  
        console.log(result);
        if (result['msg'] == 'Success') {
          this.targetData = result['data'];
          this.total = result['totalPage'];
   
          this.loader = '';
        }
        else {
          this.loader = '';
          this.dialog2.error('error', 'Something Went Wrong');
        }
  
      }));
  
    }
    //Get target List Through API Function End
  
  
  
    // *** Delete Funcation Start ***//
    deleteItem(id) {
  
      this.dialog2.delete('').then((result) => {
  
        if (result === true) {
  
          this.apiHit.PostRequest({ 'target_id': id, }, "SchemeController/delete_distributer_target").subscribe((result => {
  
            console.log(result);
            if (result['Target_delete']['status'] == 'success') {
              this.getTargetList(2);
            }
            else {
              this.dialog2.error('error', 'Something Went Wrong');
            }
  
          }));
  
        }
  
      });
  
    }
    // *** Delete Funcation End ***//
  
  
  
  
    //Excel Upload Function Start 
  
    ExcelUploadFunction(data): void {
  
      console.log(data);
      console.log(data.wallet_balance);
  
      const dialogRef = this.dialog.open(DamageScanUploadExcelComponent, {
  
        width: '400px',
  
        disableClose: true,
  
        data:
        {
          data
        }
  
      });
  
      dialogRef.afterClosed().subscribe(result => {
  
        dialogRef.close();
        this.getTargetList(2);
  
      });
  
    }
    // Excel Upload Function End
  
  
    ExcelDownloadFunction() {
  
      this.loader = 1;
      this.paginationData.pagelimit=0;
      this.apiHit.PostRequest(this.paginationData, "CouponCode/EXPORT_DAMAGE_COUPON_DATA").subscribe((result => {
  
        console.log(result);
        this.getTargetList(1);
        setTimeout(() => {
  
          this.loader = '';
          window.open(this.apiHit.downloadURL+"uploads/damage_coupons.csv");
  
  
        }, 700);
  
      }));
  
    }
  
  
    //Refresh Target List Function Start
  
    refreshData() {
  
      this.filterSearch = {};
      if (this.pageNumber == null) {
        this.pageNumber = 1;
      }
      this.getTargetList(1);
  
    }
    //Refresh Target List Function End
  



}
