<div>
    <div mat-dialog-title class="model-head">
        <h2>Update Billing File</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <div>

        <form #form="ngForm" [formGroup]="HitCron" (ngSubmit)="HitFileFunction()">
            <div mat-dialog-content>
                <div class="cs-form cs-form-model">
                    <div class="row row-mb0">
                        <div class="col s12 m12">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Enter File Name <span class="sub">*</span></mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="file_name" id="file_name">
                            </mat-form-field>
                            <mat-hint class="has-error"
                                *ngIf="HitCron.controls['file_name'].invalid && HitCron.controls['file_name'].touched">
                                File name is required
                            </mat-hint>
                        </div>
                    </div>

                </div>
            </div>
            <mat-dialog-actions align="end">
                <div class="cs-btn">
                    <button mat-button type="submit" [ngClass]="{'loading': skLoading == true}" [disabled]="HitCron.invalid || skLoading">Update</button>
                </div>
            </mat-dialog-actions>
        </form>
    </div>
    <div *ngIf="skLoading" class="loader-overlay">
        <mat-spinner></mat-spinner>
    </div>
</div>