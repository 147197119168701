import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-retailer-scheme',
  templateUrl: './retailer-scheme.component.html',
})
export class RetailerSchemeComponent implements OnInit {

  search: any = {};
  loader: any;
  data: any = [];
  paginationData: any = {};
  pageNumber: any;
  skeleton: any = [];
  schemeData: any = [];
  selectedRetailer: any = [];
  total: any = 0;
  totalTrans: any = 0;
  filterSearch: any = {};
  lastUpdatedDate: any = {};
  totalRetailerScheme: any = {};


  constructor(
    public apiHit: DatabaseService,
    public dialog: DialogService,

  ) {
    this.data = new Array(2);
    this.skeleton = new Array(10);
    this.pageNumber = 1;
    this.retailerSchemeList(1);
  }

  ngOnInit(): void {
  }



  retailerSchemeList(target) {

    this.loader = 1;
    this.lastUpdatedDate = {};
    this.selectedRetailer = [];
    if (this.search.date_from && this.search.date_to) {
      this.paginationData.date_from = moment(this.search.date_from).format('YYYY-MM-DD');
      this.paginationData.date_to = moment(this.search.date_to).format('YYYY-MM-DD');
    }

    if (target == 1) {
      this.pageNumber = 1;
    }
    this.schemeData = [];
    this.paginationData.search = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;

    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/show_pending_scheme_retailers").subscribe((response => {

      console.log(response['result']);
      if (response['result']['status'] == 'success') {
        this.schemeData = response['result']['scheme_range_balance'];
        this.totalRetailerScheme = response['result']['total_row'];
        this.total = response['result']['totalPage'];
        this.lastUpdatedDate = response['result']['last_updated_Date'];
        this.loader = '';
      }
      else {
        this.dialog.error('Error', 'Something Went Wrong. Please Check Your Internet Connectivity');
        this.loader = '';
      }

    }));
  }


  clearDateFilter() {
    this.search.date_from = '';
    this.search.date_to = '';
    this.retailerSchemeList(1);
  }


  updateRetailerScheme() {

    this.loader = 1;

    this.apiHit.PostRequest({ 'selected_retailer': this.selectedRetailer }, "SchemeController/GET_RETAILER_SCHEME_BENIFIT").subscribe((response => {

      console.log(response);
      if (response['status'] == 'Success') {
        this.retailerSchemeList(1);
      }
      else {

      }

    }));
  }



  onChangePageNumber(type) {

    console.log(this.pageNumber);
    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {


          this.pageNumber -= 1;
          this.retailerSchemeList(2);

        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.retailerSchemeList(2);

        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          console.log('current else');

          this.pageNumber = this.total;
          this.retailerSchemeList(2);

        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.retailerSchemeList(2);

        }
        else if (this.pageNumber) {

          console.log('last else');

          this.retailerSchemeList(2);
        }

      }
    }, 100);

  }


  onselectRetailer(event, select) {

    console.log(select);

    if (event.checked == true) {
      this.selectedRetailer.push(select)
    }
    else {
      let isExist = this.selectedRetailer.findIndex(row => row == select)
      if (isExist != -1) {
        this.selectedRetailer.splice(isExist, 1)
      }
    }
    console.log(this.selectedRetailer);

  }

  //Refresh Reatiler Scheme List Function Start

  refreshData() {

    this.filterSearch = {};
    if (this.pageNumber == null) {
      this.pageNumber = 1;
    }
    this.retailerSchemeList(1);
  }
  //Refresh Reatiler Scheme List Function End

  exportExcel() {

    this.loader = 1;
    this.paginationData.pagelimit = 0;
    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/EXPORT_pending_scheme_retailers").subscribe((result => {

      console.log(result);
      if (result['msg'] == 'Success') {
        this.retailerSchemeList(1);
        setTimeout(() => {

          window.open(this.apiHit.downloadURL + "uploads/pending_scheme_retailers.csv");
          this.loader = '';

        }, 700);
      }

    }));
  }

}
