import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { BillingExcelUploadComponent } from '../billing-excel-upload/billing-excel-upload.component';
import { ManualHitFileDownloadComponent } from '../manual-hit-file-download/manual-hit-file-download.component';


@Component({
    selector: 'app-billing-list',
    templateUrl: './billing-list.component.html',
})
export class BillingListComponent implements OnInit {

    pendingOrderData: any = [];
    paginationData: any = {};
    loader: any;
    total: any;
    filterSearch: any = {};
    skeleton: any = [];
    date: any = [];
    excelData: any = [];
    pageNumber: any;
    totalDistributor: any;
    totalBills: any;
    data_val: any;
    updatedDate: any;


    constructor(

        public dialog: MatDialog,
        private apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.skeleton = new Array(10);
        this.date = new Date();
        this.pageNumber = 1;
        // this.getInvoiceList(1);
    }

    clearDateFilter() {
        this.filterSearch.date_from = '';
        this.filterSearch.date_to = '';
        this.getInvoiceList(1);
    }

    ngOnInit(): void {
        this.data_val = this.apiHit.userLoginData.data
        console.log(this.data_val);
        this.paginationData = this.apiHit.getFilterData('billing-list');
        this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        console.log('invoice list filter search ', this.paginationData);
        this.getInvoiceList(this.paginationData?.target ? this.paginationData.target : 1);
    }


    public onDate(event): void {
        this.filterSearch.Bill_Date = moment(event.value).format('DD-MM-YYYY');
        this.getInvoiceList(1);
    }


    //Get Invoice List Through API Function Start

    getInvoiceList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }
        this.paginationData.search = this.filterSearch;
        this.paginationData.pagelimit = 20;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
        this.paginationData.target = target;
        this.paginationData.pageNumber = this.pageNumber;
        if (this.data_val.login_type == 'distributor') {
            this.paginationData.dist_id = this.data_val.id
            this.paginationData.login_type = this.data_val.login_type
        } else if (this.data_val.user_type == 'approval_user') {
            this.paginationData.id = this.data_val.id
            this.paginationData.user_type = this.data_val.user_type

        }
        if (this.filterSearch.date_from) {
            this.filterSearch.date_from = (moment(this.filterSearch.date_from).format('YYYY-MM-DD'));
        }


        if (this.filterSearch.date_to) {
            this.filterSearch.date_to = (moment(this.filterSearch.date_to).format('YYYY-MM-DD'));
        }
        this.apiHit.setFilterData(this.paginationData);

        this.apiHit.PostRequest(this.paginationData, "Distributor/GET_DISTRIBUTOR_INVOICE_BILLING").subscribe((result => {

            console.log(result);
            if (result['status'] == 'Success') {
                this.pendingOrderData = result['data'];
                this.totalBills = result['count']
                this.total = Math.ceil(result['count'] / 20);
                this.updatedDate = result['last_updated']?.['time']
                this.loader = '';
            }
            else {
                this.loader = '';
                this.dialog2.error('error', 'Please Check Your Internet Connectivity');
            }

        }));

    }
    //Get Invoice List Through API Function End



    onChangePageNumber(type) {

        console.log(this.pageNumber);

        if (type == 'previous') {

            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                this.getInvoiceList(2);

            }

        }
        else if (type == 'next') {

            if (this.total > this.pageNumber) {

                this.pageNumber += 1;
                this.getInvoiceList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber > this.total) {

                console.log('current else');
                this.pageNumber = this.total;
                this.getInvoiceList(2);

            }
            else if (this.pageNumber == 0) {

                this.pageNumber = 1;
                this.getInvoiceList(2);

            }
            else if (this.pageNumber) {

                console.log('last else if');
                this.getInvoiceList(2);

            }

        }

    }

    //Excel Download Function Start

    ExcelDownloadFunction() {

        this.loader = 1;

        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_INVOICE_BILLING_DATA").subscribe((result => {

            console.log(result);
            this.getInvoiceList(1);
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/INVOICE_BILLING_DATA.csv");

            }, 700);

        }));

    }

    //Excel Download Function End



    //Excel Upload Modal Function Start 

    ExcelUploadFunction(data): void {

        console.log(data);
        console.log(data.wallet_balance);

        const dialogRef = this.dialog.open(BillingExcelUploadComponent, {

            width: '400px',

            disableClose: true,

            data:
            {
                data,
            }

        });

        dialogRef.afterClosed().subscribe(result => {

            dialogRef.close();
            this.getInvoiceList(2);

        });

    }
    //Excel Upload Modal Function End

    //  Download Hit file function start


    FileHitDownloadSection(data): void {

        console.log(data);
        console.log(data.wallet_balance);

        const dialogRef = this.dialog.open(ManualHitFileDownloadComponent, {

            width: '400px',

            disableClose: true,

            data:
            {
                data,
            }

        });

        dialogRef.afterClosed().subscribe(result => {

            dialogRef.close();
            this.getInvoiceList(2);

        });

    }

    //  Download Hit file function end


    //Refresh Billing Refresh List Function Start

    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null) {
            this.pageNumber = 1;
        }
        this.getInvoiceList(1);

    }
    //Refresh Billing Refresh List Function End

}