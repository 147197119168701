<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Distributor MSL</h2>
                    <p>{{totalDistributorStock ? totalDistributorStock : '0'}} Distributor MSL Available</p>
                </div>
            </ng-container>
            <div class="left-auto" *ngIf="!loader">
                <ul class="date-filter">
                    <li>
                        <div class="cs-form pb0">
                            <div class="col s12 m6 relative">
                                <!-- <p>Select State</p> -->
                                <mat-form-field appearance="outline" class="cs-input" style="width: 250px;">
                                    <mat-label>Select State <span class="sub">*</span></mat-label>
                                    <mat-select [value]="selected_State" name="district" id="district">
                                        <mat-option (click)="selectState(data)" *ngFor="let data of stateData; let i=index" value="{{data}}"> {{data}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="cs-action">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="distributorStockList(1)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw170">Distributor</div>
                            <div class="div_cell mmw80">Segment</div>
                            <div class="div_cell mmw90">Brand</div>
                            <div class="div_cell mw130">Part Code</div>
                            <div class="div_cell mmw80">MSL</div>
                            <div class="div_cell mmw90">Current Stock</div>
                            <div class="div_cell mmw110">Secondary Order</div>
                            <div class="div_cell mmw90">Balance Stock</div>
                            <div class="div_cell mmw120">MSL Vs CS</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw170">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_name"
                                        [(ngModel)]="filterSearch.dr_name" (keyup.enter)="distributorStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw80">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="distributorStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="brand"
                                        [(ngModel)]="filterSearch.brand" (keyup.enter)="distributorStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                        [(ngModel)]="filterSearch.part_code" (keyup.enter)="distributorStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw80">
                            </div>
                            <div class="div_cell mmw90">
                            </div>
                            <div class="div_cell mmw110">
                            </div>
                            <div class="div_cell mmw90 ">
                            </div>
                            <div class="div_cell text-center mmw120 af-bdr-right">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="distributorStockList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <!-- Loop Data -->
                        <main *ngFor="let data of distributorStockData; let i=index"
                            [ngClass]="{
                                        'alert-row':data.default_stock > data.current_stock,
                                        'success-row':data.default_stock < data.current_stock,
                                        'pending-row': data.default_stock == (data.current_stock - data.Secondary_order)
                                        }"
                        >
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw170">{{data.dr_name ? (data.dr_name | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw80">{{data.segment ? (data.segment | titlecase) : 'N/A'}}</div>
                            <div class="div_cell mmw90">{{data.brand ? (data.brand | titlecase) :'N/A'}}</div>
                            <div class="div_cell mw130">{{data.part_code ? data.part_code:'N/A'}}
                                <!-- <a class="link-btn" mat-flat-button  routerLink="../distributor-detail/{{data.id}}">{{data.dr_name ?
                                    data.dr_name:'N/A'}}</a> -->
                            </div>
                            <div class="div_cell mmw80">{{data.default_stock ? data.default_stock:'0'}}</div>
                            <div class="div_cell mmw90">{{data.current_stock ? data.current_stock:'0'}}</div>
                            <div class="div_cell mmw110">{{data.Secondary_order ? data.Secondary_order:'0'}}</div>
                            <div class="div_cell mmw90  ac">{{data.current_stock ?
                                (data.current_stock - data.Secondary_order) : '0' }}</div>
                            <div class="div_cell ac mmw120">
                                <div class="cs-action">
                                    {{data.current_stock ? data.default_stock -
                                    (data.current_stock - data.Secondary_order) : '0' }}
                                    <!-- <button class="ac-green with-text" matRipple matTooltip="Export To Excel"
                                        matTooltipPosition="below">Export To Excel</button> -->
                                </div>
                            </div>
                        </main>
                        <!-- Loop Data -->
                        <app-not-found *ngIf="distributorStockData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw170">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw80">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell ac mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>
                </section>
            </div>

            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac" *ngIf="distributorStockData.length > 0 && total > 1">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="distributorStockData.length > 0"
                            (click)="exportDistributorDataInExcel()" class="ml10"><img src="assets/img/excel.png">
                            Download Excel</a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>