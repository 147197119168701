import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';
import { MatDialog } from '@angular/material/dialog';
import { RetailerEditComponent } from '../retailer-edit/retailer-edit.component';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';

@Component({
    selector: 'app-retailer-detail',
    templateUrl: './retailer-detail.component.html'
})

export class RetailerDetailComponent implements OnInit {
    
    retailerDetailData: any;
    login_data: any;
    personDetailData: any;
    reatilerInfoData: any;
    mechanicData: any;
    mechanicTransection: any = 0;
    transectionDistributor: any;
    transferDetails: any;
    assignDistributor: any;
    assignSalesUser: any;
    loader: any;
    skeleton: any = [];
    skData: any = [];
    skDataTwo: any = [];
    scanData: any = [];
    searchData: any = {};
    searchMechanic: any = {};
    searchDistributorTransaction: any = {};
    searchMechanicTransaction: any = {};
    searchTransferDetails: any = {};
    searchScanData: any = {};
    retailerInfo: any = {};
    date: any;
    data_val: any;
    matTabValue: any;
    pageNumber: any;
    paginationData: any = {};
    salesPointHistory: any = [];
    filterSearch: any = {};
    salesUser=[];
    
    constructor(
        
        public dialog: MatDialog,
        public activateRoute: ActivatedRoute,
        private apiHit: DatabaseService,
        private _location: Location,
        public dialog2: DialogService,
        
        ) {
            
            console.log(this.activateRoute['params']['_value']);
            console.log(this.apiHit.userLoginData);
            this.data_val = this.apiHit.userLoginData.data
            console.log(this.data_val,"this is data Val");
            this.login_data = this.data_val
            this.retailerDetail();
            this.salesUserdata();
            this.skeleton = new Array(10);
            this.skData = new Array(4)
            this.skDataTwo = new Array(2);
            this.date = new Date();
            this.matTabValue = 1;
            
        }
        
        ngOnInit(): void {
            
            
            
        }
        
        
        // Back Button Function  Start
        backClicked() {
            
            this._location.back();
            
        }
        // Back Button Function  End
        
    
        
        
        //Reatailer Detail Function Start
        retailerDetail() {
            console.log(this.data_val,"this is data Val");
            this.loader = 1;
            let PayloadData ={}
            if(this.data_val.login_type == 'distributor'){
                
                PayloadData = {'login_type':this.data_val.login_type,'user_Id': this.apiHit.userLoginData.data.id, 'retailer_Id': this.activateRoute['params']['_value'].id}
            }else{
                
                PayloadData = {'user_Id': this.apiHit.userLoginData.data.id, 'retailer_Id': this.activateRoute['params']['_value'].id}
            }
            this.apiHit.PostRequest(PayloadData, "Distributor/retailer_details").subscribe((result => {
                
                console.log(result);
                
                this.assignDistributor = result['retailer_details']['DistributorData'];
                this.assignSalesUser = result['retailer_details']['assign_sales_users'];
                this.retailerInfo = result['retailer_details'];
                this.retailerDetailData = result['retailer_details']['details'][0];
                
                this.personDetailData = result['retailer_details']['contact_person_info'];
                this.reatilerInfoData = result['retailer_details']['retailer_assigned_info'];
                this.mechanicData = result['retailer_details']['mechanic_assigned_info'];
                this.transectionDistributor = result['retailer_details']['distributor_to_retailer_transection'];
                
                this.transferDetails = result['retailer_details']['transferTransactions'].Details;
                
                
                this.mechanicTransection = result['retailer_details']['retailer_to_mechanic_transection'];
                this.scanData = result['retailer_details']['retailer_coupon_scan'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        salesUserdata(){

            this.apiHit.PostRequest({'retailer_Id':this.activateRoute['params']['_value'].id }, "Distributor/getSalesUser").subscribe((result => {
                
                console.log(result);
                
                this.salesUser = result['assign_sales_users'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Reatailer Detail Function End
          //Delete Entry Function Start

    deleteEntry(id) {

        console.log(id);

        let data = { "id":id}

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(data, "Distributor/removeRetailerToMechanicTransaction").subscribe((result => {

                    console.log(result);

                    if (result['status'] == 'Success') {
                        this.dialog2.success('success',result['status']);
                        this.retailerDetail();

                    }
                    else if(result['status'] == 'Failed'){
                        this.dialog2.error('error',result['status']);
                    }
                    else {
                        this.dialog2.error('error', result['status']);
                    }

                }));

            }

        });

    }
    //Delete Entry Function End
        
        
        
        //Search Mechnaic List Through API Function Start
        searchMechanicList() {
            
            this.loader = 1;
            this.mechanicData = [];
            this.searchData = this.searchMechanic;
            
            // return;
            this.apiHit.PostRequest({ 'id': this.retailerDetailData.id, 'searchData': this.searchData }, "Distributor/retailer_mechanic_search").subscribe((result => {
                
                console.log(result);
                
                this.mechanicData = result['retailer_mechanic_data'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Search Mechnaic List Through API Function End
        
        
        
        //Search Distributor To Retailer Transaction Through API Function Start
        distributorToRetailerTransaction() {
            
            this.loader = 1;
            this.transectionDistributor = [];
            this.searchData = this.searchDistributorTransaction;
            // return;
            
            this.apiHit.PostRequest({ 'id': this.retailerDetailData.id, 'searchData': this.searchData }, "Distributor/retailer_transaction_from_dist_search").subscribe((result => {
                
                console.log(result);
                // return
                this.transectionDistributor = result['distributor_to_retailer_transection'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Search Distributor To Retailer Transaction Through API Function End
        
        
        
        //Search Retailer To Mechanic Transaction Through API Function Start
        
        
        
        retailerToMechanicTransaction() {
            
            this.loader = 1;
            this.mechanicTransection = [];
            this.searchData = this.searchMechanicTransaction;
            console.log(this.searchData);
            // return;
            
            this.apiHit.PostRequest({ 'id': this.retailerDetailData.id, 'searchData': this.searchData }, "Distributor/retailer_to_mechanic_transaction_search").subscribe((result => {
                
                console.log(result);
                
                this.mechanicTransection = result['retailer_to_mechanic_transaction_search']['data'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Search Retailer To Mechanic Transaction Through API Function End
        
        
        transferdetailsearch() {
            
            this.loader = 1;
            this.transferDetails = [];
            this.searchData = this.searchTransferDetails;
            console.log(this.searchData);
            // return;
            
            this.apiHit.PostRequest({ 'id': this.retailerDetailData.id, 'searchData': this.searchData }, "Distributor/transferTransactionSearch").subscribe((result => {
                
                console.log(result);
                
                this.transferDetails = result['transferTransactionSearch'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
            
        }
        
        
        //Search Scan Transaction Through API Function Start
        scanDataDetails() {
            
            this.loader = 1;
            this.scanData = [];
            this.searchData = this.searchScanData;
            console.log(this.searchData);
            
            this.apiHit.PostRequest({ 'id': this.retailerDetailData.id, 'searchData': this.searchData }, "Distributor/retailer_coupon_scan_search").subscribe((result => {
                
                console.log(result);
                this.scanData = result['retailer_coupon_scan_search'];
                
                setTimeout(() => {
                    
                    this.loader = '';
                    
                }, 700);
                
            }));
            
        }
        //Search Retailer To Mechanic Transaction Through API Function End
        
        
        
        //Delete Assign Distributor To Retailer Function Start
        deleteAssignDistributor(id) {
            
            this.dialog2.delete('').then((result) => {
                
                if (result === true) {
                    
                    this.apiHit.PostRequest({ "id": id }, "Distributor/delete_retailer_distributor").subscribe((result => {
                        
                        console.log(result);
                        
                        if (result['delete_retailer_distributor'] == 'deleted') {
                            
                            this.retailerDetail();
                            
                        } else {
                            
                            this.dialog2.error('error', result['errors']);
                            
                        }
                        
                    }));
                    
                }
                
            });
            
        }
        //Delete Assign Distributor To Retailer Function End
        
        //Delete Assign Distributor To Retailer Function Start
        deleteAssignSalesUser(id) {
            let data = {};
            data['assign_id'] = id;
            data['uid'] = this.apiHit.userLoginData.data.id;
            this.dialog2.delete('').then((result) => {
                
                if (result === true) {
                    
                    this.apiHit.PostRequest(data, "Distributor/deleteAssignUser").subscribe((result => {
                        
                        console.log(result);
                        
                        if (result['status'] == "success") {
                            
                            this.retailerDetail();
                            
                        } else {
                            
                            this.dialog2.error('error', result['errors']);
                            
                        }
                        
                    }));
                    
                }
                
            });
            
        }
        //Delete Assign Distributor To Retailer Function End
        
        
        getSalesHistory(type) {
            
            this.loader = 1;
            
            if (type == 1) {
                this.pageNumber = 1;
            }
            this.paginationData.search = this.filterSearch;
            this.paginationData.pagelimit = 20;
            this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
            this.paginationData.retailer_id = this.activateRoute['params']['_value'].id;
            
            console.log(this.paginationData);
            
            this.apiHit.PostRequest(this.paginationData, "SchemeController/get_retailer_sales_point_detail").subscribe((result => {
                
                console.log(result);
                
                if (result['sales_point']['status'] == 'success') {
                    
                    this.salesPointHistory = result['sales_point']['sales_point_detail'];
                    this.loader = '';
                    
                } else {
                    
                    this.dialog2.error('error', 'Something Went Wrong');
                    this.loader = '';
                    
                }
                
            }));
            
        }
        
        
        tabClick(tab) {
            console.log(tab['tab']['textLabel']);
            this.matTabValue = tab['tab']['textLabel'];
            this.searchScanData = {};
            this.searchData = {};
            this.searchMechanic = {};
            this.searchDistributorTransaction = {};
            this.searchMechanicTransaction = {};
            this.searchTransferDetails = {};
            if (tab['tab']['textLabel'] == 7) {
                this.getSalesHistory(1);
            }
            else {
                this.retailerDetail();
            }
        }
        
        
        //Delete Contact Function Start
        
        deleteContatctInfo(id) {
            
            console.log(id);
            
            this.dialog2.delete('').then((result) => {
                
                if (result === true) {
                    
                    this.apiHit.PostRequest({ 'id': id }, "Distributor/delete_contact_person").subscribe((result => {
                        
                        console.log(result);
                        
                        if (result['delete_contact_person'] == 'success') {
                            
                            this.retailerDetail();
                            
                        }
                        
                        else {
                            
                            this.dialog2.error('error', result['errors']);
                            
                        }
                        
                    }));
                    
                }
                
            });
            
        }
        //Delete Contact Function End
        
        
        //Distributor To Retailer Date Created Search Function Start 
        public onDate(event): void {
            
            this.searchDistributorTransaction.dateCreated = moment(event.value).format('YYYY-MM-DD');
            this.distributorToRetailerTransaction();
            
        }
        //Distributor To Retailer Date Created Search Function END 
        
        
        //Distributor To Retailer Date Created Search Function Start 
        public onDate2(event): void {
            this.searchScanData.date_created = moment(event.value).format('YYYY-MM-DD');
            this.scanDataDetails();
            
        }
        //Distributor To Retailer Date Created Search Function END 
        
        
        //Scan Detail Date Created Search Function Start 
        public onDate3(event): void {
            this.searchScanData.scan_date = moment(event.value).format('YYYY-MM-DD');
            this.scanDataDetails();
            
        }
        //Scan Detail Date Created Search Function END 
        
        
        //Retailer To Mechanic Transaction Date Created Search Function Start 
        public onDate4(event): void {
            
            this.searchMechanicTransaction.date_created = moment(event.value).format('YYYY-MM-DD');
            this.retailerToMechanicTransaction();
            
        }
        
        
        
        public onDate5(event): void {
            
            this.searchTransferDetails.date_created = moment(event.value).format('YYYY-MM-DD');
            this.transferdetailsearch();
            
        }
        //Retailer To Mechanic Transaction Date Created Search Function END 
        
        
        public onDate6(event): void {
            
            this.filterSearch.sales_point_credit_date = moment(event.value).format('YYYY-MM-DD');
            this.getSalesHistory(1);
            
        }
        
        
        //Refresh Retailer List Function Start
        
        refreshData(target) {
            
            console.log(target);
            
            if (target == 1) {
                
                this.searchMechanic = {};
                this.searchMechanicList();
                
            }
            else if (target == 2) {
                
                this.searchDistributorTransaction = {};
                this.distributorToRetailerTransaction();
                
            }
            else if (target == 3) {
                
                this.searchMechanicTransaction = {};
                this.retailerToMechanicTransaction();
                
            }
            
            else if (target == 4) {
                
                this.searchTransferDetails = {};
                this.transferdetailsearch();
                
            }
            
            else if (target == 5) {
                
                this.searchScanData = {};
                this.scanDataDetails();
                
            }
            else {
                this.filterSearch = {};
                this.getSalesHistory(2);
            }
            
        }
        //Refresh Retailer List Function End
        
        
        //Edit Retailer Detail (Open Dialog Box) Function Start 
        
        RetailerInfoEdit(target, data): void {
            
            const dialogRef = this.dialog.open(RetailerEditComponent, {
                
                width: '',
                panelClass: "modelwidth",
                data: {
                    
                    target,
                    data,
                    
                }
                
            });
            
            dialogRef.afterClosed().subscribe(result => {
                
                this.retailerDetail();
                
            });
            
        }
        //Edit Retailer Detail (Open Dialog Box) Function End 
        //Edit Retailer Detail (Open Dialog Box) Function Start 
        
        SalesUserInfoEdit(target, data): void {
            console.log(data)
            const dialogRef = this.dialog.open(RetailerEditComponent, {
                
                width: '',
                panelClass: "modelwidth",
                data: {
                    
                    target,
                    data,
                    
                }
                
            });
            
            dialogRef.afterClosed().subscribe(result => {
                
                this.retailerDetail();
                
            });
            
        }
        //Edit Retailer Detail (Open Dialog Box) Function End 
        
    }