import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';


@Component({
    selector: 'app-incentive-list',
    templateUrl: './incentive-list.component.html',
})
export class IncentiveListComponent implements OnInit {

    incentiveData: any = [];
    paginationData: any = {};
    loader: any;
    total: any;
    data_val: any;
    filterSearch: any = {};
    skeleton: any = [];
    date: any = [];
    pageNumber: any;
    lastUpdatedDate: any;

    constructor(

        public dialog: MatDialog,
        private apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.skeleton = new Array(10);
        this.date = new Date();
        this.pageNumber = 1;
        // this.getincentiveList(1);
    }

    ngOnInit(): void {
        this.data_val = this.apiHit.userLoginData.data
        console.log(this.data_val);
        this.paginationData = this.apiHit.getFilterData('incentive-list');
        this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        console.log('retailer incentive list filter search ', this.paginationData);
        this.getincentiveList(this.paginationData?.target ? this.paginationData.target : 1);
    }



    //Get DIstributor List Through API Function Start

    getincentiveList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }
        this.paginationData.search = this.filterSearch;
        this.paginationData.pagelimit = 20;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
        if (this.filterSearch.date_from) {
            this.filterSearch.date_from = moment(this.filterSearch.date_from).format('YYYY-MM-DD');
        }
        if (this.filterSearch.date_to) {
            this.filterSearch.date_to = moment(this.filterSearch.date_to).format('YYYY-MM-DD');
        }
        this.paginationData.target = target;
        this.paginationData.pageNumber = this.pageNumber;
        if(this.data_val.login_type == 'distributor'){
            this.paginationData.dist_id = this.data_val.id
            this.paginationData.login_type = this.data_val.login_type
          }
        if (this.apiHit.userLoginData.data.user_type == 'approval_user') {
            this.paginationData.user_id = this.apiHit.userLoginData.data.id;
        }
        else {
            this.paginationData.user_id = ''
        }
        this.apiHit.setFilterData(this.paginationData);

        this.apiHit.PostRequest(this.paginationData, "SchemeController/GET_RETAILER_SCHEME_BENIFIT_LIST").subscribe((result => {

            // console.log(result);
            if (result['status'] == 'Success') {
                this.incentiveData = result['data_info'];
                this.total = result['data_info']['totalPage'];
                this.loader = '';
            }
            else {
                this.loader = '';
                this.dialog2.error('error', 'Something Went Wrong');
            }

        }));

    }
    //Get DIstributor List Through API Function End


    public onDate(event): void {
        this.filterSearch.date = moment(event.value).format('YYYY-MM-DD');
        this.getincentiveList(1);
    }


    onChangePageNumber(type) {

        console.log(this.pageNumber);

        if (type == 'previous') {

            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                this.getincentiveList(2);

            }

        }
        else if (type == 'next') {

            if (this.total > this.pageNumber) {

                this.pageNumber += 1;
                this.getincentiveList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber > this.total) {

                console.log('current else');
                this.pageNumber = this.total;
                this.getincentiveList(2);

            }
            else if (this.pageNumber == 0) {

                this.pageNumber = 1;
                this.getincentiveList(2);

            }
            else if (this.pageNumber) {

                console.log('last else if');
                this.getincentiveList(2);

            }

        }

    }


    exportRetailerIncentive() {

        this.loader = 1;
        this.paginationData.pagelimit = 0;
        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_RETAILER_INCENTIVE_DATA").subscribe((result => {

            console.log(result);
            this.getincentiveList(1);
            setTimeout(() => {
                this.loader = '';
                window.open(this.apiHit.downloadURL + "uploads/RETAILER_INCENTIVE_DATA.csv")
            }, 700);

        }));
    }

    //Refresh Distributor List Function Start

    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null) {
            this.pageNumber = 1;
        }
        this.getincentiveList(1);

    }
    //Refresh Distributor List Function End

}