<div class="login wp100">
    <div class="login-container">
        
        <div class="login-column">
            <div class="intro">
                <h2><span>A</span>sk</h2> 
                <h2><span>R</span>ewards</h2>
                <h2><span>P</span>rogram</h2>
            </div>
        </div>
        
        <div class="login-column">
            <form #reset="ngForm" [formGroup]="logInForm" (ngSubmit)="logIn(reset)"> 
                <!-- Login Form Start -->
                <div class="login-form">
                    <div class="login-logo">
                        <img src="assets/img/logo.png" alt="logo">
                    </div>
                    
                    
                    <div class="form">
                        <span class="info">Enter Your Username And Password</span>
                        
                        <mat-form-field appearance="outline" class="login-fields">
                            <mat-label>Username</mat-label>
                            <input matInput placeholder="Type Here.." formControlName="userName" id="userName">
                            <mat-icon class="inputIcon">person</mat-icon>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="login-fields">
                            <mat-label>Password</mat-label>
                            <input matInput placeholder="Type Here ..." [type]="hide ? 'password' : 'text'" formControlName="password"
                            id="password" (keypress)="NotAllowedSpace($event)">
                            <mat-icon class="inputIcon" style="transform: rotate(-20deg);">vpn_key</mat-icon>
                            <a mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </a>
                    </mat-form-field>
                    
                    <div class="login-btn">
                        <button mat-raised-button type="submit" [disabled]="logInForm.invalid || loader == 1" matTooltipPosition="below"
                        matTooltip="Login" color="accent" class="loading">Login</button>
                    </div>
                </div>
            </div>
            <!-- Login Form End -->
        </form>
    </div>
</div>
</div>