import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckinDetailComponent } from '../checkin-detail/checkin-detail.component';
@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {
  start_attend_time: string;
  loader: boolean = false;
  datanotfound = false;
  data: any = {};
  checkins: any = [];
  checkins_count: any = {};
  attendanceDate: any = [];
  show_today: boolean = true;
  assign_login_data2: any = [];
  assign_login_data: any = [];
  skelton: any = {};
  today_date = new Date().toISOString().slice(0, 10);
  searchData: any;
  backButton: boolean = false;
  districtAppend: String;
  report_manager: any = [];
  pageCount: any;
  total_page: any;
  page_limit: any;
  pagenumber: any = 1;
  start: any = 0;
  downurl: any = ''
  sr_no: number;
  constructor(private apiHit : DatabaseService,private dialoge :DialogService, public dialog2: MatDialog,public service:DatabaseService,) {
    console.log(service.downloadExcelURL);
    this.downurl = service.downloadExcelURL1;
    console.log(this.downurl);
    
    this.getReportManager('');

    this.CheckinList('todayCheckinList', 1);
  
  this.getNetworkType();
}

ngOnInit() { }

pervious(type) {
  this.start = this.start - this.page_limit;
  if (type == 'todayCheckinList') {
    this.CheckinList('todayCheckinList', 1);
  }
  else {
    this.CheckinList('checkinAll', 1);
  }
}

nextPage(type) {
  this.start = this.start + this.page_limit;
  if (type == 'todayCheckinList') {
    this.CheckinList('todayCheckinList', 1);
  }
  else {
    this.CheckinList('checkinAll', 1);
  }
}
change_tab(fn_name, type) {
  this.checkins = [];
  this.data = {};
  this.CheckinList(fn_name, type);
}
getReportManager(search) {
  this.apiHit.PostRequest({ 'search': search }, "Checkin/getSalesUserForReporting").subscribe((result => {
    if (result['all_sales_user']['statusCode'] == 200) {
      this.report_manager = result['all_sales_user']['all_sales_user']
    }
    else {
      this.dialoge.error('Error',result['all_sales_user']['statusMsg'])
    }
  }));
}
refresh(func_name, type,) {
  this.start = 0;
  this.data = {};
  this.CheckinList(func_name, type)
}

networkType: any = [];
getNetworkType() {
  this.apiHit.PostRequest({ 'type': 'checkin' }, "Checkin/allNetworkModule").subscribe((result => {
    if (result['statusCode'] == 200) {
      this.networkType = result['modules'];
    }
    else {
      this.dialoge.error('Error',result['statusMsg'])
    }
  }))
}


CheckinList(func_name, type) {

  this.loader = true;
  if (this.pagenumber > this.total_page) {
    this.pagenumber = this.total_page;
    this.start = this.pageCount - this.page_limit;
  }
  if (this.start < 0) {
    this.start = 0;
  }
  this.apiHit.PostRequest({ 'user_id': this.assign_login_data2.id, 'start': this.start, 'pagelimit': this.page_limit, 'search': this.data, 'user_type': this.assign_login_data2.type }, "Checkin/" + func_name)
    .subscribe(((result: any) => {
      console.log('download excel 0')

      if (result['statusCode'] == 200) {
        this.loader = false;
        if (func_name == 'checkinAll') {
          this.checkins_count = result['result'];
          this.checkins = (result['result']['checkin_data']);
          this.pageCount = result['count'];
          this.attendanceDate = result['result']['header_date'];
          this.show_today = false;

        }
        else {
          this.show_today = true;
          this.checkins = (result['result']);
          this.pageCount = result['count'];
        }
        for (let i = 0; i < this.checkins.length; i++) {
          this.checkins[i].order_grand_total = parseFloat(this.checkins[i].order_grand_total)
          this.checkins[i].order_grand_total = this.checkins[i].order_grand_total.toFixed(2)
          if (this.checkins[i].order_grand_total == "NaN") {
            this.checkins[i].order_grand_total = 0;
          }
        }


        if (this.pagenumber > this.total_page) {
          this.pagenumber = this.total_page;
          this.start = this.pageCount - this.page_limit;
        }
        else {
          this.pagenumber = Math.ceil(this.start / this.page_limit) + 1;
        }

        this.total_page = Math.ceil(this.pageCount / this.page_limit);
        this.sr_no = this.pagenumber - 1;
        this.sr_no = this.sr_no * this.page_limit;

        if (this.checkins.length == 0) {
          this.datanotfound = true;
        } else {
          this.datanotfound = false;
        }
        setTimeout(() => {
          this.loader = false;

        }, 100);
      } else {
        setTimeout(() => {
          this.loader = false;
        }, 100);
        this.dialoge.error('Error',result['statusMsg'])
      }
    }))
}

checkinDetail(user_id, date) {
  const dialogRef = this.dialog2.open(CheckinDetailComponent , {
    panelClass: 'full-width-modal',
    data: {
      'user_id': user_id,
      'date': date,
    }
  });

  dialogRef.afterClosed().subscribe(result => {
  });

}
exportAsXLSX(func_name, type) {
  console.log('download excel 1')
  this.loader = true
  this.apiHit.PostRequest({ 'user_id': this.assign_login_data2.id, 'search': this.data, 'user_type': this.assign_login_data2.type }, "Excel/" + func_name)
    .subscribe(((result: any) => {
      console.log('download excel 2')
      if (result['msg'] == true) {
        this.loader = false;
        window.open(this.downurl + result['filename'])
        this.CheckinList(func_name, type);
      } else {
        this.loader = false;
      }
    }), err => {
      this.loader = false;
    });

}
}
