<div class="container-fluid">
    <div class="container">
        <section id="head">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                routerLink="../distributor" routerLinkActive="active">
                <mat-icon>arrow_back</mat-icon>
            </a>

            <div class="module-name">
                <h2>Distributor</h2>
                <p>Add New</p>
            </div>

            <div class="left-auto df afe">
                <div class="indicates">
                    Indicates required fields ( <span>*</span> )
                </div>
            </div>
        </section>

        <div class="container-pd10-5">
            <form #form="ngForm" [formGroup]="AddDistributorForm" (ngSubmit)="checkDrCodeDuplicacy()">
                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>basic Information</h2>
                            </div>

                            <div class="cs-form pb0">
                                <div class="row row-mb0">
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Firm Name <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="firmName"
                                                id="firmName" maxlength="40">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.firmName.invalid && 
                                            AddDistributorForm.controls['firmName']?.touched && !AddDistributorForm.controls['firmName']['errors']?.minlength ">

                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.firmName.invalid && 
                                            AddDistributorForm.controls['firmName']['errors']?.minlength || AddDistributorForm.controls['firmName']['errors']?.maxlength">
                                            Required 3-40 Characters
                                        </mat-hint>
                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Name <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="firstName"
                                                id="firstName" maxlength="40">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.firstName.invalid && 
                                            AddDistributorForm.controls['firstName']?.touched && !AddDistributorForm.controls['firstName']['errors']?.minlength ">

                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.firstName.invalid && 
                                            AddDistributorForm.controls['firstName']['errors']?.minlength || AddDistributorForm.controls['firstName']['errors']?.maxlength">
                                            Required 3-40 Characters
                                        </mat-hint>
                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Mobile No. <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="mobileNo"
                                                id="mobileNo" maxlength="10" (ngModelChange)="checkMobileNoDuplicacy()"
                                                (keypress)="OnlyNumber($event)">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.mobileNo.invalid && 
                                            AddDistributorForm.controls['mobileNo']?.touched && !AddDistributorForm.controls['mobileNo']['errors']?.minlength ">

                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.mobileNo.invalid && 
                                            AddDistributorForm.controls['mobileNo']['errors']?.minlength || AddDistributorForm.controls['mobileNo']['errors']?.maxlength">
                                            Required 10 Numbers
                                        </mat-hint>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Email Id <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="email"
                                                id="email">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.email.invalid && 
                                            AddDistributorForm.controls['email']?.touched && !AddDistributorForm.controls['email']['errors']?.pattern">

                                        </mat-hint>
                                        <mat-hint class="has-error" *ngIf="AddDistributorForm.controls.email?.invalid && 
                                            AddDistributorForm.controls['email']['errors']?.pattern">
                                            Invalid Email
                                        </mat-hint>
                                    </div>
                                </div>
                                <div class="row row-mb0">
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Date of Birth</mat-label>
                                            <!-- <input matInput [matDatepicker]="birth" formControlName="DOB" 
                                            id="DOB" [max]="date" (ngModelChange)="minDateSelect()" readonly (click)="birth.open()"> -->


                                            <input matInput [matDatepicker]="birth" formControlName="DOB" id="DOB"
                                                [min]="minDate" [max]="maxDate" (ngModelChange)="minDateSelect()"
                                                readonly (click)="birth.open()">


                                            <mat-datepicker-toggle matSuffix [for]="birth"></mat-datepicker-toggle>
                                            <mat-datepicker #birth disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Date of Anniversary</mat-label>
                                            <input matInput [matDatepicker]="anniversary" formControlName="anniversary"
                                                id="anniversary" [min]="minDate" [max]="date" readonly
                                                (click)="anniversary.open()">
                                            <mat-datepicker-toggle matSuffix
                                                [for]="anniversary"></mat-datepicker-toggle>
                                            <mat-datepicker #anniversary disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>GST Number <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="gstNo"
                                                id="gstNo" maxlength="15">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.gstNo.invalid && 
                                            AddDistributorForm.controls['gstNo']?.touched && !AddDistributorForm.controls['gstNo']['errors']?.minlength ">
                                            <!-- &&  !AddDistributorForm.controls['gstNo']['errors']?.pattern  -->
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.gstNo.invalid && 
                                            AddDistributorForm.controls['gstNo']['errors']?.minlength || AddDistributorForm.controls['gstNo']['errors']?.maxlength ">
                                            <!-- &&!AddDistributorForm.controls['gstNo']['errors']?.pattern
                                            Required 15 Characters -->
                                            Required 15 Characters
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.gstNo?.invalid && !AddDistributorForm.controls['gstNo']['errors']?.minlength">
                                        </mat-hint>

                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>PAN Number</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="panNo"
                                                id="panNo" maxlength="10">
                                        </mat-form-field>
                                        <!-- <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.panNo?.invalid && 
                                            AddDistributorForm.controls['panNo']['errors']?.pattern && !AddDistributorForm.controls['panNo']['errors']?.minlength">
                                            Invalid PAN No
                                        </mat-hint> -->
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.panNo.invalid && 
                                            AddDistributorForm.controls['panNo']['errors']?.minlength || AddDistributorForm.controls['panNo']['errors']?.maxlength">
                                            Required 10 Characters
                                        </mat-hint>
                                    </div>
                                </div>


                                <div class="row row-mb0">
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Distributor Code <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="drCode"
                                                id="drCode">
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddDistributorForm.controls.drCode.invalid && 
                                            AddDistributorForm.controls['drCode']?.touched">
                                        </mat-hint>
                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Credit Limit </mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="credit_limit"
                                                id="credit_limit">
                                        </mat-form-field>
                                        <!-- <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.drCode.invalid && 
                                            AddDistributorForm.controls['drCode']?.touched">
                                        </mat-hint> -->
                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Outstanding</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="outstanding"
                                                id="outstanding">
                                        </mat-form-field>
                                        <!-- <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.drCode.invalid && 
                                            AddDistributorForm.controls['drCode']?.touched">
                                        </mat-hint> -->
                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Select Category </mat-label>
                                            <mat-select [value]="category" formControlName="category"
                                                name="category" id="category">
                                                <mat-option *ngFor="let data of category; let i=index"
                                                    value="{{data.category}}"> {{data.category}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>Contact Person Information</h2>
                            </div>

                            <div class="cs-form pb0">
                                <div class="row row-mb0">
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Name</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="cPersonName"
                                                id="cPersonName" maxlength="30">

                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.cPersonName.invalid && 
                                            AddDistributorForm.controls['cPersonName']['errors']?.minlength || AddDistributorForm.controls['cPersonName']['errors']?.maxlength">
                                            Required 3-30 Characters
                                        </mat-hint>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Mobile No</mat-label>
                                            <input matInput placeholder="Type Here ..."
                                                formControlName="cPersonMobileNo" id="cPersonMobileNo" maxlength="10"
                                                (keypress)="OnlyNumber($event)">
                                        </mat-form-field>

                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.cPersonName.invalid && 
                                        AddDistributorForm.controls['cPersonName']?.touched && !AddDistributorForm.controls['cPersonName']['errors']?.minlength ">

                                        </mat-hint>

                                        <!-- <ng-container *ngIf="AddDistributorForm.controls.cPersonName.value"> -->
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.cPersonMobileNo.invalid && 
                                            AddDistributorForm.controls['cPersonMobileNo']['errors']?.minlength || AddDistributorForm.controls['cPersonMobileNo']['errors']?.maxlength">
                                            Required 10 Numbers
                                        </mat-hint>
                                        <!-- </ng-container> -->


                                    </div>
                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Email Id</mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="cPersonEmail"
                                                id="cPersonEmail">
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddDistributorForm.controls.cPersonEmail?.invalid && 
                                            AddDistributorForm.controls['cPersonEmail']['errors']?.pattern">
                                            Invalid Email
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>address Information</h2>
                            </div>

                            <div class="cs-form pb0">
                                <div class="row row-mb0">

                                    <div class="col s12 m6 pading0">
                                        <div class="row row-mb0">
                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Pincode <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..." formControlName="pinNo"
                                                        id="pinNo" maxlength="6" (keypress)="OnlyNumber($event)"
                                                        (input)="enterPin(AddDistributorForm)">
                                                </mat-form-field>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddDistributorForm.controls.pinNo.invalid && 
                                                    AddDistributorForm.controls['pinNo']?.touched && !AddDistributorForm.controls['pinNo']['errors']?.minlength ">

                                                </mat-hint>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddDistributorForm.controls.pinNo.invalid && 
                                                    AddDistributorForm.controls['pinNo']['errors']?.minlength || AddDistributorForm.controls['pinNo']['errors']?.maxlength">
                                                    Required 6 Numbers
                                                </mat-hint>
                                            </div>
                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>State <span class="sub">*</span></mat-label>
                                                    <mat-select (keyup.enter)="districtList1()" [value]="stataData"
                                                        formControlName="state" name="state" id="state">

                                                        <ngx-mat-select-search formControlName="searchCtrl"
                                                            [placeholderLabel]="'Search...'"
                                                            [noEntriesFoundLabel]="'Not found'"
                                                            name="search"></ngx-mat-select-search>

                                                        <mat-option (click)="districtList(data)"
                                                            *ngFor="let data of stateData |stringFilterBy:AddDistributorForm.value.searchCtrl:'data'"
                                                            value="{{data}}">
                                                            {{data}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddDistributorForm.controls.state.invalid && 
                                                    AddDistributorForm.controls['state']?.touched">

                                                </mat-hint>
                                            </div>

                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12 m6 relative">

                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>District <span class="sub">*</span></mat-label>
                                                    <mat-select [value]="selectedDistrict" formControlName="district"
                                                        name="district" id="district">
                                                        <mat-option *ngFor="let data of districtData; let i=index"
                                                            value="{{data}}"> {{data}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <!-- <div class="inputLoading">
                                                   <img src="assets/img/spinner.svg">
                                                </div> -->
                                                <mat-hint class="has-error" *ngIf="AddDistributorForm.controls.district.invalid && 
                                                    AddDistributorForm.controls['district']?.touched">

                                                </mat-hint>
                                            </div>

                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>City <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..." formControlName="city"
                                                        id="city" maxlength="30">
                                                </mat-form-field>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddDistributorForm.controls.city.invalid && 
                                                    AddDistributorForm.controls['city']?.touched && !AddDistributorForm.controls['city']['errors']?.minlength ">

                                                </mat-hint>
                                                <mat-hint class="has-error"
                                                    *ngIf="AddDistributorForm.controls.city.invalid && 
                                                    AddDistributorForm.controls['city']['errors']?.minlength || AddDistributorForm.controls['city']['errors']?.maxlength">
                                                    Required 3-30 Characters
                                                </mat-hint>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Address</mat-label>
                                            <textarea matInput placeholder="Type Here ..." class="h70"
                                                formControlName="address" id="address"></textarea>
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddDistributorForm.controls.address.invalid && 
                                            AddDistributorForm.controls['address']['errors']?.minlength || AddDistributorForm.controls['address']['errors']?.maxlength">
                                            Required Minimum 3 Characters
                                        </mat-hint>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12">
                        <div class="cs-btn">
                            <button mat-button type="submit" [disabled]="AddDistributorForm.invalid">Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>