<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <div class="module-name" *ngIf="!loader">
                <h2>Retailer To Mechanic</h2>
                <p>{{totalTrans}} Retailer To Mechanic Transaction Available</p>
            </div>
            <div class="left-auto">
                <div class="cs-action jfe">
                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below"><i class="material-icons" (click)="retailerTransactionList(1);">search</i></a>
                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below"><i class="material-icons" (click)="refreshData()">settings_backup_restore</i></a>
                </div>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        
        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">
                
                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw120">Date</div>
                            <!-- <div class="div_cell mmw120">Created By</div> -->
                            <div class="div_cell mw130">Retailer</div>
                            <div class="div_cell mmw220">Mechanic</div>
                            <div class="div_cell mmw150">Payment Mode</div>
                            <div class="div_cell mmw100 text-right af-bdr-right">Amount</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="date_created" 
                                        [(ngModel)]="mechnaicTransactionSearch.date_created" (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_name" [(ngModel)]="mechnaicTransactionSearch.created_name"
                                     (keyup.enter)="retailerTransactionList(1);">
                                </div>
                            </div> -->
                            <div class="div_cell mw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="retailer_name" [(ngModel)]="mechnaicTransactionSearch.retailer_name"
                                     (keyup.enter)="retailerTransactionList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw220">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mechanic_name" [(ngModel)]="mechnaicTransactionSearch.mechanic_name"
                                     (keyup.enter)="retailerTransactionList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw150">
                            </div>
                            <div class="div_cell mmw100">
                                <!-- <div class="cs-action jfe">
                                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below"><i class="material-icons" (click)="retailerTransactionList(1);">search</i></a>
                                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below"><i class="material-icons" (click)="refreshData()">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->
                
                
                
                <section class="td_cell" >
                <!-- Loop Data -->
                <ng-container *ngIf="!loader">
                    <main *ngFor="let data of mechnaicTransaction; let i =index;">
                        <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                        <div class="div_cell mmw120">{{(data.date_created) | date: 'd MMM y'}}</div>
                        <div class="div_cell mw130">{{data.retailer_name ? (data.retailer_name | titlecase):'N/A'}} - {{data.retailer_mobile ? data.retailer_mobile:'N/A'}}</div>
                        <div class="div_cell mmw220">{{data.mechanic_name ? (data.mechanic_name | titlecase):'N/A'}} - {{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                        <div class="div_cell mmw150">{{data.payment_mode ? data.payment_mode:'N/A'}}</div>
                        <div class="div_cell mmw100 afe"> {{data.payment_amount ? data.payment_amount:'N/A'}}</div>
                    </main>
                    <app-not-found *ngIf="mechnaicTransaction.length == 0"></app-not-found>
                </ng-container>
                <!-- Loop Data -->


                 <ng-container *ngIf="loader">
                    <!-- Skelton Loop -->
                    <main *ngFor="let data of skeleton; let i=index">
                        <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                        <div class="div_cell mmw120"><p class="skeleton wp100">&nbsp;</p></div>
                        <!-- <div class="div_cell mmw120"><p class="skeleton wp100">&nbsp;</p></div> -->
                        <div class="div_cell mw130"><p class="skeleton wp100">&nbsp;</p></div>
                        <div class="div_cell mmw220"><p class="skeleton wp100">&nbsp;</p></div>
                        <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div>
                        <div class="div_cell mmw100"><p class="skeleton wp100">&nbsp;</p></div>
                    </main>
                    <!-- Skelton Loop -->
                </ng-container>

                </section>
            </div>
            
            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                         </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent"  *ngIf="mechnaicTransaction.length > 0"  (click)="exportExcel()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
        
    </div>
</div>
