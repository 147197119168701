import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { PendingOrderExcelUploadModalComponent } from '../pending-order-excel-upload-modal/pending-order-excel-upload-modal.component';

@Component({
    selector: 'app-pending-orders',
    templateUrl: './pending-orders.component.html',
})
export class PendingOrdersComponent implements OnInit {

    pendingOrderData: any = [];
    paginationData: any = {};
    loader: any;
    total: any;
    filterSearch: any = {};
    skeleton: any = [];
    date: any = [];
    doc_date: any = [];
    pageNumber: any;
    lastUpdatedDate: any;
    data_val: any;

    constructor(

        public dialog: MatDialog,
        private apiHit: DatabaseService,
        public dialog2: DialogService,

    ) {

        this.skeleton = new Array(10);
        this.date = new Date();
        this.doc_date = new Date();

        this.pageNumber = 1;
        // this.getPendingOrderList(1);
    }

    ngOnInit(): void {
        this.data_val = this.apiHit.userLoginData.data
        console.log(this.data_val);
        this.paginationData = this.apiHit.getFilterData('pending-orders');
        this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        console.log('pending order list filter search ', this.paginationData);
        this.getPendingOrderList(this.paginationData?.target ? this.paginationData.target : 1);
    }

    //Get Pending List Through API Function Start
    getPendingOrderList(target) {

        this.loader = 1;
        if (target == 1) {
            this.pageNumber = 1;
        }
        this.paginationData.search = this.filterSearch;
        this.paginationData.target = target;
        this.paginationData.pageNumber = this.pageNumber;
        if (this.apiHit.userLoginData.data.user_type == 'approval_user') {
            this.paginationData.user_id = this.apiHit.userLoginData.data.id;
        }
        else {
            this.paginationData.user_id = ''
        }
        if (this.data_val.login_type == 'distributor') {
            this.paginationData.dist_id = this.data_val.id
            this.paginationData.login_type = this.data_val.login_type
        }

        this.apiHit.setFilterData(this.paginationData);

        if (this.filterSearch.date_from) {
            this.filterSearch.date_from = (moment(this.filterSearch.date_from).format('YYYY-MM-DD'));
        }
       

        if (this.filterSearch.date_to) {
            this.filterSearch.date_to = (moment(this.filterSearch.date_to).format('YYYY-MM-DD'));
        }
       
        this.apiHit.PostRequest(this.paginationData, "Distributor/GET_PENDING_ORDER").subscribe((result => {

            console.log(result);
            if (result['status'] == 'Success') {
                this.pendingOrderData = result['data'];
                this.lastUpdatedDate = result['last_updated']?.['time']
                this.loader = '';
            }
            else {
                this.loader = '';
                this.dialog2.error('error', 'Something Went Wrong');
            }

        }));

    }


    clearDateFilter() {
        this.filterSearch.date_from = '';
        this.filterSearch.date_to = '';
        this.getPendingOrderList(1);
    }

    //Get Pending List Through API Function End


    public onDate(event): void {
        this.filterSearch.doc_date = moment(event.value).format('YYYY-MM-DD');
        this.getPendingOrderList(1);
    }


    onChangePageNumber(type) {

        console.log(this.pageNumber);

        if (type == 'previous') {

            if (this.pageNumber > 1) {

                this.pageNumber -= 1;
                this.getPendingOrderList(2);

            }

        }
        else if (type == 'next') {

            if (this.total > this.pageNumber) {

                this.pageNumber += 1;
                this.getPendingOrderList(2);

            }

        }
        else if (type = 'current') {

            if (this.pageNumber > this.total) {

                console.log('current else');
                this.pageNumber = this.total;
                this.getPendingOrderList(2);

            }
            else if (this.pageNumber == 0) {

                this.pageNumber = 1;
                this.getPendingOrderList(2);

            }
            else if (this.pageNumber) {

                console.log('last else if');
                this.getPendingOrderList(2);

            }

        }

    }

    //Excel Upload Function Start 
    ExcelUploadFunction(data): void {

        console.log(data);
        console.log(data.wallet_balance);

        const dialogRef = this.dialog.open(PendingOrderExcelUploadModalComponent, {

            width: '400px',

            disableClose: true,

            data:
            {
                data
            }

        });

        dialogRef.afterClosed().subscribe(result => {

            dialogRef.close();
            this.getPendingOrderList(2);

        });

    }
    //Excel Upload Function End


    ExcelDownloadFunction() {

        this.loader = 1;

        this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_PENDING_DATA").subscribe((result => {

            console.log(result);
            this.getPendingOrderList(1);
            setTimeout(() => {

                this.loader = '';
                window.open(this.apiHit.downloadURLNew + "uploads/PENDING_DATA.csv");

            }, 700);

        }));

    }


    //Refresh Distributor List Function Start

    refreshData() {

        this.filterSearch = {};
        if (this.pageNumber == null) {
            this.pageNumber = 1;
        }
        this.getPendingOrderList(1);

    }
    //Refresh Distributor List Function End

}