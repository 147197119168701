<div *ngIf="dataValue == 1">
    <div mat-dialog-title class="model-head">
        <h2>Edit Basic Information</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="EditRetailer" (ngSubmit)="updateRetailer()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">

                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Firm Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="firmName" id="firmName"
                                maxlength="40">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="EditRetailer.controls.firmName.invalid && 
                            EditRetailer.controls['firmName']?.touched && !EditRetailer.controls['firmName']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="EditRetailer.controls.firmName.invalid && 
                            EditRetailer.controls['firmName']['errors']?.minlength || EditRetailer.controls['firmName']['errors']?.maxlength">
                            Required 3-40 Characters
                        </mat-hint>
                    </div>


                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="firstName" id="firstName"
                                maxlength="40">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="EditRetailer.controls.firstName.invalid && 
                            EditRetailer.controls['firstName']?.touched && !EditRetailer.controls['firstName']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="EditRetailer.controls.firstName.invalid && 
                            EditRetailer.controls['firstName']['errors']?.minlength || EditRetailer.controls['firstName']['errors']?.maxlength">
                            Required 3-40 Characters
                        </mat-hint>
                    </div>


                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Mobile No. <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="mobileNo" id="mobileNo"
                                maxlength="10" (ngModelChange)="checkMobileNoDuplicacy()"
                                (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="EditRetailer.controls.mobileNo.invalid && 
                            EditRetailer.controls['mobileNo']?.touched && !EditRetailer.controls['mobileNo']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error" *ngIf="EditRetailer.controls.mobileNo.invalid && 
                            EditRetailer.controls['mobileNo']['errors']?.minlength || EditRetailer.controls['mobileNo']['errors']?.maxlength">
                            Required 10 Numbers
                        </mat-hint>
                    </div>


                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Email Id <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="email" id="email">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="EditRetailer.controls.email.invalid && 
                            EditRetailer.controls['email']?.touched && !EditRetailer.controls['email']['errors']?.pattern">
                        </mat-hint>
                        <mat-hint class="has-error" *ngIf="EditRetailer.controls.email?.invalid && 
                            EditRetailer.controls['email']['errors']?.pattern">
                            Invalid Email
                        </mat-hint>
                    </div>
                </div>

                <div class="row row-mb0">

                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Date of Birth</mat-label>
                            <input matInput [matDatepicker]="birth" formControlName="DOB" id="DOB" [min]="minDate"
                                [max]="maxDate" (ngModelChange)="minDateSelect()" readonly (click)="birth.open()">
                            <mat-datepicker-toggle matSuffix [for]="birth"></mat-datepicker-toggle>
                            <mat-datepicker #birth disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Date of Anniversary</mat-label>
                            <input matInput [matDatepicker]="anniversary" formControlName="anniversary" id="anniversary"
                                [min]="minDate" [max]="date" readonly (click)="anniversary.open()">
                            <mat-datepicker-toggle matSuffix [for]="anniversary"></mat-datepicker-toggle>
                            <mat-datepicker #anniversary disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>GST Number</mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="gstNo" id="gstNo"
                                maxlength="15">
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="EditRetailer.controls.gstNo.invalid && 
                            EditRetailer.controls['gstNo']['errors']?.minlength || EditRetailer.controls['gstNo']['errors']?.maxlength ">
                            Required 15 Characters
                        </mat-hint>
                    </div>
                    <div class="col s12 m3">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Pan Number</mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="panNo" id="panNo"
                                maxlength="10">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="EditRetailer.controls.panNo.invalid && 
                            EditRetailer.controls['panNo']['errors']?.minlength || EditRetailer.controls['panNo']['errors']?.maxlength ">
                            Required 10 Characters
                        </mat-hint>
                    </div>

                    <ng-container *ngIf="updateDataDetails.ASM_updated_by != ''">
                        <div class="col s12 m3" *ngIf="data.user_type== 'System_user'">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Approved By ASM</mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="asm_approved_by"
                                    id="asm_approved_by">
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="updateDataDetails.ZSM_updated_by != ''">
                        <div class="col s12 m3" *ngIf="data.user_type== 'System_user'">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Approved By ZSM</mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="zsm_approved_by"
                                    id="asm_approved_by">
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="data.user_type == 'System_user'">
                        <div class="row row-mb0">

                            <div class="col s12 m3">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label> Status <span class="sub">*</span></mat-label>
                                    <mat-select formControlName="status" id="status"
                                        (ngModelChange)="checkRetailerStatus(2)">
                                        <mat-option value="Pending">Pending</mat-option>
                                        <mat-option value="Verified">Verified</mat-option>
                                        <mat-option value="Reject">Reject</mat-option>
                                        <mat-option value="Suspect">Suspect</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col s12 m3">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>ASM Status <span class="sub">*</span></mat-label>
                                    <mat-select formControlName="asm_status" id="status"
                                        (ngModelChange)="checkRetailerAsmStatus(2)">
                                        <mat-option value="Pending">Pending</mat-option>
                                        <mat-option value="Verified">Verified</mat-option>
                                        <mat-option value="Reject">Reject</mat-option>
                                        <mat-option value="Suspect">Suspect</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div *ngIf="retailerStatus" class="col s12 m3">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>ASM Reason <span class="sub">*</span></mat-label>
                                    <input matInput placeholder="Type Here ..." formControlName="asm_status_reason"
                                        id="asm_status_reason">
                                </mat-form-field>
                                <mat-hint class="has-error"
                                    *ngIf="EditRetailer.controls.asm_status_reason.invalid && 
                                    EditRetailer.controls['asm_status_reason']?.touched && !EditRetailer.controls['asm_status_reason']['errors']?.minlength">
                                </mat-hint>
                                <mat-hint class="has-error"
                                    *ngIf="EditRetailer.controls.asm_status_reason.invalid && 
                                    EditRetailer.controls['asm_status_reason']['errors']?.minlength || EditRetailer.controls['asm_status_reason']['errors']?.maxlength">
                                    Required Atleast 3 Characters
                                </mat-hint>
                            </div>
                            <div class="col s12 m3">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label> ZSM Status <span class="sub">*</span></mat-label>
                                    <mat-select formControlName="zsm_status" id="status"
                                        (ngModelChange)="checkRetailerZsmStatus(2)">
                                        <mat-option value="Pending">Pending</mat-option>
                                        <mat-option value="Verified">Verified</mat-option>
                                        <mat-option value="Reject">Reject</mat-option>
                                        <mat-option value="Suspect">Suspect</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>


                            <div class="row">
                                <div *ngIf="retailerStatusZsm" class="col s12 m3">
                                    <mat-form-field appearance="outline" class="cs-input">
                                        <mat-label>ZSM Reason <span class="sub">*</span></mat-label>
                                        <input matInput placeholder="Type Here ..." formControlName="zsm_status_reason"
                                            id="zsm_status_reason">
                                    </mat-form-field>
                                    <mat-hint class="has-error"
                                        *ngIf="EditRetailer.controls.zsm_status_reason.invalid && 
                                        EditRetailer.controls['zsm_status_reason']?.touched && !EditRetailer.controls['zsm_status_reason']['errors']?.minlength">
                                    </mat-hint>
                                    <mat-hint class="has-error"
                                        *ngIf="EditRetailer.controls.zsm_status_reason.invalid && 
                                        EditRetailer.controls['zsm_status_reason']['errors']?.minlength || EditRetailer.controls['zsm_status_reason']['errors']?.maxlength">
                                        Required Atleast 3 Characters
                                    </mat-hint>
                                </div>

                                <div class="col s12 m3" *ngIf="this.apiHit.userLoginData.data.id == 1">
                                    <mat-form-field appearance="outline" class="cs-input">
                                        <mat-label>Scanning Status <span class="sub">*</span></mat-label>
                                        <mat-select formControlName="scanning_status" id="scanning_status">
                                            <mat-option value="0">Active</mat-option>
                                            <mat-option value="1">Not Active</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="retailerStatusVerified" class="col s12 m3">
                                    <mat-form-field appearance="outline" class="cs-input">
                                        <mat-label>Select Distributor <span class="sub">*</span></mat-label>
                                        <mat-select formControlName="distributor_id" id="distributor_id" multiple>
                                            <mat-option *ngFor="let data of distributorData" value="{{data.id}}">
                                                {{data.dr_name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-hint class="has-error" *ngIf="EditRetailer.controls.distributor_id.invalid && 
                                        EditRetailer.controls['distributor_id']?.touched">
                                    </mat-hint>
                                </div>
                            </div>


                        </div>
                    </ng-container>

                </div>


                <div class="row row-mb0">

                    <ng-container *ngIf="data.approval_type== 'asm'">

                        <ng-container *ngIf="updateDataDetails.ASM_updated_by != ''">
                            <div class="col s12 m3">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>ASM Approved By</mat-label>
                                    <input matInput placeholder="Type Here ..." formControlName="asm_approved_by"
                                        id="asm_approved_by">
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <div class="col s12 m3">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>ASM Status <span class="sub">*</span></mat-label>
                                <mat-select formControlName="asm_status" id="status"
                                    (ngModelChange)="checkRetailerAsmStatus(2)">
                                    <mat-option value="Pending">Pending</mat-option>
                                    <mat-option value="Verified">Verified</mat-option>
                                    <mat-option value="Reject">Reject</mat-option>
                                    <mat-option value="Suspect">Suspect</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div *ngIf="retailerStatus" class="col s12 m6">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>ASM Reason <span class="sub">*</span></mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="asm_status_reason"
                                    id="asm_status_reason">
                            </mat-form-field>
                            <mat-hint class="has-error"
                                *ngIf="EditRetailer.controls.asm_status_reason.invalid && 
                                EditRetailer.controls['asm_status_reason']?.touched && !EditRetailer.controls['asm_status_reason']['errors']?.minlength">
                            </mat-hint>
                            <mat-hint class="has-error"
                                *ngIf="EditRetailer.controls.asm_status_reason.invalid && 
                                EditRetailer.controls['asm_status_reason']['errors']?.minlength || EditRetailer.controls['asm_status_reason']['errors']?.maxlength">
                                Required Atleast 3 Characters
                            </mat-hint>
                        </div>

                        <div *ngIf="retailerStatusVerified" class="col s12 m3">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Select Distributor <span class="sub">*</span></mat-label>
                                <mat-select formControlName="distributor_id" id="distributor_id" multiple>
                                    <mat-option *ngFor="let data of distributorData" value="{{data.id}}">
                                        {{data.dr_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-hint class="has-error" *ngIf="EditRetailer.controls.distributor_id.invalid && 
                                EditRetailer.controls['distributor_id']?.touched">
                            </mat-hint>
                        </div>

                    </ng-container>



                    <ng-container *ngIf="data.approval_type== 'zsm'">

                        <ng-container *ngIf="updateDataDetails.ZSM_updated_by != ''">
                            <div class="col s12 m3">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>ZSM Approved By</mat-label>
                                    <input matInput placeholder="Type Here ..." formControlName="zsm_approved_by"
                                        id="zsm_approved_by">
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <div class="col s12 m3">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label> ZSM Status <span class="sub">*</span></mat-label>
                                <mat-select formControlName="zsm_status" id="status"
                                    (ngModelChange)="checkRetailerZsmStatus(2)">
                                    <mat-option value="Pending">Pending</mat-option>
                                    <mat-option value="Verified">Verified</mat-option>
                                    <mat-option value="Reject">Reject</mat-option>
                                    <mat-option value="Suspect">Suspect</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        
                        <div *ngIf="retailerStatusZsm " class="col s12 m6">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>ZSM Reason <span class="sub">*</span></mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="zsm_status_reason"
                                    id="zsm_status_reason">
                            </mat-form-field>
                            <mat-hint class="has-error"
                                *ngIf="EditRetailer.controls.zsm_status_reason.invalid && 
                                EditRetailer.controls['zsm_status_reason']?.touched && !EditRetailer.controls['zsm_status_reason']['errors']?.minlength">
                            </mat-hint>
                            <mat-hint class="has-error"
                                *ngIf="EditRetailer.controls.zsm_status_reason.invalid && 
                                EditRetailer.controls['zsm_status_reason']['errors']?.minlength || EditRetailer.controls['zsm_status_reason']['errors']?.maxlength">
                                Required Atleast 3 Characters
                            </mat-hint>
                        </div>

                        <div *ngIf="retailerStatusVerified" class="col s12 m3">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Select Distributor <span class="sub">*</span></mat-label>
                                <mat-select formControlName="distributor_id" id="distributor_id" multiple>
                                    <mat-option *ngFor="let data of distributorData" value="{{data.id}}">
                                        {{data.dr_name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-hint class="has-error" *ngIf="EditRetailer.controls.distributor_id.invalid && 
                                EditRetailer.controls['distributor_id']?.touched">
                            </mat-hint>
                        </div>

                    </ng-container>
   
                </div>


                <div class="cs-head flat-heading">
                    <h2>address Information</h2>
                </div>


                <div class="row row-mb0">
                    <div class="col s12 m6 pading0">
                        <div class="row row-mb0">
                            <div class="col s12 m6">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>Pincode <span class="sub">*</span></mat-label>
                                    <input matInput placeholder="Type Here ..." formControlName="pinNo" id="pinNo"
                                        maxlength="6" (keypress)="OnlyNumber($event)" (input)="enterPin(EditRetailer)">
                                </mat-form-field>
                                <mat-hint class="has-error" *ngIf="EditRetailer.controls.pinNo.invalid && 
                                    EditRetailer.controls['pinNo']?.touched && !EditRetailer.controls['pinNo']['errors']?.minlength ">
                                </mat-hint>
                                <mat-hint class="has-error"
                                    *ngIf="EditRetailer.controls.pinNo.invalid && 
                                    EditRetailer.controls['pinNo']['errors']?.minlength || EditRetailer.controls['pinNo']['errors']?.maxlength">
                                    Required 6 Numbers
                                </mat-hint>
                            </div>
                            <div class="col s12 m6">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>State <span class="sub">*</span></mat-label>
                                    <mat-select (keyup.enter)="districtList1()" [value]="stataData"
                                        formControlName="state" id="state">
                                        <mat-option>
                                            <ngx-mat-select-search formControlName="searchCtrl"
                                                [placeholderLabel]="'Search...'" [noEntriesFoundLabel]="'Not found'"
                                                name="search">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option
                                            *ngFor="let data of stateData | stringFilterBy:EditRetailer.value.searchCtrl:'data';  let i=index"
                                            (click)="districtList(data)" value="{{data}}">{{data}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-hint class="has-error" *ngIf="EditRetailer.controls.state.invalid && 
                                    EditRetailer.controls['state']?.touched">
                                    Required
                                </mat-hint>
                            </div>

                        </div>

                        <div class="row row-mb0">
                            <div class="col s12 m6">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>District <span class="sub">*</span></mat-label>
                                    <mat-select [value]="selectedDistrict" formControlName="district" id="district">
                                        <mat-option *ngFor="let data of districtData; let i=index" value="{{data}}">
                                            {{data}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-hint class="has-error" *ngIf="EditRetailer.controls.district.invalid && 
    EditRetailer.controls['district']?.touched">
                                    Required
                                </mat-hint>
                            </div>

                            <div class="col s12 m6">
                                <mat-form-field appearance="outline" class="cs-input">
                                    <mat-label>City <span class="sub">*</span></mat-label>
                                    <input matInput placeholder="Type Here ..." formControlName="city" id="city"
                                        maxlength="30">
                                </mat-form-field>
                                <mat-hint class="has-error" *ngIf="EditRetailer.controls.city.invalid && 
                                    EditRetailer.controls['city']?.touched && !EditRetailer.controls['city']['errors']?.minlength ">

                                </mat-hint>
                                <mat-hint class="has-error" *ngIf="EditRetailer.controls.city.invalid && 
                                    EditRetailer.controls['city']['errors']?.minlength || EditRetailer.controls['city']['errors']?.maxlength">
                                    Required 3-30 Characters
                                </mat-hint>
                            </div>
                        </div>
                    </div>

                    <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Address</mat-label>
                            <textarea matInput placeholder="Type Here ..." class="h70" formControlName="address"
                                id="address"></textarea>
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="EditRetailer.controls.address.invalid && 
    EditRetailer.controls['address']['errors']?.minlength">
                            Required Minimum 3 Characters
                        </mat-hint>
                    </div>

                </div>


            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="EditRetailer.invalid">Update</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>



<div *ngIf="dataValue == 2">
    <div mat-dialog-title class="model-head">
        <h2>Edit Distributor Information</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="EditDistributor" (ngSubmit)="changeDistributor()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m12">

                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Select Distributor <span class="sub">*</span></mat-label>
                            <mat-select formControlName="distributor_id" name="distributor_id" id="distributor_id"
                                multiple>

                                <ngx-mat-select-search formControlName="searchCtrl" [placeholderLabel]="'Search...'"
                                    [noEntriesFoundLabel]="'Not found'" name="search">
                                </ngx-mat-select-search>

                                <mat-option
                                    *ngFor="let data of distributorData |stringFilterBy:EditDistributor.value.searchCtrl:'dr_name'"
                                    (click)="assignDistributor()" value="{{data.id}}">{{data.dr_name}} -
                                    {{data.dr_code}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="EditDistributor.controls.distributor_id.invalid && 
                    EditDistributor.controls['distributor_id']?.touched">
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="EditDistributor.invalid">Update</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>



<div *ngIf="dataValue == 3">
    <div mat-dialog-title class="model-head">
        <h2>Add Contact Person</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="AddContactForm" (ngSubmit)="addContact()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="cPersonName" id="cPersonName"
                                maxlength="30">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="AddContactForm.controls.cPersonName.invalid && 
                            AddContactForm.controls['cPersonName']?.touched && !AddContactForm.controls['cPersonName']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="AddContactForm.controls.cPersonName.invalid && 
                            AddContactForm.controls['cPersonName']['errors']?.minlength || AddContactForm.controls['cPersonName']['errors']?.maxlength">
                            Required 3-30 Characters
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Mobile Number <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="cPersonMobileNo"
                                id="cPersonMobileNo" maxlength="10" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="AddContactForm.controls.cPersonMobileNo.invalid && 
                                AddContactForm.controls['cPersonMobileNo']?.touched && !AddContactForm.controls['cPersonMobileNo']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="AddContactForm.controls.cPersonMobileNo.invalid && 
                            AddContactForm.controls['cPersonMobileNo']['errors']?.minlength || AddContactForm.controls['cPersonMobileNo']['errors']?.maxlength">
                            Required 10 Numbers
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Email ID <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="cPersonEmail"
                                id="cPersonEmail">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="AddContactForm.controls.cPersonEmail.invalid && 
        AddContactForm.controls['cPersonEmail']?.touched && !AddContactForm.controls['cPersonEmail']['errors']?.pattern">

                        </mat-hint>
                        <mat-hint class="has-error" *ngIf="AddContactForm.controls.cPersonEmail?.invalid && 
    AddContactForm.controls['cPersonEmail']['errors']?.pattern">
                            Invalid Email
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="AddContactForm.invalid">Save</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>



<div *ngIf="dataValue == 4">
    <div mat-dialog-title class="model-head">
        <h2>Edit Contact Person</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="EditContactForm" (ngSubmit)="updateContact()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Name <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="updatePersonName"
                                id="updatePersonName" maxlength="30">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="EditContactForm.controls.updatePersonName.invalid && 
                            EditContactForm.controls['updatePersonName']?.touched && !EditContactForm.controls['updatePersonName']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="EditContactForm.controls.updatePersonName.invalid && 
                            EditContactForm.controls['updatePersonName']['errors']?.minlength || EditContactForm.controls['updatePersonName']['errors']?.maxlength">
                            Required 3-30 Characters
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Mobile Number <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="updatePersonMobileNo"
                                id="updatePersonMobileNo" maxlength="10" (keypress)="OnlyNumber($event)">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="EditContactForm.controls.updatePersonMobileNo.invalid && 
                            EditContactForm.controls['updatePersonMobileNo']?.touched && !EditContactForm.controls['updatePersonMobileNo']['errors']?.minlength ">
                        </mat-hint>
                        <mat-hint class="has-error"
                            *ngIf="EditContactForm.controls.updatePersonMobileNo.invalid && 
                            EditContactForm.controls['updatePersonMobileNo']['errors']?.minlength || EditContactForm.controls['updatePersonMobileNo']['errors']?.maxlength">
                            Required 10 Numbers
                        </mat-hint>
                    </div>
                    <div class="col s12 m4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Email ID <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="updatePersonEmail"
                                id="updatePersonEmail">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="EditContactForm.controls.updatePersonEmail.invalid && 
                            EditContactForm.controls['updatePersonEmail']?.touched && !EditContactForm.controls['updatePersonEmail']['errors']?.pattern">
                        </mat-hint>
                        <mat-hint class="has-error" *ngIf="EditContactForm.controls.updatePersonEmail?.invalid && 
                            EditContactForm.controls['updatePersonEmail']['errors']?.pattern">
                            Invalid Email
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="EditContactForm.invalid">Save</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>

<div *ngIf="dataValue == 5">
    <div mat-dialog-title class="model-head">
        <h2>Assign Sales User</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="AssignedSalesUser" (ngSubmit)="addAssignedSalesUser()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row">
                    <div class="col s12 m12">

                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Select Sales User<span class="sub">*</span></mat-label>
                            <mat-select formControlName="salesUser" name="salesUser" id="salesUser"
                                multiple>
                                <mat-option
                                    *ngFor="let data of salesUserList" value="{{data.id}}">{{data.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error" *ngIf="AssignedSalesUser.controls.salesUser.invalid && 
                    AssignedSalesUser.controls['salesUser']?.touched">
                        </mat-hint>
                    </div>
                </div>
            </div>
        </div>
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit" [disabled]="AssignedSalesUser.invalid">Update</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>