<div>
    <div mat-dialog-title class="model-head">
        <h2>Upload Excel</h2>
        <a class="close-model" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>


    <!-- <form [formGroup]="uploadExcelFile" (ngSubmit)="payAmount()"> -->
    <!-- <form name="uploadExcelFile" target="blank"
        action="https://phpstack-414838-2222412.cloudwaysapps.com/askApi/index.php/Distributor/IMPORT_DISTRIBUTOR_OUTSTANDING"
        method="post" enctype="multipart/form-data">
        <div>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">
                    <div class="col s12">


                        <input placeholder="Type Here ..." type="file">

                        <a class="link-btn"
                            href="https://phpstack-414838-2222412.cloudwaysapps.com/askApi/SAMPLE_OUTSTANDING.csv">download
                            sample .csv file</a>
                    </div>
                </div>

            </div>
        </div>
        
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button type="submit">Uplaod</button>
            </div>
        </mat-dialog-actions>
    </form> -->


    <form name="upload_excel" target="blank"
        action="https://phpstack-414838-2222412.cloudwaysapps.com/askApi/index.php/Distributor/IMPORT_DISTRIBUTOR_OUTSTANDING"
        method="post" enctype="multipart/form-data">
        <div class="csm-form tab-content ">
            <div class="inspace new-form-design" style="border: 0px;">

                <div class="row">

                    <div class="col-sm-6">
                        <ul>
                            <li><label>Select File</label></li>
                            <li>
                                <div>
                                    <input type="file" name="file" class="files"
                                        (change)="handleFileInput($event.target.files)" required="true" accept=".csv">
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="col-sm-6">
                        <p class="file-intro">Supported file type :- &nbsp;&nbsp;&nbsp; .CSV (<a target="_blank"
                                href="https://phpstack-414838-2222412.cloudwaysapps.com/askApi/SAMPLE_OUTSTANDING.csv">Download
                                sample file</a>)</p>
                    </div>

                </div>
            </div>
            <div class="row mb-15 mt-15">
                <div class="col-sm-12 text-right">
                    <button class="cs-btn" type="submit" (click)="UploadExcel()"
                        class="btn btn-primary create-crud">Upload</button>
                </div>
            </div>
        </div>
    </form>


</div>