<div class="container-fluid">
    <div class="container">
        <section id="head">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                (click)="backClicked()">
                <mat-icon>arrow_back</mat-icon>
            </a>

            <div class="module-name">
                <h2>Scheme</h2>
                <p *ngIf="addScheme == 0">Add Scheme</p>
                <p *ngIf="addScheme != 0">Edit Scheme</p>
            </div>

            <div class="left-auto df afe">
                <div class="indicates">
                    Indicates required fields ( <span>*</span> )
                </div>
            </div>
        </section>

        <div class="container-pd10-5">
            <form #form="ngForm" [formGroup]="AddSchemeForm" (ngSubmit)="SaveScheme()">
                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>basic Information</h2>
                            </div>

                            <div class="cs-form pb0">

                                <div class="row row-mb0">

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Scheme Title <span class="sub">*</span></mat-label>
                                            <input matInput placeholder="Type Here ..." formControlName="title"
                                                id="title">
                                        </mat-form-field>
                                        <mat-hint class="has-error"
                                            *ngIf="AddSchemeForm.controls.title.invalid && 
                                    AddSchemeForm.controls['title']?.touched && !AddSchemeForm.controls['title']['errors']?.minlength ">
                                        </mat-hint>
                                        <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.title.invalid && 
                                AddSchemeForm.controls['title']['errors']?.minlength">
                                            Required Atleast 3 Characters
                                        </mat-hint>
                                        <mat-hint class="has-error"
                                            *ngIf="AddSchemeForm.controls.title?.invalid && !AddSchemeForm.controls['title']['errors']?.minlength">
                                        </mat-hint>
                                    </div>


                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Start Date <span class="sub">*</span></mat-label>
                                            <input matInput [matDatepicker]="start_date" formControlName="start_date"
                                                id="start_date" (ngModelChange)="minDateSelect()" readonly
                                                (click)="start_date.open()">
                                            <mat-datepicker-toggle matSuffix [for]="start_date"></mat-datepicker-toggle>
                                            <mat-datepicker #start_date disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.start_date.invalid && 
                                            AddSchemeForm.controls['start_date']?.touched">
                                        </mat-hint>
                                    </div>

                                    <div class="col s12 m3">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>End Date <span class="sub">*</span></mat-label>
                                            <input matInput [matDatepicker]="end_date" formControlName="end_date"
                                                id="end_date" [min]="minEndDate" readonly (click)="end_date.open()">
                                            <mat-datepicker-toggle matSuffix [for]="end_date">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #end_date disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.end_date.invalid && 
                                            AddSchemeForm.controls['end_date']?.touched">
                                        </mat-hint>
                                    </div>

                                    <div class="col s12 m3" *ngIf="activateRoute['params']['_value']['id'] != 0">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Status <span class="sub">*</span></mat-label>
                                            <mat-select formControlName="status" name="status" id="status">
                                                <mat-option value="1">Active</mat-option>
                                                <mat-option value="2">Deactive</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.status.invalid && 
                                        AddSchemeForm.controls.status.touched">
                                        </mat-hint>
                                    </div>

                                </div>


                                <div class="row row-mb0">


                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Description</mat-label>
                                            <textarea matInput placeholder="Type Here ..." class="h70"
                                                formControlName="description" id="description"></textarea>
                                        </mat-form-field>
                                        <!-- <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.description.invalid && 
                                            AddSchemeForm.controls['description']['errors']?.minlength">
                                            Required Atleast 3 Characters
                                        </mat-hint> -->
                                    </div>

                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="cs-input">
                                            <mat-label>Terms And Conditions</mat-label>
                                            <textarea matInput placeholder="Type Here ..." class="h70"
                                                formControlName="term_condition" id="term_condition"></textarea>
                                        </mat-form-field>
                                        <!-- <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.term_condition.invalid && 
                                            AddSchemeForm.controls['term_condition']['errors']?.minlength">
                                            Required Atleast 3 Characters
                                        </mat-hint> -->
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>Scheme Area Information</h2>
                            </div>

                            <div class="cs-form">

                                <div class="row row-mb0">

                                    <div class="col s12 m4 l4">
                                        <div class="cs-column cs-column-height">
                                            
                                            <div class="mat-list pt0">
                                                <div class="mat-border"
                                                    *ngFor="let states of stateData | filter:statesearch_btn; let k=index;">
                                                    <mat-checkbox
                                                        (change)="on_select_state( $event, states.state, states.checked,k,1)"
                                                        [checked]="states.checked">{{states.state}}</mat-checkbox>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="row">
                    <div class="col s12">
                        <div class="cs-column">
                            <div class="cs-head">
                                <h2>Secheme Segment</h2>
                            </div>

                            <div class="cs-form pb0">
                                <div class="row row-mb0">

                                    <div class="col s12 m6 pading0">
                                        <div class="row row-mb0">

                                            <div class="col s12 m6">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Segment <span class="sub">*</span></mat-label>
                                                    <mat-select [value]="segmentdata" formControlName="segment"
                                                        name="segment" id="segment">
                                                        <!-- <ngx-mat-select-search formControlName="searchCtrl"
                                                            [placeholderLabel]="'Search...'"
                                                            [noEntriesFoundLabel]="'Not found'" name="search">
                                                        </ngx-mat-select-search> -->
                                                        <mat-option *ngFor="let data of segmentdata"
                                                            value="{{data.segment}}">
                                                            {{data.segment}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.segment.invalid && 
                                                    AddSchemeForm.controls['segment']?.touched">
                                                </mat-hint>
                                            </div>

                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12 m6 relative">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Type <span class="sub">*</span></mat-label>
                                                    <mat-select formControlName="type" name="type" id="type"
                                                        (ngModelChange)="checkSchemeType()">
                                                        <mat-option value="Between">Between</mat-option>
                                                        <mat-option value="Above">Above</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.type.invalid && 
                                                    AddSchemeForm.controls['type']?.touched">
                                                </mat-hint>
                                            </div>

                                            <div class="col s12 m3" *ngIf="segmentTypeCheck == 1">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Range From <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..."
                                                        formControlName="range_from" id="range_from"
                                                        (keypress)="OnlyNumber($event)">
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.range_from.invalid && 
                                                    AddSchemeForm.controls['range_from']?.touched">
                                                </mat-hint>
                                            </div>

                                            <div class="col s12 m3" *ngIf="segmentTypeCheck == 1">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Range To <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..."
                                                        formControlName="range_to" id="range_to"
                                                        (keypress)="OnlyNumber($event)">
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.range_to.invalid && 
                                                    AddSchemeForm.controls['range_to']?.touched ">
                                                </mat-hint>
                                            </div>

                                            <div class="col s12 m3" *ngIf="segmentTypeCheck == 2">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Above Range <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..."
                                                        formControlName="above_range" id="above_range"
                                                        (keypress)="OnlyNumber($event)">
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.above_range.invalid && 
                                                    AddSchemeForm.controls['above_range']?.touched ">
                                                </mat-hint>
                                            </div>


                                            <div class="col s12 m3">
                                                <mat-form-field appearance="outline" class="cs-input">
                                                    <mat-label>Scheme Point <span class="sub">*</span></mat-label>
                                                    <input matInput placeholder="Type Here ..."
                                                        formControlName="scheme_point" id="scheme_point"
                                                        (keypress)="OnlyNumber($event)">
                                                </mat-form-field>
                                                <mat-hint class="has-error" *ngIf="AddSchemeForm.controls.scheme_point.invalid && 
                                                    AddSchemeForm.controls['scheme_point']?.touched ">
                                                </mat-hint>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s12 m12">
                        <div class="cs-btn">
                            <button mat-button type="submit" [disabled]="AddSchemeForm.invalid || hideSaveButton"
                                *ngIf="addScheme == 0">Save</button>
                            <button mat-button type="submit" [disabled]="AddSchemeForm.invalid || hideSaveButton"
                                *ngIf="addScheme != 0">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>