<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <div class="module-name" *ngIf="!loader">
                <h2> Partially Delivered Orders</h2>
                <p>{{totalTrans ? totalTrans : '0'}} Partially Delivered Orders</p>
            </div>
            <div class="left-auto">
                <div class="cs-action">
                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                        (click)="getPartiallyDeliveredOrder(1)"><i class="material-icons">search</i></a>
                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                        (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                </div>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw130"> APP Date Created</div>
                            <div class="div_cell mmw130">APP Order Id</div>
                            <div class="div_cell mmw120">SAP Order Number</div>
                            <div class="div_cell mmw100">Invoice Number</div>
                            <div class="div_cell mw190">Distributor Name</div>
                            <div class="div_cell mmw130">State</div>
                            <div class="div_cell mmw90">Segment</div>
                            <div class="div_cell mmw100">Brand</div>
                            <div class="div_cell mmw110">Material Code</div>
                            <div class="div_cell mmw190">Part Code</div>
                            <div class="div_cell mmw90">APP Order Qty</div>
                            <div class="div_cell mmw90">SAP Order Qty</div>
                            <div class="div_cell mmw100">Invoice Qty</div>
                            <div class="div_cell mmw90">Pending Qty</div>
                            <div class="div_cell mmw90">Pending Days</div>
                            <div class="div_cell mmw100">Order Status</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw130">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="date_created"
                                            [(ngModel)]="searchData.date_created"
                                            (dateChange)="getPartiallyDeliveredOrder(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw130">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="order_id"
                                        [(ngModel)]="searchData.order_id" (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div> -->
                            </div>
                            <div class="div_cell mmw120">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Sales_Document"
                                        [(ngModel)]="searchData.Sales_Document"
                                        (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="invoice_no"
                                        [(ngModel)]="searchData.invoice_no"
                                        (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div>
                            </div>
                            <div class="div_cell mw190">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Sold_to_party_name"
                                        [(ngModel)]="searchData.Sold_to_party_name"
                                        (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw130">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_state"
                                        [(ngModel)]="searchData.dr_state" (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div> -->
                            </div>
                            <div class="div_cell mmw90">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="searchData.segment" (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div> -->
                            </div>
                            <div class="div_cell mmw100">
                                <!-- <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="brand"
                                        [(ngModel)]="searchData.brand" (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div> -->
                            </div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Material"
                                        [(ngModel)]="searchData.Material"
                                        (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw190">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="Description"
                                        [(ngModel)]="searchData.Description"
                                        (keyup.enter)="getPartiallyDeliveredOrder(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw100">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw100">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="getPartiallyDeliveredOrder(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <!-- Loop Data -->
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of orderData; let i =index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw130">{{(data.date_created) | date: 'd MMM y, h:mm a'}}</div>
                            <div class="div_cell mmw130">#AFF-{{data.order_id ? data.order_id:'N/A'}} </div>
                            <div class="div_cell mmw120">{{data.Sales_Document ? data.Sales_Document:'N/A'}} </div>
                            <div class="div_cell mmw100">{{data.invoice_no ? data.invoice_no:'N/A'}} </div>
                            <div class="div_cell mw190">{{data.Sold_to_party_name ? (data.Sold_to_party_name |
                                titlecase):'N/A'}}
                            </div>
                            <div class="div_cell mmw130">{{data.dr_state ? (data.dr_state | titlecase):'N/A'}} </div>
                            <div class="div_cell mmw90">{{data.segment ? (data.segment | titlecase):'N/A'}} </div>
                            <div class="div_cell mmw100">{{data.brand ? (data.brand | titlecase):'N/A'}} </div>
                            <div class="div_cell mmw110">{{data.Material ? data.Material:'N/A'}} </div>
                            <div class="div_cell mmw190">{{data.description ? data.description:'N/A'}} </div>
                            <div class="div_cell mmw90">{{data.Order_Quantity ? data.Order_Quantity:'N/A'}} </div>
                            <div class="div_cell mmw90">{{data.Confirmed_Quantity ? data.Confirmed_Quantity:'N/A'}}
                            </div>
                            <div class="div_cell mmw100">{{data.Dispatch_qty ? data.Dispatch_qty:'N/A'}} </div>
                            <div class="div_cell mmw90">{{data.Pending_qty ? data.Pending_qty:'N/A'}} </div>
                            <div class="div_cell mmw90">{{data.pending_days ? data.pending_days:'N/A'}} </div>
                            <div class="div_cell mmw100">{{data.sap_status ? data.sap_status:'N/A'}} </div>
                        </main>
                        <app-not-found *ngIf="orderData.length == 0"></app-not-found>
                    </ng-container>
                    <!-- Loop Data -->


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 text-center af-bdr-left">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw190">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw190">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>

                </section>
            </div>

            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li *ngIf="total > 1">Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="orderData.length > 0" (click)="exportExcel()"
                            class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </div>

    </div>
</div>