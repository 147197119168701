import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from '../services/dialog.service';
import { MatDialog } from '@angular/material/dialog';
import { AttendenceDetailComponent } from '../attendence-detail/attendence-detail.component';
@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  tabType: any = 'Today';
  tabType1: any = 'Present';
  data: any = {};
  total_page: any;
  count: any;
  start: any = 0;
  page_limit: any = 50
  loader:boolean = false;
  pagenumber: any;
  count_1: any;
  count_2: any;
  count_3: any;
  data_val: any;
  attendancelist: any = [];
  pagelimit: any;
  today_and_all_tab: any = 1;
  attendence_type: any = 'Present';
  totalAttendanceCount: any;
  attendanceDate: any = [];
  datanotfound: boolean = false;
  att_temp: any = [];
  tabCount: any = {};
  assign_login_data2: any = {};
  show_today: boolean = true;
  absent: boolean = true;
  active_present_absent: boolean = true;
  data_not_found: boolean = true;
  today_date: Date;
  today_day: any;
  report_manager: any = [];
  newToday_date: string;
  logIN_user: any;
  uid: any;
  uname: any;
  constructor(private apiHit : DatabaseService, public dialog: MatDialog) {
    this.getReportManager('');
    this.today_date = new Date();
    this.today_day = this.today_date.getDay();
    if (this.today_day == 5) {
      this.today_day = 'Friday'
    }


    switch (this.today_day) {
      case 0: {
        this.today_day = 'Sunday';
        break;
      }
      case 1: {
        this.today_day = 'Monday';

        break;
      }
      case 2: {
        this.today_day = 'Tuesday';

        break;
      }
      case 3: {
        this.today_day = 'Wednesday';

        break;
      }
      case 4: {
        this.today_day = 'Thursday';

        break;
      }
      case 5: {
        this.today_day = 'Friday';

        break;
      }
      case 6: {
        this.today_day = 'Saturday';

        break;
      }
      default: {
        break;
      }
    }
    this.newToday_date = moment(this.today_date).format('YYYY-MM-DD')


   }

  ngOnInit(): void {
    this.data_val = this.apiHit.userLoginData.data
    this.assign_login_data2 = this.data_val
    this.attendance_list('getAttendanceToday', 1);
  }
  
  getReportManager(searcValue) {
    this.apiHit.PostRequest({ 'search': searcValue }, "Attendance/getSalesUserForReporting").subscribe((result => {
      if (result['all_sales_user']['statusCode'] == 200) {
        this.report_manager = result['all_sales_user']['all_sales_user']
      }
      else {
        // this.toast.errorToastr(result['all_sales_user']['statusMsg'])
      }
    }));
  }
  change_tab(fn_name, type) {
    this.attendancelist = [];
    this.data = {}
    if (type == 1) {
      this.attendence_type = 'Present';
      this.tabType1 = 'Present';
    }
    else {
      this.attendence_type = '';
    }
    this.attendance_list(fn_name, type);

  }
  change_attendence_type_tab(fn_name, type, attendenceType) {
    this.attendence_type = attendenceType;
    this.attendancelist = [];
    this.data = {};
    this.attendance_list(fn_name, type);
  }
  attendance_list(func_name, type) {
    this.loader = true;
    if (this.pagenumber > this.total_page) {
      this.pagenumber = this.total_page;
      this.start = this.count_2 - this.page_limit;
    }
    if (this.start < 0) {
      this.start = 0;
    }
    if (Object.getOwnPropertyNames(this.data).length != 0) {
      this.attendancelist = [];
    }
    if (type == 1) {
      this.today_and_all_tab = 1;
    } else if (type == 2) {
      this.today_and_all_tab = 2;
    }
    if (this.data.date_created)
      this.data.date_created = moment(this.data.date_created).format('YYYY-MM-DD');
    if (this.data.date_from)
      this.data.date_from = moment(this.data.date_from).format('YYYY-MM-DD');
    if (this.data.date_to)
      this.data.date_to = moment(this.data.date_to).format('YYYY-MM-DD');


    this.data.status = this.tabType1
    this.data.tabType = this.tabType
    this.apiHit.PostRequest({ 'user_id': this.data_val.id, 'start': this.start, 'pagelimit': this.pagelimit, 'search': this.data, 'user_type': this.data_val.user_type, 'attendence_type': this.attendence_type }, "Attendance/" + func_name)
      .subscribe(((result: any) => {
        if (result['statusCode'] == 200) {
          this.loader = false;
          this.attendancelist = result['result']['attendence_data'];
          this.totalAttendanceCount = result['result']['count'];

          this.attendanceDate = result['result']['Date'];
          if (this.attendancelist.length == 0) {
            this.datanotfound = true
          } else {
            this.datanotfound = false
          }

          for (let i = 0; i < this.attendancelist.length; i++) {
            this.attendancelist[i].date_created_day = moment(this.attendancelist[i].date_created, 'YYYY.MM.DD').format("dddd");
            this.attendancelist[i].today_primary_sales = parseFloat(this.attendancelist[i].today_primary_sales)
            this.attendancelist[i].today_primary_sales = this.attendancelist[i].today_primary_sales.toFixed(2)
          }

          for (let i = 0; i < this.attendancelist.length; i++) {
            for (let j = 0; j < this.attendancelist[i].length; j++) {
              if (this.attendancelist[i][j].stop_reading == "") {
                this.attendancelist[i][j].start_reading = parseInt(this.attendancelist[i][j].start_reading);
              }
              else {
                this.attendancelist[i][j].stop_reading = parseInt(this.attendancelist[i][j].stop_reading);
                this.attendancelist[i][j].start_reading = parseInt(this.attendancelist[i][j].start_reading);
              }
            }
          }
          this.att_temp = result;

          if (type == 1) {
            this.getTabCount();

            this.count_1 = this.attendancelist.length;
            this.count_2 = result['result']['count'];

            this.show_today = true;
            this.absent = false;
            this.total_page = Math.ceil(this.count_2 / this.page_limit);

            this.pagenumber = Math.ceil(this.start / this.page_limit) + 1;

          }
          else {
            this.count_3 = result.total_no_of_attendence;
            this.count_1 = result.count;
            this.count_2 = this.attendancelist.length;
            this.show_today = false;
            this.absent = false;
            this.count = result['count'];

            this.total_page = Math.ceil(this.totalAttendanceCount / this.page_limit);
            this.pagenumber = Math.ceil(this.start / this.page_limit) + 1;
          }
          if (this.attendence_type == 'Present') {

            this.active_present_absent = true;

          } else if (this.attendence_type == 'Absent') {

            this.active_present_absent = false;

          }
          if (this.attendancelist.length == 0) {
            this.data_not_found = true;
          }
          else {
            this.data_not_found = false;
          }
        } else {
          // this.toast.errorToastr(result['statusMsg'])
          this.loader = false;
        }
      }), err => {
        this.loader = false;
      })

  }
  getTabCount() {
    this.apiHit.PostRequest({}, "Attendance/getAttendanceTodayCount").subscribe((response => {
      if (response['statusCode'] == 200) {
        this.tabCount = response['result']
      } else {
        // this.toast.errorToastr(response['statusMsg'])
      }
    }));
  }
  refresh(func_name, type) {
    this.data = {};
    this.start = 0;
    this.attendance_list(func_name, type);
  }
  attendancDetail(attendance_id, user_id, date) {
    const dialogRef = this.dialog.open(AttendenceDetailComponent, {
      panelClass: 'full-width-modal',
      data: {
        'attendance_id': attendance_id,
        'user_id': user_id,
        'date': date,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.tabType == 'Today') {
        this.attendance_list('getAttendanceToday', 1);
      } else {
        this.attendance_list('getAttendance', 2);
      }

    });
  }
}
