import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';


@Component({
    selector: 'app-pay-now-model',
    templateUrl: './pay-now-model.component.html'
})
export class PayNowModelComponent implements OnInit {

    AmountPayForm: FormGroup;
    distributorData: any;
    date: any;
    chequeMode: boolean = false;
    paytmMode: boolean = false;
    loader: any;
    wallet_balances: any;
    paymentAmount: any;
    hideSaveButton: boolean = false;


    constructor(

        @Inject(MAT_DIALOG_DATA) public data,
        public fb: FormBuilder,
        public apiHit: DatabaseService,
        public dialog: DialogService,
        public router: Router,
        private dialogRef: MatDialogRef<PayNowModelComponent>



    ) {
        dialogRef.disableClose = true;

        console.log(data);
        console.log(data['data']['wallet_balance']);


        this.distributorData = data;
        console.log(this.distributorData);
        this.wallet_balances = this.distributorData.data
        console.log(this.wallet_balances);


        this.date = new Date();


    }

    ngOnInit(): void {

        this.AmountPayForm = this.fb.group({

            paymentType: ['Wallet', [Validators.required]],
            paymentMode: ['', [Validators.required]],
            paymentAmount: ['', [Validators.required]],
            drId: [this.distributorData['data']['id'], [Validators.required]],
            drName: [this.distributorData['data']['dr_name'], [Validators.required]],
            chequeNo: new FormControl(''),
            chequeDate: new FormControl(''),
            transactionNo: new FormControl(''),
            pdf_file: ['', []],
            user_id : [this.apiHit.userLoginData.data.id,[]],
            remark: ['', [Validators.required]]


        });

    }

    // checkAmount(){

    //     console.log(this.AmountPayForm.value.paymentAmount);
    //     console.log(this.data['data']['wallet_balance']);

    //     console.log(this.AmountPayForm);



    //     if(this.AmountPayForm.value.paymentAmount > this.data['data']['wallet_balance']) {

    //         console.log('in if condition');
    //         this.dialog.error('error', 'Payment Amount Is More Than Distributor Wallet Balance');

    //     }
    //     else if(this.AmountPayForm.value.paymentAmount == 0){
    //         this.dialog.error('error', 'Zero Balance Can Not Pay');
    //     }
    //     else{

    //         this.payAmount();

    //     }

    //     // if(this.AmountPayForm.value.paymentAmount == 0){

    //     //     return;
    //     // }
    //     // else{
    //     //     this.payAmount();
    //     // }

    // }



    fileToUpload: File = null;
    handleFileInput(files: FileList) {
        console.log(files);

        this.fileToUpload = files.item(0);
        // this.uploadFile2('');
    }

    removePdfFile() {
        this.fileToUpload = null;
    }


    //Pay Amount Function Start

    checkAmount() {
        
        this.hideSaveButton = true;
        console.log(this.AmountPayForm);
        // for(var i = 0; i < this.data['data']['wallet_balance'].length; i++){
        //     JSON.stringify(this.data['data']['wallet_balance'] = Number(JSON.stringify(this.data['data']['wallet_balance)'])))

        // }
// return;
        if (this.AmountPayForm.value.paymentType == 'scanning_point' && (Number(this.data['data']['scanning_point']) < Number(this.AmountPayForm.value.paymentAmount))) {
            console.log(this.AmountPayForm.value.paymentAmount);
            console.log('====================================');
            console.log(this.data['data']['scanning_point']);
            console.log('====================================');
            this.hideSaveButton = false;
            this.dialog.error('error', 'Payment Points Is More Than Scanned by Customer Balance');

        }
        else if (this.AmountPayForm.value.paymentType == 'direct_point' && (Number(this.AmountPayForm.value.paymentAmount) > Number((this.data['data']['direct_point'])))) {
            this.hideSaveButton = false;
            this.dialog.error('error', 'Payment Points Is More Than Scanned by AFF Balance');

        }
        else if (this.AmountPayForm.value.paymentAmount == 0) {
            this.hideSaveButton = false;
            this.dialog.error('error', 'Zero Balance Can Not Pay');
        }
        else {

            this.payAmount();

        }

    }



    payAmount() {

        // this.loader = 1;
        this.hideSaveButton = false;
        let input = new FormData();
        // if (this.fileToUpload) {
        //     console.log('in if');

        //     input.append('pdf_file', this.fileToUpload);
        //     input.forEach((value, key) => {
        //         console.log(key + " " + value)
        //     });
        //     this.AmountPayForm.controls.pdf_file.setValue(input);
        // }
        // else {
        //     console.log('in else');
        //     this.AmountPayForm.controls.pdf_file.setValue('');
        // }
        if (this.AmountPayForm.controls.paymentMode.value == 'Cheque') {

            this.AmountPayForm.controls.chequeDate.setValue(moment(this.AmountPayForm.controls.chequeDate.value).format('YYYY-MM-DD'));

        }
        console.log(this.fileToUpload);
        console.log(this.AmountPayForm.value);

        // return

        this.apiHit.FileUpload(this.AmountPayForm.value, "Distributor/save_payment").subscribe((result => {

            console.log(result);
            if (result['savePayment'].status == 'success') {
                if (this.fileToUpload) {
                    this.pdfFileUpload(result['savePayment']['last_id'])
                }
                this.hideSaveButton = false;
                this.dialog.success('Success', 'Transaction Sucessfully Done');

                this.dialogRef.close();
                // this.router.navigate(['retailer']);

            }
            else if (result['savePayment'].status == 'AlreadyExist') {

                this.hideSaveButton = false;
                if (this.AmountPayForm.value.paymentMode == "Cheque") {
                    this.dialog.error('error', 'Cheque No Already Exist');
                } else {
                    this.dialog.error('error', 'Transaction No Already Exist');
                }

            } else {

                this.hideSaveButton = false;
                this.dialog.error('error', 'Something Went Wrong');

            }

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Pay Amount Function End

    pdfFileUpload(id) {

        let input = new FormData();
        console.log('in if');

        input.append('pdf_file', this.fileToUpload);
        input.forEach((value, key) => {
            console.log(key + " " + value)
        });

        this.apiHit.FileUpload(input, "Distributor/addPdf/"+id).subscribe((result => {

            console.log(result);

        }));

    }


    //Check Payment Function Start

    checkPaymentMode() {

        this.chequeMode = false;
        this.paytmMode = false;

        if (this.AmountPayForm.controls.paymentMode.value == 'Credit Note') {

            this.paytmMode = true;

            this.AmountPayForm.controls.paymentAmount.setValue('');

            this.AmountPayForm.controls.transactionNo.setValidators([Validators.required, Validators.minLength(4)]);
            this.AmountPayForm.controls.transactionNo.setValue('');

            this.AmountPayForm.controls.chequeNo.setValidators(null);
            this.AmountPayForm.controls.chequeDate.setValidators(null);
            this.AmountPayForm.controls.chequeNo.setValue('');
            this.AmountPayForm.controls.chequeDate.setValue('');

        }
        else if (this.AmountPayForm.controls.paymentMode.value == 'Cheque') {

            this.chequeMode = true;

            this.AmountPayForm.controls.paymentAmount.setValue('');
            this.AmountPayForm.controls.pdf_file.setValue('');

            this.AmountPayForm.controls.chequeNo.setValidators([Validators.required, Validators.minLength(4)]);
            this.AmountPayForm.controls.chequeDate.setValidators([Validators.required]);
            this.AmountPayForm.controls.chequeNo.setValue('');

            this.AmountPayForm.controls.transactionNo.setValidators(null);
            this.AmountPayForm.controls.transactionNo.setValue('');


        }
        else {

            this.AmountPayForm.controls.chequeNo.setValidators(null);
            this.AmountPayForm.controls.chequeDate.setValidators(null);
            this.AmountPayForm.controls.transactionNo.setValidators(null);

            this.AmountPayForm.controls.pdf_file.setValue('');
            this.AmountPayForm.controls.chequeNo.setValue('');
            this.AmountPayForm.controls.chequeDate.setValue('');
            this.AmountPayForm.controls.transactionNo.setValue('');
            this.AmountPayForm.controls.paymentAmount.setValue('');

        }
        this.AmountPayForm.controls.chequeNo.updateValueAndValidity();
        this.AmountPayForm.controls.chequeDate.updateValueAndValidity();
        this.AmountPayForm.controls.transactionNo.updateValueAndValidity();

    }
    //Check Payment Function End




    // KeyPress Function Only For Write Number Function Start

    OnlyNumber(event: any) {

        const pattern = /[0-9\'']/;
        let inputChar = String.fromCharCode(event.charCode);

        if (event.keyCode != 8 && !pattern.test(inputChar) || event.keyCode == '0') {

            event.preventDefault();

        }



    }
    // KeyPress Function Only For Write Number Function Starta


}