<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name">
                    <h2>Order Detail</h2>
                    <p>{{retailerInfo.dr_name}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <ng-template mat-tab-label *ngIf="loader">
                <div class="sk-loading">&nbsp;</div>
            </ng-template>
            <ng-template mat-tab-label *ngIf="!loader">
                <mat-icon class="example-tab-icon">info</mat-icon>
                Detail
            </ng-template>
            <div class="row">
                <div class="col s12 m8">
                    <div class="cs-column" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Retailer Information</h2>
                            <span>
                                <!-- <a class="edit-desc" *ngIf=" datas.module_name== 'Retailer' && datas.edit== 1"
                                    mat-raised-button (click)="OrderStatusChange(1,retailerInfo)"><i
                                        class="material-icons">edit</i></a> -->
                            </span>
                        </div>

                        <div class="cs-form ">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Company Name</span>
                                            <p>{{retailerInfo.dr_name ?
                                                retailerInfo.dr_name:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Contact Person Name</span>
                                            <p>{{retailerInfo.contact_name ? retailerInfo.contact_name:'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Mobile No</span>
                                            <p>{{retailerInfo.mobile_no ?
                                                retailerInfo.mobile_no:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Email</span>
                                            <p>{{retailerInfo.email_id ?
                                                retailerInfo.email_id:'N/A'}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <!-- <div class="col s3">
                                            <div class="cs-box">
                                                <span>Landline</span>
                                                <p>{{retailerInfo.pan_no ? retailerInfo.pan_no:'N/A'}}
                                                </p>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col s3">
                                            <div class="cs-box">
                                                <span>Sales Executive Name</span>
                                                <p>{{retailerInfo.email_id ?
                                                    retailerInfo.email_id:'N/A'}}</p>
                                            </div>
                                        </div> -->
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Address</span>
                                                    <p>{{retailerInfo.street? (retailerInfo.street +
                                                        ','):''}} {{retailerInfo.city_name?
                                                        (retailerInfo.city_name + ','):''}}
                                                        ,{{retailerInfo.district_name?
                                                        (retailerInfo.district_name + ','):''}},
                                                        {{retailerInfo.state_name? (retailerInfo.state_name
                                                        + ','):''}} ,{{retailerInfo.pincode}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    <!-- Skeleton Loading Data Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loading Data End -->


                    <div class="cs-column mt10" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Item Information</h2>
                            <ng-container>
                                <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                    <!-- <a class="edit-desc" *ngIf=" datas.module_name== 'Retailer' && datas.edit== 1"
                                        mat-raised-button (click)="OrderStatusChange(1,activateRoute['params']['_value'].id)"><i
                                            class="material-icons">edit</i></a> -->
                                </span>
                            </ng-container>
                        </div>

                        <div class="cs-table">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mmw90">Segment</div>
                                        <div class="div_cell mmw120">Brand</div>
                                        <div class="div_cell mmw150">Material Code</div>
                                        <div class="div_cell mw180">Part Code</div>
                                        <div class="div_cell mmw110 af-bdr-right">Qty</div>
                                        <!-- <div class="div_cell ac mmw70"></div> -->
                                    </main>
                                </section>
                            </figure>
                            <!-- Table Head End -->

                            <section class="td_cell">
                                <div class="df fdc ac" *ngIf="orderList.length == 0">
                                    <img src="assets/img/no-contact.svg" alt="No Contact" class="h180">
                                    <p style="font-size: 16px; color: #65617c;" class="mt30">No Order
                                        Available</p>
                                </div>
                                <!-- Loop Data -->
                                <main *ngFor="let data of orderList; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw90">{{data.segment ?
                                        data.segment:'N/A'}}</div>
                                    <div class="div_cell mmw120">{{data.category ?
                                        (data.category | titlecase ):'N/A'}}</div>
                                    <div class="div_cell mmw150">{{data.material_code ?
                                        (data.material_code | uppercase ):'N/A'}}</div>
                                    <div class="div_cell mw180">{{data.product_code ? data.product_code:'N/A'}}</div>
                                    <div class="div_cell mmw110">{{data.qty ?
                                        data.qty:'N/A'}}</div>

                                    <!-- <ng-container>
                                        <div class="div_cell ac mmw70">
                                            <div class="cs-action">
                                                <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                    <a class="ac-red" matRipple (click)="deleteOrder(data.order_id)"
                                                        *ngIf="datas.module_name== 'Retailer' && datas.delete==1"
                                                        matTooltip="Delete" matTooltipPosition="below"><i
                                                            class="material-icons">delete_sweep</i>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </ng-container> -->
                                </main>
                                <!-- Loop Data -->
                            </section>
                        </div>
                    </div>
                </div>



                <div class="col s12 m4">
                    <ng-container *ngIf="!loader">


                        <div class="cs-column mb10">
                            <div class="cs-head">
                                <h2>Order Information</h2>

                                <ng-container>
                                    <!-- <a class="edit-desc"
                                        *ngIf="assignDistributor.length > 0 && retailerInfo.status == 'Verified'"
                                        mat-raised-button (click)="OrderStatusChange(2,assignDistributor)"><i
                                            class="material-icons">edit</i></a> -->

                                </ng-container>
                                <ng-container>
                                    <!-- <a class="edit-desc"
                                        *ngIf="assignDistributor.length == 0 && retailerInfo.status == 'Verified'"
                                        mat-raised-button (click)="OrderStatusChange(2,retailerInfo)"><i
                                            class="material-icons">edit</i></a> -->
                                </ng-container>
                            </div>


                            <div class="cs-form ">
                                <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div class="cs-box">
                                                <span>Date</span>
                                                <p>{{orderDetail.created_date ?
                                                    (orderDetail.created_date | date: 'd MMM y, h:mm a' ):'N/A'}}
                                            </div>

                                            <div class="cs-box" *ngIf="orderDetail.order_status != 1">
                                                <span>Status Updated Date</span>
                                                <p>{{orderList[0].last_updated ?
                                                    (orderList[0].last_updated | date: 'd MMM y, h:mm a' ):'N/A'}}
                                            </div>
                                            
                                            <div class="cs-box">
                                                <span>Status</span>
                                                <strong *ngIf="orderDetail.order_status == 1"
                                                    class="yellow-clr">Pending</strong>
                                                <strong *ngIf="orderDetail.order_status == 2"
                                                    class="green-clr">Dispatch</strong>
                                                <strong *ngIf="orderDetail.order_status == 3"
                                                    class="red-clr">Reject</strong>
                                                <!-- <p>{{orderDetails.order_id}}
                                                </p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s12">
                                        <div class="fields">
                                            <div *ngIf="orderDetail.order_status == 3" class="cs-box">
                                                <span>Reject Reason</span>
                                                <p>{{orderDetail.reject_reason}}
                                                </p>
                                            </div>

                                            <div class="cs-box">
                                                <span>Order Id</span>
                                                <p>#AFF-{{orderDetail.order_id}}
                                                </p>
                                            </div>

                                            <div class="cs-box">
                                                <span>No Of Items</span>
                                                <p>{{orderDetail.total_items}}
                                                </p>
                                            </div>
                                            <div class="cs-box">
                                                <span>Total Qty</span>
                                                <p>{{orderDetail.total_quantity ?
                                                    orderDetail.total_quantity:'0'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>

                        <div class="cs-column">

                            <div class="cs-column mb10">
                                <div class="cs-head">
                                    <h2>Distributor Information</h2>

                                    <ng-container>
                                        <!-- <a class="edit-desc"
                                            *ngIf="assignDistributor.length > 0 && retailerInfo.status == 'Verified'"
                                            mat-raised-button (click)="OrderStatusChange(2,assignDistributor)"><i
                                                class="material-icons">edit</i></a> -->

                                    </ng-container>
                                    <ng-container>
                                        <!-- <a class="edit-desc"
                                            *ngIf="assignDistributor.length == 0 && retailerInfo.status == 'Verified'"
                                            mat-raised-button (click)="OrderStatusChange(2,retailerInfo)"><i
                                                class="material-icons">edit</i></a> -->
                                    </ng-container>
                                </div>


                                <div class="cs-form ">
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Company Name</span>
                                                    <p>{{distributorInfo.dr_name ?
                                                        distributorInfo.dr_name:'N/A'}}</p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Contact Person Name</span>
                                                    <p>{{distributorInfo.contact_name ?
                                                        distributorInfo.contact_name:'N/A'}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col s12">
                                            <div class="fields">
                                                <div class="cs-box">
                                                    <span>Mobile No</span>
                                                    <p>{{distributorInfo.mobile_no ? distributorInfo.mobile_no:'N/A'}}
                                                    </p>
                                                </div>
                                                <div class="cs-box">
                                                    <span>Address</span>
                                                    <p>{{distributorInfo.street? (distributorInfo.street +
                                                        ','):''}} {{distributorInfo.city_name?
                                                        (distributorInfo.city_name + ','):''}}
                                                        ,{{distributorInfo.district_name?
                                                        (distributorInfo.district_name + ','):''}},
                                                        {{distributorInfo.state_name? (distributorInfo.state_name
                                                        + ','):''}} ,{{distributorInfo.pincode}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </ng-container>
                    <!-- Sk Data Loading Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skDataTwo">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skDataTwo">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- Sk Data Loading End -->
                </div>
            </div>



        </div>
    </div>
</div>