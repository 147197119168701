<div *ngIf="modalData.model_type == 'add' || modalData.model_type == 'update'">
    <div mat-dialog-title class="model-head">
        <h2>{{data.id ? 'Update' : 'Generate'}}  Gate Pass</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form name="detail" #f="ngForm" (ngSubmit)=" f.valid && submitDetail()">
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                
                <div class="row">
                    <!-- <div class="col s12 m4 l4" *ngIf="logined_user_data.id == '1'">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Branch</mat-label>
                            <mat-select name="branch_code"  [(ngModel)]="data.branch_code" #branch_code="ngModel"  required (ngModelChange)="driverInfo(data.branch_code)">
                                <mat-option *ngFor="let row of branchData" value="{{row.branch_code}}">{{row.branch_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="alert alert-danger" *ngIf="branch_code.touched || f.submitted">
                            <p *ngIf="branch_code.errors?.required">This field is required</p>
                        </div>
                    </div> -->
                    
                    <div class="col s12 m4 l4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Delivery Boy</mat-label>
                            <input matInput placeholder="Type Here ..."  name="delivery_boy_id"  #delivery_boy_id="ngModel" [(ngModel)]="data.delivery_boy_id" [ngClass]="{'has-error' : delivery_boy_id.invalid } " required>
                        </mat-form-field>
                        <div class="alert alert-danger" *ngIf="delivery_boy_id.touched || f.submitted">
                            <p *ngIf="delivery_boy_id.errors?.required">This field is required</p>
                        </div>
                    </div>
                    <div class="col s12 m4 l4">
                        <mat-form-field  appearance="outline" class="cs-input">
                            <mat-label>Driver Mobile</mat-label>
                            <input matInput placeholder="Type Here ..."  name="mobile_number" #mobile_number="ngModel" [(ngModel)]="data.mobile_number" minlength="10" maxlength="10" min="0"   [ngClass]="{'has-error' : mobile_number.invalid } " required>
                        </mat-form-field>
                        
                        <div class="alert alert-danger" *ngIf="mobile_number.touched || f.submitted">
                            <p *ngIf="mobile_number.errors?.required">This field is required</p>
                            <p *ngIf="mobile_number.errors?.maxlength || mobile_number.errors?.minlength">Mobile No should be of 10 digits..</p>
                        </div>
                    </div>
                    <div class="col s12 m4 l4">
                        <mat-form-field  appearance="outline" class="cs-input">
                            <mat-label>Vehicle No</mat-label>
                            <input matInput placeholder="Type Here ..."  name="vehicle_number" #vehicle_number="ngModel" [(ngModel)]="data.vehicle_number" required>
                        </mat-form-field>
                        
                        <div class="alert alert-danger" *ngIf="vehicle_number.touched || f.submitted">
                            <p *ngIf="vehicle_number.errors?.required">This field is required</p>
                        </div>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col s12 m4 l4">
                        <mat-form-field  appearance="outline" class="cs-input">
                            <mat-label>Transportation Mode</mat-label>
                            <input matInput placeholder="Type Here ..."  name="transportation_mode" #transportation_mode="ngModel" [(ngModel)]="data.transportation_mode" required>
                        </mat-form-field>
                        
                        <div class="alert alert-danger" *ngIf="transportation_mode.touched || f.submitted">
                            <p *ngIf="transportation_mode.errors?.required">This field is required</p>
                        </div>
                    </div>
                    
                    <div class="col s12 m4 l4">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>POD Received</mat-label>
                            <input matInput placeholder="Type Here ..."  name="pod_received" #pod_received="ngModel" [(ngModel)]="data.pod_received"  [ngClass]="{'has-error' : pod_received.invalid } " required>
                        </mat-form-field>
                        <div class="alert alert-danger" *ngIf="pod_received.touched || f.submitted">
                            <p *ngIf="pod_received.errors?.required">This field is required</p>
                        </div>
                    </div>
                    <div class="col s12 m4 l4">
                        <mat-form-field  appearance="outline" class="cs-input">
                            <mat-label>Reference No</mat-label>
                            <input matInput placeholder="Type Here ..."  name="reference_no" #reference_no="ngModel" [(ngModel)]="data.reference_no">
                        </mat-form-field>
                    </div>
                 
                </div>
                <div class="row">
                    <div class="col s12 m4 l4">
                        <mat-form-field  appearance="outline" class="cs-input">
                            <mat-label>Box Value</mat-label>
                            <input matInput placeholder="Enter Box Value..."  name="box_value" #box_value="ngModel" [(ngModel)]="data.box_value">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="cs-table">
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw120">Date Created</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw120">Bill Number</div>
                                </main>
                            </section>
                        </figure>
                        
                        <section class="td_cell" >
                            <ng-container >
                                <!-- [ngClass]="{'dispatchWait': row.dispatch_qty == 0, 'dispatchPending': row.dispatch_qty > 0 && row.dispatch_qty < row.sale_qty, 'dispatchCom': row.dispatch_qty == row.sale_qty}" -->
                                <main *ngFor="let row of gatePassAssign; let i=index;">
                                    <div class="div_cell mmw50 text-center af-bdr-left">{{i+1}}</div>
                                    <div class="div_cell mmw120">{{row.Bill_Date | date:'dd MMM yyyy'}}</div>
                                    <div class="div_cell mw120">{{row.Cust_Vendor_Name | titlecase}} - ({{row.Cust_Vendor_Code}})</div>
                                    <div class="div_cell mmw120">{{row.ODN_Number}}</div>
                                </main>
                            </ng-container>
                            
                        </section>
                    </div>
                </div>
            </div>
        </div>
        
        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button mat-button type="submit">Update</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>



<div *ngIf="modalData.model_type == 'detail'">
    <div mat-dialog-title class="model-head">
        <h2>Gate Pass Detail</h2>
        <a class="close-model" color="warn" mat-button [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <div mat-dialog-content>
        <div class="cs-form cs-form-model">
            <div class="cs-column flat-column">
                <div class="cs-form ">
                    <div class="row">
                        <div class="col s12">
                            <div class="fields">
                                <div class="cs-box">
                                    <span>Date Created</span>
                                    <p>{{gatePassDetail.date_created | date}}</p>
                                </div>
                                <div class="cs-box">
                                    <span>Gatepass Number</span>
                                    <p>{{gatePassDetail.gate_pass_no}}</p>
                                </div>
                                <div class="cs-box">
                                    <span>Delivery Person</span>
                                    <p>{{gatePassDetail.delivery_boy_name}}</p>
                                </div>
                                <div class="cs-box">
                                    <span>Mobile No.</span>
                                    <p>{{gatePassDetail.mobile_number}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col s12">
                            <div class="fields">
                                <div class="cs-box">
                                    <span>Vehicle Number</span>
                                    <p>{{gatePassDetail.vehicle_number}}</p>
                                </div>
                                <div class="cs-box">
                                    <span>Transportation Mode</span>
                                    <p>{{gatePassDetail.transportation_mode}}</p>
                                </div>
                                <div class="cs-box">
                                    <span>POD Received</span>
                                    <p>{{gatePassDetail.pod_received ? gatePassDetail.pod_received :'---'}}</p>
                                </div>
                                <div class="cs-box">
                                    <span>Reference Number</span>
                                    <p>{{gatePassDetail.reference_number ? gatePassDetail.reference_number :'---'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="cs-table">
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw120">Date Created</div>
                                    <div class="div_cell mw150">Company Name</div>
                                    <div class="div_cell mmw120">Bill Number</div>
                                </main>
                            </section>
                        </figure>
                        
                        <section class="td_cell" >
                            <ng-container >
                                <!-- [ngClass]="{'dispatchWait': row.dispatch_qty == 0, 'dispatchPending': row.dispatch_qty > 0 && row.dispatch_qty < row.sale_qty, 'dispatchCom': row.dispatch_qty == row.sale_qty}" -->
                                
                                <main *ngFor="let row of gatePassDetail.invoice; let i=index;">
                                    <div class="div_cell mmw50 text-center af-bdr-left">{{i+1}}</div>
                                    <div class="div_cell mmw120">{{row.Bill_Date | date:'dd MMM yyyy'}}</div>
                                    <div class="div_cell mw120">{{row.Cust_Vendor_Name | titlecase}} - ({{row.Cust_Vendor_Code}})</div>
                                    <div class="div_cell mmw120">{{row.ODN_Number}}</div>
                                </main>
                            </ng-container>
                            
                        </section>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div  id="print_card" hidden>
            <h2 style="font-size: 20px;  text-align: center; margin: 16px 0px;">ASK FRAS-LE FRICTION PVT. LTD.</h2>
            <p style="font-size: 12px; text-align: center; margin: 10px 0px;" *ngIf="logined_user_data.address">{{logined_user_data.address}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Generate Date</strong> : {{gatePassDetail.date_created | date}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Gatepass Number</strong> : {{gatePassDetail.gate_pass_no}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Delivery Person</strong> : {{gatePassDetail.delivery_boy_name}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Mobile No.</strong> : {{gatePassDetail.mobile_number}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Vehicle Number</strong> : {{gatePassDetail.vehicle_number}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Transportation Mode</strong> : {{gatePassDetail.transportation_mode}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">POD Received</strong> : {{gatePassDetail.pod_received ? gatePassDetail.pod_received :'---'}}</p>
            <p style="font-size: 13px; margin: 7px 0px; padding-left:16px;"><strong style="font-size: 13px;">Reference Number</strong> : {{gatePassDetail.reference_number ? gatePassDetail.reference_number :'---'}}</p>
            <table style=" width: 100%; table-layout: fixed; border-collapse: collapse;">
                <tr>
                    <td style="font-size: 13px; padding: 10px 16px; font-weight: 600; border-bottom: 1px solid #000; width: 120px;">Date Created</td>
                    <td style="font-size: 13px; padding: 10px 16px; font-weight: 600; border-bottom: 1px solid #000;">Company Name</td>
                    <td style="font-size: 13px; padding: 10px 16px; font-weight: 600; border-bottom: 1px solid #000; width: 120px;">Bill Number</td>
                    <td style="font-size: 13px; padding: 10px 16px; font-weight: 600; border-bottom: 1px solid #000; width: 120px; text-align:center">Total Quantity</td>
                </tr>
                <tr *ngFor="let row of gatePassDetail.invoice;">
                    <td style="font-size: 13px; padding: 10px 16px;">{{row.Bill_Date | date:'dd MMM yyyy'}}</td>
                    <td style="font-size: 13px; padding: 10px 16px;">{{row.Cust_Vendor_Name | titlecase}} - ({{row.Cust_Vendor_Code}})</td>
                    <td style="font-size: 13px; padding: 10px 16px;">{{row.ODN_Number}}</td>
                    <td style="font-size: 13px; padding: 10px 16px; text-align:center">{{row.total_billing_item_qty}}</td>
                </tr>
            </table>
        </div>
    </div>
    
    <mat-dialog-actions align="end">
        <div class="cs-btn">
            <button mat-button (click)="printData();">Print Now</button>
        </div>
    </mat-dialog-actions>
</div>




