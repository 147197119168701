<div *ngIf="data.target == 1">
    <div mat-dialog-title class="model-head" >
        <h2>Coupon Staus</h2>
        <a class="close-model" mat-button  [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>
    <form [formGroup]="StatusForm" (ngSubmit)="statusUpdate()" >
        <div mat-dialog-content>
            <div class="cs-form cs-form-model">
                <div class="row row-mb0">
                    <div class="col s12">
                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Status <span class="sub">*</span></mat-label>
                            <mat-select formControlName="coupon_status" 
                            id="coupon_status" (ngModelChange)="checkStatusMode()">
                                <mat-option value="Pending">Pending</mat-option>
                                <mat-option value="Verified">Verified</mat-option>
                                <mat-option value="Reject">Reject</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="StatusForm.controls.coupon_status.invalid && StatusForm.controls['coupon_status']?.touched ">
                            Required
                        </mat-hint>
                    </div>
                </div>
                <div class="row row-mb0" *ngIf="statusReject">
                    <div class="col s12">
                        <mat-form-field appearance="outline" class="cs-input mr5">
                            <mat-label>Reason <span class="sub">*</span></mat-label>
                            <input matInput placeholder="Type Here ..." formControlName="reason" 
                            id="reason">
                        </mat-form-field>
                        <mat-hint class="has-error"
                            *ngIf="StatusForm.controls.reason.invalid && StatusForm.controls['reason']?.touched ">
                            Required
                        </mat-hint>
                    </div>
                </div>

            </div>
        </div>

        <mat-dialog-actions align="end">
            <div class="cs-btn">
                <button class="save-btn" mat-button [disabled]="StatusForm.invalid">Update</button>
                <!-- <button class="save-btn" mat-button>Update</button> -->
            </div>
        </mat-dialog-actions>
        
    </form>
</div>


<div *ngIf="data.target == 4 || data.target == 5">
    <div mat-dialog-title class="model-head" >
        <h2>Box Detail</h2>
        <a class="close-model" mat-button  [mat-dialog-close]><i class="material-icons">close</i></a>
    </div>

    <div class="row">
        <div class="col s12 m12">


            <div class="cs-table table-scroll">

                <div class="cs-column">
                    <div class="cs-head">
                        <h2>basic Information</h2>
                    </div>

                    <div class="cs-form">

                        <div class="row">
                            <div class="col s12">
                                <div class="fields">
                                    <div class="cs-box">
                                        <span>Master Coupon Code</span>
                                        <p>{{data.data.coupon_code ? data.data.coupon_code : 'N/A'}}</p>
                                    </div>
                                    <div class="cs-box">
                                        <span>Part Code</span>
                                        <p>{{data.data.part_code ? data.data.part_code : 'N/A'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col s12">
                                <div class="fields">
                                    <div class="cs-box">
                                        <span>Box Size</span>
                                        <p>{{data.data.box_size ? data.data.box_size : 0}}</p>
                                    </div>
                                    <div class="cs-box">
                                        <span>Scanned Date</span>
                                        <p>{{data.data.date_of_packing ? (data.data.date_of_packing | date : 'd MMM y, h:mm a'): 'N/A'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" *ngIf="data.target == 5">
                            <div class="col s12">
                                <div class="fields">
                                    <div class="cs-box">
                                        <span>Dispatch Date</span>
                                        <p>{{data.data.dispatch_date_time ? (data.data.dispatch_date_time | date : 'd MMM y, h:mm a'): 'N/A'}}</p>
                                    </div>
                                    <div class="cs-box">
                                        <span>Invoice No</span>
                                        <p>{{data.data.invoice_no ? data.data.invoice_no : 'N/A'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="cs-column mt10">
                    <div class="cs-head">
                        <h2>Item Information</h2>
                    </div>

                    <div class="cs-table">
                    
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 ac">S.No.</div>
                                    <div class="div_cell mw230">Primary Coupon Code</div>
                                </main>
                            </section>
                        </figure>

                        <section class="td_cell">
                            <main *ngFor="let data of data.data.box_item; let i=index">
                                <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                <div class="div_cell mw230">{{data.coupon_code ? data.coupon_code : 'N/A'}}</div>
                            </main>
                        </section>
                        
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>