import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CouponStatusModelComponent } from '../coupon-status-model/coupon-status-model.component';


@Component({
  selector: 'app-coupon-code-add',
  templateUrl: './coupon-code-add.component.html'
})
export class CouponCodeAddComponent implements OnInit {

  searchCtrl: any;
  data_search: string;
  couponForm: FormGroup;
  couponList: any = [];
  skData: any = [];
  loader: any = 1;
  date: any;
  filterSearch: any = {};
  couponAmount: any = [];
  couponCount: any = [];
  paginationData: any = {};
  partNo: any = [];
  categoryName: any = [];
  segmentdata: any = [];
  datas: any;
  userId: any;
  pageNumber: any;
  total: any;
  totalCoupon: any;
  matTabValue: any;

  typeCoupon = [

    { id: 1, name: "Primary" },
    { id: 2, name: "Master" }

  ];



  constructor(

    private fb: FormBuilder,
    public db: DatabaseService,
    public dialogs: DialogService,
    private _location: Location,
    public dialog: MatDialog,
    public apiHit: DatabaseService,



  ) {

    this.matTabValue = 0;
    this.date = new Date();
    this.userId = this.db.userLoginData.data.id;
    this.pageNumber = 1;

  }

  ngOnInit(): void {

    this.couponForm = this.fb.group({

      coupon_type: ['', [Validators.required]],
      coupon_count: ['', [Validators.required]],
      segment: ['', [Validators.required]],
      category: ['', [Validators.required]],
      part_code: ['', [Validators.required]],
      count_amount: ['', [Validators.required]],
      uid: [this.userId],
      searchCtrl: [''],

    });

    this.skData = new Array(10);
    this.getSegment();
    // this.getCouponCountAndValue();
    this.genrateCouponList(1);
    // this.getPartCodeNo();s
    console.log(this.couponList);

  }


  checkCouponType(CouponType) {

    console.log(CouponType);
    this.loader = 1;
    if (CouponType == 1) {
      this.couponForm.controls.count_amount.setValidators([Validators.required]);
    }
    else {
      this.couponForm.controls.count_amount.setValidators(null);
    }
    this.couponForm.controls.coupon_count.setValue('');
    this.couponForm.controls.segment.setValue('');
    this.couponForm.controls.category.setValue('');
    this.couponForm.controls.part_code.setValue('');
    this.couponForm.controls.count_amount.setValue('');
    this.loader = '';

  }


  getSegment() {

    this.loader = 1;
    this.db.PostRequest('', "app/AppOrderController/getSegment").subscribe((result => {

      console.log(result['result']);
      if (result['result']['status'] == 'success') {
        this.segmentdata = result['result']['segmentData'];
      }

    }));
  }

  //Part Code Api Start
  getCategory(brand) {

    this.loader = 1;
    this.partNo = [];
    this.categoryName = [];
    // console.log(this.couponForm.value);
    this.couponForm.controls.category.setValue('');
    this.couponForm.controls.part_code.setValue('');
    this.couponForm.controls.count_amount.setValue('');

    this.db.PostRequest({ 'brand': brand }, "CouponCode/partCategory").subscribe((result => {

      console.log(result);
      this.categoryName = result['partCategory'];

      setTimeout(() => {
        this.loader = '';
      }, 500);

    }));

  }
  //Part Code Api End


  //Part Code Api Start
  getPartCodeNo(category) {

    this.loader = 1;
    this.partNo = [];
    this.couponForm.controls.part_code.setValue('');
    this.couponForm.controls.count_amount.setValue('');
    console.log(this.couponForm.controls.segment.value);

    this.db.PostRequest({ 'category': category, segment: this.couponForm.controls.segment.value }, "CouponCode/partNoList").subscribe((result => {

      console.log(result);

      this.partNo = result['part_No_List'];

      setTimeout(() => {
        this.loader = '';
      }, 400);

    }));

  }
  //Part Code Api End


  //Get Part Code Value Api Start
  getPartPointValue(part_code) {
    this.findInfo();
    if (this.couponForm.value.coupon_type == 1) {
      this.loader = 1;
      this.couponForm.controls.count_amount.setValue('');

      this.db.PostRequest({ 'part_code': part_code }, "CouponCode/getPartsCouponValue").subscribe((result => {

        console.log(result);
        if (result['coupon_value']['status'] == 'success') {

          this.couponForm.controls.count_amount.setValue(result['coupon_value']['coupon_value']);
          this.loader = '';
        }
        else {
          this.dialogs.error('Error', 'Something Went Wrong');
          this.loader = '';
        }

      }));

    }

  }
  //Get Part Code Value Api End

  findInfo(){
    let index=  this.partNo.findIndex(row=>row.part_code== this.couponForm.value.part_code);
    if(index!= -1){
      this.couponForm.value.material_code = this.partNo[index].material_code;
    }
  }


  // *** Savecoupon funcation start *** //
  saveCoupon(form: NgForm) {


    console.log(this.couponForm.value);
    this.loader = 1;

    this.db.PostRequest(this.couponForm.value, "CouponCode/CouponCodes_add").subscribe((result => {
      console.log(result);

      if (result['CouponCodes_add'] == "success") {
        this.matTabValue = Number(this.couponForm.value.coupon_type) - 1;
        this.dialogs.success('Success', 'Coupon Successfully Genrated');
        form.resetForm();
        this.filterSearch = {}
        this.couponForm.controls.uid.setValue(this.userId);
        this.genrateCouponList(1);
      }

      else {
        this.dialogs.error('Error', 'Something Went Wrong');
        this.loader = '';
      }

    }));



  }
  // *** Savecoupon funcation end *** //


  tabClick(tab) {

    console.log(tab);
    this.matTabValue = tab['tab']['textLabel'];
    this.filterSearch = {};
    this.pageNumber = 1;
    this.genrateCouponList(1);

  }

  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.genrateCouponList(2);
        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.genrateCouponList(2);
        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.genrateCouponList(2);
        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.genrateCouponList(2);


        }
        else if (this.pageNumber) {

          this.genrateCouponList(2);

        }

      }

    },
      100);
  }



  // *** CouponList funcation start *** //
  genrateCouponList(target) {
    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }
    this.total = 1;
    this.paginationData.search = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.coupon_type = Number(this.matTabValue) + 1;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
   
    this.db.PostRequest(this.paginationData, "CouponCode/CouponCodes_list").subscribe((result => {
      console.log(result);
      this.couponList = result['CouponCodes_list']['details'];
      this.total = result['CouponCodes_list']['totalPage'];
      this.totalCoupon = { 'primaryCount': result['CouponCodes_list']['primary_total_row'], 'masterCount': result['CouponCodes_list']['master_total_row'] };

      console.log(this.couponList);
      setTimeout(() => {
        this.loader = '';
      }, 2000);
    }));
  }
  // *** CouponList funcation end *** //


  // *** Delete Funcation start ***//
  deleteItem(id, couponType) {
    this.dialogs.delete('').then((result) => {

      if (result === true) {

        if (couponType == 0) {

          this.db.PostRequest({ 'id': id, }, "CouponCode/CouponCodes_delete").subscribe((result => {

            this.loader = 1;
            console.log(result);

            if (result['CouponCodes_delete'] == 'deleted') {

              this.genrateCouponList(2);

            }
            else {

              this.dialogs.error('error', 'Something Went Wrong');
              setTimeout(() => {

                this.loader = '';

              }, 100);

            }

          }));

        }
        else {
          this.db.PostRequest({ 'id': id, }, "CouponCode/MasterCouponCodes_delete").subscribe((result => {

            this.loader = 1;
            console.log(result);

            if (result['CouponCodes_delete'] == 'deleted') {

              this.genrateCouponList(2);

            }
            else {
              this.dialogs.error('error', 'Something Went Wrong');
              setTimeout(() => {

                this.loader = '';

              }, 100);
            }

          }));
        }
        
      }

    });
  }
  // *** Delete Funcation end ***//


  excelData: any = [];
  csvData: any = [];

  // *** Primary CSV Funaction start ***//
  exportAsXLSX(id): void {

    this.loader = 1;
    this.excelData = [];
    this.csvData = [];


    this.db.PostRequest({ 'id': id }, "CouponCode/exportCSV").subscribe((result => {

      if (result['msg'] == 'Success') {
        this.csvData = result['data'];
        for (let i = 0; i < this.csvData.length; i++) {
          this.excelData.push({
            'Coupon Code': this.csvData[i].coupon_code,
            'Coupon Amount': this.csvData[i].coupon_code_amount,
            'Coupon Id': this.csvData[i].coupon_generate_id,
            'Part Number': this.csvData[i].part_code,
            'Set Box Price': this.csvData[i].set_box_price,
            'Set box pack': this.csvData[i].set_box_pack,
            'short part code': this.csvData[i].short_part_code,
            'with rivet or pad kit': this.csvData[i].with_rivet_or_pad_kit,
          


          });
        }
        this.db.exportAsExcelFile(this.excelData, 'CouponList');
        this.loader = '';
      }
      else {
        this.dialogs.error('Error', 'Something Went Wrong');
        this.loader = '';
      }
    }));
  }
  // *** Primary CSV Funaction end ***//

  exportTxt(id): void {

    this.loader = 1;
    this.paginationData.pagelimit = 0;

    this.db.PostRequest({ 'id': id }, "Excel/exportTxt").subscribe((result => {

      console.log(result);
      this.genrateCouponList(2);
      setTimeout(() => {

        this.loader = '';
        window.open(this.db.downloadURL + "askApi/uploads/Download_excel/coupons_data.txt");
      }, 200);

    }));
  }

  // *** Master CSV Funaction start ***//
  exportMasterExcel(id): void {

    this.loader = 1;
    this.paginationData.pagelimit = 0;

    this.db.PostRequest({ 'id': id }, "CouponCode/exportGenerateMasterCoupons").subscribe((result => {

      console.log(result);
      this.genrateCouponList(2);
      setTimeout(() => {

        this.loader = '';
        window.open(this.db.downloadURL + "uploads/master_coupon_scan_data.csv");

      }, 200);

    }));
  }
  // *** Master CSV Funaction end ***//


  public onDate(event): void {
    this.filterSearch.date_created = moment(event.value).format('YYYY-MM-DD');
    this.genrateCouponList(1);
  }

  //Refresh Distributor List Function Start

  refreshData() {

    this.filterSearch = {};
    this.genrateCouponList(1);

  }
  //Refresh Distributor List Function End


  // KeyPress Function  Start

  OnlyNumber(event: any) {

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {

      event.preventDefault();

    }
  }
  // KeyPress Function  End

  // Back Button Function  Start

  backClicked() {

    this._location.back();

  }
  // Back Button Function  End


  statusModel(target, data): void {

    const dialogRef = this.dialog.open(CouponStatusModelComponent, {

      width: '400px',
      data:
      {
        target,
        data
      }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.genrateCouponList(1);

    });

  }

}