<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <div class="module-name" *ngIf="!loader">
                <h2>Warehouse Stock</h2>
                <!-- <p>{{wareHouseStockData ? wareHouseStockData.length : '0'}} Warehouse Stock Are Available</p> -->
            </div>
            <div class="left-auto" *ngIf="!loader">
                <ul class="date-filter">
                    <li>
                        <div class="cs-form pb0">
                            <div class="col s12 m6 relative">
                                <!-- <p>Select State</p> -->
                                <mat-form-field appearance="outline" class="cs-input" style="width: 250px;">
                                    <mat-label>Select Warehouse <span class="sub">*</span></mat-label>
                                    <mat-select [value]="selected_warehouse" name="warehouse_id" id="warehouse_id">
                                        <mat-option (click)="selectWarehouse(data)" *ngFor="let data of warehouseData; let i=index" value="{{data.id}}"> {{data.warehouse_name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </li>
                    <!-- <li>
                        <div class="cs-form pb0">
                            <p>Date From</p>
                            <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                    placeholder="Search ..." name="date_from" [(ngModel)]="search.date_from" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </li>
                    <li>
                        <div class="cs-form pb0">
                            <p>Date To</p>
                            <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                    placeholder="Search ..." name="date_to" [(ngModel)]="search.date_to" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </li>
                    <li class="cs-btn mt15 mr10" *ngIf="this.search.date_from && this.search.date_to">
                        <a mat-button (click)="wareHouseStockList(1)">Go</a>
                    </li>
                    <li class="cs-btn mt15" *ngIf="this.search.date_from || this.search.date_to">
                        <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                    </li>
                    <li class="cs-btn mt15 ml10">
                        <a mat-button (click)="updateRetailerScheme()">Update Scheme</a>
                    </li> -->


                </ul>
            </div>
            <div class="cs-action">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                    (click)="wareHouseStockList(1)"><i class="material-icons">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                    (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Segment</div>
                            <div class="div_cell mmw130">Brand</div>
                            <div class="div_cell mmw140">Material Code</div>
                            <div class="div_cell mw230">Part Code</div>
                            <div class="div_cell mmw110">Master Box Qty</div>
                            <div class="div_cell mmw110">Master Box Size</div>
                            <div class="div_cell mmw110">Total Qty</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw70 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="segment"
                                        [(ngModel)]="filterSearch.segment" (keyup.enter)="wareHouseStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="category"
                                        [(ngModel)]="filterSearch.category" (keyup.enter)="wareHouseStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="material_code"
                                        [(ngModel)]="filterSearch.material_code" (keyup.enter)="wareHouseStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw230">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                        [(ngModel)]="filterSearch.part_code"
                                        (keyup.enter)="wareHouseStockList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw110"></div>
                            <div class="div_cell mmw110"></div>
                            <div class="div_cell mmw110">
                                <!-- <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                        (click)="wareHouseStockList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                        (click)="refreshData()"><i
                                            class="material-icons">settings_backup_restore</i></a>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->

                <section class="td_cell">
                    <!-- Loop Data -->
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of wareHouseStockData; let i =index;">
                            <div class="div_cell mmw70 text-center af-bdr-left"><strong>{{i+1}}</strong></div>
                            <div class="div_cell mmw110">{{data.segment ? data.segment:'N/A'}} </div>
                            <div class="div_cell mmw130">{{data.category ? data.category :'N/A'}} </div>
                            <div class="div_cell mmw140">{{data.material_code ? data.material_code:'N/A'}} </div>
                            <div class="div_cell mw230">{{data.part_code ? data.part_code:'N/A'}} </div>
                            <div class="div_cell mmw110">{{data.total_qty ? data.total_qty:'0'}}</div>
                            <div class="div_cell mmw110">{{data.box_size ? data.box_size:'0'}}</div>
                            <div class="div_cell mmw110">{{(data.box_size * data.total_qty)}}</div>
                        </main>
                        <app-not-found *ngIf="wareHouseStockData.length == 0"></app-not-found>
                    </ng-container>
                    <!-- Loop Data -->


                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw70 text-center af-bdr-left">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw140">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw230">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>

                </section>
            </div>

            <!-- Pagination Start -->
            <!-- <div class="bottom-strip">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                         </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="wareHouseStockData.length > 0" (click)="exportExcel()"
                            class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                </div>
            </div> -->
            <!-- Pagination End -->
        </div>
    </div>
</div>