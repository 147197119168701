import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
  selector: 'app-coupon-stock-utilize',
  templateUrl: './coupon-stock-utilize.component.html',
})
export class CouponStockUtilizeComponent implements OnInit {

  skeleton: any = [];
  date: any;
  loader: any;
  couponData: any = [];
  filterSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  totalUser: any;
  finalOpeningCoupon: any = 0;
  finalGenerateDuringPeriod: any = 0;
  finalScannedDuringPeriod: any = 0;
  finalDamageCoupon: any = 0;
  finalRemainingCoupon: any = 0;
  totalOpeningCoupon: any = 0;
  totalGenerateDuringPeriod: any = 0;
  totalScannedDuringPeriod: any = 0;
  totalDamageCoupon: any = 0;
  TotalRemainingCoupon:any  = 0;

  constructor(

    public apiHit: DatabaseService,
    public dialog: DialogService,

  ) {

    this.skeleton = new Array(10);
    this.date = new Date();
    this.date = new Date();
    this.pageNumber = 1;
    this.total = 1;
    // this.utilizeCouponList(1);

  }

  ngOnInit(): void {
    
  }

  clearDateFilter(){
    this.filterSearch.date_from = '';
    this.filterSearch.date_to = '';
    this.utilizeCouponList(1);
  }
  
  //Get User List Through API Function Start
  utilizeCouponList(target) {

    this.loader = 1;
    if (target == 1) {
      this.pageNumber = 1;
    }
    if (this.filterSearch.date_from && this.filterSearch.date_to) {
      this.filterSearch.date_from = moment(this.filterSearch.date_from).format('YYYY-MM-DD');
      this.filterSearch.date_to = moment(this.filterSearch.date_to).format('YYYY-MM-DD');
    }
    this.couponData = [];
    this.paginationData.searchData = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;

    this.apiHit.setFilterData(this.paginationData);
setTimeout(() => {
  

    this.apiHit.PostRequest(this.paginationData, "Distributor/get_coupon_code_by_value").subscribe((result => {

      this.couponData = result['coupon_code'];
      for (let i = 0; i < this.couponData.length; i++) {
      let newCouponCodeAmount= this.couponData[i].coupon_code_amount>0 ?this.couponData[i].coupon_code_amount: 1;
        console.log(newCouponCodeAmount);
       this.totalOpeningCoupon = (Number(newCouponCodeAmount) * this.couponData[i].no_of_opening_coupon_in_market) 
       this.totalGenerateDuringPeriod = (Number(newCouponCodeAmount) * this.couponData[i].generate_during_period) 
       this.totalScannedDuringPeriod = (Number(newCouponCodeAmount) * this.couponData[i].scanned_during_period) 
       this.totalDamageCoupon = (Number(newCouponCodeAmount) * this.couponData[i].damage_coupon) 
       this.TotalRemainingCoupon = (Number(newCouponCodeAmount) * this.couponData[i].remaining_coupon) 
       this.finalOpeningCoupon += this.totalOpeningCoupon
       this.finalGenerateDuringPeriod += this.totalGenerateDuringPeriod
       this.finalScannedDuringPeriod +=  this.totalScannedDuringPeriod
       this.finalDamageCoupon += this.totalDamageCoupon
       this.finalRemainingCoupon += this.TotalRemainingCoupon
      }
      this.loader = '';

    }));
  }, 5000);
  }
  //Get User List Through API Function End


  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.utilizeCouponList(2);

        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.utilizeCouponList(2);

        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.utilizeCouponList(2);

        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.utilizeCouponList(2);

        }
        else if (this.pageNumber) {

          this.utilizeCouponList(2);

        }

      }

    },
      100);
  }

  refreshData() {

    this.filterSearch.coupon_code_amount = '';
    this.utilizeCouponList(1);

  }

}
