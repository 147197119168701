<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container >
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                (click)="backClicked()">
                <mat-icon>arrow_back</mat-icon>
            </a>
            <div class="module-name">
                <h2>Dispatch Details</h2>
            </div>
        </ng-container>
        
    </section>
    
    <div class="container-pd10-5 no-padding">
        <div class="row mt10">
            <div class="col s12 m12">
                <div class="cs-column">
                    <div class="cs-head">
                        <h2>Invoice Details</h2>
                    </div>
                    <div class="cs-form ">
                        <div class="row mb0">
                            <div class="col s12">
                                <div class="fields">
                                    <div class="cs-box">
                                        <span>Date</span>
                                        <p>{{invoice_detail.Bill_Date | date:'dd MMM yyyy'}}</p>
                                    </div>
                                    
                                    <div class="cs-box">
                                        <span>Customer Detail</span>
                                        <p>{{invoice_detail.Cust_Vendor_Name}} - {{invoice_detail.Cust_Vendor_Code}}</p>
                                    </div>
                                    <div class="cs-box">
                                        <span>Invoice Number</span>
                                        <p>{{invoice_detail.ODN_Number ? invoice_detail.ODN_Number : '---'}}</p>
                                    </div>
                                    <div class="cs-box">
                                        <span>Total Item</span>
                                        <p>{{invoice_detail.total_billing_item}}</p>
                                    </div>
                                    <div class="cs-box">
                                        <span>Total QTY. </span>
                                        <p>{{invoice_detail.total_billing_item_qty}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
        <div class="row">
            <div class="col s12 m6">
                <div class="cs-column ">
                    <div class="cs-form pb0 ">
                        <div class="row mb0">
                            <div class="col s12 m6 l6">
                                <mat-form-field  appearance="outline" class="cs-input">
                                    <mat-label>Coupon Number</mat-label>
                                    <input matInput placeholder="Type Here ..."  name="coupon_number" #coupon_number="ngModel" [(ngModel)]="couponNumber.coupon_number"  minlength="17" maxlength="17"  #focusInput  min="0"  (ngModelChange)="checkCoupon(couponNumber.coupon_number)">
                                </mat-form-field>
                            </div>
                        </div>
                        
                        <div class="qty_value"  *ngIf="dispatchQTY > 0">
                            {{dispatchQTY}}/{{dispatchInvoice}}
                        </div>
                    </div>
                    
                    
                    <ng-container *ngIf="temCoupon.length > 0">
                        <div class="cs-head">
                            <h2>Scan Coupon</h2>
                        </div>
                        <div class="cs-table">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 af-bdr-left">S.No.</div>
                                        <div class="div_cell mw120">Coupon Code</div>
                                        <div class="div_cell mmw100 text-center">Status</div>
                                    </main>
                                </section>
                            </figure>
                            
                            <section class="td_cell" >
                                <ng-container >
                                    <main *ngFor="let row of temCoupon; let i =index" [ngClass]="row.status == 'Pending' ? 'dispatchPending' : row.status == 'Success' ? 'dispatchCom' : 'dispatchWait'">
                                        <div class="div_cell mmw50 text-center af-bdr-left">{{i+1}}</div>
                                        <div class="div_cell mw120">{{row.coupon_no}}</div>
                                        <div class="div_cell mmw100 ac">{{row.status}}</div>
                                    </main>
                                </ng-container>
                            </section>
                        </div>
                    </ng-container>
                </div>
            </div>
            
            <div class="col s12 m6">
                <div class="cs-column">
                    <div class="cs-head">
                        <h2>Item Detail</h2>
                    </div>
                    <div class="cs-table">
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 af-bdr-left">S.No.</div>
                                    <div class="div_cell mw120">Item Details</div>
                                    <div class="div_cell mmw50 text-center">QTY.</div>
                                    <div class="div_cell mmw100  text-center">Dispatch  QTY.</div>
                                </main>
                            </section>
                        </figure>
                        
                        <section class="td_cell" >
                            <ng-container >
                                <main *ngFor="let row of dispatchItem; let i=index;" [ngClass]="{'dispatchWait': row.dispatch_qty == 0, 'dispatchPending': row.dispatch_qty > 0 && row.dispatch_qty < row.Qty, 'dispatchCom': row.dispatch_qty == row.Qty}">
                                    <div class="div_cell mmw50 text-center af-bdr-left">{{i+1}}</div>
                                    <div class="div_cell mw120">{{row.Description}} - {{row.Item_Code}}</div>
                                    <div class="div_cell mmw50 ac">{{row.Qty}}</div>
                                    <div class="div_cell mmw100  ac">{{row.dispatch_qty}}</div>
                                </main>
                            </ng-container>
                            
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>