import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


@Component({
    selector: 'app-banner-list',
    templateUrl: './banner-list.component.html'
})
export class BannerListComponent implements OnInit {

    status_change: any;

    AddBannerForm: FormGroup;
    file_data: any;
    loader: any;
    skeleton: any = [];
    bannerData: any;


    constructor(

        private httpClient: HttpClient,
        private fb: FormBuilder,
        public apiHit: DatabaseService,
        public dialog: DialogService,
        public router: Router,


    ) {

        this.getBanner();
    }

    ngOnInit(): void {
        this.skeleton = new Array(4);

        this.AddBannerForm = this.fb.group({

            // file : new FormControl('')
            file: ['', Validators.required],

        });


    }


    //Change Status function start
    change_active_deactive(id, status) {

        console.log(id);
        console.log(status);



        if (status == true) {

            this.status_change = 'deactive'

        }

        else {

            this.status_change = 'active'

        }

        this.loader = 1;

        this.apiHit.PostRequest({ 'id': id, 'status': this.status_change }, "Banner/change_status ").subscribe((result => {

            setTimeout(() => {

                this.loader = '';

            },

                100);

            if (result['change_banner_status'] == 'success') {

                this.dialog.success('Status Updated', 'Successfully');
                this.getBanner();
            }
        }));

    }
    //Change Status function end


    // activeDeactive(data){

    //     // this.loader = 1;
    //     console.log(data);

    //     return;

    //     this.apiHit.PostRequest( '' , "Banner/change_status").subscribe((result => {

    //         console.log(result);

    //         this.bannerData = result['show_banners'];
    //         console.log(this.bannerData);


    //         setTimeout(() => {

    //             this.loader = '';

    //         }, 700);

    //     }));


    // }



    //Get Banner Function Start
    getBanner() {

        this.loader = 1;

        this.apiHit.PostRequest('', "Banner/showBanners").subscribe((result => {

            console.log(result);

            this.bannerData = result['show_banners'];
            console.log(this.bannerData);


            for (let index = 0; index < this.bannerData.length; index++) {

                if (this.bannerData[index].status == 'active') {

                    this.bannerData[index].isActive = true;

                } else {
                    this.bannerData[index].isActive = false;

                }

            }



            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Get Banner Function END



    //Gets called when the user selects an image
    public onFileChanged(event) {

        const fileList: FileList = event.target.files;

        if (fileList.length > 0) {

            const file = fileList[0];
            console.log('finfo', file.name, file.size, file.type);
            this.uploadFile2(file);
        }

    }

    fileToUpload: File = null;
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        this.uploadFile2('');
    }


    uploadFile2(file) {

        console.log(file);

        let input = new FormData();
        input.append('banner_image', this.fileToUpload);
        input.forEach((value, key) => {
            console.log(key + " " + value)
        });

        console.log(JSON.stringify(input));
        console.log(this.fileToUpload);

        this.loader = 1;
        this.apiHit.FileUpload(input, "Banner/add_banner").subscribe((result => {

            console.log(result);
            // return;
            if (result['banner_add'] == 'success') {

                this.dialog.success('Success', 'Banner Uploaded Sucessfully');
                this.getBanner();
                // this.router.navigate(['distributor']);


            } else {

                this.dialog.error('error', 'error');

            }

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }

    //Banner Upload File Function Start

    uploadFile(file) {

        console.log(file);

        let formData = new FormData();
        formData.append('file', file, file.name);

        console.log(formData);

        this.loader = 1;
        this.apiHit.PostRequest(formData, "Banner/add_banner").subscribe((result => {

            console.log(result);

            if (result['distributors_add'] == 'success') {

                this.dialog.success('Success', 'Image Uploaded Sucessfully');
                // this.router.navigate(['distributor']);


            } else {

                this.dialog.error('error', 'error');

            }

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Banner Upload File Function END


    deleteBanner(id) {

        console.log(id);

        this.dialog.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ "id": id }, "Banner/del_status").subscribe((result => {

                    console.log(result);

                    if (result['del_status'] == 'success') {

                        this.getBanner();

                    }
                    else {

                        this.dialog.error('error', result['errors']);

                    }
                    setTimeout(() => {

                        this.loader = '';

                    }, 700);

                }));

            }

        });

    }




}