import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  active: any={};
  constructor(
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public router: Router,
    public location: Location,
    private bnIdle: BnNgIdleService,
  ) {

    
  }
  ngOnInit(): void 
{
  this.getLoginDetail();
  console.log("getLoginDetail")
  this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
    if (isTimedOut) {
      this.router.navigate(['']);
      localStorage.removeItem('abacusCrmUserData');
      this.apiHit.userLoginData=''
    }

  });
}
  title = 'loyaltyProject';

  getLoginDetail() {

    console.log("Api Hit",this.apiHit.userLoginData);
    this.active = this.apiHit.getLoginData();
    console.log("Active",this.active);

    if ((!this.location.path() || this.location.path() == '/') && this.apiHit.userLoginData && this.apiHit.userLoginData.data) {
      if (this.apiHit?.userLoginData?.data?.user_type == 'System_user') {
        this.router.navigate([this.apiHit?.userLoginData?.data?.navigation_url]);
      }
      else if (this.apiHit?.userLoginData?.data?.user_type == 'approval_user') {
        this.router.navigate(['distributor']);
      }
      else if (this.apiHit?.userLoginData?.data?.user_type == 'scanning_user') {
        this.router.navigate(['scan-qrCode']);
      }
      else if (this.apiHit?.userLoginData?.data?.login_type == 'distributor') {
        this.router.navigate(['distributor-detail/'+this.apiHit?.userLoginData?.data?.id]);
      }
      else {
        console.log('no permission for user');
      }

    }

    // console.log(localStorage);

  }
 
}