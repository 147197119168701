import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';
import { MatDialog } from '@angular/material/dialog';
// import { RetailerEditComponent } from '../retailer-edit/retailer-edit.component';
import { OrderEditModalComponent } from '../order-edit-modal/order-edit-modal.component';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';


@Component({
  selector: 'app-secondaryorder-detail',
  templateUrl: './secondaryorder-detail.component.html',
})
export class SecondaryorderDetailComponent implements OnInit {

  retailerInfo: any;
  distributorInfo: any;
  login_data: any;
  orderList: any;
  reatilerInfoData: any;
  mechanicData: any;
  mechanicTransection: any = 0;
  transectionDistributor: any;
  transferDetails: any;
  assignDistributor: any;
  loader: any;
  skeleton: any = [];
  skData: any = [];
  skDataTwo: any = [];
  scanData: any = [];
  searchData: any = {};
  searchMechanic: any = {};
  searchDistributorTransaction: any = {};
  searchMechanicTransaction: any = {};
  searchTransferDetails: any = {};
  searchScanData: any = {};
  date: any;
  data_val: any;
  orderDetail : any;

  constructor(

    public dialog: MatDialog,
    public activateRoute: ActivatedRoute,
    private apiHit: DatabaseService,
    private _location: Location,
    public dialog2: DialogService,

  ) {

    console.log(this.activateRoute['params']['_value']);
    this.orderDetails();
    this.skeleton = new Array(10);
    this.skData = new Array(4)
    this.skDataTwo = new Array(2);
    this.date = new Date();


  }

  ngOnInit(): void {

    console.log(this.apiHit.userLoginData);
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val);

  }


  // Back Button Function  Start

  backClicked() {

    this._location.back();

  }
  // Back Button Function  End




  //Reatailer Detail Function Start

  orderDetails() {

    this.loader = 1;

    this.apiHit.PostRequest({
      'uid': this.apiHit.userLoginData.data.id,
      'order_id': this.activateRoute['params']['_value'].id
    }, "app/AppOrderController/showOrdersDetails").subscribe((result => {

      console.log(result);

      if (result['order_info']['status'] == "success") {

        console.log('in if');
        this.retailerInfo = result['order_info']['dealer_info'][0];
        this.distributorInfo = result['order_info']['distributer_info'][0];
        this.orderList = result['order_info']['orderItems'];
        this.orderDetail = result['order_info']['orderDetails'];
        this.loader = '';
        console.log(this.distributorInfo);
        
      }
      else {
        this.dialog2.error('error', 'Something Went Wrong');
        // this.loader = '';
      }

    }));

  }
  //Reatailer Detail Function End



  //Delete Contact Function Start

  deleteOrder(id) {

    console.log(id);

    this.dialog2.delete('').then((result) => {

      if (result === true) {

        this.apiHit.PostRequest({ 'id': id }, "Distributor/delete_contact_person").subscribe((result => {

          console.log(result);

          if (result['delete_contact_person'] == 'success') {

            this.orderDetails();

          }

          else {

            this.dialog2.error('error', result['errors']);

          }

        }));

      }

    });

  }
  //Delete Contact Function End


  //Distributor To Retailer Date Created Search Function Start 
  public onDate(event): void {

    this.searchDistributorTransaction.dateCreated = moment(event.value).format('YYYY-MM-DD');
    this.orderDetails();

  }



  //Refresh Retailer List Function Start

  refreshData(target) {

    this.searchMechanic = {};
    this.orderDetails();


  }
  //Refresh Retailer List Function End


  //Edit Retailer Detail (Open Dialog Box) Function Start 

  OrderStatusChange(target, data): void {

    console.log(data);

    const dialogRef = this.dialog.open(OrderEditModalComponent, {

      width: '',
      panelClass: "modelwidth",
      data: {

        target,
        data,

      }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.orderDetails();

    });

  }
  //Edit Retailer Detail (Open Dialog Box) Function End 

}