import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
  selector: 'app-secondary-order',
  templateUrl: './secondary-order.component.html',
})
export class SecondaryOrderComponent implements OnInit {

  skeleton: any = [];
  date: any;
  loader: any;
  orderListData: any;
  searchData: any = {};
  orderSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  matTabValue: any;
  status: any;
  count: any;
  data_val: any;
  ExcelData: any;
  stateData: any = [];
  last_3_month_data:any={};


  constructor(

    public apiHit: DatabaseService,
    public dialog: DialogService,

  ) {

    this.status = 'All'
    this.loader = 1;
    this.matTabValue = 0;
    // this.orderList(1, this.status);
    this.skeleton = new Array(10);
    this.date = new Date();
    this.date = new Date();
    this.stateList();
    this.pageNumber = 1;
    this.total = 1;


  }

  ngOnInit(): void {
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val);
    this.paginationData = this.apiHit.getFilterData('secondary-order');
    // this.paginationData = JSON.parse(localStorage.getItem('filterData'));
    this.orderSearch = this.paginationData?.search ? this.paginationData.search : this.orderSearch;
    this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
    this.matTabValue = this.paginationData?.matTabValue ? this.paginationData?.matTabValue : this.matTabValue;
    console.log('parimary order filter search ',this.paginationData);
    this.orderList(this.paginationData?.target ? this.paginationData.target : 1, this.status);
  }



  refreshData() {

    this.orderSearch = {};
    this.orderList(1, 'All');

  }


  clearDateFilter() {
    this.orderSearch.date_from = '';
    this.orderSearch.date_to = '';
    this.orderList(1,this.status)
  }


  orderList(target, status) {
    
    this.loader = 1;
    console.log(status);
    if(target != 3){
      this.loader = 1;
      this.orderListData = [];
    }
    if (target == 1) {
      this.pageNumber = 1;
    }
    if (this.orderSearch.date_from && this.orderSearch.date_to) {
      this.orderSearch.date_from = moment(this.orderSearch.date_from).format('YYYY-MM-DD');
      this.orderSearch.date_to = moment(this.orderSearch.date_to).format('YYYY-MM-DD');
    }
    this.total = 1;
    this.paginationData.search = this.orderSearch;
    this.paginationData.pagelimit = 20;
    // this.paginationData.secondary_order = 0;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.order_status = this.matTabValue;
    this.paginationData.secondary_order = 1;
    this.paginationData.pending_order = 0;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;
    this.paginationData.matTabValue = this.matTabValue;
    if(this.data_val.login_type == 'distributor'){
      this.paginationData.dist_id = this.data_val.id
      this.paginationData.login_type = this.data_val.login_type
    }
    if(this.apiHit.userLoginData.data.user_type=='approval_user'){
      this.paginationData.user_id = this.apiHit.userLoginData.data.id;
    }
    else{
      this.paginationData.user_id = ''
    }
  
    console.log(this.paginationData);
    this.apiHit.setFilterData(this.paginationData);

    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/showOrders").subscribe((result => {

      console.log(result);
      if (result['show_Distributor_Details']['status'] == 'success') {

        this.orderListData = result['show_Distributor_Details']['info'];
        this.ExcelData = result['show_Distributor_Details']['total_rows_excel'];
        this.last_3_month_data = result['show_Distributor_Details']['data'];

        this.count = {
          'all': result['show_Distributor_Details']['total_row'],
          'pending': result['show_Distributor_Details']['total_pending'],
          'verified': result['show_Distributor_Details']['total_verified'],
          'reject': result['show_Distributor_Details']['total_rejected'],
          'total': result['show_Distributor_Details']['all'],
          'totalOrderQty' : result['show_Distributor_Details']['total_order_qty'],
        }
        this.total = result['show_Distributor_Details']['totalPage']
        this.loader = '';
      }
      else {
        this.dialog.error('error', 'Something Went Wrong');
        this.loader = '';
      }

    }));

  }


  
  //State List Fetch From Database Through API  Start

  stateList() {

    this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {

      this.stateData = result['state_list']['state_name'];

    }));

  }
  //State List Fetch From Database Through API  End

  exportExcelFunction() {

    this.loader = 1;
    this.paginationData.pagelimit = 0;
    this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_ORDERS_DATA").subscribe((result => {
      console.log(result);
      this.orderList(1, this.status);
      setTimeout(() => {
        this.loader = '';
        window.open(this.apiHit.downloadURL + "uploads/ORDER_DATA.csv")
      }, 700);

    }));
  }


  //Active Mat-Tab Function Start

  tabClick(tab) {

    console.log(tab);
    this.matTabValue = tab['index'];
    if(tab['index'] == 0){
      this.status = 'All';
    }
    else if(tab['index'] == 1){
      this.status = 'Pending';
    }
    else if(tab['index'] == 2){
      this.status = 'Verified';
    }
    else{
      this.status = 'Reject'
    }
    // this.orderSearch = {};
    this.pageNumber = 1;
    this.orderList(1, this.status);

  }
  //Active Mat-Tab Function End


  public onDate(event): void {

    this.orderSearch.created_date = moment(event.value).format('YYYY-MM-DD');
    this.orderList(1, this.status);
  }


  deleteUser(id) {

    this.dialog.delete('').then((result) => {

      if (result === true) {

        // this.apiHit.PostRequest({ "id": id }, "User/delete_user").subscribe((result => {

        //   console.log(result);

        //   if (result['orderListData'] == 'Success') {

        //           this.orderList(2,this.status);


        //   }
        //   else {

        //     this.dialog.error('error', result['errors']);

        //   }
        //   setTimeout(() => {

        //     this.loader = '';

        //   }, 700);

        // }));

      }

    });

  }


  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.orderList(2, this.status);
        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.orderList(2, this.status);
        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.orderList(2, this.status);
        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.orderList(2, this.status);


        }
        else if (this.pageNumber) {

          this.orderList(2, this.status);

        }

      }

    },
      100);
  }

}