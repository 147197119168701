import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
  selector: 'app-primary-order',
  templateUrl: './primary-order.component.html',
})
export class PrimaryOrderComponent implements OnInit {

  skeleton: any = [];
  date: any;
  currentDay: any;
  loader: any;
  orderListData: any;
  searchData: any = {};
  orderSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  matTabValue: any;
  status: any;
  count: any;
  stateData: any = [];
  data_val: any;
  Checked: boolean = false;
  // for pdf 
  distrubutorInfo: any = {};
  orderDetail: any = {};
  orderListPdf: any = []
  invoiceData: any = [];
  last_3_month_data:any={};
  name:any
  
  constructor(

    public apiHit: DatabaseService,
    public dialog: DialogService,

  ) {

    this.status = 'All'
    this.loader = 1;
    this.matTabValue = 0;
    this.skeleton = new Array(10);
    this.date = new Date();
    this.currentDay = String(this.date.getDate()).padStart(1, '0');
    this.stateList();
    this.pageNumber = 1;
    this.total = 1;

    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    const d = new Date();
   this.name = month[d.getMonth()];

  }

  ngOnInit(): void {
    console.log(this.apiHit.userLoginData);
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val);
    this.paginationData = this.apiHit.getFilterData('order-list');
    // this.paginationData = JSON.parse(localStorage.getItem('filterData'));
    this.orderSearch = this.paginationData?.search ? this.paginationData.search : this.orderSearch;
    this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
    this.matTabValue = this.paginationData?.matTabValue ? this.paginationData?.matTabValue : this.matTabValue;

    console.log('primary order filter search ', this.paginationData);

    this.orderList(this.paginationData?.target ? this.paginationData.target : 1, this.status);
  }


  orderList(target, status) {
    this.OrderData = []
    this.loader = 1;
    console.log(status);
    if (target != 3) {
      this.orderListData = [];
    }
    if (target == 1) {
      this.pageNumber = 1;
    }
    if (this.orderSearch.date_from && this.orderSearch.date_to) {
      this.orderSearch.date_from = moment(this.orderSearch.date_from).format('YYYY-MM-DD');
      this.orderSearch.date_to = moment(this.orderSearch.date_to).format('YYYY-MM-DD');
    }
    this.total = 1;
    this.paginationData.search = this.orderSearch;
    this.paginationData.pagelimit = 20;
    // this.paginationData.secondary_order = 0;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.order_status = this.matTabValue;
    this.paginationData.secondary_order = 0;
    this.paginationData.pending_order = 0;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;
    this.paginationData.matTabValue = this.matTabValue;
      
    if (this.apiHit.userLoginData.data.user_type == 'approval_user') {
      this.paginationData.user_id = this.apiHit.userLoginData.data.id;
    }
    else {
      this.paginationData.user_id = ''
    }
    if(this.data_val.login_type == 'distributor'){
      this.paginationData.dist_id = this.data_val.id
      this.paginationData.login_type = this.data_val.login_type
    }
    console.log(this.paginationData);
    this.apiHit.setFilterData(this.paginationData);
    if(this.data_val.user_type == 'System_user' && this.data_val.id == '1'){
      this.paginationData.portal_type = 'admin'; 
    }

    this.apiHit.PostRequest(this.paginationData, "app/AppOrderController/showOrders").subscribe((result => {

      console.log(result);
      if (result['show_Distributor_Details']['status'] == 'success') {

        this.orderListData = result['show_Distributor_Details']['info'];
        this.count = {
          'all': result['show_Distributor_Details']['total_row'],
          'pending': result['show_Distributor_Details']['total_pending'],
          'verified': result['show_Distributor_Details']['total_verified'],
          'reject': result['show_Distributor_Details']['total_rejected'],
          'total': result['show_Distributor_Details']['all'],
          'hold': result['show_Distributor_Details']['total_hold'],
          'totalOrderQty': result['show_Distributor_Details']['total_order_qty'],
          'totalDispatchQty': result['show_Distributor_Details']['total_dispatch_qty'],
          'totalPendingQty': result['show_Distributor_Details']['total_pending_qty'],
          'totalamt': result['show_Distributor_Details']['total_amt'],
        }
        console.log(this.count.totalamt)
        this.total = result['show_Distributor_Details']['totalPage'];
        this.last_3_month_data = result['show_Distributor_Details']['data'];
        this.loader = '';
      }
      else {
        this.dialog.error('error', 'Please Check Your Internet Connectivity');
        this.loader = '';
      }

    }));

  }


  OrderData:any = []
  selected_item(event:any,index,Id){
this.Checked = event.checked
 
 if(this.Checked == true){
  this.OrderData[index]={
    Id
  }

 }else{
  this.OrderData.splice(index,1)

 }
 this.paginationData.OrderData = this.OrderData
  }
  stateList() {

    this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {

      this.stateData = result['state_list']['state_name'];

    }));

  }
  //State List Fetch From Database Through API  End

  refreshData() {

    this.orderSearch = {};
    this.orderList(1, 'All');

  }

  clearDateFilter() {
    this.orderSearch.date_from = '';
    this.orderSearch.date_to = '';
    this.orderList(1, this.status)
  }


  exportExcelFunction() {
    if (this.orderSearch.date_from && this.orderSearch.date_to) {
      this.orderSearch.date_from = moment(this.orderSearch.date_from).format('YYYY-MM-DD');
      this.orderSearch.date_to = moment(this.orderSearch.date_to).format('YYYY-MM-DD');
    }
    this.loader = 1;
    this.paginationData.pagelimit = 0;
    this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_ORDERS_DATA").subscribe((result => {

      console.log(result);
      this.orderList(1, this.status);
      setTimeout(() => {
        this.loader = '';
        window.location.href = (this.apiHit.downloadURLNew + "uploads/ORDER_DATA.csv")
      }, 700);

    }));
  }
  exportOrderExcelFunction() {

    this.loader = 1;
    this.paginationData.pagelimit = 0;
    console.log('this is pagination data', this.paginationData)
    this.apiHit.PostRequest(this.paginationData, "Distributor/EXPORT_ORDERS_Selected_Order_id ").subscribe((result => {
      this.orderList(1, this.status);
      setTimeout(() => {
        this.loader = '';
        window.open(this.apiHit.downloadURL + "uploads/Seledcted_ORDER_DATA.csv")
      }, 700);

    }));
  }



  //Active Mat-Tab Function Start

  tabClick(tab) {

    console.log(tab);
    this.matTabValue = tab['index'];
    if (tab['index'] == 0) {
      this.status = 'All';
    }
    else if (tab['index'] == 1) {
      this.status = 'Pending';
    }
    else if (tab['index'] == 2) {
      this.status = 'Verified';
    }
    else if(tab['index'] == 3){
      this.status = 'Reject'
    }
    else if(tab['index']==4){
      this.status = 'Hold'
    }
    else{
      this.status = 'Pending'
    }
    this.orderSearch = {};
    this.pageNumber = 1;
    this.orderList(1, this.status);

  }
  //Active Mat-Tab Function End


  public onDate(event): void {

    console.log(event.value);
    this.orderSearch.created_date = moment(event.value).format('YYYY-MM-DD');
    this.orderList(1, this.status);
  }


  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.orderList(2, this.status);
        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.orderList(2, this.status);
        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.orderList(2, this.status);
        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.orderList(2, this.status);


        }
        else if (this.pageNumber) {

          this.orderList(2, this.status);

        }

      }

    },
      100);
  }

  generatePdf(id) {

    console.log(id);

    this.apiHit.PostRequest({
      'uid': this.apiHit.userLoginData.data.id,
      'order_id': id
    }, "app/AppOrderController/showOrdersDetails"
    ).subscribe((result => {

      console.log(result);

      if (result['order_info']['status'] == "success") {
        console.log('in if');
        this.orderListPdf = result['order_info']['orderItems'];
        this.distrubutorInfo = result['order_info']['distributer_info'][0];
        this.orderDetail = result['order_info']['orderDetails'];
        this.invoiceData = result['order_info']['invoice_array'];

        this.apiHit.PostRequest({
          'order_id': id
        }, "app/AppOrderController/order_flag_up"
        ).subscribe((result => {
          console.log(result);

          this.orderList(2, this.status);
          var innerContents = document.getElementById("content").innerHTML;
          var popupWinindow = window.open();
          popupWinindow.document.open();
          popupWinindow.document.write('<html><head><link rel="stylesheet" type="text/css" href="./assets/css/print.css"></head><body onload="window.print()">' + innerContents + '</body></html>');
          popupWinindow.document.close();
          return
        }));


      }


    }));

  }

}
