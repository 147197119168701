import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  // MyUrl: string = "http://phpstack-83335-1344169.cloudwaysapps.com/api/";

  // Production Url Start
  DbUrl: string = "https://ask.abacusdesk.com/api/index.php/";
  apiURL: string = "https://ask.abacusdesk.com/askApi/index.php/";
  apiURL1: string = "https://ask.abacusdesk.com/askApi/index.php/app/";
  uploadURL: string = "https://ask.abacusdesk.com/askApi/";
  newUploadUrl: string = "https://ask.abacusdesk.com/askApi/askSfa/uploads/";
  downloadURL: string = "https://ask.abacusdesk.com/";
  downloadURLNew : string = "https://ask.abacusdesk.com/";
  downloadExcelURL: string = "https://ask.abacusdesk.com/askApi/uploads/";
  downloadExcelURL1: string = "https://ask.abacusdesk.com/askApi/askApi/uploads/";
  // Production Url End 
 
  // devlopemnt Url Start 

  // DbUrl: string = "https://devcrm.abacusdesk.com/askfrasle/askApi/index.php/";
  // apiURL: string = "https://devcrm.abacusdesk.com/askfrasle/askApi/index.php/";
  // apiURL1: string = "https://devcrm.abacusdesk.com/askfrasle/askApi/index.php/app/";
  // uploadURL: string = "https://devcrm.abacusdesk.com/askfrasle/askApi/";
  // newUploadUrl: string = "https://devcrm.abacusdesk.com/askfrasle/askSfa/uploads/";
  // downloadURL: string = "https://devcrm.abacusdesk.com/askfrasle/"; 
  // downloadURLNew: string = "https://devcrm.abacusdesk.com/askfrasle/askApi/"; 
  // downloadExcelURL: string = "https://devcrm.abacusdesk.com/askfrasle/uploads/";
  // downloadExcelURL1: string = "https://devcrm.abacusdesk.com/askfrasle/askApi/uploads/";

  // devlopemnt Url End

  countLeftNavigation = {};

  datauser: any = [];
  constructor(
    public http: HttpClient,
    private router: Router,
  ) {
    console.log(this.router.url)
  }
  userData: any = {}
  userLoginData: any = {};
  LocalFilterData: any = {};
  localPageURL: any = {};

  // datauser:any;


  //set and get user data start
  setLoginData(result) {

    sessionStorage.setItem('abacusCrmUserData', JSON.stringify(result));
    localStorage.setItem('abacusCrmUserData', JSON.stringify(result));

    this.userLoginData = result;
    this.userData = result.data;
    
  }
  can_active:any="";


  getLoginData() {
    console.log(localStorage.getItem('abacusCrmUserData'));
    // if (sessionStorage.getItem('abacusCrmUserData')) {
      if (localStorage.getItem('abacusCrmUserData')) {

      // console.log(localStorage.getItem('abacusCrmUserData'));

      // this.userLoginData = JSON.parse(sessionStorage.getItem('abacusCrmUserData'));
      this.userLoginData = JSON.parse(localStorage.getItem('abacusCrmUserData'));
      // return this.userLoginData;
    }
    else {
      this.userLoginData = {};
      // return this.userLoginData;
    }
    return this.userLoginData;
  }
  //set and get user data End


  //set and get filter data start
  setFilterData(result) {
    localStorage.setItem('filterData', JSON.stringify(result));
    this.LocalFilterData = result;
    console.log('set filter data function ', this.LocalFilterData);
  }
  getFilterData(pageURL) {

    if (this.localPageURL == pageURL) {
      console.log('in if get filter same url');

      if (localStorage.getItem('filterData')) {
        this.LocalFilterData = JSON.parse(localStorage.getItem('filterData'));
      }
    }
    else {
      console.log('in else get filter same url');

      this.LocalFilterData = {};
    }
    this.localPageURL = pageURL;
    // console.log('get filter data', this.LocalFilterData);
    return this.LocalFilterData;
  }
  //set and get filter data End


  header: any = new HttpHeaders();
  FetchData(request_data: any, fn: any) {
    let header = new HttpHeaders();
    // header = header.append('Authorization','Bearer ' + this.token);
    header = header.append("Content-Type", "application/json");
    console.log(request_data);
    return this.http.post(this.DbUrl + fn, JSON.stringify(request_data), { headers: header });
  }


  FileData(request_data: any, fn: any) {
    this.header.append("Accept", 'application/json');
    this.getLeftNavigationCount();
    this.checkUserCredentials();
    return this.http.post(this.DbUrl + fn, request_data, { headers: this.header });
  }


  PostRequest(request_data: any, fn: any) {

    var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'GET,POST,PUT,PATCH,DELETE,OPTIONS');
    console.log(headers);
    
    console.log(this.router.url)
    if(this.router.url != '/scan-qrCode' && this.router.url != '/coupon' && this.router.url != '/retailer' && this.router.url != '/distributor' && this.router.url != '/add-coupon' && this.router.url != '/billing-list'){
      // this.getLeftNavigationCount();
      // this.checkUserCredentials();
    }
    return this.http.post(this.apiURL + fn, (request_data), { headers: headers });

  }

  PostRequestforretailer(request_data: any, fn: any) {

    var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'GET,POST,PUT,PATCH,DELETE,OPTIONS');
    console.log(this.router.url)
    console.log(headers);
    
    return this.http.post(this.apiURL + fn, (request_data), { headers: headers });

  }
  PostRequest1(request_data: any, fn: any) {

    var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'GET,POST,PUT,PATCH,DELETE,OPTIONS');
    // this.getLeftNavigationCount();
    // this.checkUserCredentials();
    return this.http.post(this.apiURL1 + fn, (request_data), { headers: headers });

  }



  FileUpload(request_data: any, fn: any) {

    var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    // this.getLeftNavigationCount();
    // this.checkUserCredentials();
    return this.http.post(this.apiURL + fn, (request_data), { headers: headers });

  }


  GetRequest(request_data: any, fn: any): any {

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('Token', 'Bearer ' + this.datauser.token);
    // this.getLeftNavigationCount();
    // this.checkUserCredentials();
    return this.http.get(this.apiURL + fn, { headers: headers });

  }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  
  public exportTXTFile(json: any, fileName: string): void {
    var blob = new Blob([json], {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(blob,fileName+ "txt");
  }



  getLeftNavigationCount() {

    var headers = new HttpHeaders();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Methods', 'GET,POST,PUT,PATCH,DELETE,OPTIONS');
    this.http.post(this.apiURL1 + 'AppOrderController/getPendingCount', (''), { headers: headers }).subscribe((result) => {
      // console.log(result);
      if (result['getPendingCount']['status'] == 'success') {
        this.countLeftNavigation = result['getPendingCount']['result'];
      }
    })

  }


  checkUserCredentials() {

    if (this.userLoginData?.data?.username || this.userLoginData?.data?.password) {

      var headers = new HttpHeaders();
      headers.append("Accept", 'application/json');
      headers.append('Content-Type', 'application/json');
      headers.append('Access-Control-Allow-Origin', '*');
      headers.append('Access-Control-Allow-Methods', 'GET,POST,PUT,PATCH,DELETE,OPTIONS');
      this.http.post(this.apiURL + 'Login/submitnew',
        ({ 'userName': this.userLoginData?.data?.username, 'password': this.userLoginData?.data?.password }), { headers: headers }).subscribe((result) => {
          // console.log(result);
          if (result['message'] == 'Success') {
            // console.log('in if login');
            this.setLoginData(result);
          }
          else {
            console.log('in else login');
            localStorage.removeItem('abacusCrmUserData');
            localStorage.clear();
            this.userLoginData = {};
            this.router.navigate(['']);
          }
        })
    }

  }

}