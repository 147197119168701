import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-primary-order-add',
  templateUrl: './primary-order-add.component.html',
})
export class PrimaryOrderAddComponent implements OnInit {

  orderAdd: FormGroup;
  placeOrder: FormGroup;
  loader: any;

  status:any;
  Remark:any;

  date: any;
  currentDay: any;
  totalOrderQTY: any = 0;

  segmentdata: any = [];
  distributorData: any = [];
  partNo: any = [];
  categoryName: any = [];
  finalOrderAddArray: any = [];
  buttonLoading : any = false;
  data_val:any;

  constructor(

    private fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public param: ActivatedRoute,
    public router: Router,


  ) {
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val)
    console.log(this.apiHit.userLoginData.order_date);
    this.date = new Date();
    this.currentDay = String(this.date.getDate()).padStart(1, '0');
  }

  ngOnInit(): void {

    this.orderAdd = this.fb.group({
      dr_id: [this.data_val?.login_type == 'distributor'?this.data_val.id : '', [Validators.required]],
      segment: ['', [Validators.required]],
      category: ['', [Validators.required]],
      part_code: ['', [Validators.required]],
      qty: ['', [Validators.required]],
      shipping_address: ['', [Validators.required]],
      order_status: [1, [Validators.required]],
      searchCtrl: [''],
      

    })

    this.placeOrder = this.fb.group({
      order_remarks: ['', [Validators.required]],
      order_status: [1],
      orderArray: [],
      total_qty: [0],
      item_count: [0],
      distributor_id: [0],
    })
    this.distributorList();
    // this.getSegment();

  }

  getDistributorAddress(drData) {

    console.log(drData);
    this.status = drData.order_status;
    this.Remark = drData.order_status_remark;
    let address = ''
    // address = drData.street? (drData.street +', '):'' + drData.city_name? (drData.city_name +', '):'' + 
    // drData.district_name? (drData.district_name + ', '):'' +
    // drData.state_name? (drData.state_name + ', '):'' + drData.pincode;
    address = drData.street +', '+ drData.city_name + 
    drData.district_name +', '+
    drData.state_name +', '+ drData.pincode;
    console.log(address);


      this.orderAdd.controls.shipping_address.setValue(address);
    
   
    console.log(drData);
    console.log(this.status);
  }
  
  
  placeOrderFunction() {

    if(this.currentDay > parseInt(this.apiHit.userLoginData.order_date)){
      this.dialog.error('error', "Order can't be placed after 25th of month");
      return
    }
    


    this.dialog.save_distributor('').then((result) => {
      
      if (result === true) {
        
        this.buttonLoading = true;
        console.log(this.placeOrder);
        let data = this.placeOrder.value;
        if(this.data_val?.login_type == 'distributor'){
          data.dr_id = this.data_val.id
          data.login_type = this.data_val.login_type
        }else{

          data.dr_id = this.orderAdd.value.dr_id;
        }
        data.shipping_address = this.orderAdd.value.shipping_address;
        data.orderArray = this.finalOrderAddArray;
        data.item_count = this.finalOrderAddArray.length;
        data.is_admin = 1;
        data.user_id = this.apiHit.userLoginData.data.id;
        data.total_qty = this.totalOrderQTY;
        if(this.data_val.user_type == 'approval_user'){
          data.user_type = this.data_val.user_type;
        }

        // for (var i = 0; i < this.finalOrderAddArray.length; i++) {

        //   data.total_qty = parseInt(this.finalOrderAddArray[i]['qty']) + data.total_qty;


        // }

        console.log(data);
        
        this.apiHit.PostRequest(data, "app/AppOrderController/addOrder").subscribe((result => {
          
          console.log(result);
          if (result['show_Distributor_Details'].status == 'success') {

            this.dialog.success('Success', 'Order Successfully Added');
            this.router.navigate(['order-list']);
            this.buttonLoading = false;

          }
          else {
            this.dialog.error('error', 'Please Check Your Internet Connection');
            this.buttonLoading = false;
          }
          
        }));

      }
    });

  }

  checkTotalQty() {

    this.totalOrderQTY = 0;
    for (var i = 0; i < this.finalOrderAddArray.length; i++) {

      this.totalOrderQTY = parseInt(this.finalOrderAddArray[i]['qty']) + this.totalOrderQTY;

    }

  }




  addToList(form: NgForm) {
 if(this.data_val.login_type == 'distributor'){
  if(this.data_val.order_status == "Inactive"){
    this.dialog.error('error', this.data_val.order_status_remark);
  }else{

  if(this.currentDay > parseInt(this.apiHit.userLoginData.order_date)){
    this.dialog.error('error', "Order can't be placed after line 180 "+this.apiHit.userLoginData.order_date+' 28th of month');
    return
  }
  console.log(this.orderAdd);
  let orderList = this.orderAdd.value
  let index = this.finalOrderAddArray.findIndex(row => row.part_code == orderList.part_code);
  console.log(form);
  if (index === -1) {
    this.finalOrderAddArray.push({ 'part_code': orderList.part_code, 'category': orderList.category, 'segment': orderList.segment, 'qty': orderList.qty })
    // form.resetForm();
    this.orderAdd.controls['part_code'].reset();
    this.orderAdd.controls['qty'].reset();
  }
  else {
    this.dialog.error('oops', 'Part Already In List');
    this.orderAdd.controls['part_code'].reset();
    this.orderAdd.controls['qty'].reset();
    // this.orderAdd.controls['qty'].reset();
  }
  this.checkTotalQty();

  // console.log(this.finalOrderAddArray);
}
 }else{
    if(this.status == "Inactive"){
      this.dialog.error('error', this.Remark);
    }else{

    if(this.currentDay > parseInt(this.apiHit.userLoginData.order_date)){
      this.dialog.error('error', "Order can't be placed after 180"+this.apiHit.userLoginData.order_date+" of month");
      return
    }
    console.log(this.orderAdd);
    let orderList = this.orderAdd.value
    let index = this.finalOrderAddArray.findIndex(row => row.part_code == orderList.part_code);
    console.log(form);
    if (index === -1) {
      this.finalOrderAddArray.push({ 'part_code': orderList.part_code, 'category': orderList.category, 'segment': orderList.segment, 'qty': orderList.qty })
      // form.resetForm();
      this.orderAdd.controls['part_code'].reset();
      this.orderAdd.controls['qty'].reset();
    }
    else {
      this.dialog.error('oops', 'Part Already In List');
      this.orderAdd.controls['part_code'].reset();
      this.orderAdd.controls['qty'].reset();
      // this.orderAdd.controls['qty'].reset();
    }
    this.checkTotalQty();

    // console.log(this.finalOrderAddArray);
  }
  }}


  //Get DIstributor List Through API Function Start
  distributorList() {

    this.loader = 1;

    this.distributorData = [];

    this.apiHit.PostRequest({ 'user_id': this.apiHit.userLoginData.data.id, "user_type": 1 }, "User/showApprovalDistributer").subscribe((result => {

      console.log(result);
      this.distributorData = result['showApprovalDistributer'];
      this.loader = '';
      if(this.data_val.login_type == 'distributor'){
      let shippingAddress =  this.data_val.street +' , '
                            + this.data_val.city_name + ' , ' 
                            + this.data_val.district_name + ' ,' 
                            +this.data_val.state_name+ ' , ' 
                            + this.data_val.pincode;
      console.log(shippingAddress);

       this.orderAdd.controls.shipping_address.setValue(shippingAddress);
       this.getSegment()
      }
    }));

  }
  //Get DIstributor List Through API Function End


  getSegment() {

    this.loader = 1;
    console.log(this.orderAdd.value);
    
    if(this.orderAdd.value.dr_id == '' && this.data_val?.login_type!= 'distributor'){
      this.orderAdd.controls.category.setValue('');
      this.orderAdd.controls.segment.setValue('');
      this.loader = '';
      this.dialog.error('error', "Please Select Distributor First");
      return;
    }
    this.apiHit.PostRequest('', "app/AppOrderController/getSegment").subscribe((result => {

      console.log(result['result']);
      if (result['result']['status'] == 'success') {
        this.segmentdata = result['result']['segmentData'];
        this.loader = '';
      }

    }));
  }


  //Part Code Api Start
  getCategory(brand) {

    this.loader = 1;
    this.partNo = [];
    this.categoryName = [];
    // console.log(this.couponForm.value);
    this.orderAdd.controls.category.setValue('');
    this.orderAdd.controls.part_code.setValue('');
    // this.orderAdd.controls.count_amount.setValue('');

    this.apiHit.PostRequest({ 'brand': brand, 'dist_id' : this.orderAdd.value.dr_id }, "CouponCode/partCategory").subscribe((result => {

      console.log(result);
      this.categoryName = result['partCategory'];
      this.loader = '';

    }));

  }
  //Part Code Api End


  //Part Code Api Start
  getPartCodeNo(category) {

    this.loader = 1;
    this.partNo = [];
    this.orderAdd.controls.part_code.setValue('');
    // this.orderAdd.controls.count_amount.setValue('');
    console.log(this.orderAdd.controls.segment.value);

    this.apiHit.PostRequest({ 'category': category, segment: this.orderAdd.controls.segment.value }, "CouponCode/partNoList").subscribe((result => {

      console.log(result);

      this.partNo = result['part_No_List'];
      this.loader = '';

    }));

  }
  //Part Code Api End


  deleteOrderItem(index) {

    this.dialog.delete('').then((result) => {

      if (result === true) {
        this.finalOrderAddArray.splice(index, 1)
        this.checkTotalQty();
      }
    });

  }


  // KeyPress Function  Start

  OnlyNumber(event: any) {

    const pattern = /[0-9\'']/;
    let inputChar = String.fromCharCode(event.charCode);

    if (event.keyCode != 8 && !pattern.test(inputChar)) {

      event.preventDefault();

    }
  }
  // KeyPress Function  End

}
