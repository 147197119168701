<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name" *ngIf="!loader">
                    <h2>AFF Brake To Distributor</h2>
                    <p>{{totalTransaction}} AFF Brake To Distributor Transaction Available</p>
                </div>
                <div class="left-auto">
                    <ul class="date-filter">
                        <li>
                            <div class="cs-form pb0">
                                <p>Date From</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                    <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                        placeholder="Search ..." name="date_from" [(ngModel)]="distributorTransactionSearch.date_from"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li>
                            <div class="cs-form pb0">
                                <p>Date To</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                    <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                        placeholder="Search ..." name="date_to" [(ngModel)]="distributorTransactionSearch.date_to"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li class="cs-btn mt15 mr10" *ngIf="this.distributorTransactionSearch.date_from && this.distributorTransactionSearch.date_to">
                            <a mat-button (click)="distributorTransactionList(1)">Go</a>
                        </li>
                        <li class="cs-btn mt15" *ngIf="this.distributorTransactionSearch.date_from || this.distributorTransactionSearch.date_to">
                            <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                        </li>
                    </ul>
                </div>
            </ng-container>
            <div class="cs-action">
                <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"><i
                        class="material-icons"
                        (click)="distributorTransactionList(1);">search</i></a>
                <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"><i
                        class="material-icons"
                        (click)="refreshData()">settings_backup_restore</i></a>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>

        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date</div>
                            <div class="div_cell mmw100">Created By</div>
                            <div class="div_cell mw130">Distributor</div>
                            <div class="div_cell mmw100">Mobile</div>
                            <div class="div_cell mmw100">Point Type</div>
                            <div class="div_cell mmw280">Payment Mode</div>
                            <div class="div_cell mmw120 text-right">Amount</div>
                            <div class="div_cell text-center mmw110 af-bdr-right">Action</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                            placeholder="Search ..." name="dateCreated"
                                            [(ngModel)]="distributorTransactionSearch.dateCreated"
                                            (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_name"
                                        [(ngModel)]="distributorTransactionSearch.created_name"
                                        (keyup.enter)="distributorTransactionList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="distributorName"
                                        [(ngModel)]="distributorTransactionSearch.distributorName"
                                        (keyup.enter)="distributorTransactionList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mobile_no"
                                        [(ngModel)]="distributorTransactionSearch.mobile_no"
                                        (keyup.enter)="distributorTransactionList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100"></div>
                            <div class="div_cell mmw280"></div>
                            <div class="div_cell mmw120" *ngIf="loader">&nbsp;</div>
                            <div class="div_cell mmw120 text-right" *ngIf="!loader">{{distributorTransaction.total_amount_paid ? distributorTransaction.total_amount_paid : 0}}</div>
                            <div class="div_cell text-center mmw110 af-bdr-right">
                                <div class="cs-action">
                                    <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"><i
                                            class="material-icons"
                                            (click)="distributorTransactionList(1);">search</i></a>
                                    <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"><i
                                            class="material-icons"
                                            (click)="refreshData()">settings_backup_restore</i></a>
                                </div>
                            </div>
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->


                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <!-- Loop Data -->
                        <main *ngFor="let data of distributorTransaction.info; let i = index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">{{(data.date_created) | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw100">{{data.created_by_name ? data.created_by_name:'N/A'}}</div>
                            <div class="div_cell mw130">{{data.distributor_name ? (data.distributor_name | titlecase):'N/A'}}</div>
                            <div class="div_cell mmw100">{{data.mobile_no ? data.mobile_no:'N/A'}}</div>
                            <div class="div_cell mmw100">{{data.payment_type == 'Scheme' ? 'Scheme' : 'Coupon'}} Points</div>
                            <div class="div_cell mmw280">
                                <p>{{data.payment_mode ? data.payment_mode:'N/A'}}
                                    <span *ngIf="data.payment_mode == 'Cheque'">- {{data.cheque_no ? data.cheque_no : 'N/A'}} </span>
                                    <span *ngIf="data.payment_mode == 'Credit Note'">- {{data.transaction_no ? data.transaction_no : 'N/A'}} </span>
                                </p>
                            </div>
                            <div class="div_cell mmw120 afe"> {{data.payment_amount ? data.payment_amount:'0'}}</div>
                            <div class="div_cell ac mmw110">
                                <div class="cs-action">
                                    <span *ngIf="data.pdf_file != ''">
                                        <a  class="ac-green mr5" href="{{apiHit.uploadURL}}/uploads/credit_note/{{data.pdf_file}}" title="View Document" target="_blank" matRipple  matTooltip="View" matTooltipPosition="below">
                                            <i class="material-icons">remove_red_eye</i>
                                        </a>
                                    </span>
                                    <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                        <a class="ac-red"
                                            *ngIf=" datas.module_name== 'Transaction Report' && datas.delete==1"
                                            matRipple matTooltip="Delete" matTooltipPosition="below"
                                            (click)="deleteDistributorData(data.id)"><i
                                                class="material-icons">delete_sweep</i></a>
                                    </span>
                                </div>
                            </div>
                        </main>
                        <!-- Loop Data -->
                        <app-not-found *ngIf="distributorTransaction.length == 0"></app-not-found>
                    </ng-container>



                    <ng-container *ngIf="loader">
                        <!-- Skelton Loop -->
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw50 ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw280">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110  ac">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                        <!-- Skelton Loop -->
                    </ng-container>

                </section>
            </div>

            <!-- Pagination Start -->
            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent"  *ngIf="distributorTransaction.info.length > 0"  (click)="exportExcel()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>
                </div>
            </div>
            <!-- Pagination End -->
        </div>
    </div>
</div>