import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DatabaseService } from '../services/service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  abq_user: any = [];

  constructor(private db: DatabaseService, public router: Router) {
    console.log(this.db.userLoginData);

  }
  canActivate(

    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data.expectedRole || [];



    this.abq_user = this.db.userLoginData;
    console.log(this.abq_user);
    console.log(state.url);
    console.log(expectedRole.length);
    console.log(expectedRole.indexOf(this.abq_user.data.access_level));
    if (this.abq_user.token && (this.abq_user.data.id)) {
      this.db.can_active = "1";
      sessionStorage.setItem('abacusCrmUserData', JSON.stringify(this.abq_user));
      localStorage.setItem('abacusCrmUserData', JSON.stringify(this.abq_user));
      return true;
    }
    else {
      this.db.can_active = '';

      this.router.navigate([''], { queryParams: { returnUrl: state.url } });
      return false;
    }



  }

}
