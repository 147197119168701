<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container >
                <div class="module-name">
                    <h2>Expense</h2>
                </div>
            </ng-container>
            <div class="left-auto left-auto df ac flex-gap-5">
                <button mat-icon-button matTooltip="Refresh" (click)="refresh() ">
                    <i class="material-icons">refresh</i>
                </button>
                <button mat-icon-button matTooltip="Sorting" (click)="sortData()">
                    <i class="material-icons">swap_vert</i>
                </button>
                
                <!-- <div class="pagination" *ngIf="expense_list.length > 0">
                    <div class="pagination-content">
                        Pages
                        <span>{{pagenumber}}</span>
                        of
                        <span>{{total_page}}</span>
                    </div>
                    <div class="page-nav">
                        <button mat-icon-button matTooltip="Older" (click)="pervious()" [disabled]="start == 0">
                            <i class="material-icons">navigate_before</i>
                        </button>
                        <button mat-icon-button matTooltip="Newer" (click)="nextPage()" [disabled]="pagenumber == total_page ">
                            <i class="material-icons">navigate_next</i>
                        </button>
                    </div>
                </div> -->
                
                
                <div class="main-mat-tabbar">
                    <button mat-button [ngClass]="active_tab == 'Pending' ? 'active' : ''" (click)="active_tab = 'Pending';expenseList();"><i class="material-icons">pending_actions</i>Pending ({{tabCount.Pending}})</button>
                    <button mat-button [ngClass]="active_tab == 'Approved' ? 'active' : ''" (click)="active_tab = 'Approved';expenseList();"><i class="material-icons">task_alt</i>Approved ({{tabCount.Approved}})</button>
                    <button mat-button [ngClass]="active_tab == 'Reject' ? 'active' : ''" (click)="active_tab = 'Reject';expenseList();"><i class="material-icons">unpublished</i>Reject ({{tabCount.Reject}})</button>
                </div>
            </div>
        </section>
        <!-- </div> -->
        
        
        <div class="Tablecontainer"> 
            <div class="cs-table horizontal-scroll">
                <div class="sticky-head">
                    <div class="table-head">
                        <table>
                            <tr>
                                <th class="w55">S No.</th>
                                <th class="w110">
                                    Date Created
                                    <div class="sorting" *ngIf="assign_login_data2.export_expense=='1'">
                                        <a (click)="openBottomSheet()" matTooltip="Export Excel">
                                            <i class="material-icons">filter_alt</i>
                                        </a>
                                    </div>
                                </th>
                                <!-- <th class="w110">
                                    Claim Date
                                    <div class="sorting" *ngIf="assign_login_data2.export_expense=='1'"></div>
                                </th> -->
                                <!-- <th class="w100">Employee Code</th> -->
                                <th class="w150">Employee Name </th>
                                <th class="w100">Expense ID</th>
                                <th class="w130">Designation</th>
                                <th class="w130">Expense Type</th>
                                <th class="w100 text-right">Amount</th>
                                <th *ngIf="active_tab == 'Approved'" class="w100 text-right">Approved Amount</th>
                                <th class="w100">Senior(RM) Status</th>
                                <th class="w100">HR Status</th>
                                <th class="w130">CEO & Director Status</th>
                                <th class="w100">A/C Status</th>

                            </tr>
                        </table>
                    </div>
                    
                    <div class="table-head border-top">
                        <table>
                            <tr>
                                <th class="w55">&nbsp;</th>
                                <th class="w110 pt0 pb0">
                                    <div class="th-search-acmt">
                                        <mat-form-field class="example-full-width cs-input">
                                            <input matInput [matDatepicker]="picker" placeholder="Date" name="dateCreated"
                                            [(ngModel)]="search.dateCreated" (dateChange)="expenseList()" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker [(ngModel)]="search.dateCreated" ngDefaultControl></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </th>
                                <!-- <th class="w110 pt0 pb0">
                                    <div class="th-search-acmt">
                                        <mat-form-field class="example-full-width cs-input">
                                            <input matInput [matDatepicker]="picker1" placeholder="Date" name="claimDate"
                                            [(ngModel)]="search.claimDate" (dateChange)="expenseList()" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1 [(ngModel)]="search.claimDate" ngDefaultControl></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </th> -->
                                
                                <!-- <th class="w100">
                                    <div class="th-search-acmt">
                                        <mat-form-field class="example-full-width cs-input">
                                            <input matInput placeholder="Search" name="employeeCode" [(ngModel)]="search.employeeCode"
                                            (keyup.enter)="expenseList()">
                                        </mat-form-field>
                                    </div>
                                </th> -->
                                <th class="w150">
                                    <div class="th-search-acmt">
                                        <mat-form-field class="example-full-width cs-input">
                                            <input matInput placeholder="Search" name="userName" [(ngModel)]="search.userName"
                                            (keyup.enter)="expenseList()">
                                        </mat-form-field>
                                    </div>
                                </th>
                                <th class="w100">
                                    <div class="th-search-acmt">
                                        <mat-form-field class="example-full-width cs-input">
                                            <input type="number" min="1" matInput placeholder="Search" name="expenseId" [(ngModel)]="search.expenseId"
                                            (keyup.enter)="expenseList()">
                                        </mat-form-field>
                                    </div>
                                </th>
                                <th class="w130"></th>
                                <th class="w130">
                                    <div class="th-search-acmt">
                                        <mat-form-field class="cs-input select-input">
                                            <mat-select  name="expenseType" [(ngModel)]="search.expenseType" (selectionChange)="expenseList()">
                                                <mat-option value="Local Conveyance">Local Conveyance</mat-option>
                                                <mat-option value="Outstation Travel">Outstation Travel</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </th>
                                <th class="w100 text-right"></th>
                                <th *ngIf="active_tab == 'Approved'" class="w100 text-right"></th>
                                <th class="w100"><div>&nbsp;</div></th>
                                <th class="w100"><div>&nbsp;</div></th>
                                <th class="w130"><div>&nbsp;</div></th>
                                <th class="w100"><div>&nbsp;</div></th>
                            </tr>
                        </table>
                    </div>
                </div>
                
                <div class="table-container">
                    <div class="table-content">
                        <table>
                            <ng-container *ngIf="!loader">
                                <tr *ngFor="let list of expense_list;let i=index" class='Current'>
                                    <td class="w55">{{ i + 1 }}</td>
                                    <td class="w110">{{list.dateCreated |date : 'd MMM y'}}</td>
                                    <!-- <td class="w110">{{list.claimDate |date : 'd MMM y'}}</td> -->
                                    <!-- <td class="w100">{{list.emp_code}}</td> -->
                                    <td class="w150">{{list.userName}}</td>
                                    <td class="w100">
                                        <a class="link-btn" mat-button (click)="serve.setData(search)" routerLink="../expense-detail/{{list.id}}" routerLinkActive="active">#CLM-{{list.id}}</a>
                                    </td>
                                    <td class="w130">{{list.userType}}</td>
                                    <td class="w130">{{list.expenseType}}</td>
                                    <td class="w100 text-right">&#x20B9; {{list.totalAmt}}</td>
                                    <td *ngIf="active_tab == 'Approved'" class="w100 text-right">&#x20B9; {{list.totalApprovedAmount ? list.totalApprovedAmount : '0'}}</td>
                                    <td class="w100">
                                        <strong class="yellow-clr" *ngIf="list.seniorStatus=='Pending'">{{list.seniorStatus}}</strong>
                                       <strong class="green-clr" *ngIf="list.seniorStatus=='Approved'">{{list.seniorStatus}}</strong>
                                        <strong class="red-clr" *ngIf="list.seniorStatus=='Reject'">{{list.seniorStatus}}</strong>
                                        <!--  <div class="action-button text-right" *ngIf="list.seniorStatus=='Pending'">
                                            <a mat-icon-button matTooltip="Change Status" (click)="expModal('seniorStatus',list.id, '')">
                                                <i class="material-icons edit">edit</i>
                                            </a>
                                        </div> -->
                                    </td>
                                    <td class="w100">
                                        <strong class="yellow-clr"  *ngIf="list.hrStatus=='Pending'">{{list.hrStatus}}</strong>
                                        <strong class="yellow-clr"  *ngIf="list.hrStatus==''">{{list.hrStatus}}</strong>
                                        <strong class="green-clr"  *ngIf="list.hrStatus=='Approved'">{{list.hrStatus}}</strong>
                                        <strong class="red-clr" *ngIf="list.hrStatus=='Reject'">{{list.hrStatus}}</strong>
                                    </td>
                                    <td class="w130">
                                        <strong class="yellow-clr"  *ngIf="list.vpStatus=='Pending' ">{{list.vpStatus}}</strong>
                                        <strong class="yellow-clr"  *ngIf="list.vpStatus==''" >{{list.vpStatus}}</strong>
                                        <strong class="green-clr"  *ngIf="list.vpStatus=='Approved'">{{list.vpStatus}}</strong>
                                        <strong class="red-clr" *ngIf="list.vpStatus=='Reject'">{{list.vpStatus}}</strong>
                                    </td>
                                    
                                    <td class="w100">
                                        <strong class="yellow-clr" *ngIf="list.acStatus=='Pending'">{{list.acStatus}}</strong>
                                        <strong class="green-clr" *ngIf="list.acStatus=='Approved'">{{list.acStatus}}</strong>
                                        <strong class="red-clr" *ngIf="list.acStatus=='Reject'">{{list.acStatus}}</strong>
                                        <!-- <div class="action-button text-right" *ngIf="assign_login_data2.id=='1'">
                                            <a mat-icon-button matTooltip="Change Status" (click)="expModal('acStatus',list.id, list.totalAmt)">
                                                <i class="material-icons edit">edit</i>
                                            </a>
                                        </div> -->
                                        <!-- <div class="action-button text-right" *ngIf="(list.seniorStatus=='Approved' && list.acStatus=='Pending'  ) || assign_login_data2.id=='1' && list.seniorStatus !='Reject'">
                                            <a mat-icon-button matTooltip="Change Status" (click)="expModal('acStatus',list.id, list.hrApprovedAmount)">
                                                <i class="material-icons edit">edit</i>
                                            </a>
                                        </div> -->
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let lead of skelton">
                                <tr class="sk-loading" *ngIf="loader">
                                    <td class="w50"><div>&nbsp;</div></td>
                                    <td class="w110"><div>&nbsp;</div></td>
                                    <td class="w110"><div>&nbsp;</div></td>
                                    <td class="w100"><div>&nbsp;</div></td>
                                    <td class="w150"><div>&nbsp;</div></td>
                                    <td class="w100"><div>&nbsp;</div></td>
                                    <td class="w130"><div>&nbsp;</div></td>
                                    <td class="w130"><div>&nbsp;</div></td>
                                    <td class="w100 text-right"><div>&nbsp;</div></td>
                                    <td *ngIf="active_tab == 'Approved'" class="w100 text-right"><div>&nbsp;</div></td>
                                    <td class="w100"><div>&nbsp;</div></td>
                                    <td class="w100"><div>&nbsp;</div></td>
                                    <td class="w100"><div>&nbsp;</div></td>
                                    <td class="w100"><div>&nbsp;</div></td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </div>
            </div>
            
            <ng-container *ngIf="expense_list.length == 0 && datanotfound==true">
                <app-not-result-found></app-not-result-found>
            </ng-container>
        </div>
        
        
        
        <div class="bottom-strip" *ngIf="!loader">
            <div class="left-auto df ac">
                <div class="pagination">
                    <ul class="df ac">
                        <li>Pages {{pageNumber}} of {{total}}</li>
                        <li (click)="pervious()">
                            <a matRipple class="back" matTooltip="Previous" matTooltipPosition="above"><i class="material-icons">chevron_left</i></a>
                        </li>
                        <!-- <li><input type="number" [(ngModel)]="pageNumber" keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li> -->
                        <li (click)="nextPage('next')"><a matRipple class="next" matTooltip="Next" matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                    </ul>
                </div>
                
                <div class="multi-btns">
                    <a mat-raised-button color="accent" (click)="exportRetailerIncentive('expense_list')" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                </div>
                
            </div>
        </div>
    </div>