<div mat-dialog-title>Attendance Details
    <a mat-icon-button class="fix-btn" (click)="close()">
      <i class="material-icons edit">clear</i>
    </a>
  </div>
  <div mat-dialog-content >
    <div class="dialog-content" >
      <div class="row" *ngIf="skLoading">
        <div class="col s12">
          <div class="card">
            <div class="sk-head">
              <h2>&nbsp;</h2>
            </div>
            <div class="card-body">
              <div class="grid-box">
                <div class="sk-box" *ngFor="let row of [].constructor(10)">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="row" *ngIf="!skLoading">
        <div class="col s12 m18 l8">
          <div class="card">
            <div class="card-head">
              <h2>Basic Details</h2>
            
            </div>
  
            <div class="card-body">
  
              <div class="df afs flex-gap-10">
                <div class="img-container mw200 w200 pr10" *ngIf="attendance_data.profile_image != ''">
                  <div (click)="goToImage(url+attendance_data.profile_image)" class="image-block wp100">
                    <img src="{{url+ attendance_data.profile_image}}">
                  </div>
                </div>
                <div class="grid-box three wp100 ">
                  <div class="block-feilds">
                    <span>Date</span>
                    <p>{{attendance_data.attend_date | date}}</p>
                  </div>
  
                  <div class="block-feilds">
                    <span>Employee Name</span>
                    <p>{{attendance_data.name | titlecase}} </p>
                  </div>
  

                  <div class="block-feilds">
                    <span>Reporting Manager</span>
                    <p>{{attendance_data.reporting_manager | titlecase}}</p>
                  </div>
                  <!-- <div class="block-feilds">
                    <span>Manager Id</span>
                    <p>{{attendance_data.reporting_manager.id}}</p>
                  </div> -->
                  <div class="block-feilds">
                    <span>Start Time</span>
                    <div style="display: flex; justify-content: space-between;" >
                      <p *ngIf="attendance_data.start_time == '00:00:00'">---</p>
                      <p *ngIf="attendance_data.start_time != '00:00:00'">{{attendance_data.start_time | date: 'h:mm a'}}
                      </p>
                      <a class="sm-mat-icon-button"  *ngIf="assign_login_data2.edit_attendence=='1'" mat-icon-button matTooltip="Edit Start Time"
                        (click)="editStartTime=true">
                        <i class="material-icons">edit</i>
                      </a>
                    </div>
                    <div style="display: flex; justify-content: space-between;" *ngIf="editStartTime==true">
                      <input type="time" required name="start_time" #start_time="ngModel"
                        [(ngModel)]="attendance_data.start_time" class="time-input">
                      <a class="sm-mat-icon-button" *ngIf="assign_login_data2.edit_attendence=='1'  && attendance_data.start_time" mat-icon-button matTooltip="Save Start Time"
                        (click)="editStartTime=true">
                        <i class="material-icons pt15" (click)="saveNewStartTime('editStartTime')">save</i>
                      </a>
                    </div>
                  </div>
                  <div class="block-feilds">
                    <span>Start Location</span>
                    <p>{{attendance_data.start_address ? attendance_data.start_address :'---'}}</p>
                  </div>
                  <div class="block-feilds">
                    <span>Stop Time</span>
                     <p *ngIf="attendance_data.stop_time == '00:00:00'">---</p>
                    <p *ngIf="attendance_data.stop_time != '00:00:00'">{{attendance_data.stop_time | date: 'h:mm a'}}</p>
  
                    <div style="display: flex; justify-content: space-between;" *ngIf="editStopTime==false">
                      <p *ngIf="attendance_data.stop_time == '00:00:00'">---</p>
                      <p *ngIf="attendance_data.stop_time != '00:00:00'">{{attendance_data.stop_time | date: 'h:mm a'}}</p>
                      <a class="sm-mat-icon-button"  *ngIf="assign_login_data2.edit_attendence=='1' && attendance_data.stop_time" mat-icon-button matTooltip="Edit Stop Time"
                        (click)="editStopTime=true">
                        <i class="material-icons">edit</i>
                      </a>
                    </div>
                    <div style="display: flex; justify-content: space-between;" *ngIf="editStopTime==true">
                      <input type="time" required name="stop_time" #stop_time="ngModel"
                        [(ngModel)]="attendance_data.stop_time" class="time-input">
                      <a class="sm-mat-icon-button" *ngIf="assign_login_data2.edit_attendence=='1'" mat-icon-button matTooltip="Save Stop Time"
                        (click)="editStartTime=true">
                        <i class="material-icons pt15" (click)="saveNewStartTime('editStopTime')">save</i>
                      </a>
                    </div>
  
                  </div>
                  <div class="block-feilds">
                    <span>Stop Location</span>
                    <p>{{attendance_data.stop_address ? attendance_data.stop_address :'--'}}</p>
                  </div>
                  <!-- <div class="block-feilds">
                    <span>Reading By Google (Km)</span>
                    <p>{{attendance_data.google_map_km_per_day ? attendance_data.google_map_km_per_day:'---'}} Km</p>
                  </div> -->
                  <div class="block-feilds">
                    <span>CheckIn Count</span>
                    <p>{{checkin_data.length ? checkin_data.length :'--'}}</p>
                  </div>

  
                  <div class="block-feilds">
                    <span>Working Hour's</span>
                    <p>{{attendance_data.Total_Working_Time ? attendance_data.Total_Working_Time :'--'}}</p>
                  </div>
                  <div class="block-feilds">
                    <span>Working Type</span>
                    <p>{{attendance_data.working_type ? attendance_data.working_type :'--'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
  
        <div class="col s12 m4 l4">
          <div class="travel" *ngIf="checkin_data.length > 0">
            <ul>
              <li>
                <span class="vistit-count">
                  <i class="material-icons">location_on</i>
                </span>
                <p>
                  <strong>Day Start </strong>
                  <ng-container *ngIf="attendance_data.start_time == '0000-00-00 00:00:00'">---</ng-container>
                  <ng-container *ngIf="attendance_data.start_time != '0000-00-00 00:00:00'">{{attendance_data.start_time |
                    date:' h:mm a'}}
                    <br> <br><strong>Start GPS Address :</strong> {{attendance_data.start_address}}
  
                  </ng-container>
                </p>
              </li>
  
              <li *ngFor="let row of checkin_data; let i =index">
                <span class="vistit-count">{{i+1}}</span>
                <span class="km">{{row.km}} KM</span>
  
                <div class="counter">
                  <div>
                    <h2>{{row.company_name | titlecase}}</h2>
                    <div class="visit-time">
                      <div class="visit-hours">
                        <span class="green-clr">Check-in</span>
                        <p *ngIf="row.visit_start != '0000-00-00 00:00:00' ">{{row.visit_start | date:' h:mm a'}}</p>
                        <p *ngIf="row.visit_start == '0000-00-00 00:00:00' ">---</p>
                      </div>
                      <div class="visit-hours">
                        <span class="red-clr">Check-out</span>
                        <p *ngIf="row.visit_end != '0000-00-00 00:00:00' ">{{row.visit_end | date:'h:mm a'}}</p>
                        <p *ngIf="row.visit_end == '0000-00-00 00:00:00' ">---</p>
                      </div>
                      <div class="visit-hours">
                        <span>Total time spend</span>
                        <p>{{row.TOTAL_TIME_TAKE}}</p>
                      </div>
                    </div>
                    <p *ngIf="row.start_address"><strong>Start GPS Address :</strong> {{row.start_address}}</p>
                    <p *ngIf="row.start_address"><strong>End GPS Address :</strong> {{row.address}}</p>
                  </div>
                  <div class="type-visit">
                    <!-- <div class="types" [ngClass]="{'active': row.order_flag > 0}">
                      <span>&nbsp;</span>
                      Order
                    </div> -->
                    <div class="types" [ngClass]="{'active': row.followup_flag > 0}">
                      <span>&nbsp;</span>
                      Followup
                    </div>
                    <div class="types" [ngClass]="{'active': row.Doc > 0}">
                      <span>&nbsp;</span>
                      Photo upload
                    </div>
  
                  </div>
                </div>
              </li>
              <li>
                <span class="km" style="top:-11px; left:-63px;"
                  *ngIf="attendance_data.distance_from_last_checkin">({{attendance_data.distance_from_last_checkin}}
                  KM)</span>
                <span class="vistit-count">
                  <i class="material-icons">location_on</i>
                </span>
  
                <p *ngIf="attendance_data.stop_address && attendance_data.stop_time != '0000-00-00 00:00:00' "><strong>Day
                    Stop </strong>
                  <ng-container *ngIf="attendance_data.stop_time == '0000-00-00 00:00:00'">---</ng-container>
                  <ng-container *ngIf="attendance_data.stop_time != '0000-00-00 00:00:00'">{{attendance_data.stop_time |
                    date:' h:mm a'}}
                    <br> <br>
  
                    <br><strong>GPS Address :</strong> {{attendance_data.stop_address}}
                  </ng-container>
                </p>
  
              </li>
            </ul>
          </div>
  
          <div class="no-location" *ngIf="checkin_data.length == 0">
            <img
              src="https://img.freepik.com/premium-vector/route-vector-icon-route-destination-with-map-pin-symbols-vector-illustration-eps-10_532800-353.jpg?w=2000">
            <p>No Check In</p>
          </div>
        </div>
  
        <div class="fab-btns" *ngIf="assign_login_data2.edit_attendence=='1'">
          <button class="pulse excel" mat-fab color="primary" [matMenuTriggerFor]="menu">
            <i class="material-icons">apps</i>
            Action
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="markAbsent(attendance_data,'getAttendanceToday',1);"
              *ngIf="(assign_login_data2.edit_attendence=='1')">
              <mat-icon>cloud_upload</mat-icon>
              <span>Mark Absent</span>
            </button>
          </mat-menu>
        </div>
  
      </div>
    </div>
  </div>
