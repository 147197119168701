import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { ProductEditModalComponent } from '../product-edit-modal/product-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { TargetExcelUploadComponent } from 'src/app/Target/target-excel-upload/target-excel-upload.component';

@Component({
  selector: 'app-part-master',
  templateUrl: './part-master.component.html',
})
export class PartMasterComponent implements OnInit {


  skeleton: any = [];
  date: any;
  loader: any;
  productData: any;
  searchData: any = {};
  filterSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  matTabValue: any;
  status: any;
  totalProduct: any;
  excelData: any = [];


  constructor(
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public dialog2: MatDialog,


  ) {

    this.skeleton = new Array(10);
    this.date = new Date();
    this.date = new Date();
    this.pageNumber = 1;
    this.matTabValue = 1;
    this.status = 'All'
    // this.productList(1);

  }

  ngOnInit(): void {
    this.paginationData = this.apiHit.getFilterData('part-master-list');
    this.filterSearch = this.paginationData?.search ? this.paginationData.search : this.filterSearch;
    this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
    console.log('part list filter search ', this.paginationData);
    this.productList(this.paginationData?.target ? this.paginationData.target : 1);
  }


  productList(target) {

    if (target == 1) {
      this.pageNumber = 1;
    }
    if (target != 3) {
      this.loader = 1;
      this.productData = [];
    }
    else if (target == 3) {
      this.paginationData.is_excel = 1;
    }
    this.total = 1;
    this.paginationData.search = this.filterSearch;
    this.paginationData.pagelimit = 20;
    this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    this.paginationData.target = target;
    this.paginationData.pageNumber = this.pageNumber;

    this.apiHit.setFilterData(this.paginationData);

    console.log(this.filterSearch);

    this.apiHit.PostRequest(this.paginationData, "Distributor/getPartsList").subscribe((result => {

      console.log(result);
      if (result['status'] == 'success') {
        this.productData = result['viewPartsList']['info'];
        this.total = result['viewPartsList']['totalPage'];
        this.totalProduct = result['viewPartsList']['total_row'];
        this.loader = '';
        if (target == 3 && result['viewPartsList']['total_rows_excel'].length > 0) {
          this.exportPartMaster(result['viewPartsList']['total_rows_excel']);
        }
      }
      else {
        console.log('something went wrong');
        this.dialog.error('error', 'Something Went Wrong');
        this.loader = '';
      }

    }));
  }

  refreshData() {

    this.filterSearch = {};
    this.productList(1);

  }

  exportPartMaster(partData) {

    console.log(partData);
    this.excelData = [];

    for (let i = 0; i < partData.length; i++) {

      this.excelData.push({

        'S.No': i + 1,
        'Date Created': partData[i].date_created ? new Date(partData[i].date_created).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : 'NA',
        'Created By': partData[i].created_by_name ? partData[i].created_by_name : 'NA' ,
        'Segment' : partData[i].segment ? partData[i].segment : 'NA' ,
        'Brand': partData[i].brand ? partData[i].brand : 'NA',
        'Material Code': partData[i].material_code ? partData[i].material_code : 'NA',
        'Part Code': partData[i].part_code ? partData[i].part_code : 'NA',
        'Set Box Pack': partData[i].set_box_pack ? partData[i].set_box_pack : 'N/A',
        'Short Part Code': partData[i].short_part_code ? partData[i].short_part_code : 'N/A',
        'With Rivet or Padkit': partData[i].with_rivet_or_pad_kit ? partData[i].with_rivet_or_pad_kit : 'NA' ,
        'Stock ALert': partData[i].default_stock ? partData[i].default_stock : 'NA',
        'Coupon Point': partData[i].coupon_value ? partData[i].coupon_value : '0',
        'MRP': partData[i].mrp ? partData[i].mrp : '0',
        'List Price': partData[i].list_price ? partData[i].list_price : '0',
        'Set Box Price': partData[i].set_box_price ? partData[i].set_box_price : '0',
        'Box Size': partData[i].box_size ? partData[i].box_size : '0',
        'MRP For Master Box': partData[i].box_mrp ? partData[i].box_mrp : '0',
        'Master Box Pack': partData[i].master_box_pack ? partData[i].master_box_pack : 'N/A',
        'Sets In Master box': partData[i].set_per_master_box ? partData[i].set_per_master_box : 'N/A',
        'Last Updated' : partData[i].date_updated ? new Date(partData[i].date_updated).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : 'NA',
        'Updated By': partData[i].updated_by_name ? partData[i].updated_by_name : 'N/A',

      });

    }

    this.apiHit.exportAsExcelFile(this.excelData, 'PartMaster');

  }


  public onDate(event): void {

    this.filterSearch.dateCreated = moment(event.value).format('YYYY-MM-DD');
    this.productList(1);
  }


  deleteProduct(data) {

    console.log(data);

    this.dialog.delete('').then((result) => {

      console.log(result);

      if (result === true) {

        this.apiHit.PostRequest({ "id": data.id, "uid": this.apiHit.userLoginData.data.id }, "Distributor/deletePart").subscribe((result => {

          console.log(result);

          if (result['status'] == 'Success') {

            this.productList(2);

          }
          else {

            this.dialog.error('error', result['errors']);

          }
        }));

      }

    });

  }

  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.productList(2);
        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.productList(2);
        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.productList(2);
        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.productList(2);


        }
        else if (this.pageNumber) {

          this.productList(2);

        }

      }

    },
      100);
  }

  //Edit Product (Open Dialog Box) Function Start 

  productEdit(target, data): void {

    const dialogRef = this.dialog2.open(ProductEditModalComponent, {

      width: '',
      panelClass: "modelwidth",
      data: { target, data }

    });

    dialogRef.afterClosed().subscribe(result => {

      this.productList(2);

    });

  }
  //Edit Product (Open Dialog Box) Function End

  ExcelUploadFunction(data): void {

    const dialogRef = this.dialog2.open(TargetExcelUploadComponent, {

      width: '400px',

      disableClose: true,

      data:
      {
        'from':'Part_master'
      }

    });

    dialogRef.afterClosed().subscribe(result => {

      dialogRef.close();
      this.productList(2);

    });

  }


}
