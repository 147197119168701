<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Damage Coupon Entry</h2>
                    <!-- <p>{{targetData.all ? targetData.all : '0'}} Target Are Available</p> -->
                </div>
            </ng-container>
            <ng-container>
                <div class="left-auto df afe">
                    <!-- <div class="indicates">
                        Last Sync Time - {{targetData.last_sync_date ? (targetData.last_sync_date | date: 'd MMM y, hh mm a') : 'N/A'}}
                    </div> -->
                    <div class="cs-action">
                      
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                            (click)="refreshData()"><i
                                class="material-icons">settings_backup_restore</i></a>
                    </div>
                </div>
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>


        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">

                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw100 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw150">Created Date</div>
                            <div class="div_cell mmw300">Coupon Code</div>
                            <div class="div_cell mmw200">Coupon Code Amount</div>

                            <div class="div_cell mmw150">Damage Date</div>
                            <div class="div_cell mmw150">Uploaded By</div>
                            <div class="div_cell mmw500">Damage Reason</div>
                           
                        </main>
                    </section>
                    <section class="th_cell">
                     <main>
                        <div class="div_cell mmw100 text-center af-bdr-left"></div>
                        <div class="div_cell mmw150"></div>

                        <div class="div_cell mmw300"></div>
                        <div class="div_cell mmw200"></div>

                        <div class="div_cell mmw150"></div>
                        <div class="div_cell mmw150"></div>
                        <div class="div_cell mmw500">
                            <div class="cs-action">
                                <!-- <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                    (click)="orderList(1,status)"><i class="material-icons">search</i></a> -->
                                <!-- <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                    (click)="refreshData()"><i
                                        class="material-icons">settings_backup_restore</i></a> -->
                            </div>
                        </div>
                     </main>
                    </section>
                </figure>

                <section class="td_cell">
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let data of targetData; let i=index">
                            <div class="div_cell mmw100 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw150">{{data.date_created ? (data.date_created | date: 'dd-MMM-yyyy') : 'N/A'}}</div>

                            <div class="div_cell mmw300">{{data.coupon_code ? data.coupon_code : 'N/A'}}</div>
                            <div class="div_cell mmw200">{{data.coupon_code_amount ? data.coupon_code_amount : 'N/A'}}</div>
                            <div class="div_cell mmw150">{{data.damage_date ? (data.damage_date | date: 'dd-MMM-yyyy') : 'N/A'}}</div>
                            <div class="div_cell mmw150">{{data.updated_by_user_name ? (data.updated_by_user_name) : 'N/A'}}</div>
                           
                            <div class="div_cell mmw500">{{data.damage_reason ? (data.damage_reason | titlecase):'N/A'}}</div>
                           
                        </main>
                        <app-not-found *ngIf="targetData.length == 0"></app-not-found>
                    </ng-container>


                    <ng-container *ngIf="loader">
                        <main *ngFor="let data of skeleton; let i=index">
                            <div class="div_cell mmw100 ac"><p class="skeleton wp100">&nbsp;</p></div><!-- S.No. -->
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div><!-- Created Date -->

                            <div class="div_cell mmw300"><p class="skeleton wp100">&nbsp;</p></div><!-- Coupon Code -->
                            <div class="div_cell mmw200"><p class="skeleton wp100">&nbsp;</p></div><!-- Coupon Code Amount -->
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div><!-- Damage Date -->
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div><!-- Damage Date Updated date -->
                           
                            <div class="div_cell mw500"><p class="skeleton wp100">&nbsp;</p></div><!-- Damage Reason -->
                          
                        </main>
                    </ng-container>
                </section>
            </div>

            <div class="bottom-strip" *ngIf="!loader">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                    matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                            <li><input type="number" [(ngModel)]="pageNumber"
                                (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                            <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                    matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                        </ul>
                    </div>

                    
                    <div class="multi-btns">
                        <!-- *ngIf="apiHit.userLoginData.data.moduleData[9]['add'] == 1" -->
                        <a mat-raised-button color="accent"  (click)="ExcelUploadFunction(targetData)"><img src="assets/img/excel.png"> Upload Excel</a>
                        <!-- *ngIf="targetData.targetData.length > 0" -->
                        <a mat-raised-button color="accent"   (click)="ExcelDownloadFunction()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>