<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <div class="module-name">
                    <h2>Coupon Code</h2>
                    <p *ngIf="matTabValue == 1">{{availableCouponData.length ? availableCouponData.length : '0'}}</p>
                    <p *ngIf="matTabValue == 2 || matTabValue == 3 || matTabValue == 4 || matTabValue == 5">{{scannedCouponData.length ? scannedCouponData.length : '0'}}</p>
                </div>
                <div class="left-auto">
                    <ul class="date-filter">
                        <li>
                            <div class="cs-form pb0">
                                <p>Date From</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker2.open()">
                                    <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                        placeholder="Search ..." name="date_from" [(ngModel)]="filterSearch.date_from"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li>
                            <div class="cs-form pb0">
                                <p>Date To</p>
                                <mat-form-field appearance="outline" class="cs-input" (click)="picker3.open()">
                                    <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                        placeholder="Search ..." name="date_to" [(ngModel)]="filterSearch.date_to"
                                        readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                    <mat-datepicker #picker3></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </li>
                        <li class="cs-btn mt15 mr10" *ngIf="this.filterSearch.date_from && this.filterSearch.date_to && (matTabValue == 1 || matTabValue == 2)">
                            <a mat-button (click)="matTabValue == 1 ? couponCodeAvailableList(1) : couponCodeScannedList(1)">Go</a>
                        </li>
                        <li class="cs-btn mt15 mr10" *ngIf="this.filterSearch.date_from && this.filterSearch.date_to && (matTabValue == 3 || matTabValue == 4 || matTabValue == 5)">
                            <a mat-button (click)="getMasterCouponList(1)">Go</a>
                        </li>
                        <li class="cs-btn mt15 mr10" *ngIf="this.filterSearch.date_from || this.filterSearch.date_to">
                            <a mat-button (click)="clearDateFilter()">Clear Filter</a>
                        </li>
                        <!-- <li class="cs-btn mt15" *ngFor=" let datas of apiHit.userLoginData.data.moduleData"> 
                            <a *ngIf="datas.module_name== 'Coupon Code' && datas.add==1 && this.pagename != 'primary_scanned'" mat-button routerLink="../add-coupon">Generate Coupon</a>
                        </li> -->
                    </ul>
                </div>
                <!-- <div class="left-auto">
                    <div class="cs-btn mt0" *ngFor=" let datas of apiHit.userLoginData.data.moduleData"> 
                        <a *ngIf="datas.module_name== 'Coupon Code' && datas.add==1" mat-button routerLink="../add-coupon">Generate Coupon</a>
                    </div>
                </div> -->
            </ng-container>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
        </section>
        
        <div class="container-pd10-5 bottom-calc no-padding">

            <mat-tab-group class="tab-group flat-tab-body tab-group-list" [selectedIndex]="matTabValue - 1" (selectedTabChange)="tabClick($event)" mat-align-tabs="start">

                <!-- <mat-tab label="1">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">task_alt</mat-icon>
                        Primary Available
                        <span class="badge">{{totalcount.primaryAvailableCount ? totalcount.primaryAvailableCount : '0'}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader == true">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                </mat-tab> -->

                <mat-tab label="2">
                    <ng-template mat-tab-label *ngIf="!loader ">
                        <mat-icon class="example-tab-icon">qr_code_scanner</mat-icon>
                        Primary Scanned
                        <span class="badge">{{totalcount.primaryScannedCount ? totalcount.primaryScannedCount : '0'}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader == true">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                </mat-tab>

                <!-- <mat-tab label="3">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">task_alt</mat-icon>
                        Master Available
                        <span class="badge">{{totalcount.masterAvailableCount ? totalcount.masterAvailableCount : '0'}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader == true">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                </mat-tab> -->
<!-- 
                <mat-tab label="4">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">qr_code_scanner</mat-icon>
                        Master Scanned
                        <span class="badge">{{totalcount.masterScannedCount ? totalcount.masterScannedCount : '0'}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader == true">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                </mat-tab> -->

                <!-- <mat-tab label="5">
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">verified</mat-icon>
                        Master Dispatch
                        <span class="badge">{{totalcount.masterDispatchScannedCount ? totalcount.masterDispatchScannedCount : '0'}}</span>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template> 
                </mat-tab> -->

            </mat-tab-group>


            <!-- Available Coupon Code Tab Start -->
            <div class="cs-table" *ngIf="matTabValue == 1">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw90">Date</div>
                            <div class="div_cell mmw90">Created By</div>
                            <div class="div_cell mmw140">Coupon Code</div>
                            <div class="div_cell mmw80">Segment</div>
                            <div class="div_cell mmw80">Brand</div>
                            <div class="div_cell mmw250">Part No</div>
                            <div class="div_cell mmw140">Master Coupon Code</div>
                            <div class="div_cell mmw140">Packaging Date & Time</div>
                            <div class="div_cell mmw140">Dispatch To</div>
                            <div class="div_cell mmw140">Dispatch Date & Time</div>
                            <div class="div_cell mmw90">Transfer Date</div>
                            <div class="div_cell mmw110">Dispatch From</div>
                            <div class="div_cell mmw90 text-right af-bdr-right">Coupon Point</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="dateCreated" 
                                        [(ngModel)]="filterSearch.dateCreated" (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="createdBy"
                                    [(ngModel)]="filterSearch.createdBy" (keyup.enter)="couponCodeAvailableList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="couponCode"
                                    [(ngModel)]="filterSearch.couponCode" (keyup.enter)="couponCodeAvailableList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw250">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                    [(ngModel)]="filterSearch.part_code" (keyup.enter)="couponCodeAvailableList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw110">&nbsp;</div>
                            <div class="div_cell mmw90 af-bdr-right">
                                <div class="cs-action jfe">
                                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="couponCodeAvailableList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>
                        </main>
                    </section>
                </figure>
                
                <section class="td_cell" >
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let row of availableCouponData; let i=index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw90">{{(row.date_created) | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw90">{{row.created_by_name ? (row.created_by_name | titlecase) :'N/A'}}</div>
                            <div class="div_cell mmw140">{{row.coupon_code ? row.coupon_code:'N/A'}}</div>
                            <div class="div_cell mmw80">{{row.segment ? row.segment : 'N/A'}}</div>
                            <div class="div_cell mmw80">{{row.category ? row.category:'N/A'}}</div>
                            <div class="div_cell mmw250">{{row.part_code ? row.part_code:'N/A'}}</div>
                            <div class="div_cell mmw140">{{row.master_box_serial_no ? row.master_box_serial_no:'N/A'}}</div>
                            <div class="div_cell mmw140">
                                {{row.date_of_packing != '0000-00-00 00:00:00' ? (row.date_of_packing | date : 'd MMM y, h:mm a') : 'N/A'}}
                            </div>
                            <div class="div_cell mmw140">{{row.sold_to_party ? row.sold_to_party:'N/A'}}</div>
                            <div class="div_cell mmw140">
                                {{row.dispatch_date_time == '0000-00-00 00:00:00' ? row.dispatch_date != '0000-00-00 00:00:00'  ? ( row.dispatch_date | date : 'd MMM y, h:mm a'):'N/A' :  (row.dispatch_date_time | date : 'd MMM y, h:mm a') }}
                            </div>
                            <div class="div_cell mmw90">{{row.warehouse_assign_date_time != '0000-00-00 00:00:00' ?(row.warehouse_assign_date_time | date: 'd MMM y') : 'N/A'}}</div>
                            <div class="div_cell mmw110 afe" *ngIf="row.warehouse_assign_date_time != '0000-00-00 00:00:00'">{{row.dispatch_from_warehouse_name ? row.dispatch_from_warehouse_name : 'Company'}}</div>
                            <div class="div_cell mmw110 afe" *ngIf="row.warehouse_assign_date_time == '0000-00-00 00:00:00'">{{row.sold_to_party ? 'Company' : 'N/A'}}</div>
                            <div class="div_cell mmw90 afe">{{row.coupon_code_amount ? row.coupon_code_amount:'0'}}</div>
                        </main>
                        <app-not-found *ngIf="availableCouponData==0" class="fix"></app-not-found>
                    </ng-container>

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;" >
                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw250"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>

                </section>
            </div>
            <!-- Available Coupon Code Tab End -->


            <!-- Scanned Coupon Code Tab Start -->
            <div class="cs-table" *ngIf="matTabValue == 2">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw90">Date</div>
                            <div class="div_cell mmw90">Created By</div>
                            <div class="div_cell mmw140">Coupon Code</div>
                            <div class="div_cell mmw80">Segment</div>
                            <div class="div_cell mmw80">Brand</div>
                            <div class="div_cell mmw250">Part No</div>
                            <div class="div_cell mmw150">Distributor Detail</div>
                            <div class="div_cell mmw150">Retailer Detail</div>
                            <div class="div_cell mmw130">Mechanic Detail</div>
                            <div class="div_cell mmw130">Scan Date & Time</div>
                            <div class="div_cell mmw140">Master Coupon Code</div>
                            <div class="div_cell mmw140">Packaging Date & Time</div>
                            <div class="div_cell mmw140">Dispatch To</div>
                            <div class="div_cell mmw140">Dispatch Date & Time</div>
                            <div class="div_cell mmw140">Invoice No.</div>
                            <div class="div_cell mmw140">Customer Detail</div>
                            <div class="div_cell mmw90">Transfer Date</div>
                            <div class="div_cell mmw110">Dispatch From</div>
                            <div class="div_cell mmw90">Coupon Value</div>
                            <div class="div_cell mmw90 text-right af-bdr-right">Action</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker2.open()">
                                        <input type="text" matInput [matDatepicker]="picker2" [max]="date" placeholder="Search ..." name="dateCreated" 
                                        [(ngModel)]="filterSearch.dateCreated" (dateChange)="onDate3($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="createdBy"
                                    [(ngModel)]="filterSearch.createdBy" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="couponCode"
                                    [(ngModel)]="filterSearch.couponCode" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw250">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                    [(ngModel)]="filterSearch.part_code" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw150">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="distributor_name"
                                    [(ngModel)]="filterSearch.distributor_name" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw150">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="retailerData"
                                    [(ngModel)]="filterSearch.retailerData" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mechanicData"
                                    [(ngModel)]="filterSearch.mechanicData" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw130">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker3.open()">
                                        <input type="text" matInput [matDatepicker]="picker3" [max]="date" placeholder="Search ..." name="scanDate" 
                                        [(ngModel)]="filterSearch.scanDate" (dateChange)="onDate2($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">&nbsp;</div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="invoice_number"
                                    [(ngModel)]="filterSearch.invoice_number" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_detail"
                                    [(ngModel)]="filterSearch.dr_detail" (keyup.enter)="couponCodeScannedList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw90">&nbsp;</div>
                            <div class="div_cell mmw110">&nbsp;</div>
                            <div class="div_cell mmw90" *ngIf="loader">&nbsp;</div>
                            <div class="div_cell mmw90 text-right" *ngIf="!loader">{{scannedCouponTotalAmount ? scannedCouponTotalAmount : 0}}</div>
                            <div class="div_cell mmw90 af-bdr-right">
                                <div class="cs-action jfe">
                                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="couponCodeScannedList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData2()"><i class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>
                        </main>
                    </section>
                </figure>
                
                
                <section class="td_cell" >
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let row of scannedCouponData; let i=index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw90">{{row.date_created != '0000-00-00 00:00:00' ? (row.date_created | date: 'd MMM y'):'N/A'}}</div>
                            <div class="div_cell mmw90">{{row.created_by_name  ?  (row.created_by_name | titlecase) :'N/A'}}</div>
                            <div class="div_cell mmw140">{{row.coupon_code ? row.coupon_code:'N/A'}}</div>
                            <div class="div_cell mmw80">{{row.segment ? row.segment : 'N/A'}}</div>
                            <div class="div_cell mmw80">{{row.category ? row.category: 'N/A'}}</div>
                            <div class="div_cell mmw250">{{row.part_code ? row.part_code:'N/A'}}</div>
                            <div class="div_cell mmw150">{{row.distributor_name ? row.distributor_name:'N/A'}}</div>
                            <div class="div_cell mmw150">{{row.retailer_name ? row.retailer_name:'N/A'}} - {{row.retailer_mobile ? row.retailer_mobile:'N/A'}}</div>
                            <div class="div_cell mmw130">{{row.mechanic_name ? row.mechanic_name:'N/A'}}</div>
                            <div class="div_cell mmw130">{{row.retailer_scan_date != '0000-00-00 00:00:00' ? (row.retailer_scan_date | date : 'd MMM y, h:mm a') : 'N/A'}}</div>
                            <div class="div_cell mmw140">{{row.master_box_serial_no ? row.master_box_serial_no:'N/A'}}</div>
                            <div class="div_cell mmw140">
                                {{row.date_of_packing != '0000-00-00 00:00:00' ? (row.date_of_packing | date : 'd MMM y, h:mm a') : 'N/A'}}
                            </div>
                            <div class="div_cell mmw140">{{row.sold_to_party ? row.sold_to_party:'N/A'}}</div>
                            <div class="div_cell mmw140">
                                {{row.dispatch_date_time == '0000-00-00 00:00:00' ?row.dispatch_date != '0000-00-00 00:00:00'  ? ( row.dispatch_date | date : 'd MMM y, h:mm a'):'N/A' : row.dispatch_date_time != '0000-00-00 00:00:00' ? (row.dispatch_date_time | date : 'd MMM y, h:mm a') : 'N/A' }}
                            </div>
                            <div class="div_cell mmw140">
                                {{row.invoice_number ?row.invoice_number :  'N/A'}}
                            </div>
                            <div class="div_cell mmw140">
                                {{row.dr_detail  ?row.dr_detail :'N/A'}}
                            </div>
                            <div class="div_cell mmw90">{{row.warehouse_assign_date_time != '0000-00-00 00:00:00' ?(row.warehouse_assign_date_time | date: 'd MMM y') : 'N/A'}}</div>
                            <div class="div_cell mmw110 afe" *ngIf="row.warehouse_assign_date_time != '0000-00-00 00:00:00'">{{row.dispatch_from_warehouse_name ? row.dispatch_from_warehouse_name : 'Company'}}</div>
                            <div class="div_cell mmw110 afe" *ngIf="row.warehouse_assign_date_time == '0000-00-00 00:00:00'">{{row.sold_to_party ? 'Company' : 'N/A'}}</div>
                            <div class="div_cell mmw90 afe">{{row.coupon_code_amount ? row.coupon_code_amount:'0'}}</div>
                            <div class="div_cell mmw90 afe"></div>
                        </main>
                        <app-not-found *ngIf="scannedCouponData == 0" class="fix"></app-not-found>
                    </ng-container>

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;" >
                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw250"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw150"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw130"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw130"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>

                </section>

            </div>
            <!-- Scanned Coupon Code Tab Start -->


            <!-- Master Coupon Start -->

            <div class="cs-table" *ngIf="matTabValue == 3 || matTabValue == 4 || matTabValue == 5">
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw90">Date</div>
                            <div class="div_cell mmw110">Created By</div>
                            <div class="div_cell mmw125">Coupon Code</div>
                            <div class="div_cell mmw80">Segment</div>
                            <div class="div_cell mmw80">Brand</div>
                            <div class="div_cell mw220">Part No</div>
                            <div class="div_cell mmw70">Box Size</div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 4">Packaging Date & Time</div>
                            <div class="div_cell mmw120" *ngIf="matTabValue == 4">Warehouse Location</div>
                            <div class="div_cell mmw90" *ngIf="matTabValue == 4">Transfer Date</div>
                            <div class="div_cell mmw180" *ngIf="matTabValue == 5">Dispatch From</div>
                            <div class="div_cell mmw150" *ngIf="matTabValue == 5">Distributor Name</div>
                            <div class="div_cell mmw100" *ngIf="matTabValue == 5">Invoice No</div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 5">Dispatch Date & Time</div>
                            <div class="div_cell mmw70 text-right af-bdr-right">Action</div>
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw90">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker2.open()">
                                        <input type="text" matInput [matDatepicker]="picker2" [max]="date" placeholder="Search ..." name="dateCreated" 
                                        [(ngModel)]="filterSearch.dateCreated" (dateChange)="getMasterCouponList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="createdBy"
                                    [(ngModel)]="filterSearch.createdBy" (keyup.enter)="getMasterCouponList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw125">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="couponCode"
                                    [(ngModel)]="filterSearch.couponCode" (keyup.enter)="getMasterCouponList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mmw80">&nbsp;</div>
                            <div class="div_cell mw220">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="part_code"
                                    [(ngModel)]="filterSearch.part_code" (keyup.enter)="getMasterCouponList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw70">&nbsp;</div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 4">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="date_of_packing" 
                                        [(ngModel)]="filterSearch.date_of_packing" (dateChange)="getMasterCouponList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw120" *ngIf="matTabValue == 4">
                                <!-- <div class="filter-data">
                                    <mat-form-field (click)="picker1.open()">
                                        <input type="text" matInput [matDatepicker]="picker1" [max]="date" placeholder="Search ..." name="dispatch_date_time" 
                                        [(ngModel)]="filterSearch.dispatch_date_time" (dateChange)="getMasterCouponList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div> -->
                            </div>
                            <div class="div_cell mmw90" *ngIf="matTabValue == 4">
                                <!-- <div class="filter-data">
                                    <mat-form-field (click)="picker1.open()">
                                        <input type="text" matInput [matDatepicker]="picker1" [max]="date" placeholder="Search ..." name="dispatch_date_time" 
                                        [(ngModel)]="filterSearch.dispatch_date_time" (dateChange)="getMasterCouponList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div> -->
                            </div>
                            <div class="div_cell mmw180" *ngIf="matTabValue == 5">
                                <!-- <div class="filter-data">
                                    <mat-form-field (click)="picker1.open()">
                                        <input type="text" matInput [matDatepicker]="picker1" [max]="date" placeholder="Search ..." name="dispatch_date_time" 
                                        [(ngModel)]="filterSearch.dispatch_date_time" (dateChange)="getMasterCouponList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div> -->
                            </div>
                            <div class="div_cell mmw150" *ngIf="matTabValue == 5">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="dr_name"
                                    [(ngModel)]="filterSearch.dr_name" (keyup.enter)="getMasterCouponList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw100" *ngIf="matTabValue == 5">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="invoice_no"
                                    [(ngModel)]="filterSearch.invoice_no" (keyup.enter)="getMasterCouponList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 5">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker1.open()">
                                        <input type="text" matInput [matDatepicker]="picker1" [max]="date" placeholder="Search ..." name="dispatch_date_time" 
                                        [(ngModel)]="filterSearch.dispatch_date_time" (dateChange)="getMasterCouponList(1)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            
                            <div class="div_cell mmw70 ac">
                                <div class="cs-action jfe">
                                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="getMasterCouponList(1)"><i class="material-icons">search</i></a>
                                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshMasterCouponList()"><i class="material-icons">settings_backup_restore</i></a>
                                </div>
                            </div>
                        </main>
                    </section>
                </figure>
                
                
                <section class="td_cell" >
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let row of scannedCouponData; let i=index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw90">{{(row.date_created) | date: 'd MMM y'}}</div>
                            <div class="div_cell mmw110">{{row.created_by_name  ?  (row.created_by_name | titlecase) :'N/A'}}</div>
                            <div class="div_cell mmw125">{{row.coupon_code ? row.coupon_code:'N/A'}}</div>
                            <div class="div_cell mmw80">{{row.segment ? row.segment : 'N/A'}}</div>
                            <div class="div_cell mmw80">{{row.category ? row.category: 'N/A'}}</div>
                            <div class="div_cell mw220">{{row.part_code ? row.part_code:'N/A'}}</div>
                            <div class="div_cell mmw70">{{row.box_size ? row.box_size:0}}</div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 4">
                                {{row.date_of_packing ? (row.date_of_packing | date : 'd MMM y, h:mm a') : 'N/A'}}
                            </div>
                            <div class="div_cell mmw120" *ngIf="matTabValue == 4">
                                {{row.warehouse_name ? row.warehouse_name : 'N/A'}}
                            </div>
                            <div class="div_cell mmw90" *ngIf="matTabValue == 4">
                                {{row.warehouse_assign_date_time != '0000-00-00 00:00:00' ? (row.warehouse_assign_date_time | date : 'd MMM y') : 'N/A'}}
                            </div>
                            <div class="div_cell mmw180" *ngIf="matTabValue == 5">{{row.warehouse_id == 0 ? 'Company': row.warehouse_name}}</div>
                            <div class="div_cell mmw150" *ngIf="matTabValue == 5">{{row.dr_name ? row.dr_name:'N/A'}}</div>
                            <div class="div_cell mmw100" *ngIf="matTabValue == 5">{{row.invoice_no ? row.invoice_no:'N/A'}}</div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 5">
                                {{row.dispatch_date_time == '0000-00-00 00:00:00' ?row.dispatch_date != '0000-00-00 00:00:00'  ? ( row.dispatch_date | date : 'd MMM y, h:mm a'):'N/A'  ? ( row.dispatch_date | date : 'd MMM y, h:mm a'):'N/A' :  (row.dispatch_date_time | date : 'd MMM y, h:mm a') }}
                            </div>
                            <div class="div_cell mmw70 ac">
                                <div class="cs-action" *ngIf="matTabValue == 4 || matTabValue == 5">
                                    <a  class="ac-blue" matRipple matTooltip="View" matTooltipPosition="below" (click)="boxDetailModel(matTabValue,row)">
                                        <i class="material-icons">visibility</i>
                                    </a>
                                </div>
                            </div>
                        </main>
                        <app-not-found *ngIf="scannedCouponData == 0" class="fix"></app-not-found>
                    </ng-container>

                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skeleton;" >
                            <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw125"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mw220"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw70"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 4"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw120" *ngIf="matTabValue == 4"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw90" *ngIf="matTabValue == 4"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw180" *ngIf="matTabValue == 5"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw150" *ngIf="matTabValue == 5"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw100" *ngIf="matTabValue == 5"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw140" *ngIf="matTabValue == 5"><p class="skeleton wp100">&nbsp;</p></div>
                            <div class="div_cell mmw70"><p class="skeleton wp100">&nbsp;</p></div>
                        </main>
                    </ng-container>

                </section>

            </div>

            <!-- Master Coupon End -->

            
            <!-- Pagination Start -->
            <div class="bottom-strip">
                <div class="left-auto df ac" *ngIf="!loader">
                    <div class="pagination">
                        <ng-container *ngIf = "matTabValue == 1">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',1)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',1)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',1)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf = "matTabValue == 2 && total > 1">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',2)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',2)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',2)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container>
                        <ng-container *ngIf = "(matTabValue == 3 || matTabValue == 4 || matTabValue == 5) && total > 1">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous',3)"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons" >chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current',3)" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next',3)"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons" >chevron_right</i></a></li>
                            </ul>
                        </ng-container>
                    </div>
                    <div class="multi-btns">
                        <a mat-raised-button color="accent" *ngIf="availableCouponData.length > 0 && matTabValue == 1"  (click)="ExcelDownloadFunction(1)" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                        <a mat-raised-button color="accent" *ngIf="scannedCouponData.length > 0 && matTabValue == 2"  (click)="ExcelDownloadFunction(2)" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                        <!-- <a mat-raised-button color="accent" *ngIf="scannedCouponData.length > 0 && (matTabValue == 3 || matTabValue == 4 || matTabValue == 5)"  (click)="ExcelDownloadFunction(3)" class="ml10"><img src="assets/img/excel.png"> Download Excel</a> -->
                    </div>
                </div>
            </div>
            <!-- Pagination End -->

        </div>
    </div>
</div>