import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { LeftNavigationComponent } from './left-navigation/left-navigation.component';
import { DistributorListComponent } from './distributor/distributor-list/distributor-list.component';
import { DistributorAddComponent } from './distributor/distributor-add/distributor-add.component';
import { PayNowModelComponent } from './distributor/pay-now-model/pay-now-model.component';
import { DistributorDetailComponent } from './distributor/distributor-detail/distributor-detail.component';
import { RetailerListComponent } from './retailer/retailer-list/retailer-list.component';
import { RetailerDetailComponent } from './retailer/retailer-detail/retailer-detail.component';
import { MechanicListComponent } from './mechanic/mechanic-list/mechanic-list.component';
import { MechanicDetailComponent } from './mechanic/mechanic-detail/mechanic-detail.component';
import { CouponCodeAddComponent } from './coupon/coupon-code-add/coupon-code-add.component';
import { CouponCodeListComponent } from './coupon/coupon-code-list/coupon-code-list.component';
import { AskToDistributorListComponent } from './transaction/ask-to-distributor-list/ask-to-distributor-list.component';
import { DistributorToRetailerListComponent } from './transaction/distributor-to-retailer-list/distributor-to-retailer-list.component';
import { RetailerToMechanicListComponent } from './transaction/retailer-to-mechanic-list/retailer-to-mechanic-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RetailerAddComponent } from './retailer/retailer-add/retailer-add.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DialogService } from './services/dialog.service';
import { DatabaseService } from './services/service';
import { MechanicEditComponent } from './mechanic/mechanic-edit/mechanic-edit.component';
import { DistributorEditComponent } from './distributor/distributor-edit/distributor-edit.component';
import { RetailerEditComponent } from './retailer/retailer-edit/retailer-edit.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UserAddComponent } from './user/user-add/user-add.component';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { BannerListComponent } from './banner/banner-list/banner-list.component';
import { MyFilterPipe } from './filter/filter';
import { CouponStatusModelComponent } from './coupon/coupon-status-model/coupon-status-model.component';
import { YourPipeNameComponent } from './your-pipe-name/your-pipe-name.component';
import { CustomPipe } from './custom.pipe';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { APP_DATE_FORMATS, CustomdatePipe } from './customdate.pipe';
import { ChartsModule } from 'ng2-charts';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { FilterPipe } from './filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PrimaryOrderComponent } from './order/primary-order/primary-order.component';
import { PendingOrdersComponent } from './PendingOrder/pending-orders/pending-orders.component';
import { PartMasterComponent } from './product/part-master/part-master.component';
import { ProductEditModalComponent } from './product/product-edit-modal/product-edit-modal.component';
import { OrderDetailComponent } from './order/order-detail/order-detail.component';
import { SecondaryOrderComponent } from './order/secondary-order/secondary-order.component';
import { SecondaryorderDetailComponent } from './order/secondaryorder-detail/secondaryorder-detail.component';
import { OrderEditModalComponent } from './order/order-edit-modal/order-edit-modal.component';
import { SchemeListComponent } from './Scheme/scheme-list/scheme-list.component';
import { SchemeAddComponent } from './Scheme/scheme-add/scheme-add.component';
import { SchemeDetailComponent } from './Scheme/scheme-detail/scheme-detail.component';
import { ExcelUploadModalComponent } from './distributor/excel-upload-modal/excel-upload-modal.component';
import { PendingOrderExcelUploadModalComponent } from './PendingOrder/pending-order-excel-upload-modal/pending-order-excel-upload-modal.component';
import { BillingListComponent } from './Billing/billing-list/billing-list.component';
import { BillingExcelUploadComponent } from './Billing/billing-excel-upload/billing-excel-upload.component';
import { PendingOrderDetailComponent } from './PendingOrder/pending-order-detail/pending-order-detail.component';
import { BillingDetailComponent } from './Billing/billing-detail/billing-detail.component';
import { IncentiveListComponent } from './RetailerIncentive/incentive-list/incentive-list.component';
import { IncentiveDetailComponent } from './RetailerIncentive/incentive-detail/incentive-detail.component';
import { TargetListComponent } from './Target/target-list/target-list.component';
import { TargetExcelUploadComponent } from './Target/target-excel-upload/target-excel-upload.component';
import { PrimaryOrderAddComponent } from './order/primary-order-add/primary-order-add.component';
import { SecondaryOrderAddComponent } from './order/secondary-order-add/secondary-order-add.component';
import { CompanyStockListComponent } from './companyStock/company-stock-list/company-stock-list.component';
import { BoxPackagingDetailComponent } from './coupon/box-packaging-detail/box-packaging-detail.component';
import { PartiallyDeliveredComponent } from './transaction/partially-delivered/partially-delivered.component';
import { RetailerSchemeComponent } from './transaction/retailer-scheme/retailer-scheme.component';
import { DistributorMslComponent } from './transaction/distributor-msl/distributor-msl.component';
import { CompanyStockComponent } from './transaction/company-stock/company-stock.component';
import { WarehouseStockComponent } from './transaction/warehouse-stock/warehouse-stock.component';
import { CouponStockUtilizeComponent } from './transaction/coupon-stock-utilize/coupon-stock-utilize.component';
import { MasterAndPrimaryComponent } from './scanQrCode/master-and-primary/master-and-primary.component';
import { DamageScanCouponComponent } from './damage-scan-coupon/damage-scan-coupon.component';
import { DamageScanUploadExcelComponent } from './damage-scan-upload-excel/damage-scan-upload-excel.component';
import { CompanyDispatchListComponent } from './company-dispatch/company-dispatch-list/company-dispatch-list.component';
import { CompanyDispatchDetailComponent } from './company-dispatch/company-dispatch-detail/company-dispatch-detail.component';
import { GatepassAddComponent } from './company-dispatch/gatepass-add/gatepass-add.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CheckinComponent } from './checkin/checkin.component';
import { AttendenceDetailComponent } from './attendence-detail/attendence-detail.component';
import { ExpenseComponent } from './expense/expense.component';
import { CheckinDetailComponent } from './checkin-detail/checkin-detail.component';
import { ExpenseModalComponent } from './expense-modal/expense-modal.component';
import { ExpenseDetailComponent } from './expense-detail/expense-detail.component';
import { ImageModuleComponent } from './image-module/image-module.component';
import { ExpenseEditComponent } from './expense-edit/expense-edit.component';
import { AllowanceMasterComponent } from './allowance-master/allowance-master.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { PrintScannedListComponent } from './print-scanned-list/print-scanned-list.component';
import { CustomerCategoryComponent } from './customer-category/customer-category.component';
import { ManualHitFileDownloadComponent } from './Billing/manual-hit-file-download/manual-hit-file-download.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    LeftNavigationComponent,
    DistributorListComponent,
    FilterPipe,
    DistributorAddComponent,
    PayNowModelComponent,
    DistributorDetailComponent,
    RetailerListComponent,
    RetailerDetailComponent,
    MechanicListComponent,
    MechanicDetailComponent,
    CouponCodeAddComponent,
    CouponCodeListComponent,
    AskToDistributorListComponent,
    DistributorToRetailerListComponent,
    RetailerToMechanicListComponent,
    RetailerAddComponent,
    MechanicEditComponent,
    DistributorEditComponent,
    RetailerEditComponent,
    NotFoundComponent,
    DamageScanCouponComponent,
    UserAddComponent,
    // DashboardComponent,
    UserListComponent,
    UserDetailComponent,
    BannerListComponent,
    MyFilterPipe,
    CouponStatusModelComponent,
    YourPipeNameComponent,
    CustomPipe,
    PrimaryOrderComponent,
    PendingOrdersComponent,
    PartMasterComponent,
    ProductEditModalComponent,
    OrderDetailComponent,
    SecondaryOrderComponent,
    SecondaryorderDetailComponent,
    OrderEditModalComponent,
    SchemeListComponent,
    SchemeAddComponent,
    SchemeDetailComponent,
    ExcelUploadModalComponent,
    PendingOrderExcelUploadModalComponent,
    BillingListComponent,
    BillingExcelUploadComponent,
    ManualHitFileDownloadComponent,
    PendingOrderDetailComponent,
    BillingDetailComponent,
    IncentiveListComponent,
    IncentiveDetailComponent,
    TargetListComponent,
    TargetExcelUploadComponent,
    PrimaryOrderAddComponent,
    SecondaryOrderAddComponent,
    CompanyStockListComponent,
    BoxPackagingDetailComponent,
    PartiallyDeliveredComponent,
    RetailerSchemeComponent,
    DistributorMslComponent,
    CompanyStockComponent,
    WarehouseStockComponent,
    CouponStockUtilizeComponent,
    MasterAndPrimaryComponent,
    DamageScanUploadExcelComponent,
    CompanyDispatchListComponent,
    CompanyDispatchDetailComponent,
    GatepassAddComponent,
    AttendanceComponent,
    CheckinComponent,
    AttendenceDetailComponent,
    ExpenseComponent,
    CheckinDetailComponent,
    ExpenseModalComponent,
    ExpenseDetailComponent,
    ImageModuleComponent,
    ExpenseEditComponent,
    AllowanceMasterComponent,
    PrintScannedListComponent,
    CustomerCategoryComponent,
    ManualHitFileDownloadComponent,
    // ToastComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxMatSelectSearchModule,
    ChartsModule,

    ],
  exports:
  [
    BrowserAnimationsModule,
    MatDatepickerModule,
  ],
  entryComponents: 
  [
    PayNowModelComponent,
    MechanicEditComponent,
    DistributorEditComponent,
    RetailerEditComponent,
    CouponStatusModelComponent,
    Ng2SearchPipeModule,
    GatepassAddComponent
  ],

  providers: [
    {provide: DateAdapter, useClass: CustomdatePipe},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
    MatDatepickerModule,
    DatabaseService,
    DialogService,
    BnNgIdleService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule  { 
}