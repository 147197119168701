import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-company-stock-list',
  templateUrl: './company-stock-list.component.html',
})
export class CompanyStockListComponent implements OnInit {

  skeleton: any = [];
  date: any;
  loader: any;
  stockData: any = [];
  searchData: any = {};
  filterSearch: any = {};
  pageNumber: any;
  paginationData: any = {};
  total: any;
  matTabValue: any;
  status: any;
  totalProduct: any;
  excelData: any = [];
  last_sync_date:any;
  total_stock:any;
  total_value:any;
  data_val:any;
  conditionedApi:any ;

  constructor(
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public dialog2: MatDialog,
  ) { 
    this.skeleton = new Array(10);
    this.date = new Date();
    this.pageNumber = 1;
    this.loader = 1;
    this.data_val = this.apiHit.userLoginData.data
    console.log(this.data_val);
    

  }

  ngOnInit(): void {
    this.stockList(1);
  }


  refreshData() {

    this.filterSearch = {};
    this.stockList(1);

  }

  stockList(target) {

    if (target == 1) {
      this.pageNumber = 1;
    }
    this.loader = 1;
    // if (target != 3) {
    //   this.loader = 1;
      this.stockData = [];
    // }
    // this.total = 1;
    this.paginationData.search = this.filterSearch;
    // this.paginationData.pagelimit = 50;
    // this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
    if(this.data_val.login_type == 'distributor'){
      
      this.conditionedApi = "Distributor/getDistributerPartslistWeb"
      this.paginationData.dr_id = this.data_val.id
    }else{
      this.conditionedApi = "app/AppOrderController/ask_company_part_stock_list"
    }

    console.log(this.filterSearch);

    this.apiHit.PostRequest(this.paginationData, this.conditionedApi).subscribe((result => {

      console.log(result);
      this.stockData = result['data'];
      this.totalProduct = result['total_rows'];
      if(this.data_val.login_type != 'distributor'){
      this.last_sync_date=result['last_sync_date'];
      this.total_stock=result['total_stock'];
      this.total_value=result['total_value'];
      }
      setTimeout(() => {
        this.loader = '';
      }, 700);

    }));
  }


  onChangePageNumber(type) {

    setTimeout(() => {

      if (type == 'previous') {

        if (this.pageNumber > 1) {

          this.pageNumber -= 1;
          this.stockList(2);
        }

      }
      else if (type == 'next') {

        if (this.total > this.pageNumber) {

          this.pageNumber += 1;
          this.stockList(2);
        }

      }
      else if (type = 'current') {

        if (this.pageNumber > this.total) {

          this.pageNumber = this.total;
          this.stockList(2);
        }
        else if (this.pageNumber == 0) {

          this.pageNumber = 1;
          this.stockList(2);


        }
        else if (this.pageNumber) {

          this.stockList(2);

        }

      }

    },
      100);
  }


}