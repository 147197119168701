import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html'
})
export class UserDetailComponent implements OnInit {

    loader : any;
    skeleton:any = [];
    userDetailData : any;
    myModel = true;
    moduleData : any;
    stateDistrictList: any;
    stateData : any = [];
    module_data: any;
    

    constructor(

      private apiHit : DatabaseService,
      private _location: Location,
      public dialog2 : DialogService,
      public activateRoute : ActivatedRoute,

    ) {

        console.log(activateRoute['params']['_value']);

     }

    ngOnInit(): void {

        this.userDetail();
        this.skeleton = new Array(8);
    }


    //Get User Detail Through API Function Start

    userDetail(){

      this.loader = 1;

      this.apiHit.PostRequest( this.activateRoute['params']['_value'], "User/get_user_detail").subscribe((result => {

          console.log(result);
          
          this.userDetailData     = result['userData'];
          this.moduleData         = result['userData']['moduleData'];
          this.module_data        = result['userData']['module_data'];
          this.stateDistrictList  = result['userData']['asm_zsm_stateList']
        
          if(this.stateDistrictList){

              for(let i=0; i < this.stateDistrictList.length; i++){
                if(this.stateData.indexOf(this.stateDistrictList[i].state_name) == -1)
                  this.stateData.push(this.stateDistrictList[i].state_name)
              }

              // let isExist = this.stateDistrictList.findIndex(row=>row.state_name== row.state_name)
              // if(isExist !=- 1){
              //     console.log('state splice function');
              //     this.stateDistrictList.splice(isExist,1)
              // }
              console.log(this.stateDistrictList);
              console.log(this.stateData);
            
          }
          
          setTimeout(() => {
    
              this.loader = '';
    
          }, 700);

      }));
      

    }
    //Get User Detail Through API Function End

    // Back Button Function  Start

    backClicked() {

      this._location.back();

    }
    // Back Button Function  End

}
