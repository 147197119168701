<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name">
                    <h2>Order Detail</h2>
                    <p>{{orderDetail.dr_name}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <ng-template mat-tab-label *ngIf="loader">
                <div class="sk-loading">&nbsp;</div>
            </ng-template>
            <ng-template mat-tab-label *ngIf="!loader">
                <mat-icon class="example-tab-icon">info</mat-icon>
                Detail
            </ng-template>
            <div class="row">
                <div class="col s12 m12">
                    <div class="cs-column" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Pending Order Information</h2>
                        </div>

                        <div class="cs-form ">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields">
                                        <div class="cs-box">
                                            <span>Order Date</span>
                                            <p>{{orderDetail.Document_Date}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>So Number</span>
                                            <p>{{orderDetail.Sales_Document ? orderDetail.Sales_Document:'N/A'}}
                                            </p>
                                        </div>
                                        <div class="cs-box" *ngIf="data_val?.login_type != 'distributor'">
                                            <span>Distributor Name</span>
                                            <p>{{orderDetail.Sold_to_party_name ?
                                                orderDetail.Sold_to_party_name:'N/A'}}- {{orderDetail.Sold_to_party ?
                                                    orderDetail.Sold_to_party:'N/A'}}</p>
                                        </div>
                                        <div class="cs-box">
                                            <span>Total Value</span>
                                            <p>₹ {{orderDetail.Net_Total_Value ?
                                                orderDetail.Net_Total_Value:'0'}}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>


                    <!-- Skeleton Loading Data Start -->
                    <div class="cs-column" *ngIf="loader">
                        <div class="cs-head sk-loading">
                            <h2>&nbsp;</h2>
                        </div>
                        <div class="cs-form">
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-mb0">
                                <div class="col s12">
                                    <div class="fields sk-loading">
                                        <div class="cs-box" *ngFor="let row of skData">
                                            <span>&nbsp;</span>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Skeleton Loading Data End -->


                    <div class="cs-column mt10" *ngIf="!loader">
                        <div class="cs-head">
                            <h2>Item Information</h2>
                        </div>

                        <div class="cs-table">
                            <figure class="table-head">
                                <section class="th_cell">
                                    <main>
                                        <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                        <div class="div_cell mw230">Product Description</div>
                                        <div class="div_cell mmw150 ">Material Code</div>
                                        <div class="div_cell mmw150 af-bdr-right">Order Qty</div>
                                        <div class="div_cell mmw100 ">Net Price</div>
                                        <div class="div_cell mmw100 ">Net Value</div>
                                        <div class="div_cell mmw100 ">Dispatch Qty</div>
                                        <div class="div_cell mmw100 ">Pending Qty</div>
                                        <!-- <div class="div_cell mw120">Confirmed Qty</div>
                                        <div class="div_cell mmw150 ">Base Unit Of Measurement</div> -->
                                    </main>
                                </section>
                            </figure>
                            <!-- Table Head End -->

                            <section class="td_cell">
                                <main *ngFor="let data of orderDetail.items; let i=index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mw230">{{data.Description ?
                                        data.Description:'N/A'}}</div>
                                    <div class="div_cell mmw150">{{data.Material ?
                                        data.Material:'N/A'}}</div>
                                    <div class="div_cell mmw150">{{data.Order_Quantity ?
                                        data.Order_Quantity:'N/A'}} {{data.Base_Unit_of_Measure ?
                                            data.Base_Unit_of_Measure:'N/A'}}</div>
                                    <div class="div_cell mmw100">₹ {{data.Net_price ?
                                        data.Net_price:'N/A'}}</div>
                                    <div class="div_cell mmw100">₹ {{data.Net_Total_Value ?
                                        data.Net_Total_Value:'0'}}</div>
                                    <div class="div_cell mmw100">{{data.Dispatch_qty ?
                                            data.Dispatch_qty:'0'}} {{data.Base_Unit_of_Measure ?
                                                data.Base_Unit_of_Measure:'N/A'}}</div>
                                    <div class="div_cell mmw100">{{data.Pending_qty ?
                                        data.Pending_qty:'0'}} {{data.Base_Unit_of_Measure ?
                                            data.Base_Unit_of_Measure:'N/A'}}</div>
                                    <!-- <div class="div_cell mmw150">{{data.Base_Unit_of_Measure ?
                                        data.Base_Unit_of_Measure:'N/A'}}</div> -->
                                    <!-- <div class="div_cell mw120">{{data.Confirmed_Quantity ? data.Confirmed_Quantity:'N/A'}}</div> -->

                                </main>
                                <!-- Loop Data -->
                            </section>
                        </div>
                    </div>
                </div>
            
            </div>



        </div>
    </div>
</div>