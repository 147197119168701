import { Injectable, OnInit } from '@angular/core';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class DialogService implements OnInit{


  constructor() { }

  ngOnInit() {
  }

  saveData(title: any) {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    })
  }

  success(title: any, msg: any) {
    Swal.fire(
      title + ' !',
      msg + '.',
      'success'
    )
  }

  error(type: any, msg: any) {
    Swal.fire(
      type ? type : 'Oops',
      msg,
      'error'
    )
  }

  delete(msg:any){

      return Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
            )
            return true;
        } else {
            return false;
        }
      })
  }
  reset(msg:any){

      return Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes, reset this user's device",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            '',
            'Device has been reset.',
            'success'
            )
            return true;
        } else {
            return false;
        }
      })
  }


  alert(type:any){

    return Swal.fire({
      title: type + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
          return true;
      } else {
          return false;
      }
    })
}


  warning(type: any, msg: any) {
    Swal.fire(
      type ? type : 'Oops',
      msg,
      'warning'
    )
  }

  // error(msg:any){
  //   Swal.fire({
  //     type: 'error',
  //     title: 'Oops...',
  //     text: msg,
  //     // footer: '<a href>Why do I have this issue?</a>'
  //   })
  // }



  save_distributor(msg:any) {
    return Swal.fire({
       title: 'Are you sure?',
      
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, save it!',
     }).then((result) => {
       if (result.isConfirmed) {
        //  Swal.fire(
        //    'Success!',
           
        //    'success'
        //  )
         return true;
       } else {
         return false;
       }
     })
   }

   save_Retailer(msg:any) {
    return Swal.fire({
       title: 'Are you sure?',
      
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, save it!',
     }).then((result) => {
       if (result.isConfirmed) {
        //  Swal.fire(
        //    'Success!',
           
        //    'success'
        //  )
         return true;
       } else {
         return false;
       }
     })
   }



   save_user(msg:any) {
    return Swal.fire({
       title: 'Are you sure?',
      
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, save it!',
     }).then((result) => {
       if (result.isConfirmed) {
        //  Swal.fire(
        //    'Success!',
           
        //    'success'
        //  )
         return true;
       } else {
         return false;
       }
     })
   }

   confirmAlert(msg:any) {
    return Swal.fire({
       title: msg,
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes',
     }).then((result) => {
       if (result.isConfirmed) {
        //  Swal.fire(
        //    'Success!',
           
        //    'success'
        //  )
         return true;
       } else {
         return false;
       }
     })
   }

} 