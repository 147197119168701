import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myfilter',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {

        // console.log(items);
        // console.log(filter);

        if (!items || !filter) {
            return items;
        }
        
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        if(filter['type'] == 'status'){
            return items.filter(item => item.status.indexOf(filter['status']) !== -1);
        }
        else if(filter['type'] == 'ASM_status'){
            return items.filter(item => item.ASM_status.indexOf(filter['status']) !== -1);
        }
        else if(filter['type'] == 'ZSM_status'){
            return items.filter(item => item.ZSM_status.indexOf(filter['status']) !== -1);
        }
    }
}