import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseService } from 'src/app/services/service';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';


@Component({

    selector: 'app-distributor-edit',
    templateUrl: './distributor-edit.component.html'

})

export class DistributorEditComponent implements OnInit {

    EditDistributorForm: FormGroup;
    AddContactForm: FormGroup;
    EditContactForm: FormGroup;
    EditStockAlert: FormGroup;
    AssignBrand: FormGroup;
    AssignedSalesUser: FormGroup;
    uploadExcelFile: FormGroup;
    dataValue: any;
    statusValue: any;
    date: any;
    updateDataDetails: any;
    stateData: any = [];
    districtData: any = [];
    loader: any;
    minDate: Date;
    maxDate: Date;
    stataData: any;
    selectedDistrict: any;
    categoryName: any = [];
    salesUserList: any = [];
    category:any=[]

    constructor(

        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialog,
        public fb: FormBuilder,
        public apiHit: DatabaseService,
        public dialog2: DialogService,
        public activateRoute: ActivatedRoute

    ) {


        const currentYear = new Date().getFullYear();
        this.maxDate = new Date(currentYear - 15, 11, 31);

        console.log(data);
        this.updateDataDetails = data['data'];
        this.dataValue = this.data['target'];
        this.date = new Date();


    }


    ngOnInit(): void {

        if (this.dataValue == 1) {

            this.EditDistributorForm = this.fb.group({

                firmName: [this.updateDataDetails['dr_name'], [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                firstName: [this.updateDataDetails['contact_name'], [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
                mobileNo: [this.updateDataDetails['mobile_no'], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                email: [this.updateDataDetails['email_id'], [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                DOB: [this.updateDataDetails['date_birth'],],
                anniversary: [this.updateDataDetails['date_anniversary'],],
                gstNo: [this.updateDataDetails['gst_no'], [Validators.required, Validators.minLength(15), Validators.maxLength(15)]],
                // , Validators.pattern("^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[0-9]{1}[A-Za-z]{1}[0-9]{1}$")
                panNo: [this.updateDataDetails['pan_no'], [Validators.minLength(10), Validators.maxLength(10)]],
                // , Validators.pattern("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$")
                drCode: [this.updateDataDetails['dr_code'], [Validators.required]],

                category: [this.updateDataDetails['category'],],
                pinNo: [this.updateDataDetails['pincode'], [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
                state: [this.updateDataDetails['state_name'], [Validators.required]],
                district: [this.updateDataDetails['district_name'], [Validators.required]],
                city: [this.updateDataDetails['city_name'], [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],

                address: [this.updateDataDetails['street'], [Validators.minLength(3)]],
                order_status_remark: [this.updateDataDetails['order_status_remark'], [Validators.minLength(3)]],
                order_status: [this.updateDataDetails['order_status'], [Validators.required]],
                
                id: [this.updateDataDetails['id'], [Validators.required]],
                dr_type: [1, [Validators.required]],

                credit_limit: [this.updateDataDetails['credit_limit'],],
                outstanding: [this.updateDataDetails['outstanding'],],
                username: [this.updateDataDetails['username'],],
                password: [this.updateDataDetails['password'],],

                searchCtrl: [''],
                
            });
            console.log(this.EditDistributorForm )
            console.log(this.statusValue)

            this.stateList();
            this.categoryList()
            this.districtList(this.updateDataDetails['state_name']);
            this.minDateSelect();
            // this.setDobAndAnniversary();

        }

        else if (this.dataValue == 2) {


            this.AddContactForm = this.fb.group({

                cPersonName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                cPersonMobileNo: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                cPersonEmail: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                id: [this.updateDataDetails['id'], [Validators.required]],

            });

        }

        else if (this.dataValue == 3) {


            this.EditContactForm = this.fb.group({

                updatePersonName: [this.updateDataDetails['contact_person'], [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
                updatePersonMobileNo: [this.updateDataDetails['contact_1'], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
                updatePersonEmail: [this.updateDataDetails['contact_email'], [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")]],
                id: [this.updateDataDetails['id'], [Validators.required]],

            });

        }

        else if (this.dataValue == 4) {

            this.EditStockAlert = this.fb.group({

                uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
                part_id: [this.updateDataDetails['row_id'], [Validators.required]],
                default_stock: [this.updateDataDetails['default_stock'], [Validators.required]],

            });

        }

        else if (this.dataValue == 5) {

            this.AssignBrand = this.fb.group({

                uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
                brands: ['', [Validators.required]],
                dr_id: [this.updateDataDetails['details']['id'], [Validators.required]],

            });

            this.getPartBrand();

        }
        else if (this.dataValue == 7) { 

            this.AssignedSalesUser = this.fb.group({

                uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
                salesUser: ['', [Validators.required]],
                dr_id: [this.updateDataDetails['details']['id'], [Validators.required]],

            });

            this.getSalesUsers();

        }

        // else if (this.dataValue == 6) {

        //     this.uploadExcelFile = this.fb.group({

        //         uid: [this.apiHit.userLoginData.data.id, [Validators.required]],
        //         dr_id: [this.activateRoute['params']['_value']['id'], [Validators.required]],

        //     });
        // }
        
    }


    districtList1() {

        console.log(this.EditDistributorForm.value);



        this.apiHit.PostRequest({ 'state': this.EditDistributorForm.value.state }, "Distributor/district_list").subscribe((result => {

            console.log(result);

            this.districtData = result['district_list']['district_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));


    }


    //Part Code Api Start
    getPartBrand() {

        // this.loader = 1;
        this.categoryName = [];

        this.apiHit.PostRequest({ 'brand': '' }, "CouponCode/partCategory").subscribe((result => {

            console.log(result);
            this.categoryName = result['partCategory'];
            // distributer_assigned_brands
            console.log(this.updateDataDetails['distributer_assigned_brands']);
            for (let i = 0; i < this.updateDataDetails['distributer_assigned_brands'].length; i++) {
                for (let j = 0; j < this.categoryName.length; j++) {
                    if (this.categoryName[j]['brand'] == this.updateDataDetails['distributer_assigned_brands'][i]['brands']) {
                        this.categoryName.splice(j, 1);
                    }
                }
            }

            setTimeout(() => {
                this.loader = '';
            }, 1100);

        }));

    }
    //Part Code Api End
    //Distributor Api Start
    getSalesUsers() {

        // this.loader = 1;
        this.salesUserList = [];

        this.apiHit.PostRequest({}, "Distributor/salesUserList").subscribe((result => {

            console.log(result);
            this.salesUserList = result['salesUserList'];
            // distributer_assigned_brands
            console.log(this.updateDataDetails['assign_sales_users']);
            for (let i = 0; i < this.updateDataDetails['assign_sales_users'].length; i++) {
                for (let j = 0; j < this.salesUserList.length; j++) {
                    console.log(this.salesUserList[j]['id'])
                    console.log(this.updateDataDetails['assign_sales_users'][i]['id'])
                    if (this.salesUserList[j]['id'] == this.updateDataDetails['assign_sales_users'][i]['assigned_to']) {
                        this.salesUserList.splice(j, 1);
                    }
                }
            }

            setTimeout(() => {
                this.loader = '';
            }, 1100);

        }));

    }
    //Distributor Api End


    addAssignBrand() {

        console.log(this.AssignBrand);

        this.dialog2.save_distributor('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(this.AssignBrand.value, "Distributor/assign_brnds_to_distributer").subscribe((result => {

                    console.log(result);

                    if (result['result'] == "success") {

                        this.dialog2.success('Success', 'Brand Sucessfully Assigned');
                        this.dialogRef.closeAll();
                    }
                    else if (result['result'] == "DataAlreadyExsit") {
                        this.dialog2.error('error', 'Brand Already Assigned');
                    }
                    else {
                        this.dialog2.error('error', 'Something Went Wrong');
                    }

                }));
            };
        })
    }

    addAssignedSalesUser() {

        console.log(this.AssignedSalesUser);

        this.dialog2.save_distributor('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(this.AssignedSalesUser.value, "Distributor/drUserAssign").subscribe((result => {

                    console.log(result);

                    if (result['status'] == "success") {

                        this.dialog2.success('Success', 'Sales User Sucessfully Assigned');
                        this.dialogRef.closeAll();
                    }
                    else {
                        this.dialog2.error('error', 'Something Went Wrong');
                    }

                }));
            };
        })
    }


    //Min Date Select For Anniversary Function Start
    minDateSelect() {

        this.minDate = this.EditDistributorForm.value.DOB;

    }
    //Min Date Select For Anniversary Function End



    //Add Contact Person Through API Function Start


    addContact() {

        this.AddContactForm.controls.id.setValue((this.updateDataDetails['id']));

        this.dialog2.save_distributor('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(this.AddContactForm.value, "Distributor/add_contact_person").subscribe((result => {

                    console.log(result);
                    if (result['add_contact_person'] == "success") {
                        this.dialog2.success('Success', ' Contact Person Sucessfully Added');
                        this.dialogRef.closeAll();
                    }
                    this.dialog2.error('error', 'Something Went Wrong');

                }));
            };
        })

    }
    //Add Contact Person Through API Function End



    enterPin(formData) {

        console.log(formData.controls.pinNo.value);

        if (formData.controls.pinNo.value.length == 6) {

            this.apiHit.PostRequest1({

                'pincode': formData.controls.pinNo.value

            }, "AppMechanicDetails/getPincodeDetails").subscribe((result => {


                console.log(result);

                setTimeout(() => {
                    this.selectedDistrict = result['pincodeDetails'].district;

                }, 1000);


                this.stataData = result['pincodeDetails'].state;
                console.log(this.stataData);



                console.log(this.selectedDistrict);

                this.districtList(this.stataData)


            }
            ));

        }
    }


    //Update Contact Person Through API Function Start

    updateStockALert() {

        console.log(this.EditStockAlert.value);

        // return

        this.apiHit.PostRequest(this.EditStockAlert.value, "Distributor/updateDistributorStockAlert").subscribe((result => {

            console.log(result);

            if (result['status'] == "success") {

                this.dialog2.success('Success', 'Alert Stock Sucessfully Updated');

                this.dialogRef.closeAll();

            } else {

                this.dialog2.error('error', 'Something Went Wrong');

            }


        }));

    }
    //Update Contact Person Through API Function End


    //Update Contact Person Through API Function Start

    updateContact() {

        console.log(this.EditContactForm.value);

        // return

        this.apiHit.PostRequest(this.EditContactForm.value, "Distributor/update_contact_person").subscribe((result => {

            console.log(result);

            if (result['update_contact_person'] == "success") {

                this.dialog2.success('Success', ' Contact Person Sucessfully Updated');

                this.dialogRef.closeAll();

            } else {

                this.dialog2.error('error', 'error');

            }


        }));

    }
    //Update Contact Person Through API Function End




    //State List Fetch From Database Through API Function  Start

    stateList() {

        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {

            this.stateData = result['state_list']['state_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //State List Fetch From Database Through API Function  End



    //District List Fetch From Database Through API  Start

    districtList(district) {

        this.loader = 1;

        this.apiHit.PostRequest({ 'state': district }, "Distributor/district_list").subscribe((result => {

            this.districtData = result['district_list']['district_name'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //District List Fetch From Database Through API  End



    checkDrCodeDuplicacy() {

        this.loader = 1;
        console.log(this.EditDistributorForm.value.id);


        this.dialog2.save_distributor('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ 'id': this.EditDistributorForm.value.id, "drCode": this.EditDistributorForm.value.drCode }, "Distributor/check_dr_code").subscribe((result => {

                    console.log(result);

                    if (result['exists'] == 1) {

                        this.dialog2.error('error', 'Dr Code Already Exists');
                        this.EditDistributorForm.controls.drCode.setValue('');
                        return;

                    }
                    else {

                        // return
                        this.updateDistributer();

                    }

                    setTimeout(() => {

                        this.loader = '';

                    }, 700);

                }));
            };
        })

    }



    //Update Distributor Detail Through API Function Start

    updateDistributer() {

        if (this.EditDistributorForm.controls.DOB.value) {

            this.EditDistributorForm.controls.DOB.setValue(moment(this.EditDistributorForm.controls.DOB.value).format('YYYY-MM-DD'));

        }

        if (this.EditDistributorForm.controls.anniversary.value) {

            this.EditDistributorForm.controls.anniversary.setValue(moment(this.EditDistributorForm.controls.anniversary.value).format('YYYY-MM-DD'));

        }

        console.log(this.EditDistributorForm);
        console.log(this.EditDistributorForm.value);
        // return

        this.apiHit.PostRequest({ 'changeData': this.EditDistributorForm.value }, "Distributor/update_dealer").subscribe((result => {

            if (result['update_dealer'] == 'success') {

                console.log('in if consition');
                this.dialog2.success('Success', ' Distributor update sucessfully ');
                this.dialogRef.closeAll();

            } else {

                console.log('in else condtion');
                this.dialog2.error('error', 'error');

            }

        }));

    }
    //Update Distributor Detail Through API Function Start



    //Check Mobile No Duplicacy Function Start

    checkMobileNoDuplicacy() {

        if (this.EditDistributorForm.value.mobileNo.length == 10) {

            this.apiHit.PostRequest({

                "type": 'dr',
                'id': this.EditDistributorForm.value.id,
                "mobileNo": this.EditDistributorForm.value.mobileNo
            }, "Distributor/check_user").subscribe((result => {

                if (result['exists'] == 1) {

                    this.EditDistributorForm.controls.mobileNo.setValue('');
                    this.dialog2.error('error', 'Mobile Already Exists');

                }

                setTimeout(() => {

                    this.loader = '';

                }, 700);

            }));

        }

    }
    //Check Mobile No Duplicacy Function End




    // KeyPress Function Only For Write Number Function Start

    OnlyNumber(event: any) {

        const pattern = /[0-9\'']/;
        let inputChar = String.fromCharCode(event.charCode);

        if (event.keyCode != 8 && !pattern.test(inputChar)) {

            event.preventDefault();

        }

    }
    // KeyPress Function Only For Write Number Function End


    UploadExcel() {

        console.log(this.apiHit.userLoginData.data.id);
        let input = new FormData();
        input.append('file', this.fileToUpload);
        input.forEach((value, key) => {
            console.log(key + " " + value)
        });

        console.log(JSON.stringify(input));
        console.log(this.fileToUpload);

        this.apiHit.FileUpload(input, "Distributor/importDistributerStock").subscribe((result => {

            console.log(result);
            if (result['msg'] == 'Success') {
                this.dialog2.success('Success', 'Data Sucessfully Uploaded');
                this.dialogRef.closeAll();
            }
            else {
                this.dialog2.error('Error', 'Something Went Wrong. Please Check Your Format');
            }
            // return;

        }));

    }


    fileToUpload: File = null;
    handleFileInput(files: FileList) {
        this.fileToUpload = files.item(0);
        console.log(files.item(0));
        // this.uploadFile2('');
    }

    // Category list Api Start

    categoryList() {

        this.loader = 1;

        this.apiHit.PostRequest('', "Distributor/distributer_category").subscribe((result => {

            this.category = result['distributor_category']['data'];

            console.log(this.category)
            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    // Category list Api End


}