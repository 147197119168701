import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';

@Component({
  selector: 'app-target-excel-upload',
  templateUrl: './target-excel-upload.component.html',
})
export class TargetExcelUploadComponent implements OnInit {

  uploadExcelFile: FormGroup;
  from:any

  constructor(


    @Inject(MAT_DIALOG_DATA) public data,
    public fb: FormBuilder,
    public apiHit: DatabaseService,
    public dialog: DialogService,
    public router: Router,
    private dialogRef: MatDialogRef<TargetExcelUploadComponent>

  ) {
    dialogRef.disableClose = true;
  }
  
  ngOnInit(): void {
    this.from = this.data['from'];

  }


  UploadExcel() {
    let input = new FormData();
    input.append('file', this.fileToUpload);
    input.forEach((value, key) => {
      console.log(key + " " + value)
    });

    console.log(JSON.stringify(input));
    console.log(this.fileToUpload);

    this.apiHit.FileUpload(input, "Distributor/IMPORT_TARGET").subscribe((result => {

      console.log(result);
      if (result['status'] == 'Success') {
        this.dialog.success('Success', 'Data Sucessfully Uploaded');
        this.dialogRef.close();
      }
      else {
        this.dialog.error('Error', 'Something Went Wrong. Please Check Your Format');
      }
      // return;


    }));

  }

  fileToUpload: File = null;
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(files.item(0));

    // this.uploadFile2('');
  }

  UploadPartmasterExcel() {
    let input = new FormData();
    input.append('file', this.fileToUpload);
    input.forEach((value, key) => {
      console.log(key + " " + value)
    });

    console.log(JSON.stringify(input));
    console.log(this.fileToUpload);

    this.apiHit.FileUpload(input, "Distributor/updatePartMaster").subscribe((result => {

      console.log(result);
      if (result['status'] == 'Success') {
        this.dialog.success('Success', 'Data Updated Successfully...');
        this.dialogRef.close();
      }
      else {
        this.dialog.error('Error', 'Something Went Wrong. Please Check Your Format');
      }
      // return;


    }));

  }

  getDownloadURL(){
    this.apiHit.PostRequest({ }, "Distributor/getPartsSampleFile").subscribe((result => {

      console.log(result);
      if (result['msg']=="Success") {
        window.open(this.apiHit.downloadURL+'uploads/parts_sample_file.csv')
        
      }
      else{
        this.dialog.error('Error', 'Something Went Wrong. Please contact to support');

      }
  
    }));
  }
}
