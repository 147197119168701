import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { GatepassAddComponent } from '../gatepass-add/gatepass-add.component';
import { DialogService } from 'src/app/services/dialog.service';


@Component({
    selector: 'app-company-dispatch-list',
    templateUrl: './company-dispatch-list.component.html'
})
export class CompanyDispatchListComponent implements OnInit {
    date: any;
    loader: boolean = false;
    paginationData: any = {};
    scannedCouponCount: any;
    pageNumber: any;
    total: any;
    matTabValue: any;
    
    active_tab = 'Pending Dispatch';
    excelLoader: boolean = false;
    total_list: any
    value: any = {};
    distributor_list: any = [];
    count: any;
    total_page: any;
    sr_no: any = 0;
    pageCount: any;
    page_limit: any = 100;
    start: any = 0;
    data: any = [];
    type: any;
    filter: any = {};
    login_data: any = [];
    login_dr_id: any;
    skelton: any = {}
    today_date: Date;
    add: any = {};
    assign_login_data2: any = [];
    all_count: any = {}
    assign_login_data: any = [];
    loginData: any;
    downurl:any ='';
    gatePassAssign:any =[];
    gatePassUnassign:any =[];
    pendingDispatchCount:any;
    dispatchCount:any;
    orderSearch: any = {};
    status: any;
    stateData: any = [];



    
    
    constructor( 
        public service: DatabaseService,
        public apiHit: DatabaseService,
        public dialog: MatDialog, 
        public dialog2: DialogService
        ) {
        this.loginData = this.service.userLoginData;
        console.log( this.loginData);

        this.status = 'All'
        this.date = new Date();
        this.matTabValue = 1;
        this.pageNumber = 1;
        this.billData('',1);
        this.stateList();

        
        //
        
    }
    
    
    ngOnInit(): void {
        this.paginationData = this.service.getFilterData('coupon-list');
        // this.paginationData = JSON.parse(localStorage.getItem('filterData'));
        this.filter = this.paginationData?.searchData ? this.paginationData.searchData : this.filter;
        this.pageNumber = this.paginationData?.pageNumber ? this.paginationData.pageNumber : this.pageNumber;
        // this.matTabValue = this.paginationData?.matTabValue ? this.paginationData?.matTabValue : this.matTabValue;
        
    this.billData('',this.paginationData?.target ? this.paginationData.target : 1);
        
    }
    
     
    
    
    billData(action: any = '',target) {
        this.loader = true;
        
        if (action == "refresh") {
            this.filter = {};
            this.distributor_list = [];
            this.start = 0;
        }
        if (this.pageNumber > this.total_page) {
            this.pageNumber = this.total_page;
            this.start = this.pageCount - this.page_limit;
        }
        
        if (this.start < 0) {
            this.start = 0;
        }
        
        
        if (target != 3) {
            this.distributor_list = [];
          }
          if (target == 1) {
            this.pageNumber = 1;
          }
        if(this.matTabValue == 1){
            this.filter.active_tab = 'Pending Dispatch';
        }
        if(this.matTabValue == 2){
            this.filter.active_tab = 'Dispatched';
        }
        if(this.matTabValue == 3){
            this.filter.active_tab = 'Gate Pass';
        }
        if (this.orderSearch.date_from && this.orderSearch.date_to) {
            this.orderSearch.date_from = moment(this.orderSearch.date_from).format('YYYY-MM-DD');
            this.orderSearch.date_to = moment(this.orderSearch.date_to).format('YYYY-MM-DD');
          }
        this.total = 1;
        this.paginationData.search = this.orderSearch;
        this.paginationData.pagelimit = 50;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
        this.paginationData.filter = this.filter;
        this.paginationData.target = target;
        this.paginationData.pageNumber = this.pageNumber;
        this.paginationData.matTabValue = this.matTabValue;
        this.service.PostRequest(this.paginationData, "Dispatch/tallyInvoiceCreditBillingListing")
        .subscribe((result => {
            if(result['statusCode']==200){
                this.distributor_list = result['credit_billing_list'];
                this.dispatchCount = result['dispatch'];
                this.count = result['count'];
                this.total = result['totalPage'];
                this.pendingDispatchCount = result['pendingDispatch'];
                this.loader = false;
                if(this.active_tab == 'Pending Dispatch'){
                    this.pageCount = result['pendingDispatch'];
                }
                if(this.active_tab == 'Dispatched'){
                    this.pageCount = result['dispatch'];
                }
                // if (this.pageNumber > this.total_page) {
                //     this.pageNumber = this.total_page;
                //     this.start = this.pageCount - this.page_limit;
                // }
                // else {
                //     this.pageNumber = Math.ceil(this.start / this.page_limit) + 1;
                // }
                // this.total_page = Math.ceil(this.pageCount / this.page_limit);
                // this.sr_no = this.pageNumber - 1;
                // this.sr_no = this.sr_no * this.page_limit;
                
            }else{
                this.loader = false;
                this.dialog2.error('', result['statusMsg']);
            }
        }))
    }
    
    clearDateFilter() {
        this.orderSearch.date_from = '';
        this.orderSearch.date_to = '';
        if (this.matTabValue == 1) {
            this.billData(1, this.status)
        }
        else if (this.matTabValue == 2) {
            this.billData(1, this.status)
        }
        else if (this.matTabValue == 3) {
            this.getGeatePass(1,)
        }
      }
      stateList() {

        this.apiHit.PostRequest('', "Distributor/state_list").subscribe((result => {
    
          this.stateData = result['state_list']['state_name'];
    
        }));
    
      }
    gate_pass_list:any =[];
    getGeatePass(action: any = '') {
        this.start = 0;
        if (action == "refresh") {
            this.filter = {};
            this.gate_pass_list = [];
        }
        // if (this.pageNumber > this.total_page) {
        //     this.pageNumber = this.total_page;
        //     this.start = this.pageCount - this.page_limit;
        // }
        
        // if (this.start < 0) {
        //     this.start = 0;
        // }
        this.loader = true;
        
        
        if(this.matTabValue == 1){
            this.filter.active_tab = 'Pending Dispatch';
        }
        if(this.matTabValue == 2){
            this.filter.active_tab = 'Dispatched';
        }
        if(this.matTabValue == 3){
            this.filter.active_tab = 'Gate Pass';
        }
        if (this.orderSearch.date_from && this.orderSearch.date_to) {
            this.orderSearch.date_from = moment(this.orderSearch.date_from).format('YYYY-MM-DD');
            this.orderSearch.date_to = moment(this.orderSearch.date_to).format('YYYY-MM-DD');
          }
        this.paginationData.search = this.orderSearch;
        this.paginationData.pagelimit = 50;
        this.paginationData.start = (this.pageNumber - 1) * this.paginationData.pagelimit;
        this.paginationData.filter = this.filter;
        this.paginationData.pageNumber = this.pageNumber;
        this.paginationData.matTabValue = this.matTabValue;
        // 'branch_code':this.loginData.branch_code,
        this.service.PostRequest(this.paginationData, "Dispatch/getGatePassList")
        .subscribe((result => {
            if(result['statusCode']==200){
                this.gate_pass_list = (result['result']);
                console.log(this.gate_pass_list);
                
                this.loader = false;
                this.count = result['count'];
                this.total = result['totalPage'];
                this.total_list = (result['overall_total_sum']);
                // if (this.pageNumber > this.total_page) {
                //     this.pageNumber = this.total_page;
                //     this.start = this.pageCount - this.page_limit;
                // }
                // else {
                //     this.pageNumber = Math.ceil(this.start / this.page_limit) + 1;
                // } 
                // this.total_page = Math.ceil(this.pageCount / this.page_limit);
                // this.sr_no = this.pageNumber - 1;
                // this.sr_no = this.sr_no * this.page_limit;
            }else{
                this.loader = false;
                this.dialog2.error('', result['statusMsg']);
            }
        }))
    }
    
    
    select_item(event,indx)
    {        
        if(event.checked)
        {
            this.gatePassAssign.push(this.distributor_list[indx]);
            let idx = this.gatePassUnassign.findIndex(row => row.id == this.distributor_list[indx].id);
            this.gatePassUnassign.splice(idx,1);
        }
        else
        {
            let idx = this.gatePassAssign.findIndex(row => row.id == this.distributor_list[indx].id);
            this.gatePassAssign.splice(idx,1);
            this.gatePassUnassign.push(this.distributor_list[indx]);
        }
    }
    
    
    public onDate(event, type): void {
        console.log(type);
        
        console.log('in available function');

        if(type == 'date_created'){ 
            this.filter.date_created = moment(event.value).format('YYYY-MM-DD');
            // this.billData('',1);
        }
        if(type == 'bill_date'){
            this.filter.Bill_Date = moment(event.value).format('YYYY-MM-DD');
            // this.billData('',1);
        }
        if(type == 'invoice_date'){
            this.filter.invoice_date = moment(event.value).format('YYYY-MM-DD');
            this.getGeatePass(1);
        }
        if(type == 'order_date'){
            this.filter.order_date = moment(event.value).format('YYYY-MM-DD');
            this.getGeatePass(1);

        }
        if(type == 'date_created'){
            this.filter.date_created = moment(event.value).format('YYYY-MM-DD');
            this.getGeatePass(1);

        }
        if(this.matTabValue == 1||this.matTabValue == 2){
            this.pageNumber = 1;
            this.billData('',1)
        }
        if(this.matTabValue == 3){
            this.pageNumber = 1;
            this.getGeatePass('')
        }
        // if(this.matTabValue == 2){
        //     this.filter.active_tab = 'Dispatched';
        // }
        // if(this.matTabValue == 3){
        //     this.filter.active_tab = 'Gate Pass';
        // }

    }
    
    
    //Active Mat-Tab Function Start
    
    tabClick(tab) {
        this.matTabValue = tab['tab']['textLabel'];
        if (this.matTabValue == 1 || this.matTabValue == 2) {
            this.filter = {};
            this.pageNumber = 1;
            this.billData('',1)
        }
        else {
            console.log('tab dispatched')
            this.filter = {};
            this.pageNumber = 1;
            this.getGeatePass('')
        }
    }
    //Active Mat-Tab Function End
    
    ExcelDownloadFunction() {

        this.loader = true;
        this.paginationData.pagelimit = 0;
            this.apiHit.PostRequest(this.paginationData, "Excel/Download_dispatch_list").subscribe((result => {

                console.log(result);
                this.getGeatePass('');
                setTimeout(() => {
 
                    this.loader = false;
                    window.open(this.apiHit.downloadURL + "askApi/uploads/Download_excel/dispatchList.csv");

                }, 200);

            }));
        
       

    }

    
    
    openDialog(): void {  
        const dialogRef = this.dialog.open(GatepassAddComponent, {
            width: '1024px',
            panelClass:'cs-modal',
            disableClose: true,
            data:{
                'model_type':'add',
                'gatePassAssign':this.gatePassAssign,
            }
            
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result != false){
                this.billData('',1);
                this.gatePassAssign = [];
            }
        });
    }
    
    
    
    
    getDetails(id, type): void {  
        const dialogRef = this.dialog.open(GatepassAddComponent, {
            width: '1024px',
            panelClass:'cs-modal',
            disableClose: true,
            data:{
                'model_type':type,
                'gatepass_id':id,
            }
            
        });
        dialogRef.afterClosed().subscribe(result => {
            if(result != false){
                this.getGeatePass('');
            }
        });
    }
    
    
    onChangePageNumber(type) {
    console.log(type,'This is type')
        setTimeout(() => {
            if (this.matTabValue!=3) {
                
            
          if (type == 'previous') {
    
            if (this.pageNumber > 1) {
    
              this.pageNumber -= 1;
              this.billData('',2);
            }
    
          }
          else if (type == 'next') {
          console.log('total value',this.total)
          console.log('pageNumber value',this.pageNumber)
            if (this.total > this.pageNumber) {
    
              this.pageNumber += 1;
              this.billData('',2);
            }
    
          }
          else if (type = 'current') {
    
            if (this.pageNumber > this.total) {
    
              this.pageNumber = this.total;
              this.billData('',2);
            }
            else if (this.pageNumber == 0) {
    
              this.pageNumber = 1;
              this.billData('',2);
    
    
            }
            else if (this.pageNumber) {
    
              this.billData('',2);
    
            }
    
          }
        }
          else{

              if (type == 'previous') {
        
                if (this.pageNumber > 1) {
        
                  this.pageNumber -= 1;
                  this.getGeatePass(3);
                }
        
              }
              else if (type == 'next') {
              console.log('total value',this.total)
              console.log('pageNumber value',this.pageNumber)
                if (this.total > this.pageNumber) {
        
                  this.pageNumber += 1;
                  this.getGeatePass(3);
                }
        
              }
              else if (type = 'current') {
        
                if (this.pageNumber > this.total) {
        
                  this.pageNumber = this.total;
                  this.getGeatePass(3);
                }
                else if (this.pageNumber == 0) {
        
                  this.pageNumber = 1;
                  this.getGeatePass(3);
        
        
                }
                else if (this.pageNumber) {
        
                  this.getGeatePass(3);
        
                }
        
              }
          }
    
        },
          100);
      }
    
}
