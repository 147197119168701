<div class="container-fluid">
    <div class="container">

        <section id="head" class="ac">
            <ng-container *ngIf="!loader&&login_data?.login_type != 'distributor'">
                <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
                    (click)="backClicked()">
                    <mat-icon>arrow_back</mat-icon>
                </a>
                <div class="module-name" >
                    <h2>Distributor Detail</h2>
                    <p>{{distributorDetailData.dr_name ? distributorDetailData.dr_name:'N/A'}}</p>
                </div>
            </ng-container>

            <ng-container *ngIf="loader">
                <div class="module-name sk-loading">
                    <h2>&nbsp;</h2>
                    <p>&nbsp;</p>
                </div>
            </ng-container>
        </section>

        <div class="container-pd10-5 no-padding">

            <mat-tab-group class="tab-group flat-tab-body" (selectedTabChange)="tabClick($event)"
                mat-align-tabs="start">

                <mat-tab label="1">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">info</mat-icon>
                        Detail
                    </ng-template>
                    <div class="pt10 pb10 pr5 pl5">
                        <div class="row">
                            <div class="col s12 m8">

                                <div class="cs-column" *ngIf="!loader">
                                    <div class="cs-head">
                                        <h2>basic Information</h2>
                                        <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                            <a class="edit-desc" 
                                                *ngIf="datas.module_name== 'Distributor' && datas.edit== 1 && (login_data.approval_type!='asm' && login_data.approval_type!='zsm' )"
                                                mat-raised-button
                                                (click)="DistributorInfoEdit(1, distributorDetailData)"><i
                                                    class="material-icons">edit</i></a>
                                        </span>
                                    </div>

                                    <div class="cs-form">
                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>Date & Created By</span>
                                                        <p>{{(distributorDetailData.date_created ?
                                                            distributorDetailData.date_created:'N/A') | date: 'd MMM y'}} - {{distributorDetailData.created_by_name ?
                                                            distributorDetailData.created_by_name:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Firm Name</span>
                                                        <p>{{distributorDetailData.dr_name ?
                                                            distributorDetailData.dr_name:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Name</span>
                                                        <p>{{distributorDetailData.contact_name ?
                                                            distributorDetailData.contact_name:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Distributor Code</span>
                                                        <p>{{distributorDetailData.dr_code ?
                                                            distributorDetailData.dr_code:'N/A'}}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>Mobile</span>
                                                        <p>{{distributorDetailData.mobile_no ?
                                                            distributorDetailData.mobile_no:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Email ID</span>
                                                        <p>{{distributorDetailData.email_id ?
                                                            distributorDetailData.email_id:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Date Of Birth</span>


                                                        <p>{{(distributorDetailData.date_birth ?
                                                            (distributorDetailData.date_birth | date: 'd MMM y') :'N/A')
                                                            }}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Date Of Anniversary</span>
                                                        <p>{{(distributorDetailData.date_anniversary ?
                                                            (distributorDetailData.date_anniversary | date: 'd MMM y')
                                                            :'N/A') }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>GST Number</span>
                                                        <p>{{distributorDetailData.gst_no ?
                                                            distributorDetailData.gst_no:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>PAN Number</span>
                                                        <p>{{distributorDetailData.pan_no ?
                                                            distributorDetailData.pan_no:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Credit Limit</span>
                                                        <p>₹{{distributorDetailData.credit_limit ?
                                                            distributorDetailData.credit_limit:'0'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Outstanding</span>
                                                        <p>₹{{distributorDetailData.outstanding ?
                                                            distributorDetailData.outstanding:'0'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Distributor Category</span>
                                                        <p>{{distributorDetailData.category ?
                                                            distributorDetailData.category:'N/A'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>ASM Name</span>
                                                        <p>{{distributorDetailData.asm_names ?
                                                            distributorDetailData.asm_names:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>ZSM Name</span>
                                                        <p>{{distributorDetailData.zsm_names ?
                                                            distributorDetailData.zsm_names:'N/A'}}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Sales Point</span>
                                                        <p>{{distributorDetailData.scheme_wallet ?
                                                            distributorDetailData.scheme_wallet:'0'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>Address</span>
                                                        <p>{{distributorDetailData.street? (distributorDetailData.street
                                                            + ','):''}} {{distributorDetailData.city_name?
                                                            (distributorDetailData.city_name + ','):''}}
                                                            ,{{distributorDetailData.district_name?
                                                            (distributorDetailData.district_name + ','):''}},
                                                            {{distributorDetailData.state_name?
                                                            (distributorDetailData.state_name + ','):''}}
                                                            ,{{distributorDetailData.pincode}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12">
                                                <div class="fields">
                                                    <div class="cs-box">
                                                        <span>Username</span>
                                                        <p style="text-transform: lowercase;">{{distributorDetailData.username ? distributorDetailData.username : 'N/A' }}</p>
                                                    </div>
                                                    <div class="cs-box">
                                                        <span>Password</span>
                                                        <p>{{distributorDetailData.password ?
                                                            distributorDetailData.password:'N/A'}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="cs-column" *ngIf="loader">
                                    <div class="cs-head sk-loading">
                                        <h2>&nbsp;</h2>
                                    </div>
                                    <div class="cs-form">
                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields sk-loading">
                                                    <div class="cs-box" *ngFor="let row of skData">
                                                        <span>&nbsp;</span>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col s12">
                                                <div class="fields sk-loading">
                                                    <div class="cs-box" *ngFor="let row of skData">
                                                        <span>&nbsp;</span>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12">
                                                <div class="fields sk-loading">
                                                    <div class="cs-box" *ngFor="let row of skData">
                                                        <span>&nbsp;</span>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="cs-column mt10" *ngIf="!loader">
                                    <div class="cs-head">
                                        <h2>Contact person Information</h2>
                                        <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                            <a class="edit-desc"
                                                *ngIf="datas.module_name== 'Distributor' && datas.add==1"
                                                (click)="DistributorInfoEdit(2, distributorDetailData)"
                                                mat-raised-button><i class="material-icons">add</i></a>
                                        </span>
                                    </div>


                                    <div class="cs-table">
                                        <figure class="table-head">
                                            <section class="th_cell">
                                                <main>
                                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                                    <div class="div_cell mmw230">Name</div>
                                                    <div class="div_cell mmw220">Mobile No.</div>
                                                    <div class="div_cell mw150 af-bdr-right">Email Id </div>
                                                    <div class="div_cell ac mmw80 ">Action</div>
                                                </main>
                                            </section>
                                        </figure>

                                        <section class="td_cell">
                                            <div class="df fdc ac" *ngIf="personDetailData.length == 0">
                                                <img src="assets/img/no-contact.svg" alt="No Contact" class="h180">
                                                <p style="font-size: 16px; color: #65617c;" class="mt30">No Contact
                                                    Available</p>
                                            </div>
                                            <main *ngFor="let data of personDetailData; let i=index">
                                                <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                                <div class="div_cell mmw230">{{data.contact_person ?
                                                    data.contact_person:'N/A'}}</div>
                                                <div class="div_cell mmw220"> {{data.contact_1 ? data.contact_1:'N/A'}}
                                                </div>
                                                <div class="div_cell mw150 af-bdr-right">{{data.contact_email ?
                                                    data.contact_email:'N/A'}}</div>
                                                <div class="div_cell ac mmw80">
                                                    <div class="cs-action"
                                                        *ngIf="apiHit.userLoginData.data.moduleData[1]['edit'] == 1 || apiHit.userLoginData.data.moduleData[1]['delete'] == 1">
                                                        <a class="ac-green"><i class="material-icons"
                                                                *ngIf="apiHit.userLoginData.data.moduleData[1]['edit'] == 1"
                                                                (click)="DistributorInfoEdit(3,data)">edit</i>
                                                        </a>
                                                        <a class="ac-red" matRipple
                                                            (click)="deleteContatctInfo(data.id)" matTooltip="Delete"
                                                            *ngIf="apiHit.userLoginData.data.moduleData[1]['delete'] == 1"
                                                            matTooltipPosition="below"><i
                                                                class="material-icons">delete_sweep</i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </main>
                                        </section>
                                    </div>
                                </div>

                            </div>

                            <div class="col s12 m4">
                                <ng-container *ngIf="!loader">
                                    <div class="cs-column mb10">
                                        <div class="cs-head">
                                            <h2>Assign Sales User</h2>
                                            <ng-container *ngIf="login_data.user_type=='System_user'">
                                                <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                    <a class="edit-desc"
                                                        *ngIf="datas.module_name=='Distributor' && datas.add==1 || datas.edit==1"
                                                        (click)="SalesUserInfoEdit(7, assignedBrands)"
                                                        mat-raised-button><i class="material-icons">add</i></a>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="login_data.user_type=='approval_user'">
                                                <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                    <a class="edit-desc" *ngIf="login_data.approval_type=='zsm' || login_data.approval_type=='asm'" 
                                                        (click)="SalesUserInfoEdit(7, assignedBrands)"
                                                        mat-raised-button><i class="material-icons">add</i></a>
                                                </ng-container>
                                            </ng-container>
                                        </div>


                                        <div class="cs-table">
                                            <figure class="table-head">
                                                <section class="th_cell">
                                                    <main>
                                                        <div class="div_cell mw100  af-bdr-left">Name</div>
                                                        <div class="div_cell text-center mmw60 af-bdr-right"
                                                            *ngIf="login_data.user_type=='System_user'">Action</div>
                                                    </main>
                                                </section>
                                            </figure>



                                            <section class="td_cell">
                                                <main *ngFor="let data of assignedBrands.assign_sales_users">
                                                    <div class="div_cell mw100 ">{{data.assigned_sales_user ? data.assigned_sales_user : 'N/A'}}
                                                    </div>
                                                    <div class="div_cell mmw60 ac">
                                                        <div class="cs-action">
                                                            <span
                                                                *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                                <a class="ac-red"
                                                                    *ngIf="datas.module_name== 'Distributor' && datas.delete==1"
                                                                    matRipple matTooltip="Delete"
                                                                    matTooltipPosition="below"
                                                                    (click)="deleteAssigSalesUser(data.id)"><i
                                                                        class="material-icons">delete_sweep</i></a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </main>

                                            </section>
                                        </div>
                                    </div>
                                    <div class="cs-column mb10">
                                        <div class="cs-head">
                                            <h2>Assign Brand Information</h2>
                                            <ng-container *ngIf="login_data.user_type=='System_user'">
                                                <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                    <a class="edit-desc"
                                                        *ngIf="datas.module_name=='Distributor' && datas.add==1 || datas.edit==1"
                                                        (click)="DistributorInfoEdit(5, assignedBrands)"
                                                        mat-raised-button><i class="material-icons">add</i></a>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="login_data.user_type=='approval_user'">
                                                <ng-container *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                    <a class="edit-desc" *ngIf="login_data.approval_type=='zsm' || login_data.approval_type=='asm'" 
                                                        (click)="DistributorInfoEdit(5, assignedBrands)"
                                                        mat-raised-button><i class="material-icons">add</i></a>
                                                </ng-container>
                                            </ng-container>
                                        </div>


                                        <div class="cs-table">
                                            <figure class="table-head">
                                                <section class="th_cell">
                                                    <main>
                                                        <div class="div_cell mw100  af-bdr-left">Brand</div>
                                                        <div class="div_cell text-center mmw60 af-bdr-right"
                                                            *ngIf="login_data.user_type=='System_user'">Action</div>
                                                    </main>
                                                </section>
                                            </figure>



                                            <section class="td_cell">
                                                <main *ngFor="let data of assignedBrands.distributer_assigned_brands">
                                                    <div class="div_cell mw100 ">{{data.brands ? data.brands : 'N/A'}}
                                                    </div>
                                                    <div class="div_cell mmw60 ac">
                                                        <div class="cs-action">
                                                            <span
                                                                *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                                <a class="ac-red"
                                                                    *ngIf="datas.module_name== 'Distributor' && datas.delete==1"
                                                                    matRipple matTooltip="Delete"
                                                                    matTooltipPosition="below"
                                                                    (click)="deleteAssigBrand(data.id)"><i
                                                                        class="material-icons">delete_sweep</i></a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </main>

                                            </section>
                                        </div>
                                    </div>


                                    <div class="cs-column" *ngIf="!loader">
                                        <div class="cs-head">
                                            <h2>Wallet Information</h2>
                                        </div>

                                        <div class="cs-form">
                                            <div class="row">
                                                <div class="col s12 m12">
                                                    <div class="fields fields-bg">
                                                        <div class="cs-box">
                                                            <span>Paid To Retailer</span>

                                                            <p> {{distributorDetailData.paidToRetailer ?
                                                                distributorDetailData.paidToRetailer:'0'}}</p>
                                                        </div>
                                                        <div class="cs-box">
                                                            <span>My Achievement</span>
                                                            <p> {{distributorDetailData.achievement ?
                                                                distributorDetailData.achievement:'0'}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row ">
                                                <div class="col s12 m12">
                                                    <div class="fields fields-bg">
                                                        <div class="cs-box">
                                                            <span>Earn by scan</span>
                                                            <p>{{distributorDetailData.scanning_point ?
                                                                distributorDetailData.scanning_point:'0'}}</p>
                                                        </div>
                                                        <div class="cs-box">
                                                            <span>Direct transfer by company</span>
                                                            <p>{{distributorDetailData.direct_point ?
                                                                distributorDetailData.direct_point:'0'}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row row-mb0">
                                                <div class="col s12 m12">
                                                    <div class="fields fields-bg">
                                                        <div class="cs-box">
                                                            <span>Received Amount</span>
                                                            <p>{{distributorDetailData.receivedAmount ?
                                                                distributorDetailData.receivedAmount:'0'}}</p>
                                                        </div>
                                                        <div class="cs-box">
                                                            <span>Balance Amount</span>
                                                            <p>{{distributorDetailData.wallet_balance ?
                                                                distributorDetailData.wallet_balance:'0'}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="cs-column" *ngIf="loader">
                                    <div class="cs-head sk-loading">
                                        <h2>&nbsp;</h2>
                                    </div>

                                    <div class="cs-form">
                                        <div class="row">
                                            <div class="col s12 m12">
                                                <div class="fields sk-loading">
                                                    <div class="cs-box" *ngFor="let row of skDataTwo">
                                                        <span>&nbsp;</span>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row row-mb0">
                                            <div class="col s12 m12">
                                                <div class="fields sk-loading">
                                                    <div class="cs-box" *ngFor="let row of skDataTwo">
                                                        <span>&nbsp;</span>
                                                        <p>&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-tab>



                <mat-tab label="2">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">supervised_user_circle</mat-icon>
                        Retailer
                    </ng-template>
                    <div class="cs-table">
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw90">Date</div>
                                    <div class="div_cell mmw90" *ngIf="login_data?.login_type != 'distributor'" >Created By</div>
                                    <div class="div_cell mw130">Company Name</div>
                                    <div class="div_cell mmw180">Contact Person</div>
                                    <div class="div_cell mmw100">Mobile</div>
                                    <div class="div_cell mmw180">State & District</div>
                                    <div class="div_cell mmw90 text-center">Mechanic</div>
                                    <div class="div_cell mmw100 text-right">Wallet Balance</div>
                                    <div class="div_cell text-center mmw70 af-bdr-right">Action</div>
                                </main>
                            </section>
                        </figure>


                        <section class="th_cell">
                            <main>
                                <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                <div class="div_cell mmw90">
                                    <div class="filter-data">
                                        <mat-form-field (click)="picker.open()">
                                            <input type="text" matInput [matDatepicker]="picker" [max]="date"
                                                placeholder="Search ..." name="date_created"
                                                [(ngModel)]="retailerDataDetails.date_created"
                                                (dateChange)="onDate($event)" readonly>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="div_cell mmw90" *ngIf="login_data?.login_type != 'distributor'">
                                    <div class="filter-data">
                                        <i class="material-icons">search</i>
                                        <input type="text" placeholder="Search ..." name="created_name"
                                            [(ngModel)]="retailerDataDetails.created_name"
                                            (keyup.enter)="searchRetailerist();">
                                    </div>
                                </div>
                                <div class="div_cell mw130">
                                    <div class="filter-data">
                                        <i class="material-icons">search</i>
                                        <input type="text" placeholder="Search ..." name="dr_name"
                                            [(ngModel)]="retailerDataDetails.dr_name"
                                            (keyup.enter)="searchRetailerist();">
                                    </div>
                                </div>
                                <div class="div_cell mmw180">
                                    <div class="filter-data">
                                        <i class="material-icons">search</i>
                                        <input type="text" placeholder="Search ..." name="contact_name"
                                            [(ngModel)]="retailerDataDetails.contact_name"
                                            (keyup.enter)="searchRetailerist();">
                                    </div>
                                </div>
                                <div class="div_cell mmw100">
                                    <div class="filter-data">
                                        <i class="material-icons">search</i>
                                        <input type="text" placeholder="Search ..." name="mobile_no"
                                            [(ngModel)]="retailerDataDetails.mobile_no"
                                            (keyup.enter)="searchRetailerist();">
                                    </div>
                                </div>
                                <div class="div_cell mmw180"></div>
                                <div class="div_cell mmw90 text-center"></div>
                                <div class="div_cell mmw100 text-right"></div>
                                <div class="div_cell text-center mmw70 af-bdr-right">
                                    <div class="cs-action">
                                        <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                            (click)="searchRetailerist()"><i class="material-icons">search</i></a>
                                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                                            (click)="refreshData(1)"><i
                                                class="material-icons">settings_backup_restore</i></a>
                                    </div>
                                </div>
                            </main>
                        </section>


                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <main *ngFor="let row of retailerData; let i =index">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw90">{{row.date_created | date:'d MMM y'}}</div>
                                    <div class="div_cell mmw90" *ngIf="login_data?.login_type != 'distributor'">{{row.createdBy_name | titlecase}}</div>
                                    <div class="div_cell mw130">
                                        <a class="link-btn" mat-flat-button
                                            routerLink="../../retailer-detail/{{row.id}}">
                                            {{row.dr_name ? row.dr_name : 'N/A'}}
                                        </a>
                                    </div>
                                    <div class="div_cell mmw180">{{row.contact_name ? row.contact_name : 'N/A'}}</div>
                                    <div class="div_cell mmw100">{{row.mobile_no ? row.mobile_no : 'N/A'}}</div>
                                    <div class="div_cell mmw180">{{row.state_name ? row.state_name : 'N/A'}}
                                        {{row.district_name ? row.district_name : 'N/A'}}</div>
                                    <div class="div_cell mmw90  ac">{{row.mechanic_assign.length ?
                                        row.mechanic_assign.length:'0'}}</div>

                                    <div class="div_cell mmw100 afe"> {{row.wallet_balance ? row.wallet_balance : '0'}}
                                    </div>
                                    <div class="div_cell ac mmw70">
                                        <div class="cs-action">
                                            <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                <a class="ac-red"
                                                    *ngIf=" datas.module_name== 'Distributor' && datas.delete==1"
                                                    matRipple matTooltip="Delete" matTooltipPosition="below"
                                                    (click)="deleteAssignRetailer(row.uniqueId)"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </span>
                                        </div>
                                    </div>
                                </main>
                                <app-not-found *ngIf="retailerData.length == 0"></app-not-found>
                            </ng-container>


                            <ng-container *ngIf="loader">
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90" *ngIf="login_data?.login_type != 'distributor'">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw130">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90  ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100 afe">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell ac mmw70">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                            </ng-container>
                        </section>
                    </div>
                </mat-tab>



                <!-- ASK To Distributor Transaction Mat Tab Start -->

                <mat-tab label="3">
                    <ng-template mat-tab-label class="w230" *ngIf="loader">
                        <div class="sk-loading w230">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader&&login_data?.login_type != 'distributor'">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        AFF To Distributor Transaction
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader&&login_data?.login_type == 'distributor'">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        My Transaction
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">
                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw120">Date</div>
                                    <div class="div_cell mmw150">Point Type</div>
                                    <div class="div_cell mw150"> Mode</div>
                                    <div class="div_cell mmw120 text-right">Points</div>
                                    <div class="div_cell mmw180">Remark</div>
                                    <div class="div_cell mmw180">Transaction ID</div>
                                    <div class="div_cell text-center mmw110 af-bdr-right">Action</div>
                                </main>
                            </section>


                            <!-- Search Section Start -->

                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw120">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker2.open()">
                                                <input type="text" matInput [matDatepicker]="picker2" [max]="date"
                                                    placeholder="Search ..." name="dateCreated"
                                                    [(ngModel)]="companyToDistributorTransaction.dateCreated"
                                                    (dateChange)="onDate2($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker2">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker2></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mmw150"></div>
                                    <div class="div_cell mw150"></div>
                                    <div class="div_cell mmw120 text-right"></div>
                                    <div class="div_cell mmw180 text-right"></div>
                                    <div class="div_cell mmw180">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="transaction_no"
                                                [(ngModel)]="companyToDistributorTransaction.transaction_no"
                                                (keyup.enter)="searchCompanyTransaction();">
                                        </div>
                                    </div>

                                    <div class="div_cell text-center mmw110 af-bdr-right">
                                        <div class="cs-action">
                                            <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                                                (click)="searchCompanyTransaction()"><i
                                                    class="material-icons">search</i></a>

                                            <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                matTooltipPosition="below" (click)="refreshData(2)"><i
                                                    class="material-icons">settings_backup_restore</i></a>
                                        </div>
                                    </div>
                                </main>
                            </section>
                            <!-- Search Section END -->
                        </figure>
                        <!-- Table Head End -->


                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <!-- Loop Data -->
                                <main *ngFor="let row of drTransection; let i=index;">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw120">{{row.date_created | date:'d MMM y'}}</div>
                                    <div class="div_cell mmw150">{{row.payment_type == 'scanning_point' ? 'Customer Scanned':'AFF Scanned'}} Points</div>
                                    <div class="div_cell mw150">
                                        <p>{{row.payment_mode ? row.payment_mode : 'N/A'}} 
                                        <span *ngIf="row.payment_mode == 'Cheque'">
                                            - {{row.cheque_no ? row.cheque_no : 'N/A'}}
                                        </span>
                                    </p>
                                    </div>
                                    <div class="div_cell mmw120 afe"> {{row.payment_amount ? row.payment_amount : '0'}}</div>
                                        <div class="div_cell mmw180">{{row.remark ? row.remark : 'N/A'}}
                                    </div>
                                    <div class="div_cell mmw180">{{row.transaction_no ? row.transaction_no : 'N/A'}}
                                    </div>
                                    
                                    <div class="div_cell ac mmw110">
                                        <div class="cs-action">
                                            <span *ngIf="row.pdf_file != ''">
                                                <a  class="ac-green mr5" href="{{apiHit.uploadURL}}/uploads/credit_note/{{row.pdf_file}}" title="View Document" target="_blank" matRipple  matTooltip="View" matTooltipPosition="below">
                                                    <i class="material-icons">remove_red_eye</i>
                                                </a>
                                            </span>
                                            <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                <a class="ac-red"
                                                    *ngIf="datas.module_name== 'Distributor' && datas.delete==1"
                                                    matRipple matTooltip="Delete" matTooltipPosition="below"
                                                    (click)="transectionDelete(row.id)"><i
                                                        class="material-icons">delete_sweep</i></a>
                                            </span>
                                        </div>
                                    </div>
                                </main>
                                <!-- Loop Data -->

                                <app-not-found *ngIf="drTransection.length == 0"></app-not-found>
                            </ng-container>


                            <ng-container *ngIf="loader">
                                <!-- Skelton Loop -->
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw120">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw120">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton Loop -->
                            </ng-container>

                        </section>
                        
                    </div>
                </mat-tab>

                <!-- ASK To Distributor Transaction Mat Tab End -->



                <!-- Distributor To Retailer Transaction Start -->

                <mat-tab label="4">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading w230">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader&&login_data?.login_type != 'distributor'">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Distributor To Retailer Transaction
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader&&login_data?.login_type == 'distributor'">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                         Retailer Transaction
                    </ng-template>
                    <div class="cs-table">
                        <!-- Table Head Start-->
                        <figure class="table-head">

                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw110">Date</div>
                                    <div class="div_cell mw150">Retailer</div>
                                    <div class="div_cell mmw150">Point Type</div>
                                    <div class="div_cell mmw180">Payment Mode</div>
                                    <div class="div_cell mmw110 text-right">Amount</div>
                                    <div class="div_cell mmw200 af-bdr-right">Transaction ID</div>
                                </main>
                            </section>


                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw110">
                                        <div class="filter-data">
                                            <mat-form-field (click)="picker3.open()">
                                                <input type="text" matInput [matDatepicker]="picker3" [max]="date"
                                                    placeholder="Search ..." name="date_created"
                                                    [(ngModel)]="distributorToRetailerTransaction.date_created"
                                                    (dateChange)="onDate3($event)" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="picker3">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker3></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="div_cell mw150">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="retailer_name"
                                                [(ngModel)]="distributorToRetailerTransaction.retailer_name"
                                                (keyup.enter)="searchDistributorToRetailerTransaction();">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw150"></div>
                                    <div class="div_cell mmw180"></div>
                                    <div class="div_cell mmw110 text-right">
                                    </div>
                                    <div class="div_cell mmw200 af-bdr-right">
                                        <div class="df ac">
                                            <div class="filter-data">
                                                <i class="material-icons">search</i>
                                                <input type="text" placeholder="Search ..." name="transaction_no"
                                                    [(ngModel)]="distributorToRetailerTransaction.transaction_no"
                                                    (keyup.enter)="searchDistributorToRetailerTransaction();">
                                            </div>

                                            <div class="cs-action ml15">
                                                <a class="ac-grey" matRipple matTooltip="Search"
                                                    matTooltipPosition="below"
                                                    (click)="searchDistributorToRetailerTransaction()"><i
                                                        class="material-icons">search</i></a>
                                                <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                    matTooltipPosition="below" (click)="refreshData(3)"><i
                                                        class="material-icons">settings_backup_restore</i></a>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </section>

                        </figure>
                        <!-- Table Head End -->



                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <main *ngFor="let row of retailerTransection; let i=index;">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw110">{{row.date_created | date:'d MMM y'}}</div>
                                    <div class="div_cell mw150">{{row.retailer_name ? row.retailer_name : 'N/A'}} -
                                        {{row.mobile_no ? row.mobile_no : 'N/A'}}</div>
                                    <div class="div_cell mmw150">{{row.payment_type == 'scheme_incentive' ? 'Scheme' : 'Coupon'}} Points</div>
                                    <div class="div_cell mmw180">{{row.payment_mode ? row.payment_mode : 'N/A'}}</div>
                                    <div class="div_cell mmw110 afe"> {{row.payment_amount ? row.payment_amount : '0'}}
                                    </div>
                                    <div class="div_cell mmw200">{{row.transaction_no ? row.transaction_no : 'N/A'}}
                                    </div>
                                </main>
                                <app-not-found *ngIf="retailerTransection.length == 0"></app-not-found>
                            </ng-container>

                            <ng-container *ngIf="loader">
                                <!-- Skelton Loop -->
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw150">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw200">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton Loop -->
                            </ng-container>

                        </section>
                        <!-- <div class="col s12 m6" *ngIf="!loader">
                                                                                                                        <div class="cs-btn mt8 text-right">
                                                                                                                            <button type="submit" (click)="exportDataInExcel(3,retailerTransection)" mat-button>Export</button>
                                                                                                                        </div>
                                                                                                                    </div> -->
                    </div>
                </mat-tab>


                
                <!-- Distributor To Retailer Transaction End -->

                <mat-tab label="5">
                    <ng-template mat-tab-label *ngIf="loader">
                        <div class="sk-loading w230">&nbsp;</div>
                    </ng-template>
                    <ng-template mat-tab-label *ngIf="!loader">
                        <mat-icon class="example-tab-icon">swap_horizontal_circle</mat-icon>
                        Stock
                    </ng-template>
                    <div class="cs-table table-scroll mb40" style="max-height: calc(100vh - 183px);">
                        <figure class="table-head">

                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                                    <div class="div_cell mmw70">Segment</div>
                                    <div class="div_cell mmw120">Brand</div>
                                    <div class="div_cell mw180">Part Code</div>
                                    <div class="div_cell mmw110 ">MSL</div>
                                    <div class="div_cell mmw90 af-bdr-right">Current Stock</div>
                                    <div class="div_cell mmw110 af-bdr-right">Secondary Order</div>
                                    <div class="div_cell mmw90 af-bdr-right">Balance Stock</div>
                                    <div class="div_cell mmw110">Last Updated</div>
                                    <div class="div_cell mmw110">Updated By</div>
                                    <div class="div_cell mmw100 af-bdr-right">Action</div>
                                </main>
                            </section>

                            <section class="th_cell">
                                <main>
                                    <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                                    <div class="div_cell mmw70">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="segment"
                                                [(ngModel)]="distributorToRetailerTransaction.segment"
                                                (keyup.enter)="getDistributorStockAlert(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw120">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="category"
                                                [(ngModel)]="distributorToRetailerTransaction.category"
                                                (keyup.enter)="getDistributorStockAlert(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mw180">
                                        <div class="filter-data">
                                            <i class="material-icons">search</i>
                                            <input type="text" placeholder="Search ..." name="part_code"
                                                [(ngModel)]="distributorToRetailerTransaction.part_code"
                                                (keyup.enter)="getDistributorStockAlert(1);">
                                        </div>
                                    </div>
                                    <div class="div_cell mmw110 text-right">
                                    </div>
                                    <div class="div_cell mmw90 text-right"></div>
                                    <div class="div_cell mmw110 text-right"></div>
                                    <div class="div_cell mmw90 text-right"></div>
                                    <div class="div_cell mmw110">
                                    </div>
                                    <div class="div_cell mmw110">
                                    </div>
                                    <div class="div_cell mmw100 af-bdr-right">
                                        <div class="df ac">
                                            <div class="cs-action ml15">
                                                <a class="ac-grey" matRipple matTooltip="Search"
                                                    matTooltipPosition="below" (click)="getDistributorStockAlert(1)"><i
                                                        class="material-icons">search</i></a>
                                                <a class="ac-refresh" matRipple matTooltip="Refresh"
                                                    matTooltipPosition="below" (click)="getDistributorStockAlert(2)"><i
                                                        class="material-icons">settings_backup_restore</i></a>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </section>
                        </figure>


                        <section class="td_cell">
                            <ng-container *ngIf="!loader">
                                <main *ngFor="let row of stockListData; let i=index;"
                                    [ngClass]="{'alert-row':row.default_stock > row.current_stock}">
                                    <div class="div_cell mmw50 ac"><strong>{{i+1}}</strong></div>
                                    <div class="div_cell mmw70">{{row.segment ? row.segment : 'N/A'}}</div>
                                    <div class="div_cell mmw120">{{row.brand ? row.brand : 'N/A'}}</div>
                                    <div class="div_cell mw180">{{row.part_code ? row.part_code : 'N/A'}}</div>
                                    <div class="div_cell mmw110">{{row.default_stock ? row.default_stock : '0'}}</div>
                                    <div class="div_cell mmw90">{{row.current_stock ? row.current_stock : '0'}}</div>
                                    <div class="div_cell mmw110">{{row.Secondary_order ? row.Secondary_order : '0'}}
                                    </div>
                                    <div class="div_cell mmw90">
                                        {{row.current_stock ? (row.current_stock - row.Secondary_order) : (0 - row.Secondary_order)}}</div>
                                    <div class="div_cell mmw110">{{row.date_updated | date:'d MMM y'}}</div>
                                    <div class="div_cell mmw110">{{row.updated_by ? row.updated_by : 'N/A'}}</div>
                                    <div class="div_cell mmw100">
                                        <div class="df ac">
                                            <div class="cs-action">
                                                <span *ngFor="let datas of apiHit.userLoginData.data.moduleData">
                                                    <a class="ac-green" matRipple matTooltip="Edit"
                                                        *ngIf=" datas.module_name== 'Distributor' && datas.edit== 1"
                                                        matTooltipPosition="below"><i class="material-icons"
                                                            (click)="DistributorInfoEdit(4, row)">edit</i></a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                                <app-not-found *ngIf="stockListData.length == 0"></app-not-found>
                            </ng-container>


                            <ng-container *ngIf="loader">
                                <!-- Skelton Loop -->
                                <main *ngFor="let data of skeleton; let i=index">
                                    <div class="div_cell mmw50 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw70 ac">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw120">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mw180">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw90">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw110">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                    <div class="div_cell mmw100">
                                        <p class="skeleton wp100">&nbsp;</p>
                                    </div>
                                </main>
                                <!-- Skelton Loop -->
                            </ng-container>


                        </section>

                    </div>

                    <!-- <div class="bottom-strip" *ngIf="!loader">
                        <div class="left-auto df ac">
                            <div class="multi-btns">
                                <a mat-raised-button color="accent" ><img src="assets/img/excel.png"> Upload Excel</a>
                                <a mat-raised-button color="accent"  (click)="ExcelDownloadFunction()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                            </div>
                        </div>
                    </div> -->
 
                    <!-- <div class="bottom-fix-group" *ngIf="!loader"> -->
                        <div class="bottom-strip" *ngIf="!loader">
                            <div class="left-auto df ac">
                                <div class="multi-btns" *ngFor="let datas of apiHit.userLoginData.data.moduleData; let i=index">
                                    <a *ngIf=" datas.module_name== 'Distributor' && datas.edit== 1" mat-raised-button color="accent" (click)="DistributorInfoEdit(6, distributorDetailData)"><img src="assets/img/excel.png">Upload Excel</a>
                                    <a mat-raised-button color="accent" class="ml10" *ngIf="!loader && stockListData.length > 0 && i == 0" (click)="exportDataInExcel(1)"><img src="assets/img/excel.png">Download Excel</a>
                                </div>
                            </div>
                        </div>

                </mat-tab>

            </mat-tab-group>

        </div>
    </div>
</div>