import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
    status:boolean = false
    data: any;
    constructor(

      private router : Router,
      public db : DatabaseService, 
      private render: Renderer2
      
      
    ) { }

    ngOnInit(): void {

      // console.log(this.db.userLoginData);
      this.data= this.db.userLoginData.data
      console.log(this.data);

    }

    logout(){
        
        console.log('in log out function');
        localStorage.removeItem('abacusCrmUserData');
        sessionStorage.removeItem('abacusCrmUserData');
        sessionStorage.clear();
        localStorage.clear();
        this.db.userLoginData = {};
        this.router.navigate(['']);

    }

    toggleHeader() 
    {
      this.status = !this.status;

      if(this.status) {

        this.render.addClass(document.body, 'active');

      }
      else {

        this.render.removeClass(document.body, 'active');

      }

    }

}