import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../services/service';
import { DialogService } from '../services/dialog.service';

@Component({
    selector: 'app-allowance-master',
    templateUrl: './allowance-master.component.html',
    styleUrls: ['./allowance-master.component.scss']
})
export class AllowanceMasterComponent implements OnInit {
    designation: any;
    userRoleData: any = [];
    allowanceData: any = [];
    loader: any;
    skLoading: boolean=false;
    logined_user_data2: any;
    assign_login_data: any;
    downurl: any = '';
    logined_user_data: any
    constructor(public serve :DatabaseService ,public dialoge : DialogService) { }
    
    ngOnInit(): void {
        this.get_designation();
        this.get_allowance();
    }
    get_designation() {
        this.serve.PostRequest({ 'designation': this.designation }, "Master/salesType").subscribe((response => {
            if (response['sales']['statusCode'] == 200) {
                this.userRoleData = response['sales']['result'];
                this.get_allowance();
            }
            else {
                this.dialoge.error("Error",response['sales']['statusMsg']);
            }
        }));
        
        
    }
    
    get_allowance() {
        this.loader = 1;
        this.serve.PostRequest({ 'designation': this.designation }, "Master/getAllowanceData").subscribe((response => {
            if (response['allowance']['statusCode'] == 200) {
                this.allowanceData = response['allowance']['result'];
                console.log(this.allowanceData);
                
                // for (var i = 0; i < this.userRoleData.length; i++) {
                //     for (var j = 0; j < this.allowanceData.length; j++) {
                //         if (this.userRoleData[i]['id'] == this.allowanceData[j]['roleId']) {
                //             this.userRoleData[i]['flight'] = this.allowanceData[j]['flight'];
                //             this.userRoleData[i]['trainSC'] = this.allowanceData[j]['trainSC'];
                //             this.userRoleData[i]['train3Tier'] = this.allowanceData[j]['train3Tier'];
                //             this.userRoleData[i]['train2Tier'] = this.allowanceData[j]['train2Tier'];
                //             this.userRoleData[i]['busAC'] = this.allowanceData[j]['busAC'];
                //             this.userRoleData[i]['busNonAC'] = this.allowanceData[j]['busNonAC'];
                //             this.userRoleData[i]['auto'] = this.allowanceData[j]['auto'];
                //             this.userRoleData[i]['taxi'] = this.allowanceData[j]['taxi'];
                //             this.userRoleData[i]['car'] = this.allowanceData[j]['car'];
                //             this.userRoleData[i]['bike'] = this.allowanceData[j]['bike'];
                //             this.userRoleData[i]['hotel'] = this.allowanceData[j]['hotel'];
                //             this.userRoleData[i]['metro'] = this.allowanceData[j]['metro'];
                //             this.userRoleData[i]['food'] = this.allowanceData[j]['food'];
                //             this.userRoleData[i]['car_outstation'] = this.allowanceData[j]['car_outstation'];
                //             this.userRoleData[i]['acbus_outstation'] = this.allowanceData[j]['acbus_outstation'];
                //         }
                //     }
                // }
                setTimeout(() => {
                    this.loader = '';
                    
                }, 5000);
            }
            else {
                this.dialoge.error('Error',response['allowance']['statusMsg']);
            }
            
        }));
    }
    refresh() {
        this.get_allowance();
    }
    updateAllowance() {
        for (var i = 0; i < this.userRoleData.length; i++) {
            for (var j = 0; j < this.allowanceData.length; j++) {
                if (this.userRoleData[i]['id'] == this.allowanceData[j]['roleId']) {
                    this.userRoleData[i]['flight'] = this.allowanceData[j]['flight'];
                    this.userRoleData[i]['trainSC'] = this.allowanceData[j]['trainSC'];
                    this.userRoleData[i]['train3Tier'] = this.allowanceData[j]['train3Tier'];
                    this.userRoleData[i]['train2Tier'] = this.allowanceData[j]['train2Tier'];
                    this.userRoleData[i]['busAC'] = this.allowanceData[j]['busAC'];
                    this.userRoleData[i]['busNonAC'] = this.allowanceData[j]['busNonAC'];
                    this.userRoleData[i]['auto'] = this.allowanceData[j]['auto'];
                    this.userRoleData[i]['taxi'] = this.allowanceData[j]['taxi'];
                    this.userRoleData[i]['car'] = this.allowanceData[j]['car'];
                    this.userRoleData[i]['bike'] = this.allowanceData[j]['bike'];
                    this.userRoleData[i]['hotel'] = this.allowanceData[j]['hotel'];
                    this.userRoleData[i]['metro'] = this.allowanceData[j]['metro'];
                    this.userRoleData[i]['food'] = this.allowanceData[j]['food'];
                    this.userRoleData[i]['car_outstation'] = this.allowanceData[j]['car_outstation'];
                    this.userRoleData[i]['acbus_outstation'] = this.allowanceData[j]['acbus_outstation'];
                }
            }
        }
        this.skLoading = true;
        this.dialoge.confirmAlert("Update Allowance !").then((result) => {
            if (result) {
                this.serve.PostRequest({ 'data': this.userRoleData }, "Master/updateAllowance").subscribe((response) => {
                    if (response['allowance']['statusCode'] == 200) {
                        this.dialoge.success("Sucess",response['allowance']['statusMsg']);
                        this.skLoading = false;
                        this.get_allowance();
                    }
                    else {
                        this.skLoading = false;
                        this.dialoge.error("Error",response['allowance']['statusMsg']);
                    }
                }, err => {
                    this.skLoading = false;
                });
            }else{
                this.skLoading = false;
            }
        })
    }
}
