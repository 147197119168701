<div class="container-fluid">
    <div class="container">
        <section id="head" class="ac">
            <div class="module-name" *ngIf="!loader">
                <h2>Mechanic</h2>
                <p>{{totalMechanic}} Mechanic Available</p>
            </div>
            <div class="module-name sk-loading" *ngIf="loader">
                <h2>&nbsp;</h2>
                <p>&nbsp;</p>
            </div>
            <div class="left-auto">
                <div class="cs-action">
                    <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="mechanicList(1)"><i class="material-icons">search</i></a>
                    <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                </div>
            </div>
        </section>
        
        <div class="container-pd10-5 bottom-calc no-padding">
            <div class="cs-table">
                <!-- Table Head Start-->
                <figure class="table-head">
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                            <div class="div_cell mmw110">Date</div>
                            <div class="div_cell mmw130">Created By</div>
                            <div class="div_cell mw130">Name</div>
                            <div class="div_cell mmw85">Mobile</div>
                            <div class="div_cell mmw170">State</div>
                            <div class="div_cell mmw170">District</div>
                            <!-- <div class="div_cell mmw80 text-center">Distributor</div> -->
                            <div class="div_cell mmw80 text-center">Retailer</div>
                            <div class="div_cell mmw95 text-right">Wallet Balance</div>
                            
                            
                            <ng-container *ngFor="let datas of db.userLoginData.data.moduleData">
                                <div class="div_cell text-center mmw70 af-bdr-right" *ngIf=" datas.module_name== 'Mechanic' && datas.delete==1 || datas.module_name== 'Mechanic' && datas.delete==1" >Action</div>
                            </ng-container>
                            
                        </main>
                    </section>
                    <section class="th_cell">
                        <main>
                            <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                            <div class="div_cell mmw110">
                                <div class="filter-data">
                                    <mat-form-field (click)="picker.open()">
                                        <input type="text" matInput [matDatepicker]="picker" [max]="date" placeholder="Search ..." name="date" 
                                        [(ngModel)]="filterSearch.date" (dateChange)="onDate($event)" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="div_cell mmw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="created_name" [(ngModel)]="filterSearch.created_name" (keyup.enter)="mechanicList(1);">
                                </div>
                            </div>
                            <div class="div_cell mw130">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="contact_name" [(ngModel)]="filterSearch.contact_name" (keyup.enter)="mechanicList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw85">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="mobile_no" [(ngModel)]="filterSearch.mobile_no" (keyup.enter)="mechanicList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw170">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="state_name" [(ngModel)]="filterSearch.state_name" (keyup.enter)="mechanicList(1);">
                                </div>
                            </div>
                            <div class="div_cell mmw170">
                                <div class="filter-data">
                                    <i class="material-icons">search</i>
                                    <input type="text" placeholder="Search ..." name="district_name" [(ngModel)]="filterSearch.district_name" (keyup.enter)="mechanicList(1);">
                                </div>
                            </div>
                            <!-- <div class="div_cell mmw80">
                            </div> -->
                            <div class="div_cell mmw80">
                            </div>
                            <div class="div_cell mmw95 text-right">
                            </div>
                            
                            
                            <ng-container *ngFor="let datas of db.userLoginData.data.moduleData">
                                
                                
                                <!-- <div class="div_cell text-center mmw70 af-bdr-right" *ngIf=" datas.module_name== 'Mechanic' && datas.delete==1 || datas.module_name== 'Mechanic' && datas.delete==1" >Action</div> -->
                                
                                
                                <div class="div_cell text-center mmw70 af-bdr-right"  *ngIf=" datas.module_name== 'Mechanic' && datas.delete==1 || datas.module_name== 'Mechanic' && datas.delete==1" >
                                    <!-- <div class="cs-action">
                                        <a class="ac-grey" matRipple  matTooltip="Search" matTooltipPosition="below" (click)="mechanicList(1)"><i class="material-icons">search</i></a>
                                        <a class="ac-refresh" matRipple  matTooltip="Refresh" matTooltipPosition="below" (click)="refreshData()"><i class="material-icons">settings_backup_restore</i></a>
                                    </div> -->
                                </div>
                            </ng-container> 
                        </main>
                    </section>
                </figure>
                <!-- Table Head End -->
                
                
                
                <section class="td_cell" *ngIf="!dataNotFound">
                    <!-- Loop Data -->
                    <ng-container *ngIf="!loader">
                        <main *ngFor="let row of mechanicData; let i = index;">
                            <div class="div_cell mmw50 ac">
                                <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                                <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                            </div>
                            <div class="div_cell mmw110">{{(row.date_created) | date:'d MMM y' }}</div>
                            <div class="div_cell mmw130">{{row.createdBy_name?(row.createdBy_name | titlecase):'N/A'}}</div>
                            <div class="div_cell mw130"><a class="link-btn" mat-flat-button routerLink="../mechanic-detail/{{row.id}}">{{row.contact_name | titlecase}}</a></div>
                            <div class="div_cell mmw85">{{row.mobile_no}}</div>
                            <!-- <ng-container *ngIf="row.state_name != ''"> -->
                                <div class="div_cell mmw170">{{row.state_name ? (row.state_name | titlecase) :'N/A'}}</div>
                            <!-- </ng-container> -->
                            <!-- <ng-container *ngIf="row.district_name != '' "> -->
                                <div class="div_cell mmw170">{{row.district_name ? (row.district_name | titlecase) : 'N/A'}}</div>
                            <!-- </ng-container> -->
                            <!-- <ng-container *ngIf="row.state_name == '' && row.district_name == '' ">
                                <div class="div_cell mmw170">N/A</div>
                            </ng-container> -->
                            <div class="div_cell mmw80 ac">{{row.retailerCount ? row.retailerCount:'0'}}</div>
                            <div class="div_cell mmw95 afe">  {{row.wallet_balance? row.wallet_balance:'0'}}</div>


                            <ng-container *ngFor="let datas of db.userLoginData.data.moduleData">
                                <!-- <div class="div_cell text-center mmw70 af-bdr-right" *ngIf=" datas.module_name== 'Mechanic' && datas.delete==1 || datas.module_name== 'Mechanic' && datas.delete==1" >Action</div> -->
                                <div class="div_cell mmw70 ac" *ngIf=" datas.module_name== 'Mechanic' && datas.delete==1 || datas.module_name== 'Mechanic' && datas.delete==1">
                                    <div class="cs-action">
                                        <ng-container *ngFor="let datas of db.userLoginData.data.moduleData">
                                            <a class="ac-red"  *ngIf=" datas.module_name== 'Mechanic' && datas.delete==1"  matRipple  matTooltip="Delete" matTooltipPosition="below" (click)="deleteItem(row.id)"><i class="material-icons">delete_sweep</i></a>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                            </main>
                        </ng-container>
                        <!-- Loop Data -->
                        
                        
                        <!-- Skeleton Loading Start -->
                        <ng-container *ngIf="loader">
                            <main *ngFor="let row of skeleton;" >
                                <div class="div_cell mmw50 ac"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw110"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw130"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mw130"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw85"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw170"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw170"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw80"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw95 afe"><p class="skeleton wp100">&nbsp;</p></div>
                                <div class="div_cell mmw70 ac"><p class="skeleton wp100">&nbsp;</p></div>
                            </main>
                        </ng-container>
                        
                        <!-- Skeleton Loading End -->
                    </section>
                    <app-not-found *ngIf="dataNotFound"></app-not-found>
                </div>
                
                <div class="bottom-strip">
                    <div class="left-auto df ac"  *ngIf="!loader">
                        <div class="pagination">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{totalPage}}</li>
                                <li (click)="onChangePageNumber('previous')"><a matRipple class="back"  matTooltip="Previous" matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber" (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next')"><a matRipple class="next"  matTooltip="Next" matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                            </ul>
                        </div>
                        <div class="multi-btns">
                            <a mat-raised-button color="accent"  *ngIf="mechanicData.length > 0"  (click)="exportMechanicData()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                        </div>
                    </div>
                </div>


                <!-- <div class="bottom-strip" *ngIf="!loader">
                    <div class="left-auto df ac">
                        <div class="pagination">
                            <ul class="df ac">
                                <li>Pages {{pageNumber}} of {{total}}</li>
                                <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                        matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber"
                                        (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                                <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                        matTooltipPosition="above"><i class="material-icons">chevron_right</i></a></li>
                            </ul>
                        </div>
                        <div class="multi-btns">
                            <a mat-raised-button color="accent"  *ngIf="distributorData.length > 0"  (click)="exportDistributorDataInExcel()" class="ml10"><img src="assets/img/excel.png"> Download Excel</a>
                        </div>
    
                    </div>
                </div> -->


            </div>
            
        </div>
    </div>
    