import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseService } from 'src/app/services/service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DistributorEditComponent } from '../distributor-edit/distributor-edit.component';
import { DialogService } from 'src/app/services/dialog.service';
import * as moment from 'moment';


@Component({
    selector: 'app-distributor-detail',
    templateUrl: './distributor-detail.component.html'
})
export class DistributorDetailComponent implements OnInit {


    distributorDetailData: any;
    retailerData: any = [];
    drTransection: any = [];
    retailerTransection: any = [];
    assignedBrands: any = [];
    personDetailData: any;
    loader: any;
    skeleton: any = [];
    skData: any = [];
    skDataTwo: any = [];
    excelData: any = [];
    date: any;
    retailerDataDetails: any = {};
    companyToDistributorTransaction: any = {};
    distributorToRetailerTransaction: any = {};
    searchData: any = {};
    matTabValue: any;
    stockListData: any = [];
    login_data: any;


    constructor(

        public activateRoute: ActivatedRoute,
        private apiHit: DatabaseService,
        private _location: Location,
        public dialog: MatDialog,
        public dialog2: DialogService,

    ) {

        console.log(activateRoute['params']['_value']);
        this.skeleton = new Array(10);
        this.skData = new Array(4)
        this.distributorDetail();
        this.searchCompanyTransaction();
        this.skDataTwo = new Array(2);
        this.date = new Date();
        this.matTabValue = 1;


    }

    ngOnInit(): void {

        this.login_data = this.apiHit.userLoginData.data
        console.log(this.login_data.approval_type);
    }


    // Back Button Function  Start

    backClicked() {

        this._location.back();

    }
    // Back Button Function  End



    //Get Distributor Detail Through API Function Start

    distributorDetail() {

        this.loader = 1;

        this.apiHit.PostRequest(this.activateRoute['params']['_value'], "Distributor/dealer_details").subscribe((result => {

            console.log(result);

            this.distributorDetailData = result['dealer_details']['details'];
            this.personDetailData = result['dealer_details']['contact_person_info'];
            this.retailerData = result['dealer_details']['retailer_assigned_info'];
            this.assignedBrands = result['dealer_details'];
            // this.drTransection = result['dealer_details']['company_to_distributor_transection']; 
            this.retailerTransection = result['dealer_details']['distributor_to_retailer_transection'];

            console.log(this.retailerTransection);


            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Get Distributor Detail Through API Function End


    //Delete Assign Brand Function Start
    deleteAssigBrand(id) {

        let data = {};
        data['assign_id'] = id;
        data['dr_id'] = id;
        data['uid'] = id;


        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(data, "Distributor/delete_brands_to_distributer").subscribe((result => {

                    console.log(result);
                    if (result['result']['status'] == "Success") {
                        this.distributorDetail();
                    } else {
                        this.dialog2.error('error', 'Something Went Wrong');
                    }

                }));

            }

        });

    }
    //Delete Assign Brand Function End

    //Delete Assign Sales User Function Start
    deleteAssigSalesUser(id) {

        let data = {};
        data['assign_id'] = id;
        data['uid'] = this.apiHit.userLoginData.data.id;


        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest(data, "Distributor/deleteAssignUser").subscribe((result => {

                    console.log(result);
                    if (result['status'] == "success") {
                        this.distributorDetail();
                    } else {
                        this.dialog2.error('error', 'Something Went Wrong');
                    }

                }));

            }

        });

    }
    //Delete Assign Sales User Function End


    //Search Mechnaic List Through API Function End

    searchCompanyTransaction() {

        this.loader = 1;
        this.drTransection = [];
        this.searchData.id = this.activateRoute['params']['_value']['id'];
        this.searchData.searchData = this.companyToDistributorTransaction;
        console.log(this.searchData);
        // return;

        this.apiHit.PostRequest(this.searchData, "Distributor/company_to_distributor_transaction_search").subscribe((result => {

            console.log(result);

            this.drTransection = result['transactionData']['data']['info'];

            console.log(this.drTransection);

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Search Company Transaction List END


    getDistributorStockAlert(target) {

        this.loader = 1;
        if (target == 2) {
            this.distributorToRetailerTransaction = {};
        }

        this.apiHit.PostRequest({ 'dr_id': this.activateRoute['params']['_value']['id'], 'search': this.distributorToRetailerTransaction }, "Distributor/getDistributerPartslist").subscribe((result => {

            console.log(result);

            if (result['status'] == "Success") {
                this.stockListData = result['info'];
                this.loader = '';
            }
            else {
                this.dialog2.error('error', 'Something Went Wrong');
                this.loader = '';

            }

        }));

    }


    //Active Mat-Tab Function Start
    tabClick(tab) {
        console.log(tab['tab']['textLabel']);
        this.matTabValue = tab['tab']['textLabel'];
        this.companyToDistributorTransaction = {};
        this.distributorToRetailerTransaction = {};
        if(this.matTabValue == 2 || this.matTabValue == 4 || this.matTabValue == 1){
            this.distributorDetail();
        }
        else if(this.matTabValue == 3){
            this.searchCompanyTransaction();
        }
        else if(this.matTabValue == 5) {
            this.searchData = {};
            this.getDistributorStockAlert(1);
        }
    }
    //Active Mat-Tab Function End



    //Search Mechnaic List Through API Function Start
    searchRetailerist() {

        this.loader = 1;
        this.retailerData = [];
        this.searchData.id = this.activateRoute['params']['_value']['id'];
        this.searchData.searchData = this.retailerDataDetails;
        console.log(this.searchData);
        // return;
        this.apiHit.PostRequest(this.searchData, "Distributor/distributor_retailer_list_search").subscribe((result => {

            console.log(result);

            this.retailerData = result['retailerData'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }



    //Search Diatributor To Retailer Transaction List Start
    searchDistributorToRetailerTransaction() {

        this.loader = 1;
        this.retailerTransection = [];
        this.searchData.id = this.activateRoute['params']['_value']['id'];
        this.searchData.searchData = this.distributorToRetailerTransaction;

        console.log(this.searchData);

        // return;
        this.apiHit.PostRequest(this.searchData, "Distributor/distributor_to_retailer_transaction_search").subscribe((result => {

            console.log(result);

            this.retailerTransection = result['transactionData'];

            setTimeout(() => {

                this.loader = '';

            }, 700);

        }));

    }
    //Search Diatributor To Retailer Transaction List End



    //Delete Distributor Contact Person Through API Function Start
    deleteContatctInfo(id) {

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ 'id': id }, "Distributor/delete_contact_person").subscribe((result => {

                    if (result['delete_contact_person'] == 'success') {

                        this.distributorDetail();

                    }

                    else {

                        this.dialog2.error('error', 'Something Went Wrong');

                    }

                }));

                this.loader = '';

            }

        });

    }
    //Delete Distributor Contact Person Through API Function End


    //Delete Assign Retailer To Distributor Function Start
    deleteAssignRetailer(id) {

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.apiHit.PostRequest({ "id": id }, "Distributor/delete_retailer_distributor").subscribe((result => {

                    console.log(result);

                    if (result['delete_retailer_distributor'] == 'deleted') {

                        this.distributorDetail();

                    } else {

                        this.dialog2.error('error', 'Something Went Wrong');

                    }

                }));

            }

        });

    }
    //Delete Assign Retailer To Distributor Function End


    //Delete Company To Distributor Transection Delete Function Start
    transectionDelete(id) {
        
        console.log(id);

        this.dialog2.delete('').then((result) => {

            if (result === true) {

                this.loader = 1;

                this.apiHit.PostRequest({ "id": id }, "Distributor/payment_delete").subscribe((result => {

                    console.log(result);

                    if (result['payment_delete'] == 'success') {

                        this.searchCompanyTransaction();

                    } else {

                        this.dialog2.error('error', 'Something Went Wrong');

                    }

                }));

            }

        });

    }
    //Delete Company To Distributor Transection Delete Function End


    public onDate(event): void {

        this.retailerDataDetails.date_created = moment(event.value).format('YYYY-MM-DD');
        this.searchRetailerist();

    }


    public onDate2(event): void {

        this.companyToDistributorTransaction.dateCreated = moment(event.value).format('YYYY-MM-DD');
        this.searchCompanyTransaction();

    }


    public onDate3(event): void {

        this.distributorToRetailerTransaction.date_created = moment(event.value).format('YYYY-MM-DD');
        this.searchDistributorToRetailerTransaction();

    }


    // *** Refresh Funcation start ***//
    refreshData(target) {

        console.log(target);

        if (target == 1) {
            console.log('in if condtion');

            this.retailerDataDetails = {};
            this.searchRetailerist();

        }
        else if (target == 2) {
            console.log('in else if condtion');

            this.companyToDistributorTransaction = {}
            this.searchCompanyTransaction();

        }
        else if (target == 3) {
            console.log('in else condtion');

            this.distributorToRetailerTransaction = {};
            this.searchDistributorToRetailerTransaction();

        }
        else {
            this.getDistributorStockAlert(2)
        }

    }
    // *** Refresh Funcation End ***//



    //Edit Distributor Detail (Open Dialog Box) Function Start 
    DistributorInfoEdit(target, data): void {

        const dialogRef = this.dialog.open(DistributorEditComponent, {

            width: '',
            panelClass: "modelwidth",
            data: { target, data }

        });

        dialogRef.afterClosed().subscribe(result => {

            this.distributorToRetailerTransaction;
            this.distributorDetail();
            this.searchCompanyTransaction();
            this.getDistributorStockAlert(2);

        });

    }
    //Edit Distributor Detail (Open Dialog Box) Function End
    //Edit Sales User  (Open Dialog Box) Function Start 
    SalesUserInfoEdit(target, data): void {

        const dialogRef = this.dialog.open(DistributorEditComponent, {

            width: '',
            panelClass: "modelwidth",
            data: { target, data }

        });

        dialogRef.afterClosed().subscribe(result => {

            this.distributorToRetailerTransaction;
            this.distributorDetail();
            this.searchCompanyTransaction();
            this.getDistributorStockAlert(2);

        });

    }
    //Edit Sales User  (Open Dialog Box) Function End






    //Export Data Into Excel File Function Start 
    exportDataInExcel(target) {

        this.excelData = [];

        this.loader = 1;
        if (target == 2) {
            this.distributorToRetailerTransaction = {};
        }

        this.apiHit.PostRequest({ 'dr_id': this.activateRoute['params']['_value']['id'], 'search': this.distributorToRetailerTransaction }, "Distributor/getDistributerPartslist").subscribe((result => {

            console.log(result);

            if (result['status'] == "Success") {
                this.stockListData = result['info'];
                console.log(this.stockListData);
                
                for (let i = 0; i < this.stockListData.length; i++) {

                    this.excelData.push({
        
                        'S.No': i + 1,
                        'Segment' : this.stockListData[i].segment,
                        'Brand': this.stockListData[i].brand,
                        'Part Code': this.stockListData[i].part_code,
                        'Stock Alert': this.stockListData[i].default_stock,
                        'Current Stock': this.stockListData[i].current_stock,
                        'Secondary Order': this.stockListData[i].Secondary_order,
                        'Balance Order': this.stockListData[i].current_stock - this.stockListData[i].Secondary_order,
        
                    });
                }
                this.apiHit.exportAsExcelFile(this.excelData, 'DistributorStockAlert');
                setTimeout(() => {
                    this.loader = '';
                }, 700);

            }
            else {
                this.dialog2.error('error', 'Something Went Wrong');
                this.loader = '';

            }
            console.log(this.excelData);
            

        }));
        console.log(this.excelData);

    }
    //Export Data Into Excel File Function End 

}