<div class="container-fluid">
    <div class="container">
        
        <section id="head" class="ac" *ngIf="!loader">
            <a mat-icon-button class="back-page-btn" matTooltip="Back" matTooltipPosition="below"
            (click)="backClicked()">
            <mat-icon>arrow_back</mat-icon>
        </a>
        
        <div class="module-name">
            <h2>Coupon Code</h2>
            <p>Generate Coupon</p>
        </div>
        <div class="left-auto df afe">
            <div class="indicates">
                Indicates required fields ( <span>*</span> )
            </div>
        </div>
    </section>
    
    <!-- skeleton Start -->
    <section id="head" *ngIf="loader">
        <div class="module-name sk-loading">
            <h2>&nbsp;</h2>
            <p>&nbsp;</p>
        </div>
    </section>
    <!-- skeleton end -->
    
    <div class="container-pd10-5">
        <div class="row">
            <div class="col s12 m12">
                
                <div class="cs-column" *ngIf="!loader">
                    <div class="cs-head">
                        <h2>coupon Generate</h2>
                    </div>
                    
                    <form #reset="ngForm" [formGroup]="couponForm" (ngSubmit)="saveCoupon(reset)">
                        <div class="cs-form pb0">
                            <div class="row row-mb0">
                                
                                <div class="col s12 m2">
                                    <mat-form-field appearance="outline" class="cs-input">
                                        <mat-label>Coupon Type <span class="sub">*</span></mat-label>
                                        <mat-select formControlName="coupon_type" name="coupon_type" id="coupon_type">
                                            <mat-option (click)="checkCouponType(data.id)"
                                            *ngFor="let data of typeCoupon" value="{{data.id}}">
                                            {{data.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                
                                <mat-hint class="has-error" *ngIf="couponForm.controls.coupon_type.invalid && 
                                couponForm.controls.coupon_type.touched">
                            </mat-hint>
                        </div>
                        
                        
                        <div class="col s12 m2">
                            <mat-form-field appearance="outline" class="cs-input">
                                <mat-label>Total Number Of Code <span class="sub">*</span></mat-label>
                                <input matInput placeholder="Type Here ..." formControlName="coupon_count"
                                id="coupon_count" (keypress)="OnlyNumber($event)">
                            </mat-form-field>
                            <mat-hint class="has-error"
                            *ngIf="couponForm.controls.coupon_count.invalid &&  couponForm.controls['coupon_count']?.touched">
                        </mat-hint>
                    </div>
                    
                    <div class="col s12 m2">
                        <mat-form-field appearance="outline" class="cs-input">
                            <mat-label>Segment <span class="sub">*</span></mat-label>
                            <mat-select formControlName="segment" name="segment" id="segment">
                                <mat-option (click)="getCategory(data.segment)"
                                *ngFor="let data of segmentdata" value="{{data.segment}}">
                                {{data.segment}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <mat-hint class="has-error" *ngIf="couponForm.controls.segment.invalid && 
                    couponForm.controls.segment.touched">
                </mat-hint>
            </div>
            
            <div class="col s12 m2">
                <mat-form-field appearance="outline" class="cs-input">
                    <mat-label>Brand <span class="sub">*</span></mat-label>
                    <mat-select formControlName="category" name="category" id="category">
                        <mat-option (click)="getPartCodeNo(data.brand)"
                        *ngFor="let data of categoryName" value="{{data.brand}}">
                        {{data.brand}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-hint class="has-error" *ngIf="couponForm.controls.category.invalid && 
            couponForm.controls.category.touched">
        </mat-hint>
    </div>
    
    <div class="col s12 m3">
        <mat-form-field appearance="outline" class="cs-input">
            <mat-label>Part No <span class="sub">*</span></mat-label>
            <mat-select formControlName="part_code" name="part_code" id="part_code">
                <ngx-mat-select-search formControlName="searchCtrl"
                [placeholderLabel]="'Search...'"
                [noEntriesFoundLabel]="'Not found'" name="search">
            </ngx-mat-select-search>
            <mat-option (click)="getPartPointValue(data.part_code)"
            *ngFor="let data of partNo  |stringFilterBy:couponForm.value.searchCtrl:'part_code'"
            value="{{data.part_code}}">
            {{data.part_code}}
        </mat-option>
    </mat-select>
    
</mat-form-field>
<mat-hint class="has-error" *ngIf="couponForm.controls.part_code.invalid && 
couponForm.controls.part_code.touched">
</mat-hint>
</div>

<div class="col s12 m2" *ngIf="couponForm.value.coupon_type == 1">
    <mat-form-field appearance="outline" class="cs-input">
        <mat-label>Coupon Point <span class="sub">*</span></mat-label>
        <input matInput placeholder="Type Here ..." formControlName="count_amount"
        id="count_amount" [readonly]="true" (keypress)="OnlyNumber($event)">
    </mat-form-field>
    <mat-hint class="has-error"
    *ngIf="couponForm.controls.count_amount.invalid &&  couponForm.controls['count_amount']?.touched">
</mat-hint>
</div>

<div class="col s12 m1">
    <div class="cs-btn mt8">
        <button type="submit" [disabled]="!couponForm.valid"
        mat-button>GENERATE</button>
    </div>
</div>


</div>
</div>
</form>
</div>



<!-- Skeleton Start -->
<div class="cs-column" *ngIf="loader">
    <div class="cs-head sk-loading">
        <h2>&nbsp;</h2>
    </div>
    
    <div class="cs-form pb0">
        <div class="row">
            <div class="col s12 m12">
                <div class="fields sk-loading">
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                    <div class="cs-box">
                        <p class="h35 wp100">&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Skeleton End -->


</div>
</div>


<div class="container-pd10-5 bottom-calc no-padding">
    
    <mat-tab-group class="tab-group flat-tab-body tab-group-list" [selectedIndex]="matTabValue" (selectedTabChange)="tabClick($event)"
    mat-align-tabs="start">
    
    <!-- <mat-tab label=0>
        <ng-template mat-tab-label *ngIf="loader">
            <div class="sk-loading">&nbsp;</div>
        </ng-template>
        <ng-template mat-tab-label *ngIf="!loader">
            <mat-icon class="example-tab-icon">qr_code_scanner</mat-icon>
            Primary
            <span class="badge">{{totalCoupon.primaryCount ? totalCoupon.primaryCount : 0}}</span>
        </ng-template>
    </mat-tab>
    
    <mat-tab label=1>
        <ng-template mat-tab-label *ngIf="loader">
            <div class="sk-loading">&nbsp;</div>
        </ng-template>
        <ng-template mat-tab-label *ngIf="!loader">
            <mat-icon class="example-tab-icon">qr_code_scanner</mat-icon>
            Master
            <span class="badge">{{totalCoupon.masterCount ? totalCoupon.masterCount : 0}}</span>
        </ng-template>
    </mat-tab> -->
    
</mat-tab-group>

<div class="cs-table left-right-5">
    <!-- Table Head Start-->
    <figure class="table-head" style="top: -4px;">
        <section class="th_cell">
            <main>
                <div class="div_cell mmw50 text-center af-bdr-left">S.No.</div>
                <div class="div_cell mmw100">Date</div>
                <div class="div_cell mmw110">Created By</div>
                <div class="div_cell mmw120  text-center">Total Coupon Code</div>
                <div class="div_cell mmw90   text-right" *ngIf="matTabValue == 0">Coupon Value</div>
                <div class="div_cell mmw90">Segment</div>
                <div class="div_cell mmw120">Brand</div>
                <div class="div_cell mw220">Part No</div>
                <div class="div_cell mmw90" *ngIf="matTabValue == 1">Box Size</div>
                <div class="div_cell mmw100" *ngIf="matTabValue == 0">Status</div>
                <div class="div_cell mmw130" *ngIf="matTabValue == 0">Reject Reason</div>
                <div class="div_cell mmw130 af-bdr-right">Action</div>
            </main>
        </section>
        <section class="th_cell">
            <main>
                <div class="div_cell mmw50 text-center af-bdr-left">&nbsp;</div>
                <div class="div_cell mmw100">
                    <div class="filter-data">
                        <mat-form-field (click)="picker.open()">
                            <input type="text" matInput [matDatepicker]="picker" [max]="date"
                            placeholder="Search ..." name="date_created"
                            [(ngModel)]="filterSearch.date_created" (dateChange)="onDate($event)"
                            readonly>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="div_cell mmw110">
                    <div class="filter-data">
                        <i class="material-icons">search</i>
                        <input type="text" placeholder="Search ..." name="created_by"
                        [(ngModel)]="filterSearch.created_by" (keyup.enter)="genrateCouponList(1);">
                    </div>
                </div>
                <div class="div_cell mmw120">
                    <div class="filter-data">
                        <i class="material-icons">search</i>
                        <input type="text" placeholder="Search ..." name="coupon_count"
                        [(ngModel)]="filterSearch.coupon_count" (keyup.enter)="genrateCouponList(1);">
                    </div>
                </div>
                <div class="div_cell mmw90 " *ngIf="matTabValue == 0">&nbsp;</div>
                <div class="div_cell mmw90">&nbsp;</div>
                <div class="div_cell mmw120">&nbsp;</div>
                <div class="div_cell mw220">
                    <div class="filter-data">
                        <i class="material-icons">search</i>
                        <input type="text" placeholder="Search ..." name="part_code"
                        [(ngModel)]="filterSearch.part_code" (keyup.enter)="genrateCouponList(1);">
                    </div>
                </div>
                <div class="div_cell mmw90 " *ngIf="matTabValue == 1">&nbsp;</div>
                <div class="div_cell mmw100" *ngIf="matTabValue == 0">
                    <div class="filter-data">
                        <i class="material-icons">search</i>
                        <input type="text" placeholder="Search ..." name="status"
                        [(ngModel)]="filterSearch.status" (keyup.enter)="genrateCouponList(1);">
                    </div>
                </div>
                <div class="div_cell mmw130 " *ngIf="matTabValue == 0">&nbsp;</div>
                <div class="div_cell mmw130 af-bdr-right">
                    <div class="cs-action jfs">
                        <a class="ac-grey" matRipple matTooltip="Search" matTooltipPosition="below"
                        (click)="genrateCouponList(1)"><i class="material-icons">search</i></a>
                        <a class="ac-refresh" matRipple matTooltip="Refresh" matTooltipPosition="below"
                        (click)="refreshData()"><i
                        class="material-icons">settings_backup_restore</i></a>
                    </div>
                </div>
            </main>
        </section>
    </figure>
    <!-- Table Head End -->
    
    
    
    <section class="td_cell">
        <!-- Loop Data -->
        <ng-conatiner *ngIf="!loader">
            <main *ngFor="let row of couponList; let i = index">
                <div class="div_cell mmw50 ac">
                    <strong *ngIf="pageNumber">{{((pageNumber-1)*paginationData.pagelimit)+i+1}}</strong>
                    <strong *ngIf="pageNumber == null">{{i+1}}</strong>
                    <!-- <strong>{{i+1}}</strong> -->
                </div>
                <div class="div_cell mmw100">{{row.date_created | date:'d MMM y'}} </div>
                <div class="div_cell mmw110 ">{{row.created_by_name | titlecase}}</div>
                <div class="div_cell mmw120 ac">{{row.coupon_count ? row.coupon_count : '0'}}</div>
                <div class="div_cell mmw90 afe" *ngIf="matTabValue == 0"> {{row.count_amount ? row.count_amount : '0'}}</div>
                <div class="div_cell mmw90">{{row.segment ? row.segment : 'N/A'}}</div>
                <div class="div_cell mmw120">{{row.category ? row.category : 'N/A'}}</div>
                <div class="div_cell mw220">{{row.part_code ? row.part_code : 'N/A'}}</div>
                <div class="div_cell mmw90" *ngIf="matTabValue == 1">{{row.box_size ? row.box_size : 0}}</div>
                <div class="div_cell mmw100" *ngIf="matTabValue == 0">
                    <strong *ngIf="row.status=='Pending'" class="yellow-clr">{{row.status ?
                        row.status:'N/A'}}</strong>
                        <strong *ngIf="row.status=='Verified'" class="green-clr">{{row.status ?
                            row.status:'N/A'}}</strong>
                            <strong *ngIf="row.status=='Reject'" class="red-clr">{{row.status ?
                                row.status:'N/A'}}</strong>
                            </div>
                            <div class="div_cell mmw130" *ngIf="matTabValue == 0">{{row.status_reason ? row.status_reason : 'N/A'}}</div>
                            <div class="div_cell mmw130">
                                <div class="cs-action">
                                    <a  class="ac-green" matRipple matTooltip="Edit" matTooltipPosition="below"
                                    *ngIf="row.showDelete && db.userLoginData.data.moduleData[10]['edit'] == 1 && matTabValue == 0" (click)="statusModel(1,row)"><i
                                    class="material-icons">edit</i></a>
                                    <a class="ac-blue" matRipple matTooltip="Download Text File" matTooltipPosition="below"
                                    *ngIf="row.status == 'Verified' && matTabValue == 0" (click)="exportTxt(row.id)"><i
                                    class="material-icons">download</i></a>
                                    <a class="ac-blue" matRipple matTooltip="Download Excel File" matTooltipPosition="below"
                                    *ngIf="row.status == 'Verified' && matTabValue == 0" (click)="exportAsXLSX(row.id)"><i
                                    class="material-icons">cloud_download</i></a>
                                    <a class="ac-blue" matRipple matTooltip="Download" matTooltipPosition="below"
                                    *ngIf="matTabValue == 1" (click)="exportMasterExcel(row.id)"><i
                                    class="material-icons">cloud_download</i></a>
                                    <a class="ac-red" matRipple matTooltip="Delete" matTooltipPosition="below"
                                    *ngIf="row.showDelete && db.userLoginData.data.moduleData[10]['delete'] == 1" (click)="deleteItem(row.id,matTabValue)"><i
                                    class="material-icons">delete_sweep</i></a>
                                </div>
                            </div>
                        </main>
                        <app-not-found *ngIf="couponList.length == 0"></app-not-found>
                    </ng-conatiner>
                    <!-- Loop Data -->
                    
                    <!-- sk-loading start -->
                    <ng-container *ngIf="loader">
                        <main *ngFor="let row of skData;">
                            <div class="div_cell mmw50">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw110">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90 afe" *ngIf="matTabValue == 0">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw120">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mw220">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw90" *ngIf="matTabValue == 1">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw100" *ngIf="matTabValue == 0">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130" *ngIf="matTabValue == 0">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                            <div class="div_cell mmw130">
                                <p class="skeleton wp100">&nbsp;</p>
                            </div>
                        </main>
                    </ng-container>
                    <!-- sk-loading end -->
                </section>
            </div>
            
            
            <!-- Pagination Start -->
            <div class="bottom-strip" *ngIf="!loader && total > 1">
                <div class="left-auto df ac">
                    <div class="pagination">
                        <ul class="df ac">
                            <li>Pages {{pageNumber}} of {{total}}</li>
                            <li (click)="onChangePageNumber('previous')"><a matRipple class="back" matTooltip="Previous"
                                matTooltipPosition="above"><i class="material-icons">chevron_left</i></a></li>
                                <li><input type="number" [(ngModel)]="pageNumber"
                                    (keyup.enter)="onChangePageNumber('current')" placeholder="Go To"></li>
                                    <li (click)="onChangePageNumber('next')"><a matRipple class="next" matTooltip="Next"
                                        matTooltipPosition="above"><i class="material-icons">chevron_right</i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Pagination End -->
                    
                </div>
            </div>
        </div>
    </div>